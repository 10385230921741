import * as React from 'react';
import styled from 'styled-components/macro';

const Icon = styled.path`
  fill: ${props => props.color || 'currentColor'};
  opacity: ${props => props.opacity || 'inherit'};
`;

export const PhoneIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      <Icon
        {...rest}
        fillRule="evenodd"
        d="M17.016 12.425c-.046-.137-.336-.34-.87-.606-.144-.084-.35-.198-.617-.343-.267-.145-.509-.279-.726-.4-.217-.122-.421-.24-.612-.355-.03-.023-.125-.09-.285-.2-.16-.11-.296-.192-.406-.246-.11-.053-.22-.08-.326-.08-.153 0-.343.109-.572.326-.229.217-.438.453-.629.709-.19.255-.392.492-.606.709-.213.217-.388.326-.526.326-.068 0-.154-.02-.257-.057-.103-.038-.18-.07-.235-.097-.053-.027-.144-.08-.274-.16l-.217-.132c-1.044-.58-1.94-1.243-2.687-1.99-.747-.747-1.41-1.643-1.99-2.687-.007-.015-.051-.088-.131-.217-.08-.13-.134-.221-.16-.275-.027-.053-.06-.131-.098-.234-.038-.103-.057-.189-.057-.257 0-.138.109-.313.326-.526.217-.214.454-.416.71-.606.255-.19.49-.4.708-.63.217-.228.326-.418.326-.57 0-.107-.027-.216-.08-.327-.053-.11-.135-.246-.246-.406-.11-.16-.177-.255-.2-.286-.114-.19-.232-.394-.355-.611-.122-.217-.255-.46-.4-.726-.145-.267-.259-.473-.343-.618C4.914.32 4.712.03 4.575-.016c-.053-.023-.133-.034-.24-.034-.206 0-.475.038-.806.114-.332.076-.593.156-.783.24C2.364.464 1.96.93 1.533 1.7 1.145 2.416.95 3.125.95 3.826c0 .206.014.406.04.6.027.195.075.414.143.658.069.244.124.425.166.543.042.118.12.33.234.635.115.305.183.491.206.56.267.747.583 1.414.95 2.001.601.976 1.423 1.984 2.463 3.025 1.04 1.04 2.049 1.862 3.025 2.464.587.366 1.254.682 2.001.95.069.022.255.09.56.205.305.114.517.192.635.234.118.042.299.098.543.166.244.069.463.117.657.143.195.027.395.04.6.04.702 0 1.411-.194 2.128-.583.77-.427 1.234-.83 1.394-1.212.085-.19.164-.452.24-.783.077-.332.115-.6.115-.806 0-.107-.011-.187-.034-.241z"
      />
    </svg>
  );
};
