import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { MdFolder } from 'react-icons/md';
import {
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
} from 'lib/components';
import {
  GroupListItem,
  ContactsBulkUpdateParams,
  ContactListItem,
} from 'lib/api';
import { useParams } from 'react-router';
import { ModalAddContactToGroup, ModalDelete } from 'lib/components/modal';
import { NoContacts } from './NoContacts';
import { Contact } from 'lib/api';
import { useUpdateMultipleContactsMutation } from 'lib/api/contacts/useUpdateMultipleContactsMutation';
import { useQueryContactGroups } from 'lib/api/contacts/useQueryContactGroups';
import { useRemoveGroupContactMutation } from 'lib/api/group/useRemoveGroupContactMutation';
import { Group } from 'lib/api/contacts/types';

const Content = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const TableCell = styled.div`
  text-align: left;
`;

type Props = {
  contact: Contact;
};

export const ContactGroups = ({ contact }: Props) => {
  const [data, setData] = useState<Array<Group>>([]);
  const [search, setSearch] = useState('');
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const group = useRef<Group>({ name: '', groupId: 0, userId: 0 });
  const themes = useTheme();

  let { id } = useParams() as {
    id: string;
  };

  const onRemoveGroupContactMutationSuccess = () => {
    setShowRemoveModal(false);
  };

  const { mutateAsync: removeGroupContact } = useRemoveGroupContactMutation(
    id,
    onRemoveGroupContactMutationSuccess
  );

  const onUpdateMultipleContactsMutationSuccess = () => {
    setShowAddToGroupModal(false);
  };

  const { mutateAsync: updateContactsBulk } = useUpdateMultipleContactsMutation(
    onUpdateMultipleContactsMutationSuccess,
    id
  );

  const { data: contactGroups, isLoading } = useQueryContactGroups(id);
  const groups: Group[] = contactGroups?.groups ?? [];

  useEffect(() => {
    if (contactGroups) {
      setData(groups);
    }
  }, [contactGroups]);

  const onSearch = (value: string) => {
    setSearch(value);
    const filtered = groups.filter((group: Group) =>
      group.name.toLowerCase().includes(value.toLowerCase())
    );
    setData(filtered);
  };

  const openRemoveModal = (selectedGroup: Group) => {
    group.current = selectedGroup;
    setShowRemoveModal(true);
  };
  const onDelete = async () => {
    await removeGroupContact({
      contactId: [id],
      groupId: group.current.groupId,
    });
  };

  const addContactsToGroup = async (groups: GroupListItem[]) => {
    const data: ContactsBulkUpdateParams = {
      contacts: [{ contactId: id } as ContactListItem],
      groups: groups,
    };
    await updateContactsBulk(data);
  };
  const text = `remove ${contact.firstName} ${contact.lastName} from the group ’${group.current.name}’`;
  return (
    <Content>
      {showAddToGroupModal && (
        <ModalAddContactToGroup
          handleModalClose={() => setShowAddToGroupModal(false)}
          handleSubmit={addContactsToGroup}
          selectedContacts={[{ contactId: id }]}
          existingGroups={
            data && data?.length > 0
              ? data?.map(group => group.groupId)
              : undefined
          }
        />
      )}
      {showRemoveModal && (
        <ModalDelete
          whiteButtonText={'Yes, Remove'}
          orangeButtonText={'No, Keep in Group'}
          title={'Remove Contact from Group?'}
          text1={
            <p style={{ marginBottom: -12 }}>
              You’re going to <strong>{text}</strong>
            </p>
          }
          text={'This action can’t be undone.'}
          handleModalClose={() => setShowRemoveModal(false)}
          onClickWhiteButton={onDelete}
          onClickOrangeButton={() => setShowRemoveModal(false)}
        />
      )}
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : !isLoading && data.length == 0 && !search ? (
        <NoContacts setShowAddToGroupModal={setShowAddToGroupModal} />
      ) : (
        <>
          <Row>
            <div style={{ width: 300 }}>
              <Search placeholder='Search Groups...' onSearch={onSearch} />
            </div>
            <Button
              onClick={() => setShowAddToGroupModal(true)}
              text='Add to Group'
              variant='primary'
              icon={
                <MdFolder color={themes.colors.white[100]} />
              } /*** SUS-958 changes ***/
            />
          </Row>
          <TableContextProvider>
            <Table
              compact={true}
              headers={[<TableCell>Group Name</TableCell>, '']}
              hoverable={false}
              rows={data?.map((x: Group, index: number) => ({
                key: index,
                columns: [
                  <TableCell>{x.name}</TableCell>,
                  <Button
                    onClick={() => openRemoveModal(x)}
                    variant='destructive'
                    text='Remove from group'
                  ></Button>,
                ],
                onClick: () => {},
              }))}
              isLoading={isLoading}
            />
            {data.length === 0 && (
              <p
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: '36px 0',
                }}
              >
                No matching results found.{' '}
              </p>
            )}
          </TableContextProvider>
        </>
      )}
    </Content>
  );
};
