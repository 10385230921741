import { PaginationWrapper } from 'app/pages/admin/ims/components/style';
import {
  HoverPopup,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Gap, Stack } from 'lib/components/styles/layout';
import { LinkText, NormalText } from 'app/pages/admin/index.styled';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { CustomReportsTableColumns, tableFields } from './const';
import { SortButtons } from 'app/pages/admin/components/SortButtons';
import dayjs from 'dayjs';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import { Button, Tooltip } from 'react-covideo-common';
import DeleteIcon from 'lib/images/DeleteIcon';
import { IoMdDownload } from 'react-icons/io';
import { useHistory } from 'react-router';
import { CustomReportItem } from 'lib/context';
import {
  calculateDatesForRange,
  getTimeframeLabel,
} from '../../components/DateRangeSelector/DateRangeSelector';
import { CustomReportButton } from '../CustomReportButton';
import { useIsMutating } from 'react-query';
import { downloadCustomReportData } from 'lib/api';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { getFrequencySchedule, getFrequencyType } from '../../util';
import { FREQUENCY_TYPE } from 'lib/components/frequency/constants';
import { MdContentCopy } from 'react-icons/md';
import { useDuplicateCustomReportMutation } from 'lib/api/customReports/useDuplicateCustomReportMutation';
import { CustomReportsParams } from 'lib/api/customReports/useCustomReportsQuery';

export const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
}>`
  display: flex;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;

type Props = {
  reportsCount: number;
  reportsArray: CustomReportItem[];
  page: number;
  size: number;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  deleteAction: React.Dispatch<React.SetStateAction<string | null>>;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  isDeletingCustomReport: boolean;
  customReportsQueryParams: CustomReportsParams;
};

const DATE_FORMAT = 'DD/MM/YYYY';

export const CreatedByMe = ({
  reportsCount,
  reportsArray,
  page,
  size,
  onPaginationChange,
  deleteAction,
  onSortingUpdate,
  sortKey,
  order,
  setLimit,
  isDeletingCustomReport,
  customReportsQueryParams,
}: Props) => {
  const history = useHistory();
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [isDownloading, setIsDownloading] = useState(false);

  const { mutateAsync: duplicateustomReportMutation } =
    useDuplicateCustomReportMutation(customReportsQueryParams);

  const getAllRecipients = (report: CustomReportItem): string[] => {
    const recipients: string[] = [];
    if (report.recurrings) {
      report.recurrings.forEach((recurring: { recipients: any }) => {
        recipients.push(...recurring.recipients);
      });
      return recipients;
    }
    return [];
  };

  const constructReportRecipientsText = (
    report: CustomReportItem
  ): { text: string; more: string } => {
    const recipients = getAllRecipients(report);

    if (!Array.isArray(recipients) || recipients.length === 0) {
      return { text: '', more: '' };
    }

    if (recipients.length === 1) {
      return { text: recipients[0], more: '' };
    }

    return { text: recipients[0], more: `+ ${recipients.length - 1} more` };
  };

  const constructRecipientsPopupList = (report: CustomReportItem) => {
    let recipients = getAllRecipients(report);
    return (
      <Stack gap='2px'>
        {Array.isArray(recipients) &&
          recipients.map((recipient, index) => (
            <ParagraphSmall key={index} ellipsis width='260px'>
              {recipient}
            </ParagraphSmall>
          ))}
      </Stack>
    );
  };

  const handleCsvDownload = async (report: CustomReportItem) => {
    if (report.range) {
      setIsDownloading(true);
      const { start, end } = calculateDatesForRange(report.range);
      await downloadCustomReportData(
        report.reportId || '',
        `${report.title}.csv`,
        start,
        end
      );
      setIsDownloading(false);
    }
  };

  const loading = isDeletingCustomReport || !!isDisabled;

  return (
    <Layout>
      <TableContextProvider
        total={reportsCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div className='scroll--container' style={{ paddingBottom: '20px' }}>
          <Table
            compact={true}
            borderColor={theme.palette.neutral10}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell key={index} width={item.width} onClick={() => {}}>
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            rows={reportsArray?.map(
              (report: CustomReportItem, index: number) => ({
                key: index,
                columns: [
                  ...tableFields.map((item, index) => {
                    let columnValue = <></>;
                    switch (item.value) {
                      case CustomReportsTableColumns.REPORT_NAME:
                        columnValue = (
                          <Tooltip popup='Report details'>
                            <LinkText
                              onClick={() =>
                                history.push(
                                  '/reports/custom/' + report.reportId
                                )
                              }
                            >
                              <ParagraphSmall
                                ellipsis
                                width={item.width + 'px'}
                                maxWidth={item.width + 'px'}
                              >
                                {report.title}
                              </ParagraphSmall>
                            </LinkText>
                          </Tooltip>
                        );
                        break;
                      case CustomReportsTableColumns.TYPE:
                        columnValue =
                          report.recurrings && report.recurrings.length > 0 ? (
                            <Chip
                              bgColor={theme.palette.covideoBlue02NonAlpha}
                              fontColor={theme.palette.covideoBlue80}
                            >
                              Recurring
                            </Chip>
                          ) : (
                            <Chip
                              bgColor={theme.palette.covideoBlue02NonAlpha}
                              fontColor={theme.palette.covideoBlue80}
                            >
                              Static
                            </Chip>
                          );
                        break;
                      case CustomReportsTableColumns.DELIVERY: {
                        const hasRecurrings =
                          report.recurrings && report.recurrings.length > 0;
                        const frequencyType = getFrequencyType(
                          report.recurrings?.[0]?.frequency
                        );
                        const isDailyReport =
                          frequencyType &&
                          frequencyType === FREQUENCY_TYPE.DAILY;

                        columnValue = hasRecurrings ? (
                          <Tooltip
                            popup={getFrequencySchedule(
                              report.recurrings?.[0]?.frequency
                            )}
                            hoverable={!isDailyReport}
                            extendStyles={{
                              element: {
                                ...(!isDailyReport && { cursor: 'pointer' }),
                              },
                            }}
                          >
                            <NormalText>
                              {getFrequencyType(
                                report.recurrings?.[0]?.frequency,
                                true
                              )}
                            </NormalText>
                          </Tooltip>
                        ) : (
                          <NormalText>One-time</NormalText>
                        );
                        break;
                      }
                      case CustomReportsTableColumns.RECIPIENTS:
                        const { text, more } =
                          constructReportRecipientsText(report);
                        columnValue = (
                          <HoverPopup
                            key={index}
                            width='max-content'
                            maxWidth='267px'
                            position='above'
                            padding='8px 12px'
                            hoverable={
                              <Gap flexWrap='nowrap' gap='4px'>
                                <ParagraphSmall
                                  ellipsis
                                  width={!!more ? '180px' : '244px'}
                                >
                                  {text}
                                </ParagraphSmall>
                                <ParagraphSmall>{more}</ParagraphSmall>
                              </Gap>
                            }
                            popup={constructRecipientsPopupList(report)}
                          />
                        );
                        break;
                      case CustomReportsTableColumns.DATE:
                        columnValue = (
                          <NormalText>
                            {report.range
                              ? getTimeframeLabel(report.range)
                              : `${dayjs(report.startDate).format(DATE_FORMAT)} - ${dayjs(report.endDate).format(DATE_FORMAT)}`}
                          </NormalText>
                        );
                        break;
                      case CustomReportsTableColumns.SYNC: {
                        const hasRecurrings =
                          report.recurrings && report.recurrings.length > 0;
                        columnValue = (
                          <Gap
                            flexDirection='row'
                            gap='8px'
                            flexWrap='nowrap'
                            alignItems='end'
                            width='100%'
                            justifyContent='end'
                          >
                            <Tooltip popup='Edit'>
                              <CustomReportButton
                                text={''}
                                variant='secondary'
                                isEdit
                                disabled={loading}
                                reportId={report.reportId}
                              />
                            </Tooltip>

                            {hasRecurrings && (
                              <Tooltip popup='Duplicate'>
                                <Button
                                  variant='secondary'
                                  icon={<MdContentCopy size={20} />}
                                  disabled={loading}
                                  onClick={async () => {
                                    if (report.reportId) {
                                      await duplicateustomReportMutation(
                                        report.reportId
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                            )}

                            <Tooltip popup='Download .csv'>
                              <Button
                                variant='secondary'
                                icon={<IoMdDownload size={20} />}
                                disabled={isDownloading}
                                onClick={async () =>
                                  await handleCsvDownload(report)
                                }
                              />
                            </Tooltip>

                            <Tooltip popup='Delete'>
                              <Button
                                variant='destructive'
                                disabled={loading}
                                icon={
                                  <DeleteIcon
                                    color={theme.palette.buttonDelete}
                                  />
                                }
                                onClick={() =>
                                  deleteAction(report.reportId ?? '')
                                }
                              />
                            </Tooltip>
                            <Tooltip popup='Report details'>
                              <Button
                                variant='secondary'
                                disabled={loading}
                                text='Details'
                                onClick={() =>
                                  history.push(
                                    '/reports/custom/' + report.reportId
                                  )
                                }
                              />
                            </Tooltip>
                          </Gap>
                        );
                        break;
                      }
                      default:
                        columnValue = <></>;
                    }
                    return (
                      <TableCell key={index} width={item.width}>
                        {columnValue}
                      </TableCell>
                    );
                  }),
                ],
                onClick: () => {},
              })
            )}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' onSizeChange={setLimit} />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
