import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { MdDeleteForever } from 'react-icons/md';
import CloseIcon from 'lib/images/CloseIcon';
import { Button } from 'react-covideo-common';
interface Props {
  onClose: (params?: any) => void;
  onDelete: (params?: any) => void;
  // data: ListWebsiteOverlay;
  modalLoading: boolean;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const TitleBody = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.black_1_100};
`;

export const ModalSwitchCta = (props: Props) => {
  const { onClose, onDelete, modalLoading } = props;
  const themes = useTheme();
  return (
    <Modal>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row justifyContent={'space-between'}>
          <TitleContent>
            <TitleHeader>
              <Title>Leave without saving changes?</Title>
              <CloseIconWrap>
                <CloseIcon
                  onClick={onClose}
                  width={24}
                  height={24}
                  color={theme.palette.midGrey}
                />
              </CloseIconWrap>
            </TitleHeader>
            <TitleBody>
              Your updates will not be saved. This action can’t be undone.
            </TitleBody>
          </TitleContent>
        </Row>
        <Row justifyContent={'flex-end'}>
          <Button
            variant='destructive'
            icon={<MdDeleteForever />}
            text={'Leave this Section'}
            onClick={() => onDelete()}
          />
          <Button text={'Stay on this Section'} onClick={() => onClose()} />
        </Row>
      </Content>
    </Modal>
  );
};
