import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import styled, { useTheme } from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal, Table, TableContextProvider } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import Select from 'react-select';
import { fetchUsers } from '../../api';
import { connectVinsolutionUsers } from '../../api/crmIntegrationsApi';
import Switch from '../../../app/pages/design/landingPageBuilder/components/Switch';
import { VerificationStatus } from '../../const/VerificationStatus';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin-top: 24px;
`;

const Scrollable = styled.div`
  overflow-y: scroll;
  height: 350px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const TableCell = styled.div<{ width?: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  white-space: initial;

  .arrow--right {
    padding: 2px 0 0 30px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-top: 3%;
  margin-bottom: 3%;
`;

const ToggleLabel = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  margin-right: 13px;
`;

const UserToAssign = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e84c3d;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
`;
const OptionLabel = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  padding: 8px;
`;
const OptionEmail = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4e5461;
  margin-left: auto;
  padding: 8px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleModalSave: (shouldRefresh?: boolean) => void;
  data?: any;
  vinsolutionDealerUsers: VinsolutionUser[];
};

type VinsolutionUser = {
  dealerId: number;
  email: string;
  firstName: string;
  lastName: string;
  ilmAccess: string;
  isActive: number;
  userId: number | null;
  vinUserId: number;
  vinsolutionsUserId: number;
};

const tableFields = [
  {
    label: 'VinSolution User',
    value: 'vin_user',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Covideo User',
    value: 'covideo_user',
  },
];

export const CRMAssignUserModal = (props: Props) => {
  const [onlyUnassignedUsers, setOnlyUnassignedUsers] = useState(false);
  const [covideoUsers, setCovideoUsers] = useState([]);
  const [vinsolutionUsers, setVinsolutionUsers] = useState<VinsolutionUser[]>(
    []
  );
  const [unassignUsers, setUnassignUsers] = useState<VinsolutionUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<VinsolutionUser[]>([]);
  const [mapArray, setMapArray] = useState([]);

  const { handleModalSave, handleModalClose, data, vinsolutionDealerUsers } =
    props;

  const { ref } = useOnClickOutside(handleModalClose);
  const themes = useTheme();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const fetchCovideoUsers = async () => {
    const response = await fetchUsers({
      start: 0,
      limit: 200,
      status: VerificationStatus.ACTIVE,
      customerId: data.customerId,
    });
    setCovideoUsers(response.users);
  };

  const mapVinsolutionUsers = (users: VinsolutionUser[]) => {
    setVinsolutionUsers(users);
    setFilteredUsers(
      users.sort((a, b) => a.firstName.localeCompare(b.firstName))
    );

    let unassign: any = [];
    users.forEach((user: any) => {
      if (user.userId === null) {
        unassign.push(user);
      }
    });
    setUnassignUsers(unassign);
  };

  const filterUsers = () => {
    setOnlyUnassignedUsers(!onlyUnassignedUsers);
    if (!onlyUnassignedUsers) {
      setFilteredUsers(unassignUsers);
    } else {
      setFilteredUsers([...vinsolutionUsers]);
    }
  };

  useEffect(() => {
    mapVinsolutionUsers(vinsolutionDealerUsers);
    fetchCovideoUsers();
    document.addEventListener('keyup', e => escPress(e.key));
    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const onSave = async () => {
    for (const mapItem of mapArray) {
      await connectVinsolutionUsers(mapItem, 0).catch(error =>
        console.log(error)
      );
    }
    handleModalSave();
  };

  const onUserChange = async (userId: number, user: VinsolutionUser) => {
    let mapObject = {
      vinsolutionsUserId: user.vinsolutionsUserId,
      userId,
    };
    let arr: any = [...mapArray];
    arr.push(mapObject);
    setMapArray(arr);

    const users = [...vinsolutionUsers];
    const vinUser = users.find(
      userObject => userObject.vinsolutionsUserId === user.vinsolutionsUserId
    );

    if (vinUser) {
      vinUser.userId = userId;
    }

    mapVinsolutionUsers(users);
  };

  const customSingleValue = (props: any) => {
    const { innerProps, innerRef } = props;
    return (
      <OptionWrapper ref={innerRef} {...innerProps}>
        <OptionLabel>
          {props.data.firstName} {props.data.lastName}
        </OptionLabel>
        <OptionEmail>{props.data.email}</OptionEmail>
      </OptionWrapper>
    );
  };

  const rows = filteredUsers?.map((user: VinsolutionUser, index: number) => ({
    key: index,
    columns: [
      ...tableFields.map(item => {
        return (
          <TableCell width={300}>
            {item.value === 'vin_user' && user.firstName + ' ' + user.lastName}
            {item.value === 'email' &&
              (user[item.value] === null || user[item.value] === ''
                ? 'No email found'
                : user[item.value])}
            {item.value === 'covideo_user' && (
              <Select
                components={{ Option: customSingleValue }}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    height: '40px',
                    width: '300px',
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  menuPortal: (base: any) => ({
                    ...base,
                    zIndex: 1000,
                  }),
                }}
                options={covideoUsers}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) =>
                  option.firstName + ' ' + option.lastName
                }
                value={covideoUsers.find((o: any) => {
                  if (user.userId !== null && user.userId === o.id) {
                    return user.userId === o.id;
                  }
                  return null;
                })}
                onChange={option => onUserChange(option.id, user)}
                placeholder='None'
              />
            )}
          </TableCell>
        );
      }),
    ],
    onClick: () => {},
  }));

  return (
    <Modal style={{ position: 'fixed' }} widthOnDesktop={'77%'}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            Assign users from VinSolutions to {data.business}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <TextWrapper>
            Assign each fetched user from integration to users under dropdowns
            on the left.
          </TextWrapper>
          {vinsolutionUsers.length !== 0 ? (
            <>
              <UserWrapper>
                <ToggleContainer>
                  <ToggleLabel>Show only my unassigned users</ToggleLabel>
                  <Switch
                    id={'switch-un-users'}
                    isOn={onlyUnassignedUsers}
                    onColor={themes.colors.primary[100]}
                    handleToggle={() => {
                      filterUsers();
                    }}
                  />
                </ToggleContainer>
                <UserToAssign>
                  {unassignUsers.length === 1 || unassignUsers.length === 0
                    ? unassignUsers.length + ' user to assign'
                    : unassignUsers.length + ' users to assign'}
                </UserToAssign>
              </UserWrapper>
              <Scrollable>
                <TableContextProvider
                  total={filteredUsers.length}
                  initPage={0}
                  initSize={10}
                >
                  <Table
                    compact={true}
                    relative
                    headers={[
                      ...tableFields.map(item => {
                        return <TableCell>{item.label}</TableCell>;
                      }),
                    ]}
                    hoverable={false}
                    rows={rows}
                    columnWidths={['300px', '300px', '300px']}
                  />
                </TableContextProvider>
              </Scrollable>
            </>
          ) : (
            <>
              <h4>Users {data.business} not found</h4>
            </>
          )}
        </Content>
        <Footer>
          <Button
            text='Connect & Save'
            variant='primary'
            onClick={onSave}
            disabled={vinsolutionUsers.length === 0}
          />
        </Footer>
      </div>
    </Modal>
  );
};
