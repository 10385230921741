import * as React from 'react';
import { Modal } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

import CloseIcon from '../../images/CloseIcon';
import { SelectFields } from '../selectFields/SelectFields';
import {
  BUTTON_VARIANT,
  ICON_POSITION,
  BUTTON_SIZE,
} from 'react-covideo-common/dist/components/Button/types';

export interface Field {
  value: string;
  label: string;
  description?: string;
}
export interface ActionButtonProps {
  variant?: BUTTON_VARIANT;
  text?: string;
  icon?: JSX.Element;
  iconPosition?: ICON_POSITION;
  size?: BUTTON_SIZE;
  onClick: (fields: Field[]) => void;
}

type Props = {
  onClose: () => void;
  allFields: Field[];
  initialFields: Field[];
  title: string;
  onBackClick?: () => void;
  maxFieldConfig?: {
    count: number;
    hideText?: boolean;
  };
  actionButtons?: ActionButtonProps[];
};

const Header = styled.div`
  display: flex;
  padding: 32px;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  height: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Body = styled.div`
  padding: 32px;
  display: flex;
  max-width: 1064px;
  min-width: 1064px;
  min-height: 400px;
`;

export const TABLE_NAME_PREFIX = 'CovideoTableFields';
export const ModalCustomTableFields = ({
  onClose,
  allFields,
  initialFields,
  title,
  maxFieldConfig = { count: 100, hideText: true },
  actionButtons,
}: Props) => {
  return (
    <Modal style={{ position: 'fixed' }}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <CloseButtonWrap title={'Close'}>
          <CloseIcon
            onClick={onClose}
            width={24}
            height={24}
            color={theme.palette.label}
          />
        </CloseButtonWrap>
      </Header>
      <Body>
        <SelectFields
          allFields={allFields}
          initialFields={initialFields}
          scrollableHeight='480px'
          maxFieldConfig={maxFieldConfig}
          actionButtons={actionButtons}
        />
      </Body>
    </Modal>
  );
};
