import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RecordHome } from './recordHome';
import { RecordScreen } from './recordScreen';
import { RecordCam } from './recordCam';
import { RecordBoth } from './recordBoth';
import { getVideosCount } from 'lib/api';
import { useAuth } from 'lib/context';
import { NotFoundTemplate } from '../notFound';

export const RecordRoutes = () => {
  const { userData } = useAuth();
  const [userVideoCount, setUserVideoCount] = React.useState(0);
  const [isRecordAllowed, setRecordAllowed] = React.useState(true);

  const fetchUserVideoCount = async () => {
    const { count } = await getVideosCount();
    setUserVideoCount(count);
  };

  React.useEffect(() => {
    fetchUserVideoCount();
  }, []);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id != 0) {
        if (
          userPackageDetails.maxVideosCreated != 0 &&
          userVideoCount >= userPackageDetails.maxVideosCreated
        ) {
          setRecordAllowed(false);
        }
      }
    }
  }, [userData, userVideoCount]);
  return (
    <Switch>
      <Route
        path='/record/Record_Home'
        component={
          isRecordAllowed ? RecordHome : () => <Redirect to='/upgrade-plan' />
        }
      />
      <Route
        path='/record/Record_Screen'
        component={
          isRecordAllowed ? RecordScreen : () => <Redirect to='/upgrade-plan' />
        }
      />
      <Route
        path='/record/Record_Cam'
        component={
          isRecordAllowed ? RecordCam : () => <Redirect to='/upgrade-plan' />
        }
      />
      <Route
        path='/record/Record_Both'
        component={
          isRecordAllowed ? RecordBoth : () => <Redirect to='/upgrade-plan' />
        }
      />
      <Route path='*' component={NotFoundTemplate} />
    </Switch>
  );
};
