import styled from 'styled-components/macro';
import { ReactComponent as Svg } from './small-calendar.svg';

const SmallCalendarIcon = styled(Svg)`
  width: ${props => props.width || '12px'};
  height: ${props => props.height || '12px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || '0.5'};
  }
`;

export default SmallCalendarIcon;
