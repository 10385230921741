import { theme } from 'lib/style';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Select, { components } from 'react-select';
import { MdAdd, MdFolder } from 'react-icons/md';
import { HoverPopup } from 'lib/components';
import { CustomerHover } from 'app/pages/repairOrders/components';
import { trim } from 'lodash';
import { MultipleRecipientType, Recipient } from '../types';
import selectors from '../../../../../../../../cypress/selectors';
import { FaUser } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  padding: 24px;
  z-index: 2;
`;
const CardTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${theme.palette.covideoBlue100};
`;
const SelectedWrapper = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding ?? '4px 6px'};
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  color: ${theme.palette.primaryDarkBlue};
`;
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;
const OptionLabel = styled.div`
  margin-left: 12px;
  width: 700px;
  max-width: 100%;
`;
const CustomOptionLabel = styled.div`
  display: flex;
  justify-content: 'flex-end';
  width: 100%;
  align-items: center;
`;
const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Email = styled.div`
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;
const Phone = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
  width: 120px;
  margin-left: auto;
`;

const ExpandedContentWrapper = styled.div`
  margin-top: 8px;
`;

const ClearSelect = styled.div`
  margin-left: 6px;
  padding-top: 3px;
  cursor: pointer;
`;

const SelectedValueText = styled.div<{ isGroup?: boolean }>`
  font-weight: ${({ isGroup }) => (isGroup ? 600 : 400)};
  font-size: ${({ isGroup }) => (isGroup ? '14px' : '16px')};
  color: ${({ isGroup }) =>
    isGroup ? theme.palette.blue80 : theme.palette.blue100};
  line-height: 24px;
  display: flex;
  align-items: center;
  max-width: 700px;
  @media screen and (max-width: 800px) {
    max-width: 280px;
  }
`;

const GroupContactCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 4px;
  color: ${theme.palette.blue80};
`;

const selectStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    minHeight: '40px',
    boxShadow: 'none',
    borderColor: state.isFocused
      ? theme.palette.covideoBlue100
      : theme.palette.gray40,
    ':hover': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':focus': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':active': {
      borderColor: theme.palette.covideoBlue100,
    },
  }),
  option: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    color: theme.palette.gray100,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
  }),
  singleValue: (base: any) => ({
    ...base,
    position: 'static',
    width: 'auto',
    maxWidth: 'initial',
    transform: 'none',
  }),
  multiValue: (base: any) => ({
    ...base,
    position: 'static',
    width: 'auto',
    maxWidth: 'initial',
    transform: 'none',
    background: 'transparent',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    ':hover': {
      backgroundColor: 'transparent',
    },
    ':focus': {
      backgroundColor: 'transparent',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 4,
    overflow: 'show',
    gap: 4,
  }),
};

const createNew = {
  value: '0',
  label: 'Create New Customer',
  phone: '',
  name: '',
  type: MultipleRecipientType.CREATE_NEW,
  contactsCount: 0,
};

const filterOptions = (
  candidate: { label: string; value: string; data: any },
  input: string
) => {
  if (candidate.value === createNew.value) {
    return true;
  }
  const label = candidate.label.toLowerCase();
  const name = candidate.data.name ? candidate.data.name.toLowerCase() : '';
  const phone = candidate.data.phone ? candidate.data.phone.toLowerCase() : '';
  const haystack = `${label} ${name} ${phone}`;
  return haystack.includes(input.toLowerCase());
};

const Option = (props: any) => {
  return (
    <OptionWrapper
      data-cy={selectors.libraryPage.sendAndShareModal.recipientOption}
    >
      {props.value === '0' ? (
        <MdAdd color={theme.palette.blue40} />
      ) : props?.value && props.value.toString().includes('group-') ? (
        <MdFolder color={theme.palette.blue40} />
      ) : (
        <FaUser color={theme.palette.blue40} />
      )}
      <OptionLabel>
        <components.Option {...props} />
      </OptionLabel>
    </OptionWrapper>
  );
};

type OptionLabelProps = {
  label: string;
  phone: string;
  name: string;
};

type Props = {
  selectedRecipientIds: (string | number)[] | null;
  onChangeRecipient: (selectedRecipientIds: string[]) => void;
  recipients: Recipient[];
  expandedContent: ReactNode;
  showExpandedContent: boolean;
  createNewOption?: boolean;
  onInputChange: (inputValue: string) => void;
  multiple?: boolean;
  onMenuScrollToBottom: () => void;
  title: string;
};

export const RecipientSearch = ({
  selectedRecipientIds,
  onChangeRecipient,
  recipients,
  expandedContent,
  showExpandedContent,
  onInputChange,
  createNewOption = true,
  multiple = false,
  onMenuScrollToBottom,
  title,
}: Props) => {
  const ref = useRef(null);
  const [selectRef, setSelectRef] = useState<any>(ref);
  const [options, setOptions] = useState<any[]>([]);

  const handleRecipientChange = (option: any) => {
    if (!option) {
      onChangeRecipient([]);
      return;
    }
    if (!multiple) {
      onChangeRecipient([option.value]);
    }
    if (multiple) {
      onChangeRecipient(option.map((o: any) => o.value));
    }
  };

  const formatOptionLabel = ({ label, phone, name }: OptionLabelProps) => (
    <CustomOptionLabel>
      <Email>{label || '-'}</Email>
      <Phone>&nbsp;{phone || '-'}</Phone>
      <Name>&nbsp;{name.trim() || '-'}</Name>
    </CustomOptionLabel>
  );

  const SingleValue = ({ children, ...props }: any) => {
    let selectedValueText;
    if (trim(props.data.name)) {
      selectedValueText = props.data.name;
    } else if (props.data.label) {
      selectedValueText = props.data.label;
    } else if (props.data.phone) {
      selectedValueText = props.data.phone;
    } else {
      selectedValueText = children;
    }
    return (
      <SelectedWrapper>
        <HoverPopup
          position='bottom-left'
          popup={
            <CustomerHover
              firstName={trim(props.data.name)}
              lastName={''}
              email={props.data.label}
              phone={props.data.phone}
            />
          }
          hoverable={
            <>
              <components.SingleValue {...props}>
                <SelectedValueText>{selectedValueText}</SelectedValueText>
              </components.SingleValue>
            </>
          }
          disableHover={selectedRecipientIds?.includes('0')}
        />
        <ClearSelect onClick={() => selectRef.select.clearValue()}>
          <IoMdCloseCircle color={theme.palette.blue40} size={13} />
        </ClearSelect>
      </SelectedWrapper>
    );
  };

  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props}>
        <IoMdCloseCircle color={theme.palette.blue40} size={13} />
      </components.MultiValueRemove>
    );
  };

  const MultiValue = ({ children, ...props }: any) => {
    let selectedValueText;
    if (props.data.type === MultipleRecipientType.GROUP) {
      selectedValueText = props.data.label;
    } else if (trim(props.data.name)) {
      selectedValueText = props.data.name;
    } else if (props.data.label) {
      selectedValueText = props.data.label;
    } else if (props.data.phone) {
      selectedValueText = props.data.phone;
    } else {
      selectedValueText = children;
    }
    return (
      <SelectedWrapper padding='0'>
        <HoverPopup
          position='bottom-left'
          popup={
            <CustomerHover
              firstName={
                props.data.type === MultipleRecipientType.GROUP
                  ? ''
                  : trim(props.data.name)
              }
              lastName={''}
              email={
                props.data.type === MultipleRecipientType.GROUP
                  ? props.data.name
                  : props.data.label
              }
              phone={props.data.phone}
            />
          }
          hoverable={
            <>
              <components.MultiValue {...props}>
                <SelectedValueText
                  isGroup={props.data.type === MultipleRecipientType.GROUP}
                >
                  {props.data.type === MultipleRecipientType.GROUP && (
                    <MdFolder
                      size={22}
                      color={theme.palette.blue40}
                      style={{ marginRight: 6 }}
                    />
                  )}{' '}
                  {selectedValueText}
                  {props.data.type === MultipleRecipientType.GROUP && (
                    <GroupContactCount>
                      {props.data.contactsCount} contact
                      {props.data.contactsCount === 1 ? '' : 's'}
                    </GroupContactCount>
                  )}
                </SelectedValueText>
              </components.MultiValue>
            </>
          }
          disableHover={selectedRecipientIds?.includes('0')}
        />
      </SelectedWrapper>
    );
  };

  useEffect(() => {
    const recipientOptions = recipients.map(r => {
      const groupRecipients = r?.multipleRecipients || [];
      const multipleRecipientsPhone = groupRecipients.filter(
        groupRec => !!groupRec.phone
      );
      const multipleRecipientsEmail = groupRecipients.filter(
        groupRec => !!groupRec.email
      );
      return {
        value: r.id,
        label:
          r.type !== MultipleRecipientType.GROUP
            ? r.email || ''
            : `${r.name}` || '',
        type: r.type,
        contactsCount: groupRecipients.length,
        phone:
          r.type !== MultipleRecipientType.GROUP
            ? r.phone || ''
            : r.multipleRecipients
              ? `${multipleRecipientsPhone.length} SMS contact${
                  multipleRecipientsPhone.length !== 1 ? 's' : ''
                }`
              : '',
        name:
          r.type !== MultipleRecipientType.GROUP
            ? r.name || ''
            : r.multipleRecipients
              ? `${multipleRecipientsEmail.length} email contact${
                  multipleRecipientsEmail.length !== 1 ? 's' : ''
                }`
              : '',
      };
    });
    if (createNewOption) {
      recipientOptions.unshift(createNew);
    } else {
      recipientOptions.filter(option => option.value !== '0');
    }
    setOptions(recipientOptions);
  }, [recipients, createNewOption]);

  return (
    <Card data-cy={selectors.libraryPage.sendAndShareModal.recipientSearch}>
      <CardTitle>{title}</CardTitle>
      <Select
        ref={r => setSelectRef(r)}
        isMulti={multiple}
        components={{ Option, SingleValue, MultiValue, MultiValueRemove }}
        styles={selectStyle}
        options={options}
        value={options.filter(o => selectedRecipientIds?.includes(o.value))}
        onChange={handleRecipientChange}
        isSearchable={true}
        placeholder={
          createNewOption
            ? 'Search contacts or enter a new one...'
            : 'Search customers...'
        }
        filterOption={filterOptions}
        formatOptionLabel={formatOptionLabel}
        onInputChange={onInputChange}
        isDisabled={selectedRecipientIds?.includes('0')}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {showExpandedContent && selectedRecipientIds && (
        <ExpandedContentWrapper>{expandedContent}</ExpandedContentWrapper>
      )}
    </Card>
  );
};
