import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { createDepartment, mergeDepartment, updateDepartment } from 'lib/api';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #001b53;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  isMerge?: boolean;
  isEdit?: boolean;
  deptName?: string;
  selectedDept?: any;
  customerId?: any;
};

export const CreateDepartmentModal = (props: Props) => {
  const {
    handleModalClose,
    isMerge,
    selectedDept,
    customerId,
    deptName,
    isEdit,
  } = props;

  const [department, setDepartment] = React.useState(isEdit ? deptName : '');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const saveDepartment = async () => {
    setLoading(true);
    try {
      let createRes: any = null;
      if (!isEdit) {
        createRes = await createDepartment({ name: department });
        if (isMerge && selectedDept.length && customerId)
          await mergeDepartment(
            { from: selectedDept, to: createRes.data.id },
            customerId
          );
      } else {
        createRes = await updateDepartment(
          { name: department },
          selectedDept[0]
        );
      }

      setLoading(false);
      successToast({
        title: `Department ${
          isEdit ? 'updated' : isMerge ? 'merged' : 'added'
        } successfully!`,
        button: !isEdit
          ? {
              variant: 'primary',
              text: 'View',
              action: () => {
                history.push(`/users/department/${createRes.data.id}`);
              },
            }
          : undefined,
      });
      handleModalClose(true);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            {isMerge
              ? 'Name the Merged Department'
              : isEdit
                ? 'Edit Department Name'
                : 'Create New Department'}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <InputWrapper>
            {/* <Label>Department name</Label> */}
            <Input
              type='text'
              value={department}
              placeholder='Department Name'
              onChange={event => setDepartment(event.target.value)}
            />
          </InputWrapper>
        </Content>
        <Footer>
          <Button
            text={
              isMerge
                ? 'Merge and Create New'
                : isEdit
                  ? 'Save Changes'
                  : 'Create Department'
            }
            onClick={async () => {
              await saveDepartment();
            }}
            disabled={!department || loading}
          />
        </Footer>
      </div>
    </Modal>
  );
};
