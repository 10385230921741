import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { multilocationKeys } from './multilocationsKeys';

export interface Location {
  locationId?: number | null;
  parentCustomerId: number | null;
  customerId: number | null;
  name: string | null;
}

export interface User {
  id: number | string;
  customerId: number;
  parentUserId: number;
  parentCustomerId: number | null;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ChildCustomer {
  customerId: number;
  business: string;
  parentCustomerId: number;
  location: Location;
  users: User[];
}

export interface ParentCustomer {
  customerId: number | null;
  parentCustomerId: number;
  business: string | null;
  users: User[];
  location: Location;
  childCustomers: ChildCustomer[];
}

const getMultilocationConfiguration = async (customerId: string) => {
  return (
    await EXPRESS_API.get(
      `superadmin/multilocation/${customerId}/configuration`
    )
  ).data as ParentCustomer;
};

export const useGetSingleMultiLocation = (customerId: string) => {
  return useQuery(
    multilocationKeys.single(customerId),
    () => getMultilocationConfiguration(customerId),
    {
      refetchOnMount: true,
      enabled: !!customerId,
    }
  );
};
