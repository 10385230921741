import React from 'react';

import {
  Slider,
  Handles,
  Tracks,
  Rail,
  GetRailProps,
  GetHandleProps,
  GetTrackProps,
} from 'react-compound-slider';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import EyeIcon from 'lib/images/EyeIcon';

interface OpacitySliderProps {
  domain: number[];
  onChange?: (val: any) => void;
  onUpdate: (val: any) => void;
  initialValue: number;
}

interface OpacitySliderRailProps {
  getRailProps: GetRailProps;
}

type HandleObject = {
  id: string;
  value: number;
  percent: number;
};

type TrackObject = {
  percent: number;
};

interface OpacitySliderHandleProps {
  handle: HandleObject;
  domain: number[];
  getHandleProps: GetHandleProps;
  themes: any;
}

interface OuterHandleProps {
  percent?: number;
  backgroundColor?: string;
}

interface OuterTrackProps {
  source: TrackObject;
  target: TrackObject;
  backgroundColor?: string;
}

interface OpacityTrackProps {
  source: TrackObject;
  target: TrackObject;
  getTrackProps: GetTrackProps;
  themes: any;
}

const SliderContainer = styled.div`
  background-color: ${theme.palette.secondaryButtonBlueBg};
  border: 1px solid ${theme.palette.primaryDarkBlue10Percent};
  border-radius: 6px;
  height: 40px;
  width: 176px;
  display: flex;
  align-items: center;
  padding-left: 11px;
`;

const OuterRail = styled.div`
  position: absolute;
  transform: translate(0%, -50%);
  width: 100%;
  height: 4px;
  cursor: pointer;
`;

const InnerRail = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  transform: translate(0%, -50%);
  pointer-events: none;
  background-color: rgba(0, 27, 83, 0.15);
  border-radius: 2px;
`;

const OuterHandle = styled.div<OuterHandleProps>`
  left: ${props => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  --webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 24px;
  height: 42px;
  cursor: pointer;
  background-color: none;
`;

const InnerHandle = styled.div<OuterHandleProps>`
  left: ${props => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  background-color: ${props => props.backgroundColor};
  box-sizing: border-box;
`;

const OuterTrack = styled.div<OuterTrackProps>`
  position: absolute;
  transform: translate(0%, -50%);
  height: 4px;
  z-index: 1;
  background-color: ${props => props.backgroundColor};
  border-radius: 2px;
  cursor: pointer;
  left: ${props => props.source.percent}%;
  width: ${props => props.target.percent - props.source.percent}%;
`;

export const OpacitySlider = ({
  domain,
  onChange,
  onUpdate,
  initialValue,
}: OpacitySliderProps) => {
  const themes = useTheme();
  const OpacitySliderRail: React.FC<OpacitySliderRailProps> = ({
    getRailProps,
  }) => (
    <>
      <OuterRail {...getRailProps()} />
      <InnerRail />
    </>
  );

  const OpacityHandle = ({
    domain,
    handle,
    getHandleProps,
    themes,
  }: OpacitySliderHandleProps) => {
    const { id, percent, value } = handle;
    const min = domain[0];
    const max = domain[1];

    return (
      <>
        <OuterHandle
          percent={percent}
          {...getHandleProps(id)}
          backgroundColor={themes.colors.primary[100]}
        />
        <InnerHandle
          percent={percent}
          role='slider'
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          backgroundColor={themes.colors.primary[100]}
        />
      </>
    );
  };

  const OpacityTrack = ({
    source,
    target,
    getTrackProps,
    themes,
  }: OpacityTrackProps) => {
    return (
      <OuterTrack
        source={source}
        target={target}
        backgroundColor={themes.colors.primary[100]}
        {...getTrackProps()}
      />
    );
  };

  return (
    <SliderContainer>
      <EyeIcon height='12px' color={theme.palette.blue100}></EyeIcon>
      <Slider
        step={1}
        domain={domain}
        rootStyle={{
          width: `calc(100% - ${42}px)`,
          touchAction: 'none',
          position: 'relative',
          left: '10px',
        }}
        onUpdate={onUpdate}
        onChange={onChange}
        values={[initialValue]}
      >
        <Rail>
          {({ getRailProps }) => (
            <OpacitySliderRail getRailProps={getRailProps} />
          )}
        </Rail>

        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <OpacityHandle
                  themes={themes}
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>

        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <OpacityTrack
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  themes={themes}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </SliderContainer>
  );
};
