import React, { useEffect, useState } from 'react';
import { Container, MainContainer, MainWrapper } from '../../index.styled';
import SaveIcon from 'lib/images/SaveIcon';
import { TextInput } from 'lib/components';
import { Breadcrumbs } from 'app/pages/reports/components/Breadcrumbs';
import {
  useGetSingleOrganization,
  useCreateOrganization,
  useUpdateOrganization,
} from 'lib/api/superAdminApi';
import { useHistory, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import {
  Header,
  HeaderContainer,
  Row,
  Tab,
  TabContainer,
  TextInputContainer,
  RowTitle,
  Label,
  AdminContainer,
  AdminTitle,
  SelectInputContainer,
} from '../styles';
import { SuperAdminUser } from 'lib/context';
import {
  IUserOrganizationAccess,
  IOrganizationRegion,
} from 'lib/api/organizations/types';
import { ADMIN_ACCESS } from '../const';
import RegionCard from '../components/RegionCard';
import ConfigureAccessModal from '../components/ConfigureAccessModal';
import SuperAdminSearchDropdown from '../../components/SuperAdminSearchDropdown';
import AdminCard from '../components/AdminCard';
import { Button } from 'react-covideo-common';

type OrganizationInfo = {
  name: string;
  userOrganizationAccesses: Partial<IUserOrganizationAccess>[];
  organizationRegions: Partial<IOrganizationRegion>[];
};

const ORGANIZATION_TABS = {
  DETAILS: 'Details',
  REGIONS: 'Regions',
  ADMINS: 'Admins',
};

const initialOrgInfo = {
  name: '',
  userOrganizationAccesses: [] as Partial<IUserOrganizationAccess>[],
  organizationRegions: [] as Partial<IOrganizationRegion>[],
};

type SelectedAdmin = {
  name: string;
  accessLevel?: string;
  isNewAdmin?: boolean;
  userAccessId?: number;
  username: string;
  userId: number;
};

const Details = () => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isAddRegionDisabled, setIsAddRegionDisabled] = useState(true);
  const [isConfigureModalOpen, setIsConfigureModalOpen] = useState('');

  const [activeTab, setActiveTab] = useState(ORGANIZATION_TABS.DETAILS);
  const [organizationDetails, setOrganizationDetails] =
    useState<OrganizationInfo>(initialOrgInfo);
  const [selectedAdmins, setSelectedAdmins] = useState<SelectedAdmin[]>([]);
  const [newRegion, setNewRegion] = useState('');
  const [organizationId, setOrganizationId] = useState(
    useParams<{ id: string }>().id
  );

  const isDetailsTab = activeTab === ORGANIZATION_TABS.DETAILS;
  const isAdminsTab = activeTab === ORGANIZATION_TABS.ADMINS;
  const isRegionsTab = activeTab === ORGANIZATION_TABS.REGIONS;

  const isNew = organizationId === 'add';

  const { data: organization } = useGetSingleOrganization(
    Number(organizationId) || 0
  );

  // creating/editing organization
  const { mutateAsync: createOrganization, isLoading: isCreating } =
    useCreateOrganization();
  const { mutateAsync: updateOrganization, isLoading: isUpdating } =
    useUpdateOrganization(Number(organizationId) || 0);

  const history = useHistory();

  const BREADCRUMBS = [
    {
      link: '/admin/organizations',
      label: 'Organizations',
    },
    {
      link: undefined,
      label: isNew ? 'Create New' : organization?.name || '',
    },
  ];

  useEffect(() => {
    validateInputs();
  }, [organizationDetails, selectedAdmins, isUpdating, isCreating]);

  useEffect(() => {
    if (organization && !!Object.keys(organization).length) {
      if (!!organization.userOrganizationAccesses.length) {
        const organizationAdmins = organization.userOrganizationAccesses.map(
          (admin: IUserOrganizationAccess) => {
            return {
              name: `${admin?.user?.firstName} ${admin?.user?.lastName}`,
              userId: admin.userId,
              accessLevel: getUserAccessLevel(admin),
              creatingOrganization: isNew,
              isNewAdmin: false,
              username: admin?.user?.username || '',
            };
          }
        );
        setSelectedAdmins(organizationAdmins);
      }

      const orgObject = {
        name: organization.name,
        userOrganizationAccesses: organization.userOrganizationAccesses,
        organizationRegions: organization.organizationRegions,
      };
      setOrganizationDetails(orgObject);
      setOrganizationId(organization.organizationId.toString());
    }
  }, [organization]);

  useEffect(() => {
    const exists = organizationDetails.organizationRegions.some(
      (region: Partial<IOrganizationRegion>) =>
        region?.name?.toLowerCase() === newRegion.toLowerCase()
    );
    if (exists) {
      setIsAddRegionDisabled(true);
      return;
    }

    setIsAddRegionDisabled(!newRegion.length);
  }, [newRegion]);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setOrganizationDetails(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const updateOrganizationInfo = async () => {
    let data = { ...organizationDetails };

    const currentAccessList = data.userOrganizationAccesses;
    const newAccessList = selectedAdmins;

    const userOrganizationAccesses = newAccessList.map(
      (newAdmin: SelectedAdmin) => {
        let id;
        let enableSignInAs = false;
        let limitToRegions = [] as number[];

        const exists = currentAccessList.find(
          (admin: Partial<IUserOrganizationAccess>) =>
            admin.userId === newAdmin.userId
        );
        if (exists) {
          id = exists.id;
          enableSignInAs = !!exists.enableSignInAs;
          limitToRegions = exists.limitToRegions || [];
        }

        return {
          organizationId: Number(organizationId),
          userId: newAdmin.userId,
          limitToRegions,
          enableSignInAs,
          id,
        } as IUserOrganizationAccess;
      }
    );

    data.userOrganizationAccesses = userOrganizationAccesses;

    data.organizationRegions = data.organizationRegions.map(
      (region: Partial<IOrganizationRegion>) => {
        if (
          !!region.organizationRegionId &&
          region.organizationRegionId.toString().includes('new')
        ) {
          delete region.organizationRegionId;
        }
        return region;
      }
    );

    await updateOrganization({
      organizationId: Number(organizationId),
      data,
    });
  };

  const createNewOrganization = async () => {
    let data = {
      ...organizationDetails,
      userOrganizationAccesses: [] as Partial<IUserOrganizationAccess>[],
    };

    const userOrganizationAccesses = selectedAdmins.map(
      (admin: SelectedAdmin) => ({
        userId: admin.userId,
        enableSignInAs: false,
        limitToRegions: [],
      })
    ) as Partial<IUserOrganizationAccess>[];

    data.userOrganizationAccesses = userOrganizationAccesses;

    data.organizationRegions = data.organizationRegions.map(
      (region: Partial<IOrganizationRegion>) => {
        if (
          !!region.organizationRegionId &&
          region.organizationRegionId.toString().includes('new')
        ) {
          delete region.organizationRegionId;
        }
        return region;
      }
    );

    await createOrganization(data);
    history.push('/admin/organizations');
  };

  const validateInputs = () => {
    const isNameEmpty = !organizationDetails.name.length;

    if (isNameEmpty) {
      setIsSaveDisabled(true);
      return false;
    }
    if (isUpdating || isCreating) {
      setIsSaveDisabled(true);
      return;
    }

    setIsSaveDisabled(false);
  };

  const updateAdminList = (user: SuperAdminUser) => {
    // check if already added
    const added = selectedAdmins.some(
      (admin: SelectedAdmin) => admin.userId === user.id
    );

    if (added) {
      return;
    }

    setSelectedAdmins([
      ...selectedAdmins,
      {
        userId: user.id,
        name: `${user.firstName} ${user.lastName}`,
        username: user.username || '',
        accessLevel: '',
        isNewAdmin: true,
      },
    ]);
  };

  const onRemoveAdmin = (userId: number) => {
    // remove from selected
    setSelectedAdmins(prevSelectedAdmins =>
      prevSelectedAdmins.filter(admin => admin.userId !== userId)
    );
  };

  const getUserAccessLevel = (admin: IUserOrganizationAccess) => {
    if (isNew) return '';
    return admin.enableSignInAs &&
      (!admin.limitToRegions?.length ||
        admin.limitToRegions?.length ===
          organizationDetails.organizationRegions.length)
      ? ADMIN_ACCESS.FULL
      : ADMIN_ACCESS.PARTIAL;
  };

  const addRegion = () => {
    setOrganizationDetails(prev => ({
      ...prev,
      organizationRegions: [
        ...prev.organizationRegions,
        { name: newRegion, organizationRegionId: `new-${uuidv4()}` },
      ],
    }));
    setNewRegion('');
  };

  const removeRegion = (id: string) => {
    setOrganizationDetails(prev => ({
      ...prev,
      organizationRegions: organizationDetails.organizationRegions.filter(
        (region: Partial<IOrganizationRegion>) =>
          region?.organizationRegionId?.toString() !== id
      ),
    }));
  };

  return (
    <MainContainer style={{ height: '100vh', justifyContent: 'start' }}>
      <MainWrapper>
        <Container style={{ overflowX: 'unset' }}>
          <Breadcrumbs crumbs={BREADCRUMBS}></Breadcrumbs>
          <HeaderContainer>
            <Header>{isNew ? 'New Organization' : organization?.name}</Header>
            <Button
              text='Save Organization'
              onClick={() =>
                isNew ? createNewOrganization() : updateOrganizationInfo()
              }
              icon={<SaveIcon color='white' height={15} />}
              disabled={isSaveDisabled}
            />
          </HeaderContainer>

          <TabContainer>
            {Object.values(ORGANIZATION_TABS).map(tab => (
              <Tab
                key={tab}
                active={tab === activeTab}
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                {tab}
              </Tab>
            ))}
          </TabContainer>

          {isDetailsTab && (
            <div>
              <Row>
                <RowTitle>Organization Info</RowTitle>
                <Label>Name</Label>
                <TextInputContainer>
                  <TextInput
                    name='name'
                    onChange={onTextChange}
                    value={organizationDetails.name || ''}
                    maxLength={95}
                  />
                </TextInputContainer>
              </Row>
            </div>
          )}
          {isAdminsTab && (
            <div>
              <Row>
                <RowTitle>Organization Admins</RowTitle>
                <SelectInputContainer>
                  <SuperAdminSearchDropdown onSelectUser={updateAdminList} />
                  <AdminContainer>
                    {!!selectedAdmins.length && <AdminTitle>Admin</AdminTitle>}
                    {selectedAdmins.map((admin: SelectedAdmin) => (
                      <AdminCard
                        key={admin.userId}
                        name={admin.name}
                        accessLevel={admin.accessLevel}
                        username={admin.username}
                        creatingOrganization={isNew}
                        isNewAdmin={admin.isNewAdmin}
                        onClick={
                          isNew || admin.isNewAdmin
                            ? () => onRemoveAdmin(admin.userId)
                            : () =>
                                setIsConfigureModalOpen(admin.userId.toString())
                        }
                      />
                    ))}
                  </AdminContainer>
                </SelectInputContainer>
              </Row>
            </div>
          )}

          {isRegionsTab && (
            <div>
              <Row style={{ marginBottom: '32px', padding: 0 }}>
                <RowTitle>Enter New Region</RowTitle>
                <TextInputContainer style={{ display: 'flex' }}>
                  <TextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setNewRegion(e.target.value)
                    }
                    value={newRegion}
                    maxLength={95}
                    placeholder='Enter New Region Name'
                    style={{ boxSizing: 'border-box', height: '40px' }}
                  />
                  <Button
                    text='Create'
                    variant='secondary'
                    onClick={addRegion}
                    disabled={isAddRegionDisabled}
                  />
                </TextInputContainer>
              </Row>

              {!!organizationDetails.organizationRegions.length && (
                <Row style={{ padding: 0 }}>
                  <RowTitle>Regions</RowTitle>
                  <TextInputContainer>
                    {organizationDetails.organizationRegions.map(
                      (region: Partial<IOrganizationRegion>) => (
                        <div
                          style={{ marginBottom: '16px' }}
                          key={`${region?.organizationRegionId}-${region?.name}`}
                        >
                          <RegionCard
                            name={region.name || ''}
                            onRemove={() =>
                              removeRegion(
                                region?.organizationRegionId?.toString() || ''
                              )
                            }
                          />
                        </div>
                      )
                    )}
                  </TextInputContainer>
                </Row>
              )}
            </div>
          )}
        </Container>
      </MainWrapper>
      {!!isConfigureModalOpen.length && (
        <ConfigureAccessModal
          organizationRegions={organizationDetails.organizationRegions}
          handleModalClose={() => setIsConfigureModalOpen('')}
          userAccessInfo={
            organizationDetails?.userOrganizationAccesses.find(
              (user: Partial<IUserOrganizationAccess>) =>
                user?.userId?.toString() === isConfigureModalOpen
            ) || {}
          }
          organizationId={Number(organizationId)}
          organizationName={organizationDetails.name}
        />
      )}
    </MainContainer>
  );
};

export default Details;
