import React from 'react';
import { FieldArray } from 'formik';
import {
  IQuickFilterQuery,
  IQuickFilterRule,
} from 'lib/api/quickFilters/getQuickFilters';
import { Dropdown } from 'lib/components';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import AddIcon from 'lib/images/AddIcon';
import DeleteIcon from 'lib/images/DeleteIcon';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import {
  getRuleOptions,
  getRulesValuesOptions,
  getInitialValues,
} from './utils';
import { Gap } from 'lib/components/styles/layout';
import { IQuickQueryValues } from './QuickFiltersBuilder';
import { Button } from 'react-covideo-common';

interface Option {
  value: string;
  label: string;
}

const query_by: Option[] = [
  { value: 'engagement_rate', label: 'Engagement Rate' },
  { value: 'cta_clicks', label: 'CTA Clicks' },
  { value: 'lead_source', label: 'Lead Source' },
];

const conditions: Option[] = [
  { label: 'AND', value: '$and' },
  { label: 'OR', value: '$or' },
];

const GroupWrapper = styled.div`
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 6px;
  background: white;
  padding: 16px 24px;
`;

const RemoveLinkBtnWrapper = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #fef6f5;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(232, 76, 61, 0.1);
  border-radius: 6px;
`;

const GroupItem = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  background: white;
`;

interface Props {
  root: keyof IQuickFilterQuery;
  index: number;
  ruleArray: IQuickFilterRule[];
  queryKey: string;
  values: IQuickQueryValues;
  remove: <T>(index: number) => T | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  INITIAL_RULE: IQuickFilterRule;
}

export const QuickFilterItem = ({
  root,
  index,
  ruleArray,
  queryKey,
  values,
  remove,
  setFieldValue,
  INITIAL_RULE,
}: Props) => {
  return (
    <GroupWrapper>
      <FieldArray name={`rules[${root}][${index}][${queryKey}]`}>
        {arrayHelpers => {
          return (
            <>
              {ruleArray.map((currentValue: IQuickFilterRule, idx: number) => {
                const fieldValue = `rules[${root}][${index}][${queryKey}][${idx}]`;

                const [ruleKey] = Object.keys(currentValue || {});

                //grab inner key of of query obj
                const [ruleValuesKey] = Object.keys(
                  currentValue?.[ruleKey] || {}
                );

                //grab inner value of of query obj
                const [rulesValues] = Object.values(
                  currentValue?.[ruleKey] || {}
                );

                const rules = getRuleOptions(ruleKey);
                const rules_values = getRulesValuesOptions(ruleKey);

                const INITIAL_CONTITIONALS_OPTION = getInitialValues(
                  conditions,
                  queryKey
                );
                const INITIAL_QUERY_BY_OPTION = getInitialValues(
                  query_by,
                  ruleKey
                );
                const INITIAL_RULES_OPTION = getInitialValues(
                  rules,
                  ruleValuesKey
                );
                const INITIAL_RULES_VALUES_OPTIONS = getInitialValues(
                  rules_values,
                  rulesValues
                );

                return (
                  <React.Fragment key={`${idx}_${ruleKey}`}>
                    <GroupItem
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      {idx === 0 ? (
                        <Gap alignItems='center'>
                          <ParagraphSmallBold
                            color={theme.palette.covideoBlue100}
                            textAlign='right'
                            style={{
                              width: 80,
                            }}
                          >
                            Where
                          </ParagraphSmallBold>
                        </Gap>
                      ) : (
                        <Dropdown
                          options={conditions}
                          value={INITIAL_CONTITIONALS_OPTION}
                          menuPortalTarget={document.body}
                          menuPosition='fixed'
                          menuPlacement='bottom'
                          width={80}
                          onChange={(e: Option) => {
                            const conditionValue =
                              values.rules?.[root]?.[index][queryKey] || [];
                            return setFieldValue(`rules[${root}][${index}]`, {
                              [e.value]: conditionValue,
                            });
                          }}
                        />
                      )}
                      <Dropdown
                        options={query_by}
                        value={INITIAL_QUERY_BY_OPTION}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        menuPlacement='bottom'
                        onChange={(e: Option) => {
                          //grab new dropdown values
                          const rule = getRuleOptions(e.value);
                          const ruleValue = getRulesValuesOptions(e.value);

                          const ruleobj = {
                            [rule[0].value]: ruleValue[0].value,
                          };

                          setFieldValue(`${fieldValue}`, {
                            [e.value]: ruleobj,
                          });
                        }}
                        width={260}
                      />

                      <Dropdown
                        options={rules}
                        value={INITIAL_RULES_OPTION}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        menuPlacement='bottom'
                        onChange={(e: Option) => {
                          setFieldValue(`${fieldValue}[${ruleKey}]`, {
                            [e.value]: rulesValues || rules_values[0].value,
                          });
                        }}
                        width={210}
                      />

                      <Dropdown
                        options={rules_values}
                        value={INITIAL_RULES_VALUES_OPTIONS}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        menuPlacement='bottom'
                        onChange={(e: Option) => {
                          setFieldValue(
                            `${fieldValue}[${ruleKey}][${ruleValuesKey}]`,
                            e.value
                          );
                        }}
                        width={210}
                        placeholder='select value'
                        disabled={!ruleValuesKey}
                      />

                      {!(index === 0 && idx === 0) && (
                        <RemoveLinkBtnWrapper
                          onClick={() => {
                            if (index === 1 && idx === 0) {
                              arrayHelpers.remove(idx);
                              remove(index);
                              return;
                            }
                            arrayHelpers.remove(idx);
                          }}
                        >
                          <DeleteIcon color={theme.palette.buttonDelete} />
                        </RemoveLinkBtnWrapper>
                      )}
                    </GroupItem>
                  </React.Fragment>
                );
              })}
              <Button
                variant='secondary'
                icon={<AddIcon height={21} width={21} />}
                text={'New rule'}
                disabled={values?.rules?.[root]?.[index][queryKey].length === 2}
                onClick={() => arrayHelpers.push(INITIAL_RULE)}
              />
            </>
          );
        }}
      </FieldArray>
    </GroupWrapper>
  );
};
