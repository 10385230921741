import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import ReverseLeftRightIcon from '../../../../../../lib/images/ReverseLeftRight';
import { ModalEmailBatchRecipients } from '../../../../../../lib/components/modal';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from '../../../../../../lib/components';
import dayjs from 'dayjs';
import { ModalEmailBatchResend } from '../../../../../../lib/components/modal';
import DuplicateIcon from '../../../../../../lib/images/DuplicateIcon';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { STANDARD_DATE_FORMAT } from '../../../../../../lib/const/DateFormat';

interface Props {
  emailBatches: any[];
  count: number;
  onPaginationChange: any;
  sort?: any;
  setSort?: any;
  loadEmailBatches: () => void;
}

const PaginationWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeff2;
  padding: 16px 0;
`;

const HeaderTitle = styled.div`
  width: 14%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  box-sizing: border-box;
`;

const EmailBatchItem = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeff2;
  cursor: pointer;
  &:hover {
    background-color: ${theme.palette.lightgray40};
  }
`;

const EmailBatchValue = styled.div`
  width: 14%;
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  padding: 16px 0;
  display: flex;
  div {
    margin-left: 24px;
  }
`;

const Actions = styled.div`
  display: flex;
  padding-top: 8px;
`;

const DefaultButton = styled.div`
  min-width: 20px;
  margin-left: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.lightgray40};
  color: ${theme.palette.black_1_100};
  height: 40px;
  width: 46px;
  border-radius: 4px;
  cursor: pointer;
  transition: width 0.2s ease;
  overflow: hidden;
  &:hover {
    background-color: ${theme.palette.lightgray};
  }
  ${theme.mediaQueryMinWidth.md} {
    margin-left: 12px;
    margin-right: 0;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
    div {
      display: block;
      padding: 8px;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  color: ${theme.palette.coal};
  z-index: 90;
  width: 300px;
  left: -200px;
  top: -50px;

  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.08);
  border: solid 1px rgb(247, 248, 250);
`;

const SortCell = styled.div`
  display: inline;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

const BatchesList = ({
  emailBatches,
  count,
  onPaginationChange,
  sort,
  setSort,
  loadEmailBatches,
}: Props) => {
  const [selectedEmailBatch, setSelectedEmailBatch] = useState(null as any);
  const [resendEmailBatch, setResendEmailBatch] = useState(null as any);

  const handleCloseResend = () => {
    setResendEmailBatch(null);
    onPaginationChange({ page: 0, size: 10 });
    loadEmailBatches();
  };

  return (
    <div>
      <Header>
        <HeaderTitle style={{ paddingLeft: '24px', width: '30%' }}>
          Subject
        </HeaderTitle>
        <HeaderTitle style={{ width: '8%' }}>Recipients</HeaderTitle>
        <HeaderTitle style={{ width: '8%' }}>DealerID</HeaderTitle>
        <HeaderTitle style={{ width: '8%' }}>MessageID</HeaderTitle>
        <HeaderTitle style={{ width: '20%' }}>Customer</HeaderTitle>
        <HeaderTitle style={{ width: '20%' }}>
          Date Sent
          <SortCell>
            <IoMdArrowRoundUp
              className={sort == `ASC` ? 'active' : ''}
              onClick={() => setSort('ASC')}
            />
            <IoMdArrowRoundDown
              className={sort == `DESC` ? 'active' : ''}
              onClick={() => setSort('DESC')}
            />
          </SortCell>
        </HeaderTitle>
      </Header>
      <TableContextProvider total={count} onChange={onPaginationChange}>
        <div>
          {emailBatches.map(emailBatch => {
            return (
              <EmailBatchItem
                key={emailBatch.batchId}
                onClick={() =>
                  selectedEmailBatch === emailBatch
                    ? setSelectedEmailBatch(null)
                    : setSelectedEmailBatch(emailBatch)
                }
              >
                <EmailBatchValue style={{ width: '30%' }}>
                  <div
                    style={{
                      maxWidth: '250px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {emailBatch.subject}
                  </div>
                </EmailBatchValue>
                <EmailBatchValue style={{ width: '8%' }}>
                  {emailBatch.recipients.length || 0}
                </EmailBatchValue>
                <EmailBatchValue style={{ width: '8%' }}>
                  {emailBatch.dealerId}
                </EmailBatchValue>
                <EmailBatchValue style={{ width: '8%' }}>
                  {emailBatch.messageId}
                </EmailBatchValue>
                <EmailBatchValue style={{ width: '20%' }}>
                  <div
                    style={{
                      marginLeft: '0',
                      maxWidth: '150px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {emailBatch.customer?.business}
                  </div>
                </EmailBatchValue>
                <EmailBatchValue style={{ width: '20%' }}>
                  {dayjs(emailBatch.sentAt).format(
                    `${STANDARD_DATE_FORMAT} HH:mm A`
                  )}
                </EmailBatchValue>
                <Actions>
                  <DefaultButton
                    title='Resend'
                    onClick={e => {
                      e.stopPropagation();
                      setResendEmailBatch(emailBatch);
                    }}
                  >
                    <ReverseLeftRightIcon color={theme.palette.black_1_100} />
                  </DefaultButton>
                  {!!emailBatch.resend && (
                    <TooltipWrapper>
                      <Tooltip>This email batch was a resend.</Tooltip>
                      <DuplicateIcon
                        width='26px'
                        height='26px'
                        style={{
                          marginTop: '6px',
                          marginLeft: '6px',
                          color: '#39C16C',
                          opacity: '0.7',
                        }}
                      />
                    </TooltipWrapper>
                  )}
                </Actions>
              </EmailBatchItem>
            );
          })}
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
      {selectedEmailBatch && (
        <ModalEmailBatchRecipients
          emailBatch={selectedEmailBatch}
          handleModalClose={() => setSelectedEmailBatch(null)}
        />
      )}
      {resendEmailBatch && (
        <ModalEmailBatchResend
          emailBatch={resendEmailBatch}
          handleModalClose={handleCloseResend}
        />
      )}
    </div>
  );
};

export const EmailBatchesList = React.memo(BatchesList);
