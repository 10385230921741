import React from 'react';
import { CustomReportBodyWrapper } from '../styles';
import { reportData } from 'lib/const';
import { ICombinedUserData, useAuth } from 'lib/context';
import { VIDEO_ATTRIBUTES } from 'lib/utils/videoAttributes';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../types';
import { SelectFields } from 'lib/components/selectFields/SelectFields';
import { groupBy } from 'lodash';
import { REPORT_GROUP_TYPE } from 'lib/const/ReportData';
import { SelectField } from 'lib/components/selectFields/types';

const getAllReports = (userData: ICombinedUserData): SelectField[] =>
  Object.keys(reportData)
    .filter(
      reportValue =>
        !reportData[reportValue].isAutomotive ||
        (!!userData.isAutomotive && !!reportData[reportValue].isAutomotive)
    )
    .map(reportValue => {
      const isVideoAttributes = reportValue.includes(VIDEO_ATTRIBUTES);
      if (isVideoAttributes)
        return {
          value: 'video-attributes~~details',
          label: reportData[reportValue]?.label,
          description: reportData[reportValue]?.description || '',
          group: reportData[reportValue]?.group || '',
        };

      return {
        value: reportValue,
        label: reportData[reportValue].label,
        description: reportData[reportValue]?.description || '',
        group: reportData[reportValue]?.group || '',
      };
    });

export const CustomModalReportDataStep = () => {
  const { userData } = useAuth();
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  const allReports = getAllReports(userData);

  const onAddClick = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const onRemoveClick = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const onDragItemHandler = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const selectedFields = allReports.filter(item =>
    values?.reports?.includes(item.value)
  );

  const groupedFields = groupBy(allReports, 'group') as Record<
    REPORT_GROUP_TYPE,
    SelectField[]
  >;

  return (
    <CustomReportBodyWrapper>
      <SelectFields
        allFields={groupedFields}
        initialFields={selectedFields}
        onRemoveClick={onRemoveClick}
        onAddClick={onAddClick}
        onDragItem={onDragItemHandler}
        scrollableHeight='580px'
      />
    </CustomReportBodyWrapper>
  );
};
