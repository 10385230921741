import React from 'react';
import { FaBan } from 'react-icons/fa';
import { MdAddCircle, MdCheck } from 'react-icons/md';
import styled, { useTheme } from 'styled-components/macro';
import { VirtualBackgroundLibraryModal } from './VirtualBackgroundLibraryModal';
import vb1 from 'lib/images/virtualBackgrounds/vb1.jpeg';
import vb2 from 'lib/images/virtualBackgrounds/vb2.jpeg';
import vb3 from 'lib/images/virtualBackgrounds/vb3.jpeg';
import vb4 from 'lib/images/virtualBackgrounds/vb4.jpeg';
import vb5 from 'lib/images/virtualBackgrounds/vb5.jpeg';
import vb6 from 'lib/images/virtualBackgrounds/vb6.jpeg';
import vb7 from 'lib/images/virtualBackgrounds/vb7.jpeg';
import vb8 from 'lib/images/virtualBackgrounds/vb8.jpeg';
import vb9 from 'lib/images/virtualBackgrounds/vb9.jpeg';
import vb10 from 'lib/images/virtualBackgrounds/vb10.jpeg';
import vb11 from 'lib/images/virtualBackgrounds/vb11.jpeg';
import vb12 from 'lib/images/virtualBackgrounds/vb12.jpeg';
import vb13 from 'lib/images/virtualBackgrounds/vb13.jpeg';
import vb14 from 'lib/images/virtualBackgrounds/vb14.jpeg';
import vb15 from 'lib/images/virtualBackgrounds/vb15.jpeg';
import vb16 from 'lib/images/virtualBackgrounds/vb16.jpeg';
import vb17 from 'lib/images/virtualBackgrounds/vb17.jpeg';
import vb18 from 'lib/images/virtualBackgrounds/vb18.jpeg';
import vb19 from 'lib/images/virtualBackgrounds/vb19.jpeg';
import vb20 from 'lib/images/virtualBackgrounds/vb20.jpeg';
import vb21 from 'lib/images/virtualBackgrounds/vb21.jpeg';
import { useAuth } from 'lib/context';
import { ICustomVirtualBackground } from 'lib/api/customVirtualBackground/useCustomVirtualBackgroundQuery';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #f6f7f9;
  border-radius: 5px;
  padding: 15px;
  flex-direction: column;
  box-sizing: border-box;
  .selected {
    border: 3px solid #001b53 !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.div`
  height: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4e5461;
`;

const GridContainer = styled.div`
  display: flex;
  width: 100%;
  height: 278px;
  box-sizing: border-box;
  overflow-y: scroll;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16.5px;
  grid-template-columns: 180px 180px 180px 180px;
`;

type GridItemProps = {
  backgroundUrl?: string;
};
const GridItem = styled.div<GridItemProps>`
  background: url('${props =>
    props.backgroundUrl ? props.backgroundUrl : ''}');
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100px;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(39, 42, 50, 0.1);
  box-sizing: border-box;
`;

export const VirtualBackgroundImages = [
  vb1,
  vb2,
  vb3,
  vb4,
  vb5,
  vb6,
  vb7,
  vb8,
  vb9,
  vb10,
  vb11,
  vb12,
  vb13,
  vb14,
  vb15,
  vb16,
  vb17,
  vb18,
  vb19,
  vb20,
  vb21,
];

const CUSTOM_BACKGROUND_BUCKET_URL =
  'https://cv-custom-virtual-backgrounds.s3.amazonaws.com/';

interface Props {
  onSelect: any;
  onMainButtonClick: any;
  showVideoLoader?: any;
  setShowVideoLoader?: any;
  selectedVirtualBackgroundUrl?: any;
  customVirtualBackgrounds?: ICustomVirtualBackground[];
}
export const VirtualBackgrounds = ({
  onSelect,
  onMainButtonClick,
  selectedVirtualBackgroundUrl,
  customVirtualBackgrounds,
}: Props) => {
  const [showLibraryModal, setShowLibraryModal] = React.useState(false);
  const { userData } = useAuth();
  const themes = useTheme();
  const customVbUrls = customVirtualBackgrounds?.map(vb => vb.url) || [];

  const virtualBackgrounds = [...customVbUrls, ...VirtualBackgroundImages];

  return (
    <>
      {showLibraryModal && (
        <VirtualBackgroundLibraryModal
          selectedVirtualBackgroundUrl={selectedVirtualBackgroundUrl}
          onSelect={onSelect}
          handleModalClose={() => {
            setShowLibraryModal(false);
          }}
        />
      )}
      <Container>
        <Header>
          <Title>Select a virtual background</Title>
          <Button
            variant='primary'
            text={'Done'}
            icon={<MdCheck size={24} />}
            onClick={onMainButtonClick}
          />
        </Header>
        <GridContainer>
          <Grid>
            <GridItem
              onClick={() => {
                onSelect('', 'none', -1);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  color: '#001B53',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                  width: '180px',
                  fontSize: '34px',
                  borderRadius: '3px',
                  boxSizing: 'border-box',
                }}
                className={
                  selectedVirtualBackgroundUrl === '' ? 'selected' : ''
                }
              >
                <FaBan />
              </div>
            </GridItem>
            {userData.droplrAccess && (
              <GridItem
                onClick={() => {
                  setShowLibraryModal(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#FF8B22',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                    width: '180px',
                    fontSize: '28px',
                    borderRadius: '3px',
                    boxSizing: 'border-box',
                  }}
                >
                  <MdAddCircle color={themes.colors.primary[100]} />
                  <div
                    style={{
                      marginTop: '5px',
                      width: 'auto',
                      display: 'flex',
                      color: themes.colors.primary[100],
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'auto',
                      fontSize: '14px',
                      boxSizing: 'border-box',
                    }}
                  >
                    Select your own
                  </div>
                </div>
              </GridItem>
            )}
            {virtualBackgrounds.map((image: string, index: number) => {
              return (
                <GridItem
                  key={image + index}
                  backgroundUrl={image}
                  className={
                    selectedVirtualBackgroundUrl === image ? 'selected' : ''
                  }
                  onClick={() => {
                    if (image.includes(CUSTOM_BACKGROUND_BUCKET_URL)) {
                      onSelect(image, 'custom', -1);
                      return;
                    }
                    onSelect(image, 'default', index);
                  }}
                ></GridItem>
              );
            })}
          </Grid>
        </GridContainer>
      </Container>
    </>
  );
};
