import React from 'react';
import styled from 'styled-components/macro';
import { Heading } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { MdEmail } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 48px;
`;

type Props = {
  loading: boolean;
  onDownloadCsv(): void;
};

export const UsageReportsHeader = ({ loading, onDownloadCsv }: Props) => {
  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>Usage Reports</Heading>
      </LeftHeaderWrapper>
      <div>
        <Button
          onClick={onDownloadCsv}
          text='Send Report'
          disabled={loading}
          icon={<MdEmail color={theme.palette.gray100} />}
          variant='secondary'
        />
      </div>
    </HeaderWrapper>
  );
};
