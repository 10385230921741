import React from 'react';
import {
  FieldContainer,
  FieldLabel,
  Red,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
} from '../../../components/AdminFormComponents';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components/macro';
import { RadioInput, TextInput } from 'lib/components';
import { theme } from 'lib/style';
import { generatePassword, validPassword } from 'lib/utils/functions';
import { CopyIcon } from 'lib/images/CopyIcon';
import { successToast } from 'lib/components/toasts/success';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import LockIcon from 'lib/images/LockIcon';
import { PASSWORD_FORMAT_ERROR } from 'app/pages/account/userAccount/editMyProfile/components';
import {
  CustomPasswordErrorMessage,
  getTooltip,
  PasswordGuide,
} from 'app/pages/admin/components/PasswordTooltip';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
}

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.width || '100%'};
  height: 64px;
  box-sizing: border-box;
  label {
    min-width: 250px;
  }
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 690px;
`;

const EyeField = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const PasswordSection = (props: UserFormProps) => {
  const { isSubmitting, setFieldValue, isNewUser, values } = props;
  const [manualPassword, setManualPassword] = React.useState(true);
  const [passwordView, setPasswordView] = React.useState<'password' | 'text'>(
    'password'
  );
  const [passwordErrorHide, setPasswordErrorHide] = React.useState(true);

  const copyPassword = (password: string) => {
    const cb = navigator.clipboard;
    cb.writeText(password || '').then(() => {
      successToast({ title: 'Password copied successfully!' });
    });
  };

  return (
    <Section id={'password'}>
      <SectionTitle>
        {isNewUser ? 'Password' : 'Create New Password'}
      </SectionTitle>
      {!isNewUser && (
        <RowWrapper>
          <Row>
            <FieldLabel>
              New password:
              {getTooltip()}
            </FieldLabel>
            <Field name='password'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer style={{ maxWidth: '800px' }}>
                    <div style={{ display: 'flex' }}>
                      <PasswordContainer>
                        <TextInput
                          {...field}
                          type={passwordView}
                          placeholder='Password'
                          autoComplete='new-password'
                          required={true}
                          autoFocus={false}
                          disabled={isSubmitting}
                          onKeyUp={() => {
                            setPasswordErrorHide(true);
                            if (
                              values.password &&
                              !validPassword(values.password)
                            ) {
                              setPasswordErrorHide(false);
                            }
                            /******/
                          }}
                        />
                        {props.optionalError.password &&
                          props.optionalError.password.length &&
                          !passwordErrorHide && (
                            <p className='error'>
                              {props.optionalError.password !==
                              PASSWORD_FORMAT_ERROR ? (
                                props.optionalError.password
                              ) : (
                                <PasswordGuide color={theme.palette.red100} />
                              )}
                            </p>
                          )}
                        <EyeField
                          onClick={() => {
                            setPasswordView(
                              passwordView === 'password' ? 'text' : 'password'
                            );
                          }}
                        >
                          {passwordView === 'password' ? (
                            <IoMdEyeOff />
                          ) : (
                            <IoMdEye />
                          )}
                        </EyeField>
                      </PasswordContainer>
                    </div>
                    <p className='user__desc'>
                      Leave blank if you don't want to change the password.
                    </p>
                    <EyeField
                      onClick={() => {
                        setPasswordView(
                          passwordView === 'password' ? 'text' : 'password'
                        );
                      }}
                    >
                      {passwordView === 'password' ? (
                        <IoMdEyeOff />
                      ) : (
                        <IoMdEye />
                      )}
                    </EyeField>
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      )}
      {isNewUser && (
        <RowWrapper>
          <RadioButtonsWrapper width={'90%'} justifyContent={'flex-start'}>
            <RadioInput
              name={'manualPassword'}
              value={'#FFFFFF'}
              text={'Enter manually'}
              checkedColor={theme.palette.primaryThemeColor || '#FF8B22'}
              checked={manualPassword}
              onChange={() => {}}
              onClick={() => {
                setManualPassword(true);
                setFieldValue(`isResetPassword`, false);
                setFieldValue(`password`, '');
              }}
            />
            <RadioInput
              name={'generatePassword'}
              value={'#000000'}
              text={'Let user set a password'}
              checkedColor={theme.palette.primaryThemeColor || '#FF8B22'}
              checked={!manualPassword}
              onChange={() => {}}
              onClick={() => {
                setManualPassword(false);
                setFieldValue('isResetPassword', true);
                setFieldValue('password', generatePassword(10));
              }}
            />
          </RadioButtonsWrapper>
          {manualPassword && (
            <Row>
              <FieldLabel>
                Password: {isNewUser && <Red>*</Red>}
                {getTooltip()}
              </FieldLabel>
              <Field name='password' autoComplete='new-password'>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <Gap flexDirection='row' gap={'10px'} flexWrap='nowrap'>
                        <PasswordContainer>
                          <TextInput
                            {...field}
                            type={passwordView}
                            placeholder='Password'
                            autoComplete='new-password'
                            required={true}
                            autoFocus={false}
                            disabled={isSubmitting}
                          />
                          <CustomPasswordErrorMessage name='password' />
                          <EyeField
                            onClick={() => {
                              setPasswordView(
                                passwordView === 'password'
                                  ? 'text'
                                  : 'password'
                              );
                            }}
                          >
                            {passwordView === 'password' ? (
                              <IoMdEyeOff />
                            ) : (
                              <IoMdEye />
                            )}
                          </EyeField>
                        </PasswordContainer>
                        <Button
                          text='Copy'
                          icon={
                            <CopyIcon
                              color={theme.palette.covideoBlue100}
                              width='20px'
                              height='20px'
                            />
                          }
                          onClick={() => copyPassword(values.password)}
                          variant='secondary'
                        />
                      </Gap>
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
          {!manualPassword && (
            <Row>
              <Field name='automaticPassword'>
                {() => {
                  return (
                    <FieldContainer>
                      <div style={{ display: 'flex' }}>
                        <Button
                          text='Password will be set by a user'
                          icon={
                            <LockIcon
                              color={theme.palette.covideoBlue100}
                              width='20px'
                              height='20px'
                            />
                          }
                          disabled={true}
                          variant='secondary'
                        />
                      </div>
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
        </RowWrapper>
      )}
    </Section>
  );
};
