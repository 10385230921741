import styled from 'styled-components/macro';
import { ReactComponent as HashtagSvg } from './hashtag.svg';

const HashtagIcon = styled(HashtagSvg)`
  width: ${props => props.width || '14px'};
  height: ${props => props.height || '14px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    fill-opacity: ${props => props.opacity || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default HashtagIcon;
