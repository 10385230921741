import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SuperAdminUser, TableField } from 'lib/context';
import {
  CheckboxInput,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { Badge, BadgeType } from 'lib/components/Badge';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { FaChevronRight } from 'react-icons/fa';
import { MdCheck, MdEmail, MdLaunch, MdPhone } from 'react-icons/md';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import { Layout } from '../index.styled';
import dayjs from 'dayjs';
import { copyField } from 'lib/utils/functions';
import { SortButtons } from './SortButtons';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { loginAsForSuperAdmin } from 'lib/api';
import { LoginAsModal } from 'app/pages/reports/components';
import { UserAccessBadge } from './UserAccessBadge';
// import { Button } from 'react-covideo-common';
// import { useIsMutating } from 'react-query';
import { MdLocalPhone } from 'react-icons/md';
import { successToast } from 'lib/components/toasts/success';

const HoverElement = styled.div<{
  padding?: string;
  top?: string;
  right?: string;
}>`
  padding-left: 0px;
  .hover-content {
    display: none;
  }
  &.padding--25 {
    padding-left: 25px;
  }
  &.padding--25--right {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:hover {
    .hover-content {
      top: ${props => props.top || '40px'};
      right: ${props => props.right || '0px'};
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '10px 10px 10px 10px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
      z-index: 10;
      .sub-content {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  }
  svg {
    transform: scale(1.3);
  }
`;

const TickWrapper = styled.div`
  background: rgba(0, 27, 83, 0.05);
  width: 20px;
  height: 20px;
  line-height: 1.5;
  padding: 4px 3px 4px 5px;
  border-radius: 4px;
`;

export enum UserTableColumns {
  ACCESS = 'access',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  LOGINAS = 'loginas',
  STATUS = 'status',
  EMAIL = 'email',
  CONTACT = 'contact',
  LAST_LOGIN = 'loginDate',
  CUSTOMER = 'customerId',
  PHONE = 'phone1',
  TRANSCRIPTION_ACCESS = 'transcriptionAccess',
  DEPARTMENT_NAME = 'departmentName',
  DROPLR_ACCESS = 'droplrAccess',
  USERNAME = 'username',
  SIGNUP_DATE = 'signupDate',
}
const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
  display?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${props => (props.display ? props.display : 'flex')};
  margin-left: 5px;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

const PaginationWrapper = styled.div`
  width: 100%;
`;

const UserTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.palette.gray100};
`;

const NormalText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.gray100};
`;

const ActionWrapper = styled.div`
  padding: 12px;
`;

const LoginAsWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

type Props = {
  userCount: number;
  userArray: SuperAdminUser[];
  selectedUsersData: SuperAdminUser[];
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  tableFields: TableField[];
  onUserSelect(user: SuperAdminUser, checked: boolean): void;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  openUserDetails(customer: SuperAdminUser): void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
};

export const UserTable = ({
  userCount,
  userArray,
  selectedUsersData,
  page,
  size,
  sortKey,
  order,
  tableFields,
  onUserSelect,
  onPaginationChange,
  openUserDetails,
  onSortingUpdate,
}: Props) => {
  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState(false);
  // const isLoadingSendInvite = useIsMutating();
  const onLoginAs = async (user: SuperAdminUser) => {
    if (!user.username) return;
    await loginAsForSuperAdmin({ userId: user.id, username: user.username });
    setIsLoginAsModalOpen(true);
  };

  const copyPhone = (phone: any) => {
    const cb = navigator.clipboard;
    cb.writeText(phone || '').then(() => {
      successToast({ title: 'Phone # copied successfully' });
    });
  };

  return (
    <Layout>
      <TableContextProvider
        total={userCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={[
              '',
              ...tableFields.map((item, index) => {
                return (
                  <TableCell key={index} width={item.width} onClick={() => {}}>
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            fixColumnIndex='left'
            rows={userArray.map((user: SuperAdminUser, index: number) => ({
              key: index,
              columns: [
                <TableCell width={40} alignItems='center'>
                  <CheckboxInput
                    width='24px'
                    blueCheck={true}
                    checked={selectedUsersData.includes(user)}
                    onChange={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                      let { checked } = e.target as HTMLInputElement;
                      onUserSelect(user, checked);
                    }}
                  />
                </TableCell>,
                ...tableFields.map((item, index) => {
                  let columnValue = <></>;
                  let columnWidth = item.width;
                  switch (item.value) {
                    case UserTableColumns.FIRST_NAME:
                      columnValue = <UserTitle>{user.firstName}</UserTitle>;
                      break;
                    case UserTableColumns.LAST_NAME:
                      columnValue = <UserTitle>{user.lastName}</UserTitle>;
                      break;
                    case UserTableColumns.LOGINAS:
                      columnValue = (
                        <HoverElement
                          top='-35px'
                          right='-30px'
                          onClick={async () => await onLoginAs(user)}
                        >
                          <div className='hover-content'>
                            <div className='sub-content'>Log in as user</div>
                          </div>
                          <LoginAsWrapper>
                            <MdLaunch size={20} />
                          </LoginAsWrapper>
                        </HoverElement>
                      );
                      break;
                    case UserTableColumns.STATUS:
                      columnValue = (
                        <Badge
                          type={
                            user.verified === VerificationStatus.ACTIVE
                              ? BadgeType.LIGHT_ORANGE
                              : BadgeType.GRAY
                          }
                          text={
                            user.verified === VerificationStatus.ACTIVE
                              ? 'ACTIVE'
                              : 'INACTIVE'
                          }
                        />
                      );
                      break;
                    case UserTableColumns.EMAIL:
                      columnValue = <NormalText>{user.email}</NormalText>;
                      break;
                    case UserTableColumns.CONTACT:
                      columnValue = (
                        <>
                          <HoverElement>
                            <MdEmail
                              size={24}
                              color={theme.palette.gray60}
                              onClick={() => {
                                copyField(user.email ?? '', 'Email');
                              }}
                            />
                            <div className='hover-content'>
                              <strong>Click to copy:</strong>
                              <br />
                              <div className='sub-content'>{user.email}</div>
                            </div>
                          </HoverElement>
                          <HoverElement>
                            <MdPhone
                              color={theme.palette.gray60}
                              onClick={() => {
                                copyField(user.phone1 ?? '', 'Phone');
                              }}
                            />
                            <div className='hover-content'>
                              <strong>Click to copy:</strong>
                              <br />
                              <div className='sub-content'>{user.phone1}</div>
                            </div>
                          </HoverElement>
                        </>
                      );
                      break;
                    case UserTableColumns.LAST_LOGIN:
                      columnValue = (
                        <NormalText>
                          {user.loginDate
                            ? dayjs(new Date(user.loginDate * 1000)).format(
                                STANDARD_DATE_FORMAT
                              )
                            : //   : !user.loginDate && user.verified ? (
                              //   user.inviteSent ? (
                              //     <Button
                              //       disabled={!!isLoadingSendInvite}
                              //       text='Resend'
                              //       variant='secondary'
                              //       onClick={() => handleInviteEmailSend(user)}
                              //     />
                              //   ) : (
                              //     <Button
                              //       disabled={!!isLoadingSendInvite}
                              //       text='Send'
                              //       variant='secondary'
                              //       onClick={() => handleInviteEmailSend(user)}
                              //     />
                              //   )
                              // )

                              '-'}
                        </NormalText>
                      );
                      break;
                    case UserTableColumns.CUSTOMER:
                      columnValue = (
                        <HoverElement>
                          <NormalText>{user.customerName}</NormalText>
                          <div className='hover-content'>
                            <NormalText>{user.customerName}</NormalText>
                          </div>
                        </HoverElement>
                      );
                      break;
                    case UserTableColumns.ACCESS:
                      columnWidth = undefined;
                      columnValue = (
                        <UserAccessBadge
                          isAutomotive={!!user.automotiveAccess}
                          automotiveRole={user.automotiveRole}
                          access={user.access?.toString()}
                          mlAutoCreated={user.mlAutoCreated}
                        />
                      );
                      break;
                    case UserTableColumns.SIGNUP_DATE:
                      columnValue = (
                        <NormalText>
                          {user.signupDate
                            ? dayjs(new Date(user.signupDate)).format(
                                STANDARD_DATE_FORMAT
                              )
                            : '-'}
                        </NormalText>
                      );
                      break;
                    case UserTableColumns.USERNAME:
                      columnValue = <NormalText>{user.username}</NormalText>;
                      break;
                    case UserTableColumns.DEPARTMENT_NAME:
                      columnValue = (
                        <NormalText>{user.departmentName}</NormalText>
                      );
                      break;
                    case UserTableColumns.PHONE:
                      columnValue = (
                        <HoverElement
                          padding='10px'
                          top='-60px'
                          className='hover--div'
                        >
                          <MdLocalPhone
                            onClick={() => {
                              copyPhone(user.phone1);
                            }}
                          />
                          <div className='hover-content'>
                            <strong>Click to copy:</strong>
                            <br />
                            <div className='sub-content'>
                              {user[item.value]}
                            </div>
                          </div>
                        </HoverElement>
                      );
                      break;
                    case UserTableColumns.TRANSCRIPTION_ACCESS:
                      columnValue = (
                        <>
                          {user.transcriptionAccess?.toString()?.trim() !==
                          '0' ? (
                            <TickWrapper>
                              <MdCheck />
                            </TickWrapper>
                          ) : (
                            '-'
                          )}
                        </>
                      );
                      break;
                    case UserTableColumns.DROPLR_ACCESS:
                      columnValue = (
                        <>
                          {user.droplrAccess?.toString()?.trim() !== '0' ? (
                            <TickWrapper>
                              <MdCheck />
                            </TickWrapper>
                          ) : (
                            '-'
                          )}
                        </>
                      );
                      break;
                    default:
                      columnValue = <></>;
                  }
                  return (
                    <TableCell
                      key={index}
                      width={columnWidth}
                      onClick={() => {}}
                      display={'block'}
                    >
                      {columnValue}
                    </TableCell>
                  );
                }),
                <TableCell
                  cursor='pointer'
                  textAlign='center'
                  alignItems='center'
                  onClick={() => openUserDetails(user)}
                >
                  <ActionWrapper>
                    <FaChevronRight size={12} />
                  </ActionWrapper>
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
      {isLoginAsModalOpen && (
        <LoginAsModal
          handleModalClose={() => {
            setIsLoginAsModalOpen(false);
            window.location.reload();
          }}
        />
      )}
    </Layout>
  );
};
