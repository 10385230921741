import { timezones } from 'lib/const';

export const hoursOptions = Array.from({ length: 12 }, (_, hourIndex) => {
  const option =
    hourIndex < 9 ? `0${hourIndex + 1}` : (hourIndex + 1).toString();
  return {
    value: option,
    label: option,
  };
});

export const isTimeValid = (time: string = '') => {
  const timeArray = time.split(':');

  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);

  return (
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 &&
    hours <= 12 &&
    minutes >= 0 &&
    minutes < 60
  );
};

export const timeFormats = [
  {
    value: 'AM',
    text: 'AM',
  },
  {
    value: 'PM',
    text: 'PM',
  },
];

export const minutesOptions = ['00', '15', '30', '45'].map(item => {
  return { value: item, label: item };
});

export const timezonesOptions = timezones.map(timezone => ({
  value: timezone.value,
  label: timezone.text,
  offset: timezone.offset,
  utc: timezone.utc,
}));
