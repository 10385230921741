import { TableField } from 'lib/context';

export enum CustomReportsTableColumns {
  REPORT_NAME = 'title',
  TYPE = 'type',
  RECIPIENTS = 'recipients',
  DATE = 'date',
  SYNC = 'sync',
  DELIVERY = 'delivery',
}

export const tableFields: TableField[] = [
  {
    value: CustomReportsTableColumns.REPORT_NAME,
    label: 'Report Name',
    sortable: true,
    sortKey: 'REPORT_NAME',
    width: 170,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.TYPE,
    label: 'Type',
    sortable: true,
    width: 105,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.DELIVERY,
    label: 'Delivery',
    width: 105,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.RECIPIENTS,
    label: 'Recipients',
    width: 270,
  },
  {
    value: CustomReportsTableColumns.DATE,
    label: 'Timeframe',
    width: 150,
  },
  {
    value: CustomReportsTableColumns.SYNC,
    label: '',
    height: '100%',
  },
];
