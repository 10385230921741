import React from 'react';
import styled from 'styled-components/macro';
import * as PapaParse from 'papaparse';
import { theme } from 'lib/style';
import UploadIcon from 'lib/images/UploadIcon';
import AddIcon from '../../images/AddIcon';
import { BUTTON_VARIANT } from 'react-covideo-common/dist/components/Button/types';
import { Button } from 'react-covideo-common';

type Event = any;

interface IFileInfo {
  name: string;
  size: number;
  type: string;
}

type ContainerProps = {
  width: number;
  height: number;
  marginTop: number;
  backgroundColor?: string;
};

const DragAndDropContainer = styled.div<ContainerProps>`
  margin-top: ${props => props.marginTop}px;
  text-align: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => props.backgroundColor || theme.palette.white};

  .file-exists {
    text-align: left;
    color: red;
  }

  .drag-drop-zone {
    display: block;
    width: ${props => props.width - 2}px;
    height: ${props => props.height}px;
    border: 1px dashed ${theme.palette.lightgray};
    border-radius: 5px;
    color: ${theme.palette.primaryDarkBlue};
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    box-sizing: border-box;

    input {
      background: none !important;
      border: none;
      padding: 0 !important;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${theme.palette.secondaryBlue};
      }
    }
  }

  .drag-drop-zone.inside-drag-area {
    svg {
      opacity: 0.7;
    }
  }

  .drag-drop-zone.outside-drag-area {
    svg {
      opacity: 0.3;
    }
  }

  .dropped-files li {
    color: #07f;
    padding: 3px;
    text-align: left;
    font-weight: bold;
  }

  .file {
    display: none;
  }
`;

type TextProps = {
  isActive: boolean;
  backgroundColor?: string;
};

const TextComponent = styled.div<TextProps>`
  height: 100%;
  width: 100%;
  float: left;
  line-height: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -1;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.isActive
        ? '#f6f7f9'
        : '#fff'};
`;

const ButtonContainer = styled.div`
  svg {
    opacity: 1 !important;
  }
`;

const Text = styled.p`
  width: 317px;
  margin: 0 0 21px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: ${theme.palette.black_1_100};
`;

interface DragAndDropProps {
  accept?: string;
  fileEncoding?: string;
  inputStyle?: object;
  onError?: (error: Error) => void;
  onFileLoaded?: (data: Array<any>, fileInfo: IFileInfo) => any;
  onDrop?: (e: any) => void;
  text?: string;
  showIcon?: boolean;
  height?: number;
  width?: number;
  marginTop?: number;
  parserOptions?: PapaParse.ParseConfig;
  disabled?: boolean;
  button?: boolean;
  textStyle?: React.CSSProperties;
  backgroundColor?: string;
  showAddIcon?: boolean;
  placeholder?: string;
  isMultiple?: boolean;
  variant?: BUTTON_VARIANT;
}

const DragAndDrop = ({
  accept = '.csv, text/csv',
  fileEncoding = 'UTF-8',
  inputStyle = {},
  onError,
  onFileLoaded,
  onDrop,
  parserOptions = {} as PapaParse.ParseConfig,
  text = 'Drag and drop your .csv file here or',
  showIcon = true,
  width = 528,
  height = 230,
  marginTop = 0,
  disabled = false,
  button = false,
  textStyle,
  backgroundColor,
  showAddIcon = true,
  isMultiple = true,
  placeholder = 'Browse files...',
}: DragAndDropProps) => {
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth };
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        let validFile = true;
        for (let file of action.files) {
          validFile = accept.includes(file.name.substr(-4));
        }

        if (validFile)
          return { ...state, fileList: state.fileList.concat(action.files) };
        else return state;
      default:
        return state;
    }
  };

  const [_, setDisable] = React.useState(false);

  const [data, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  const handleDragEnter = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target && e.target.id.includes('text-')) {
      return;
    }

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
  };

  const handleDragOver = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  const handleDrop = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    let reader: FileReader = new FileReader();
    const target = e.dataTransfer || e.target;
    const files: FileList = target.files!;
    if (files.length > 0) {
      const fileInfo: IFileInfo = {
        name: files[0].name,
        size: files[0].size,
        type: files[0].type,
      };

      reader.onload = (_event: Event) => {
        const csvData = PapaParse.parse(
          reader.result as string,
          Object.assign(parserOptions, {
            error: onError,
            encoding: fileEncoding,
          })
        );
        onFileLoaded && onFileLoaded(csvData.data, fileInfo);
      };

      dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
      dispatch({ type: 'ADD_FILE_TO_LIST', files });
      reader.readAsText(files[0], fileEncoding);
    }
  };

  const browseFiles = (e: Event) => {
    e.preventDefault();
    const fileInput = document.getElementById('file');
    if (!fileInput || !fileInput.click) {
      return;
    }
    setDisable(true);
    fileInput.click();
  };

  return (
    <DragAndDropContainer
      width={width}
      height={height}
      marginTop={marginTop}
      backgroundColor={backgroundColor}
    >
      <div
        className={
          data.inDropZone
            ? 'drag-drop-zone inside-drag-area'
            : 'drag-drop-zone outside-drag-area'
        }
        onDrop={(e: Event) => (onDrop ? onDrop(e) : handleDrop(e))}
        onDragOver={(e: Event) => handleDragOver(e)}
        onDragEnter={(e: Event) => handleDragEnter(e)}
        onDragLeave={(e: Event) => handleDragLeave(e)}
      >
        <>
          {showIcon && <UploadIcon width='72px' height='72px' />}
          <TextComponent
            id='text-browse'
            isActive={data.inDropZone}
            backgroundColor={backgroundColor}
          >
            {!data.inDropZone && data.fileList.length === 0 && (
              <>
                <Text style={textStyle}>{text}</Text>
                {button && !data.inDropZone ? (
                  <ButtonContainer>
                    <Button
                      text={placeholder}
                      icon={showAddIcon ? <AddIcon color='#fff' /> : <></>}
                      disabled={disabled}
                      onClick={browseFiles}
                    />
                  </ButtonContainer>
                ) : (
                  <input
                    type='button'
                    value='browse your files...'
                    onClick={browseFiles}
                  />
                )}
              </>
            )}
            {data.inDropZone && (
              <>
                <br />
                <p id='text-drop'>Drop file here</p>
              </>
            )}
            {data.fileList[0] && (
              <>
                <br />
                <p>{data.fileList[0][0].name} is ready</p>
              </>
            )}
            <input
              className='file'
              id='file'
              type='file'
              style={inputStyle}
              multiple={isMultiple}
              accept={accept}
              onChange={(e: Event) => (onDrop ? onDrop(e) : handleDrop(e))}
              disabled={disabled}
            />
          </TextComponent>
        </>
      </div>
    </DragAndDropContainer>
  );
};

export default DragAndDrop;
