import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import VideoIcon from 'lib/images/VideoIcon';
import { CountDown } from 'lib/components/CountDown';
import PauseIcon from 'lib/images/pauseButton';
import RouteLeavingGuard from '../../../video/videoDetails/main/RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { MIME_TYPE, useMediaRecorder } from 'lib/hooks/useMediaRecorder';
import { MdVideocam } from 'react-icons/md';
import CheckmarkIcon from 'lib/images/CheckmarkIcon';
import DeleteIcon from 'lib/images/DeleteIcon';
import SaveIcon from 'lib/images/SaveIcon';
import { useAuth } from 'lib/context';
import { toMMSS } from '../../../../../lib/utils/functions';
import * as workerTimers from 'worker-timers';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

const FinishButtonWrapper = styled.div`
  margin-left: calc(50% - 48.5px - 150px);
`;

const UploadButton = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary[100]};
  color: ${theme.palette.white};
  height: 40px;
  width: 166px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  margin-left: calc(100% - 274px);
  transition: width 0.2s ease;
  overflow: hidden;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1.6;
  &:hover {
    background-color: ${props => props.theme.colors.primary[100]};
  }
  .icon-wrapper {
    margin-right: 0;
  }
  .text {
    font-size: 15px;
  }
`;

const RetakeButton = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.lightgray};
  color: ${theme.palette.black_1_100};
  height: 40px;
  width: 108px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  transition: width 0.2s ease;
  overflow: hidden;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1.6;
  &:hover {
    background-color: ${theme.palette.hamburgerMenuHover};
  }
  .icon-wrapper {
    margin-right: 0;
  }
  .text {
    font-size: 15px;
  }
`;

type WrapperProps = {
  isTrial?: boolean;
};

const RecordBtnWrapper = styled.div<WrapperProps>`
  display: flex;
  width: 710px;
  position: absolute;
  top: ${props => (props.isTrial ? '656px' : '584px')};
`;

const ControlsContainer = styled.div`
  width: 150px;
`;

const ScreenIsRecording = styled.div`
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #4e5461;
`;

const Timer = styled.div`
  position: absolute;
  top: 10px;
  left: 125px;
`;

const stopStream = (s: any) => {
  try {
    s.getTracks().forEach((t: any) => t.stop());
  } catch (e) {}
};

// Firefox 1.0+
//@ts-ignore
const isFirefox = typeof InstallTrigger !== 'undefined';

let countdownBeforeStart: any = null;

const QUALITY_MAP: { [key: string]: any } = {
  low: { width: { ideal: 640 }, height: { ideal: 360 } },
  standard: { width: { ideal: 1280 }, height: { ideal: 720 } },
  high: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

const DEFAULT_SETTINGS = {
  videoSource: { value: '' },
  videoQuality: 'standard',
  audioSource: { value: 'default' },
};

let s: any;
let count = 0;
//@ts-ignore
const getDisplayMedia = navigator.mediaDevices.getDisplayMedia;
export const RecordBoth = ({
  onRecordingStart,
  onRecordingEnd,
  onUpload,
  uploadDisabled,
  onRecordingUrlGeneration,
  handleGoToRecordHome,
}: any) => {
  count++;
  const history = useHistory();
  const themes = useTheme();
  const previewRef = useRef(null);
  const [countdown, setCountdown] = useState(3);
  const [isRecording, setIsRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [finished, setFinished] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(new Date());
  const [seconds, setSeconds] = useState(0);
  const [secondsBeforePause, setSecondsBeforePause] = useState(0);

  const settings = {
    videoSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoSource as any,
    videoQuality: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoQuality,
    audioSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).audioSource as any,
  };
  const { userData } = useAuth();

  useEffect(() => {
    if (error === 'permission_denied') {
      return;
    }
    //@ts-ignore
    if (document.pictureInPictureElement) {
      return;
    }

    const width = 640;
    const height = 360;
    const cnstrn = {
      audio: false,
      video: {
        deviceId: settings.videoSource ? settings.videoSource.value : undefined,
        width: { ideal: width },
        height: { ideal: height },
        frameRate: 24,
      },
    };

    navigator.mediaDevices
      .getUserMedia(cnstrn)
      .then(stream => {
        s = stream;
        //@ts-ignore
        window.streams.push(s);
        if (!previewRef.current) {
          return;
        }
        //@ts-ignore
        previewRef.current.srcObject = stream;
        //@ts-ignore
        previewRef.current.oncanplaythrough = async () => {
          if (!previewRef.current) {
            return;
          }
          try {
            //@ts-ignore
            previewRef.current.play();
            //@ts-ignore
            await previewRef.current.requestPictureInPicture();
            //@ts-ignore
            previewRef.current.oncanplaythrough = null;
          } catch (e) {
            console.log(e);
          }
        };
      })
      .catch(error => {
        console.log('The following error occurred: ' + error);
      });
  }, []);

  let audio: any;
  try {
    audio = { deviceId: settings.audioSource.value };
  } catch (e) {}
  if (!settings || !settings.audioSource || !settings.audioSource.value) {
    audio = false;
  }

  if (count > 1) {
    //@ts-ignore
    navigator.mediaDevices.getDisplayMedia = () => {};
  }

  const quality =
    settings && settings.videoQuality && settings.videoQuality.value
      ? QUALITY_MAP[settings.videoQuality]
      : QUALITY_MAP.standard;

  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    previewStream,
    error,
  } = useMediaRecorder({
    screen: true,
    video: { ...quality, frameRate: 24 },
    audio,
    quality: settings.videoQuality,
    blobPropertyBag: { type: MIME_TYPE },
    mimeType: MIME_TYPE,
  });

  if (previewStream) {
    previewStream.getVideoTracks()[0].onended = function () {
      if (status === 'recording') {
        handleStopRecording();
      } else {
        handleGoToRecordHome();
      }
    };
  }

  React.useEffect(() => {
    localStorage.setItem('record_error', '');
    if (!settings.videoSource || !settings.videoSource.value) {
      localStorage.setItem(
        'record_error',
        'No camera detected. Please check your recording settings and make sure the camera is plugged in.'
      );
      count = 0;
      handleGoToRecordHome();
    }
    return () => {
      count = 0;
      //@ts-ignore
      navigator.mediaDevices.getDisplayMedia = getDisplayMedia;
      closeAndClear();
    };
  }, []);

  React.useEffect(() => {
    if (error === 'permission_denied') {
      isFirefox &&
        localStorage.setItem(
          'record_error',
          'Please allow access to share your screen then try again.'
        );
      closeAndClear();
      count = 0;
      //@ts-ignore
      navigator.mediaDevices.getDisplayMedia = getDisplayMedia;
      handleGoToRecordHome();
    }
  }, [error]);

  React.useEffect(() => {
    if (
      !finished &&
      !canceled &&
      previewStream &&
      !mediaBlobUrl &&
      !isRecording
    ) {
      handleStartRecording();
    }
  }, [previewStream]);

  React.useEffect(() => {
    if (mediaBlobUrl) {
      onRecordingUrlGeneration(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    let myInterval = workerTimers.setInterval(() => {
      if (isRecording && !paused && !countdown) {
        setSeconds(
          secondsBeforePause +
            (new Date().getTime() - recordingStartTime.getTime()) / 1000
        );
      }
    }, 1000);
    return () => {
      workerTimers.clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (
      userData &&
      userData.user?.packageDetails &&
      userData.user?.packageDetails?.maxLength &&
      Math.ceil(seconds) >= userData.user?.packageDetails?.maxLength
    ) {
      handleStopRecording();
    }
  }, [seconds, userData]);

  const haltRecording = () => {
    if (isRecording && countdown > 0) {
      setCanceled(true);
      clearTimeout(countdownBeforeStart);
      setIsRecording(false);
      setCountdown(3);
    }
  };

  const handleStartRecording = () => {
    setCanceled(false);
    setIsRecording(true);
  };

  const handleStopRecording = async () => {
    setFinished(true);
    onRecordingEnd && onRecordingEnd();
    setIsRecording(false);
    setSecondsBeforePause(0);
    //@ts-ignore
    document.pictureInPictureElement && document.exitPictureInPicture();
    try {
      //@ts-ignore
      const streams = [...window.streams];
      //@ts-ignore
      streams.filter(Boolean).forEach(s => stopStream(s));
      //@ts-ignore
      window.streams = [];
    } catch (e) {}
    await stopRecording();
  };

  const handlePauseRecording = () => {
    pauseRecording();
    setSecondsBeforePause(seconds);
    setPaused(true);
  };

  const handleResumeRecording = () => {
    resumeRecording();
    setRecordingStartTime(new Date());
    setPaused(false);
  };

  const handleUpload = async () => {
    let file = await fetch(mediaBlobUrl || '').then((r: any) => r.blob());
    closeAndClear();
    onUpload({ file, fileUrl: mediaBlobUrl });
  };

  React.useEffect(() => {
    if (countdown > 0 && isRecording) {
      countdownBeforeStart = setTimeout(
        () => setCountdown(countdown - 1),
        1000
      );
    } else if (isRecording) {
      onRecordingStart && onRecordingStart();
      startRecording();
      setRecordingStartTime(new Date());
    }
  }, [isRecording, countdown]);

  const closeAndClear = () => {
    //@ts-ignore
    const streams = [...window.streams];
    //@ts-ignore
    streams.filter(Boolean).forEach(s => stopStream(s));
    //@ts-ignore
    window.streams = [];
    setIsRecording(false);
    if (countdownBeforeStart) {
      clearTimeout(countdownBeforeStart);
    }

    exitPip();
  };

  const exitPip = () => {
    //@ts-ignore
    document.pictureInPictureElement && document.exitPictureInPicture();
  };

  const body = React.useMemo(
    () => (
      <>
        {status === 'recording' && !mediaBlobUrl && !canceled && (
          <ScreenIsRecording>
            {paused ? 'Recording is paused…' : 'Recording in progress…'}
          </ScreenIsRecording>
        )}
        {!mediaBlobUrl && (
          <iframe title='videoFrame' style={{ display: 'none' }}>
            <video
              muted={true}
              style={{ display: 'none' }}
              ref={previewRef}
              width={0}
              height={0}
              autoPlay
            />
          </iframe>
        )}
        <RecordBtnWrapper isTrial={userData.trialAccount}>
          {status === 'recording' && (
            <ControlsContainer>
              {!paused && (
                <Button
                  variant='secondary'
                  icon={<PauseIcon />}
                  text={'Pause'}
                  onClick={handlePauseRecording}
                />
              )}
              {paused && (
                <Button
                  variant='red'
                  icon={<MdVideocam size={24} />}
                  text={'Resume'}
                  onClick={handleResumeRecording}
                />
              )}
              <Timer>{toMMSS(seconds, 0)}</Timer>
            </ControlsContainer>
          )}
          {status === 'recording' && (
            <FinishButtonWrapper>
              <Button
                variant='primary'
                icon={<CheckmarkIcon color={themes.colors.white[100]} />}
                text={'Finish'}
                onClick={handleStopRecording}
              />
            </FinishButtonWrapper>
          )}
          {isRecording && status !== 'recording' && (
            <Gap justifyContent='center' width='100%'>
              <Button
                variant='secondary'
                text='Cancel'
                onClick={haltRecording}
              />
            </Gap>
          )}
          {!isRecording && status === 'idle' && (
            <Gap justifyContent='center' width='100%'>
              <Button
                variant='red'
                icon={<VideoIcon width={24} height={24} opacity={1} />}
                text='Start recording'
                onClick={handleStartRecording}
              />
            </Gap>
          )}
          {status === 'stopped' && (
            <RetakeButton>
              <Button
                variant='secondary'
                icon={<DeleteIcon height={20} width={20} />}
                text={'Discard'}
                onClick={handleGoToRecordHome}
              />
            </RetakeButton>
          )}
          {status === 'stopped' && (
            <UploadButton>
              <Button
                disabled={uploadDisabled}
                icon={<SaveIcon height={20} width={20} />}
                text={'Save Recording'}
                onClick={handleUpload}
              />
            </UploadButton>
          )}
        </RecordBtnWrapper>
      </>
    ),
    [status, seconds, isRecording, paused, mediaBlobUrl, uploadDisabled]
  );

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return status === 'recording';
        }}
        title='Leave without saving the recording?'
        text='Your recording will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
      <CountDown
        counter={countdown}
        willStart={countdown > 0 && isRecording}
        onClick={() => {}}
        hide={!countdown}
      />
      {body}
    </>
  );
};
