import {
  CheckboxInput,
  HoverPopup,
  LoadingIndicator,
  ModalDelete,
  ModalVideoListVideo,
  SelectionHeaderPopup,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Button } from 'react-covideo-common';
import {
  Container,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import React, { useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import {
  deleteScheduledEmailAndSmsButtonPopupText,
  deleteScheduledEmailButtonPopupText,
  deleteScheduledSmsButtonPopupText,
  editScheduleButtonPopupText,
  noSchedulesMessage,
  pageTitle,
  smsRecipientBadgeText,
  tableFields,
} from '../const';
import { IOnPaginationChange, TableFieldsValue } from '../types';
import { theme } from 'lib/style';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useGetSchedulesQuery } from 'lib/api/schedule/getSchedules';
import { Schedule, ScheduleRecipient } from 'lib/api/schedule/types';
import { BiCheck, BiMinus } from 'react-icons/bi';
import { useAuth } from 'lib/context';
import { SendAndShareModal } from 'app/pages/library/videos/videoList/components/SendAndShare/SendAndShareModal';
import { toNumber } from 'lodash';
import { useDeleteScheduleMutation } from 'lib/api/schedule/deleteSingleSchedule';
import {
  parseDeliveryTimeToScheduledTimezone,
  parseDeliveryUnixTimestampToUserTimezone,
} from '../utils';
import { SendAndShareProvider } from 'lib/context/send-and-share/provider';
import DeleteIcon from 'lib/images/DeleteIcon';
import { useDeleteMultipleSchedulesMutation } from 'lib/api/schedule/deleteMultipleSchedules';

type TableHeaderProps = {
  textAlign?: string;
};
const TableHeader = styled.p<TableHeaderProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  padding-left: 8px;
  color: #9297a2;
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  :last-child {
    padding-right: 8px;
  }
`;

const NoResults = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 36px 0;
`;

type TableCellProps = {
  label?: string;
  justifyContent?: string;
  paddingLeft?: string;
};
const TableCell = styled.div<TableCellProps>`
  width: auto;
  max-width: 260px;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '8px')};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'left'};
`;

type TableCellTextProps = {
  clickable?: boolean;
  fontWeight?: string;
};
const TableCellText = styled.span<TableCellTextProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: ${theme.palette.gray100};
  ${props =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const RecipientPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
`;

const PopupRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 16px;
`;

const PopupText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.palette.gray100};
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const RecipientBadgeWrapper = styled.div`
  flex-shrink: 0;
`;

const Badge = styled.div`
  padding: 2px 8px;
  background: #e6e8ee;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.secondary[5]};
  color: ${({ theme }) => theme.colors.secondary[100]};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;

type TableCellHoverPopupProps = {
  scrollBarNeeded?: boolean;
};
const TableCellHoverPopup = styled(HoverPopup)<TableCellHoverPopupProps>`
  > div > div {
    max-height: 200px;
    overflow-y: auto;
  }
`;

type SendCheckboxProps = {
  checked?: boolean;
};
const SendCheckbox = styled.div<SendCheckboxProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  background: ${props =>
    props.checked
      ? props.theme.colors.primary[100]
      : props.theme.colors.secondary['non_alpha']};
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const CounterContainer = styled.div`
  font-size: 14px;

  span {
    font-weight: 600;
  }
`;

const PopupHeaderBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Main = () => {
  const { userData } = useAuth();
  const themes = useTheme();
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState<{ page: number; size: number }>({
    page: 0,
    size: 10,
  });
  const [initialData, setInitialData] = useState<Schedule[] | null>(null);
  const [previewVideoId, setPreviewVideoId] = useState<string>('');
  const [editScheduleId, setEditScheduleId] = useState<number | undefined>(
    undefined
  );
  const [editScheduleVideoId, setEditScheduleVideoId] = useState<string>('');
  const [deleteScheduleId, setDeleteScheduleId] = useState<
    number | undefined
  >();
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState<Array<number>>([]);
  const [deleteScheduleModalTitleExtra, setDeleteScheduleModalTitleExtra] =
    useState<string>('');

  const {
    data: scheduleData,
    refetch: refreshSchedule,
    isLoading: isLoadingGetSchedules,
    isFetching: isFetchingGetSchedules,
  } = useGetSchedulesQuery({
    start: filter.page * filter.size,
    limit: filter.size,
  });

  const { isLoading: isLoadingDeleteSchedule, mutateAsync: deleteSchedule } =
    useDeleteScheduleMutation();

  const { mutateAsync: deleteMultipleSchedules } =
    useDeleteMultipleSchedulesMutation();

  useEffect(() => {
    if (scheduleData) {
      setInitialData(scheduleData.schedules);
      setCount(scheduleData.count);
    }
  }, [scheduleData]);

  useEffect(() => {
    refreshSchedule();
  }, [filter]);

  const openVideoPreviewModal = (previewVideoId: string) => {
    setPreviewVideoId(previewVideoId);
  };

  const closeVideoPreviewModal = () => {
    setPreviewVideoId('');
  };

  const closeEditScheduleModal = () => {
    setEditScheduleId(undefined);
    setEditScheduleVideoId('');
  };

  const openConfirmDeleteScheduleModal = (deleteScheduleId: number) => {
    setDeleteScheduleId(deleteScheduleId);
  };

  const closeConfirmDeleteScheduleModal = () => {
    setDeleteScheduleId(undefined);
  };

  const openEditScheduleModal = (scheduleId: number, videoId: string) => {
    setEditScheduleVideoId(videoId);
    setEditScheduleId(scheduleId);
  };

  const handleCancelScheduleClick = async (
    scheduleId: number,
    sendEmail: boolean,
    sendSms: boolean
  ) => {
    openConfirmDeleteScheduleModal(scheduleId);
    if (sendEmail) {
      if (sendSms) {
        setDeleteScheduleModalTitleExtra('email and SMS');
        return;
      }
      setDeleteScheduleModalTitleExtra('email');
      return;
    }
    setDeleteScheduleModalTitleExtra('SMS');
    return;
  };

  const handleDeleteSchedule = async () => {
    if (deleteScheduleId) {
      await deleteSchedule(deleteScheduleId);
      setSelectedSchedules([]);
      closeConfirmDeleteScheduleModal();
    }
  };

  const handleMultipleDelete = async () => {
    if (selectedSchedules.length) {
      await deleteMultipleSchedules(selectedSchedules);
      setSelectedSchedules([]);
      setShowMultipleDeleteModal(false);
    }
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    if (filter.page !== newPage || filter.size !== newSize) {
      setFilter({
        page: newPage,
        size: newSize,
      });
    }
  };

  const renderPopupTextForOneRecipientWhenMultiple = (
    recipient: ScheduleRecipient
  ) => {
    const firstName = recipient.firstName;
    const lastName = recipient.lastName;
    const phone = recipient.phone;
    const email = recipient.email;
    if (firstName) {
      return `${firstName}${!!lastName ? ` ${lastName}` : ''} - ${
        !!email ? email : phone
      }`;
    }
    if (lastName) {
      return `${lastName} - ${!!email ? email : phone}`;
    }
    if (email) {
      return email;
    }
    return phone;
  };

  const renderRecipentsCellContent = (recipients: ScheduleRecipient[]) => {
    const totalNumberOfRecipients = recipients.length;
    if (totalNumberOfRecipients < 1) {
      return <TableCellText fontWeight='600'>No recipients</TableCellText>;
    }
    if (totalNumberOfRecipients < 2) {
      const firstName = recipients[0].firstName;
      const lastName = recipients[0].lastName;
      const phone = recipients[0].phone;
      const email = recipients[0].email;
      const cellText = !!firstName
        ? `${firstName}${!!lastName ? ` ${lastName}` : ''}`
        : !!lastName
          ? lastName
          : !!email
            ? email
            : phone;
      const popupText = !!email ? email : phone;
      return (
        <TableCellHoverPopup
          width='auto'
          maxWidth='450px'
          position='above'
          padding='0'
          showTriangle={true}
          hoverable={<TableCellText fontWeight='600'>{cellText}</TableCellText>}
          popup={
            <RecipientPopupContainer>
              <PopupRow>
                <PopupText>{popupText}</PopupText>
                {phone && email && (
                  <RecipientBadgeWrapper>
                    <Badge>{smsRecipientBadgeText}</Badge>
                  </RecipientBadgeWrapper>
                )}
              </PopupRow>
            </RecipientPopupContainer>
          }
        />
      );
    }
    return (
      <TableCellHoverPopup
        scrollBarNeeded={totalNumberOfRecipients > 7}
        width='auto'
        maxWidth='450px'
        position='above'
        padding='0'
        showTriangle={true}
        hoverable={
          <TableCellText fontWeight='600'>
            {`${totalNumberOfRecipients} recipients`}
          </TableCellText>
        }
        popup={
          <RecipientPopupContainer>
            {recipients.map((recipient: ScheduleRecipient, index: number) => (
              <PopupRow key={index}>
                <PopupText key={index}>
                  {renderPopupTextForOneRecipientWhenMultiple(recipient)}
                </PopupText>
                {recipient.phone && recipient.email && (
                  <RecipientBadgeWrapper>
                    <Badge>SMS recipient</Badge>
                  </RecipientBadgeWrapper>
                )}
              </PopupRow>
            ))}
          </RecipientPopupContainer>
        }
      />
    );
  };

  const renderCancelScheduleButtonPopupElement = (
    sendEmail: boolean,
    sendSms: boolean
  ) => {
    let poputText = '';
    if (sendEmail && sendSms) {
      poputText = deleteScheduledEmailAndSmsButtonPopupText;
    } else if (sendEmail) {
      poputText = deleteScheduledEmailButtonPopupText;
    } else if (sendSms) {
      poputText = deleteScheduledSmsButtonPopupText;
    }
    return <PopupText>{poputText}</PopupText>;
  };

  //HEADERS
  const headers = [
    <CheckboxInput
      id={'select-all-schedules'}
      name={'select-all-schedules'}
      checked={selectedSchedules.length === initialData?.length}
      onChange={() => {
        if (selectedSchedules.length === initialData?.length) {
          setSelectedSchedules([]);
          return;
        }
        setSelectedSchedules(
          initialData?.map(schedule => schedule.scheduleId) || []
        );
      }}
      onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
      }}
    />,
    ...tableFields.map(({ value, label }) => {
      if (
        value === TableFieldsValue.SEND_SMS ||
        value === TableFieldsValue.SEND_EMAIL
      ) {
        return <TableHeader textAlign='center'>{label}</TableHeader>;
      }
      return <TableHeader>{label}</TableHeader>;
    }),
    '',
  ];

  //COLUMNS
  const getColumns = (item: Schedule) => [
    ...tableFields.map(({ value }) => {
      switch (value) {
        case TableFieldsValue.RECIPIENTS:
          return (
            <TableCell>{renderRecipentsCellContent(item[value])}</TableCell>
          );
        case TableFieldsValue.SCHEDULED_FOR:
          return (
            <TableCell>
              <TableCellHoverPopup
                width='auto'
                position='above'
                padding='8px 12px'
                showTriangle={true}
                hoverable={
                  <TableCellText>
                    {parseDeliveryUnixTimestampToUserTimezone(
                      item.deliveryUnixTimestamp,
                      userData
                    )}
                  </TableCellText>
                }
                popup={
                  <TableCellText>
                    {parseDeliveryTimeToScheduledTimezone(
                      item.deliveryTime || ''
                    )}
                  </TableCellText>
                }
              />
            </TableCell>
          );
        case TableFieldsValue.VIDEO:
          return (
            <TableCell>
              <TableCellText
                clickable={true}
                onClick={() => {
                  openVideoPreviewModal(item.videoId.toString());
                }}
              >
                {item.video?.title}
              </TableCellText>
            </TableCell>
          );
        case TableFieldsValue.SEND_EMAIL:
          return (
            <TableCell justifyContent='center' paddingLeft='0'>
              {
                <SendCheckbox checked={item[value]}>
                  {item[value] ? (
                    <BiCheck size={16} color={themes.colors.white[100]} />
                  ) : (
                    <BiMinus size={16} color={theme.palette.primaryDarkBlue} />
                  )}
                </SendCheckbox>
              }
            </TableCell>
          );
        case TableFieldsValue.SEND_SMS:
          return (
            <TableCell justifyContent='center' paddingLeft='0'>
              {
                <SendCheckbox checked={item[value]}>
                  {item[value] ? (
                    <BiCheck size={16} color={themes.colors.white[100]} />
                  ) : (
                    <BiMinus size={16} color={theme.palette.primaryDarkBlue} />
                  )}
                </SendCheckbox>
              }
            </TableCell>
          );
        default:
          return null;
      }
    }),
    <TableCell justifyContent='center'>
      <ActionButtonWrapper>
        <HoverPopup
          width='auto'
          position='above'
          padding='8px 12px'
          showTriangle={true}
          hoverable={
            <Button
              onClick={() =>
                handleCancelScheduleClick(
                  item.scheduleId,
                  item.sendEmail,
                  item.sendSms
                )
              }
              variant='destructive'
              icon={<MdDeleteForever size={24} />}
            />
          }
          popup={renderCancelScheduleButtonPopupElement(
            item.sendEmail,
            item.sendSms
          )}
        />
        <HoverPopup
          width='auto'
          position='above'
          padding='8px 12px'
          showTriangle={true}
          hoverable={
            <Button
              onClick={() =>
                openEditScheduleModal(item.scheduleId, item.videoId.toString())
              }
              icon={<MdEdit size={20} />}
              variant='secondary'
            />
          }
          popup={<PopupText>{editScheduleButtonPopupText}</PopupText>}
        />
      </ActionButtonWrapper>
    </TableCell>,
  ];

  const onSelectSchedule = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const checked = e.target.checked;

    if (checked) {
      // add
      setSelectedSchedules([...selectedSchedules, id]);
      return;
    }

    // remove
    setSelectedSchedules([
      ...selectedSchedules.filter(scheduleId => scheduleId !== id),
    ]);
  };

  //ROWS
  const rows =
    initialData?.map((item: Schedule, index: number) => ({
      key: index,
      columns: [
        <CheckboxInput
          id={item.scheduleId.toString()}
          name={item.scheduleId.toString()}
          checked={selectedSchedules.includes(item.scheduleId)}
          onChange={(e: any) => onSelectSchedule(e, item.scheduleId)}
          onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
          }}
        />,
        ...getColumns(item),
      ],
    })) || [];

  const showLoading = isLoadingGetSchedules || isFetchingGetSchedules;
  const noDataToShow = !initialData?.length;
  const showVideoPreviewModal = !!previewVideoId;
  const showEditScheduleModal = !!editScheduleId && !!editScheduleVideoId;
  const showDeleteScheduleModal = !!deleteScheduleId;

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Heading>{pageTitle}</Heading>
        </HeaderWrapper>
        {showLoading && <LoadingIndicator isLoading={true} height='500px' />}
        {!showLoading && noDataToShow && (
          <NoResults>{noSchedulesMessage}</NoResults>
        )}
        {!!selectedSchedules.length && (
          <SelectionHeaderPopup left='208px'>
            <PopupHeaderBody>
              <CounterContainer>
                Schedules Selected: <span>{selectedSchedules.length}</span>
              </CounterContainer>

              <Button
                onClick={() => {
                  setShowMultipleDeleteModal(true);
                }}
                variant='destructive'
                text='Delete'
                icon={<DeleteIcon height={20} color={theme.palette.red100} />}
              />
            </PopupHeaderBody>
          </SelectionHeaderPopup>
        )}
        {!showLoading && !noDataToShow && (
          <TableContextProvider
            total={count}
            initSize={filter.size}
            initPage={filter.page}
            onChange={onPaginationChange}
          >
            <Table
              compact={true}
              headers={headers}
              rows={rows}
              columnWidths={[
                '30px',
                '260px',
                '260px',
                '260px',
                '64px',
                '64px',
                '75px',
              ]}
            />
            <TableFooter>
              <TablePaginationNew />
              <TablePaginationSizeNew text='Show items:' />
            </TableFooter>
          </TableContextProvider>
        )}
        {showVideoPreviewModal && (
          <ModalVideoListVideo
            videoId={previewVideoId}
            handleModalClose={closeVideoPreviewModal}
          />
        )}
      </MainWrapper>
      {showEditScheduleModal && (
        <SendAndShareProvider videoId={toNumber(editScheduleVideoId)}>
          <SendAndShareModal
            handleModalClose={closeEditScheduleModal}
            editScheduleId={editScheduleId}
          />
        </SendAndShareProvider>
      )}
      {(showDeleteScheduleModal || showMultipleDeleteModal) && (
        <ModalDelete
          disabled={isLoadingDeleteSchedule}
          title={
            showMultipleDeleteModal
              ? 'Delete scheduled messages?'
              : `Delete scheduled ${deleteScheduleModalTitleExtra}`
          }
          orangeButtonText={showMultipleDeleteModal ? 'Keep them' : 'Keep it'}
          whiteButtonText={
            showMultipleDeleteModal ? 'Delete them' : 'Delete it'
          }
          text={
            showMultipleDeleteModal
              ? `You are about to delete ${selectedSchedules.length} scheduled messages.`
              : `You are about to delete scheduled ${deleteScheduleModalTitleExtra}.`
          }
          onClickOrangeButton={
            showMultipleDeleteModal
              ? () => setShowMultipleDeleteModal(false)
              : closeConfirmDeleteScheduleModal
          }
          onClickWhiteButton={
            showMultipleDeleteModal
              ? handleMultipleDelete
              : handleDeleteSchedule
          }
          handleModalClose={
            showMultipleDeleteModal
              ? () => setShowMultipleDeleteModal(false)
              : closeConfirmDeleteScheduleModal
          }
        />
      )}
    </Container>
  );
};
