import * as React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal, CheckboxInput } from 'lib/components';
import { Search } from 'lib/components/inputs/Search';
import { CarServiceItem } from 'lib/api/carService/types';
import { useState } from 'react';
import { ServiceActive } from 'lib/const';
import { priceFormatter } from 'lib/utils/functions';
import { MdAdd } from 'react-icons/md';
import { ServiceForm } from '.';
import { HiArrowLeft } from 'react-icons/hi';
import { FiCheck } from 'react-icons/fi';
import { useCarServicesQuery } from 'lib/api/carService/useCarServiceQuery';

type ModalItemProps = {
  minHeight: string;
};
const ModalItem = styled.div<ModalItemProps>`
  position: relative;
  box-sizing: border-box;
  width: 592px;
  max-width: 100%;
  padding: 32px;
  min-height: ${props => props.minHeight};
  background-color: #fff;
`;

const ModalFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 72px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  background: ${theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-right: 32px;
  }
`;
type HeaderProps = {
  justify: string;
};
const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.black_1_100};
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;
`;

const SearchStyled = styled(Search)`
  width: 378px;
  max-width: 100%;
  height: 42px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.themeDark50Percent};
  cursor: pointer;
`;

const Content = styled.div`
  box-sizing: border-box;
  margin-top: 28px;
  max-height: 384px;
  padding-bottom: 36px;
  overflow-y: auto;
`;

const ServiceWrapper = styled.div`
  padding: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
`;
const ServiceTitle = styled.div`
  width: 340px;
  max-width: 100%;
  margin-left: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ServicePrice = styled.div`
  margin-left: auto;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.covideoBlue100};
  text-align: right;
`;

const GoBack = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

enum View {
  Choose,
  Create,
}

type Props = {
  handleModalClose: () => void;
  onSubmit: (servicesToInsert: CarServiceItem[]) => void;
  itemsTrackerMap: { [key: string]: boolean };
};

export const ModalChooseService = ({
  handleModalClose,
  onSubmit,
  itemsTrackerMap,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedService, setSelectedService] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState(View.Choose);

  const { data } = useCarServicesQuery({
    limit: 100,
    start: 0,
    sort: '',
    search: searchQuery,
    filterByActive: ServiceActive.Active,
  });

  const carServices: CarServiceItem[] = data?.services || [];

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const onInsertClick = () => {
    const servicesToInsert = carServices.filter(service =>
      selectedService.some(serviceId => serviceId === service.serviceId)
    );
    onSubmit(servicesToInsert);
    handleModalClose();
  };

  const onSubmitService = async (service: CarServiceItem) => {
    onSubmit([service]);
    handleModalClose();
  };

  const title = `Add Items`;
  const newServiceData = {
    serviceId: '',
    title: '',
    price: '0',
    active: 1,
  };

  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem minHeight={selectedView === View.Choose ? '520px' : '252px'}>
        {selectedView === View.Choose && (
          <>
            <Header justify='space-between'>
              <Title>{title}</Title>
              <CloseButtonWrapper>
                <CloseIcon
                  width={'24px'}
                  height={'24px'}
                  onClick={handleModalClose}
                />
              </CloseButtonWrapper>
            </Header>
            <SubHeader>
              <SearchStyled
                placeholder='Search services...'
                onSearch={onSearch}
              />
              <Button
                onClick={() => setSelectedView(View.Create)}
                text='Create New'
                variant='secondary'
                icon={<MdAdd />}
              />
            </SubHeader>
            <Content>
              {carServices.map((service: CarServiceItem) => {
                if (itemsTrackerMap[service.serviceId]) {
                  return <React.Fragment key={service.serviceId} />;
                }
                return (
                  <ServiceWrapper key={service.serviceId}>
                    <CheckboxInput
                      width='24px'
                      blueCheck={true}
                      checked={selectedService.includes(service.serviceId)}
                      onChange={(e: React.SyntheticEvent) => {
                        let { checked } = e.target as HTMLInputElement;
                        if (checked)
                          setSelectedService([
                            ...selectedService,
                            service.serviceId,
                          ]);
                        else
                          setSelectedService(
                            selectedService.filter(
                              (e: any) => e != service.serviceId
                            )
                          );
                      }}
                    />
                    <ServiceTitle>{service.title}</ServiceTitle>
                    <ServicePrice>{priceFormatter(service.price)}</ServicePrice>
                  </ServiceWrapper>
                );
              })}
            </Content>
            {selectedService.length > 0 && (
              <ModalFooter>
                <Button
                  variant='primary'
                  text={`Insert ${selectedService.length} item${
                    selectedService.length > 1 ? 's' : ''
                  }`}
                  onClick={() => onInsertClick()}
                />
              </ModalFooter>
            )}
          </>
        )}
        {selectedView === View.Create && (
          <>
            <Header justify='flex-start'>
              <GoBack onClick={() => setSelectedView(View.Choose)}>
                <HiArrowLeft color={theme.palette.gray60} />
              </GoBack>
              <Title>New Quote Item</Title>
            </Header>
            <ServiceForm
              serviceData={newServiceData}
              handleSubmit={onSubmitService}
              buttonText='Create'
              buttonIcon={<FiCheck color={theme.palette.white} />}
            />
          </>
        )}
      </ModalItem>
    </Modal>
  );
};
