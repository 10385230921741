import styled from 'styled-components/macro';
import { ReactComponent as CheckmarkSvg } from './checkmark.svg';

const CheckmarkIcon = styled(CheckmarkSvg)`
  width: ${props => props.width || '21px'};
  height: ${props => props.height || '21px'};
  fill: ${props => props.color || 'currentColor'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default CheckmarkIcon;
