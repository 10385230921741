import React from 'react';

import profileImagePlaceholder from 'lib/images/profileImagePlaceholder.png';
import { useHistory, useParams } from 'react-router';
import {
  BlueParagraph,
  BlueParagraphWrapper,
  Chip,
  Divider,
  NoOneChip,
  ProfileInfoWrapper,
  Title,
} from '../styles/ProfileInfoSection';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { theme } from 'lib/style';
import { ModalsType } from '../conversations.types';
import { useAuth, useSockets } from 'lib/context';
import { useGetUsersCustomerQuery } from 'lib/api/users/getUsersCustomers';
import {
  MdAdd,
  MdCreate,
  MdEmail,
  MdOutlineContentCopy,
  MdPhone,
} from 'react-icons/md';
import { isAssignedToYou, isUnassigned } from '../utils/helper';
import { CONVERSATION_STATUS } from 'lib/context/socket/types';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';

interface IProfileInfoSection {
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
  singleConversation: ISingleConversation;
}

const ProfileInfoSection = ({
  singleConversation,
  setModals,
}: IProfileInfoSection) => {
  const history = useHistory();
  const { userStatus } = useSockets();

  const { userData } = useAuth();

  const { customerId, isCompanyAdmin, userId: loggedUser, customer } = userData;

  const { id } = useParams() as { id: string };
  const { contact, userId } = singleConversation;
  let leadsIntegrationActive = !!customer.leadsIntegrationActive;

  //if userId is null we are not fetching customer users
  const { data: customerUsers } = useGetUsersCustomerQuery(
    { customerId },
    !!userId
  );
  const isOnline = userStatus[id] === CONVERSATION_STATUS.ONLINE;

  const user = customerUsers?.find(user => user.id === userId);
  const isUserUnassigned = isUnassigned(userId);
  const isContactEditable =
    !leadsIntegrationActive &&
    isAssignedToYou(userId, userData) &&
    contact.userId === userId;

  const onMoreInfoHandler = () => {
    if (isContactEditable) {
      history.push(`/contacts/list/${contact?.contactId}`);
    }
  };

  const copyToClipBoard = async (email: string) => {
    await navigator.clipboard.writeText(email);
    successToast({
      title: `${email} is successfully copied into the Clipboard.`,
    });
  };

  return (
    <ProfileInfoWrapper>
      <img
        src={profileImagePlaceholder}
        alt='profileImagePlaceholder'
        draggable={false}
      />
      <BlueParagraph>
        {contact?.firstName} {contact?.lastName}
      </BlueParagraph>
      <Gap
        style={{ width: '100%', paddingBottom: 24 }}
        flexDirection='column'
        gap='24px'
      >
        <Chip isOnline={isOnline}>
          {isOnline ? 'Available to Chat' : 'Offline'}
        </Chip>
        {contact?.contactId && isContactEditable && (
          <Gap gap='16px' center style={{ width: '100%' }}>
            <>
              <Button
                variant='secondary'
                text='Details'
                onClick={() =>
                  history.push(`/contacts/list/${contact?.contactId}`)
                }
              />
              <Button
                variant='secondary'
                text='Activity'
                onClick={() =>
                  history.push(
                    `/contacts/list/${contact?.contactId}?tab=activity`
                  )
                }
              />
            </>
          </Gap>
        )}
      </Gap>
      <Divider />
      <Gap style={{ width: '100%' }} alignItems='flex-start'>
        <>
          {contact?.phone ? (
            <Gap onClick={onMoreInfoHandler}>
              <MdPhone color={theme.palette.covideoBlue20} size={24} />
              <div>
                <ParagraphSmall
                  style={{ fontWeight: 600 }}
                  color='#001b53'
                  ellipsis
                  width='170px'
                >
                  {contact?.phone}
                </ParagraphSmall>
              </div>
              {isContactEditable && (
                <MdCreate
                  color='rgba(0, 27, 83, 0.6)'
                  size={20}
                  cursor='pointer'
                />
              )}
            </Gap>
          ) : (
            <>
              {isContactEditable && (
                <Gap onClick={onMoreInfoHandler}>
                  <MdPhone color={theme.palette.covideoBlue20} size={24} />
                  <ParagraphSmall color='rgba(0, 27, 83, 0.6)'>
                    Add phone
                  </ParagraphSmall>
                  <MdAdd
                    color='rgba(0, 27, 83, 0.6)'
                    size={18}
                    cursor='pointer'
                  />
                </Gap>
              )}
            </>
          )}
        </>

        <>
          {contact?.email ? (
            <Gap style={{ width: '100%' }} alignItems='center'>
              <MdEmail color={theme.palette.covideoBlue20} size={24} />
              <div style={{ display: 'flex', gap: 4 }}>
                <ParagraphSmall
                  style={{ fontWeight: 600 }}
                  color='#001b53'
                  ellipsis
                  width='150px'
                >
                  {contact?.email}
                </ParagraphSmall>
                <MdOutlineContentCopy
                  color={theme.palette.covideoBlue20}
                  onClick={() => copyToClipBoard(contact.email)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {isContactEditable && (
                <MdCreate
                  color='rgba(0, 27, 83, 0.6)'
                  size={18}
                  cursor='pointer'
                  onClick={onMoreInfoHandler}
                />
              )}
            </Gap>
          ) : (
            <>
              {isContactEditable && (
                <Gap onClick={onMoreInfoHandler}>
                  <MdEmail color={theme.palette.covideoBlue20} size={24} />
                  <ParagraphSmall color='rgba(0, 27, 83, 0.6)'>
                    Add email
                  </ParagraphSmall>
                  <MdAdd
                    color='rgba(0, 27, 83, 0.6)'
                    size={18}
                    cursor='pointer'
                  />
                </Gap>
              )}
            </>
          )}
        </>
      </Gap>
      <Divider />
      <Gap style={{ paddingTop: 16, width: '100%' }}>
        <Gap
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          style={{ width: '100%' }}
          gap='8px'
        >
          <Title>Assigned user</Title>
          <Gap flexDirection='column' alignItems='flex-start'>
            {!user ? (
              <NoOneChip>No one</NoOneChip>
            ) : (
              <BlueParagraphWrapper>
                <BlueParagraph width='100%'>
                  {user.firstName} {user.lastName}{' '}
                  {`${user.id}` === loggedUser ? '(You)' : null}{' '}
                </BlueParagraph>
              </BlueParagraphWrapper>
            )}
            {isCompanyAdmin ? (
              <Button
                variant={isUserUnassigned ? 'primary' : 'secondary'}
                text={isUserUnassigned ? 'Assign To...' : 'Reassign'}
                onClick={() => setModals('reassign')}
              />
            ) : null}
          </Gap>
        </Gap>
        {!!contact?.emailPreferred || !!contact?.smsPreferred ? (
          <Gap
            style={{ width: '100%' }}
            flexDirection='column'
            alignItems='flex-start'
          >
            <Title>Preferred way of contact</Title>
            <BlueParagraph>
              {contact?.emailPreferred ? 'Email' : ''}
              {contact?.emailPreferred && contact?.smsPreferred ? ', ' : null}
              {contact?.smsPreferred ? 'SMS' : ''}
            </BlueParagraph>
          </Gap>
        ) : null}
      </Gap>
    </ProfileInfoWrapper>
  );
};

export default ProfileInfoSection;
