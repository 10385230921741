import { createResellerReport, updateResellerReport } from 'lib/api';
import { ButtonSwitch, Dropdown, Modal } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { ReportDataProps } from 'lib/context';
import { useToastError } from 'lib/hooks';
import CheckmarkIcon from 'lib/images/CheckmarkIcon';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import { capitalize, omit } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-covideo-common';
import { MdArrowBack } from 'react-icons/md';
import styled, { useTheme } from 'styled-components/macro';
import {
  dayOptions,
  hoursOptions,
  isTimeValid,
  minutesOptions,
  monthlyDaysOptions,
  timeFormats,
  timezonesOptions,
} from '../util';
import { timeRangeConstants } from './DateRangeSelector/DateRangeSelector';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 800px;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 24px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
  margin-bottom: 8px;
`;

const FrequencyContainer = styled.div`
  display: flex;
`;

const DropdownContainer = styled.div<any>`
  width: 200px;
  margin-left: 12px;
  & > .recurringDropdown > div {
    border-radius: 4px;
  }
  & > .recurringMonthlyDropdown {
    width: 200px;
    margin: ${props => props.margin || '0 10px'};
  }
  & > .recurringMonthlyDropdown > div {
    border-radius: 4px;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  > svg {
    position: absolute;
    margin: 12px 8px;
  }
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Day = styled.div<any>`
  width: 40px;
  height: 40px;
  margin-left: 12px;
  padding: 8px 12px;
  border-radius: 5px;
  border: solid 1px #d0d3d9;
  background-color: #d0d3d9;
  box-sizing: border-box;

  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #9297a2;
  cursor: pointer;
  &.active {
    background-color: ${props => props.activeBackgroundColor || '#ff8b22'};
    color: #fff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type RecurringModalProps = {
  title?: string;
  customers: Array<number>;
  handleModalClose: () => void;
  resetStates: () => void;
  report: ReportDataProps;
  onBack: () => void;
};

const frequencies = [
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];

const SimplifiedRecurringModal = ({
  title,
  customers,
  handleModalClose,
  resetStates,
  report,
  onBack,
}: RecurringModalProps) => {
  const { reportData, type } = report;
  const reportId = reportData?.resellerReportId;
  let initialWeekly;
  let initialMonthly;
  let initialFrequencyDetails;
  let initialHours;
  let initialMinutes;
  let initialTimeFormat;
  let initialTimezone;

  if (reportId && reportData) {
    const freqDetails = reportData.frequency.split(';');
    const deliveryTimeDetails = reportData.deliveryTime.split(';');

    initialFrequencyDetails = freqDetails[1];
    if (type === 'weekly') initialWeekly = freqDetails[1];
    else initialMonthly = { value: freqDetails[1], label: freqDetails[1] };

    const timeByHour = deliveryTimeDetails[0].split(':');
    initialHours = { value: timeByHour[0], label: timeByHour[0] };
    initialMinutes = { value: timeByHour[1], label: timeByHour[1] };

    initialTimeFormat = deliveryTimeDetails[1];
    initialTimezone = timezonesOptions.find(
      item => item.value === deliveryTimeDetails[2]
    );
  }

  const frequency = type === 'weekly' ? frequencies[0] : frequencies[1];
  const [frequencyDetails, setFrequencyDetails] = useState(
    initialFrequencyDetails ||
      (type === 'weekly' ? dayOptions[0].value : monthlyDaysOptions[0].value)
  );
  const [weekly, setWeekly] = useState(initialWeekly || dayOptions[0].value);
  const [monthlyDay, setMonthlyDay] = useState(
    initialMonthly || monthlyDaysOptions[0]
  );
  const [hours, setHours] = useState(initialHours || hoursOptions[0]);
  const [minutes, setMinutes] = useState(initialMinutes || minutesOptions[0]);
  const [timeFormat, setTimeFormat] = useState(initialTimeFormat || 'AM');
  const [timezone, setTimezone] = useState(
    initialTimezone || timezonesOptions[0]
  );
  const [_, setLoading] = useState(false);
  const { showError } = useToastError();

  const themes = useTheme();

  const closeWithReset = () => {
    resetStates();
    handleModalClose();
  };

  const { ref } = useOnClickOutside(closeWithReset);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      closeWithReset();
    }
  };
  const isFormValid =
    isTimeValid(`${hours.value}:${minutes.value}`) &&
    !!timezone.value &&
    !!frequencyDetails &&
    customers.length;

  const onCreateReport = async () => {
    setLoading(true);
    const frequencyString = `${frequency.value};${frequencyDetails}`;
    const selectedHour = `${hours.value}:${minutes.value}`;
    const deliveryTime = `${selectedHour};${timeFormat};${timezone.value};${timezone.offset}`;

    const body = {
      customers,
      frequency: frequencyString,
      deliveryTime,
      range:
        type === 'weekly'
          ? timeRangeConstants.LAST_WEEK
          : timeRangeConstants.LAST_30_DAYS,
    };

    try {
      if (reportId) await updateResellerReport(omit(body, 'range'), reportId);
      else await createResellerReport(body);
    } catch (e) {
      showError(e);
    }

    successToast({
      title: `Successfully ${reportId ? 'Edited' : 'Created'} Report`,
    });
    setLoading(false);
    closeWithReset();
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={closeWithReset}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {type === 'weekly' && <Label>Send on</Label>}
          <FrequencyContainer>
            {frequency.value === 'weekly' && (
              <DayContainer>
                {dayOptions.map(day => (
                  <Day
                    key={day.value}
                    className={weekly === day.value ? 'active' : ''}
                    activeBackgroundColor={themes.colors.primary[100]}
                    onClick={() => {
                      setWeekly(day.value);
                      setFrequencyDetails(day.value);
                    }}
                  >
                    {day.label}
                  </Day>
                ))}
              </DayContainer>
            )}
            {frequency.value === 'monthly' && (
              <DropdownContainer
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0',
                }}
              >
                <Label style={{ whiteSpace: 'nowrap', marginBottom: '0' }}>
                  Send on
                </Label>
                <Dropdown
                  width={64}
                  placeholder='Select day of month'
                  value={monthlyDay}
                  onChange={newValue => {
                    setFrequencyDetails(newValue.value);
                    setMonthlyDay(newValue);
                  }}
                  options={monthlyDaysOptions}
                  className='recurringMonthlyDropdown'
                  creatable={false}
                />
                <Label style={{ whiteSpace: 'nowrap', marginBottom: '0' }}>
                  day of month
                </Label>
              </DropdownContainer>
            )}
          </FrequencyContainer>

          <div style={{ marginTop: '32px' }}>
            <Label>Preferred Delivery Time</Label>
            <div style={{ display: 'flex' }}>
              <TimeContainer>
                <Dropdown
                  icon='checkmark'
                  ariaLabel='Choose hours'
                  creatable={false}
                  defaultValue={hoursOptions[0]}
                  value={hours}
                  onChange={newValue => setHours(newValue)}
                  options={hoursOptions}
                  extendStyles={{
                    container: { width: 65 },
                    menu: { width: 95 },
                  }}
                />
                :
                <Dropdown
                  icon='checkmark'
                  ariaLabel='Choose minutes'
                  creatable={false}
                  defaultValue={minutesOptions[0]}
                  value={minutes}
                  onChange={newValue => {
                    setMinutes(newValue);
                  }}
                  options={minutesOptions}
                  extendStyles={{
                    container: { width: 65 },
                    menu: { width: 85 },
                  }}
                />
              </TimeContainer>
              <ButtonSwitch
                defaultValue={timeFormat}
                values={timeFormats}
                onChange={newValue => setTimeFormat(newValue)}
              />
              <DropdownContainer style={{ width: '100%' }}>
                <Dropdown
                  placeholder='Timezone'
                  value={timezone}
                  onChange={newValue => setTimezone(newValue)}
                  options={timezonesOptions}
                  className='recurringDropdown'
                  creatable={false}
                />
              </DropdownContainer>
            </div>
          </div>
        </Content>
        <ButtonContainer>
          <Button
            variant='secondary'
            text='Select Locations'
            onClick={onBack}
            icon={<MdArrowBack />}
          />
          <Button
            text={`${reportId ? `Save` : `Activate`}  ${capitalize(
              type
            )} Report`}
            disabled={!!isFormValid}
            onClick={() => onCreateReport()}
            icon={<CheckmarkIcon />}
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default SimplifiedRecurringModal;
