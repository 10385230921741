import { Viewer } from 'lib/api/recipients/types';
import { useQuery } from 'react-query';
import { recipientsKeys } from './recipientsKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface VideosRecipientsParams {
  isCompany: number;
  size: number;
}

const getVideosRecipients = async (
  videoId: string,
  params: VideosRecipientsParams
) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/recipients`, {
    params,
  });

  const viewers = response.data
    .map((d: Viewer) => ({
      ...d,
      name: (d.name || '').trim() || '-',
      notification: d.sendReply === 1,
    }))
    .reverse();

  return { viewers };
};

export const useVideoRecipients = (
  videoId: string,
  params: VideosRecipientsParams
) => {
  return useQuery(
    recipientsKeys.video_recipients(videoId, params),
    () => getVideosRecipients(videoId, params),
    {
      refetchOnMount: true,
    }
  );
};
