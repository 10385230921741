import React, { useEffect, useState } from 'react';
import SocialStatsTable from './SocialStatsTable';
import { InsightsWrapper } from './styles/socialInsights';
import { getFacebookVideoAnalytics } from 'lib/api/facebookApi';
import { LoadingIndicator } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { getVideoAnalytics } from 'lib/api/youtubeApi';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { errorToast } from 'lib/components/toasts/error';
import { getLinkedInVideoAnalytics } from 'lib/api/linkedinApi';
interface ISocialInsights {
  videoId: string;
}
export interface IAnalyticsProps {
  comments?: number;
  likes?: number;
  reactions?: number;
  views?: number;
  dislikes?: number;
  publishedAt: Date;
  postId: string;
  integrationType: string;
  organizationId?: string | null;
  organizationName?: string | null;
}

export interface IAnalyticsLogsProps {
  id: number;
  integrationType: string;
  pageId: string;
  pageToken: string;
  postId: string;
  publishedAt: string;
  userId: number;
  videoId: number;
}

const NoStats = styled.div`
  margin-top: 34px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
  font-weight: 500;
`;

const SocialInsights = ({ videoId }: ISocialInsights) => {
  const [facebookAnalytics, setFacebookAnalytics] = useState<IAnalyticsProps[]>(
    []
  );
  const [youtubeAnalytics, setYoutubeAnalytics] = useState<IAnalyticsProps[]>(
    []
  );
  const [linkedInAnalytics, setLinkedInAnalytics] = useState<IAnalyticsProps[]>(
    []
  );
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [youTubeLoading, setYouTubeLoading] = useState(false);
  const [linkedInLoading, setLinkedInLoading] = useState(false);
  const [isYoutubeFeatureAvailable, setIsYoutubeFeatureAvailable] =
    React.useState(false);
  const [isFacebookFeatureAvailable, setIsFacebookFeatureAvailable] =
    React.useState(false);
  const [isLinkedInFeatureAvailable, setIsLinkedInFeatureAvailable] =
    React.useState(false);
  const { userData } = useAuth();
  const handleYoutubeAnalytics = async () => {
    try {
      setYouTubeLoading(true);
      const youtubeMetrics = await getVideoAnalytics(parseInt(videoId));
      // Array.isArray is here to make sure it won't fail if there is object response provided
      if (youtubeMetrics && Array.isArray(youtubeMetrics)) {
        setYoutubeAnalytics(youtubeMetrics);
      }
      setYouTubeLoading(false);
    } catch (error) {
      setYoutubeAnalytics([]);
      setYouTubeLoading(false);
    }
  };

  const handleFacebookAnalytics = async () => {
    try {
      setFacebookLoading(true);
      const facebookMetrics = await getFacebookVideoAnalytics(
        parseInt(videoId)
      );
      if (facebookMetrics.status === 404) {
        setFacebookLoading(false);
        return;
      }
      if (facebookMetrics.status === 401) {
        errorToast({
          title: facebookMetrics.data.message,
        });
        setFacebookLoading(false);
        return;
      }
      // facebook returns object when there is an error
      // 401 and 404 part are covered but Array.isArray is here to make sure it won't fail if there is another object response provided
      if (facebookMetrics && Array.isArray(facebookMetrics)) {
        setFacebookAnalytics(facebookMetrics);
      }
      setFacebookLoading(false);
    } catch (error) {
      setFacebookLoading(false);
    }
  };

  const handleLinkedInAnalytics = async () => {
    try {
      setLinkedInLoading(true);
      const linkedInMetrics = await getLinkedInVideoAnalytics(
        parseInt(videoId)
      );
      if (linkedInMetrics.status === 404) {
        setLinkedInLoading(false);
        return;
      }
      if (linkedInMetrics.status === 401) {
        errorToast({
          title: linkedInMetrics.data.message,
        });
        setLinkedInLoading(false);
        return;
      }
      if (linkedInMetrics && Array.isArray(linkedInMetrics)) {
        setLinkedInAnalytics(linkedInMetrics);
      }
      setLinkedInLoading(false);
    } catch (error) {
      setLinkedInLoading(false);
    }
  };

  useEffect(() => {
    setIsYoutubeFeatureAvailable(
      checkIfFeatureIsEnabled(userData, productFeature.YOUTUBE)
    );
    setIsFacebookFeatureAvailable(
      checkIfFeatureIsEnabled(userData, productFeature.FACEBOOK)
    );
    setIsLinkedInFeatureAvailable(
      checkIfFeatureIsEnabled(userData, productFeature.LINKEDIN)
    );
  }, []);

  useEffect(() => {
    if (!isYoutubeFeatureAvailable) {
      return;
    }

    handleYoutubeAnalytics();
  }, [isYoutubeFeatureAvailable]);

  useEffect(() => {
    if (!isFacebookFeatureAvailable) {
      return;
    }

    handleFacebookAnalytics();
  }, [isFacebookFeatureAvailable]);

  useEffect(() => {
    if (!isLinkedInFeatureAvailable) {
      return;
    }

    handleLinkedInAnalytics();
  }, [isLinkedInFeatureAvailable]);

  const loadingMetrics = facebookLoading || youTubeLoading || linkedInLoading;
  const metrics = [
    ...facebookAnalytics,
    ...youtubeAnalytics,
    ...linkedInAnalytics,
  ];

  return (
    <InsightsWrapper loading={loadingMetrics || metrics.length === 0}>
      {loadingMetrics ? (
        <LoadingIndicator isLoading={loadingMetrics} />
      ) : !loadingMetrics && metrics.length === 0 ? (
        <>
          <NoStats>No data to show.</NoStats>
        </>
      ) : (
        metrics.map(analytic => {
          return (
            analytic && (
              <SocialStatsTable data={analytic} key={analytic.postId} />
            )
          );
        })
      )}
    </InsightsWrapper>
  );
};

export default SocialInsights;
