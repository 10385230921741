import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  ShareSettings,
  VehicleSearch,
  builderLandingPageTypeId,
  defaultLPOption,
  LandingPageOption,
  LandingPageType,
  ShareButtons,
  SendShareVideoForm,
  TabWrapper,
  ModalHeader,
  HeaderSide,
  LinkWrapper,
  LinkBtn,
  HeaderCenter,
  ModalBody,
  getTabs,
  freemiumLPOption,
  freemiumCTAOption,
} from '../';
import { useAuth, InventoryItem } from 'lib/context';
import { EmailIcon, EmailIconDefault, EmailTemplate } from 'lib/api';

import { uniqBy } from 'lodash';
import { MdClose } from 'react-icons/md';
import { ButtonPillSwitch } from 'lib/components';
import { VideoTitleAndThumbnail } from '../components';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { useSendAndShare } from 'lib/context/send-and-share/provider';
import { useGetEmailIconsQuery } from 'lib/api/emailIcons/getEmailIcons';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { GetQuickshareParams } from 'lib/api/quickshare/useGetQuickshareDetails';

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 50px;
`;

const Main = styled.div`
  margin-right: 32px;
  width: 384px;
  max-width: 100%;
`;

const Side = styled.div`
  box-sizing: border-box;
  width: 384px;
  max-width: 100%;
  padding: 16px;
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
`;

const VehicleSearchWrapper = styled.div`
  margin: 0 0 16px;
`;

const Label = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: 0 0 8px 0;
`;

type Props = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  handleModalClose: () => void;
  notify: boolean;
};

export const ShareTab = ({
  activeTab,
  setActiveTab,
  handleModalClose,
  notify,
}: Props) => {
  const { userData, whitelabel } = useAuth();
  const {
    overlays: overlaysData,
    templates,
    linksets: linksetData,
    video,
    vin,
    changeVin,
  } = useSendAndShare();
  const videoRequest = video?.videoRequest;
  const hideLegacyYoutubeIntegration =
    whitelabel?.name === WHITELABEL_NAME.COVIDEO;
  const { customer } = userData;
  const tabs = getTabs(userData);
  const isWebsiteOverlayEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.WEBSITE_OVERLAY
  );
  const isCTASEnabled = checkIfFeatureIsEnabled(userData, productFeature.CTAS);

  const { data: emailIcons } = useGetEmailIconsQuery({ limit: 100 });

  // Customer's reactionPreference have three values: 0, 1, 2
  // Reactions disabled if customer preference is 0 or not set
  // Reactions disabled if video preference is 0 or not set
  const reactionsEnabled =
    !!customer.reactionsPreference && !!video.reactionsPreference;
  const videoReplyEnabled =
    customer.allowReplies?.toString() === '1' && !!video.videoReplyEnabled;

  const [disableShare, setDisableShare] = useState(false);
  const [emailIconOptions, setEmailIconOptions] = React.useState<
    EmailIconDefault[]
  >([]);
  const [selectedEmailIcon, setSelectedEmailIcon] = useState<
    EmailIconDefault | undefined
  >(undefined);

  const [shareData, setShareData] = useState<GetQuickshareParams>({
    templateId: isWebsiteOverlayEnabled ? 0 : freemiumLPOption.value,
    linksetId: isCTASEnabled ? '' : freemiumCTAOption.value,
    overlayId: '',
    vin: '',
    allowReactions: reactionsEnabled,
    allowVideoReply: videoReplyEnabled,
    iconId: '',
    notifyUser: notify,
  });
  const [templatesOptions, setTemplatesOptions] = useState<LandingPageOption[]>(
    []
  );

  const [selectedTemplate, setSelectedTemplate] =
    useState<LandingPageOption>(defaultLPOption);

  const salesServicePageOption = {
    value: -2,
    label: `${userData.isAutomotiveServiceRole ? 'Service' : 'Sales'} page`,
    landingPageType: LandingPageType.AUTOMOTIVE,
  };

  // flag to disable static php landing pages, show only custom landing pages (built by our builder) and sales/service page
  const disableStaticLandingPages = userData.isAutomotiveSalesRole && !!vin;

  // flag to disable cds landing page, to use vidmails only
  const disableCDSLandingPage =
    !userData.isAutomotive || userData.isAutomotiveServiceRole;

  const transformTemplatesData = async () => {
    if (!isWebsiteOverlayEnabled) {
      setShareData(old => ({
        ...old,
        templateId: freemiumLPOption.value,
      }));
      setTemplatesOptions([freemiumLPOption]);
      setSelectedTemplate(freemiumLPOption);
      return;
    }
  };

  const transformEmailIcons = async () => {
    let options: EmailIconDefault[] = emailIconOptions;

    options = options.concat(
      emailIcons?.icons.map(({ id, title, thumbnail }: EmailIcon) => ({
        title,
        thumbnail,
        type: 'icons',
        id: id,
      })) ?? []
    );

    options = options.concat(
      emailIcons?.templates?.map(({ id, title, thumbnail }: EmailTemplate) => ({
        title,
        thumbnail,
        type: 'templates',
        id: id,
      })) ?? []
    );

    options = uniqBy(options, 'id');
    if (emailIcons?.default) {
      const defaultItem = options.find(
        option => option.id === emailIcons.default.id
      );

      setSelectedEmailIcon(defaultItem);
    }

    setEmailIconOptions(options);
  };

  const filterLPAndSelectDefault = () => {
    if (!templates) {
      return;
    }
    let autoSelectedOption = disableCDSLandingPage
      ? defaultLPOption
      : salesServicePageOption;
    const defaultLandingPage = templates?.default;
    let options = (templates?.templates || [])
      .filter(
        (template: any) =>
          !disableStaticLandingPages ||
          template.typeId === builderLandingPageTypeId
      )
      .map((template: any) => ({
        label: template.title,
        value: template.id,
        landingPageType:
          template.typeId !== builderLandingPageTypeId
            ? LandingPageType.VIDMAILS
            : LandingPageType.AUTOMOTIVE,
      }));
    // add automotive LP as option if not disabled
    if (!disableCDSLandingPage) {
      options = [...options, salesServicePageOption];
    }
    // add chosen default LP as option if not disabled (static not disabled or not static)
    if (
      defaultLandingPage?.id &&
      (!disableStaticLandingPages ||
        defaultLandingPage?.typeId === builderLandingPageTypeId)
    ) {
      const defaultLandingPageOption = {
        label: templates.default.title,
        value: templates.default.id,
        landingPageType:
          defaultLandingPage?.typeId === builderLandingPageTypeId
            ? LandingPageType.AUTOMOTIVE
            : LandingPageType.VIDMAILS,
      };
      autoSelectedOption = defaultLandingPageOption;
      options = [...options, defaultLandingPageOption];
    }
    options = uniqBy(options, 'value');
    // generate new url
    setShareData(old => ({
      ...old,
      templateId: parseInt(autoSelectedOption.value.toString(), 10),
    }));
    // set template options for dropdown
    setTemplatesOptions(options);
    // select autoSelectedOption as chosen (default or automotive)
    setSelectedTemplate(autoSelectedOption);
  };

  useEffect(() => {
    transformTemplatesData();
    transformEmailIcons();
  }, []);

  useEffect(() => {
    setDisableShare(shareData.templateId === -1 && !shareData.overlayId);
  }, [shareData]);

  useEffect(() => {
    if (shareData.overlayId !== '') return;
    if (overlaysData?.websiteUrls?.length > 0)
      setShareData(old => ({
        ...old,
        overlayId: overlaysData.websiteUrls[0].id,
      }));
  }, [shareData, overlaysData.websiteUrls]);

  useEffect(() => {
    if (shareData.linksetId !== '') return;
    linksetData?.linksets?.forEach(linkSetData => {
      if (linkSetData.defaultLinks) {
        setShareData(old => ({ ...old, linksetId: linkSetData.id }));
      }
    });
  }, [shareData, linksetData.linksets]);

  useEffect(() => {
    setShareData(old => ({ ...old, vin: vin }));
  }, [vin]);

  useEffect(() => {
    if (!templates) {
      return;
    }
    // filter template options depending on is vehicle selected
    filterLPAndSelectDefault();
  }, [vin, templates]);

  return (
    <TabWrapper display={activeTab === tabs.share.value ? 'block' : 'none'}>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter>
          <ButtonPillSwitch
            defaultValue={activeTab}
            values={tabs}
            onChange={newTab => setActiveTab(newTab)}
          />
        </HeaderCenter>
        <HeaderSide></HeaderSide>
      </ModalHeader>
      <ModalBody>
        <ContentWrapper>
          <Main>
            <ShareButtons
              video={video}
              disableShare={disableShare}
              shareData={shareData}
              hideLegacyYoutubeIntegration={hideLegacyYoutubeIntegration}
            />
          </Main>
          <Side>
            <VideoTitleAndThumbnail video={video} marginBottom='24px' />
            {userData.isAutomotiveSalesRole && (
              <VehicleSearchWrapper>
                <Label>Search vehicle</Label>
                <VehicleSearch
                  onChangeVehicle={(item: InventoryItem) =>
                    changeVin(item?.vin || '')
                  }
                  selectedVin={vin}
                  fetchSold={false}
                  customSelectStyle={{
                    menu: (base: any) => ({
                      ...base,
                      minWidth: 465,
                    }),
                    placeholder: (base: any) => ({
                      ...base,
                      fontSize: '14px',
                    }),
                  }}
                  dropdownOptionLabelMaxWidth='95%'
                  limitedSingleValueWidth='290px'
                />
              </VehicleSearchWrapper>
            )}
            <ShareSettings
              shareData={shareData}
              setShareData={setShareData}
              overlaysData={
                overlaysData.websiteUrls
                  ? overlaysData.websiteUrls.map(url => ({
                      value: url.id.toString(),
                      label: url.title,
                    }))
                  : []
              }
              templateOptions={templatesOptions}
              linksetsData={linksetData.linksets}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              emailIconOptions={emailIconOptions}
              selectedEmailIcon={selectedEmailIcon}
              setSelectedEmailIcon={setSelectedEmailIcon}
            />
            <SendShareVideoForm
              videoRequest={videoRequest}
              showNotifyToggle={true}
              notify={shareData.notifyUser}
              setNotify={(val: boolean) =>
                setShareData(old => ({ ...old, notifyUser: val }))
              }
              allowReactions={shareData.allowReactions}
              reactionsEnabled={reactionsEnabled}
              videoReplyEnabled={videoReplyEnabled}
              setAllowReactions={(val: boolean) =>
                setShareData(old => ({ ...old, allowReactions: val }))
              }
              allowVideoReply={shareData.allowVideoReply}
              setAllowVideoReply={(val: boolean) =>
                setShareData(old => ({ ...old, allowVideoReply: val }))
              }
            />
          </Side>
        </ContentWrapper>
      </ModalBody>
    </TabWrapper>
  );
};
