import { RecordOptionPath } from 'app/pages/record/const';
import { ModalRecordHome } from 'app/pages/record/recordModal/ModalRecordHome';
import { ModalRecordOption } from 'app/pages/record/recordModal/ModalRecordOption';
import React, { useState } from 'react';
import {
  GetSignedUrlsParams,
  getVoiceoverSignedURLs,
  uploadVoiceoverAudio,
} from 'lib/api';
import { generateTempFileName } from 'app/pages/video/videoVoiceover/components/util';
import { useToastError } from 'lib/hooks';

type Props = {
  handleShowRecordHome: (show: boolean) => void;
  onRecordingUploaded: (uploadedUrl: string, previewUrl: string) => void;
};

export const AddRecordingToMerge = ({
  handleShowRecordHome,
  onRecordingUploaded,
}: Props) => {
  const { showError } = useToastError();
  const [showRecordOption, setShowRecordOption] =
    useState<RecordOptionPath | null>(null);

  const [uploading, setUploading] = useState(false);

  // Uploads temp video file to self-deleting voiceover bucket
  const handleRecordingBlobReady = async (url: string) => {
    setUploading(true);
    setShowRecordOption(null);
    const blob = await fetch(url || '').then(r => r.blob());
    const tempFileName = generateTempFileName('merge_recording', 'webm');
    const file = new File([blob], tempFileName);

    const signedUrlData: GetSignedUrlsParams = {
      fileName: file.name,
      mimeType: 'video/webm',
      folder: '',
    };
    const signedURL = await getVoiceoverSignedURLs(signedUrlData);
    if (!signedURL || signedURL instanceof Error) {
      setUploading(false);
      showError(signedURL);
      return;
    }

    const data = {
      file: file as File,
      uploadURL: signedURL.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };
    try {
      await uploadVoiceoverAudio({ data });
    } catch (error) {
      setUploading(false);
      showError(error);
      return;
    }
    setUploading(false);
    onRecordingUploaded(signedURL.downloadUrl, url);
  };

  return (
    <>
      <ModalRecordHome
        handleModalClose={() => handleShowRecordHome(false)}
        onRecordOptionClick={(recordOption: RecordOptionPath | null) => {
          setShowRecordOption(recordOption);
        }}
        loading={uploading}
      />
      {showRecordOption && (
        <ModalRecordOption
          handleModalClose={() => {
            handleShowRecordHome(false);
            setShowRecordOption(null);
          }}
          handleShowRecordHome={() => {
            handleShowRecordHome(true);
            setShowRecordOption(null);
          }}
          handleRecordingBlobReady={handleRecordingBlobReady}
          recordOption={showRecordOption}
        />
      )}
    </>
  );
};
