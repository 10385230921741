import React from 'react';
import { CheckboxInput, Modal, NewTextInput } from 'lib/components';
import { useOnClickOutside } from 'lib/utils';
import { PageHeader } from 'app/pages/account/userAccount/addOns/components/PageElements';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import {
  RequestVinSolutionsIntegration,
  ReqestEleadIntegration,
  RequestCodirect,
  requestAddon,
  requestAffiliateAddon,
} from 'lib/api/addonsApi';
import { useHistory } from 'react-router-dom';
import { successToast } from '../toasts/success';
import { Button } from 'react-covideo-common';

type Props = {
  handleModalClose: () => void;
  subject: string;
  title?: string;
  description?: string | React.ReactNode;
};

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled(PageHeader)`
  font-size: 18px;

  font-weight: 700;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  margin-top: 24px;
  margin-right: 24px;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin: 0px 30px 30px 30px;
  width: 592px;

  font-weight: 400;
`;

const Note = styled.textarea`
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Group = styled.div`
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  color: ${theme.palette.neutral80};
  label {
    &:nth-child(1) {
      margin: 0 12px 0 0;
    }
  }
`;

const Textarea = styled(NewTextInput)`
  color: #7b7b7b;
  background-color: #dfe3e6;

  &:focus {
    color: #7b7b7b;
    background-color: #dfe3e6;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  place-content: flex-end;
`;

const Description = styled.div``;

export const ModalContactAM = ({
  handleModalClose,
  subject,
  title,
  description,
}: Props) => {
  const { ref } = useOnClickOutside(handleModalClose);
  const [notes, setNotes] = React.useState('');
  const [emailSelected, setEmailSelected] = React.useState(false);
  const [phoneSelected, setPhoneSelected] = React.useState(false);

  const history = useHistory();

  const SendRequest = async () => {
    if (subject === 'Codirect Request') {
      await RequestCodirect({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
      }).then(data => {
        successToast({
          title: data,
        });

        history.push('/home');
      });
    } else if (subject === 'Elead Integration') {
      await ReqestEleadIntegration({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
      }).then(data => {
        successToast({
          title: data,
        });

        history.push('/integrations');
      });
    } else if (subject === 'VinSolutions Integration') {
      await RequestVinSolutionsIntegration({
        notes,
        email: emailSelected,
        phone: phoneSelected,
      }).then(data => {
        successToast({
          title: data,
        });
        history.push('/integrations');
      });
    } else if (subject === 'Covideo Legacy Plan Update') {
      await requestAddon({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
        isBilling: true,
      });
      handleModalClose();
      history.push('/billing');
    } else if (subject === 'Affiliate Earnings Request') {
      await requestAffiliateAddon({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
      }).then(data => {
        successToast({
          title: data,
        });

        history.push('/home');
      });
    } else if (subject === 'Covideo Subscription Update Request') {
      await requestAddon({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
        isBilling: true,
      }).then(data => {
        handleModalClose();
        successToast({
          title: data,
        });
      });
    } else {
      await requestAddon({
        subject,
        notes,
        email: emailSelected,
        phone: phoneSelected,
      }).then(data => {
        successToast({
          title: data,
        });

        history.push('/home');
      });
    }
  };

  return (
    <Modal>
      <div ref={ref}>
        <Header>
          <HeaderTitle>{title ? title : 'Contact Account Manager'}</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {description ? (
            <>
              <Description>{description}</Description>
              <br />
            </>
          ) : null}
          <Group>
            <Row>Subject</Row>
            <Row>
              <Textarea
                readOnly
                value={subject}
                placeholder={'Enter subject...'}
                required={true}
              />
            </Row>
          </Group>
          <Group>
            <Row>Write a note or a question for Account Manager</Row>
            <Row>
              <Note onChange={(e: any) => setNotes(e.target.value)}></Note>
            </Row>
          </Group>
          <Group>
            <Row>Preferred method of contact</Row>
            <Row>
              <CheckboxInput
                name='contactEmail'
                id='contactEmail'
                checked={emailSelected}
                onChange={() => setEmailSelected(!emailSelected)}
              />
              Email
            </Row>
            <Row>
              <CheckboxInput
                name='contactPhone'
                id='contactPhone'
                checked={phoneSelected}
                onChange={() => setPhoneSelected(!phoneSelected)}
              />
              Phone
            </Row>
          </Group>
          <ButtonRow>
            <Button
              text='Send Message'
              disabled={!(emailSelected || phoneSelected)}
              onClick={SendRequest}
            />
          </ButtonRow>
        </Content>
      </div>
    </Modal>
  );
};
