import React from 'react';
import { BsXCircleFill } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components/macro';

import { LoadingIndicator } from 'lib/components';
import { ModalRemoveUser } from 'lib/components/modal/ModalRemoveUser';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { getAllDepartmentsForCustomer } from 'lib/api';
import { IoMdArrowForward } from 'react-icons/io';

interface Field {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  selectable: boolean;
}

type FieldProps = {
  isSelected: boolean;
  selectable: boolean;
};

const Body = styled.div`
  display: flex;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AllFieldsWrap = styled.div`
  // box-sizing:border-box;

  border-radius: 7px;
  display: flex;
  flex-direction: column;
  width: 232px;
  height: 268px;
  background-color: #f6f7f9;
  padding: 12px;
  position: relative;
`;

const ResultsFieldsWrap = styled.div`
  border-radius: 7px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f6f7f9;
`;

const AllSelectedFieldsWrap = styled.div`
  align-self: flex-end;
  text-align: right;
  height: 100%;
`;

const SelectedFieldsWrap = styled.div`
  // box-sizing:border-box;
  border-radius: 7px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 232px;
  height: 268px;
  padding: 12px;
  position: relative;
  background-color: #f6f7f9;
  border-color: #eeeff2;
  border-style: solid;
  border-width: 1px;
`;

const FieldWrap = styled.div<FieldProps>`
  width: 100%;
  margin-top: 12px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${props => (props.isSelected || !props.selectable ? '0.3' : '1')};
  transition: all 0.1s;
`;

const SelectedFieldWrap = styled(FieldWrap)`
  background-color: #fff;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
  margin: 12px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #4e5461;
  color: white;
  cursor: pointer;
  font-size: 22px;
  margin: 12px;
`;

const SelectedIconWrap = styled(IconWrap)`
  background-color: transparent;
  opacity: 1;
`;

const NoResultsFound = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: #4e5461;
  display: flex;
`;

const ManageFilesBody = styled(Body)`
  margin-top: 50px;
`;

const InfoText = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #272a32;
  margin-top: 35px;
`;

const WrapTitle = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #9297a2;
`;

export const DepartmentLimitTabContent = ({
  selectedFields,
  setSelectedFields,
}: any) => {
  const {
    userData: { customerId },
  } = useAuth();

  const [loading, setLoading] = React.useState(true);
  const [departments, setDepartments] = React.useState([]);
  const [showRemoveModalFor, setShowRemoveModalFor] = React.useState<Field>();

  const onAddClick = (field: Field) => {
    setSelectedFields([...selectedFields, field]);
  };

  const onRemoveClick = (field: Field) => {
    removeUser(field);
  };

  const removeUser = (field: Field) => {
    if (isSelected(field)) {
      let newSelectedFields = [...selectedFields];
      newSelectedFields.splice(selectedFields.indexOf(field), 1);
      setSelectedFields(newSelectedFields);
      setShowRemoveModalFor(undefined);
    }
  };

  const isSelected = (field: any): boolean => {
    return !!selectedFields.find((item: any) => {
      return item.id === field.id;
    });
  };

  const fetchDepartments = async () => {
    setLoading(true);
    const departments = await getAllDepartmentsForCustomer(customerId);
    setDepartments(departments.data);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchDepartments();
  }, []);

  return (
    <>
      {showRemoveModalFor && showRemoveModalFor.id > 0 && (
        <ModalRemoveUser
          handleModalConfirm={() => {
            removeUser(showRemoveModalFor);
          }}
          handleModalClose={() => {
            setShowRemoveModalFor(undefined);
          }}
          user={showRemoveModalFor}
          type='Files'
        />
      )}
      <InfoText>
        Select departments you want to enable this attribute for
      </InfoText>
      <ManageFilesBody>
        <Row>
          {loading && (
            <div style={{ width: '524px' }}>
              <LoadingIndicator isLoading={loading} />
            </div>
          )}
          {!loading && (
            <>
              <Col>
                <WrapTitle>Available</WrapTitle>
                <AllFieldsWrap>
                  <ResultsFieldsWrap>
                    {departments.length === 0 && (
                      <NoResultsFound>No Departments Found.</NoResultsFound>
                    )}
                    {departments &&
                      departments.length &&
                      departments.map((field: any) => {
                        if (isSelected(field)) {
                          return <React.Fragment key={field.id} />;
                        } else {
                          return (
                            <FieldWrap
                              key={field.id}
                              isSelected={isSelected(field)}
                              selectable={true}
                            >
                              <Text>{field.name}</Text>
                              <IconWrap onClick={() => onAddClick(field)}>
                                <MdAdd />
                              </IconWrap>
                            </FieldWrap>
                          );
                        }
                      })}
                  </ResultsFieldsWrap>
                </AllFieldsWrap>
              </Col>
              <Col>
                <div
                  style={{
                    width: '60px',
                    height: '100%',
                    fontSize: '22px',
                    color: '#D0D3D9',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IoMdArrowForward />
                </div>
              </Col>
              <Col>
                <WrapTitle>Selected</WrapTitle>
                <AllSelectedFieldsWrap>
                  <SelectedFieldsWrap>
                    {selectedFields.length === 0 && (
                      <NoResultsFound>
                        Users in all departments will see this attribute{' '}
                      </NoResultsFound>
                    )}
                    {selectedFields &&
                      !!selectedFields.length &&
                      selectedFields.map((field: any) => (
                        <SelectedFieldWrap
                          key={field.id}
                          isSelected={false}
                          selectable={true}
                        >
                          <Text>{field.name}</Text>
                          <SelectedIconWrap
                            onClick={() => onRemoveClick(field)}
                          >
                            <BsXCircleFill color='#E84C3D' fillOpacity={0.8} />
                          </SelectedIconWrap>
                        </SelectedFieldWrap>
                      ))}
                  </SelectedFieldsWrap>
                </AllSelectedFieldsWrap>
              </Col>
            </>
          )}
        </Row>
      </ManageFilesBody>
    </>
  );
};
