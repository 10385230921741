import React from 'react';
import {
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getInputField,
} from '../../../components/AdminFormComponents';

export const ContactSection = (props: UserFormProps) => {
  const { isSubmitting, isNewUser } = props;

  return (
    <Section id={'contactInfo'}>
      <SectionTitle>Contact</SectionTitle>
      <RowWrapper>
        {!isNewUser && (
          <Row>
            <FieldLabel>Email</FieldLabel>
            {getInputField('email', true, false, isSubmitting, 'email')}
          </Row>
        )}
        <Row>
          <FieldLabel>Phone 1</FieldLabel>
          {getInputField('phone1', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Phone 2</FieldLabel>
          {getInputField('phone2', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Cell Phone</FieldLabel>
          {getInputField('cellPhone', true, false, isSubmitting)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
