import React, { useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '../../../../../lib/components/textEditor/editorStyles.css';
import EmptyIcon from 'lib/images/empty-icon.svg';

type Props = {
  onTextEditorChange: (text: string) => void;
  setTextEditorBody?: (text: string) => void;
  initialContent?: string;
  height?: string;
  placeholder: string;
  handleRemoveAttachment?: (fileName: string) => void;
  handleAddAttachment?: (files: FileList) => void;
  attachedFiles?: FileList;
  disableAttachButton?: boolean;
  sectionDetails?: any;
};

export const LpbTextEditor = (props: Props) => {
  const { onTextEditorChange, initialContent, height, placeholder } = props;

  let content = initialContent || '';
  if (content && content.indexOf('<p>') !== 0) {
    content = `<p>${initialContent}</p>`;
  }

  const blocksFromHTML = htmlToDraft(content);
  const initialData = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialData)
  );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onTextEditorChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
        .replace(/(<p>)/g, '')
        .replace(/(<\/p>)/g, '')
    );
  };

  return (
    <Editor
      editorStyle={{
        minHeight: height || 300,
        border: '1px solid #dfe3e6',
        padding: 12,
        fontFamily: 'Verdana',
      }}
      placeholder={placeholder}
      editorState={editorState}
      // keyBindingFn={keyBindingFn}
      wrapperClassName='demo-wrapper'
      editorClassName='demo-editor word-wrap-fix'
      toolbarClassName='demo-toolbar'
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['link', 'inline', 'textAlign', 'colorPicker'],
        inline: {
          inline: { inDropdown: true },
          inDropdown: true,
          options: ['underline'],
          className: 'toolbar-underline-icon',
          underline: {
            className:
              'toolbar-custom-icon toolbar-custom-link-dropdown-option toolbar-underline-icon small-icon',
            icon: EmptyIcon,
          },
        },
        colorPicker: {
          className:
            'toolbar-custom-icon lpbColorPicker toolbar-eyedropper-icon small-icon',
          icon: EmptyIcon,
        },
        textAlign: {
          textAlign: { inDropdown: false },
          inDropdown: false,
          options: ['left', 'center', 'right'],
          left: {
            className: 'toolbar-custom-icon toolbar-align-left-icon',
            icon: EmptyIcon,
          },
          center: {
            className: 'toolbar-custom-icon toolbar-align-center-icon',
            icon: EmptyIcon,
          },
          right: {
            className: 'toolbar-custom-icon toolbar-align-right-icon',
            icon: EmptyIcon,
          },
        },
        link: {
          inDropdown: true,
          className: 'toolbar-link-icon',
          options: ['link', 'unlink'],
          link: {
            className: 'toolbar-link-icon',
            icon: EmptyIcon,
          },
          unlink: {
            className: 'toolbar-unlink-icon',
            icon: EmptyIcon,
          },
        },
      }}
    />
  );
};
