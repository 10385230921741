import * as React from 'react';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from 'lib/components';
import { useHistory, useLocation } from 'react-router-dom';
import { VideoMerge } from './videoMerge';
import {
  ModalMergeCreateNew,
  ModalVideoMergeListVideo,
} from 'lib/components/modal';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import RouteLeavingGuard from '../../videoDetails/main/RouteLeavingGuard';
import { NotFound } from 'app/pages/notFound/NotFound';
import { AddRecordingToMerge } from '../components/AddRecordingToMerge';
import { v4 as uuidv4 } from 'uuid';
import { EXTERNAL_VIDEO_PREFIX } from 'app/pages/record/const';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';
import { useVideosMergeMutation } from 'lib/api/videos/useVideosMergeMutation';
import { useGetMultipleVideos } from 'lib/api/videos/useGetMultipleVideos';

const MainContainer = styled.main`
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  min-height: 500px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 64px 24px 84px 24px;
  }
`;

export const Main = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const ids = (urlParams.get('videos') || '').split(',').filter(Boolean);
  const [videosToMerge, setVideosToMerge] = useState<any[]>(
    ids.map(id => ({ id }))
  );
  const history = useHistory();
  const [showMergeModal, setMergeModal] = React.useState(false);
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [showRecordHome, setShowRecordHome] = useState(false);
  const { mutateAsync: mergeVideos, isLoading: isMergeVideosLoading } =
    useVideosMergeMutation();

  const videoQueries = useGetMultipleVideos(
    videosToMerge.map(video => video.id)
  );

  useEffect(() => {
    if (!videosToMerge || !videosToMerge.length) {
      setMergeModal(true);
    }
  }, [videosToMerge]);

  useEffect(() => {
    const updatedVideos = videoQueries.map((query, index) => {
      if (query.data) {
        return {
          ...query.data,
          ...videosToMerge[index],
          source: query.data.videoSource,
          flvName: `${query.data.flvName}.mp4`,
          start: '00:00:00:00',
        };
      }
      return videosToMerge[index];
    });

    const hasChanged = updatedVideos.some((video, index) => {
      const originalVideo = videosToMerge[index];
      return (
        video.id !== originalVideo.id ||
        video.source !== originalVideo.source ||
        video.flvName !== originalVideo.flvName
      );
    });

    if (hasChanged) {
      setVideosToMerge(updatedVideos);
    }
  }, [videoQueries]);

  const handleMergeVideos = async (videoName: string) => {
    const duration =
      1000 * videosToMerge.reduce((sum, v) => sum + (v.trimDuration || 0), 0);
    const videos = videosToMerge.map(v => ({
      start: v.start,
      end: v.end,
      id: v.id,
      flvName: v.flvName,
    }));
    await mergeVideos({
      videos,
      videoName,
      duration,
    });
    setSaved(true);
    history.push('/home?refresh=true');
  };

  const handleAddExternalVideo = (
    videoSource: string,
    videoPreviewSource?: string
  ) => {
    const id = `${EXTERNAL_VIDEO_PREFIX}${uuidv4()}`;
    setVideosToMerge(prev => [
      ...prev,
      {
        id,
        videoSource: videoPreviewSource || videoSource,
        source: videoPreviewSource || videoSource,
        flvName: videoSource,
        start: '00:00:00:00',
        processing: VideoEncodingStatus.READY,
      },
    ]);
    setShowRecordHome(false);
    setMergeModal(false);
  };

  if (videoQueries.some(query => query.isLoading)) {
    return (
      <LoadingIndicator
        isLoading={videoQueries.some(query => query.isLoading)}
      />
    );
  }

  if (videoQueries.some(query => query.isError)) {
    return <NotFound />;
  }

  const hasProcessingVideos = !!videosToMerge.find(
    (v: any) => v?.processing === VideoEncodingStatus.PROCESSING
  );

  return (
    <MainContainer>
      <RouteLeavingGuard
        when={true}
        navigate={path => history.push(path)}
        stay={true}
        onStay={() => setShowSaveModal(true)}
        onConfirm={() => {}}
        shouldBlockNavigation={() => {
          if (!saved || videosToMerge.length === 0) {
            return true;
          }
          return false;
        }}
        disableSave={hasProcessingVideos}
        infoBoxText={
          hasProcessingVideos
            ? 'Some of the videos are still processing. Please wait until they are done, and then proceed with merging.'
            : ''
        }
      />
      <VideoMerge
        openModal={() => setMergeModal(true)}
        handleShowRecordScreen={() => setShowRecordHome(true)}
        videos={videosToMerge}
        setVideos={setVideosToMerge}
        mergeVideos={() => setShowSaveModal(true)}
      />
      {showMergeModal && (
        <ModalVideoMergeListVideo
          handleSelectedVideos={(selectedVideos = []) => {
            setVideosToMerge([
              ...videosToMerge,
              ...selectedVideos.map((v: any) => {
                const selectedVideo =
                  videoQueries.find(query => query.data && query.data.id === v)
                    ?.data || ({} as any);
                return {
                  ...selectedVideo,
                  id: v.toString(),
                  source: selectedVideo.videoSource,
                  flvName: `${selectedVideo.flvName}.mp4`,
                };
              }),
            ]);
            setMergeModal(false);
          }}
          onSubmitExternal={handleAddExternalVideo}
          handleModalClose={() => setMergeModal(false)}
          title='Add More Videos'
          showHelpdesk={false}
          showExternalSources={true}
        />
      )}
      {showSaveModal && videosToMerge.length !== 0 && (
        <ModalMergeCreateNew
          initTitle={''}
          handleSubmit={title => handleMergeVideos(title)}
          handleModalClose={() => setShowSaveModal(false)}
          isLoading={isMergeVideosLoading}
        />
      )}
      {showRecordHome && (
        <AddRecordingToMerge
          handleShowRecordHome={setShowRecordHome}
          onRecordingUploaded={handleAddExternalVideo}
        />
      )}
    </MainContainer>
  );
};
