import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { ModalTabChangeConfirm } from 'lib/components';
import { theme } from 'lib/style';
import RouteLeavingGuard from '../../../app/pages/video/videoDetails/main/RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';

export interface Tab {
  id?: number;
  title: string;
  component: React.ReactNode;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  disabled?: boolean;
  takeConfirmation?: boolean;
  inActiveTextColor?: string;
  isEnabled?: boolean;
}

export type TabProps = {
  isActive: boolean;
  disabled?: boolean;
  tabsLength: number;
  inActiveTextColor?: string;
  activeColor?: string;
  isEnabled?: boolean;
};

export type Props = {
  tabs: Array<Tab>;
  navbarPadding?: number | string;
  defaultActiveTab?: number;
  onChange?: (index: number) => void;
  takeConfirmation?: boolean;
};

type NavigationProps = {
  padding?: number | string;
};

const NavItem = styled.div<TabProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  padding: 0 8px 8px 0;
  width: ${({ tabsLength }) => (theme.modalWidthPx - 48) / tabsLength};
  color: ${({ isActive, inActiveTextColor }) =>
    isActive
      ? props => props.activeColor
      : inActiveTextColor || theme.palette.primaryDarkBlue};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  border-bottom: ${({ isActive, activeColor }) =>
    isActive ? `4px solid ${activeColor}` : 'none'};
  cursor: ${props => (props.isEnabled ? 'pointer' : 'not-allowed !important')};
  ${theme.mediaQueryMinWidth.cmd} {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px 8px;
  }
  .Tooltip-Wrapper {
    .Tooltip-Tip {
      left: 180px;
    }
  }
`;

const DesktopNavbar = styled.nav<NavigationProps>`
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: solid 1px #f7f8fa;
  ${props => {
    let padding = `${theme.padding16} ${theme.padding24} 0`;
    if (typeof props.padding === 'number') {
      padding = `${props.padding}px;`;
    } else if (typeof props.padding === 'string') {
      padding = props.padding;
    }

    return `
      display: flex;
      padding: ${padding};
    `;
  }}
`;

const ContentContainer = styled.div<TabProps>`
  ${props => (props.isActive ? 'display: block' : 'display:none')};
  padding: 8px 32px 0;
  height: 100%;
  width: 560px;
`;

type TitleProps = {
  isActive: boolean;
  activeColor: string;
};

const Title = styled.div<TitleProps>`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin: 4px 0;
  ${props => props.isActive && `color: ${props.theme.colors.primary[100]}`};
`;

const Icon = styled.div<TabProps>`
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  max-width: 654px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .tab-navigation {
    .tab.disabled {
      color: #dfdfdf;
    }
  }
`;

export const Tabs = ({
  tabs,
  navbarPadding,
  defaultActiveTab,
  onChange,
  takeConfirmation = false,
}: Props) => {
  const [activeTab, setActiveTab] = React.useState(defaultActiveTab || 0);
  const [showTabPopup, setShowTabPopup] = React.useState(false);
  const [popupTabData, setPopupTabData] = React.useState<any>({});
  const themes = useTheme();

  const onItemClick = (index: number, disabled?: boolean) => {
    if (!disabled) {
      onChange && onChange(index);
      setActiveTab(index);
    }
  };
  const history = useHistory();

  if (tabs.length === 0) return null;

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return (
            takeConfirmation &&
            localStorage.getItem('showTabChangePopup') == '1'
          );
        }}
        title='You have unsaved changes'
        text='Save changes before leaving page to prevent losing data.'
        confirmButtonText='Back to edit'
        discardButtonText='Close this page'
        showDiscardIcon={false}
        showSaveIcon={false}
      />
      <TabsContainer className='tab-container'>
        <DesktopNavbar className='tab-navigation' padding={navbarPadding}>
          {tabs.map((tab, index) => {
            const isEnabled = tab.isEnabled !== false;
            const isActive = activeTab === index;
            return (
              <NavItem
                className={isEnabled ? 'tab' : 'tab disabled'}
                tabsLength={tabs.length}
                disabled={tab.disabled}
                key={index}
                isActive={isActive}
                inActiveTextColor={tab.inActiveTextColor}
                activeColor={themes.colors.primary[100]}
                onClick={() => {
                  if (isEnabled) {
                    //takeConfirmation decide that confirm popup can be shown but localstorage showTabChangePopup key decide to popup it or not
                    if (
                      takeConfirmation &&
                      !isActive &&
                      localStorage.getItem('showTabChangePopup') == '1'
                    ) {
                      setPopupTabData({ index, disabled: tab.disabled });
                      setShowTabPopup(true);
                    } else {
                      localStorage.setItem('showTabChangePopup', '0');
                      onItemClick(index, tab.disabled);
                    }
                  }
                }}
                isEnabled={isEnabled}
              >
                <ProductInfoTooltip hideToolTip={isEnabled}>
                  {!!tab.icon && (
                    <Icon isActive={isActive} tabsLength={tabs.length}>
                      {isActive ? tab.iconActive : tab.icon}
                    </Icon>
                  )}
                  <Title
                    activeColor={themes.colors.primary[100]}
                    isActive={isActive}
                  >
                    {tab.title}
                  </Title>
                </ProductInfoTooltip>
              </NavItem>
            );
          })}
        </DesktopNavbar>
        {tabs.map((tab, index) => (
          <ContentContainer
            className='tab-content'
            key={`${tab.title}Content`}
            tabsLength={tabs.length}
            isActive={activeTab === index}
          >
            {tab.component}
          </ContentContainer>
        ))}
        {showTabPopup && (
          <ModalTabChangeConfirm
            whiteButtonText='Back to edit'
            orangeButtonText='Close this tab'
            title={'You have unsaved changes'}
            text={`Save changes before switching tabs to prevent losing data.`}
            handleModalClose={() => {
              setShowTabPopup(false);
            }}
            onClickWhiteButton={async () => {
              setShowTabPopup(false);
            }}
            onClickOrangeButton={() => {
              setShowTabPopup(false);
              onItemClick(popupTabData.index, popupTabData.disabled);
            }}
          />
        )}
      </TabsContainer>
    </>
  );
};
