import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CloseButton, Dropdown, Modal, RadioInput } from 'lib/components';
import { getDevices } from '../../utils/functions';
import { Button } from 'react-covideo-common';

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 592px;
  min-height: 500px;
  background-color: #fff;
`;

const ModalHeader = styled.div`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  position: absolute;
  margin-left: 32px;
  margin-top: 32px;
`;

const Content = styled.div`
  min-height: 406px;
  padding: 62px 32px 32px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 8px;
`;

const Form = styled.form`
  float: left;
  width: 100%;
`;

const Row = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Col = styled.div`
  flex: 100%;
  min-height: 60px;
  & > label {
    font-size: 14px;
    float: left;
    margin-bottom: 5px;
    margin-top: 5px;
    color: ${theme.palette.label};
  }
  & > span {
    opacity: 0.25;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

const Select = styled(Dropdown)`
  float: left;
  width: 528px;
  & > div {
    border-radius: 4px;
  }

  >div>div: first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    flex-wrap: nowrap;
  }
`;

const RadioGroup = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
  height: 24px;
  margin-top: 16px;
  > span {
    margin-left: 12px;
  }
`;

const OptionDesc = styled.div`
  color: ${theme.palette.label};
  margin-left: auto;
`;

type Props = {
  disabled?: boolean;
  title?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (data: object) => void;
  initialSettings: any;
};

type Option = {
  label: string;
  value: any;
};

export const ModalRecordingSettings = ({
  disabled = false,
  handleModalClose,
  handleSubmit,
  initialSettings,
}: Props) => {
  const [settings, setSettings] = React.useState(initialSettings);
  const [audioOptions, setAudioOptions] = React.useState([] as Option[]);
  const [videoOptions, setVideoOptions] = React.useState([] as Option[]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('record_settings') || '{}');
    const audio = [] as Option[];
    const video = [] as Option[];
    getDevices()
      .then((devices: any) => {
        devices.forEach((device: any) => {
          if (!device || !device.label) {
            return;
          }
          if (device.kind == 'audioinput') {
            audio.push({ label: device.label.trim(), value: device.deviceId });
          }
          if (device.kind == 'videoinput') {
            video.push({ label: device.label.trim(), value: device.deviceId });
          }
        });
        audio.push({
          label: 'No Audio',
          value: false,
        });

        if (
          !saved.videoSource ||
          !video.find(v => v.value === saved.videoSource.value)
        ) {
          saved.videoSource = video[0];
        }

        if (
          !saved.audioSource ||
          !audio.find(a => a.value === saved.audioSource.value)
        ) {
          saved.audioSource = audio[0];
        }

        localStorage.setItem('record_settings', JSON.stringify(saved));
        setSettings({ ...settings, ...saved });
        setAudioOptions(audio);
        setVideoOptions(video);
      })
      .catch(() => {
        audio.push({
          label: 'No Audio',
          value: false,
        });

        video.push({
          label: 'No Video',
          value: false,
        });

        if (
          !saved.videoSource ||
          !video.find(v => v.value === saved.videoSource.value)
        ) {
          saved.videoSource = video[0];
        }

        if (
          !saved.audioSource ||
          !audio.find(a => a.value === saved.audioSource.value)
        ) {
          saved.audioSource = audio[0];
        }

        setSettings({ ...settings, ...saved });
        setAudioOptions(audio);
        setVideoOptions(video);
      });
  }, []);

  const onSubmit = () => {
    localStorage.setItem('record_settings', JSON.stringify(settings));
    handleSubmit(settings);
  };

  return (
    <Modal>
      <ModalItem>
        <ModalHeader>Recording settings</ModalHeader>
        <CloseButton disabled={disabled} onClick={handleModalClose} />
        <Content>
          <Form onSubmit={() => {}}>
            {!!videoOptions && !!videoOptions.length && (
              <Row>
                <Col>
                  <label htmlFor='videoSource'>
                    <b>Video source</b>
                  </label>
                  <Select
                    creatable={false}
                    name='videoSource'
                    value={settings.videoSource}
                    placeholder='No settings'
                    options={videoOptions}
                    onChange={(value: any) =>
                      value !== null
                        ? setSettings({
                            ...settings,
                            videoSource: value,
                          })
                        : () => {}
                    }
                    height={40}
                  />
                </Col>
              </Row>
            )}
            {!!audioOptions && !!audioOptions.length && (
              <Row>
                <Col>
                  <label htmlFor='audioSource'>
                    <b>Audio source</b>
                  </label>
                  <Select
                    creatable={false}
                    name='audioSource'
                    value={settings.audioSource}
                    placeholder='No settings'
                    options={audioOptions}
                    onChange={(value: any) =>
                      value !== null
                        ? setSettings({
                            ...settings,
                            audioSource: value,
                          })
                        : () => {}
                    }
                    dropdownHeight={140}
                    height={40}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <label htmlFor='videoQuality'>
                  <b>Video quality</b>
                </label>
                <RadioGroup role='quality'>
                  <RadioLabel>
                    <RadioInput
                      checked={settings.videoQuality === 'low'}
                      name='quality'
                      value='low'
                      onClick={() =>
                        setSettings({ ...settings, videoQuality: 'low' })
                      }
                    />
                    <span>Low</span>
                    <OptionDesc>Faster Upload</OptionDesc>
                  </RadioLabel>
                  <RadioLabel>
                    <RadioInput
                      checked={settings.videoQuality === 'standard'}
                      name='quality'
                      value='standard'
                      onClick={() =>
                        setSettings({ ...settings, videoQuality: 'standard' })
                      }
                    />
                    <span>Standard</span>
                    <OptionDesc>Fast Upload</OptionDesc>
                  </RadioLabel>
                  <RadioLabel>
                    <RadioInput
                      checked={settings.videoQuality === 'high'}
                      name='quality'
                      value='high'
                      onClick={() =>
                        setSettings({ ...settings, videoQuality: 'high' })
                      }
                    />
                    <span>High</span>
                    <OptionDesc>Slow Upload</OptionDesc>
                  </RadioLabel>
                </RadioGroup>
              </Col>
            </Row>
          </Form>
          <ButtonContainer>
            <Button
              variant='secondary'
              text='Cancel'
              onClick={handleModalClose}
            />
            <Button text='Save' onClick={onSubmit} />
          </ButtonContainer>
        </Content>
      </ModalItem>
    </Modal>
  );
};
