import { useQuery } from 'react-query';
import { billingKeys } from './billingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type BillingDetailsAddress = {
  line1: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
};

export type BillingDetails = {
  name: string;
  address: BillingDetailsAddress;
};
export type Card = {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  billing_details: BillingDetails;
  isDefault?: boolean;
};

export type GetCardsResponse = {
  methods: Card[];
  hasMore: boolean;
};

type Params = { limit?: number };

export const getCards = async (params: Params): Promise<GetCardsResponse> => {
  const response = await EXPRESS_API.get<GetCardsResponse>(
    '/register/payment/methods',
    {
      params,
    }
  );
  return response.data;
};

export const useCardsQuery = (params: Params) => {
  return useQuery<GetCardsResponse, Error>(billingKeys.cards(params), () =>
    getCards(params)
  );
};
