import { IWhiteLabel, WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { ICombinedUserData, THEME_NAME } from '../auth';

export const getThemeByWhiteLabel = (
  whitelabel: IWhiteLabel | undefined,
  user: ICombinedUserData | undefined
) => {
  if (!whitelabel) {
    return THEME_NAME.COVIDEO;
  }
  if (whitelabel.name === WHITELABEL_NAME.COVIDEO) {
    const hasAutomotiveAccess =
      user?.customer?.automotiveAccess?.toString() === '1';
    return hasAutomotiveAccess ? THEME_NAME.AUTOMOTIVE : THEME_NAME.COVIDEO;
  }
  return improveWhiteLabelName(whitelabel.name);
};

export const improveWhiteLabelName = (name: WHITELABEL_NAME) => {
  switch (name) {
    case WHITELABEL_NAME.KENNECTED:
      return THEME_NAME.KENNECTED;
    case WHITELABEL_NAME.PRO_VIDEO_NOW:
      return THEME_NAME.PROVIDEO;
    case WHITELABEL_NAME.APP_2_VID:
      return THEME_NAME.APP_2_VID;
    default:
      return THEME_NAME.COVIDEO;
  }
};
