import { successToast } from 'lib/components/toasts/success';
import { MarkAsSentOptions } from 'lib/const/MarkAsSentOptions';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';
import {
  facebookUrl,
  linkedinUrl,
  twitterUrl,
  getYoutubeUrl,
} from 'app/pages/library/videos/videoList/components/SendAndShare/share/const';
import { getMarkAsSentValue } from 'lib/utils/markAsSent';
import { isSafari } from 'lib/utils/browserChecks';
import { copyToClipboardQuickShare } from 'lib/utils/functions';
import { EXPRESS_API } from 'configs/api/expressApi';

interface MarkAsSentResponse {
  key: string;
  url: string;
  html: string;
  thumbnail: string;
  legacyHtml: string;
  shortUrlCode: string;
}

export type QuickShareTypes =
  | 'html_code'
  | 'url'
  | 'crm_code'
  | 'faceboook'
  | 'twitter'
  | 'youtube'
  | 'linkedin'
  | 'quickshare'
  | 'copyEmail'
  | 'copySms'
  | '';

export interface IQuickShareMarkAsSentParams {
  videoId?: string;
  linksetId?: number | string;
  templateId?: number | string;
  overlayId?: number | string;
  vin?: number | string;

  markAsSentConfirmed?: boolean | undefined;
  videoActivityType?: string | undefined;
  iconId?: number;
  format?: string;
  useAutomotive?: string | boolean | undefined;
  allowVideoReply?: string | boolean | undefined;
  allowReactions?: string | boolean | undefined;
  notifyUser?: string | boolean | undefined;
  toastType?: QuickShareTypes;
  reuseShortUrl?: boolean;
  expirationDate?: Date | null;
}

const markQuickShareAsSent = async (params: IQuickShareMarkAsSentParams) => {
  if (isSafari) {
    params = { ...params, reuseShortUrl: true };
  }
  const { videoId, toastType: _, ...rest } = params;

  const body = {
    ...rest,
  };

  const { data } = await EXPRESS_API.post(
    `share/quickshare/${videoId}/mark-as-sent`,
    body
  );

  return data;
};

const showProperToastAndCopyToClipboard = (
  data: MarkAsSentResponse,
  variables: IQuickShareMarkAsSentParams,
  youtubeUrl: string
) => {
  switch (variables.toastType) {
    case 'crm_code':
      return {
        title: `CRM Code Copied!`,
        clipboardText: data.url,
        clipboardHtml: data.html,
      };
    case 'url':
      return {
        title: 'URL Copied!',
        clipboardText: data.url,
      };
    case 'html_code':
      return {
        title: 'Raw HTML Copied!',
        clipboardText: data.legacyHtml,
      };
    case 'quickshare':
    case 'copyEmail':
      return {
        title: 'Link copied to clipboard!',
        clipboardText: data.url,
        clipboardHtml: data.html,
      };
    case 'copySms':
      return {
        title: 'Link copied to clipboard!',
        clipboardText: data.url,
        clipboardHtml: data.url,
      };
    case 'faceboook':
      return {
        socialUrl: `${facebookUrl}${encodeURIComponent(data.url)}`,
      };
    case 'twitter':
      return {
        socialUrl: `${twitterUrl}${encodeURIComponent(data.url)}`,
      };
    case 'youtube':
      return {
        socialUrl: `${youtubeUrl}`,
      };
    case 'linkedin':
      return {
        socialUrl: `${linkedinUrl}${encodeURIComponent(data.url)}`,
      };
    default:
      break;
  }
};

export const useQuickShareMarkAsSentMutation = (
  onSuccessCallback: () => void
) => {
  const { showError } = useToastError();
  const { userData, whitelabel } = useAuth();
  const markAsSent = getMarkAsSentValue(userData);
  return useMutation(markQuickShareAsSent, {
    onSuccess: async (data: MarkAsSentResponse, variables) => {
      const youtubeUrl = getYoutubeUrl(whitelabel, variables.videoId);
      const dataToShow = showProperToastAndCopyToClipboard(
        data,
        variables,
        youtubeUrl
      );

      if (markAsSent === MarkAsSentOptions.ALWAYS.value) {
        if (userData.isAutomotive) {
          successToast({
            title: `Video marked as Sent`,
          });
        }
      }

      if (markAsSent === MarkAsSentOptions.ASK_VERY_TIME.value) {
        variables.markAsSentConfirmed &&
          userData.isAutomotive &&
          successToast({ title: 'Video marked as Sent' });
      }

      if (dataToShow) {
        if (!isSafari && dataToShow.clipboardText) {
          await copyToClipboardQuickShare(
            dataToShow.clipboardText,
            dataToShow?.clipboardHtml || ''
          );
        }
        if (dataToShow.title) {
          // Copied message
          successToast({ title: dataToShow.title });
        }

        if (dataToShow.socialUrl) {
          window.open(`${dataToShow.socialUrl}`, '_blank');
        }
      }

      onSuccessCallback();
    },
    onError: err => showError(err),
  });
};
