import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { createLandingPage } from 'lib/api';
import { useQueryClient } from 'react-query';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { Button } from 'react-covideo-common';
interface Props {
  onClose: (params?: any) => void;
  modalLoading: boolean;
  design: any;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
  marginTop?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  min-height: 100%;
  .containerWidth {
    width: 45%;
  }
  align-items: center;
`;

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 0 0 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 16)}px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  margin-left: auto;
`;

const Label = styled.div`
  height: auto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const TextInput = styled.input`
  display: flex;
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-bottom: 16px
  box-sizing: border-box;
  &:focus {
    outline: 0;
    border-color: ${theme.palette.primaryDarkBlue}
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Col = styled.div`
  height: auto;
  overflow: hidden;
  width: 100%;
`;

export const ModalRenameLandingPage = (props: Props) => {
  const { onClose, modalLoading, design } = props;

  const [pageTitle, setPageTitle] = React.useState(
    design.landingPage.title || ''
  );

  const themes = useTheme();
  const queryClient = useQueryClient();

  const modalTitle = 'Rename Landing Page';

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        save(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [pageTitle]);

  async function save(e: any) {
    e?.preventDefault();
    if (!pageTitle) {
      return;
    }

    const updatedLandingPage = { ...design.landingPage };
    updatedLandingPage.title = pageTitle;
    updatedLandingPage.designId = design.id;
    await createLandingPage(updatedLandingPage).catch(() => {});
    onClose();
    queryClient.invalidateQueries([TEMPLATE_QUERY]);
  }

  return (
    <Modal widthOnDesktop={'45%'}>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row>
          <Title>{modalTitle}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row>
          <Form>
            <Row>
              <Col className='input' style={{ height: 'auto', flex: 1 }}>
                <Label>Design Name</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='designName'
                    type='text'
                    autoFocus={true}
                    required={true}
                    maxLength={30}
                    placeholder={'Enter Page Title'}
                    defaultValue={pageTitle || ''}
                    onChange={(e: any) => {
                      setPageTitle(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row marginTop={35} justifyContent={'flex-end'}>
              <Button
                text={'Save Design'}
                onClick={(e: any) => {
                  save(e);
                }}
                disabled={pageTitle.trim() == ''}
              />
            </Row>
          </Form>
        </Row>
      </Content>
    </Modal>
  );
};
