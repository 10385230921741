import React from 'react';
import {
  ActivateCaptions,
  DeactivateCaptions,
  GetCaptionStats,
} from 'lib/api/addonsApi';
import { LoadingIndicator, Modal } from 'lib/components';
import { ModalConfirmSubscription } from 'lib/components/modal/ModalConfirmSubscription';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import { useAuth } from 'lib/context';
import { IoMdInformationCircle } from 'react-icons/io';
import styled from 'styled-components/macro';
import { ManageSeats } from '../components/ManageSeats';
import {
  ActiveHeader,
  AddonFooter,
  OptionContainer,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';
import { ManageCaptionsActive } from './ManageCaptionsActive';
import { ManageCaptionsInactive } from './ManageCaptionsInactive';
import { Button } from 'react-covideo-common';

const SeatsContainer = styled(OptionContainer)`
  height: 320px;
`;

export const ManageCaptions = () => {
  const domain = window.location.hostname;
  const captions_cost = domain === 'app.moov.ie' ? 25 : 20;
  const [isLoading, setIsLoading] = React.useState(false);

  const { whitelabel } = useAuth();

  const [globals, setGlobals] = React.useState({
    active: false,
    currentTotalUsers: 1,
    currentCostPerUser: captions_cost,
    users: [],
  });

  const [updatedGlobals, setUpdatedGlobals] = React.useState<{
    active: boolean;
    currentTotalUsers: number;
    currentCostPerUser: number;
  }>();

  React.useEffect(() => {
    setIsLoading(true);
    GetCaptionStats()
      .then(data => {
        setGlobals({
          currentTotalUsers: data.licenses,
          currentCostPerUser:
            data.licenses < 10
              ? captions_cost
              : data.licenses >= 10 && data.licenses < 25
                ? captions_cost - 5
                : data.licenses >= 25 && data.licenses < 50
                  ? captions_cost - 10
                  : captions_cost - 15,
          active: data.activated,
          users: data.users,
        });

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const CaptionsActiveHeader = styled(ActiveHeader)`
    position: initial;
    width: 45px;
    margin-left: 10px;
  `;

  const SeatsHeader = styled.div`
    font-style: SemiBold;
    font-size: 16px;
    line-height: 24px;
    text-align: end;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
  `;

  const ModalBody = styled.div`
    width: 592px;
    height: 504px;
  `;

  const ModalHeader = styled(PageHeader)`
    font-size: 18px;
    padding-right: 32px;
    justify-content: space-between;
  `;

  const ModalDescription = styled(PageDescription)`
    background-color: #b6e0ec40;
    padding-left: 0px;
    margin: 0 36px 0 36px;
    padding: 12px 12px 12px 12px;
    font-size: 15px;
    color: #272a32;
  `;

  const InfoIcon = styled.div`
    font-size: 24px;
    margin-right: 8px;
    opacity: 0.6;
    color: #001b53;
  `;

  const ModalDescriptionLabel = styled.div``;

  const CloseButtonWrapper = styled.label`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: ${theme.palette.themeDark50Percent};
    &:hover {
      cursor: pointer;
    }
  `;

  const ModalChangeSubscription = styled(SeatsContainer)`
    max-width: none;
    width: 100%;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `;

  const [showChangeSubscription, setShowChangeSubscription] =
    React.useState(false);
  const { ref } = useOnClickOutside(() => {
    setShowChangeSubscription(false);
  });

  const UpdateGlobals = (
    user_count: number,
    cost_per: number,
    active_page: boolean,
    users: any
  ) => {
    setGlobals({
      currentTotalUsers: user_count,
      currentCostPerUser: cost_per,
      active: active_page,
      users,
    });
  };

  if (isLoading) {
    return (
      <PageContainer>
        <LoadingIndicator isLoading={isLoading} text={'Loading...'} />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      {updatedGlobals && (
        <ModalConfirmSubscription
          subscriptionType={2}
          oldSelection={globals.currentTotalUsers}
          newSelection={
            updatedGlobals.active ? updatedGlobals.currentTotalUsers : 0
          }
          handleModalClose={() => {
            setUpdatedGlobals(undefined);
          }}
          handleModalConfirm={() => {
            let tempGlobals = updatedGlobals;
            setUpdatedGlobals(undefined);

            if (tempGlobals.active) {
              ActivateCaptions(tempGlobals.currentTotalUsers).then(() => {
                //
              });
            } else {
              DeactivateCaptions().then(() => {
                //
              });
            }

            setGlobals({
              currentTotalUsers: tempGlobals.currentTotalUsers,
              currentCostPerUser: tempGlobals.currentCostPerUser,
              active: tempGlobals.active,
              users: globals.users,
            });
          }}
        />
      )}
      {showChangeSubscription && (
        <Modal>
          <div ref={ref}>
            <ModalBody>
              <ModalHeader>
                Change subscription
                <CloseButtonWrapper>
                  <CloseIcon
                    width={'24px'}
                    height={'24px'}
                    onClick={() => {
                      setShowChangeSubscription(false);
                    }}
                  />
                </CloseButtonWrapper>
              </ModalHeader>
              <ModalDescription>
                <InfoIcon>
                  <IoMdInformationCircle width={'24px'} height={'24px'} />
                </InfoIcon>
                <ModalDescriptionLabel>
                  Next month’s invoice will reflect the largest number of seats
                  you had active this month.
                </ModalDescriptionLabel>
              </ModalDescription>
              <ModalChangeSubscription>
                <ManageSeats
                  Type='captions'
                  SaveButtonText='Save subscription'
                  Globals={globals}
                  Update={(user_count, cost_per, active_page) => {
                    setShowChangeSubscription(false);
                    setUpdatedGlobals({
                      active: active_page,
                      currentTotalUsers: user_count,
                      currentCostPerUser: cost_per,
                    });
                  }}
                  AutoSave={false}
                />
                <Button
                  variant='destructive'
                  text='Cancel subscription for Captions'
                  onClick={() => {
                    setShowChangeSubscription(false);
                    setUpdatedGlobals({
                      active: false,
                      currentTotalUsers: globals.currentTotalUsers,
                      currentCostPerUser: globals.currentCostPerUser,
                    });
                  }}
                />
              </ModalChangeSubscription>
            </ModalBody>
          </div>
        </Modal>
      )}
      <PageHeader>
        Captions
        {globals.active && (
          <>
            <CaptionsActiveHeader>Active</CaptionsActiveHeader>
            <SeatsHeader>
              {globals.currentTotalUsers} seat
              {globals.currentTotalUsers > 1 ? 's' : ''} -{' '}
              {domain === 'app.moov.ie' ? '£' : '$'}
              {globals.currentTotalUsers * globals.currentCostPerUser}/mo
              <Button
                variant='secondary'
                text='Change subscription'
                onClick={() => {
                  setShowChangeSubscription(true);
                }}
              />
            </SeatsHeader>
          </>
        )}
      </PageHeader>
      {!globals.active ? (
        <ManageCaptionsInactive Globals={globals} Update={UpdateGlobals} />
      ) : (
        <ManageCaptionsActive Globals={globals} />
      )}
      <AddonFooter>
        Have a question? Contact your account manager, contact us at{' '}
        {whitelabel.supportEmail} or give us a call at {whitelabel.supportPhone}
        .
      </AddonFooter>
    </PageContainer>
  );
};
