import { Button } from 'react-covideo-common';
import React from 'react';
import {
  GetSMSStats,
  GetCaptionStats,
  GetFilesStats,
  GetLPBStats,
} from 'lib/api/addonsApi';
import {
  Addon,
  AddonActions,
  AddonActive,
  AddonCheckmark,
  AddonContainer,
  AddonContent,
  AddonDescription,
  AddonHeader,
  AddonPricing,
  AddonTitle,
} from '../components/PageElements';
import { useHistory } from 'react-router-dom';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { useAuth } from 'lib/context';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { ModalActivateSubscription } from 'lib/components/modal/ModalActivateSubscription';
import { PackageNameLabel, productFeature } from 'lib/utils/productFeature';
import {
  MainWrapper,
  Container,
  HeaderWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

export const ManageAddons = () => {
  const [smsActive, setSMSActive] = React.useState(false);
  const [lpbActive, setLPBActive] = React.useState(false);
  const [captionsActive, setCaptionsActive] = React.useState(false);
  const [filesActive, setFilesActive] = React.useState(false);
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [contactFormSubject, setContactFormSubject] = React.useState('');
  const [activateModalTitle, setActivateModalTitle] = React.useState('');

  const {
    userData: { access, trialAccount, user },
    whitelabel,
  } = useAuth();

  const isCompanyAdmin = access === '3' || access === '4';
  const domain = window.location.hostname;
  const captions_cost = domain === 'app.moov.ie' ? '£25' : '$20';
  const captionsVideoURL =
    domain === 'app.moov.ie'
      ? 'https://covideosystems.s3.amazonaws.com/videos/mooviecaptions.mp4'
      : domain === 'app.covideo.com'
        ? 'https://covideosystems.s3.us-east-1.amazonaws.com/videos/Captions%20Promo.mp4'
        : 'https://covideosystems.s3.us-east-1.amazonaws.com/videos/Captions%20Promo%20Generic.mp4';
  const isCovideoDomain =
    (whitelabel && whitelabel.name === WHITELABEL_NAME.COVIDEO) ||
    domain.includes('localhost');

  const upgradePlanLink = '/upgrade-plan';

  const history = useHistory();

  const FilesManage = () => {
    trialAccount
      ? setActivateModalTitle('Files & Boards')
      : history.push('/profile/add-ons/files/manage');
  };

  const SMSManage = async () => {
    trialAccount
      ? setActivateModalTitle('SMS')
      : history.push('/profile/add-ons/sms/manage');
  };

  const LPBManage = async () => {
    trialAccount
      ? setActivateModalTitle('Video Landing Page Builder')
      : history.push('/profile/add-ons/lpb/manage');
  };

  const CaptionsManage = async () => {
    trialAccount
      ? setActivateModalTitle('Captions & Translations')
      : history.push('/profile/add-ons/captions/manage');
  };

  const FilesManageRedirect = () => {
    history.push('/profile/add-ons/files/manage');
  };

  const SMSManageRedirect = async () => {
    history.push('/profile/add-ons/sms/manage');
  };

  const LPBManageRedirect = async () => {
    history.push('/profile/add-ons/lpb/manage');
  };

  const WheelsTVRedirect = async () => {
    history.push('/profile/add-ons/wheels-tv/details');
  };

  const CaptionsManageRedirect = async () => {
    history.push('/profile/add-ons/captions/manage');
  };

  const CodirectDetails = async () => {
    history.push('/profile/add-ons/codirect/details');
  };

  let isLandingPageBuilderDisabled = false;
  let isCaptionsDisabled = false;
  let isFilesAndBoardsDisabled = false;
  let isSmsDisabled = false;
  let packageId = 0;

  if (
    user &&
    user.packageDetails &&
    user.packageDetails.id &&
    user.packageDetails.products
  ) {
    packageId = user.packageDetails.id;
    isLandingPageBuilderDisabled = !user.packageDetails.products.includes(
      productFeature.LANDING_PAGE_BUILDER
    );
    isCaptionsDisabled = !user.packageDetails.products.includes(
      productFeature.CAPTIONS
    );
    isFilesAndBoardsDisabled = !user.packageDetails.products.includes(
      productFeature.FILES_AND_BOARDS
    );
    isSmsDisabled = !user.packageDetails.products.includes(
      productFeature.SMS_ADMIN_CONTROLS
    );
  }

  React.useEffect(() => {
    GetSMSStats().then(data => {
      setSMSActive(data.activated);
    });
  }, []);

  React.useEffect(() => {
    GetLPBStats().then(data => {
      setLPBActive(data.activated);
    });
  }, []);

  React.useEffect(() => {
    GetCaptionStats().then(data => {
      setCaptionsActive(data.activated);
    });
  }, []);

  React.useEffect(() => {
    GetFilesStats().then(data => {
      setFilesActive(data.activated);
    });
  }, []);

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Heading>Add-ons</Heading>
        </HeaderWrapper>
        <AddonContainer>
          {isCovideoDomain && (
            <Addon>
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  startWithControls={false}
                  videoSource='https://covideosystems.s3.us-east-1.amazonaws.com/videos/SMS%20Feature%20Promo.mp4'
                />
              </AddonHeader>
              <AddonContent>
                <AddonTitle>SMS</AddonTitle>
                <AddonPricing>Activate for $25 per month</AddonPricing>
                <AddonDescription>
                  Message leads and customers from a unique, local number
                  dedicated specifically for your dealership.
                </AddonDescription>
                {isSmsDisabled && (
                  <AddonActions>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        window.location.href = upgradePlanLink;
                      }}
                      text={`Available in ${PackageNameLabel.TEAMS}`}
                    />
                  </AddonActions>
                )}
                {!isSmsDisabled && (
                  <AddonActions>
                    {smsActive && (
                      <>
                        <AddonActive>
                          <AddonCheckmark /> Active
                        </AddonActive>
                        {isCompanyAdmin && (
                          <Button
                            variant='secondary'
                            text='Manage'
                            onClick={SMSManage}
                          />
                        )}
                      </>
                    )}
                    {!smsActive && (
                      <>
                        {isCompanyAdmin && (
                          <>
                            <Button
                              variant='primary'
                              text='Activate'
                              onClick={SMSManage}
                            />
                            <Button
                              variant='secondary'
                              text='View Details'
                              onClick={SMSManageRedirect}
                            />
                          </>
                        )}
                        {!isCompanyAdmin && (
                          <Button
                            variant='primary'
                            text='Contact your Account Manager'
                            onClick={() => {
                              setContactFormSubject('SMS');
                              setShowContactForm(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </AddonActions>
                )}
              </AddonContent>
            </Addon>
          )}

          {isCovideoDomain && (
            <Addon>
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  thumbnail='https://covideosystems.s3.amazonaws.com/thumbnails/LandingPagePromoFF.png'
                  videoSource={
                    'https://covideosystems.s3.amazonaws.com/videos/LandingPageBuilderPromo.mp4'
                  }
                  startWithControls={false}
                />
              </AddonHeader>
              <AddonContent>
                <AddonTitle>Video Landing Page Builder</AddonTitle>
                <AddonPricing>Activate for $25 per month</AddonPricing>
                <AddonDescription>
                  Create, save and reuse endless video landing pages with
                  Covideo's new builder that puts you in the designer's seat.
                </AddonDescription>
                {isLandingPageBuilderDisabled && (
                  <AddonActions>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        window.location.href = upgradePlanLink;
                      }}
                      text={`Available in ${PackageNameLabel.TEAMS}`}
                    />
                  </AddonActions>
                )}
                {!isLandingPageBuilderDisabled && (
                  <AddonActions>
                    {(!lpbActive && (
                      <>
                        {isCompanyAdmin && (
                          <>
                            <Button
                              variant='primary'
                              text='Activate'
                              onClick={LPBManage}
                            />
                            <Button
                              variant='secondary'
                              text='View Details'
                              onClick={LPBManageRedirect}
                            />
                          </>
                        )}
                        {!isCompanyAdmin && (
                          <Button
                            variant='primary'
                            text='Contact your Account Manager'
                            onClick={() => {
                              setContactFormSubject('Landing Page Builder');
                              setShowContactForm(true);
                            }}
                          />
                        )}
                      </>
                    )) ||
                      (lpbActive && (
                        <>
                          <AddonActive>
                            <AddonCheckmark /> Active
                          </AddonActive>
                          {isCompanyAdmin && (
                            <Button
                              variant='secondary'
                              text='Manage'
                              onClick={LPBManage}
                            />
                          )}
                        </>
                      ))}
                  </AddonActions>
                )}
              </AddonContent>
            </Addon>
          )}

          <Addon className={isCovideoDomain ? '' : 'addonForWhitelabel'}>
            {/* Hide Video for whitelabels*/}
            {isCovideoDomain === true && (
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  startWithControls={false}
                  videoSource={captionsVideoURL}
                />
              </AddonHeader>
            )}
            <AddonContent>
              <AddonTitle>Captions & Translations</AddonTitle>
              <AddonPricing>
                Activate from {captions_cost} per month per user
              </AddonPricing>
              <AddonDescription>
                Auto generate captions for your videos with the click of a
                button, and enable your viewers to select their preferred
                language, instantly translating your captions in real-time.
              </AddonDescription>
              {isCaptionsDisabled && (
                <AddonActions>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      window.location.href = upgradePlanLink;
                    }}
                    text={`Available in ${PackageNameLabel.TEAMS}`}
                  />
                </AddonActions>
              )}
              {!isCaptionsDisabled && (
                <AddonActions>
                  {captionsActive && (
                    <>
                      <AddonActive>
                        <AddonCheckmark /> Active
                      </AddonActive>
                      {isCompanyAdmin && (
                        <Button
                          variant='secondary'
                          text='Manage'
                          onClick={CaptionsManage}
                        />
                      )}
                    </>
                  )}
                  {!captionsActive && (
                    <>
                      {isCompanyAdmin && (
                        <>
                          <Button
                            variant='primary'
                            text='Activate'
                            onClick={CaptionsManage}
                          />
                          <Button
                            variant='secondary'
                            text='View Details'
                            onClick={CaptionsManageRedirect}
                          />
                        </>
                      )}
                      {!isCompanyAdmin && (
                        <Button
                          variant='primary'
                          text='Contact your Account Manager'
                          onClick={() => {
                            setContactFormSubject('Captions & Translations');
                            setShowContactForm(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </AddonActions>
              )}
            </AddonContent>
          </Addon>

          {isCovideoDomain && (
            <Addon>
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  videoSource='https://videos.covideo.com/videos/1675_3_twqmx2hp3o1631720807.mp4'
                  startWithControls={false}
                />
              </AddonHeader>
              <AddonContent>
                <AddonTitle>Files & Boards</AddonTitle>
                <AddonPricing>
                  Activate from $10 per month per user
                </AddonPricing>
                <AddonDescription>
                  Use Covideo to snap, store and share all of your digital
                  assets with screenshots, files, and boards.
                </AddonDescription>
                {isFilesAndBoardsDisabled && (
                  <AddonActions>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        window.location.href = upgradePlanLink;
                      }}
                      text={`Available in ${PackageNameLabel.TEAMS}`}
                    />
                  </AddonActions>
                )}
                {!isFilesAndBoardsDisabled && (
                  <AddonActions>
                    {filesActive && (
                      <>
                        <AddonActive>
                          <AddonCheckmark /> Active
                        </AddonActive>
                        {isCompanyAdmin && (
                          <Button
                            variant='secondary'
                            text='Manage'
                            onClick={FilesManage}
                          />
                        )}
                      </>
                    )}
                    {!filesActive && (
                      <>
                        {isCompanyAdmin && (
                          <>
                            <Button
                              variant='primary'
                              text='Activate'
                              onClick={FilesManage}
                            />
                            <Button
                              variant='secondary'
                              text='View Details'
                              onClick={FilesManageRedirect}
                            />
                          </>
                        )}
                        {!isCompanyAdmin && (
                          <Button
                            variant='primary'
                            text='Contact your Account Manager'
                            onClick={() => {
                              setContactFormSubject('Files & Boards');
                              setShowContactForm(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </AddonActions>
                )}
              </AddonContent>
            </Addon>
          )}

          {isCovideoDomain && packageId === 0 && (
            <Addon>
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  startWithControls={false}
                  videoSource='https://videos.covideo.com/videos/71305_3_u1zgd70shs1620066633.mp4'
                />
              </AddonHeader>
              <AddonContent>
                <AddonTitle>Codirect</AddonTitle>
                <AddonPricing></AddonPricing>
                <AddonDescription>
                  New for auto, RV, marine, and motorsports: marketing
                  intelligence system that delivers trackable growth & retention
                  for dealerships.
                </AddonDescription>
                <AddonActions>
                  <Button
                    variant='secondary'
                    text='View Details'
                    onClick={CodirectDetails}
                  />
                </AddonActions>
              </AddonContent>
            </Addon>
          )}

          {isCovideoDomain && packageId === 0 && (
            <Addon>
              <AddonHeader>
                <VideoPlayer
                  videoRef={React.createRef()}
                  playButtonPosition='center'
                  playerBackgroundColor='#000'
                  playerIconsColor='#ffffff'
                  thumbnail='https://images.covideo.com/thumbnails/71305_3_pf6ywpxpls1604691452_0001.png'
                  videoSource='https://videos.covideo.com/videos/71305_3_pf6ywpxpls1604691452.mp4'
                  startWithControls={false}
                />
              </AddonHeader>
              <AddonContent>
                <AddonTitle>WheelsTV</AddonTitle>
                <AddonPricing>Activate for $100 dealership/month</AddonPricing>
                <AddonDescription>
                  Access WheelsTV’s library of more than 6,000 video test drive
                  reports, for the in-depth, relevant video content buyers are
                  searching for.
                </AddonDescription>
                <AddonActions>
                  <Button
                    variant='secondary'
                    text='View Details'
                    onClick={WheelsTVRedirect}
                  />
                </AddonActions>
              </AddonContent>
            </Addon>
          )}
        </AddonContainer>
        {showContactForm && (
          <ModalContactAM
            handleModalClose={() => setShowContactForm(false)}
            subject={contactFormSubject}
          />
        )}
        {activateModalTitle.length ? (
          <ModalActivateSubscription
            handleModalClose={() => setActivateModalTitle('')}
            handleSubmit={addon =>
              history.push({ pathname: '/upgrade', state: { addon } })
            }
            title={activateModalTitle}
            handleCheckBox={true}
          />
        ) : null}
      </MainWrapper>
    </Container>
  );
};
