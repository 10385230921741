import * as React from 'react';
import { Modal } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
interface ComponentProps {
  title: string;
  thumbnailSource: string;
  onClose: (p?: any) => void;
}
interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 575px;
  height: 350px;
`;

const ThumbnailContainer = styled.div`
  background-color: ${theme.palette.blackRgba75};
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const ThumbnailImage = styled.div`
  display: flex;
  min-width: 45px;
  transition: all 0.2s;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

type ImgProps = {
  img?: string;
};

const Thumbnail = styled.img<ImgProps>`
  background-color: ${theme.palette.white};
  display: block;
  height: 100%;
  width: auto !important;
  max-width: 100%;
`;

const Component = (props: ComponentProps) => {
  const { title, onClose, thumbnailSource } = props;
  return (
    <Modal widthOnDesktop={'1008px'} disableFullScreen={true}>
      <Content>
        <Row justifyContent={'space-between'}>
          <Title>{title}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row justifyContent={'center'}>
          <ThumbnailWrapper>
            <ThumbnailContainer>
              <ThumbnailImage>
                <Thumbnail src={thumbnailSource} />
              </ThumbnailImage>
            </ThumbnailContainer>
          </ThumbnailWrapper>
        </Row>
      </Content>
    </Modal>
  );
};

export default Component;
