import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { DefaultLabel, Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { Button } from 'react-covideo-common';
import { useIsMutating } from 'react-query';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #272a32;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
`;

const Textarea = styled.textarea`
  padding-left: 8px;
  width: 528px;
  min-width: 528px;
  max-width: 528px;
  height: 100px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: 0;
  }
`;

type Props = {
  videoDeletion?: any;
  handleModalClose: () => void;
  handleSubmit: Function;
};

export const ModalVideoDeletionRequest = ({
  handleModalClose,
  handleSubmit,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [message, setMessage] = useState('');

  const updateMessage = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <Modal>
      <ModalItem>
        <Header>
          Send Request to Your Admin
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <DefaultLabel>Add a note for the admin</DefaultLabel>
          <Textarea
            value={message}
            onChange={updateMessage}
            placeholder='Tell the admin for how long you want to extend expiration, and why...'
          />
          <ButtonsWrapper>
            <Button
              disabled={isDisabled}
              text={'Send Request'}
              onClick={() => handleSubmit(message)}
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
