import {
  GetCtaSetResponse,
  GetLandingPagesResponse,
  GetWebsiteOverlaysResponse,
  VideoListItem,
} from 'lib/api';
import { useGetLinkSetsQuery } from 'lib/api/exitLinkSets/getExitLinksSets';
import { useGetOverlays } from 'lib/api/overlays/getOverlaysQuery';
import { useGetTemplatesQuery } from 'lib/api/templates/getTemplatesQuery';
import { useSingleVideoQuery } from 'lib/api/videos/useSingleVideoQuery';
import React, { ReactNode, useState } from 'react';
import { VideoRequest } from '../types';

const SendAndShareContext = React.createContext({
  overlays: {},
  templates: {},
  isLoading: true,
  linksets: {},
  video: {},
  vin: '',
  changeVin: (vin: string) => console.log(vin),
} as {
  overlays: GetWebsiteOverlaysResponse;
  templates: GetLandingPagesResponse;
  isLoading: boolean;
  linksets: GetCtaSetResponse;
  video: VideoListItem & { autogeneratedThumbnail: string } & {
    videoRequest?: VideoRequest;
  };
  vin: string;
  changeVin: (vin: string) => void;
});

export const SendAndShareProvider = ({
  children,
  videoId,
}: {
  children: ReactNode;
  videoId: number;
}) => {
  const [vin, setVin] = useState('');
  const { data: video, isLoading: isLoadingVideo } = useSingleVideoQuery({
    videoId,
  });

  const { isLoading: isLoadingOverlays, data: overlays } = useGetOverlays({
    start: 0,
    limit: 100,
    search: '',
  });

  const { isLoading: isLoadingTemplates, data: templates } =
    useGetTemplatesQuery({
      start: 0,
      limit: 500,
      search: '',
    });

  const { isLoading: isLoadingExitLinkSets, data: linksets } =
    useGetLinkSetsQuery({
      start: 0,
      limit: 200,
      search: '',
    });

  const isLoading =
    isLoadingOverlays ||
    isLoadingTemplates ||
    isLoadingExitLinkSets ||
    isLoadingVideo;

  return (
    <SendAndShareContext.Provider
      value={{
        overlays: { ...(overlays as GetWebsiteOverlaysResponse) },
        templates: { ...(templates as GetLandingPagesResponse) },
        linksets: { ...(linksets as GetCtaSetResponse) },
        video: {
          ...(video as VideoListItem & { autogeneratedThumbnail: string } & {
            videoRequest?: VideoRequest;
          }),
        },
        isLoading,
        vin,
        changeVin: (newVin: string) => setVin(newVin),
      }}
    >
      {children}
    </SendAndShareContext.Provider>
  );
};

export const useSendAndShare = () => {
  const context = React.useContext(SendAndShareContext);
  if (context === undefined) {
    throw new Error(
      'useSendAndShare must be used within a SendAndShareProvider'
    );
  }
  return context;
};
