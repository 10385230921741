import keyBy from 'lodash/keyBy';

import { GlobalAction, TeleprompterState } from '.';

const GET_SCRIPTS_START = 'GET_SCRIPTS_START';
const GET_SCRIPTS_ERROR = 'GET_SCRIPTS_ERROR';
const GET_SCRIPTS_SUCCESS = 'GET_SCRIPTS_SUCCESS';
const GET_SCRIPT_START = 'GET_SCRIPT_START';
const GET_SCRIPT_ERROR = 'GET_SCRIPT_ERROR';
const GET_SCRIPT_SUCCESS = 'GET_SCRIPT_SUCCESS';
const ADD_SCRIPTS_START = 'ADD_SCRIPTS_START';
const ADD_SCRIPTS_ERROR = 'ADD_SCRIPTS_ERROR';
const ADD_SCRIPTS_SUCCESS = 'ADD_SCRIPTS_SUCCESS';
const EDIT_SCRIPTS_START = 'EDIT_SCRIPTS_START';
const EDIT_SCRIPTS_ERROR = 'EDIT_SCRIPTS_ERROR';
const EDIT_SCRIPTS_SUCCESS = 'EDIT_SCRIPTS_SUCCESS';
const DELETE_SCRIPTS_START = 'DELETE_SCRIPTS_START';
const DELETE_SCRIPTS_ERROR = 'DELETE_SCRIPTS_ERROR';
const DELETE_SCRIPTS_SUCCESS = 'DELETE_SCRIPTS_SUCCESS';

export const teleprompterReducer = (
  state: TeleprompterState,
  action: GlobalAction
): TeleprompterState => {
  const { payload, type } = action;
  switch (type) {
    case GET_SCRIPTS_START:
    case GET_SCRIPT_START:
    case ADD_SCRIPTS_START:
    case DELETE_SCRIPTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SCRIPTS_ERROR:
    case GET_SCRIPT_ERROR:
    case DELETE_SCRIPTS_ERROR:
    case ADD_SCRIPTS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SCRIPTS_SUCCESS: {
      return {
        ...state,
        scripts: keyBy(payload.scripts, 'scriptId'),
        count: payload.count,
        loading: false,
      };
    }
    case ADD_SCRIPTS_SUCCESS:
    case GET_SCRIPT_SUCCESS:
    case EDIT_SCRIPTS_SUCCESS: {
      return {
        ...state,
        scripts: {
          ...state.scripts,
          [payload.script.scriptId]: payload.script,
        },
        loading: false,
      };
    }
    case DELETE_SCRIPTS_SUCCESS: {
      const { scriptId } = payload;
      const copyState = { ...state };
      delete copyState.scripts[scriptId];
      copyState.loading = false;
      return copyState;
    }
    default:
      return state;
  }
};

export const SCRIPTS_ACTIONS = {
  GET_SCRIPTS_START,
  GET_SCRIPTS_ERROR,
  GET_SCRIPTS_SUCCESS,
  GET_SCRIPT_START,
  GET_SCRIPT_ERROR,
  GET_SCRIPT_SUCCESS,
  ADD_SCRIPTS_START,
  ADD_SCRIPTS_ERROR,
  ADD_SCRIPTS_SUCCESS,
  EDIT_SCRIPTS_START,
  EDIT_SCRIPTS_ERROR,
  EDIT_SCRIPTS_SUCCESS,
  DELETE_SCRIPTS_START,
  DELETE_SCRIPTS_ERROR,
  DELETE_SCRIPTS_SUCCESS,
};
