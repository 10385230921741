import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components/macro';
import { EMAIL_REGEX } from 'lib/utils/regexes';
import { MdContacts } from 'react-icons/md';
import { Styles } from 'react-select';

const components = {
  DropdownIndicator: null,
};

type Props = {
  onChange: (recipients: string[]) => void;
  initRecipients?: string[];
  onError?: (email: string) => void;
  isClearable?: boolean;
  showIcon?: boolean;
  placeholder?: string;
  styles?: Partial<Styles<Recipient, true>> | undefined;
};

type Recipient = {
  value: string;
  label: string;
};

const IconWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 8px;
`;

export const RecipientsSelector = (props: Props) => {
  const [recipients, setRecipients] = useState([] as Recipient[]);
  const [inputValue, setInputValue] = useState('');

  const {
    onChange,
    initRecipients,
    onError,
    showIcon = false,
    isClearable = true,
    placeholder = 'Add recipients...',
    styles,
  } = props;

  const handleRecipientChange = (recipients: any) => {
    if (!recipients) {
      recipients = [];
    }
    setRecipients(recipients);
    onChange(recipients.map((recipient: any) => recipient.value));
  };

  React.useEffect(() => {
    if (initRecipients) {
      const recipientsArray = initRecipients.map(label => ({
        value: label,
        label,
      }));
      setRecipients(recipientsArray);
    }
  }, [initRecipients]);

  const handleInputChange = (showError: boolean) => {
    if (!inputValue) {
      return;
    }

    if (!EMAIL_REGEX.test(inputValue)) {
      showError && onError && onError(inputValue);
      return;
    }
    setInputValue('');
    const newRecipients = [
      ...recipients,
      {
        value: inputValue,
        label: inputValue,
      },
    ];
    setRecipients(newRecipients);
    onChange(newRecipients.map(recipient => recipient.value));
    onError && onError('');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleInputChange(true);
        event.preventDefault();
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable={isClearable}
        isMulti
        menuIsOpen={false}
        onChange={handleRecipientChange}
        onInputChange={(input: string) => setInputValue(input)}
        onKeyDown={handleKeyDown}
        onBlur={() => handleInputChange(false)}
        placeholder={placeholder}
        value={recipients}
        styles={styles}
      />
      {showIcon && (
        <IconWrapper>
          <MdContacts size={20} color='#9297A2' />
        </IconWrapper>
      )}
    </div>
  );
};
