import * as React from 'react';
import { useState, useEffect } from 'react';
import get from 'lodash/get';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import Select from 'react-select';
import {
  CheckboxInput,
  CloseButton,
  Modal,
  Table,
  LoadingIndicator,
} from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import WarningIcon from 'lib/images/WarningIcon';
import { importSuperAdminUsers, importUsers } from 'lib/api';
import DragAndDrop from 'lib/components/inputs/DragAndDrop';
import { useAuth } from 'lib/context';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { getAutomotiveRoleValueFromLabel } from 'lib/utils/automotiveRolePermissionChecks';
import { validateInputForCsvFormulaInjection } from 'lib/utils/functions';
import { AccessRole } from 'lib/const';
import { Button } from 'react-covideo-common';

const SAMPLE_LENGTH = 1;
const FIRST_NAME_REG = new RegExp('first|^name$', 'ig');
const LAST_NAME_REG = new RegExp('last|surname', 'ig');
const USER_NAME_REG = new RegExp('username', 'ig');
const TITLE_REG = new RegExp('titke', 'ig');
const DEPARTMENT_REG = new RegExp('dept|department', 'ig');
const PHONE_REG = new RegExp('phone', 'ig');
const EMAIL_REG = new RegExp('email', 'ig');
const PASSWORD_REG = new RegExp('password', 'ig');
const ADMIN_REG = new RegExp('admin', 'ig');
const AUTOMOTIVE_ROLE_REG = new RegExp('automotive|role', 'ig');
const NMLS_REG = new RegExp('nmls', 'ig');
const USER_TYPE_REG = new RegExp('user type', 'ig');

const VERIFIED_REG = new RegExp('active', 'ig');
const CAPTION_REG = new RegExp('enable captions', 'ig');
const MOBILE_SMS_REG = new RegExp('mobile sms', 'ig');

const HEADER_REG_VALUES = [
  { field: 'firstName', reg: FIRST_NAME_REG },
  { field: 'lastName', reg: LAST_NAME_REG },
  { field: 'username', reg: USER_NAME_REG },
  { field: 'phone1', reg: PHONE_REG },
  { field: 'cellPhone', reg: PHONE_REG },
  { field: 'email', reg: EMAIL_REG },
  { field: 'password', reg: PASSWORD_REG },
  // { field: 'phone2', reg: null },
  { field: 'title', reg: TITLE_REG },
  { field: 'departmentName', reg: DEPARTMENT_REG },
  { field: 'isAdmin', reg: ADMIN_REG },
  { field: 'automotiveRoleLabel', reg: AUTOMOTIVE_ROLE_REG },
  { field: 'nmls', reg: NMLS_REG },
  { field: 'userType', reg: USER_TYPE_REG },
  // SuperAdmin user fields
  { field: 'active', reg: VERIFIED_REG },
  { field: 'transcriptionAccess', reg: CAPTION_REG },
  { field: 'mobileSMSAccess', reg: MOBILE_SMS_REG },
];

const FieldLength = {
  firstName: 25,
  lastName: 25,
  email: 255,
  phone1: 25,
  cellPhone: 25,
  // phone2: 25,
  username: 50,
  password: 25,
};

export enum ModalOrigin {
  USERS = 1,
  SUPER_ADMIN_USERS = 2,
}

export enum ModalType {
  ONE = 'one',
  BULK = 'bulk',
}

type Props = {
  disabled?: boolean;
  title?: string;
  text?: any;
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleSubmit: (data: object) => void;
  modalType?: ModalType;
  origin?: ModalOrigin;
  customerId?: number;
  automotiveEnabled?: boolean;
};

type csvDataProps = {
  data: string[][];
  uploaded?: boolean;
};

type colOptionProps = {
  label: string;
  value: string;
};

type ListItemProps = {
  bgColor?: string;
};

const EMAIL_VALIDATION_REG =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const ModalItem = styled.div`
  min-height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

const ContentFooter = styled.div`
  span {
    opacity: 0.75;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.themeDark};
  }
  p {
    opacity: 0.6;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin-left: 22px;

    b {
      opacity: 0.8;
      font-size: 13px;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Form = styled.form`
  width: 100%;
  margin-top: 1em;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

const NoteSpan = styled.span`
  float: left;
  width: 590px;
  margin: 16px 0 16px 0;
`;

const ErrorMessage = styled.div`
  background-color: ${theme.palette.secondaryRedDanger};
  height: 48px;
  width: 100%;
  margin-top: 5px;
  span {
    display: flex;
    color: ${theme.palette.primaryRedDanger};
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  svg {
    margin: 8px 16px 0 8px;
  }
`;

const TableCellHeader = styled.div`
  text-align: left;
`;

const TableCell = styled.div`
  padding-right: 8px;
  text-align: left;
`;

const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

const SelectInput = styled(Select)`
  width: 200px;
  border-radius: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  div {
    width: 88px;
    border-right: 1px solid #f6f7f9;
    border-bottom: 1px solid #f6f7f9;
    padding: 5px;
    text-align: center;
    justify-content: center;
    display: flex;
    .span--holder {
      min-width: 50px;
      max-width: 50px;
      min-height: 20px;
      background: #eeeff2;
      border-radius: 5px;
    }
  }
  .bottom--none {
    border-bottom: none;
  }
`;

const ListContainer = styled.div`
  max-height: 410px;
  overflow-y: scroll;
`;

const ListItem = styled.div<ListItemProps>`
  background: ${props => (props.bgColor ? props.bgColor : '#F6F7F9')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 10px;
  .item--label {
    display: flex;
    label {
      padding-left: 10px;
    }
  }
`;

const Description = styled.div`
  display: flex;
  justify-content: center;
  background: #e2ecf5;
  padding: 5px;
`;

const UserExistLabel = styled.div`
  padding: 5px;
  background: white;
  border-radius: 7px;
  margin-right: 15px;
  color: #e84c3dcc;
  font-weight: 600;
`;

const UserExistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const initialCSVData: csvDataProps = { data: [], uploaded: false };
const initialColOptions: colOptionProps[] = [];
const initialColMapping: { [key: string]: string } = {
  firstName: 'None',
  lastName: 'None',
  username: 'None',
  phone1: 'None',
  cellPhone: 'None',
  email: 'None',
  password: 'None',
  // phone2: 'None',
  title: 'None',
  departmentName: 'None',
  isAdmin: 'None',
  automotiveRoleLabel: 'None',
  nmls: 'None',
  // Super admin user fields
  active: 'None',
  transcriptionAccess: 'None',
  mobileSMSAccess: 'None',
};

const initialColSampleData: { [key: string]: string[] } = {};
const initialInvalidRows: { [key: string]: boolean } = {};
const initialDuplicatedRows: { [key: string]: string[] } = {};

enum Views {
  MAPPING = 1,
  NON_IMPORTED_USERS = 2,
  IMPORT = 3,
  LOADING = 5,
  MAPPING_IMPORT = 6,
}

export const ImportUserModal = ({
  modalType = ModalType.ONE,
  disabled = false,
  title = '',
  text = '',
  origin = ModalOrigin.USERS,
  customerId = 0,
  automotiveEnabled,
  handleModalClose,
  handleSubmit,
}: Props) => {
  const { userData } = useAuth();
  const isAutomotive = automotiveEnabled ?? userData.isAutomotive;
  const FieldOptions = [
    { value: 'None', label: 'Choose...' },
    { value: 'firstName', label: 'First Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'username', label: 'Username' },
    { value: 'phone1', label: 'Phone Number' },
    { value: 'cellPhone', label: 'Cell Phone Number' },
    { value: 'email', label: 'Email' },
    { value: 'password', label: 'Password' },
    // { value: 'phone2', label: 'Phone Number-2' },
    { value: 'title', label: 'Title' },
    { value: 'departmentName', label: 'Department' },
    { value: 'isAdmin', label: 'Admin' },
    { value: 'nmls', label: 'NMLS' },
    { value: 'userType', label: 'User Type' },

    // Super admin user fields
    { value: 'active', label: 'Active' },
    { value: 'transcriptionAccess', label: 'Enable Captions' },
    { value: 'mobileSMSAccess', label: 'Mobile SMS' },
  ];
  const REQUIRED_FIELDS = [
    'firstName',
    'lastName',
    'username',
    // 'phone1',
    'email',
    'password',
  ];
  if (isAutomotive) {
    REQUIRED_FIELDS.push('automotiveRoleLabel');
    FieldOptions.push({
      value: 'automotiveRoleLabel',
      label: 'Automotive Role',
    });
  }
  const [currentView, setCurrentView] = useState<Views>(Views.IMPORT);
  const [CSVData, setCSVData] = useState(initialCSVData);
  const [colOptions, setColOptions] = useState(initialColOptions);
  const [colMapping, setColMapping] = useState(initialColMapping);
  const [colSamples, setColSamples] = useState(initialColSampleData);
  const [uploadError, setUploadError] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [_, setUserValid] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [nonImportedUser, setNonImportedUser] = useState<any>({
    discardedUsers: [],
    message: '',
  });
  const { showError } = useToastError();

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: '',
    automotiveRoleLabel: '',
    // phone1: '',
    // phone2: '',
  });
  const [invalidRows, setInvalidRows] = useState(initialInvalidRows);
  const [invalidCount, setInvalidCount] = useState(0);
  const [duplicatedRows, setDuplicatedRows] = useState(initialDuplicatedRows);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const values = Object.keys(colMapping).reduce((obj: any, field: string) => {
      CSVData.data.forEach((d: string[]) => {
        if (!obj[field]) {
          obj[field] = [];
        }
        obj[field].push(getColValue(d, field));
      });
      return obj;
    }, {});
    const invalid: { [key: string]: boolean } = {};
    const duplicated: { [key: string]: string[] } = {};
    Object.keys(values).forEach(field => {
      const data = values[field];
      for (let i in data) {
        invalid[i] =
          invalid[i] ||
          !validate(data[i], field) ||
          // @ts-ignore
          data[i].lenght > FieldLength[field];
        if (field === 'username') {
          const username = data[i];
          if (!duplicated[username]) {
            duplicated[username] = [];
          }
          if (!invalid[i]) {
            const index = Number(i) + 2;
            duplicated[username].push(index.toString());
          }
          if (duplicated[username].length > 1) {
            invalid[i] = true;
          }
        }
      }
    });
    setInvalidRows(invalid);
    setDuplicatedRows(duplicated);
    setInvalidCount(Object.values(invalid).filter(Boolean).length);
  }, [colMapping, CSVData.data]);

  const initColOptions = (row: string[]) => {
    const options = row.map((_: string, i: number) => ({
      value: `${i}`,
      label: `Col ${i + 1}`,
    }));

    options.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
  };

  useEffect(() => {
    const bulkValid = modalType === ModalType.BULK && !!CSVData.data.length;
    setUserValid(bulkValid);
  }, [modalType, CSVData.data]);
  const initCSVHeaders = (row: string[]) => {
    const options = [];
    let found = false;
    let count = 0;
    for (let i in row) {
      if (!row[i]) {
        break;
      }
      let value = row[i].toString();
      options.push({ label: value, value: `${i}` });
      for (let header of HEADER_REG_VALUES) {
        if (header.reg != null && value.match(header.reg)) {
          found = true;
          count++;
          setColMapping(value => ({ ...value, [header.field]: `${i}` }));
        }
      }
    }
    options.unshift({ value: 'None', label: 'Choose...' });

    found ? setColOptions(options) : setColMapping(initialColMapping);
    return { found, count };
  };

  const initSampleData = (data: string[][]) => {
    let sample = { ...colSamples };
    let sampleCollected;
    let i = 0;
    while (!sampleCollected && i < data.length) {
      let row = data[i];
      row.forEach((field, index) => {
        if (!sample[index]) {
          sample[index] = [];
        }
        sample[index].push(field);
      });
      let values = Object.keys(sample).map(k => sample[k]);
      sampleCollected = values.reduce(
        (collected, fieldSample) =>
          collected && fieldSample.length >= SAMPLE_LENGTH,
        false
      );
      i++;
    }
    setColSamples(sample);
  };

  const onColChange = (field: string, value: string) => {
    const newField =
      Object.keys(colMapping).find(key => colMapping[key] === value) || 'None';
    if (field === 'None') {
      setColMapping({ ...colMapping, [newField]: 'None' });
    } else {
      setColMapping({ ...colMapping, [newField]: 'None', [field]: value });
    }
  };

  const onUpload = (csv: string[][], fileInfo: any) => {
    const data = [...csv];
    setUploadError(false);
    if (!fileInfo.name.includes('csv')) {
      setUploadError(true);
      return;
    }
    const firstRow = data[0];
    const { found } = initCSVHeaders(firstRow);
    if (found) {
      data.shift();
    } else {
      initColOptions(firstRow);
    }
    initSampleData(data);
    setCSVData({ data, uploaded: true });
    setCurrentView(Views.MAPPING);
  };

  const getSampleData = (index: string) => {
    if (!colSamples[index]) {
      return '';
    }
    return colSamples[index].filter(Boolean).slice(0, SAMPLE_LENGTH);
  };

  const getColValue = (data: string[], field: string) => {
    const columnValue = get(colMapping, field, '');
    if (!columnValue || columnValue === 'None') {
      return '';
    }
    return data[parseInt(columnValue)]?.toString().trim() || '';
  };

  const initializeListView = () => {
    setSelectedUser(
      CSVData.data
        .filter((e: any, i: number) => !invalidRows[i])
        .map((e: any) => getColValue(e, 'username'))
    );
    setCurrentView(Views.MAPPING_IMPORT);
  };

  const getAccess = (data: string[]) => {
    const isAdmin = getColValue(data, 'isAdmin').toLowerCase();
    const userType = getColValue(data, 'userType').toLowerCase();
    if (isAdmin === 'yes' || userType === 'admin') {
      return AccessRole.ADMIN;
    }

    if (userType === 'supervisor') {
      return AccessRole.SUPERVISOR;
    }

    return AccessRole.USER;
  };
  const onSubmit = async () => {
    try {
      const users = CSVData.data
        .map(
          (data, i) =>
            !invalidRows[i] &&
            selectedUser.includes(getColValue(data, 'username')) && {
              firstName: getColValue(data, 'firstName'),
              lastName: getColValue(data, 'lastName'),
              email: getColValue(data, 'email'),
              phone1: getColValue(data, 'phone1'),
              cellPhone: getColValue(data, 'cellPhone'),
              username: getColValue(data, 'username'),
              password: getColValue(data, 'password'),
              // phone2: getColValue(data, 'phone2'),
              title: getColValue(data, 'title'),
              departmentName: getColValue(data, 'departmentName'),
              access: getAccess(data),
              automotiveRole: getAutomotiveRoleValueFromLabel(
                getColValue(data, 'automotiveRoleLabel')
              ),
              nmls: getColValue(data, 'nmls'),
              active: getColValue(data, 'verified'),
              transcriptionAccess: getColValue(data, 'transcriptionAccess'),
              mobileSMSAccess: getColValue(data, 'mobileSMSAccess'),
            }
        )
        .filter(Boolean);
      const response =
        origin === ModalOrigin.USERS
          ? await importUsers({ users })
          : await importSuperAdminUsers({ users, customerId });
      const importedUserIds = response?.data?.userIds;
      if (importedUserIds?.length) {
        handleSubmit(importedUserIds);
      }
      if (
        response.data &&
        response.data.discardedUsers &&
        response.data.discardedUsers.length
      ) {
        setNonImportedUser({
          discardedUsers: response.data.discardedUsers,
          message: response.message,
        });
        setCurrentView(Views.NON_IMPORTED_USERS);
        return;
      }
      successToast({ title: response.message });
      handleModalClose(true);
    } catch (error) {
      showError(error);
    }
  };

  const validateEmail = (email: string = '') => {
    let emailError = '';
    if (!email) {
      emailError = 'Please enter email address';
    } else if (!EMAIL_VALIDATION_REG.test(email.toLowerCase())) {
      emailError = 'Please enter valid email address';
    } else if (!validateInputForCsvFormulaInjection(email)) {
      emailError = 'Please enter valid email';
    }
    setErrors({ ...errors, email: emailError });
    return emailError;
  };

  // const validatePhone = (phone: string | undefined) => {
  // let phoneError = '';
  // AS per ticket DEV-682
  // if (phone && !PHONE_VALIDATION_REG.test(phone)) {
  //   phoneError = 'Please enter valid phone number';
  // }
  // setErrors({ ...errors, phone1: phoneError });
  // return phoneError;
  // };

  // const validatePhone2 = (phone: string | undefined) => {
  //   let phoneError = '';
  //   if (phone && phone.length && phone.length < 7 && !PHONE_VALIDATION_REG.test(phone)) {
  //     phoneError = 'Please enter valid phone number';
  //   }
  //   setErrors({ ...errors, phone2: phoneError });
  //   return phoneError;
  // };

  const validateFirstName = (firstName: string | undefined) => {
    let firstNameError = '';
    if (!firstName) {
      firstNameError = 'Please enter valid first name';
    } else if (!validateInputForCsvFormulaInjection(firstName)) {
      firstNameError = 'Please enter valid first name';
    }
    setErrors({ ...errors, firstName: firstNameError });
    return firstNameError;
  };

  const validateLastName = (lastName: string | undefined) => {
    let lastNameError = '';
    if (!lastName) {
      lastNameError = 'Please enter valid last name';
    } else if (!validateInputForCsvFormulaInjection(lastName)) {
      lastNameError = 'Please enter valid last name';
    }
    setErrors({ ...errors, lastName: lastNameError });
    return lastNameError;
  };

  const validateIsAdmin = (isAdmin: string | undefined) => {
    let isAdminError = '';
    if (isAdmin && !['yes', 'no'].includes(isAdmin.toLowerCase())) {
      isAdminError = 'Please enter yes or no for Admin';
    }

    setErrors({ ...errors, isAdmin: isAdminError });
    return isAdminError;
  };

  const validateAutomotiveRoleLabel = (automotiveRole: string | undefined) => {
    let automotiveRoleError = '';
    if (
      automotiveRole &&
      !getAutomotiveRoleValueFromLabel(automotiveRole.toLowerCase())
    ) {
      automotiveRoleError = 'Please enter a valid Automotive Role';
    }

    setErrors({ ...errors, automotiveRoleLabel: automotiveRoleError });
    return automotiveRoleError;
  };

  const validationMapping: { [key: string]: Function } = {
    email: validateEmail,
    //phone1: validatePhone,
    firstName: validateFirstName,
    lastName: validateLastName,
    // phone2: validatePhone2,
    isAdmin: validateIsAdmin,
    automotiveRoleLabel: validateAutomotiveRoleLabel,
  };

  const validate = (value: any, field: string) => {
    if (!validationMapping[field]) {
      return true;
    }
    return !validationMapping[field](value);
  };

  const parseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const AddBulkUser = (
    <ModalItem>
      <Content>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          {text}
          <HeaderContainer>
            <div>First name</div>
            <div>Last name</div>
            <div>Email</div>
            {/* <div>Phone</div> */}
            <div>Username</div>
            <div>Password</div>
            {isAutomotive && <div style={{ width: 122 }}>Automotive role</div>}
          </HeaderContainer>
          <HeaderContainer>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            {/* <div className="bottom--none">
              <div className="span--holder"></div>
            </div> */}
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            {isAutomotive && (
              <div
                style={{ width: 122, maxWidth: 122, minWidth: 122 }}
                className='bottom--none'
              >
                <div className='span--holder'></div>
              </div>
            )}
          </HeaderContainer>
          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                width={isAutomotive ? 630 : 590}
              />

              {uploadError && (
                <ErrorMessage>
                  <span>
                    <CloseIcon
                      color={theme.palette.primaryRedDanger}
                      width={32}
                      height={32}
                    />
                    Uploaded file is not in .csv format. Try again!
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
        <ContentFooter>
          <NoteSpan style={{ marginBottom: 0 }}>
            The chosen email address(es) is/are permission based - Recipient(s)
            has/have specifically asked to receive emails from me See more{' '}
            {seeMore
              ? ' to receive emails from me or I have a relationship with the recipient(s). ' +
                'The chosen email address(es) has/have not been purchased - Recipient(s) has/have not been purchased ' +
                'from any source in any way. The chosen email address(es) is/are not part of a mailing list or ' +
                'distribution list - Recipient(s) does/do not redistribute emails that have been sent to it to more ' +
                'than one email address on a regular basis. The chosen email address(es) is/are not captured - ' +
                'Recipient(s) has/have not been captured by any means (i.e. surfing the internet). '
              : 't... '}
            <b
              style={{
                fontWeight: 700,
                color: theme.palette.primaryDarkBlue,
                cursor: 'pointer',
              }}
              onClick={() => setSeeMore(!seeMore)}
            >
              See {seeMore ? 'less' : 'more'}
            </b>
          </NoteSpan>
        </ContentFooter>
      </Content>
    </ModalItem>
  );

  const printDuplicatedRows = React.useCallback(() => {
    const result: any[] = [];
    for (let username in duplicatedRows) {
      if (duplicatedRows[username].length > 1) {
        result.push(
          <Label key={`error-key-${username}`}>
            <Text>
              {' '}
              {username} has multiple entries( line{' '}
              {duplicatedRows[username].join(', ')})
            </Text>
          </Label>
        );
      }
    }
    return result;
  }, [duplicatedRows]);

  const MapImpoted = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 42 }}>
            Connect columns from .csv file to user fields
          </div>
          <Table
            compact={true}
            headers={[
              <TableCellHeader>Column Label (csv)</TableCellHeader>,
              <TableCellHeader>Example</TableCellHeader>,
              <TableCellHeader>User Field</TableCellHeader>,
            ]}
            hoverable={false}
            rows={colOptions.slice(1).map((x, index: number) => ({
              key: index,
              columns: [
                <TableCell>{x.label}</TableCell>,
                <TableCell>{getSampleData(index.toString())}</TableCell>,
                <TableCell>
                  <SelectInput
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Choose...'
                    styles={{
                      control: (base: any) => ({ ...base, height: '40px' }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    options={FieldOptions}
                    value={FieldOptions.find(
                      option =>
                        option.value ==
                        (Object.keys(colMapping).find(
                          key => colMapping[key] === index.toString()
                        ) || 'None')
                    )}
                    onChange={(option: any) =>
                      onColChange(option.value, index.toString())
                    }
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
          {invalidCount > 0 && invalidCount < CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage>
                  <span>
                    <WarningIcon
                      color={theme.palette.primaryRedDanger}
                      width={32}
                      height={32}
                    />
                    {invalidCount}/{CSVData.data.length} rows contain invalid
                    data. Those rows will be ignored.
                  </span>
                </ErrorMessage>
              </Row>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {invalidCount == CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <WarningIcon
                      color={theme.palette.primaryRedDanger}
                      width={24}
                      height={24}
                      style={{ marginTop: '0px' }}
                    />
                    {colMapping.username !== 'None'
                      ? 'Please check username column label in .csv file and ensure username column mapping with username column field'
                      : 'The Username field is required for importing users into Covideo. Please check your file to ensure you have created and labeled a column for Username.'}{' '}
                  </span>
                </ErrorMessage>
              </Row>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {REQUIRED_FIELDS.some((e: any) => colMapping[e] == 'None') && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <WarningIcon
                      color={theme.palette.primaryRedDanger}
                      width={24}
                      height={24}
                      style={{ marginTop: '0px' }}
                    />
                    {REQUIRED_FIELDS.filter(
                      (e: any) => colMapping[e] == 'None'
                    ).join(', ') +
                      ' field is required for importing users into Covideo. Please check your file to ensure you have created and labeled a column for ' +
                      REQUIRED_FIELDS.filter(
                        (e: any) => colMapping[e] == 'None'
                      ).join(', ')}{' '}
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={
            CSVData.data.length - invalidCount == 0 ||
            REQUIRED_FIELDS.some(e => colMapping[e] == 'None')
          }
          text={'Continue'}
          onClick={() => {
            initializeListView();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const ImportUser = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Choose users to import</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <ListContainer>
            <ListItem key={`user-item-global`} bgColor={'#FFFFF'}>
              <div className='item--label'>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={false}
                  checked={
                    selectedUser.length == CSVData.data.length - invalidCount
                  }
                  onChange={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    let { checked } = event.target as HTMLInputElement;
                    if (checked) {
                      setSelectedUser(
                        CSVData.data
                          .filter((e: any, i: number) => !invalidRows[i])
                          .map((e: any) => getColValue(e, 'username'))
                      );
                    } else setSelectedUser([]);
                  }}
                />
                <label>All Users</label>
              </div>
            </ListItem>
            {CSVData.data.map((e: any, i: number) => {
              if (invalidRows[i]) return null;
              return (
                <ListItem key={`user-item-${i}`}>
                  <div className='item--label'>
                    <CheckboxInput
                      blueCheck={true}
                      checkGroupIndicator={false}
                      checked={selectedUser.includes(
                        getColValue(e, 'username')
                      )}
                      onChange={(event: React.SyntheticEvent) => {
                        event.stopPropagation();
                        let { checked } = event.target as HTMLInputElement;
                        if (checked)
                          setSelectedUser([
                            ...selectedUser,
                            getColValue(e, 'username'),
                          ]);
                        else
                          setSelectedUser(
                            selectedUser.filter(
                              (el: any) => el != getColValue(e, 'username')
                            )
                          );
                      }}
                    />
                    <label>{getColValue(e, 'firstName')}</label>
                  </div>
                  <label>{getColValue(e, 'email')}</label>
                </ListItem>
              );
            })}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={!selectedUser.length}
          text={`Import ${selectedUser.length} users`}
          onClick={() => {
            setCurrentView(Views.LOADING);
            onSubmit();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const NonImportedUsers = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>Import Complete</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <Description
            style={{ justifyContent: 'start', background: 'transparent' }}
          >
            {nonImportedUser.message}
          </Description>
          <Description
            style={{
              justifyContent: 'start',
              background: 'transparent',
              paddingLeft: '17px',
            }}
          >
            List of skipped (not imported) users
          </Description>
          <ListContainer>
            {nonImportedUser.discardedUsers.map((user: any, i: number) => {
              if (invalidRows[i]) return null;
              return (
                <ListItem key={`user-item-${i}`}>
                  <div className='item--label'>
                    <label>{`${user.firstName} ${user.lastName}`}</label>
                  </div>
                  <UserExistWrapper>
                    <UserExistLabel>{user.failReason}</UserExistLabel>
                    <div style={{ color: '#9297A2' }}>{user.email}</div>
                  </UserExistWrapper>
                </ListItem>
              );
            })}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text='Finish'
          onClick={() => {
            handleModalClose(true);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Importing users...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.IMPORT:
      CurrentVievComponent = AddBulkUser;
      break;
    case Views.MAPPING:
      CurrentVievComponent = MapImpoted;
      break;
    case Views.MAPPING_IMPORT:
      CurrentVievComponent = ImportUser;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
    case Views.NON_IMPORTED_USERS:
      CurrentVievComponent = NonImportedUsers;
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
