import styled from 'styled-components/macro';
import { ReactComponent as Restore } from './restore.svg';

const RestoreIcon = styled(Restore)`
  width: ${props => props.width || '16px'};
  height: ${props => props.height || '16px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default RestoreIcon;
