import styled from 'styled-components/macro';
import { ReactComponent as DotsSvg } from './dots-vertical.svg';

export const DotsVerticalIcon = styled(DotsSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;
