import React from 'react';
import styled from 'styled-components/macro';
import { ADMIN_ACCESS } from '../const';
import { Badge, BadgeType } from 'lib/components/Badge';
import { theme } from 'lib/style';
import ShowServicesIcon from 'lib/images/ShowServicesIcon';
import DeleteIcon from 'lib/images/DeleteIcon';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;
  border-bottom: 1px solid ${theme.palette.gray10};
  color: ${theme.palette.covideoBlue100};
`;

const Left = styled.div`
  font-size: 14px;
  margin-right: 20px;
`;

const Name = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

const Username = styled.div`
  margin-bottom: 5px;
`;

type AdminProps = {
  name: string;
  username: string;
  accessLevel?: string;
  creatingOrganization?: boolean;
  isNewAdmin?: boolean;
  onClick: () => void;
};

const AdminCard = ({
  accessLevel,
  username,
  name,
  creatingOrganization = false,
  isNewAdmin = false,
  onClick,
}: AdminProps) => {
  const editVariant = !(creatingOrganization || isNewAdmin);

  return (
    <Container>
      <Left>
        <Name>{name}</Name>
        <Username>{username}</Username>
        {!isNewAdmin && (
          <Badge
            width={100}
            text={accessLevel || ''}
            type={
              accessLevel === ADMIN_ACCESS.FULL
                ? BadgeType.LIGHT_ORANGE
                : BadgeType.GRAY
            }
          />
        )}
      </Left>
      <div>
        {editVariant ? (
          <Button
            onClick={onClick}
            icon={<ShowServicesIcon color={theme.palette.covideoBlue100} />}
            text='Configure Access'
            variant='secondary'
          />
        ) : (
          <Button
            onClick={onClick}
            icon={<DeleteIcon height={20} color={theme.palette.red100} />}
            text='Remove'
            variant='destructive'
          />
        )}
      </div>
    </Container>
  );
};

export default AdminCard;
