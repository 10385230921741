import React, { useState } from 'react';

import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { LoadingIndicator, Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { sendReportData } from 'lib/api';
import { RecipientsSelector } from './RecipientsSelector';
import { successToast } from 'lib/components/toasts/success';
import { ReportTypes } from 'lib/const/ReportData';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { SendInfoMessage } from 'app/pages/admin/customers/components/SendInfoMessage';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const Caption = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 14px;
`;

type Props = {
  handleModalClose: (showRecurring: boolean) => void;
  startDate: Date;
  endDate: Date;
  range: string;
  reportUrl?: string;
  reportId?: string;
  users?: string[];
  customer?: string;
  extraParams?: any;
  onError?: () => void;
  sendDataFunction?: (recipients: string[]) => Promise<void>;
  reports?: ReportTypes[];
  isRecurring?: boolean;
};

export const SendReportModal = (props: Props) => {
  const {
    startDate,
    endDate,
    reportUrl,
    reportId,
    handleModalClose,
    range,
    users = [],
    customer,
    extraParams,
    onError,
    sendDataFunction,
    reports,
    isRecurring,
  } = props;
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();

  const currentUserEmail = isRecurring ? [userData?.email] : [];
  const [recipients, setRecipients] = useState<string[]>(currentUserEmail);
  const [error, setError] = useState('');

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const sendReport = async () => {
    setLoading(true);
    try {
      let url = '';

      if (reportUrl) {
        url = reportUrl;
      }

      if (reportId) {
        url = `custom-reports/${reportId}`;
      }

      if (sendDataFunction) {
        await sendDataFunction(recipients);
        setLoading(false);
        handleModalClose(false);
        return;
      }
      await sendReportData(
        url,
        startDate,
        endDate,
        range,
        recipients,
        users,
        customer,
        extraParams,
        undefined,
        undefined,
        reports
      );
      successToast({ title: 'The report was successfully sent!' });
      handleModalClose(false);
    } catch (error) {
      console.log(error);
      if (onError) onError();
    }
    setLoading(false);
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Send Report</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose(false)}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <Content>
          {loading && <LoadingIndicator isLoading={loading} height='50px' />}

          {!loading && (
            <>
              {isRecurring && <SendInfoMessage />}
              <Caption>
                {isRecurring
                  ? 'Additional Recipients (optional)'
                  : 'Share the report with additional recipients'}
              </Caption>

              <RecipientsSelector
                onChange={recipients => setRecipients(recipients)}
                onError={email => {
                  if (email) {
                    setError('Invalid email address.');
                  } else {
                    setError('');
                  }
                }}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Content>
        <Footer>
          {!loading && (
            <Button
              text='Send'
              disabled={!recipients.length}
              onClick={() => sendReport()}
            />
          )}
        </Footer>
      </div>
    </Modal>
  );
};
