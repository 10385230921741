import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { DATE_FORMAT } from '../repairOrders/const';
import { REPORT_SCOPE } from '../reportsApi';

export interface ISendCustomReportPayload {
  reportId: number;
  from: Date;
  to: Date;
  range: string;
  receivers: string[];
  reportScope?: REPORT_SCOPE;
}

const sendCustomReport = async ({
  reportId,
  from,
  to,
  range,
  receivers,
  reportScope = REPORT_SCOPE.RESELLER,
}: ISendCustomReportPayload): Promise<string> => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    range,
    reportScope,
    timezoneOffset: new Date().getTimezoneOffset(),
  };

  const response = await EXPRESS_API.post(
    `/custom-reports/${reportId}/send`,
    { receivers },
    {
      params,
    }
  );

  return response.data;
};

export const useSendCustomReportMutation = () => {
  return useMutation(sendCustomReport, {
    onSuccess: () => {
      successToast({ title: 'Custom report sent successfully' });
    },
    onError: () => {
      errorToast({ title: 'Something went wrong sending the report' });
    },
  });
};
