import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CheckboxInput, LoadingIndicator, Search } from 'lib/components';
import { CustomerList } from './CustomersWithUsers/CustomerList';
import {
  calculateNonEmptyKeysAndTotalItems,
  createInitialState,
  getAllUserIds,
  getInitialCustomersFromUserData,
  getTotalUsers,
} from './utils';
import {
  IResellerCustomersResponse,
  ResellerCustomer,
  useCustomersResellersQuery,
} from 'lib/api/superadmin/useCustomersResellersQuery';
import { useAuth } from 'lib/context';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../../types';

import { Button } from 'react-covideo-common';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';

const Content = styled.div`
  width: 944px;
  height: 100%;
  margin-top: 32px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 30px;
`;

const SelectionCountWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const SelectionCountText = styled.p`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #272a32;
  margin: 0;
  display: flex;
  align-items: center;
`;

const UsersList = styled.div`
  margin-top: 32px;
  height: 600px;
  padding-bottom: 32px;
  overflow-y: auto;
`;

const Message = styled.p`
  font-family: Work Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin: 0 0 32px 0;
  color: ${({ theme }) => theme.colors.neutral[80]};
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

type Props = {
  initUsers?: string[];
  setResellerCustomersResponse: React.Dispatch<
    React.SetStateAction<IResellerCustomersResponse | undefined>
  >;
  resellerCustomersResponse: IResellerCustomersResponse | undefined;
};

const size = 750;

export const SelectCustomReportCustomerUsers = ({
  initUsers,
  setResellerCustomersResponse,
  resellerCustomersResponse,
}: Props) => {
  const { userData } = useAuth();

  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  //  to calculate SelectionCountText on initial load
  const initialResselersCustomers: ResellerCustomer[] =
    getInitialCustomersFromUserData(values);

  const [customerState, setCustomerState] = useState<{
    [key: string]: string[];
  }>({});
  const [searchQuery, setSearchQuery] = React.useState('');

  const { data, isLoading, fetchNextPage } = useCustomersResellersQuery({
    params: {
      resellerId: userData.resellerId,
      search: searchQuery,
      limit: size,
    },
    enabled: true,
  });

  const filteredUsers =
    resellerCustomersResponse?.customers.filter(customer =>
      customer.business.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const filteredUsersLength = getTotalUsers(filteredUsers);
  const totalUsersLength = getTotalUsers(resellerCustomersResponse?.customers);

  const filteredUsersValue = filteredUsers
    .flatMap(customer => customer.users.map(user => `${user.id}`))
    .filter(id => values.users.includes(id)).length;

  const isAllSelected = filteredUsersLength === filteredUsersValue;

  useEffect(() => {
    if (data) {
      // on data change calculate customers
      const customers = [
        ...(resellerCustomersResponse?.customers || []),
        ...(data?.pages.flatMap(customer => customer.customers.map(d => d)) ||
          []),
        ...initialResselersCustomers,
      ];

      const customersMap: { [key: number]: ResellerCustomer } = {};

      // Iterate over the customers array and populate the customersMap
      customers.forEach(customer => {
        if (!customersMap[customer.customerId]) {
          // If customer doesn't exist in the map, add it
          customersMap[customer.customerId] = customer;
        } else {
          // If customer already exists in the map, update if it has more users
          if (
            customer.users.length >=
            customersMap[customer.customerId].users.length
          ) {
            customersMap[customer.customerId] = customer;
          }
        }
      });

      // Convert the customersMap back to an array
      const result: ResellerCustomer[] = Object.values(customersMap);

      // Result contains ResellerCustomer objects with unique customer IDs and the ones with the most users

      const allCustomers: IResellerCustomersResponse | undefined = {
        ...(data.pages[0] || {}),
        customers: result,
      };

      const initialUsers = createInitialState(allCustomers, initUsers || []);
      setCustomerState(initialUsers);
      setResellerCustomersResponse(allCustomers);
    }
  }, [data]);

  const getSelectedCustomerIds = (customerState: {
    [key: string]: string[];
  }) => {
    const selectedCustomerIds = Object.entries(customerState)
      .filter(([, value]) => value.length)
      .map(([key]) => key.toString());
    return selectedCustomerIds;
  };

  const setSelectedCustomersHandler = (customer: ResellerCustomer) => {
    const updatedState = { ...customerState };

    const isCustomerSelected =
      updatedState[customer.customerId]?.length === customer.users.length;

    // Toggle selection of the customer
    if (isCustomerSelected) {
      // If the customer is already selected, deselect it and remove associated users
      updatedState[customer.customerId] = [];
    } else {
      // If the customer is not selected, select it and add associated users
      updatedState[customer.customerId] = customer.users.map(u => `${u.id}`);
    }
    const userIds = getAllUserIds(updatedState);
    setFieldValue('users', userIds);

    setCustomerState(updatedState);
  };

  const setSelectedUsersHandler = (userId: string, customerId: string) => {
    const updatedState = { ...customerState };

    const isUserSelected = customerState[customerId]?.includes(userId);
    if (isUserSelected) {
      // If the user is already selected, remove it from the selected users
      updatedState[customerId] =
        updatedState[customerId]?.filter(id => id !== userId) || [];
    } else {
      // If the user is not selected, add it to the selected users
      updatedState[customerId] = [...(updatedState[customerId] || []), userId];
    }
    const userIds = getAllUserIds(updatedState);
    setFieldValue('users', userIds);
    const selectedCustomerIds = getSelectedCustomerIds(updatedState);
    setFieldValue('customerIds', selectedCustomerIds);
    setCustomerState(updatedState);
  };

  const toggleSelectAll = () => {
    const tempCustomerState = { ...customerState };

    if (isAllSelected) {
      const resetedCustomers = Object.fromEntries(
        filteredUsers?.map(customer => [customer.customerId, []]) || []
      );
      const userIds = getAllUserIds({
        ...tempCustomerState,
        ...resetedCustomers,
      });
      setFieldValue('users', userIds);
      const selectedCustomerIds = getSelectedCustomerIds({
        ...tempCustomerState,
        ...resetedCustomers,
      });
      setFieldValue('customerIds', selectedCustomerIds);
      return setCustomerState({ ...tempCustomerState, ...resetedCustomers });
    }
    const updatedState = {} as { [key: string]: string[] };
    filteredUsers.forEach(customer => {
      updatedState[customer.customerId] = customer.users.map(
        user => `${user.id}`
      );
    });
    const userIds = getAllUserIds({ ...tempCustomerState, ...updatedState });
    setFieldValue('users', userIds);
    const selectedCustomerIds = getSelectedCustomerIds({
      ...tempCustomerState,
      ...updatedState,
    });
    setFieldValue('customerIds', selectedCustomerIds);
    setCustomerState({ ...tempCustomerState, ...updatedState });
  };

  const toggleFutureUsers = () => {
    const selectedCustomerIds = getSelectedCustomerIds(customerState);
    setFieldValue('customerIds', selectedCustomerIds);
    setFieldValue('includeNewUsers', !values.includeNewUsers);
  };

  const { selectedUsers, selectedCompanies } =
    calculateNonEmptyKeysAndTotalItems(customerState);

  const loadedCustomers =
    data?.pages.reduce(
      (accumulator, page) => accumulator + page.customers.length,
      0
    ) || 0;

  return (
    <Content>
      <>
        <Message>
          Select users whose data will be included in the report
        </Message>
        <Menu>
          <SelectionCountWrapper>
            <CheckboxInput
              checked={filteredUsersLength === 0 ? false : isAllSelected}
              onChange={toggleSelectAll}
              disabled={isLoading || filteredUsersLength === 0}
            />
            <SelectionCountText>
              Selected: {selectedUsers}/{totalUsersLength} users in{' '}
              {selectedCompanies}{' '}
              {selectedCompanies > 1 ? 'companies' : 'company'}
            </SelectionCountText>
          </SelectionCountWrapper>
          <SelectionCountWrapper>
            <CheckboxInput
              checked={!!values.includeNewUsers}
              onChange={toggleFutureUsers}
            />
            <SelectionCountText>
              <div style={{ fontWeight: 'bold' }}>Include New Users</div>
            </SelectionCountText>
          </SelectionCountWrapper>
          <div style={{ width: '300px' }}>
            <Search
              placeholder='Search companies...'
              onSearch={query => setSearchQuery(query)}
              disabled={isLoading}
              initialValue={searchQuery}
            />
          </div>
        </Menu>

        {isLoading ? (
          <div>
            <LoadingIndicator isLoading={true} />
          </div>
        ) : (
          <>
            {data?.pages[0]?.customersCount === 0 ? (
              <EmptySearch>
                <ParagraphSmallBold
                  color={theme.palette.blue60}
                  style={{ width: 485, textWrap: 'initial' }}
                >
                  We couldn't find any users with the name '{searchQuery}' in
                  their first or last names. Please try searching with a
                  different name.
                </ParagraphSmallBold>
              </EmptySearch>
            ) : (
              <UsersList id='scrollableDiv'>
                <CustomerList
                  setSelectedCustomersHandler={setSelectedCustomersHandler}
                  setSelectedUsersHandler={setSelectedUsersHandler}
                  customerList={filteredUsers}
                  customerState={customerState}
                />
                {loadedCustomers !== data?.pages[0]?.customersCount && (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Button
                      variant='secondary'
                      text='Load More'
                      size='small'
                      onClick={() => fetchNextPage()}
                      disabled={
                        loadedCustomers === data?.pages[0]?.customersCount
                      }
                      style={{ margin: '8px 0 0 0' }}
                    />
                  </div>
                )}
              </UsersList>
            )}
          </>
        )}
      </>
    </Content>
  );
};
