import React from 'react';
import styled from 'styled-components/macro';
import { useField } from 'formik';
import { Label } from './Label';
import Select, { components } from 'react-select';
import { defaultStyle, fontSmallStyle } from '../constants/style';

const SelectInput = styled(Select)`
  width: 100%;
  &:focus {
    outline: 0;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
`;

interface FormSelectProps {
  label: string;
  name: string;
  values: any[];
  mapValues?: boolean;
  hasIcons?: boolean;
  info?: string[];
  width?: string;
  placeholder?: string;
  showLabel?: boolean;
  forFonts?: boolean;
  menuPlacement?: string;
  onChange?: (value: string) => void;
  defaultValue?: any;
  isAutomotive?: boolean;
}

export const FormSelect = ({
  values,
  name,
  label,
  width = '100%',
  placeholder = '',
  showLabel = true,
  menuPlacement = 'bottom',
  forFonts = false,
  onChange,
  mapValues = true,
  hasIcons,
  defaultValue,
  isAutomotive,
}: FormSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const options: any[] = mapValues
    ? values.map(val => ({
        value: val,
        label: val,
      }))
    : values;

  const { Option, SingleValue } = components;

  const IconOption = (props: any) => (
    <Option {...props}>
      {hasIcons ? (
        <OptionContainer>
          <img src={props.data.icon} alt="icon" />
          {props.data.value}
        </OptionContainer>
      ) : (
        <>{props.data.value}</>
      )}
    </Option>
  );

  const CustomSingleValue = (props: any) => (
    <SingleValue {...props}>
      {hasIcons ? (
        <OptionContainer>
          <img src={props.data.icon} alt="icon" />
          {props.data.value}
        </OptionContainer>
      ) : (
        <>{props.data.value}</>
      )}
    </SingleValue>
  );

  return (
    <div style={{ width: width }}>
      {showLabel && (
        <Label isAutomotive={isAutomotive} htmlFor={name}>
          <div>{label}</div>
        </Label>
      )}
      <SelectInput
        styles={forFonts ? fontSmallStyle : defaultStyle}
        placeholder={placeholder}
        options={options}
        value={options.find((o: any) => o.value == value)}
        onChange={(option: any) => {
          setValue(option.value);
          onChange && onChange(option.value);
        }}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuPlacement={menuPlacement}
        components={{ Option: IconOption, SingleValue: CustomSingleValue }}
        defaultValue={defaultValue || ''}
      />
    </div>
  );
};
