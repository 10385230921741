import React from 'react';

export const VisibilityOverlay = () => {
  return (
    <svg
      width='944'
      height='48'
      viewBox='0 0 944 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='944'
        height='48'
        transform='matrix(1 0 0 -1 0 48)'
        fill='url(#paint0_linear_16455_290768)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_16455_290768'
          x1='472'
          y1='0'
          x2='472'
          y2='48'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FAFAFC' />
          <stop offset='1' stopColor='#FAFAFC' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
