import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal } from 'lib/components';
import { CarServiceItem } from 'lib/api/carService/types';
import { Button } from 'react-covideo-common';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: ${theme.palette.black_1_100};
    margin: 0;
  }
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.coal};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.palette.black};
    b {
      font-weight: bold;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 8px;
`;

type Props = {
  count: number;
  archive: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  handleModalClose: () => void;
  servicesDictionary: { [key: string]: CarServiceItem };
  selectedServices: string[];
};

export const ModalArchive = ({
  archive,
  count,
  onClickSecondaryButton,
  onClickPrimaryButton,
  handleModalClose,
  servicesDictionary,
  selectedServices,
}: Props) => {
  const title = `${archive ? 'Archive' : 'Restore'} ${
    count > 1 ? count : ''
  } service${count > 1 ? 's' : ''}?`;
  const text = `Are you sure you want to ${archive ? 'archive ' : 'restore '}`;
  let truncatedString = '';
  if (servicesDictionary && servicesDictionary[selectedServices[0]]) {
    truncatedString =
      servicesDictionary[selectedServices[0]].title &&
      servicesDictionary[selectedServices[0]].title.length > 25
        ? `${servicesDictionary[selectedServices[0]].title.substring(0, 25)}...`
        : servicesDictionary[selectedServices[0]].title;
  }
  const boldPart =
    count === 1
      ? `'${truncatedString}'?`
      : `${count} selected service${count > 1 ? 's' : ''}?`;
  const primaryButtonText = archive
    ? `Yes, Archive${count > 1 ? ' ' + count + ' Services' : ''}`
    : `Yes, Restore${count > 1 ? ' ' + count + ' Services' : ''}`;
  const secondaryButtonText = archive ? 'No, keep' : 'No, keep archived';
  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          <h2>{title}</h2>
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <p>
            {text} <b>{boldPart}</b>
          </p>
        </Content>
        <ButtonsWrapper>
          <Button
            variant='secondary'
            data-cy='service-secondary-button'
            text={secondaryButtonText}
            onClick={onClickSecondaryButton}
          />
          <Button
            variant='red'
            data-cy='service-primary-button'
            text={primaryButtonText}
            onClick={onClickPrimaryButton}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
