import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';

import { useQuery } from 'react-query';
import { GetCtaSetParams, GetCtaSetResponse } from '../types';
import { EXIT_LINKS_QUERY } from 'lib/const/SendAndShare';
import { EXPRESS_API } from 'configs/api/expressApi';

const getExitLinksSets = async (
  params?: GetCtaSetParams
): Promise<GetCtaSetResponse> => {
  const { search = '', start = 0, limit = 0 } = params || {};
  const response = await EXPRESS_API.get('/exitlinksets', {
    params: {
      search,
      start,
      limit,
    },
  });
  return response.data;
};

export const useGetLinkSetsQuery = (
  params?: GetCtaSetParams,
  enabled?: boolean
) => {
  const { userData } = useAuth();
  const isCTASEnabled = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
  return useQuery([EXIT_LINKS_QUERY], () => getExitLinksSets(params), {
    enabled: (isCTASEnabled && enabled) || true,
    refetchOnMount: true,
  });
};
