import React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';
import { Modal, LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import { fetchCustomerUsers, reassignSmsChat, fetchCustomer } from 'lib/api';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  margin-top: 24px;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const Note = styled.textarea`
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const OptinSelect = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
`;

type Props = {
  handleModalClose: (chatId?: any) => void;
  title?: string;
  buttonLabel?: string;
  selectLabel?: string;
  messageObj?: any;
};

export const ReassignChatModal = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [note, setNote] = React.useState('');
  const [users, setUsers] = React.useState<any>([]);
  const [selectedUser, setSelectedUser] = React.useState('');
  const [defaultUserId, setDefaultUserId] = React.useState('');
  const [userName, setUserName] = React.useState('');

  const { handleModalClose, title, buttonLabel, selectLabel, messageObj } =
    props;
  const {
    userData: { customerId },
  } = useAuth();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  const handleReassign = async () => {
    try {
      setLoading(true);
      const response = await reassignSmsChat(
        {
          userId: selectedUser,
          note,
        },
        messageObj.chatId
      );
      successToast({
        title:
          response.message ||
          `Chat successfully assigned to ${userName || 'selected one'}!`,
      });
      handleModalClose(messageObj.chatId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const loadCustomer = async () => {
    const customer = await fetchCustomer(customerId);
    if (customer && customer.defaultSMSUserId) {
      setDefaultUserId(customer.defaultSMSUserId);
    }
  };

  React.useEffect(() => {
    fetchCustomerUsers(customerId).then((data: any) => {
      setUsers(data);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    loadCustomer();
  }, []);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>{title || 'Reassign Chat'}</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {!loading ? (
            <>
              {' '}
              {selectLabel && (
                <Row>
                  <Label>{selectLabel}</Label>
                </Row>
              )}
              <Row>
                <Select
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      height: '40px',
                      width: '100%',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    container: () => ({ width: '100%' }),
                    singleValue: (base: any, state: any) => ({
                      ...base,
                      width: state.selectProps.menuIsOpen ? '2%' : '98%',
                      borderBottom: 'none',
                    }),
                    menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
                    option: (base: any) => ({
                      ...base,
                      borderBottom: '1px solid #EEEFF2',
                    }),
                  }}
                  options={users.filter((e: any) => e.id != messageObj.userId)}
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                  menuPlacement={'bottom'}
                  formatOptionLabel={option => (
                    <OptinSelect>
                      <div>{`${option.firstName} ${option.lastName}`}</div>
                      {defaultUserId == option.id && (
                        <div style={{ color: '#001B5366' }}>Default user</div>
                      )}
                    </OptinSelect>
                  )}
                  getOptionValue={option => option.id}
                  getOptionLabel={option =>
                    `${option.firstName} ${option.lastName}`
                  }
                  value={users.find((o: any) => {
                    return o.id == selectedUser;
                  })}
                  onChange={(option: any) => {
                    setSelectedUser(option.id);
                    setUserName(`${option.firstName} ${option.lastName}`);
                  }}
                  placeholder='Select User'
                />
              </Row>
              <Row>
                <InputWrapper>
                  <Label>Add a note for {userName || 'User'}</Label>
                  <Note onChange={(e: any) => setNote(e.target.value)} />
                </InputWrapper>
              </Row>{' '}
            </>
          ) : (
            <LoadingIndicator isLoading={loading} height='300px' />
          )}
        </Content>
        <Footer>
          <Button
            text={buttonLabel || 'Reassign'}
            onClick={handleReassign}
            disabled={loading || !selectedUser}
          />
        </Footer>
      </div>
    </Modal>
  );
};
