import { useGetMeetingStatusList } from 'lib/api/meetings/useGetMeetingStatusList';
import {
  MeetingResponse,
  MEETING_STATUSES,
  MeetingStatus,
} from 'lib/api/meetings/types';
import { LoadingIndicator, Search, Tabs } from 'lib/components';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { useAuth } from 'lib/context';
import placeholder from 'lib/images/tablePlaceholderBig.jpg';
import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { MeetingDetails } from './MeetingDetails';
import { Tab } from 'lib/components/tabs';
import { MeetingListItem } from '../components/MeetingListItem';
import { MeetingListActions } from '../components/meetingListActions/MeetingListActions';
import { CancelMeetingModal } from '../components/modals/CancelMeetingModal';
import { useStartNowMeetingMutation } from 'lib/api/meetings/useStartNowMeetingMutation';
import { DeleteMeetingModal } from '../components/modals/DeleteMeetingModal';
import { useSendInviteMeetingMutation } from 'lib/api/meetings/useSendInviteMeetingMutation';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const SearchBar = styled.div`
  margin-right: 12px;
  min-width: 400px;
`;

const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: ${theme.palette.gray100};
  padding-top: 16px;
`;

const NoLivesLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;
const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;

const MainDiv = styled.div`
  margin-top: 40px;
`;

const TabsContainer = styled.div`
  width: 392px;
`;

export enum TAB_IDS {
  SCHEDULED = 0,
  ENDED = 1,
  CANCELED = 2,
  RECURRING = 3,
}

const tabs: Tab[] = [
  {
    id: TAB_IDS.SCHEDULED,
    title: 'Scheduled',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
  {
    id: TAB_IDS.ENDED,
    title: 'Ended',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
  {
    id: TAB_IDS.CANCELED,
    title: 'Canceled',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
  {
    id: TAB_IDS.RECURRING,
    title: 'Recurring',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
];

const getTabStatus = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return MEETING_STATUSES.SCHEDULED;
    case 1:
      return MEETING_STATUSES.FINISHED;
    case 2:
      return MEETING_STATUSES.CANCELED;
    case 3:
      return MEETING_STATUSES.RECURRING;
    default:
      return MEETING_STATUSES.SCHEDULED;
  }
};

export const MeetingList = () => {
  const [statusType, setStatusType] = useState<MeetingStatus>(
    MEETING_STATUSES.SCHEDULED
  );
  const {
    data: meetingListStatusData,
    isLoading,
    refetch,
  } = useGetMeetingStatusList(statusType);
  const { userData } = useAuth();
  const [meetingId, setMeetingId] = useState<string | null>(null);
  const [
    createMeetingStatus,
    setCreateMeetingStatus,
  ] = useState<MeetingStatus | null>(null);

  const {
    mutateAsync: sendInvite,
    isLoading: emailSending,
  } = useSendInviteMeetingMutation();
  const { mutateAsync: startNowAsync } = useStartNowMeetingMutation();
  const [meetings, setMeetings] = useState<MeetingResponse[]>([]);
  const [selectedMeeting, setSelectedMeeting] = useState<MeetingResponse>();
  const [meetingDetailsModal, setMeetingDetailsModal] = useState<boolean>(
    false
  );
  const [showModals, setShowModals] = useState<'delete' | 'cancel' | null>(
    null
  );
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState(TAB_IDS.SCHEDULED);

  useEffect(() => {
    const tabStatus = getTabStatus(activeTab);
    setStatusType(tabStatus);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [statusType]);

  useEffect(() => {
    if (meetingListStatusData) {
      setMeetings(meetingListStatusData);
    }
  }, [meetingListStatusData]);

  const onSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    if (!searchTerm) {
      meetingListStatusData && setMeetings(meetingListStatusData);
      return;
    }

    const filteredLives = meetings.filter(live => {
      const { meetingId, attendees, title } = live;
      const emails = attendees
        .map(attendee => attendee.email)
        .filter(Boolean)
        .join('|')
        .toLowerCase();

      return (
        meetingId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        emails?.includes(searchTerm.toLowerCase())
      );
    });

    setMeetings(filteredLives);
  };

  const onStartNowClick = async (meetingId: string | null) => {
    startNowAsync({
      meetingId,
      userId: Number(userData.id),
    });
  };

  const onSendInvite = async (meetingId: string) => {
    if (meetingId) {
      await sendInvite(meetingId);
    }
  };

  const onMeetingCancel = (meeting: MeetingResponse) => {
    setSelectedMeeting(meeting);
    setShowModals('cancel');
  };

  const onMeetingDelete = (meeting: MeetingResponse) => {
    setSelectedMeeting(meeting);
    setShowModals('delete');
  };

  const handleCloseFullScreenModal = () => {
    setMeetingDetailsModal(false);
    setMeetingId(null);
    refetch();
  };

  const handleOpenLiveDetailsModal = (
    meetingId: string | null,
    meetingStatus: MeetingStatus | null
  ) => {
    setMeetingId(meetingId);
    setCreateMeetingStatus(meetingStatus);
    setMeetingDetailsModal(true);
  };

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  if (meetingDetailsModal) {
    return (
      <MeetingDetails
        handleCloseFullScreenModal={handleCloseFullScreenModal}
        meetingId={meetingId}
        createMeetingStatus={createMeetingStatus}
        userId={userData?.id}
      />
    );
  }

  return (
    <Container>
      <MainWrapper resetPadding>
        <HeaderTitle>Meetings</HeaderTitle>
        <TabsContainer>
          <Tabs
            tabs={tabs}
            defaultActiveTab={activeTab}
            onChange={setActiveTab}
            navbarPadding='16px 0px 0px 0px'
          />
        </TabsContainer>
        <HeaderContainer>
          <SearchBar>
            <Search
              key={activeTab}
              placeholder='Search by name, ID, or participants…'
              onSearch={onSearch}
            />
          </SearchBar>
          <MeetingListActions
            handleOpenLiveDetailsModal={handleOpenLiveDetailsModal}
            activeTab={activeTab}
          />
        </HeaderContainer>
        {meetings.length !== 0 && (
          <MainDiv>
            {meetings.map(element => (
              <MeetingListItem
                data={element}
                key={element.meetingId}
                startNow={onStartNowClick}
                edit={() =>
                  handleOpenLiveDetailsModal(
                    element.meetingId,
                    element.meetingStatus
                  )
                }
                sendInvite={onSendInvite}
                emailSending={emailSending}
                onCancel={onMeetingCancel}
                onDelete={onMeetingDelete}
                activeTab={activeTab}
              />
            ))}
          </MainDiv>
        )}
        {meetings.length === 0 && (
          <NoLivesLayout>
            <Text>
              {!search
                ? `No ${statusType} meetings`
                : 'No matching results found'}
            </Text>
          </NoLivesLayout>
        )}
        {showModals === 'cancel' && selectedMeeting?.meetingId && (
          <CancelMeetingModal
            attendees={selectedMeeting?.attendees || []}
            meetingId={selectedMeeting.meetingId}
            handleModalClose={() => setShowModals(null)}
            handleCloseAllModals={() => setShowModals(null)}
          />
        )}

        {showModals === 'delete' && selectedMeeting?.meetingId && (
          <DeleteMeetingModal
            meetingId={selectedMeeting.meetingId}
            handleModalClose={() => setShowModals(null)}
            handleCloseAllModals={() => setShowModals(null)}
          />
        )}
      </MainWrapper>
    </Container>
  );
};
