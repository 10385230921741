import styled from 'styled-components/macro';
import { ReactComponent as CovideoSquareSvg } from './covideo-with-square-background.svg';

const CovideoWithSquareBackground = styled(CovideoSquareSvg)`
  width: ${props => props.width || '64px'};
  height: ${props => props.height || '64px'};
  .icon path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default CovideoWithSquareBackground;
