import React from 'react';
import {
  IChatMeetingResponse,
  useMeetingsChatQuery,
} from 'lib/api/meetings/useMeetingsChatQuery';
import { LoadingIndicator } from 'lib/components';
import { Heading, ParagraphExtraSmall } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { calculateDiff } from 'lib/utils/time';
import styled from 'styled-components/macro';
import noChatMessages from '../assets/no-messages.png';
import { addLinksToText } from '../utils';
import { Gap } from 'lib/components/styles/layout';
interface IProps {
  meetingId: string | null;
}

const Body = styled.div`
  overflow-y: auto;
  width: 592px;
  padding: 12px 24px 24px 24px;
  flex-direction: column;
  background: #f7f9fa;
  border: 1px solid rgba(0, 27, 83, 0.05);
  display: flex;
  gap: 8px;
  border-radius: 8px;
  height: calc(100vh - 168px);
  margin: 0 auto;
`;

const MessageWrapper = styled.div<{ remote?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ remote }) => (remote ? 'flex-start' : 'flex-end')};
`;

const Message = styled.div<{ remote?: boolean }>`
  word-break: break-word;
  flex: 1;
  background: ${({ remote }) =>
    remote ? theme.palette.white : theme.palette.covideoBlue100};
  color: ${({ remote }) =>
    remote ? theme.palette.covideoBlue100 : theme.palette.white};
  padding: 12px 16px;
  border-radius: ${({ remote }) =>
    remote ? '8px 8px 8px 0px' : '8px 8px 0px 8px'};
`;

export const MeetingTabChat = ({ meetingId }: IProps) => {
  const { data, isLoading } = useMeetingsChatQuery(meetingId || '');

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (data?.length === 0) {
    return (
      <div style={{ marginTop: 126 }}>
        <img src={noChatMessages} alt='no messages'></img>
        <Heading color={theme.palette.covideoBlue100} textAlign='center'>
          No chat messages
        </Heading>
      </div>
    );
  }

  return (
    <Body>
      {data?.map((message: IChatMeetingResponse, i: number) => {
        const isRemote = message.userId === null;
        const showSender =
          !data[i + 1] || message.connectionId !== data[i + 1].connectionId;

        return (
          <MessageWrapper key={i} remote={isRemote}>
            <Message
              dangerouslySetInnerHTML={{
                __html: addLinksToText(message.text),
              }}
              remote={isRemote}
            />
            {showSender && (
              <Gap
                alignItems='center'
                gap='4px'
                justifyContent={isRemote ? 'flex-start' : 'flex-end'}
              >
                <ParagraphExtraSmall>
                  {!isRemote ? 'You' : message.nickname}
                </ParagraphExtraSmall>
                <ParagraphExtraSmall>·</ParagraphExtraSmall>
                <ParagraphExtraSmall>
                  {calculateDiff(message.createdAt)}
                </ParagraphExtraSmall>
              </Gap>
            )}
          </MessageWrapper>
        );
      })}
    </Body>
  );
};
