import styled from 'styled-components/macro';
import { ReactComponent as InfoQuestionMarkSvg } from './info-question-mark.svg';

const InfoQuestionMarkIcon = styled(InfoQuestionMarkSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default InfoQuestionMarkIcon;
