import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { usersKeys } from './queryKeys';

export interface ManagedUsersParams {
  customerId: string;
  search?: string;
  sort?: string;
  page?: number;
  size?: number;
  userActiveStatus?: string;
  departmentId?: string | number;
}
export interface IManagedUsersResponse {
  userList: UserList[];
  totalCount: number;
  totalAllowedUsers: number;
  totalActiveUsers: number;
}

export interface UserList {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone1: string;
  verified: number;
  loginDate: string;
  isPending: number;
  transcriptionAccess: number;
  mobileSMSAccess: number;
  droplrAccess: number;
  access: number;
  mlAutoCreated: number;
  inviteSent: number;
  automotiveRole: number | null;
  signupDate: string;
  departmentName: null | string;
}

export const getManagedUsers = async ({
  customerId,
  search = '',
  page = 0,
  size = 10,
  userActiveStatus = 'ALL',
  sort = '',
  departmentId = 0,
}: ManagedUsersParams): Promise<IManagedUsersResponse> => {
  const params = {
    start: page * size,
    limit: size,
    departmentId,
    userActiveStatus,
    ...(search === '' ? {} : { search }),
    ...(sort === '' ? {} : { sort }),
  };

  const response = await EXPRESS_API.get(`/users/${customerId}/managed-users`, {
    params,
  });
  return response.data;
};

export const useManagedUsersQuery = (
  params: ManagedUsersParams,
  enabled?: boolean
) => {
  const { showError } = useToastError();

  return useQuery(
    usersKeys.managed_users(params),
    () => getManagedUsers(params),
    {
      onError: showError,
      enabled: !!params.customerId || enabled,
    }
  );
};
