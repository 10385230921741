import styled from 'styled-components/macro';

export const InsightsWrapper = styled.div<{ loading: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  text-align: center;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
`;
