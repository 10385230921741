import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Link, NavLink } from 'react-router-dom';
import BackArrowSvg from 'lib/images/back-arrow.svg';
import CodirectIcon from 'lib/images/CodirectIcon';
import { useAuth } from '../context';
import { useLandingPageBuilderContext } from '../../app/pages/design/landingPageBuilder/context';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { isAdminSession } from 'lib/utils/auth';
import { InviteFriendsModal } from './modal/ModalInviteFriends';
import { Button } from 'react-covideo-common';
import { IoMdPeople } from 'react-icons/io';
import { checkIfFreemium } from 'lib/utils/functions';
import { PackageNameLabel, productFeature } from 'lib/utils/productFeature';
import LockIcon from 'lib/images/LockIcon';
import {
  MdDeleteForever,
  MdCreditCard,
  MdExtension,
  MdHelp,
  MdSubtitles,
  MdEmail,
  MdAutoDelete,
  MdScheduleSend,
  MdWork,
  MdDirectionsRun,
} from 'react-icons/md';
import { BiWorld } from 'react-icons/bi';
import { FaStar } from 'react-icons/fa';
import ShowServicesIcon from 'lib/images/ShowServicesIcon';
import ShowSnippetsIcon from 'lib/images/ShowSnippetsIcon';
import { CommonTypography } from './styles/typography';
import AccountIcon from 'lib/images/AccountIcon';
import AccountsIcon from 'lib/images/AccountsIcon';
import LabelIcon from 'lib/images/LabelIcon';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

type ContainerProps = {
  padding?: number;
  overflow?: string;
};

const Container = styled.nav<ContainerProps>`
  z-index: 6;
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  height: 89vh;
  position: fixed;
  left: 0;
  top: 0;
  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    color: rgba(255, 255, 255, 0.5);
  }
  padding-top: ${props => props.padding || 100}px;
  min-width: 225px;
  background-color: ${theme.palette.covideoBlue02NonAlpha};
  ${theme.mediaQueryMinWidth.mb} {
    display: flex;
  }
  overflow: ${props => props.overflow || 'visible'};
`;

type NavItemProps = {
  isDisabled?: boolean;
};

const NavItem = styled(NavLink)<NavItemProps>`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  display: flex;
  padding: 10px 16px 10px 28px;
  justify-content: flex-start;
  min-height: 20px;
  height: 40px;
  align-items: center;
  text-align: center; /* to center icons */
  opacity: 1;
  transition: all 0.2s;
  ${theme.fontNormal500};
  font-size: ${theme.fontSizes.md};
  color: ${theme.palette.primaryDarkBlue75Percent};
  text-decoration: none;

  ${props =>
    !props.isDisabled &&
    `
    &:hover {
      color: ${theme.palette.primaryDarkBlue};
      background-color: ${theme.palette.white};
    }
  `}

  &.active {
    background-color: ${theme.palette.white};
    color: ${theme.palette.primaryDarkBlue};
    border-left: 2px solid ${theme.palette.primaryDarkBlue};
    svg {
      color: ${theme.palette.primaryDarkBlue};
      fill: ${theme.palette.primaryDarkBlue};
    }
    g > path {
      fill: ${theme.palette.primaryDarkBlue};
    }
  }
`;

const Icon = styled.span<{ color?: string }>`
  margin: 0;
  font-size: ${theme.iconSizeS};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${props => (props.color ? props.color : 'rgba(0, 27, 83, 0.4)')};
  min-width: 24px;
`;

const IconWrap = styled.div`
  position: relative;
`;

const Title = styled.span<{ hasNotifications?: any; color?: string }>`
  ${theme.fontNormal600}
  // margin-left: 14px;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0;
  color: ${props =>
    props.color ? props.color : theme.palette.primaryDarkBlue};
  position: relative;

  ${({ hasNotifications, theme }) =>
    hasNotifications &&
    css`
      :after {
        content: '';
        width: 6px;
        height: 6px;
        background: ${theme.colors.primary[100]};
        position: absolute;
        border-radius: 6px;
        left: -18px;
        top: 2px;
      }
    `}

  ${theme.mediaQueryMinWidth.lg} {
    display: block;
  }
`;

const BackButton = styled(Link)`
  border: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  padding: 10px 16px 10px 32px;
  color: ${theme.palette.primaryDarkBlue};
  font-weight: 500;
  min-width: 160px;
  text-decoration: underline;
  &:hover {
    color: ${theme.palette.primaryDarkBlue};
    background-color: ${theme.palette.white};
  }
  ${theme.mediaQueryMinWidth.mb} {
    aside {
      display: none;
    }
  }
  ${theme.mediaQueryMinWidth.sm} {
    width: 100px;
    aside {
      display: block;
    }
    img {
      margin: 0 16px 0 0;
    }
  }
`;

const AdditionalIcon = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.palette.secondaryGrey};
`;

const AdditionalIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: #e6e6e6;
  border-radius: 6px;
  position: absolute;
  right: -10px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Badge = styled.span`
  font-size: 10px;
  padding: 2px 4px;
  margin-left: 4px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
`;

const Notification = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001b53;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  width: 24px;
  height: 24px;
`;

const CodirectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const CodirectBtn = styled.div`
  background: #ffffff;
  border: 1px solid #eeeff2;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  width: 176px;
  height: 48px;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
`;

const NewIcon = styled.div`
  background: #e44a3a;
  border-radius: 3px;
  width: 28px;
  height: 14px;
  font-size: 12px;
  color: white;
  padding: 4px;
  text-align: center;
  font-weight: 700;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipMain = styled.div`
  width: 100%;
`;

const EnabledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
`;

const EnabledDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DisabledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
`;

const DisabledDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px 0px 28px;
  gap: 12px;
`;

const Label = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.covideoBlue40};
`;

const Line = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.blue05};
`;

const ItemsWrapper = styled.div<{ paddingBottom?: string }>`
  padding-bottom: ${props => props.paddingBottom || '0px'};
  text-align: center;
`;

export interface SidebarItem {
  productFeatureId?: number;
  title: string;
  path?: string;
  icon?: any;
  exact?: boolean;
  additionalIcon?: any;
  badge?: string;
  notificationCount?: string;
  isDisabled?: boolean;
  isPaid?: boolean;
  paidUrl?: string;
  isLabel?: boolean;
  iconColor?: string;
  color?: string;
}

interface Props {
  hasBack?: boolean;
  items: SidebarItem[];
  removeBackButton?: boolean;
  showCodirect?: boolean;
}

const iconSize = '24px';
const additionalIconSize = '16px';

export const profile = {
  title: 'My Profile',
  icon: <AccountIcon width={iconSize} height={iconSize} />,
  path: '/profile/Profile_EditUserInfo',
  isDisabled: false,
};
export const company = {
  title: 'Company Profile',
  icon: <MdWork size={iconSize} />,
  path: '/profile/Profile_EditCompanyInfo',
  isDisabled: false,
};
export const manageUsers = {
  title: 'Manage Users',
  icon: <AccountsIcon width={iconSize} height={iconSize} />,
  path: '/users/list',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  isDisabled: false,
};
export const billing = {
  title: 'Billing',
  icon: <MdCreditCard size={iconSize} />,
  // path: `/profile/Profile_EditCreditCard`,
  path: '/billing',
  isDisabled: false,
};
export const addons = {
  title: 'Add-ons',
  icon: <MdExtension size={iconSize} />,
  path: '/profile/add-ons',
  isDisabled: false,
};
export const videos = {
  title: 'Videos',
  isLabel: true,
};
export const teleprompterItem = {
  title: 'Teleprompter',
  icon: <MdSubtitles size={iconSize} />,
  path: '/profile/teleprompter',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  productFeatureId: productFeature.TELEPROMPTER,
};
export const videoAttributes = {
  title: 'Video Attributes',
  icon: <LabelIcon width={iconSize} height={iconSize} />,
  path: '/profile/Profile_VideoAttributes',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  productFeatureId: productFeature.VIDEO_ATTRIBUTES,
};
export const videoExpiration = {
  title: 'Video Expiration',
  icon: <MdAutoDelete size={iconSize} />,
  path: '/profile/Profile_VideoDeletion',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  productFeatureId: productFeature.VIDEO_EXPIRATION,
};
export const emails = {
  title: 'Emails',
  isLabel: true,
};
export const scheduleItem = {
  title: 'Schedules',
  icon: <MdScheduleSend size={iconSize} />,
  path: '/profile/schedules',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  productFeatureId: productFeature.SCHEDULES,
};
export const snippetsItem = {
  title: 'Templates',
  icon: <ShowSnippetsIcon height={'17px'} />,
  path: '/profile/templates',
  additionalIcon: (
    <LockIcon
      width={additionalIconSize}
      height={additionalIconSize}
      color={'#001B53'}
    />
  ),
  productFeatureId: productFeature.TEMPLATES,
};
export const emailBatches = {
  title: 'Email Batches',
  icon: <MdEmail size={iconSize} />,
  path: '/profile/Email_Batches',
  isDisabled: false,
};
export const servicesItem = {
  title: 'Services',
  icon: <ShowServicesIcon height={'17px'} />,
  path: '/profile/services',
  isDisabled: false,
};
export const others = {
  title: 'Others',
  isLabel: true,
};
export const socialProfile = {
  title: 'Social Profiles',
  icon: <BiWorld size={iconSize} />,
  path: '/profile/social-profiles',
};
export const help = {
  title: 'Help Center',
  icon: <MdHelp size={iconSize} />,
  path: '/Help',
  isDisabled: false,
};
export const affiliate = {
  title: 'Affiliate',
  icon: <FaStar size={iconSize} />,
  path: '/profile/Profile_AffiliateApply',
  isDisabled: false,
};
export const recentlyDeleted = {
  title: 'Recently Deleted',
  icon: <MdDeleteForever size={iconSize} />,
  path: '/profile/recently-deleted',
  isDisabled: false,
};
export const logout = {
  title: 'Log Out',
  icon: <MdDirectionsRun size={iconSize} />,
  path: '/Sign_Out',
  isDisabled: false,
  iconColor: theme.palette.red40,
  color: theme.palette.red100,
};

//BACK ICON
const BackIcon = ({ hasBack }: Pick<Props, 'hasBack'>) => {
  if (hasBack) {
    <IconWrap>
      <BackButton to={'/'}>
        <img src={BackArrowSvg} height={16} width={16} alt={'Go Back Arrow'} />
        <aside>Back</aside>
      </BackButton>
    </IconWrap>;
  }
  return null;
};
//DISABLED ITEM
const DisabledItem = ({ item }: { item: SidebarItem }) => {
  const {
    title,
    icon,
    additionalIcon,
    badge,
    notificationCount,
    isDisabled,
    isPaid,
    paidUrl,
  } = item;
  const nextPlan = [
    'Video Attributes',
    'Files',
    'Boards',
    'Captions',
    'Security',
    'Schedules',
    'Templates',
    'Appearance',
    'Annotations',
    'Overlay URLs',
    'QR Codes',
    'Video Expiration',
    'Lives',
  ].includes(title)
    ? PackageNameLabel.TEAMS
    : PackageNameLabel.PRO;

  return (
    <TooltipMain>
      <ProductInfoTooltip
        nextPlan={nextPlan}
        isPaid={isPaid}
        paidUrl={paidUrl}
        productFeatureId={item.productFeatureId}
        style={{
          left: 'calc(100% + 150px)',
        }}
      >
        <DisabledItemWrapper>
          <Icon>{icon}</Icon>
          <DisabledDataWrapper>
            <Title>
              {title}
              {!!badge && <Badge>{badge}</Badge>}
              {!!notificationCount && (
                <Notification>{notificationCount}</Notification>
              )}
            </Title>
            {(!!additionalIcon || isDisabled) && (
              <AdditionalIconWrapper>
                <AdditionalIcon>{additionalIcon}</AdditionalIcon>
              </AdditionalIconWrapper>
            )}
          </DisabledDataWrapper>
        </DisabledItemWrapper>
      </ProductInfoTooltip>
    </TooltipMain>
  );
};

//ENABLED ITEM
const EnabledItem = ({ item }: { item: SidebarItem }) => {
  const { title, icon, badge, notificationCount } = item;
  const hasNotificationsCount = !!notificationCount;
  const shouldShowNotification =
    (title === 'Guides' && parseInt(notificationCount ?? '0') > 0) ||
    (title !== 'Guides' && hasNotificationsCount);

  return (
    <EnabledItemWrapper>
      <Icon color={item.iconColor}>{icon}</Icon>
      <EnabledDataWrapper>
        <Title color={item.color}>
          {title}
          {!!badge && <Badge>{badge}</Badge>}
        </Title>
        {shouldShowNotification && (
          <Notification>{notificationCount}</Notification>
        )}
      </EnabledDataWrapper>
    </EnabledItemWrapper>
  );
};

//SIDEBAR NAVIGATION
export const SidebarNavigation = ({
  items,
  hasBack = true,
  showCodirect = false,
}: Props) => {
  const { userData, whitelabel } = useAuth();
  const { trialExpired } = userData;
  const { isEnabled } = useLandingPageBuilderContext();
  const [inviteFriends, setInviteFriends] = React.useState(false);

  const history = useHistory();

  if (isEnabled) {
    return <></>;
  }

  const onNavItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: SidebarItem
  ) => {
    const { isDisabled, title } = item;
    if (isDisabled || trialExpired || title === help.title) {
      e.preventDefault();
    }

    if (title === help.title) {
      window
        .open(whitelabel?.supportURL || 'https://support.covideo.com', '_blank')
        ?.focus();
    }
  };

  const isItemVisible = (item: SidebarItem) => {
    const { title } = item;
    if (title === help.title && !whitelabel.supportURL) {
      return true;
    }
    if (
      title === billing.title &&
      whitelabel.name !== WHITELABEL_NAME.COVIDEO
    ) {
      return true;
    }
    if (title === socialProfile.title) {
      return true;
    }
    return false;
  };

  const setModal = (isOpen: boolean) => {
    setInviteFriends(isOpen);
  };

  const isLegacyPackage =
    parseInt(userData.customer.packageId.toString(), 10) === PackageName.LEGACY;
  return (
    <Container
      padding={isAdminSession() || userData.trialAccount ? 136 : 96}
      overflow={isLegacyPackage ? 'auto' : 'visible'}
    >
      <BackIcon hasBack={hasBack} />
      <ItemsWrapper paddingBottom={isLegacyPackage ? '65px' : '0px'}>
        {items.map((item: SidebarItem, index) => {
          const { path, exact, isDisabled } = item;

          const isVisible = isItemVisible(item);
          if (isVisible) {
            return null;
          }

          if (!!item.isLabel) {
            return (
              <LabelContainer key={index}>
                <Label>{item.title}</Label>
                <Line />
              </LabelContainer>
            );
          }
          return (
            <IconWrap key={index}>
              <NavItem
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  onNavItemClick(e, item);
                }}
                className={isDisabled || trialExpired ? 'disabled' : ''}
                key={index}
                to={
                  isDisabled || trialExpired || !path ? index.toString() : path
                }
                exact={exact}
                isDisabled={isDisabled || trialExpired}
              >
                {isDisabled ? (
                  <DisabledItem item={item} />
                ) : (
                  <EnabledItem item={item} />
                )}
              </NavItem>
            </IconWrap>
          );
        })}
        {showCodirect && (
          <CodirectWrapper>
            <CodirectBtn onClick={() => history.push('/codirect')}>
              <CodirectIcon width='90px' height='40px' />
              <NewIcon>NEW</NewIcon>
            </CodirectBtn>
          </CodirectWrapper>
        )}
        {checkIfFreemium(userData) && (
          <>
            {inviteFriends && (
              <InviteFriendsModal
                handleModalClose={() => setModal(false)}
                onDismissHandler={() => null}
                onMaybeLaterHandler={() => null}
              />
            )}
            <Button
              style={{
                margin: '56px auto 0 auto',
              }}
              text='Invite Friends'
              variant='white'
              icon={
                <IoMdPeople color={theme.palette.covideoBlue100} size={20} />
              }
              onClick={() => {
                setModal(true);
              }}
            />
          </>
        )}
      </ItemsWrapper>
    </Container>
  );
};
