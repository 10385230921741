import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { CloseButton, Modal } from 'lib/components';
import VideoUpload, { View } from '../videoUpload/VideoUpload';

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 592px;
  height: 580px;
  overflow: hidden;
  background-color: #fff;
`;

export const ModalUpload = withRouter((props: any) => {
  const { disabled = false, handleModalClose } = props;
  const [canceling, setCanceling] = useState(false);
  const [progress, setProgress] = useState(View.FORM);

  const handleClose = () => {
    if (progress === View.FORM) {
      return handleModalClose();
    }
    setCanceling(true);
  };

  return (
    <Modal>
      <ModalItem>
        <CloseButton disabled={disabled} zIndex={true} onClick={handleClose} />
        <VideoUpload
          handleModalClose={handleModalClose}
          progress={progress}
          setProgress={setProgress}
          canceling={canceling}
          contentStyle={{
            body: {
              maxHeight: '440px',
              minHeight: '440px',
            },
          }}
          setCanceling={setCanceling}
        />
      </ModalItem>
    </Modal>
  );
});
