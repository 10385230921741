export enum PROMPT_TYPE {
  TELEPROPTER_SCRIPT = 'Teleprompter Script',
  EMAIL = 'Email',
  SMS = 'SMS',
  CRM_MESSAGE = 'CRM Message',
}

export enum PROMPT_USE_CASE {
  INITIAL_REACH_OUT = 'Initial Reach Out',
  INITIAL_INQUIRY_REPLY = 'Initial Inquiry Reply',
  VEHICLE_WALKAROUND = 'Vehicle Walkaround',
  TEST_DRIVE_INVITATION = 'Test Drive Invitation',
  APPOINTMENT_REMINDER = 'Appointment Reminder',
  MISSED_APPOINTMENT = 'Missed Appointment',
  UNSOLD_FOLLOW_UP = 'Unsold Follow Up',
  EVENT_PROMOTION_ALERT = 'Event & Promotion Alert',
  POST_SALE_FOLLOW_UP = 'Post-Sale Follow-Up',
  REVIEW_REFERRAL_REQUEST = 'Review or Referral Request',
  SERVICE_MAINTENANCE_REMINDERS = 'Service and Maintenance Reminders',
  NONE = '',
}
