import { updateCookie } from './cookie';
import { EXPRESS_API } from './expressApi';

export const updateAxiosInstaceToken = (
  newToken: string,
  refreshTokenNew?: string | undefined,
  domain?: string | undefined
) => {
  EXPRESS_API.defaults.headers['Authorization'] = `Bearer ${newToken}`;
  updateCookie(newToken, refreshTokenNew, domain);
};
