import { NoEmailSvg } from 'lib/images';
import React, { useState } from 'react';
import {
  CovideoHeader,
  Description,
  EmailVerificationWrapper,
  HeaderImage,
  Heading,
  Resend,
  TextDetails,
} from './style';
import { useSendEmailVerification } from 'lib/api/emailVerification/sendEmailVerification';
import { LoadingIndicator } from 'lib/components';
import { useIsMutating } from 'react-query';
import { usersKeys } from 'lib/api/users/queryKeys';

export const EmailVerification = () => {
  const [resent, setResent] = useState(false);

  const {
    mutateAsync: sendEmailVerification,
    isLoading: isSendingEmailVerification,
  } = useSendEmailVerification(setResent);

  const isMutatingUser = useIsMutating(usersKeys.user());

  const handleSendEmailVerification = async () => {
    await sendEmailVerification();
  };

  const loading = isMutatingUser > 0;

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }

  return (
    <>
      <CovideoHeader>
        <HeaderImage
          src='https://www.covideo.com/images/covideo-logo-dark.svg'
          alt='dark logo'
        />
      </CovideoHeader>
      <EmailVerificationWrapper>
        <TextDetails>
          <Heading>Check Your Email</Heading>
          <Description>
            Click on the link in email we have sent you to verify your account.
          </Description>
          <Resend
            onClick={handleSendEmailVerification}
            disabled={resent || isSendingEmailVerification}
          >
            Resend Email
          </Resend>
        </TextDetails>
        <NoEmailSvg />
      </EmailVerificationWrapper>
    </>
  );
};
