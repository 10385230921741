import styled from 'styled-components/macro';
import { ReactComponent as CloseSvg } from './Codirect_FinalLogo 1.svg';

const CodirectIcon = styled(CloseSvg)`
  width: ${props => props.width || '16px'};
  height: ${props => props.height || '16px'};
  path {
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default CodirectIcon;
