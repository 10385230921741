import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { useOnClickOutside } from 'lib/utils';
import ProductInfoTooltip from './ProductInfoTooltip/ProductInfoTooltip';
import LockIcon from 'lib/images/LockIcon';

type MenuWrapperProps = {
  isMenuOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  display: ${props => (props.isMenuOpen ? 'block' : 'none')};
  z-index: 10; /** SUS-1289 && SUS-2567 changes **/
  position: absolute;
`;

const ItemIcon = styled.div`
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
`;
type ItemTitleProps = {
  color: string;
};
const ItemTitle = styled.div<ItemTitleProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${props => props.color};
  margin-left: 10px;
`;
const ItemSubtitle = styled.div`
  color: ${theme.palette.blue40};
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
`;

interface Data {
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  onClick?: (id: string) => void;
  titleColor?: string;
  isDisabled?: boolean;
  productFeatureId?: number;
  productTooltipStyle?: React.CSSProperties;
  nextPlan?: string;
  showNonFreemiumTooltip?: boolean;
  tooltipText?: string;
  dataCy?: string;
  showLockIcon?: boolean;
}

type Props = {
  isMenuOpen: boolean;
  items: Array<Data>;
  setIsMenuOpen: (b: any) => void;
  id: string;
  className?: string;
  reference?: any;
  width?: string;
  dataCy?: string;
};

type ItemProps = {
  width?: string;
  isDisabled?: boolean;
};

const Item = styled.div<ItemProps>`
  display: flex;
  height: 40px;
  align-items: center;
  width: ${props => props.width || 'auto'};
  min-width: 200px;
  background: ${theme.palette.white};
  ${props =>
    !props.isDisabled &&
    `&:hover {
    background: ${theme.palette.blue05};
  }`}
  z-index: 100;
  ${props => (props.isDisabled ? `cursor: not-allowed;` : `cursor: pointer;`)}
`;

export const ListDropdownMenu = ({
  isMenuOpen,
  items,
  setIsMenuOpen,
  id,
  className,
  reference,
  width = 'auto',
  dataCy,
}: Props) => {
  const { ref } = useOnClickOutside(() => setIsMenuOpen(false));

  return (
    <MenuWrapper
      ref={reference ? reference : ref}
      isMenuOpen={isMenuOpen}
      {...(className ? { className: className } : {})}
      data-cy={dataCy}
    >
      {items.map((item, i) =>
        item.isDisabled ? (
          <ProductInfoTooltip
            productFeatureId={item.productFeatureId}
            direction='left'
            style={{
              right: '200px',
              ...item.productTooltipStyle,
            }}
            key={item.title}
            nextPlan={item.nextPlan}
            isNonFreemiumTooltip={item.showNonFreemiumTooltip}
            tooltipText={item.tooltipText}
            visibility={!!item.showLockIcon}
          >
            <Item width={width} key={i} isDisabled={item.isDisabled}>
              <ItemIcon style={{ opacity: 0.5 }}>{item.icon}</ItemIcon>
              <ItemTitle
                style={{ marginRight: '10px', opacity: 0.5 }}
                color={item.titleColor ? item.titleColor : theme.palette.coal}
              >
                {item.title}
              </ItemTitle>

              {!item.showNonFreemiumTooltip && !!item.showLockIcon && (
                <LockIcon width={17} height={17} />
              )}
            </Item>
          </ProductInfoTooltip>
        ) : (
          <Item
            width={width}
            key={i}
            data-cy={item?.dataCy}
            onClick={() => {
              item.onClick && item.onClick(id);
              reference ? setIsMenuOpen(0) : setIsMenuOpen(false);
            }}
          >
            <ItemIcon>{item.icon}</ItemIcon>
            <ItemTitle
              color={item.titleColor ? item.titleColor : theme.palette.coal}
            >
              {item.title}
            </ItemTitle>
            {item.subtitle !== '' && (
              <ItemSubtitle>{item.subtitle}</ItemSubtitle>
            )}
          </Item>
        )
      )}
    </MenuWrapper>
  );
};
