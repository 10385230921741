import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import { Search, LoadingIndicator, CheckboxInput } from 'lib/components';
import { ModalCreateLead, LeadTable } from '.';
import { LeadItem } from 'lib/context';
import { exportCSVFile } from 'lib/utils/functions';
import { MdAdd } from 'react-icons/md';
import { useToastError } from 'lib/hooks';
import { ILead, useLeadsQuery } from 'lib/api/leads/getLeads';
import { useAddLeadMutation } from 'lib/api/leads/useAddLead';

const Content = styled.div`
  margin-top: 32px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Filter = styled.div`
  min-width: 215px;
  margin-right: 12px;
`;

const SelectionContainer = styled.div`
  display: none;
  position: fixed;
  left: 0px;
  right: 0;
  top: 65px;
  background: white;
  height: 40px;
  z-index: 10;
  justify-content: 'space-between';
  align-items: center;
  padding: 12px 44px 12px 61px;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  ${theme.mediaQueryMinWidth.sm} {
    display: flex;
  }
`;

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 916px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-between;
`;

const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;

const exportHeader = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'phone',
  addedOn: 'Added on',
  status: 'Status',
};

export const LeadListing = () => {
  const { showError } = useToastError();
  const [showCreateLeadModal, setShowCreateLeadModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedLead, setSelectedLead] = useState<string[]>([]);

  const { data: leads, refetch: refreshLeads } = useLeadsQuery(
    { size, page, sort: '-', search: searchQuery },
    true
  );
  const { mutateAsync: mutateAddLeadAsync, isLoading: loading } =
    useAddLeadMutation();

  const leadsArray = leads?.leads || [];
  useEffect(() => {
    refreshLeads();
  }, [page, size, searchQuery]);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const handleExportBatchClick = () => {
    const selectedLeadsArray = leadsArray.filter((lead: ILead) => {
      return selectedLead.find((id: string | number) => id === lead?.leadId);
    });
    exportCSVFile(exportHeader, selectedLeadsArray, 'selected_customers');
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedLead([]);
    }
  };

  const handleSubmit = async (data: Partial<LeadItem>) => {
    try {
      await mutateAddLeadAsync(data);
      refreshLeads();
      setShowCreateLeadModal(false);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      {!!selectedLead.length && (
        <SelectionContainer>
          <LayoutOption>
            <CheckboxSelectionWrapper>
              <CheckboxInput
                blueCheck={true}
                checkGroupIndicator={
                  selectedLead.length > 0 &&
                  leadsArray.length !== selectedLead.length
                }
                checked={leadsArray.length == selectedLead.length}
                ignoreGrayForAllSelect={true}
                grayCheck={true}
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.stopPropagation();
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.stopPropagation();
                  let { checked } = event.target as HTMLInputElement;
                  if (checked)
                    setSelectedLead(leadsArray.map((e: any) => e.leadId));
                  else setSelectedLead([]);
                }}
              />
              <SelectionCountWrap>
                <SelectionCountText>Customers selected:</SelectionCountText>
                <b>{selectedLead.length}</b>
              </SelectionCountWrap>
            </CheckboxSelectionWrapper>
            <div>
              <Button
                onClick={() => handleExportBatchClick()}
                text='Export'
                variant='secondary'
              />
            </div>
          </LayoutOption>
        </SelectionContainer>
      )}
      <Content>
        <Header>
          <Filter>
            <Search placeholder='Search Customers...' onSearch={onSearch} />
          </Filter>
          <Button
            onClick={() => setShowCreateLeadModal(true)}
            variant='primary'
            text='Create'
            icon={<MdAdd />}
          />
        </Header>
        {leadsArray.length && !loading ? (
          <LeadTable
            leadsArray={leadsArray}
            selectedLead={selectedLead}
            count={leads?.count || 0}
            page={page}
            size={size}
            onPaginationChange={onPaginationChange}
            setSelectedLead={setSelectedLead}
          />
        ) : !leadsArray.length && !loading ? (
          <p>No customers to show...</p>
        ) : (
          <LoadingIndicator isLoading={loading} height='200px' />
        )}
        {showCreateLeadModal && (
          <ModalCreateLead
            handleModalClose={() => setShowCreateLeadModal(false)}
            handleSubmit={handleSubmit}
          />
        )}
      </Content>
    </>
  );
};
