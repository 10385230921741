import React from 'react';
import {
  FieldLabel,
  FormProps,
  getCheckboxField,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const AutominerSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  return (
    <Section>
      <SectionTitle>Autominer</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'autominerEnabled',
            'Enable Autominer',
            !!values.autominerEnabled,
            () => setFieldValue('autominerEnabled', !values.autominerEnabled),
            isSubmitting
          )}
        </Row>

        {!!values.autominerEnabled && (
          <Row>
            <FieldLabel>Autominer Group ID</FieldLabel>
            {getInputField('autominerGroupId', false, false, isSubmitting)}
          </Row>
        )}
      </RowWrapper>
    </Section>
  );
};
