import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { PageLayoutListItem } from '../PageLayoutListItem';
import landingSocialLayout from 'lib/images/landingPageLayoutSocialAndCtaReach.svg';
import landingMediaFocusLayout from 'lib/images/landingPageMediaFocusLayout.svg';
import landingStandardLayout from 'lib/images/landingPageStandardLayout.svg';
import landingDigitalPresence from 'lib/images/landingPageDigitalPresenceLayout.svg';
import landingBrandCtaLayout from 'lib/images/landingPageBrandAndCTAReachLayout.svg';
import landingMediaImageLayout from 'lib/images/landingPageMediaImageLayout.svg';
import { useLandingPageBuilderContext } from 'app/pages/design/landingPageBuilder/context';
import { getLayoutForLayoutType } from '../Constants';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
interface Props {
  onClose: (params?: any) => void;
  onContinueButtonClick: (params?: any) => void;
  modalLoading: boolean;
  type: 'add' | 'edit';
  selectedLayoutName?: string;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const getUserInfoFromPreviousLayout = (oldLayoutData: any) => {
  const data = oldLayoutData;
  let infoString = '';
  for (let key in data) {
    const section = data[key];
    if (section.sectionType === 1) {
      let childSections = section.childSections;
      for (let i = 0; i < childSections.length; i++) {
        let childSection = childSections[i];
        if (
          childSection.sectionType === 5 &&
          childSection.isUserContactInfo === true
        ) {
          infoString = data[key]['childSections'][i].textContent;
        }
      }
    }
    if (section.sectionType === 5 && section.isUserContactInfo) {
      infoString = data[key].textContent;
    }
  }

  return infoString;
};
export const ModalLandingPageDesignAddOrEdit = (props: Props) => {
  const {
    selectedLayout,
    setSelectedLayout,
    setSelectedLayoutData,
    selectedLayoutData,
    defaultCta,
    defaultSocialLinks,
    userProfile,
  } = useLandingPageBuilderContext();
  const { onClose, onContinueButtonClick, modalLoading, type } = props;
  const title = type === 'add' ? 'Choose Layout' : 'Choose Layout';
  const buttonText = type === 'add' ? 'Continue' : 'Continue';
  const themes = useTheme();

  const { whitelabel, userData } = useAuth();

  React.useEffect(() => {}, []);

  const getUserInfoString = () => {
    if (!userProfile) {
      return '';
    }

    const firstName = userProfile?.firstName || '';
    const lastName = userProfile?.lastName || '';
    const email = userProfile?.email || '';
    const phone1 = userProfile?.phone1 ? userProfile.phone1.trim() : '';
    const phone2 = userProfile?.phone2 ? userProfile.phone2.trim() : '';
    const department = userProfile?.Dept || '';

    let contactString = '';
    const name = firstName + ' ' + lastName;
    if (name && name.trim() != '') {
      contactString += name + ' | ';
    }

    const emailAndContact = email + (phone1 !== '' ? ' ' + phone1 : '');
    if (emailAndContact && emailAndContact != '') {
      contactString += emailAndContact + ' | ';
    }

    const deptAndContact = (phone2 ? phone2 + ' ' : '') + department;
    if (deptAndContact && deptAndContact != '') {
      contactString += deptAndContact;
    }

    return contactString;
  };

  const onLayoutSelect = (e: any) => {
    let currentLayoutData = { ...selectedLayoutData };
    let userInfo = '';
    if (currentLayoutData) {
      userInfo = getUserInfoFromPreviousLayout(currentLayoutData);
    }

    if (userInfo === '') {
      userInfo = getUserInfoString();
    }
    let selectedLayoutName1 = e.target.getAttribute('data-layout-name');
    setSelectedLayout(selectedLayoutName1);
    setSelectedLayoutData(
      getLayoutForLayoutType(
        whitelabel,
        selectedLayoutName1,
        defaultCta,
        defaultSocialLinks,
        userData
      )
    );
  };

  return (
    <Modal>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row justifyContent={'space-between'}>
          <Title>{title}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row justifyContent={'space-between'}>
          <PageLayoutListItem
            isSelected={selectedLayout == 'standard'}
            layoutName={'standard'}
            layoutImageSource={landingStandardLayout}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
          <PageLayoutListItem
            isSelected={selectedLayout == 'digitalPresence'}
            layoutName={'digitalPresence'}
            layoutImageSource={landingDigitalPresence}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
          <PageLayoutListItem
            isSelected={selectedLayout == 'brand'}
            layoutName={'brand'}
            layoutImageSource={landingBrandCtaLayout}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
        </Row>
        <Row>
          <PageLayoutListItem
            isSelected={selectedLayout == 'social'}
            layoutName={'social'}
            layoutImageSource={landingSocialLayout}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
          <PageLayoutListItem
            isSelected={selectedLayout == 'mediaFocus'}
            layoutName={'mediaFocus'}
            layoutImageSource={landingMediaFocusLayout}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
          <PageLayoutListItem
            isSelected={selectedLayout == 'mediaImage'}
            layoutName={'mediaImage'}
            layoutImageSource={landingMediaImageLayout}
            onLayoutSelect={onLayoutSelect}
            selectedLayoutName={selectedLayout}
          />
        </Row>

        <Row justifyContent={'flex-end'}>
          <Button
            text={buttonText}
            disabled={selectedLayout == ''}
            onClick={() => {
              onContinueButtonClick();
            }}
          />
        </Row>
      </Content>
    </Modal>
  );
};
