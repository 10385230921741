import styled from 'styled-components/macro';
import { ReactComponent as ImageSvg } from './icon-services.svg';

const ShowServicesIcon = styled(ImageSvg)`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '17px'};
  fill: ${props => props.color || 'currentColor'};
`;

export default ShowServicesIcon;
