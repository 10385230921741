import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Fragment } from 'react';
import LockIcon from 'lib/images/LockIcon';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { HoverPopup } from 'lib/components/HoverPopup';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: auto;
  height: 40px;
  background: ${theme.palette.secondaryButtonBlueBg};
  border-radius: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 4px;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  width: 164px;
  text-align: center;
  color: ${theme.palette.primaryDarkBlue};
  opacity: 0.5;
  position: relative;
  z-index: 3;
  cursor: pointer;
`;
const ActiveText = styled(Text)`
  opacity: 1;
`;
type PillProps = {
  transform: string;
};
const Pill = styled.div<PillProps>`
  width: 164px;
  height: 32px;
  background: ${theme.palette.white};
  position: absolute;
  z-index: 2;
  left: 4px;
  transform: ${props => props.transform};
  box-shadow: 0px 2px 4px rgba(29, 30, 36, 0.04);
  border-radius: 16px;
`;

const DisabledFragment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 164px;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: ${theme.palette.primaryDarkBlue};
  opacity: 0.5;
  position: relative;
  z-index: 3;
  cursor: not-allowed;
`;

type Props = {
  defaultValue: string;
  onChange: (value: string) => void;
  values: object;
  className?: string;
  pillStyle?: object;
  textStyle?: object;
  disabledTextStyle?: object;
  disableToggle?: boolean;
};

export const ButtonPillSwitch = ({
  defaultValue,
  values,
  onChange,
  className,
  pillStyle,
  textStyle,
  disabledTextStyle,
  disableToggle = false,
}: Props) => {
  const valuesArray = Object.values(values);
  const [activeValue, setActiveValue] = React.useState(defaultValue);
  const nextPlan = 'Pro';
  const toggle = (value: string) => {
    setActiveValue(value);
    onChange(value);
  };

  React.useEffect(() => {
    setActiveValue(defaultValue);
  }, [defaultValue]);

  return (
    <Wrapper {...(className && { className: className })}>
      {valuesArray
        .filter(tab => !tab.isHidden)
        .map((item, index) => {
          const itemIsComplexObject = !!item.value;
          const value = itemIsComplexObject ? item.value : item;
          const isHidden = itemIsComplexObject ? !!item.isHidden : false;
          const isLocked = itemIsComplexObject ? !!item.isLocked : false;
          const isLive = itemIsComplexObject ? !!item.isLive : true;
          const isDisabled = itemIsComplexObject ? !!item.isDisabled : false;
          const isActive = value === activeValue;
          if (isHidden) {
            return <React.Fragment key={`pill-${index}`}></React.Fragment>;
          }

          if (!isLive) {
            return (
              <HoverPopup
                width='120px'
                padding='8px 12px'
                popup={<>Available Soon</>}
                hoverable={
                  <DisabledFragment key={`pill-${index}`}>
                    {value}
                  </DisabledFragment>
                }
              />
            );
          }
          if (isLocked) {
            return (
              <ProductInfoTooltip nextPlan={nextPlan} direction='bottom'>
                <DisabledFragment key={`pill-${index}`}>
                  {value}
                  <LockIcon
                    width={16}
                    height={16}
                    color={theme.palette.primaryDarkBlue}
                  />
                </DisabledFragment>
              </ProductInfoTooltip>
            );
          }
          if (isActive) {
            return (
              <Fragment key={`pill-${index}`}>
                <Pill
                  style={pillStyle}
                  transform={`translate(${index * 100}%)`}
                />

                <ActiveText style={textStyle}>{value}</ActiveText>
              </Fragment>
            );
          }
          if (isDisabled) {
            return (
              <DisabledFragment key={`pill-${index}`} style={disabledTextStyle}>
                {value}
              </DisabledFragment>
            );
          }
          return (
            <Text
              style={textStyle}
              key={`pill-${index}`}
              onClick={() => (disableToggle ? onChange(value) : toggle(value))}
            >
              {value}
            </Text>
          );
        })}
    </Wrapper>
  );
};
