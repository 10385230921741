import React from 'react';
import styled, { css } from 'styled-components/macro';
import { HelpDeskTypes, EHelpDesk } from './utils';

const getHelpDeskWrapperStyles = (name: HelpDeskTypes) => {
  switch (name) {
    case EHelpDesk.LANDING_PAGES_BUILDER:
      return css`
        border: none;
        padding: 0;
        margin-bottom: 0; /** SUS-965 changes **/
      `;
    default:
      return css``;
  }
};

export const HelpDeskWrapper = styled.div<{ name: HelpDeskTypes }>`
  display: flex;
  width: 100%;
  padding: 13px 0;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid rgba(238, 239, 242, 1);
  margin-bottom: 20px;

  ${({ name }) => getHelpDeskWrapperStyles(name)}
`;

export const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 4px;
  padding: 8px;

  :hover {
    background: ${({ theme }) => theme.colors.secondary[5]};
    border-radius: 4px;
  }
  :hover .rect {
    fill: ${({ theme }) => theme.colors.secondary[100]};
  }
  :hover p {
    color: ${({ theme }) => theme.colors.secondary[100]};
  }
`;

export const ItemParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  color: #4e5461;
`;

export const Player = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_0_35500'
      maskUnits='userSpaceOnUse'
      x='1'
      y='3'
      width='18'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.4 3.5C2.07452 3.5 1 4.57452 1 5.9V14.1C1 15.4255 2.07452 16.5 3.4 16.5H16.6C17.9255 16.5 19 15.4255 19 14.1V5.9C19 4.57452 17.9255 3.5 16.6 3.5H3.4ZM9.38 7.05995L12.58 9.45995C12.9335 9.72505 13.0051 10.2265 12.74 10.58L12.6918 10.6383C12.6578 10.6756 12.6204 10.7096 12.58 10.74L9.38 13.14C9.02654 13.405 8.5251 13.3334 8.26 12.98C8.15614 12.8415 8.1 12.673 8.1 12.5V7.69995C8.1 7.25812 8.45817 6.89995 8.9 6.89995C9.0731 6.89995 9.24152 6.95609 9.38 7.05995Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_0_35500)'>
      <rect width='20' height='20' fill='#9297A2' className='rect' />
    </g>
  </svg>
);

export const School = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_0_35496'
      maskUnits='userSpaceOnUse'
      x='0'
      y='3'
      width='20'
      height='15'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0007 4.58325C11.209 3.66659 12.959 3.33325 14.584 3.33325C15.7923 3.33325 17.0757 3.51659 18.1423 3.99159C18.7507 4.26659 19.159 4.85825 19.159 5.53325V14.9333C19.159 16.0249 18.1423 16.8249 17.0923 16.5499C16.2757 16.3416 15.409 16.2499 14.5757 16.2499C13.2757 16.2499 11.8923 16.4583 10.7757 17.0166C10.284 17.2666 9.71732 17.2666 9.21732 17.0166C8.10065 16.4666 6.71732 16.2499 5.41732 16.2499C4.58398 16.2499 3.71732 16.3416 2.90065 16.5499C1.85065 16.8166 0.833984 16.0166 0.833984 14.9333V5.53325C0.833984 4.85825 1.24232 4.26659 1.85065 3.99159C2.92565 3.51659 4.20898 3.33325 5.41732 3.33325C7.04232 3.33325 8.79232 3.66659 10.0007 4.58325ZM16.5007 14.7583C17.0173 14.8499 17.5007 14.4666 17.5007 13.9416V6.04992C17.5007 5.65825 17.2173 5.31659 16.834 5.23325C16.109 5.07492 15.3507 4.99992 14.584 4.99992C13.1673 4.99992 11.1257 5.54159 10.0007 6.24992V15.8416C11.1257 15.1333 13.1673 14.5916 14.584 14.5916C15.2257 14.5916 15.8757 14.6416 16.5007 14.7583Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_0_35496)'>
      <rect width='20' height='20' fill='#9297A2' className='rect' />
    </g>
  </svg>
);
