import bottomLeft from 'lib/images/bottomLeft.svg';
import bottomRight from 'lib/images/bottomRight.svg';
import bottomCenter from 'lib/images/bottomCenter.svg';
import topLeft from 'lib/images/topLeft.svg';
import topRight from 'lib/images/topRight.svg';
import { annotationTypes } from './types';

export const positions = [
  'Top Left',
  'Top Center',
  'Top Right',
  'Bottom Left',
  'Bottom Center',
  'Bottom Right',
  'Center',
];

export const positionsWithIcons = [
  {
    value: 'Bottom Center',
    icon: bottomCenter,
    for: annotationTypes.ANNOTATION_TEXT,
  },
  {
    value: 'Bottom Left',
    icon: bottomLeft,
    for: annotationTypes.ANNOTATION_TEXT,
  },

  {
    value: 'Bottom Right',
    icon: bottomRight,
    for: annotationTypes.ANNOTATION_TEXT,
  },

  {
    value: 'Top Left',
    for: annotationTypes.ANNOTATION_IMAGE,
    icon: topLeft,
  },
  {
    value: 'Top Right',
    for: annotationTypes.ANNOTATION_IMAGE,
    icon: topRight,
  },
];
