import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { GuideRoutes } from './main/GuideRoutes';

export const Guides = () => (
  <>
    <DocumentHead title='Guides' />
    <PageTemplate main={<GuideRoutes />} />
  </>
);
