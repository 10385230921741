import React, { useState } from 'react';

import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { LoadingIndicator, Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { RecipientsSelector } from './RecipientsSelector';
import { sendAnalyticsReport } from 'lib/api';
import { StatusOptionsValues } from 'app/pages/video/videoDetails/components/const';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

type Props = {
  handleModalClose: (modals: null) => void;
  params?:
    | {
        search: string;
        emailStatus: StatusOptionsValues;
        SMS: number;
      }
    | {};
  videoId: string;
};

export const AnalyticsReportModal = (props: Props) => {
  const [recipients, setRecipients] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { handleModalClose, params, videoId } = props;

  const { ref } = useOnClickOutside(handleModalClose);
  const { showError } = useToastError();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose(null);
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const sendReport = async () => {
    setLoading(true);

    try {
      const config = {
        params,
        videoId,
        payload: {
          receivers: recipients,
        },
      };
      await sendAnalyticsReport(config);
      handleModalClose(null);
      successToast({ title: 'You have successfully sent a report' });
    } catch (error) {
      showError(error);
    }
    setLoading(false);
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Send Report</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose(null)}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <Content>
          {loading ? (
            <LoadingIndicator isLoading={loading} height='50px' />
          ) : (
            <>
              <RecipientsSelector
                onChange={recipients => setRecipients(recipients)}
                onError={email => {
                  if (email) {
                    setError('Invalid email address.');
                  } else {
                    setError('');
                  }
                }}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Content>
        <Footer>
          <Button
            text={loading ? 'Sending' : 'Send'}
            disabled={loading || !recipients || !recipients.length}
            onClick={sendReport}
          />
        </Footer>
      </div>
    </Modal>
  );
};
