import styled from 'styled-components/macro';
import { ReactComponent as LogoSvg } from './Logo.svg';

const LogoIcon = styled(LogoSvg)`
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '24px'};
  path {
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default LogoIcon;
