import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  Dropdown,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { VideoReactionItem } from 'lib/context';
import { theme } from 'lib/style';
import { OrderTableArrowIcon } from 'lib/images/OrderTableArrow';
import { Button } from 'react-covideo-common';
import { MdDeleteForever, MdEmail, MdFileDownload } from 'react-icons/md';
import { exportCSVFile, toMMSSTimestamp } from 'lib/utils/functions';
import {
  VideoReactionsDictionary,
  VideoReactionType,
} from 'lib/const/VideoReactionsDictionary';
import dayjs from 'dayjs';
import { SendReactionsModal } from 'lib/components/modal/SendReactionsModal';
import { getSearchData, prepareExportDataItem } from 'lib/utils/videoReactions';
import { Dictionary } from 'lodash';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { MESSAGES } from 'lib/const/Common';
import { useVideoReactionsQuery } from 'lib/api/videos/useGetVideoReactions';

const TableContainer = styled.div`
  width: 100%;
  th,
  td {
    text-align: start;
    border-bottom: none;
    font-size: 14px;
    padding: 12px;
    font-weight: 400;
    color: black;
  }

  tr:last-of-type > td {
    border-bottom: none;
  }

  tr {
    border-bottom: 1px solid ${theme.palette.secondaryGray20Percent};
  }

  th {
    color: ${theme.palette.gray60};
    font-weight: 400;
  }

  td:nth-of-type(2) {
    text-align: center;
  }

  td:nth-of-type(4),
  th:nth-of-type(4) {
    text-align: start;
  }

  td:nth-of-type(4) {
    font-weight: 500;
  }

  td:nth-of-type(5),
  th:nth-of-type(5) {
    text-align: end;
  }

  .search {
    width: 243px;
  }
`;

const IconWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;

const TimestampContainer = styled.div`
  margin-right: 5px;
`;

const InputsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const TopRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const DeleteIconContainer = styled.div`
  cursor: pointer;
`;

const SelectOption = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 8px;
  }
`;

const SORT = {
  ASC: 'asc',
  DESC: 'desc',
};

interface VideoReactionsTableProps {
  videoId: string;
  handleDeleteVideoReaction: (
    videoId: string,
    reactionId: string,
    type: VideoReactionType
  ) => void;
}

interface VideoReactionRowProps {
  key: string;
  columns: any[];
  onClick: () => void;
}

interface SortProps {
  sort: string;
  field: string;
  type: string;
}

export const VideoReactionsTable = ({
  videoId,
  handleDeleteVideoReaction,
}: VideoReactionsTableProps) => {
  const { data: videoReactions } = useVideoReactionsQuery(videoId);

  const [reactionsEmote, setReactionsEmote] = React.useState<
    Dictionary<VideoReactionItem>
  >({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [activeTimestampSort, setActiveTimestampSort] = useState('');
  const [activeDateSort, setActiveDateSort] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(0);

  const [selectedTableRows, setSelectedTableRows] = useState<
    VideoReactionRowProps[]
  >([]);
  const [exportData, setExportData] = useState<VideoReactionItem[]>([]);

  const [allData, setAllData] = useState<VideoReactionItem[]>([]);
  const [showSendReportModal, setShowSendReportModal] = useState(false);

  useEffect(() => {
    if (videoReactions) {
      let reactionsObject = {} as any;
      Object.keys(videoReactions).forEach(
        key =>
          videoReactions[key].type === VideoReactionType.EMOTE &&
          (reactionsObject[key] = videoReactions[key])
      );
      setReactionsEmote(reactionsObject);
    }
  }, [videoReactions]);

  const exportHeaders = {
    playbackPosition: 'Timestamp',
    emoticonId: 'Reaction',
    name: 'Name',
    email: 'Email Address / Phone',
    createdAt: 'Date',
  };

  const fieldsToBeSearched = [
    'playBackPosition',
    'email',
    'phone',
    'createdAt',
    'name',
  ];

  const headers = [
    <IconWrapper
      onClick={() => {
        let sort;
        if (!activeTimestampSort) sort = SORT.ASC;
        else
          activeTimestampSort === SORT.ASC
            ? (sort = SORT.DESC)
            : (sort = SORT.ASC);
        setActiveTimestampSort(sort);
        sortData({ sort, field: 'playbackPosition', type: 'number' });
      }}
    >
      <TimestampContainer>Timestamp</TimestampContainer>
      <OrderTableArrowIcon
        color={
          activeTimestampSort === SORT.ASC
            ? theme.palette.primaryDarkBlue
            : theme.palette.gray60
        }
        rotate={180}
      />
      <OrderTableArrowIcon
        color={
          activeTimestampSort === SORT.DESC
            ? theme.palette.primaryDarkBlue
            : theme.palette.gray60
        }
      />
    </IconWrapper>,
    'Reaction',
    'Name',
    'Email Address / Phone',
    <IconWrapper
      onClick={() => {
        let sort;
        if (!activeDateSort) sort = SORT.ASC;
        else
          activeDateSort === SORT.ASC ? (sort = SORT.DESC) : (sort = SORT.ASC);
        setActiveDateSort(sort);
        sortData({ sort, field: 'createdAt', type: 'date' });
      }}
    >
      <TimestampContainer>Date</TimestampContainer>
      <OrderTableArrowIcon
        color={
          activeDateSort === SORT.ASC
            ? theme.palette.primaryDarkBlue
            : theme.palette.gray60
        }
        rotate={180}
      />
      <OrderTableArrowIcon
        color={
          activeDateSort === SORT.DESC
            ? theme.palette.primaryDarkBlue
            : theme.palette.gray60
        }
      />
    </IconWrapper>,
    '',
  ];

  const emojiOptions = Object.keys(VideoReactionsDictionary).map(
    (key: any) => ({
      value: VideoReactionsDictionary[key].id,
      label: (
        <SelectOption>
          <div>{VideoReactionsDictionary[key].icon}</div>
          {VideoReactionsDictionary[key].title}
        </SelectOption>
      ),
    })
  );

  const options = [
    { value: 0, label: 'All Reactions' },
    ...emojiOptions.slice(0, emojiOptions.length - 1),
  ];

  const createTableRow = (videoReaction: VideoReactionItem) => {
    const {
      playbackPosition,
      emoticonId,
      email,
      phone,
      createdAt,
      reactionId,
      name,
    } = videoReaction;
    const emoticon = emoticonId
      ? VideoReactionsDictionary[emoticonId]
      : { icon: '' };

    return {
      key: reactionId,
      columns: [
        toMMSSTimestamp(playbackPosition, 0),
        emoticon.icon,
        name,
        email ? email : phone,
        dayjs(createdAt).format(`${STANDARD_DATE_FORMAT} hh:mm:ss A`),
      ],
      onClick: () => {},
    };
  };

  // load initial data
  const prepareTableData = () => {
    const rows = [] as any;
    const elements = [] as any;
    const dataForExport = [] as any;

    Object.values(reactionsEmote).forEach(reaction => {
      rows.push(createTableRow(reaction));
      elements.push(reaction);
      dataForExport.push(prepareExportDataItem(reaction));
    });

    setAllData(elements);
    setSelectedTableRows(rows.slice(0, size));
    setExportData(dataForExport);
    setCount(rows.length);
  };

  const updateTableData = () => {
    if (allData.length) {
      let tableData = getSearchData({
        fieldsToBeSearched,
        allData,
        searchTerm,
      });
      const dataForExport = [] as any;

      if (filter) {
        tableData = tableData.filter((item: any) => item.emoticonId === filter);
      }

      // update count
      setCount(tableData.length);

      const tableRows = [] as any;

      const start = page ? page * size : page;
      const end = page ? page * size + (size - 1) : size - 1;

      tableData.forEach((item: any) => {
        tableRows.push(createTableRow(item));
        dataForExport.push(prepareExportDataItem(item));
      });

      setSelectedTableRows(tableRows.slice(start, end));
      setExportData(dataForExport);
    }
  };

  const onPaginationChange = ({ page, size }: any) => {
    setPage(page);
    setSize(size);
  };

  const dateSortingFunction = (a: string, b: string, sort: string) => {
    if (sort === SORT.ASC) {
      return dayjs(a).diff(dayjs(b));
    }
    return dayjs(b).diff(dayjs(a));
  };

  const numberSortingFunction = (a: number, b: number, sort: string) => {
    if (sort === SORT.ASC) {
      return a - b;
    }
    return b - a;
  };

  const sortData = ({ sort, field, type }: SortProps) => {
    let toBeSortedTableData = getSearchData({
      fieldsToBeSearched,
      searchTerm,
      allData,
    });
    const sortedRows = [] as any;
    const dataForExport = [] as any;

    if (filter) {
      toBeSortedTableData = toBeSortedTableData.filter(
        (item: any) => item.emoticonId === filter
      );
    }

    switch (type) {
      case 'date':
        toBeSortedTableData.sort((a: any, b: any) =>
          dateSortingFunction(a[field], b[field], sort)
        );

        break;
      case 'number':
        toBeSortedTableData.sort((a: any, b: any) =>
          numberSortingFunction(a[field], b[field], sort)
        );
        break;
      default:
        break;
    }

    toBeSortedTableData.forEach((item: any) => {
      sortedRows.push(createTableRow(item));
      dataForExport.push(prepareExportDataItem(item));
    });

    setExportData(dataForExport);
    if (!filter) setAllData(toBeSortedTableData);
    setSelectedTableRows(sortedRows.slice(0, size - 1));
    setPage(0);
  };

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };

  useEffect(() => {
    prepareTableData();
  }, [reactionsEmote]);

  useEffect(() => {
    updateTableData();
  }, [page, size, searchTerm, filter]);

  return (
    <TableContainer>
      <TopRowContainer>
        <InputsContainer>
          <Search
            onSearch={(value: string) => handleSearch(value)}
            className='search'
            prevSearch={searchTerm}
            placeholder='Search'
          ></Search>
          <Dropdown
            value={filter}
            defaultValue={
              filter ? VideoReactionsDictionary[filter].title : 'All Reactions'
            }
            placeholder={
              filter ? VideoReactionsDictionary[filter].title : 'All Reactions'
            }
            options={options}
            onChange={(filter: any) => {
              setFilter(filter.value);
            }}
            width={243}
          ></Dropdown>
        </InputsContainer>

        {!!selectedTableRows.length && (
          <ButtonsContainer>
            <Button
              onClick={() => {
                setShowSendReportModal(true);
              }}
              text='Send'
              icon={<MdEmail />}
              variant='ghost'
            />
            <Button
              onClick={() =>
                exportCSVFile(exportHeaders, exportData, 'video_reactions')
              }
              text='Download'
              variant='ghost'
              icon={<MdFileDownload />}
            />
          </ButtonsContainer>
        )}
      </TopRowContainer>

      <TableContextProvider
        total={count}
        initSize={size}
        initPage={page}
        onChange={onPaginationChange}
      >
        {selectedTableRows.length > 0 ? (
          <Table
            headers={headers}
            rows={selectedTableRows.map(row => {
              const { key, columns } = row;
              const deletableColumns = [
                ...columns,
                <TableCellContainer>
                  <DeleteIconContainer
                    onClick={() =>
                      handleDeleteVideoReaction(
                        videoId,
                        key,
                        VideoReactionType.EMOTE
                      )
                    }
                  >
                    <MdDeleteForever
                      color={theme.palette.primaryRedDanger}
                      style={{ marginLeft: '10px' }}
                      size={25}
                    />
                  </DeleteIconContainer>
                </TableCellContainer>,
              ];
              return {
                key,
                columns: deletableColumns,
              };
            })}
            columnWidths={['100px', '65px', 'auto', 'auto', '100px', 'auto']}
          />
        ) : (
          <>{MESSAGES.NO_DATA_FOUND}</>
        )}
        <TableFooter>
          <TablePaginationNew />
          <TablePaginationSizeNew text='Show reactions:' />
        </TableFooter>
      </TableContextProvider>
      {showSendReportModal && (
        <SendReactionsModal
          videoId={videoId}
          handleModalClose={() => setShowSendReportModal(false)}
          data={exportData}
        ></SendReactionsModal>
      )}
    </TableContainer>
  );
};
