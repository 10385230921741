import {
  getTimezoneByUtc,
  timezonesOptions,
  updateDateToNext15MinuteInterval,
} from 'app/pages/account/userAccount/schedule/utils';
import dayjs from 'dayjs';
import { UserInvite } from 'lib/api/superadmin/useUserInvitesQuery';
import { MANAGE_INVITES_TYPE } from './types';

export const getCustomDateTimePickerValue = (
  deliveryTime: string | undefined | null
) => {
  if (deliveryTime) {
    const splitDate = deliveryTime && deliveryTime.split(';');
    let combinedDateTime: Date = new Date();
    if (splitDate) {
      combinedDateTime = new Date(
        `${splitDate[0]} ${splitDate[1]} ${splitDate[2]}`
      );
      return combinedDateTime;
    }
  }
  return updateDateToNext15MinuteInterval(new Date());
};

export const getUserTimezone = (
  deliveryTime: string | undefined | null,
  timezone: string
) => {
  // get edit time zone
  if (deliveryTime) {
    if (deliveryTime?.length > 0) {
      const deliveryTimeDetails = deliveryTime.split(';');
      return getTimezoneByUtc(deliveryTimeDetails[3]);
    }
  }
  const userPreferredTimezone = timezonesOptions.find(tz =>
    tz.utc.includes(timezone)
  );

  return userPreferredTimezone || timezonesOptions[0];
};

export const getDeliveryTime = (
  deliveryTime: string | undefined | null,
  timezone: string
) => {
  return `${dayjs(updateDateToNext15MinuteInterval(new Date())).format(
    'YYYY-MM-DD;hh:mm;A'
  )};${getUserTimezone(deliveryTime, timezone)?.utc[0]}`;
};

export const getDefaultManageInvitesType = (
  isMultipleUsers: boolean,
  users: UserInvite[]
) => {
  if (isMultipleUsers) {
    return MANAGE_INVITES_TYPE.IMMEDIATELY;
  }

  const firstUserInvite = users[0].userInvite;
  if (firstUserInvite && firstUserInvite.deliveryTime) {
    return MANAGE_INVITES_TYPE.SCHEDULED;
  }

  return MANAGE_INVITES_TYPE.IMMEDIATELY;
};
