import { Guides } from 'lib/api/guides/guidesQuery';
import { CheckboxInput, ModalVideoListVideo } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { toMMSS } from 'lib/utils/functions';
import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { useHistory } from 'react-router';
import { Button } from 'react-covideo-common';
import { SingleCategoryModalType } from './main/pages/SingleCategory';
import DragIcon from 'lib/images/DragIcon';
import useImageLoadRetry from 'lib/hooks/useImageLoadRetry';
import { ModalsType } from './main/pages/MainGuidePage';
import useVideoLoadRetry from 'lib/hooks/useVideoLoadRetry';
import DeleteIcon from 'lib/images/DeleteIcon';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';

type VideoThumbnailProps = {
  visible: boolean;
  src: string;
  skipLast?: boolean;
};

const ThumbnailWrapper = styled.div<{ skipLast: boolean }>`
  border-radius: 6px;
  display: flex;
  gap: 8px;
  max-width: 300px;
  flex-direction: column;
  :hover .action-button-wrapper {
    opacity: 1 !important;
  }

  ${props =>
    props.skipLast &&
    css`
      @media (max-width: 1280px) {
        :nth-child(4) {
          display: none;
        }
      }
    `}
  @media (max-width: 1639px) {
    max-width: 236px;
  }
`;
const ThumbnailImageWrapper = styled.div<VideoThumbnailProps>`
  position: relative;
  height: 168px;
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  cursor: pointer;

  ${props =>
    props.visible
      ? css`
          background-image: url(${props.src});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        `
      : css`
          background: ${theme.palette.black};
        `}

  @media (max-width: 1639px) {
    height: 133px;
  }
`;

const VideoDuration = styled.div`
  border-radius: 4px;
  display: inline-flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #272a32;

  position: absolute;
  bottom: 6px;
  right: 6px;
`;
const VideoDurationParagraph = styled.p`
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
`;

const ActionHeader = styled.div`
  position: absolute;
  height: 32px;
  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;
  pointer-events: all;

  width: 100%;
  z-index: 1;
  top: 8px;
  right: 0px;
`;

const DragIconWrapper = styled.div`
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: #272a32;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const Badge = styled.div`
  background: ${props => props.theme.colors.primary[100]};
  color: white;
  padding: 3px 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;

  position: absolute;
  bottom: 6px;
  left: 6px;
`;

const TransparentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  opacity: 0;
`;

export const Thumbnail = ({
  hasActions,
  guide,
  handleGuideSelection,
  selectedGuides,
  setModals,
  isDraggable,
  hasCheckbox = true,
  skipLast = false,
}: {
  hasActions?: boolean;
  guide: Guides;
  handleGuideSelection?: (videoId: number, categoryId?: number) => void;
  selectedGuides?: number[];
  setModals?:
    | React.Dispatch<React.SetStateAction<SingleCategoryModalType>>
    | React.Dispatch<React.SetStateAction<ModalsType>>;
  isDraggable?: boolean;
  hasCheckbox?: boolean;
  skipLast?: boolean;
}) => {
  const thumbnailSrc =
    (guide?.customThumbnail as string) || (guide.video.thumbnail as string);
  const { retryCount, error } = useImageLoadRetry({
    src: thumbnailSrc,
  });
  const thumbnailAvailable = !error && retryCount === 0;
  const prepareTime = (d: number) => {
    return toMMSS(d / 1000).split('.')[0];
  };

  const duration = guide?.video?.videoLength;
  const { videoData } = useVideoLoadRetry({
    videoId: guide.videoId,
    enabled: guide?.video?.processing === VideoEncodingStatus.PROCESSING,
  });

  const newDuration = duration
    ? prepareTime(duration)
    : videoData?.videoLength
      ? prepareTime(videoData?.videoLength)
      : '...';

  const history = useHistory();

  const [openVideoPreviewModal, setOpenVideoPreviewModal] = useState(false);

  useEffect(() => {
    if (openVideoPreviewModal && window.Intercom) {
      window.Intercom('trackEvent', 'guide-viewed', {
        guideCategoryId: guide.guideCategoryId,
        guideTitle: guide.name,
        videoId: guide.videoId,
      });
    }
  }, [openVideoPreviewModal]);
  return (
    <>
      <ThumbnailWrapper skipLast={skipLast}>
        <ThumbnailImageWrapper
          visible={thumbnailAvailable}
          src={thumbnailSrc}
          key={retryCount}
          skipLast={skipLast}
        >
          {!!guide.isNew && <Badge>NEW</Badge>}
          {hasActions && (
            <ActionHeader>
              <Gap
                justifyContent={`${
                  !hasCheckbox ? 'flex-end' : 'space-between'
                }`}
                width='100%'
                onDrag={() => null}
              >
                {hasCheckbox && (
                  <div
                    style={{
                      background: 'rgba(247, 249, 250, 1)',
                      borderRadius: 6,
                    }}
                  >
                    <CheckboxInput
                      grayCheck
                      checked={selectedGuides?.includes(guide?.videoId)}
                      onChange={() => {
                        handleGuideSelection?.(guide?.videoId);
                      }}
                    />
                  </div>
                )}
                {selectedGuides?.length === 0 && (
                  <Gap
                    gap='6px'
                    className='action-button-wrapper'
                    style={{ opacity: '0' }}
                  >
                    <Button
                      size='small'
                      onClick={() =>
                        history.push(
                          '/classic/edit-video/' + guide?.videoId + '/details'
                        )
                      }
                      icon={<MdEdit size={18} />}
                      variant='secondary'
                    />

                    <Button
                      size='small'
                      onClick={() => {
                        setModals?.('delete-guides');
                        handleGuideSelection?.(
                          guide.videoId,
                          guide.guideCategoryId
                        );
                      }}
                      variant='destructive'
                      icon={<DeleteIcon width='18px' height='18px' />}
                    />
                  </Gap>
                )}
              </Gap>
            </ActionHeader>
          )}
          {isDraggable && (
            <DragIconWrapper>
              <DragIcon color={theme.palette.white} />
            </DragIconWrapper>
          )}
          <VideoDuration>
            <VideoDurationParagraph>{newDuration}</VideoDurationParagraph>
          </VideoDuration>
          <TransparentContainer
            onClick={() => setOpenVideoPreviewModal(true)}
          />
        </ThumbnailImageWrapper>
        <ParagraphSmallBold
          style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
          ellipsis
          color='#272A32'
          onClick={() => history.push(`/guides/${guide.videoId}/details`)}
        >
          {guide?.name}
        </ParagraphSmallBold>
      </ThumbnailWrapper>
      {openVideoPreviewModal && (
        <ModalVideoListVideo
          handleModalClose={() => setOpenVideoPreviewModal(false)}
          videoId=''
          source={guide?.video?.videoSource || ''}
        />
      )}
    </>
  );
};
