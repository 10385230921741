import React from 'react';
import { BsXCircleFill } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components/macro';

import { AddFilesToUser, RemoveFilesFromUser } from 'lib/api/addonsApi';
import { LoadingIndicator, Search } from 'lib/components';
import { ModalRemoveUser } from 'lib/components/modal/ModalRemoveUser';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { fetchCustomerUsers } from 'lib/api';

import { PageDescription } from '../components/PageElements';

type Props = {
  Globals: {
    currentTotalUsers: number;
    currentCostPerUser: number;
    active: boolean;
    users: any;
  };
};

export const ManageFilesActive = ({ Globals }: Props) => {
  interface Field {
    email: string;
    id: number;
    firstName: string;
    lastName: string;
    selectable: boolean;
  }

  type FieldProps = {
    isSelected: boolean;
    selectable: boolean;
  };

  const {
    userData: { customerId, email },
    invalidateUser,
  } = useAuth();

  const [selectedFields, setSelectedFields] = React.useState<Field[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [previousSearch, setPreviousSearch] = React.useState('');
  const [showRemoveModalFor, setShowRemoveModalFor] = React.useState<Field>();
  const usersLeft = Globals.currentTotalUsers - selectedFields.length;

  const Seats = styled.label`
    font-weight: 700;
    margin-left: 5px;
    margin-right: 5px;
  `;

  const Body = styled.div`
    padding: 0 32px;
    display: flex;
    min-width: 1000px;
    min-height: 400px;
  `;

  const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `;

  const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  const AllFieldsWrap = styled.div`
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    width: 384px;
    height: 500px;
    background-color: #eeeff2;
    padding: 12px;
    position: relative;
    top: 22px;
  `;

  const ResultsFieldsWrap = styled.div`
    border-radius: 7px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 384px;
    height: 100%;
    background-color: #eeeff2;
  `;

  const AllSelectedFieldsWrap = styled.div`
    align-self: flex-end;
    text-align: right;
    height: 100%;
  `;

  const SelectedFieldsWrap = styled.div`
    border-radius: 7px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 384px;
    height: 525px;
    background-color: #fff;
    border-color: #eeeff2;
    border-style: solid;
    border-width: 1px;
    padding: 0 12px 0 12px;
  `;

  const FieldWrap = styled.div<FieldProps>`
    width: 100%;
    margin-top: 12px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: ${props => (props.isSelected || !props.selectable ? '0.3' : '1')};
    transition: all 0.1s;
  `;

  const SelectedFieldWrap = styled(FieldWrap)`
    background-color: #f6f7f9;
  `;

  const Text = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.palette.black_1_100};
    margin: 12px;
  `;

  const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #4e5461;
    color: white;
    cursor: pointer;
    font-size: 22px;
    margin: 12px;
    opacity: ${usersLeft < 1 ? '0.3' : '1'};
  `;

  const SelectedIconWrap = styled(IconWrap)`
    background-color: transparent;
    opacity: 1;
  `;

  const NoResultsFound = styled.div`
    margin-top: 25px;
  `;

  const SeatsLeft = styled.label`
    font-size: 14px;

    line-height: 20px;
    color: #9297a2;
  `;

  const ManageFilesBody = styled(Body)`
    margin-top: 50px;
  `;

  const onAddClick = async (field: Field) => {
    if (usersLeft > 0 && !isSelected(field)) {
      AddFilesToUser(field.id).then(async () => {
        if (field.email === email) {
          // UPDATE USER
          await invalidateUser();
        }
      });

      setSelectedFields([...selectedFields, field]);
    }
  };

  const onRemoveClick = (field: Field) => {
    if (isSelected(field)) {
      setShowRemoveModalFor(field);
    }
  };

  const removeUser = (field: Field) => {
    if (isSelected(field)) {
      RemoveFilesFromUser(field.id).then(async () => {
        if (field.email === email) {
          // UPDATE USER
          await invalidateUser();
        }
      });

      let newSelectedFields = [...selectedFields];
      newSelectedFields.splice(selectedFields.indexOf(field), 1);
      setSelectedFields(newSelectedFields);
      setShowRemoveModalFor(undefined);
    }
  };

  const isSelected = (field: Field): boolean => {
    return !!selectedFields.find(item => {
      return item.id === field.id;
    });
  };

  const fetchUsers = async () => {
    setLoading(true);
    const users = await fetchCustomerUsers(customerId);
    users.sort((userA: any, userB: any) => {
      if (userA.firstName < userB.firstName) {
        return -1;
      }
      if (userA.firstName > userB.firstName) {
        return 1;
      }
      return 0;
    });

    const initSelectedUsers = users.filter((user: any) => {
      return Globals.users.includes(user.id);
    });

    setUsers(users);
    setFilteredUsers(users);
    setSelectedFields(initSelectedUsers);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const searchUsers = (search: string) => {
    let searchTerm = search.toLowerCase();
    const data = users.filter((user: any) => {
      const firstName = user.firstName.toLowerCase();
      const lastName = user.lastName.toLowerCase();
      return (
        firstName.indexOf(searchTerm) !== -1 ||
        lastName.indexOf(searchTerm) !== -1
      );
    });
    setFilteredUsers(data);
    setPreviousSearch(search);
  };

  return (
    <>
      {showRemoveModalFor && showRemoveModalFor.id > 0 && (
        <ModalRemoveUser
          handleModalConfirm={() => {
            removeUser(showRemoveModalFor);
          }}
          handleModalClose={() => {
            setShowRemoveModalFor(undefined);
          }}
          user={showRemoveModalFor}
          type='Files'
        />
      )}

      <PageDescription>
        The plan you’ve selected contains
        <Seats>
          {Globals.currentTotalUsers} seat
          {Globals.currentTotalUsers > 1 ? 's' : ''}
        </Seats>
        for Files & Boards. Please, assign the seats to the desired users.
      </PageDescription>
      <ManageFilesBody>
        <Row>
          <Col>
            <AllFieldsWrap>
              <Search
                prevSearch={previousSearch}
                placeholder='Search Users...'
                onSearch={searchUsers}
              />
              <ResultsFieldsWrap>
                {(loading && (
                  <LoadingIndicator isLoading={loading} height='300px' />
                )) ||
                  (!loading && filteredUsers.length === 0 && (
                    <NoResultsFound>
                      There are no users matching your search
                    </NoResultsFound>
                  )) ||
                  (!loading &&
                    filteredUsers &&
                    filteredUsers.map((field: Field) => (
                      <FieldWrap
                        key={field.id}
                        isSelected={isSelected(field)}
                        selectable={true}
                      >
                        <Text>
                          {field.firstName} {field.lastName}
                        </Text>
                        <IconWrap onClick={() => onAddClick(field)}>
                          <MdAdd />
                        </IconWrap>
                      </FieldWrap>
                    )))}
              </ResultsFieldsWrap>
            </AllFieldsWrap>
          </Col>
          <Col>
            <AllSelectedFieldsWrap>
              <SeatsLeft>
                {Globals.currentTotalUsers - selectedFields.length} seats left
              </SeatsLeft>
              <SelectedFieldsWrap>
                {(loading && (
                  <LoadingIndicator isLoading={loading} height='300px' />
                )) ||
                  (!loading && selectedFields.length === 0 && (
                    <NoResultsFound>
                      There are no users matching your search
                    </NoResultsFound>
                  )) ||
                  (!loading &&
                    selectedFields &&
                    selectedFields.map((field: Field) => (
                      <SelectedFieldWrap
                        key={field.id}
                        isSelected={false}
                        selectable={true}
                      >
                        <Text>
                          {field.firstName} {field.lastName}
                        </Text>
                        <SelectedIconWrap onClick={() => onRemoveClick(field)}>
                          <BsXCircleFill color='#E84C3D' fillOpacity={0.8} />
                        </SelectedIconWrap>
                      </SelectedFieldWrap>
                    )))}
              </SelectedFieldsWrap>
            </AllSelectedFieldsWrap>
          </Col>
        </Row>
      </ManageFilesBody>
    </>
  );
};
