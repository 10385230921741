import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import {
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import {
  CardProps,
  keyMetricsCards,
  keyMetricsCardTitles,
  keyMetricsInitialTableFields,
  keyMetricsTableFields,
  KeyMetricsTableFieldsProps,
} from '../constants';
import {
  CardsContainer,
  CardWrapper,
  FiltersContainer,
  FiltersLeft,
  FiltersRight,
  KeyMetricsWrapper,
  Right,
  Title,
  Total,
} from '../styles';
import {
  calculateDatesForRange,
  DateRangeSelector,
  GenerateReportModal,
  timeRangeConstants,
} from '../../components';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import { NoContent } from 'app/pages/management/components/NoContent';
import { SortCell, TableCell } from 'app/pages/admin/index.styled';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import {
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import ModalSelectFilters from '../filters/modals/ModalSelectFilters';
import { ILead, useLeadsQuery } from 'lib/api/leads/getLeads';
import { useKeyMetricsStatisticsQuery } from 'lib/api/leads/getKeyMetricsStatistics';
import { useAuth } from 'lib/context';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { successToast } from 'lib/components/toasts/success';
import { downloadReportData } from 'lib/api';

export const KeyMetrics = () => {
  const { userData } = useAuth();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const [dateRange, setDateRange] = React.useState(initRange);
  const [showSendReportModal, setShowSendReportModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);

  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [customizeTable, setCustomizeTable] = useState<boolean>(false);
  //Table
  const columnWidths: number[] = [];
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [tableFields, setTableFields] = useState<KeyMetricsTableFieldsProps[]>(
    keyMetricsInitialTableFields
  );
  const leadsIntegrationActive = !!userData.customer.leadsIntegrationActive;
  const soldTableField = 'Sold';
  const sectionName = 'KeyMetrics';

  const {
    data: leads,
    isLoading,
    refetch: refetchLeads,
  } = useLeadsQuery(
    {
      size: size,
      sort: sort,
      page: page,
      search: searchQuery,
      startDate: startDate,
      endDate: endDate,
      conversation: false,
    },
    leadsIntegrationActive
  );
  const {
    data: ketMatriscStatistics,
    isLoading: isLoadingStatistics,
    refetch: refetchStatistics,
  } = useKeyMetricsStatisticsQuery(
    {
      startDate: startDate,
      endDate: endDate,
    },
    leadsIntegrationActive
  );

  useEffect(() => {
    refetchLeads();
  }, [page, size, searchQuery, sort, startDate]);

  useEffect(() => {
    refetchStatistics();
  }, [startDate]);

  React.useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem(
      TABLE_NAME_PREFIX + sectionName
    );
    if (dataFromLocalStorage) {
      const selectedKeyMetricsFields: KeyMetricsTableFieldsProps[] =
        JSON.parse(dataFromLocalStorage);
      setTableFields(selectedKeyMetricsFields);
    }
  }, []);

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  //Table
  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const sortElement = (sortParam: string) => {
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('DESC')
        ? sort.replace('DESC', 'ASC')
        : sort.replace('ASC', 'DESC');
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}-DESC`;
      setSort(newSort);
    }
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const onTableFieldsUpdate = (
    selectedFields: KeyMetricsTableFieldsProps[]
  ) => {
    setTableFields(selectedFields);
  };
  //Table end

  const getFilterValue = (label: string) => {
    if (label === keyMetricsCardTitles.leads) {
      return ketMatriscStatistics?.count;
    }
    if (label === keyMetricsCardTitles.views) {
      return ketMatriscStatistics?.views;
    }
    if (label === keyMetricsCardTitles.conversionRateWithViews) {
      return ketMatriscStatistics?.conversionRateWithViews;
    }
    if (label === keyMetricsCardTitles.conversionRateWithoutViews) {
      return ketMatriscStatistics?.conversionRateWithoutViews;
    }
  };

  const downloadAll = async () => {
    return downloadReportData(
      `/metrics/key-metrics/download`,
      'key-metrics-report.csv',
      startDate,
      endDate,
      [],
      undefined,
      'POST'
    );
  };

  const onEmailsSent = () => {
    successToast({
      title: 'The report will be generated and emailed shortly.',
    });
  };

  const onSaveCustomTableFields = (fields: KeyMetricsTableFieldsProps[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + sectionName}`,
      JSON.stringify(fields)
    );
    onTableFieldsUpdate(fields);
    setCustomizeTable(false);
  };

  return (
    <KeyMetricsWrapper>
      {customizeTable && (
        <ModalCustomTableFields
          title='Change columns'
          onClose={() => setCustomizeTable(false)}
          allFields={keyMetricsTableFields}
          initialFields={tableFields}
          maxFieldConfig={{ count: keyMetricsTableFields.length }}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
      <Gap justifyContent='space-between' m='0 0 16px 0'>
        <Heading>Key Metrics Report</Heading>
        <Right>
          <DateRangeSelector
            onDateRangeChange={onDateRangeChange}
            initialValue={initRange}
          />

          {openFiltersModal && (
            <ModalSelectFilters
              handleClose={() => setOpenFiltersModal(false)}
            />
          )}
        </Right>
      </Gap>
      <CardsContainer>
        {!isLoadingStatistics ? (
          keyMetricsCards.map((card: CardProps, index: number) => (
            <CardWrapper key={card.title + index}>
              <Total>{getFilterValue(card.title)}</Total>
              <Title>{card.title}</Title>
            </CardWrapper>
          ))
        ) : (
          <LoadingIndicator isLoading={isLoading} height='80px' />
        )}
      </CardsContainer>
      <FiltersContainer>
        <FiltersLeft>
          <Search placeholder='Search table' onSearch={onSearch} />
          <Chip
            bgColor={theme.palette.neutral10}
            fontColor={theme.palette.neutral80}
            lineHeight='20px'
            height='24px'
            width='100%'
          >
            {`Total records: ${leads?.count}`}
          </Chip>
        </FiltersLeft>
        <FiltersRight>
          <Button
            text='Export'
            variant='secondary'
            onClick={() => downloadAll()}
          />
          <Button
            text='Send'
            variant='secondary'
            onClick={() => setShowSendReportModal(true)}
          />
          <Button
            text='Change columns'
            variant='secondary'
            onClick={() => setCustomizeTable(true)}
          />
        </FiltersRight>
      </FiltersContainer>
      {leads?.leads?.length && !isLoading ? (
        <TableContextProvider
          total={leads?.count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <div
            className='scroll--container'
            style={{ overflowX: 'auto', paddingBottom: '20px' }}
          >
            <Table
              borderColor={theme.palette.neutral10}
              tdFontSize='14px'
              tdFontWeight={300}
              compact={true}
              relative={true}
              headers={[
                '',
                ...tableFields.map((item, index) => {
                  return (
                    <TableCell
                      width={columnWidths[index + 1]}
                      onClick={() => sortElement(item.value)}
                    >
                      {item.label}
                      <SortCell>
                        <IoMdArrowRoundUp
                          className={
                            sort == `${item.value}-ASC` ? 'active' : ''
                          }
                        />
                        <IoMdArrowRoundDown
                          className={
                            sort == `${item.value}-DESC` ? 'active' : ''
                          }
                        />
                      </SortCell>
                    </TableCell>
                  );
                }),
              ]}
              hoverable={false}
              columnWidths={columnWidths}
              fixColumnIndex='left'
              rows={leads?.leads?.map((user: ILead, index: number) => ({
                key: index,
                columns: [
                  <TableCell
                    width={columnWidths[tableFields.length]}
                    style={{ alignItems: 'center' }}
                  ></TableCell>,

                  ...tableFields.map((item, index) => {
                    return (
                      <TableCell
                        width={columnWidths[index + 1]}
                        onClick={() => {}}
                      >
                        {item.label === soldTableField
                          ? !!user[item.value as keyof ILead]
                            ? 'Yes'
                            : 'No'
                          : user[item.value as keyof ILead] || '-'}
                      </TableCell>
                    );
                  }),
                ],
                onClick: () => {},
              }))}
            />
          </div>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show metrics:' />
          </TableFooter>
        </TableContextProvider>
      ) : !leads?.leads?.length && !isLoading ? (
        <NoContent title={'No matching results found.'} />
      ) : (
        <LoadingIndicator isLoading={isLoading} height='500px' />
      )}

      {showSendReportModal && (
        <GenerateReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          onSent={() => {
            setShowSendReportModal(false);
            onEmailsSent();
          }}
          title='Send Key Metrics Report'
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/metrics/key-metrics'
          showRecipientsSelector={true}
        />
      )}
    </KeyMetricsWrapper>
  );
};
