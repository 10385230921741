import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useState } from 'react';
import { useMutation } from 'react-query';

export interface VideoData {
  title: string;
  filename: string;
  id?: number | string;
  isFreemium?: boolean;
  isGuide?: boolean;
  folder?: string | number;
}

export interface CreateVideoResponse {
  success: boolean;
  url: string;
  html: string;
  id: string;
  videoId: string;
  data?: any;
}

interface IProgress {
  loaded: number;
  total: number;
  percentage: number;
}

const createVideo = async (
  props: VideoData,
  onProgress?: (progress: IProgress) => void
): Promise<CreateVideoResponse> => {
  const { title, filename, isFreemium, isGuide, folder, id } = props;

  const config = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      if (onProgress) {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        onProgress({
          loaded: Math.floor(loaded / 1024),
          total: Math.floor(total / 1024),
          percentage: percentCompleted,
        });
      }
    },
  };

  const response = await EXPRESS_API.post(
    `/videos`,
    {
      filename,
      title,
      isFreemium,
      isGuide: !!isGuide,
      folder: folder || 'Primary',
    },
    config
  );

  return {
    ...response.data,
    ...(id !== undefined ? { id: id, videoId: response.data.id } : {}),
  };
};

export const useCreateVideoMutation = () => {
  const [createProgress, setCreateProgress] = useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  return {
    ...useMutation((data: VideoData) => createVideo(data, setCreateProgress), {
      onSuccess: () => {
        successToast({ title: 'Video successfully created!' });
      },
      onError: () => {
        errorToast({
          title: 'Failed to create video. Please try again later.',
        });
      },
    }),
    progress: createProgress,
  };
};
