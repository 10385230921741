import * as React from 'react';
import styled from 'styled-components/macro';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import revshareicon from 'lib/images/revshareicon.png';
import earnupicon from 'lib/images/earnupicon.png';
import brandguidelinesicon from 'lib/images/brandguidelinesicon.png';
import earnupicondealerservices from 'lib/images/earnupicondealerservices.png';
import revshareicondealerservices from 'lib/images/revshareicondealerservices.png';
import brandguidelinesicondealerservices from 'lib/images/brandguidelinesicondealerservices.png';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { useTheme } from 'styled-components/macro';

interface IPerkListItem {
  id: number;
  description: string;
  covideoIcon: string;
  automotiveIcon: string;
}

const perksListItems: IPerkListItem[] = [
  {
    id: 1,
    description:
      'Get automatically approved and earn up to 30% recurring rev-share!',
    covideoIcon: earnupicon,
    automotiveIcon: earnupicondealerservices,
  },
  {
    id: 2,
    description: 'Starting rev-share is 15%',
    covideoIcon: revshareicon,
    automotiveIcon: revshareicondealerservices,
  },
  {
    id: 3,
    description:
      'Get access to our marketing materials, brand guidelines, and all other content that can help you be successful at promoting Covideo.',
    covideoIcon: brandguidelinesicon,
    automotiveIcon: brandguidelinesicondealerservices,
  },
];

const SubTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.blue100};
  margin: 50px 0px;
  flex-direction: column;
`;

const IntroSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CTAWrapper = styled.div<{ justifyContent: string; margin: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'end'};
  flex-direction: row;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '0px')};
`;

const PerksList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const PerksItem = styled.div`
  display: flex;
  align-items: center;
`;

const Paragraph = styled.p<{
  margin: string;
}>`
  font-family: 'Work Sans';
  font-style: 500;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: ${theme.palette.secondaryGrayParagraph};
  margin: ${({ margin }) => (margin ? margin : '0px')};
`;
const MoreOpportunites = styled.p`
  color: ${theme.palette.gray60};
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const CtaButton = styled.a<{ width: string; background: string }>`
  width: ${({ width }) => (width ? width : '175px')};
  height: 40px;
  background-color: ${({ background }) => background};
  text-decoration: none;
  display: inline-block;
  color: ${theme.palette.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

export const Main = () => {
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const themes = useTheme();
  const buttonBackground = themes.colors.primary[100];

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <Heading color={theme.palette.covideoBlue100}>
          Covideo Affiliates
        </Heading>
        <IntroSection>
          <SubTitle>Who is an Affiliate?</SubTitle>
          <Paragraph margin='0px'>
            Independent marketers, sales reps, or anyone with a professional
            network.
          </Paragraph>
        </IntroSection>
        <PerksList>
          <SubTitle>
            What are some of the perks for Covideo affiliates?
          </SubTitle>
          {perksListItems.map(perk => {
            return (
              <PerksItem key={perk.id}>
                <img
                  key={perk.id}
                  src={isAutomotive ? perk.automotiveIcon : perk.covideoIcon}
                  alt={perk.description}
                  width={'120px'}
                  height={'120px'}
                />
                <Paragraph margin='0px 0px 0px 20px'>
                  {perk.description}
                </Paragraph>
              </PerksItem>
            );
          })}
        </PerksList>
        <CTAWrapper justifyContent='end' margin='0px'>
          <CtaButton
            width='175px'
            href='https://covideo.firstpromoter.com/signup/15594'
            background={buttonBackground}
          >
            Join Now
          </CtaButton>
        </CTAWrapper>
        <CTAWrapper justifyContent='space-between' margin='50px 0px 0px 0px'>
          <SubTitle>
            Already a Covideo affiliate with a FirstPromoter account?
          </SubTitle>
          <CtaButton
            width='175px'
            href='https://covideo.firstpromoter.com/login'
            background={buttonBackground}
          >
            Login Now
          </CtaButton>
        </CTAWrapper>
        <MoreOpportunites>
          Learn more about our other partnership opportunities{' '}
          <a
            href={
              isAutomotive
                ? 'https://dealerservices.covideo.com/partner/'
                : 'https://www.covideo.com/partners/'
            }
          >
            here
          </a>
          .
        </MoreOpportunites>
      </MainWrapper>
    </Container>
  );
};
