import myTeamIcon from 'lib/images/freemiumOnboarding/my-team-icon.png';
import myselfIcon from 'lib/images/freemiumOnboarding/myself-icon.png';
import hrIcon from 'lib/images/freemiumOnboarding/hr-icon.png';
import salesIcon from 'lib/images/freemiumOnboarding/sales-icon.png';
import supportIcon from 'lib/images/freemiumOnboarding/support-icon.png';
import marketingIcon from 'lib/images/freemiumOnboarding/marketing-icon.png';
import fundraisingIcon from 'lib/images/freemiumOnboarding/fundraising-icon.png';
import dealershipIcon from 'lib/images/freemiumOnboarding/dealership-icon.png';
import educationIcon from 'lib/images/freemiumOnboarding/education-icon.png';
import otherIcon from 'lib/images/freemiumOnboarding/other-icon.png';
import classmatesIcon from 'lib/images/freemiumOnboarding/classmates-icon.png';
import familyIcon from 'lib/images/freemiumOnboarding/family-icon.png';
import colleaguesIcon from 'lib/images/freemiumOnboarding/colleagues-icon.png';
import clientsIcon from 'lib/images/freemiumOnboarding/clients-icon.png';
import wontShareIcon from 'lib/images/freemiumOnboarding/wont-share-icon.png';
import { SelectorItem } from './types';

export const freemiumOboardingFlows: SelectorItem[] = [
  {
    value: 'Business',
    iconSrc: myTeamIcon,
  },
  {
    value: 'Personal',
    iconSrc: myselfIcon,
  },
];

export const covideoUsageOptionsForMyTeams: SelectorItem[] = [
  {
    value: 'Dealership',
    iconSrc: dealershipIcon,
  },
  {
    value: 'Sales',
    iconSrc: salesIcon,
  },
  {
    value: 'Support',
    iconSrc: supportIcon,
  },
  {
    value: 'Marketing',
    iconSrc: marketingIcon,
  },
  {
    value: 'Education',
    iconSrc: educationIcon,
  },
  {
    value: 'HR',
    iconSrc: hrIcon,
  },
  {
    value: 'Fundraising',
    iconSrc: fundraisingIcon,
  },
  {
    value: 'Other',
    iconSrc: otherIcon,
  },
];

export const covideoUsageOptionsForMyself: SelectorItem[] = [
  ...covideoUsageOptionsForMyTeams,
];

export const shareVideoOptionsForMyself: SelectorItem[] = [
  {
    value: 'Classmates',
    iconSrc: classmatesIcon,
  },
  {
    value: 'Family',
    iconSrc: familyIcon,
  },
  {
    value: 'Colleagues',
    iconSrc: colleaguesIcon,
  },
  {
    value: 'Clients',
    iconSrc: clientsIcon,
  },
  {
    value: "I won't share",
    iconSrc: wontShareIcon,
  },
  {
    value: 'Other',
    iconSrc: otherIcon,
  },
];

export const companySizeOptionsForCds: string[] = [
  '1-4',
  '5-10',
  '11-20',
  '21-50',
  '51-100',
  '100+',
];

export const companySizeOptions: string[] = [
  'Just me',
  ...companySizeOptionsForCds,
];

export const INDUSTRIES = {
  AUTOMOTIVE: 'automotive',
  B2B_SALES: 'b2b sales',
  EDUCATION: 'education',
  REAL_ESTATE: 'real estate',
  SENIOR_LIVING: 'senior living',
  OTHER: 'other',
};

export const CDS_DEPARTMENTS = {
  SALES: 'Sales',
  SERVICE: 'Service',
  BDC: 'BDC',
  OTHER: 'Other',
};

export const cdsDepartmentOptions = [
  {
    value: CDS_DEPARTMENTS.SALES,
    label: CDS_DEPARTMENTS.SALES,
  },
  {
    value: CDS_DEPARTMENTS.SERVICE,
    label: CDS_DEPARTMENTS.SERVICE,
  },
  {
    value: CDS_DEPARTMENTS.BDC,
    label: CDS_DEPARTMENTS.BDC,
  },
  {
    value: CDS_DEPARTMENTS.OTHER,
    label: CDS_DEPARTMENTS.OTHER,
  },
];

export const covideoIndustryOptions = [
  {
    value: INDUSTRIES.AUTOMOTIVE,
    label: 'Automotive',
  },
  {
    value: INDUSTRIES.B2B_SALES,
    label: 'B2B Sales',
  },
  {
    value: INDUSTRIES.EDUCATION,
    label: 'Education',
  },
  {
    value: INDUSTRIES.REAL_ESTATE,
    label: 'Real Estate/Mortgage',
  },
  {
    value: INDUSTRIES.SENIOR_LIVING,
    label: 'Senior Living',
  },
  {
    value: INDUSTRIES.OTHER,
    label: 'Other',
  },
];

export const ANDROID_GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=covideo.com&feature=nav_result#?t=W251bGwsMSwyLDNd';
export const IOS_APP_STORE_URL =
  'https://itunes.apple.com/us/app/covideo/id507458417?mt=8';
export const browserExtensionURL =
  'https://chrome.google.com/webstore/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
export const MOBILE_COVIDEO_URL = 'https://mobile.covideo.com';
