import { Search } from 'lib/components';
import { Container, Gap, MainWrapper } from 'lib/components/styles/layout';
import { useAuth } from 'lib/context';
import React, { useState } from 'react';
import { Heading } from 'lib/components/styles/typography';
import { HeaderContainer } from '../../components/styles';
import { GuidesCategorized } from './GuidesCategorized';
import { Guides } from './Guides';
import { ReorderCategoryModal } from '../../modals/ReorderCategoryModal';
import { RenameCategoryModal } from '../../modals/RenameCategoryModal';
import { DeleteCategoryModal } from '../../modals/DeleteCategoryModal';
import { AddGuideModal } from '../../modals/AddGuideModal';
import { AddCategoryModal } from '../../modals/AddCategoryModal';
import { VisibilityModal } from '../../modals/VisibilityModal';
import { VisibilityContextProvider } from '../../provider/context';
import { checkGuideCategoriesAdministrationAccess } from 'lib/utils/productFeature';
import { SelectedGuideCategoryType } from './constants';
import { Button } from 'react-covideo-common';
import { AiOutlinePlus } from 'react-icons/ai';

export type ModalsType =
  | 'reorder'
  | 'rename'
  | 'delete'
  | 'add-category'
  | 'add-guides'
  | 'visibility'
  | 'delete-guides'
  | '';

export const MainGuidePage = () => {
  const { userData } = useAuth();
  const showGuideButtons = checkGuideCategoriesAdministrationAccess(userData);
  const [search, setSearch] = useState('');
  const [modals, setModals] = useState<ModalsType>('');
  // we removed setting state so we can later pass a prop to express-api
  const [showOnlyCompanyGuides] = useState(false);

  const [selectedGuideCategory, setSelectedGuideCategory] =
    useState<SelectedGuideCategoryType>(null);

  const closeModalHandler = () => {
    setModals('');
    setSelectedGuideCategory(null);
  };

  return (
    <Container>
      <MainWrapper resetPadding>
        <HeaderContainer>
          <Heading>Guides</Heading>
          <Gap gap='8px'>
            <Search
              width='240px'
              onSearch={(search: string) => setSearch(search)}
              placeholder='Search guides'
              prevSearch={search}
            />
            {showGuideButtons && (
              <Gap gap='8px'>
                <Button
                  text='Reorder'
                  variant='secondary'
                  onClick={() => setModals('reorder')}
                />
                <Button
                  onClick={() => setModals('add-category')}
                  text='Category'
                  icon={<AiOutlinePlus size={18} />}
                  variant='secondary'
                />
                <Button
                  onClick={() => setModals('add-guides')}
                  text='Guide'
                  variant='primary'
                  icon={<AiOutlinePlus size={18} color='white' />}
                />
              </Gap>
            )}
          </Gap>
        </HeaderContainer>
        {search === '' ? (
          <GuidesCategorized
            setSelectedGuideCategory={setSelectedGuideCategory}
            setModals={setModals}
            showOnlyCompanyGuides={showOnlyCompanyGuides}
          />
        ) : (
          <Guides search={search} setSearch={setSearch} setModals={setModals} />
        )}
        {modals === 'reorder' && (
          <ReorderCategoryModal closeModalHandler={closeModalHandler} />
        )}
        {modals === 'rename' && (
          <RenameCategoryModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}
        {modals === 'add-category' && (
          <AddCategoryModal closeModalHandler={closeModalHandler} />
        )}
        {modals === 'delete' && (
          <DeleteCategoryModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}
        {modals === 'add-guides' && (
          <AddGuideModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}
        {modals === 'visibility' && (
          <VisibilityContextProvider>
            <VisibilityModal
              selectedGuideCategory={selectedGuideCategory}
              closeModalHandler={closeModalHandler}
            />
          </VisibilityContextProvider>
        )}
      </MainWrapper>
    </Container>
  );
};
