import styled from 'styled-components/macro';
import { ReactComponent as PlaySvg } from './PlayButton.svg';

const PlayIcon = styled(PlaySvg)`
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '40px'};
  path {
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default PlayIcon;
