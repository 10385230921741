import React from 'react';
import styled from 'styled-components/macro';
import { LeadListing } from '../components';

const Container = styled.div`
  padding: 64px 0;
  width: 916px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 200px);
`;

const Content = styled.div`
  margin-top: 32px;
`;

export const List = () => {
  return (
    <Container>
      <Content>
        <LeadListing />
      </Content>
    </Container>
  );
};
