export enum APP_ENVIROMENT {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
  SUSTAINING = 'sustaining',
}
export enum API_EXPRESS_URLS {
  DEVELOPMENT = 'https://localhost:3000/api/',
  STAGING = 'https://api-staging.covideo.com/',
  SANDBOX = 'https://api-sandbox.covideo.com/',
  PRODUCTION = 'https://api.covideo.com/',
  SUSTAINING = 'https://api-sustaining.covideo.com/',
}

export type CurrentEnvProps = {
  defaultValue: string;
  staging?: string;
  sandbox?: string;
  sustaining?: string;
  development?: string;
  production?: string;
};

export enum COOKIE_NAME {
  JWT = 'jwt',
  REFRESH_JWT = 'refreshJwt',
}
