import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DocumentHead, MainContainer, PageTemplate } from 'lib/components';
import { useAuth } from 'lib/context';
import { Container, Wrapper } from './styles';
import { Video } from '../components';
import { BackToIntegrations } from '../components/BackToIntegrations';
import { Gap } from 'lib/components/styles/layout';
import { IoMdLaptop, IoMdMail } from 'react-icons/io';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { IntegrationHeader } from '../components/IntegrationHeader';
import { INTEGRATION_TYPE } from '../main/constants';
import { useIntegrationTypeFilter } from '../main/useIntegrationFilterType';
import { Button } from 'react-covideo-common';
import { Description } from 'app/pages/library/components/boardModal/style';

export enum ActionTypes {
  CONTACT = 'contact',
  INSTALL = 'install',
  DEMO = 'demo',
  DOWNLOAD = 'download',
}

interface IntegrationDetailsPageProps {
  onClick?: any;
  actionType?: ActionTypes;
  connectSocialProfile?: boolean;
  btnText?: string;
}

export const IntegrationDetailsPage = ({
  onClick,
  actionType,
  connectSocialProfile = false,
  btnText,
}: IntegrationDetailsPageProps) => {
  const history = useHistory();
  const location = useLocation();
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const slug = location.pathname.split('/')[2];
  const filter = useIntegrationTypeFilter();

  const [showContactForm, setShowContactForm] = useState(false);

  const integration = (
    isAutomotive
      ? filter([INTEGRATION_TYPE.AUTOMOTIVE])
      : filter([INTEGRATION_TYPE.UNIVERSAL])
  ).find(ig => ig.key === slug);

  const getActionButton = () => {
    switch (actionType) {
      case ActionTypes.CONTACT:
        return (
          <Button
            text={btnText || 'Request integration'}
            icon={<IoMdMail />}
            onClick={() => {
              setShowContactForm(true);
            }}
          />
        );
      case ActionTypes.INSTALL:
        return <Button text='Install' onClick={onClick} />;
      case ActionTypes.DEMO:
        return (
          <Button
            text='Reach Out to Covideo'
            icon={<IoMdLaptop />}
            onClick={() => {
              setShowContactForm(true);
            }}
          />
        );
      case ActionTypes.DOWNLOAD:
        return <Button text='Download' onClick={onClick} />;
      default:
        <></>;
    }
  };

  return (
    <>
      <DocumentHead title='Integration' />
      <PageTemplate
        modal={[]}
        main={
          <MainContainer>
            <Container>
              {integration && (
                <Wrapper>
                  {showContactForm && (
                    <ModalContactAM
                      handleModalClose={() => setShowContactForm(false)}
                      subject={`${integration.name} Integration`}
                    />
                  )}
                  <Gap flexDirection='row' justifyContent='space-between'>
                    <BackToIntegrations />
                    <Gap>
                      {getActionButton()}
                      {connectSocialProfile && (
                        <Button
                          text='Connect your social profile'
                          onClick={() =>
                            history.push('/profile/social-profiles')
                          }
                        />
                      )}
                    </Gap>
                  </Gap>
                  <IntegrationHeader
                    imgSrc={integration?.imgSrc || ''}
                    title={integration?.name || ''}
                  />
                  <Gap flexDirection='column' width='720px' gap='24px'>
                    {integration.videoSource && (
                      <Video
                        thumbnail={integration.thumbnail || ''}
                        videoSource={integration.videoSource}
                      />
                    )}
                    <Description>{integration.description}</Description>
                  </Gap>
                </Wrapper>
              )}
            </Container>
          </MainContainer>
        }
      />
    </>
  );
};
