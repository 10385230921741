import styled from 'styled-components/macro';
import { ReactComponent as ImageSvg } from './icon-green-check.svg';

const ShowGreenCheckIcon = styled(ImageSvg)`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '17px'};
  fill: ${props => props.color || 'currentColor'};
`;

export default ShowGreenCheckIcon;
