import { ReportTypes, reportTypes } from './ReportData';

export type TableFieldType = {
  value: string;
  label: string;
  type: string;
  defaultOrder: string;
};

export const CUSTOM_TABLE_COLUMNS_KEY = 'CustomerUsageCustomTableFields';
export const ALL_USERS = ['all'];
export const DEFAULT_REPORTS = [
  reportTypes.RECORDED,
  reportTypes.SENT,
  reportTypes.VIEWS,
  reportTypes.CTA,
  reportTypes.LAST_LOGIN,
];
export const order = {
  ASC: 'asc',
  DESC: 'desc',
};
export const fieldValues = {
  NAME: 'name',
  RECORDED: 'recorded',
  SENT_EMAILS: 'sentEmails',
  VIEWS: ReportTypes.VIEWS,
  CLICKS: ReportTypes.CTA,
  LAST_NAME: 'lastName',
  LAST_LOGIN: 'loginDate',
  LOGIN_AS: 'loginAs',
  ENGAGEMENT: ReportTypes.ENGAGEMENT,
  OPENED: ReportTypes.OPENED,
  DELIVERED: ReportTypes.DELIVERED,
  DEFERRED: ReportTypes.DEFERRED,
  SPAM: ReportTypes.SPAM,
  BOUNCED: ReportTypes.BOUNCED,
  DROPPED: ReportTypes.DROPPED,
  UNSUBSCRIBED: ReportTypes.UNSUBSCRIBED,
  AVERAGE_VIDEO_DURATION: ReportTypes.AVERAGE_VIDEO_DURATION,
};
export const fixedTableFields = {
  name: {
    value: fieldValues.NAME,
    label: 'Name',
    type: 'string',
    orderKey: fieldValues.LAST_NAME,
    defaultOrder: order.ASC,
  },
  loginAs: {
    value: fieldValues.LOGIN_AS,
    label: '',
  },
};
export const allDynamicTableFields: TableFieldType[] = [
  {
    value: fieldValues.RECORDED,
    label: 'Recorded',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.SENT_EMAILS,
    label: 'Sent',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.VIEWS,
    label: 'Views',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.CLICKS,
    label: 'CTA Clicks',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.LAST_LOGIN,
    label: 'Last Login',
    type: 'string',
    defaultOrder: order.ASC,
  },
  {
    value: fieldValues.ENGAGEMENT,
    label: 'Engagement',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.OPENED,
    label: 'Opened',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DELIVERED,
    label: 'Delivered',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DEFERRED,
    label: 'Deferred',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.SPAM,
    label: 'Spam',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.BOUNCED,
    label: 'Bounced',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DROPPED,
    label: 'Dropped',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.UNSUBSCRIBED,
    label: 'Unsubscribed',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.AVERAGE_VIDEO_DURATION,
    label: 'Avg. Video Duration',
    type: 'number',
    defaultOrder: order.DESC,
  },
];
// Fix report and custom column mismatch
export const tableFieldToReportTypeMapper = {
  [fieldValues.RECORDED]: ReportTypes.RECORDED,
  [fieldValues.SENT_EMAILS]: ReportTypes.SENT,
  [fieldValues.LAST_LOGIN]: ReportTypes.LAST_LOGIN,
};
