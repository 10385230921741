import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const BadgeContainer = styled.div<{ isPrivate?: boolean }>`
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  border-radius: 6px;
  background: ${({ isPrivate }) =>
    isPrivate
      ? theme.palette.destructiveBg
      : theme.palette.covideoBlue02NonAlpha};
  color: ${({ isPrivate }) =>
    isPrivate ? theme.palette.tomatoRed : theme.palette.covideoBlue80};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-right: auto;
`;

type ItemProps = {
  isPrivate?: boolean;
};

const CategoryBadge = ({ isPrivate }: ItemProps) => {
  return (
    <BadgeContainer isPrivate={isPrivate}>
      {isPrivate ? 'Private' : 'Public'}
    </BadgeContainer>
  );
};

export default CategoryBadge;
