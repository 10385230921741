import { RouteComponentProps, StaticContext } from 'react-router';

export enum INTEGRATION {
  SALESFORCE = 'Salesforce',
  ELEMENT_451 = 'Element451',
  HUBSPOT = 'HubSpot',
  CHROME = 'Chrome',
  GMAIL = 'Gmail',
  LINKEDIN = 'LinkedIn',
  OFFICE_365 = 'Office 365',
  OUTLOOK = 'Outlook',
  ZOOM = 'Zoom',
  IOS = 'iOS',
  ANDROID = 'Android',
  FACEBOOK = 'Facebook',
  YOUTUBE = 'YouTube',
  DEALERSOCKET = 'DealerSocket',
  ELEAD = 'Elead',
  IPACKET = 'iPacket',
  PROMAX = 'ProMax',
  TMS = 'TMS',
  EDEALER = 'Edealer',
  AUTO_SWEET = 'AutoSweet',
  PREDIAN = 'Predian',
  DEALER_INSPIRE = 'DealerInspire',
  HOMENET = 'HomeNet',
  AUTOMINER = 'Autominer',
  VAUTO = 'VAuto',
  DEALERSLINK = 'Dealerslink',
  ACV_MAX = 'ACV MAX',
  AUTO_REVOLUTION = 'Auto Revolution',
  DEALER_CENTER = 'Dealer Center',
  VIN_SULUTIONS = 'VinSolutions',
  VIN_CUE = 'VinCue',
  WHEELS_TV = 'WheelsTV',
  DEALER_CLOUD = 'dealer.cloud',
  DEALER_COM = 'dealer.com',
  CREDIT700 = '700Credit',
  LESA = 'Lesa',
  VINMOTION = 'VinMotion',
  DEALER_CAR_SEARCH = 'Dealer Car Search',
  CARS_FOR_SALE = 'Cars For Sale',
}

export enum INTEGRATIONS_CATEGORIES {
  COMMUNICATIONS = 'Communications',
  CRM = 'CRM',
  MARKETING = 'Marketing',
  SOCIAL = 'Social',
}
export enum CDS_CATEGORIES {
  CONTENT = 'Content',
  DATA = 'Data',
  INVENTORY = 'Inventory',
  VEHICLE_PRESENTATION = 'Vehicle Presentation',
  FINANCE = 'Finance',
}
export enum DISCOVER_BY_FILTERS {
  FREE_TRIAL = 'Free Trial',
  FEATURED = 'Featured',
  NEWEST = 'Newest',
}

export enum INTEGRATION_TYPE {
  AUTOMOTIVE = 'automotive',
  UNIVERSAL = 'universal',
}

export interface IntegrationProps {
  key: string;
  name: string;
  categories: (
    | INTEGRATIONS_CATEGORIES
    | DISCOVER_BY_FILTERS
    | CDS_CATEGORIES
  )[];
  description: string | JSX.Element;
  path: string;
  component:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>;
  imgSrc: string;
  productFeatureId?: number;
  enabled?: boolean;
  thumbnail?: string;
  videoSource?: string;
  actionButton?: JSX.Element;
  onClick?: any;
  automotiveHidden?: boolean;
  show: INTEGRATION_TYPE[];
}

// chrome
export const CHROME_DOWNLOAD_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
export const CHROME_HOW_TO_LINK =
  'https://support.covideo.com/en/articles/4558269-how-do-i-install-the-chrome-extension';
// gmail
export const GMAIL_INSTALL_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
// linkedin
export const LINKEDIN_INSTALL_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc ';
export const LINKEDIN_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5166997-add-covideo-to-your-linkedin-account';
// outlook
export const OUTLOOK_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/3151798-outlook-add-in-installation-guide';
export const OFFICE365_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/4967720-how-to-use-covideo-in-office-365';
// zoom
export const ZOOM_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5645195-set-up-the-covideo-for-zoom-integration';
// salesforce
export const SALESFORCE_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/4859415-how-to-use-the-covideo-salesforce-integration';
// office365
export const OFFICE_365_HELP_DESK_LINK =
  'https://appsource.microsoft.com/en-us/product/office/WA200002558?tab=Overview';
// mobile iOS/Android
export const IOS_URL = 'https://apps.apple.com/us/app/covideo/id507458417';
export const MOBILE_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/4558211-how-do-i-install-the-covideo-mobile-app';
export const MOBILE_FAQ =
  'https://support.covideo.com/en/collections/1379143-mobile-app';
export const ANDROID_INSTALL_LINK =
  'https://play.google.com/store/apps/details?id=covideo.com&feature=nav_result#?t=W251bGwsMSwyLDNd';
// elead
export const ELEAD_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5397722-how-to-use-the-covideo-elead-integration';
// ipacket
export const IPACKET_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5554151-how-to-use-the-covideo-ipacket-integration';
// wheelstv
export const WHEELSTV_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/4612493-how-to-use-wheelstv-video-content-in-covideo';
// vinsolutions
export const VIN_SOLUTIONS_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5708074-how-to-use-the-covideo-and-vinsolutions-integration';
