import React from 'react';
import styled from 'styled-components/macro';
import { Dropdown, ButtonSwitch } from 'lib/components';
import {
  hoursOptions,
  minutesOptions,
  timeFormats,
  timezonesOptions,
} from './utils';
import { Gap } from 'lib/components/styles/layout';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../types';

const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  > svg {
    position: absolute;
    margin: 12px 8px;
  }
`;

const LabelContainerWrapper = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  height: 38px;
`;

const LabelContainer = styled.div`
  width: 250px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const CustomReportPreferedDeliveryTime = () => {
  const { values, setFieldValue } =
    useFormikContext<CustomReportModalFormikValues>();

  const recurring = values.recurrings;

  const DELIVERY_TIME_ARRAY = recurring.deliveryTime?.split(';');

  const HOURS: string = DELIVERY_TIME_ARRAY[0]?.split(':')[0] || '10';
  const MINUTES: string = DELIVERY_TIME_ARRAY[0]?.split(':')[1] || '00';
  const TIMEFORMAT = DELIVERY_TIME_ARRAY[1] || 'AM';
  const TIMEZONE =
    timezonesOptions.find(option => option.value === DELIVERY_TIME_ARRAY[2]) ||
    timezonesOptions[0];

  const setHoursHandler = (hours: { value: string; label: string }) => {
    setFieldValue(
      'recurrings.deliveryTime',
      `${hours.value}:${MINUTES};${TIMEFORMAT};${TIMEZONE.value};${TIMEZONE.offset}`
    );
  };

  const setMinutsHandler = (minutes: { value: string; label: string }) => {
    setFieldValue(
      'recurrings.deliveryTime',
      `${HOURS}:${minutes.value};${TIMEFORMAT};${TIMEZONE.value};${TIMEZONE.offset}`
    );
  };

  const setTimeformatHandler = (timeformat: string) => {
    setFieldValue(
      'recurrings.deliveryTime',
      `${HOURS}:${MINUTES};${timeformat};${TIMEZONE.value};${TIMEZONE.offset}`
    );
  };

  const setTimezoneHandler = (timezone: {
    value: string;
    label: string;
    offset: number;
    utc: string[];
  }) => {
    setFieldValue(
      'recurrings.deliveryTime',
      `${HOURS}:${MINUTES};${TIMEFORMAT};${timezone.value};${timezone.offset}`
    );
  };
  const CustomValueContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    return (
      <LabelContainerWrapper>
        <LabelContainer>{children}</LabelContainer>
      </LabelContainerWrapper>
    );
  };

  return (
    <div>
      <Label>Preferred Delivery Time</Label>
      <Gap gap='8px' flexWrap='nowrap'>
        <TimeContainer>
          <Dropdown
            icon='checkmark'
            ariaLabel='Choose hours'
            creatable={false}
            value={{ value: HOURS, label: HOURS }}
            onChange={newValue => setHoursHandler(newValue)}
            options={hoursOptions}
            extendStyles={{
              container: { width: 70 },
              menu: { width: 95 },
            }}
          />
          :
          <Dropdown
            icon='checkmark'
            ariaLabel='Choose minutes'
            creatable={false}
            defaultValue={minutesOptions[0]}
            value={{ value: MINUTES, label: MINUTES }}
            onChange={newValue => setMinutsHandler(newValue)}
            options={minutesOptions}
            extendStyles={{
              container: { width: 70 },
              menu: { width: 85 },
            }}
          />
        </TimeContainer>
        <Gap>
          <ButtonSwitch
            defaultValue={TIMEFORMAT}
            values={timeFormats}
            onChange={newValue => setTimeformatHandler(newValue)}
          />
        </Gap>
        <Dropdown
          placeholder='Timezone'
          value={TIMEZONE}
          creatable={false}
          onChange={newValue => {
            setTimezoneHandler(newValue);
          }}
          options={timezonesOptions}
          extendStyles={{
            control: { maxHeight: 40, flexWrap: 'wrap', width: '100%' },
            container: { width: 313 },
          }}
          isSearchable={false}
          customComponents={{ ValueContainer: CustomValueContainer }}
        />
      </Gap>
    </div>
  );
};
