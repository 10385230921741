import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import DeleteIcon from 'lib/images/DeleteIcon';
import {
  Search,
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
  HoverPopup,
  SelectionHeaderPopup,
} from 'lib/components';
import Select from 'react-select';
import { useAuth } from 'lib/context';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { MdCheck } from 'react-icons/md';
import { RiPencilFill } from 'react-icons/ri';
import { Button } from 'react-covideo-common';
import { getDepartments } from 'lib/api';
import { ModalDeleteSnippets } from '.';
import { SharedWith } from 'lib/const';
import { useQuerySnippets } from 'lib/api/snippets/useQuerySnippets';
import { useDeleteSnippetsMutation } from 'lib/api/snippets/useDeleteSnippetsMutation';

const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
`;
const Filter = styled.div`
  margin-right: 12px;
  min-width: 215px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CheckWrapper = styled.div`
  margin-right: 16px;
`;
const TableCell = styled.div`
  width: auto;
  text-align: left;
  display: flex;
  position: relative;
`;
const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;
const TickWrapper = styled.div`
  background: rgba(0, 27, 83, 0.05);
  width: 20px;
  height: 20px;
  line-height: 1.5;
  padding: 4px 3px 4px 5px;
  border-radius: 4px;
`;

const EmptyList = styled.p`
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DepartmentName = styled.span`
  display: block;
  margin-bottom: 6px;
`;

const tableFields = [
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'sharedWith',
    label: 'Shared with',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'edit',
    label: '',
  },
];

const columnWidths = [450, 150, 45, 45, 20];

const sharedWithOptions = Object.values(SharedWith)
  .filter((sharedWith: string) => !!sharedWith)
  .map((sharedWith: string) => {
    return {
      value: sharedWith,
      label: sharedWith,
    };
  });

type Props = {
  handleUpdateSnippet: (id: string) => void;
};

export const SnippetListing = ({ handleUpdateSnippet }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sharedWith, setSharedWith] = useState('');
  const [sort, setSort] = useState('');
  const [selectedSnippet, setSelectedSnippet] = useState<string[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<{
    [key: number]: string;
  }>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const queryParams = {
    limit: size,
    start: page * size,
    sort,
    search: searchQuery,
    sharedWith,
  };

  const { data: snippets, isLoading } = useQuerySnippets(queryParams);
  const { mutateAsync: deleteSnippets } = useDeleteSnippetsMutation(
    [...selectedSnippet],
    queryParams
  );

  const {
    userData: { customerId },
  } = useAuth();

  const getDepartmentOptions = async () => {
    const response = await getDepartments(customerId, {}).catch(err => err);
    const departments: { [key: number]: string } = {};
    (response?.data || []).forEach((d: any) => {
      departments[d['DepartmentID']] = d['Name'];
    });
    setDepartmentOptions(departments);
  };

  useEffect(() => {
    getDepartmentOptions();
  }, [page, size, sort, searchQuery, sharedWith]);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedSnippet([]);
    }
  };

  const sortElement = (sortParam: any) => {
    if (!['title'].includes(sortParam)) {
      return;
    }
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}`;
      setSort(newSort);
    }
  };

  const handleDeleteClick = async () => {
    await deleteSnippets();
    getDepartmentOptions();
    setSelectedSnippet([]);
    setShowDeleteModal(false);
  };

  return (
    <>
      {!!selectedSnippet?.length && (
        <SelectionHeaderPopup left='208px'>
          <CheckboxSelectionWrapper>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedSnippet.length > 0 &&
                snippets?.snippets.length !== selectedSnippet.length
              }
              checked={snippets?.snippets.length == selectedSnippet.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                let { checked } = event.target as HTMLInputElement;
                if (checked)
                  setSelectedSnippet(
                    snippets?.snippets.map((e: any) => e.snippetId)
                  );
                else setSelectedSnippet([]);
              }}
            />
            <SelectionCountWrap>
              <SelectionCountText>Templates selected:</SelectionCountText>
              <b>{selectedSnippet.length}</b>
            </SelectionCountWrap>
          </CheckboxSelectionWrapper>
          <div>
            <Button
              data-cy='template-delete-button'
              variant='destructive'
              onClick={() => setShowDeleteModal(true)}
              text='Delete'
              icon={<DeleteIcon color={theme.palette.buttonDelete} />}
            ></Button>
          </div>
        </SelectionHeaderPopup>
      )}
      <FiltersContainer>
        <Filter>
          <Search placeholder='Search templates...' onSearch={onSearch} />
        </Filter>
        <Filter>
          <Select
            styles={{
              control: (base: any) => ({ ...base, height: '40px' }),
              indicatorSeparator: () => ({ display: 'none' }),
              menuPortal: provided => ({
                ...provided,
                zIndex: 5,
              }),
            }}
            options={sharedWithOptions}
            menuPortalTarget={document.body}
            menuPlacement={'bottom'}
            value={sharedWithOptions.find(o => {
              return o.value === sharedWith;
            })}
            onChange={(option: any) => {
              setPage(0);
              if (option) {
                setSharedWith(option.value);
              } else {
                setSharedWith('');
              }
            }}
            isSearchable={false}
            placeholder={'Shared with ...'}
            isClearable
          />
        </Filter>
      </FiltersContainer>
      {snippets?.snippets?.length && !isLoading ? (
        <TableContextProvider
          total={snippets?.count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <Table
            dataCy={'template-table'}
            columnWidths={columnWidths}
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map(item => {
                return (
                  <TableCell onClick={() => sortElement(item.value)}>
                    {item.label}
                    {item.value == 'title' ? (
                      <SortCell>
                        <IoMdArrowRoundUp
                          className={sort == `${item.value}` ? 'active' : ''}
                        />
                        <IoMdArrowRoundDown
                          className={sort == `-${item.value}` ? 'active' : ''}
                        />
                      </SortCell>
                    ) : null}
                  </TableCell>
                );
              }),
            ]}
            hoverable={false}
            fixColumnIndex='left'
            rows={snippets?.snippets?.map((snippet: any, index: number) => ({
              key: index,
              columns: [
                ...tableFields?.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      {item.value === 'title' ? (
                        <TitleWrapper>
                          <CheckWrapper>
                            <CheckboxInput
                              dataCy='template-checkbox'
                              width='24px'
                              blueCheck={true}
                              checked={selectedSnippet.includes(
                                snippet.snippetId
                              )}
                              onChange={(e: React.SyntheticEvent) => {
                                let { checked } = e.target as HTMLInputElement;
                                if (checked)
                                  setSelectedSnippet([
                                    ...selectedSnippet,
                                    snippet.snippetId,
                                  ]);
                                else
                                  setSelectedSnippet(
                                    selectedSnippet.filter(
                                      (e: any) => e != snippet.snippetId
                                    )
                                  );
                              }}
                            />
                          </CheckWrapper>
                          {snippet[item.value]?.length > 35
                            ? snippet[item.value]?.substring(0, 35) + '...'
                            : snippet[item.value]}
                          {/* SUS-796 changes */}
                        </TitleWrapper>
                      ) : item.value === 'sms' || item.value === 'email' ? (
                        <TickWrapper>
                          {snippet[item.value] ? <MdCheck /> : null}
                        </TickWrapper>
                      ) : item.value === 'sharedWith' &&
                        snippet[item.value] === 'Department' &&
                        snippet.departments?.length == 1 ? (
                        <>
                          {snippet.departments?.map((d: any, index: number) => (
                            <span key={index}>{departmentOptions[d.id]}</span>
                          ))}
                        </>
                      ) : item.value === 'sharedWith' &&
                        snippet[item.value] === 'Department' &&
                        snippet.departments.length > 1 ? (
                        <HoverPopup
                          width='300px'
                          position='top'
                          padding='8px 12px'
                          popup={
                            <>
                              {snippet.departments.map(
                                (d: any, index: number) => (
                                  <DepartmentName key={index}>
                                    {departmentOptions[d.id]}
                                    {index + 1 < snippet.departments.length &&
                                      ', '}
                                  </DepartmentName>
                                )
                              )}
                            </>
                          }
                          hoverable={
                            <>{snippet.departments.length} Departments</>
                          }
                        />
                      ) : item.value === 'edit' ? (
                        <HoverPopup
                          width='100px'
                          position='top'
                          padding='8px 12px'
                          popup={<>Edit template</>}
                          hoverable={
                            <RiPencilFill
                              data-cy='template-edit-button'
                              onClick={() =>
                                handleUpdateSnippet(snippet.snippetId)
                              }
                              size={20}
                              color={theme.palette.primaryDarkBlue}
                            />
                          }
                        />
                      ) : (
                        <>{snippet[item.value]}</>
                      )}
                    </TableCell>
                  );
                }),
              ],
              onClick: () => {},
            }))}
          />
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show templates:' />
          </TableFooter>
        </TableContextProvider>
      ) : !snippets?.snippets?.length && !isLoading ? (
        <EmptyList>No templates to show.</EmptyList>
      ) : (
        <LoadingIndicator isLoading={isLoading} height='500px' />
      )}

      {showDeleteModal && (
        <ModalDeleteSnippets
          count={selectedSnippet?.length}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickPrimaryButton={handleDeleteClick}
          onClickSecondaryButton={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};
