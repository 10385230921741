import { useHistory } from 'react-router-dom';
import { ActivateSMS, DeactivateSMS, GetSMSStats } from 'lib/api/addonsApi';
import { Button } from 'react-covideo-common';
import { ModalConfirmSubscription } from 'lib/components/modal/ModalConfirmSubscription';
import React from 'react';
import { MdDone } from 'react-icons/md';
import {
  ActiveHeader,
  ActiveOptionContainer,
  AddonFooter,
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  OptionContainer,
  Options,
  PageContainer,
  PageDescription,
  PageHeader,
  PopularHeader,
  PopularOptionContainer,
} from '../components/PageElements';
import { ModalActivateSubscription } from 'lib/components/modal/ModalActivateSubscription';
import { useAuth } from 'lib/context';
import { useTheme } from 'styled-components/macro';
import { successToast } from 'lib/components/toasts/success';

enum optionSelections {
  None = 0,
  SMS2500 = 1,
  SMS5k = 2,
  SMS7k = 3,
}

export const ManageSMSAddon = () => {
  const [selectedOption, setSelectedOption] = React.useState(
    optionSelections.None
  );

  const [showUpgradePackage, setShowUpgradePackage] =
    React.useState<optionSelections>();
  const [activateModalTitle, setActivateModalTitle] = React.useState('');

  const history = useHistory();

  const {
    userData: { trialAccount },
  } = useAuth();
  const themes = useTheme();

  React.useEffect(() => {
    GetSMSStats().then(data => {
      setSelectedOption(data.smsPackage);
    });
  }, []);

  const option2500 = (
    <>
      <label>
        <b>2,500 sms</b>/mo
      </label>
      <label>$25/mo</label>
      <Button
        text={
          selectedOption === optionSelections.SMS2500
            ? 'Deactivate'
            : selectedOption === optionSelections.None
              ? 'Activate'
              : 'Downgrade'
        }
        variant={
          selectedOption === optionSelections.SMS2500
            ? 'red'
            : selectedOption === optionSelections.None
              ? 'transparent'
              : 'ghost'
        }
        onClick={async () => {
          if (selectedOption === optionSelections.None && trialAccount) {
            setActivateModalTitle('SMS');
          } else if (selectedOption === optionSelections.SMS2500) {
            setShowUpgradePackage(optionSelections.None);
          } else {
            setShowUpgradePackage(optionSelections.SMS2500);
          }
        }}
      />
    </>
  );

  const option5k = (
    <>
      <label>
        <b>5,000 sms</b>/mo
      </label>
      <label>$50/mo</label>
      <Button
        text={
          selectedOption === optionSelections.None
            ? 'Activate'
            : selectedOption === optionSelections.SMS2500
              ? 'Upgrade'
              : selectedOption == optionSelections.SMS5k
                ? 'Deactivate'
                : 'Downgrade'
        }
        variant={selectedOption === optionSelections.SMS5k ? 'red' : 'primary'}
        onClick={async () => {
          if (
            (selectedOption === optionSelections.None ||
              selectedOption === optionSelections.SMS2500) &&
            trialAccount
          ) {
            setActivateModalTitle('SMS');
          } else if (selectedOption === optionSelections.SMS5k) {
            setShowUpgradePackage(optionSelections.None);
          } else {
            setShowUpgradePackage(optionSelections.SMS5k);
          }
        }}
      />
    </>
  );

  const option10k = (
    <>
      <label>
        <b>7,500 sms</b>/mo
      </label>
      <label>$75/mo</label>
      <Button
        text={
          selectedOption === optionSelections.None
            ? 'Activate'
            : selectedOption === optionSelections.SMS2500 ||
                selectedOption === optionSelections.SMS5k
              ? 'Upgrade'
              : 'Deactivate'
        }
        variant={selectedOption === optionSelections.SMS7k ? 'red' : 'primary'}
        onClick={async () => {
          if (
            (selectedOption === optionSelections.None ||
              selectedOption === optionSelections.SMS2500 ||
              selectedOption === optionSelections.SMS5k) &&
            trialAccount
          ) {
            setActivateModalTitle('SMS');
          } else if (selectedOption === optionSelections.SMS7k) {
            setShowUpgradePackage(optionSelections.None);
          } else {
            setShowUpgradePackage(optionSelections.SMS7k);
          }
        }}
      />
    </>
  );

  return (
    <>
      {showUpgradePackage !== undefined && (
        <ModalConfirmSubscription
          subscriptionType={1}
          oldSelection={selectedOption}
          newSelection={showUpgradePackage}
          handleModalClose={() => {
            setShowUpgradePackage(undefined);
          }}
          handleModalConfirm={async () => {
            let newPackage = showUpgradePackage;
            setShowUpgradePackage(undefined);
            setSelectedOption(newPackage);

            if (newPackage === 0) {
              await DeactivateSMS().then(data => {
                successToast({
                  title: data,
                });
              });
            } else {
              await ActivateSMS(newPackage).then(data => {
                successToast({
                  title: data,
                });
              });
            }
          }}
        />
      )}
      {activateModalTitle.length ? (
        <ModalActivateSubscription
          handleModalClose={() => setActivateModalTitle('')}
          handleSubmit={() => history.push('/upgrade')}
          title={activateModalTitle}
        />
      ) : null}
      <PageContainer>
        <PageHeader>SMS</PageHeader>
        <PageDescription>
          SMS feature allows you to message buyers and customers from a unique,
          local number dedicated specifically to your dealership.
        </PageDescription>
        <Options>
          {(selectedOption === optionSelections.SMS2500 && (
            <ActiveOptionContainer>
              <ActiveHeader>Active</ActiveHeader>
              {option2500}
            </ActiveOptionContainer>
          )) || <OptionContainer>{option2500}</OptionContainer>}
          {(selectedOption === optionSelections.SMS5k && (
            <ActiveOptionContainer>
              <ActiveHeader>Active</ActiveHeader>
              {option5k}
            </ActiveOptionContainer>
          )) || (
            <PopularOptionContainer color={themes.colors.primary[100]}>
              <PopularHeader color={themes.colors.primary[100]}>
                Popular
              </PopularHeader>
              {option5k}
            </PopularOptionContainer>
          )}
          {(selectedOption === optionSelections.SMS7k && (
            <ActiveOptionContainer>
              <ActiveHeader>Active</ActiveHeader>
              {option10k}
            </ActiveOptionContainer>
          )) || <OptionContainer>{option10k}</OptionContainer>}
        </Options>
        <FeatureList>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Protect Privacy: Record, send and track messages from your
                personal device without using your personal phone number
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Keep it Professional: Monitor team outreach and video statistics
                from your Covideo dashboard
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Prevent Dropped Leads: Never miss out on leads or conversations,
                even if there’s staff turnover
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Comply with Policy: Centralize dealership communication and
                adhere to messaging compliance policies
              </label>
            </FeatureText>
          </Feature>
        </FeatureList>
        <AddonFooter>
          Have a question? Contact your account manager, contact us at
          support@covideo.com or give us a call at 1.800.306.1445.
        </AddonFooter>
      </PageContainer>
    </>
  );
};
