import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  min-width: 500px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

type DeactivateModalProps = {
  handleModalClose: () => void;
  type: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const DeactivateReportModal = ({
  handleModalClose,
  type,
  onCancel,
  onSubmit,
}: DeactivateModalProps) => {
  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Deactivate {capitalize(type)} Report?</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>Report's data will be reset to the defaults.</Content>
        <ButtonContainer>
          <Button
            variant='secondary'
            text='No, Keep Active'
            onClick={onCancel}
          />
          <Button
            variant='destructive'
            text='Yes, Deactivate'
            onClick={onSubmit}
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default DeactivateReportModal;
