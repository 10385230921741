import styled from 'styled-components/macro';
import { ReactComponent as WarningSvg } from './warning.svg';

const WarningIcon = styled(WarningSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default WarningIcon;
