import React, { useEffect, useState } from 'react';
import { ChangeUserCustomerModal } from './ChangeUserCustomerModal';
import { SuperAdminUser } from 'lib/context';
import { ChangeUserStatusModal } from './ChangeUserStatusModal';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { ChangeAutomotiveRoleModal } from 'app/pages/management/components/ChangeAutomotiveRoleModal';

export const SelectedUsersActions = ({
  selectedUsers,
  showChangeCustomerButton,
  onSuccessfullUpdate,
  showEditRoleButton,
}: {
  selectedUsers: SuperAdminUser[];
  showChangeCustomerButton: boolean;
  onSuccessfullUpdate(): void;
  showEditRoleButton?: boolean;
}) => {
  const [showUserCustomerPopup, setShowUserCustomerPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [showModalChangeAutomotiveRole, setShowModalChangeAutomotiveRole] =
    useState(false);
  const [commonUserAutomotiveRole, setCommonUserAutomotiveRole] = useState(0);

  useEffect(() => {
    if (!selectedUsers.length || !showEditRoleButton) {
      return;
    }

    const automotiveRole = selectedUsers[0].automotiveRole || 0;
    const isSameAutomotiveRole = selectedUsers.every(
      (user: SuperAdminUser) => user.automotiveRole === automotiveRole
    );
    if (isSameAutomotiveRole) {
      setCommonUserAutomotiveRole(automotiveRole);
    } else {
      setCommonUserAutomotiveRole(0);
    }
  }, [showEditRoleButton, selectedUsers]);
  return (
    <>
      <Gap gap='8px'>
        <Button
          onClick={() => {
            setShowStatusPopup(true);
          }}
          text='Change Status'
          variant='secondary'
        />
        {showChangeCustomerButton && (
          <Button
            onClick={() => {
              setShowUserCustomerPopup(true);
            }}
            text='Change Customer'
            variant='secondary'
          />
        )}

        {showEditRoleButton && (
          <Button
            onClick={() => {
              setShowModalChangeAutomotiveRole(true);
            }}
            text='Edit Role'
            variant='ghost'
          />
        )}
      </Gap>
      {showUserCustomerPopup && showChangeCustomerButton && (
        <ChangeUserCustomerModal
          selectedUsers={selectedUsers}
          handleModalClose={shouldRefresh => {
            setShowUserCustomerPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showStatusPopup && (
        <ChangeUserStatusModal
          selectedUsers={selectedUsers}
          handleModalClose={shouldRefresh => {
            setShowStatusPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showModalChangeAutomotiveRole && (
        <ChangeAutomotiveRoleModal
          selectedUsers={selectedUsers.map(user => user.id)}
          selectedAutomotiveRole={commonUserAutomotiveRole}
          handleModalClose={shouldRefresh => {
            setShowModalChangeAutomotiveRole(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
    </>
  );
};
