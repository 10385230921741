import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { VideoPlayer } from '../../videoPlayer';
import SaveIcon from 'lib/images/SaveIcon';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-covideo-common';
import * as Yup from 'yup';
import { AttributeFields } from '../components/AttributesFields';
import { isEmpty } from 'lodash';
import { VideoTagsForReviewScreen } from '../components/VideoTagsForReviewScreen';
import { VideoTagsForRecordingReview } from '../components/VideoTagsForRecordingReview';
import { useAuth } from 'lib/context';
import {
  checkIfVideoRequestFieldsRequired,
  validateAutomotiveFields,
} from 'lib/utils/automotiveRolePermissionChecks';
import { productFeature } from 'lib/utils/productFeature';
import { MdEmail } from 'react-icons/md';
import VideoUploadFolderDropdown from 'lib/components/videoUpload/VideoUploadFolderDropdown';
import { DEFAULT_UPLOAD_FOLDER } from 'lib/components/videoUpload/VideoUpload';
import { Option } from 'lib/components/dropdown/Dropdown';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';
import { VideoAttribute } from 'lib/api';

const Container = styled.div`
  padding-bottom: 64px;
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  margin: 0 auto;
  ${theme.mediaQueryMinWidth.md} {
    width: 1000px;
    max-width: 100%;
    flex-direction: column;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  order: 4;
  ${theme.mediaQueryMinWidth.md} {
    width: 100%;
    order: 0;
    flex-direction: row;
    justify-content: center;
    height: 40px;
  }
  a {
    justify-content: center;
    margin: 0;
    height: 40px;
    &:nth-of-type(n + 2) {
      margin: 20px 0 0 0;
    }
    ${theme.mediaQueryMinWidth.xs} {
      width: 30vw;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 12px;
      }
    }
    ${theme.mediaQueryMinWidth.md} {
      width: 30%;
      max-width: 200px;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 24px;
      }
    }
    div {
      width: auto;
      &:nth-of-type(1) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 18px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 24px;
        }
      }
      &:nth-of-type(2) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 14px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 16px;
        }
      }
    }
  }
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-right: 24px;
  ${theme.mediaQueryMinWidth.md} {
    width: 100%;
    max-width: 380px;
  }
`;

const VideoWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 488px;
  .fullscreen {
    height: auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: auto;
  ${theme.fontBold800}
  font-size: 24px;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${theme.palette.title};
  line-height: 1.82;
  margin-right: auto;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  .blur {
    min-height: 40px;
    line-height: 1.82;
    max-width: 360px;
    overflow: hidden;
    text-align: left !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .focus {
    min-height: 40px;
    line-height: 1.82;
    border: 1px solid ${theme.palette.secondaryBlue};
    border-radius: 4px;
    max-width: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
  }
  width: auto;
  gap: 8px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  align-items: start;
  ${theme.mediaQueryMinWidth.md} {
    flex-direction: row;
  }
`;

const TitleInput = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e1e2e5;
  padding: 8px 12px;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
  }
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

interface Props {
  title: string;
  videoSource: string;
  videoTitle: string;
  isReviewCase?: boolean;
  formRef?: any; // left as any because Formik initial values depend on video attributes
  formId?: string;
  videoRequestData: any; // TO-DO: replace any in separate task
  folderValue?: Option;
  showFoldersDropdown?: boolean;

  onTagsUpdate?: (formId: string, tags: object[]) => void;
  onDelete?: (videoId: string) => Promise<void>;
  handleAttributeChange: (values?: object) => void;
  onChangeVideoRequestData?: (data: any) => void;
  handleContinueToSendShare?: (data: boolean) => void;
  setFolderValue?: React.Dispatch<React.SetStateAction<Option>>;
  handleUpload: (values: object, videoTags: object[]) => void;
}

export const DetailedVideoPreview = (props: Props) => {
  const {
    title,
    videoSource,
    videoTitle,
    isReviewCase = false,
    formRef = null,
    formId = '',
    videoRequestData,
    folderValue = DEFAULT_UPLOAD_FOLDER,
    showFoldersDropdown = true,
    onTagsUpdate,
    handleUpload,
    setFolderValue,
    handleContinueToSendShare = () => {},
    onDelete,
    handleAttributeChange = () => {},
    onChangeVideoRequestData = () => {},
  } = props;

  const { userData } = useAuth();

  const [isAttributeFeatureAvailable, setIsAttributeFeatureAvailable] =
    React.useState(true);

  const [videoTags, setVideoTags] = React.useState([] as object[]);
  const [automotiveFieldsValid, setAutomotiveFieldsValid] =
    React.useState(true);

  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);

  const { data } = useGetAllVideoAttributesQuery({
    page: 0,
    size: 200, // attempt to fetch all on the first render
    filterByDepartment: true,
  });

  const { attributes: attributesList } = data || {
    attributes: [],
    count: 0,
  };

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const packageDetails = userData.user.packageDetails;
      if (packageDetails && packageDetails.id) {
        const isAttributeEnabled = packageDetails.products.includes(
          productFeature.VIDEO_ATTRIBUTES
        );
        setIsAttributeFeatureAvailable(isAttributeEnabled);
      }
    }
  }, []);

  React.useEffect(() => {
    if (!onTagsUpdate || !formId) {
      return;
    }

    onTagsUpdate(formId, videoTags);
  }, [videoTags]);

  React.useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current[formId].validateForm();
    }
  }, []);

  React.useEffect(() => {
    if (videoRequestFieldsRequired) {
      setAutomotiveFieldsValid(false);
    }
  }, [videoRequestFieldsRequired]);

  const initValueObject: any = {};

  initValueObject[`covideoName`] = videoTitle;
  initValueObject[`folder`] = folderValue;

  attributesList.map((att: VideoAttribute) => {
    if (att.type === 'dropdown') {
      const defaultValue = att.dropdownValues.find(
        (value: any) => value.default
      );
      if (defaultValue && defaultValue.value) {
        // add attributeId since it won't be available in the upload component otherwise
        initValueObject[`attributes${att.index}-${att.attributeId}`] =
          defaultValue.value;
        return defaultValue.value;
      }

      initValueObject[`attributes${att.index}-${att.attributeId}`] = '';
      return '';
    }

    initValueObject[`attributes${att.index}-${att.attributeId}`] =
      att.default || '';

    return att.default || '';
  });

  const getFormValidations = () => {
    const validations: any = {};
    validations[`covideoName`] = new Yup.string()
      .trim()
      .required('This field can’t be empty')
      .min(1, 'This field can’t be empty')
      .max(100, 'Video Title can not be more than 100 characters!'); // SUS-797 changes

    attributesList.forEach((attr: VideoAttribute) => {
      if (attr && attr.isRequired) {
        validations[`attributes${attr.index}-${attr.attributeId}`] =
          new Yup.string()
            .required('This field can’t be empty')
            .min(1, 'This field can’t be empty');
      }
    });

    return validations;
  };

  const attributesSchema = Yup.object().shape(getFormValidations()).required();

  const validateInit = () => {
    try {
      attributesSchema.validateSync(initValueObject);
    } catch (e) {
      //@ts-ignore
      return e.errors;
    }
  };
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const onChangeRequestData = (data: any) => {
    setAutomotiveFieldsValid(
      !videoRequestFieldsRequired || validateAutomotiveFields(userData, data)
    );
    onChangeVideoRequestData(data);
  };

  const continueToSendShare = (values: any, videoTags: any) => {
    handleContinueToSendShare(true);
    handleUpload(values, videoTags);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        innerRef={ref => {
          if (formRef && formId) {
            formRef.current[formId] = ref;
          }
        }}
        initialValues={initValueObject}
        initialErrors={validateInit()}
        isInitialValid={isEmpty(validateInit())}
        validationSchema={attributesSchema}
        validateOnMount={true}
        onSubmit={async () => {}}
      >
        {({ errors, isValid, values, setFieldValue }) => {
          if (values.covideoName.includes('.')) {
            errors[`covideoName`] = `Video Title can not contain dot(.)!`;
          } else if (
            !errors[`covideoName`] ||
            errors[`covideoName`] == `Video Title can not contain dot(.)!`
          ) {
            delete errors[`covideoName`];
          }
          return (
            <>
              {!isReviewCase && (
                <ButtonsBox>
                  <TitleWrapper>{title}</TitleWrapper>
                  <ButtonsWrapper>
                    <Button
                      disabled={!isValid || !automotiveFieldsValid}
                      icon={<SaveIcon height={16} width={16} />}
                      text={
                        userData.isAutomotiveSalesRole
                          ? 'Save'
                          : 'Save Recording'
                      }
                      onClick={() => {
                        handleUpload(values, videoTags);
                      }}
                    />
                    {userData.isAutomotiveSalesRole && (
                      <Button
                        variant='primary'
                        disabled={!isValid || !automotiveFieldsValid}
                        icon={<MdEmail size={20} />}
                        text='Continue to Send & Share'
                        onClick={() => {
                          continueToSendShare(values, videoTags);
                        }}
                      />
                    )}
                  </ButtonsWrapper>
                </ButtonsBox>
              )}
              <DetailsWrapper>
                <Form>
                  <Information>
                    <Label>Video Title</Label>
                    <Field name={'covideoName'} type='text' as={TitleInput} />
                    {errors[`covideoName`] ? (
                      <div style={{ color: '#E84C3D', marginTop: '3px' }}>
                        {errors[`covideoName`]}
                      </div>
                    ) : (
                      <></>
                    )}
                    {!!showFoldersDropdown && (
                      <Field
                        name='folder'
                        as={VideoUploadFolderDropdown}
                        value={values.folder}
                        showAllFolderOption={false}
                        setValue={(val: Option) => {
                          if (!!setFolderValue) {
                            setFolderValue(val);
                          }
                          setFieldValue('folder', val);
                        }}
                      />
                    )}

                    {isReviewCase && !!onDelete && (
                      <VideoTagsForReviewScreen
                        onDelete={onDelete}
                        id={formId}
                        videoTags={videoTags}
                        onUpdate={(tags: any) => setVideoTags(tags)}
                      />
                    )}
                    {!isReviewCase && (
                      <VideoTagsForRecordingReview
                        id={formId}
                        videoTags={videoTags}
                        onUpdate={(tags: any) => setVideoTags(tags)}
                      />
                    )}
                    {isAttributeFeatureAvailable && (
                      <div>
                        <AttributeFields
                          onUpdate={handleAttributeChange}
                          attributes={attributesList}
                          initValues={initValueObject}
                          onChangeVideoRequestData={onChangeRequestData}
                          videoRequestData={videoRequestData}
                        />
                      </div>
                    )}
                  </Information>
                </Form>
                <VideoWrapper>
                  <VideoPlayer
                    videoSource={videoSource}
                    videoRef={videoRef}
                    videoId={formId}
                  />
                </VideoWrapper>
              </DetailsWrapper>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};
