import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { TextInput } from 'lib/components';
import { deleteUserAccount } from 'lib/api';
import { theme } from '../../../../../../lib/style';
import EyeIcon from '../../../../../../lib/images/EyeIcon';
import { ModalDeleteAccount } from '../../../../design/modal/ModalDeleteAccount';
import { useAuth } from 'lib/context';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import {
  CustomPasswordErrorMessage,
  getTooltip,
} from 'app/pages/admin/components/PasswordTooltip';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

interface FormProps {
  isSubmitting?: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const FieldLabel = styled.label`
  width: 120px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const SectionTitle = styled.p.attrs((props: { margin: boolean }) => props)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: ${props => (props.margin ? '0' : '10px 0 0 0')};
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  color: #9297a2;
  margin-top: 11px;
  margin-left: 364px;
`;

const DeleteAccountText = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
`;
const UnderlineText = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
`;

const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
};

export const PasswordForm = (props: FormProps) => {
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    React.useState(false);
  const history = useHistory();
  const { isSubmitting } = props;
  const {
    userData: { customerId, userId, user },
    whitelabel,
  } = useAuth();

  const isFreemiumUser = user.packageDetails.id === PackageName.FREE;

  const domain = window.location.hostname;
  const isCovideo =
    whitelabel?.name === WHITELABEL_NAME.COVIDEO ||
    domain.includes('localhost');

  let supportEmail = isCovideo
    ? 'support@covideo.com'
    : whitelabel.supportEmail || '';

  const [type, setType] = useState(INPUT_TYPES.PASSWORD);
  const [confirmType, setConfirmType] = useState(INPUT_TYPES.PASSWORD);

  const toggleType = () => {
    setType(
      type === INPUT_TYPES.TEXT ? INPUT_TYPES.PASSWORD : INPUT_TYPES.TEXT
    );
  };

  const toggleConfirmType = () => {
    setConfirmType(
      confirmType === INPUT_TYPES.TEXT ? INPUT_TYPES.PASSWORD : INPUT_TYPES.TEXT
    );
  };
  const deleteAccountHandler = async () => {
    await deleteUserAccount(userId);
    history.push('/Sign_Out');
  };

  return (
    <>
      {showDeleteAccountModal == true ? (
        <ModalDeleteAccount
          onDelete={() => {
            deleteAccountHandler();
          }}
          modalLoading={false}
          data={{
            title: 'Delete your account?',
          }}
          onClose={() => {
            setShowDeleteAccountModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {/* Reset Password */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle margin={true}>Reset Password</SectionTitle>
        <RowWrapper>
          {customerId != '40741' && (
            <>
              {/* New Password */}
              <Row>
                <FieldLabel>New Password {getTooltip()}</FieldLabel>
                <Field name='newPassword'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type={type}
                          autoComplete='new-password'
                          disabled={isSubmitting}
                        />
                        <IconWrapper onClick={toggleType}>
                          <EyeIcon />
                        </IconWrapper>
                        <CustomPasswordErrorMessage name='newPassword' />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>

              {/* Confirm Password */}
              <Row>
                <FieldLabel>Confirm New Password</FieldLabel>
                <Field name='confirmPassword'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type={confirmType}
                          autoComplete='new-password'
                          disabled={isSubmitting}
                        />
                        <IconWrapper onClick={toggleConfirmType}>
                          <EyeIcon />
                        </IconWrapper>
                        <ErrorMessage
                          name='confirmPassword'
                          component={ErrorMsg}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>
            </>
          )}
        </RowWrapper>
      </div>
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle margin={false}>Delete Account</SectionTitle>
        <DeleteAccountText>
          <UnderlineText onClick={() => setShowDeleteAccountModal(true)}>
            Delete your account
          </UnderlineText>{' '}
          {!isFreemiumUser && supportEmail !== '' && (
            <>
              or{' '}
              <UnderlineText
                onClick={() =>
                  (window.location.href = `mailto:${supportEmail}`)
                }
              >
                contact our support
              </UnderlineText>{' '}
              if you need help.
            </>
          )}
        </DeleteAccountText>
      </div>
    </>
  );
};
