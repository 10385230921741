import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { LoadingIndicator, ModalVideoListVideo, Tooltip } from 'lib/components';
import { ModalVideoDeletion } from 'lib/components/modal/ModalVideoDeletion';
import { useAuth } from 'lib/context';
import PlayIcon from 'lib/images/PlayIcon';
import { ModalVideoDeletionApproval } from 'lib/components/modal/ModalVideoDeletionApproval';
import { updateVideoExpiration } from 'lib/api';
import { Card } from '../card/Card';
import { MdAutoDelete, MdLinkOff } from 'react-icons/md';
import { EmptyState } from '..';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { successToast } from 'lib/components/toasts/success';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Button } from 'react-covideo-common';
import { useCreateVideoDeleteMutation } from 'lib/api/videoDeletes/useCreateVideoDeleteMutation';
import { useUpdateVideoDeleteMutation } from 'lib/api/videoDeletes/useUpdateVideoDeleteMutation';
import { useRemoveVideoDeleteMutation } from 'lib/api/videoDeletes/useRemoveVideoDelete';
import { useApproveVideoDeleteMutation } from 'lib/api/videoDeletes/useApproveVideoDeleteMutation';
import { useDenyVideoDeleteMutation } from 'lib/api/videoDeletes/useDenyVideoDeleteMutation';
import {
  CreateVideoDeleteParams,
  StopRequestData,
  UpdateVideoDeleteParams,
  VideoDeletionApprovalProps,
} from 'lib/api/videoDeletes/types';
import { useVideoDeleteQuery } from 'lib/api/videoDeletes/useVideoDeleteQuery';

const MainDiv = styled.div`
  display: flex;
  margin-top: 64px;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 40px 330px;
  flex-grow: 1;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Title = styled.div`
  height: 40px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${theme.palette.primaryDarkBlue};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 32px;
  background: #f2f4f6;
  box-sizing: border-box;
  border-radius: 12px;
  width: fit-content;
  gap: 16px;
  max-width: 1024px;
`;

const NoStopRequests = styled.div`
  margin-top: 32px;
  margin-bottom: 28px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const StopRequests = styled.div`
  margin-top: 32px;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
  padding: 0 16px;
`;

const VideoTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 18px;
  }
`;

const StopRequestsContainer = styled.div``;

const TooltipWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
    }
  }
`;

export const Main = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showVideoListVideoModal, setShowVideoListVideoModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  const {
    userData: { customer },
    invalidateUser,
  } = useAuth();

  const closeCreateDeletionModal = () => {
    setShowCreateModal(false);
  };

  const closeEditDeletionModal = () => {
    setShowEditModal(false);
  };

  const { data: videoDelete, isLoading: loading } = useVideoDeleteQuery();

  const [days, setDays] = useState(10);

  useEffect(() => {
    setDays(videoDelete?.days ?? 10);
  }, [videoDelete]);

  const { mutateAsync: createVideoDelete } = useCreateVideoDeleteMutation(
    closeCreateDeletionModal
  );
  const { mutateAsync: updateVideoDelete } = useUpdateVideoDeleteMutation(
    closeEditDeletionModal
  );
  const { mutateAsync: removeVideoDelete } = useRemoveVideoDeleteMutation();
  const { mutateAsync: approveVideoDelete } = useApproveVideoDeleteMutation();
  const { mutateAsync: denyVideoDelete } = useDenyVideoDeleteMutation();

  const isExpirationActive = customer.videoExpiration?.toString() === '1';
  const isDeletionActive = customer.videoDeletionAccess?.toString() === '1';

  const { mutate } = useMutation(updateVideoExpiration, {
    onError: (err: AxiosError) => console.log(err),
    onSuccess: async () => {
      await invalidateUser();
      successToast({
        title: !isExpirationActive
          ? 'Video expiration activated!'
          : 'Video expiration deactivated!',
      });
    },
  });

  const isActive = videoDelete?.deleteId;

  const stopRequests: StopRequestData[] = !!videoDelete?.stopRequests?.length
    ? videoDelete?.stopRequests.filter(sr => sr.approved === null)
    : [];

  const videoDeletion = {
    ...videoDelete,
    stopRequests,
  };

  const openVideoListVideoModal = () => {
    setShowVideoListVideoModal(true);
  };

  const closeVideoListVideoModal = () => {
    setShowVideoListVideoModal(false);
  };

  const openCreateDeletionModal = () => {
    setShowCreateModal(true);
  };

  const openEditDeletionModal = () => {
    setShowEditModal(true);
  };

  const openApprovalModal = () => {
    setShowApproveModal(true);
  };

  const closeApprovalModal = () => {
    setShowApproveModal(false);
  };

  const updateRequest = async (
    data: VideoDeletionApprovalProps,
    approve: boolean
  ) => {
    if (approve) {
      await approveVideoDelete(data);
    } else {
      await denyVideoDelete(data);
    }
    closeApprovalModal();
  };

  const deactivateVideoDeletion = async () => {
    if (videoDeletion?.deleteId)
      await removeVideoDelete(videoDeletion?.deleteId.toString());
  };

  const handleExpirationActivation = (expiration: boolean) => {
    mutate({
      customerId: customer.customerId,
      data: { videoExpiration: expiration },
    });
  };

  const hasStopRequests =
    videoDeletion?.stopRequests && videoDeletion?.stopRequests.length;

  return (
    <MainDiv>
      <Content>
        <LoadingIndicator isLoading={loading} />
        {!loading && (
          <>
            <Menu>
              <Title>Video Expiration</Title>
            </Menu>
            <Container>
              <Card
                icon={<MdLinkOff size={20} color='#001B53' />}
                title='Video Expiration'
                isActive={isExpirationActive}
                textInfo='If set, customers can’t view a video after the date defined by a user, and expiration won’t delete the video.'
                activateText={'Activate Expiration'}
                deactivateText='Deactivate Expiration'
                deactivateFn={() => handleExpirationActivation(false)}
                activateFn={() => handleExpirationActivation(true)}
              />
              {isDeletionActive && (
                <Card
                  icon={<MdAutoDelete size={20} color='#001B53' />}
                  title='Video Deletion'
                  isActive={!!isActive}
                  isEdit={!!videoDeletion && !!videoDeletion.deleteId}
                  textInfo='Automatically delete videos after a specific time frame since video is created.'
                  activateFn={openCreateDeletionModal}
                  deactivateFn={deactivateVideoDeletion}
                  editFn={openEditDeletionModal}
                  activateText='Activate Deletion'
                  deactivateText='Deactivate Deletion'
                />
              )}
            </Container>
          </>
        )}
        {!hasStopRequests && isActive && (
          <>
            <NoStopRequests>No Requests</NoStopRequests>
            <EmptyState />
          </>
        )}
        {!!hasStopRequests && (
          <>
            <StopRequests>
              {videoDeletion.stopRequests.length} request
              {videoDeletion.stopRequests.length > 1 ? 's' : ''} to review
            </StopRequests>
            <StopRequestsContainer>
              {videoDeletion.stopRequests.map((r: any) => (
                <Row key={r.requestId}>
                  <VideoTitle>
                    <PlayIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setVideoId(r.videoId);
                        openVideoListVideoModal();
                      }}
                    />
                    <div>
                      <b>{r.video.title}</b>
                    </div>
                  </VideoTitle>
                  <div>{`${r.user.firstName} ${r.user.lastName}`}</div>
                  <ButtonWrapper>
                    {r.messages && !!r.messages.length && (
                      <TooltipWrapper>
                        <BsFillChatLeftTextFill style={{ cursor: 'pointer' }} />
                        <Tooltip
                          text={r.messages[0].message}
                          topPixels={-60}
                          widthPixels={416}
                        />
                      </TooltipWrapper>
                    )}
                    {r.approved !== 1 && r.approved !== 0 && (
                      <Button
                        text='Review request'
                        onClick={openApprovalModal}
                      />
                    )}
                  </ButtonWrapper>
                  {showApproveModal && (
                    <ModalVideoDeletionApproval
                      videoDeletionRequest={r}
                      handleModalClose={closeApprovalModal}
                      handleSubmit={updateRequest}
                    />
                  )}
                </Row>
              ))}
            </StopRequestsContainer>
          </>
        )}
      </Content>
      {showCreateModal && (
        <ModalVideoDeletion
          handleModalClose={closeCreateDeletionModal}
          handleSubmit={async (data: CreateVideoDeleteParams) =>
            await createVideoDelete(data)
          }
          days={days}
          setDays={setDays}
        />
      )}
      {showEditModal && (
        <ModalVideoDeletion
          videoDeletion={videoDeletion}
          handleModalClose={closeEditDeletionModal}
          handleSubmit={async (data: UpdateVideoDeleteParams) =>
            await updateVideoDelete(data)
          }
          days={days}
          setDays={setDays}
        />
      )}
      {showVideoListVideoModal && (
        <ModalVideoListVideo
          videoId={videoId}
          handleModalClose={() => {
            closeVideoListVideoModal();
            setVideoId('');
          }}
        />
      )}
    </MainDiv>
  );
};
