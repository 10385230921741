import { timezones } from 'lib/const';

export interface TimezoneInterface {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: Array<string>;
  popular?: boolean;
  description?: string;
}

export const getTimezonesWithSelected = (selectedTimezone?: string) => {
  const formattedTimezones = timezones.map((tz: TimezoneInterface) => {
    const formattedTimezone = {
      isSelected: false,
      description: `${tz.text}. ${tz.utc[0]}`,
      value: tz.utc[0],
      offset: tz.offset,
      popular: tz.popular,
    };

    if (tz.utc.length > 1) {
      const tzs = tz.utc.map(utc => ({
        isSelected: selectedTimezone === utc || selectedTimezone === tz.text,
        description: `${tz.text}. ${utc}`,
        value: utc,
        offset: tz.offset,
        popular: tz.popular,
      }));
      return tzs;
    }

    if (selectedTimezone) {
      formattedTimezone.isSelected =
        selectedTimezone === formattedTimezone.value ||
        selectedTimezone === tz.text;
    }

    return formattedTimezone;
  });

  const ft: any = [];
  const _ft = ft.concat(...formattedTimezones);

  return _ft;
};
