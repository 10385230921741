import Cookies from 'js-cookie';
import { CookieAttributes } from 'js-cookie';
import { isEnvDevelopment } from './enviroments';
import { COOKIE_NAME } from './types';

const cookieOptions: CookieAttributes = {
  domain: isEnvDevelopment ? 'localhost' : '.covideo.com',
  path: '/',
  secure: true,
  sameSite: 'none',
  expires: 7,
};

const updateCookie = (
  newToken: string,
  refreshTokenNew?: string | undefined,
  domain?: string | undefined
) => {
  if (domain) {
    cookieOptions.domain = `.${domain}`;
  }
  Cookies.set(COOKIE_NAME.JWT, newToken, cookieOptions);
  if (refreshTokenNew) {
    Cookies.set(COOKIE_NAME.REFRESH_JWT, refreshTokenNew, cookieOptions);
  }
};

export { updateCookie };
