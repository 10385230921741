import React, { useEffect, useState } from 'react';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetTabs from '../tabs';
import { WidgetWrapper } from './common/styles';
import { IParams, VariantTypes, WIDGET_TABS, WidgetLabels } from './types';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import {
  Body,
  BodyParagraph,
  ItemsWrapper,
  WidgetTitle,
} from './styles/topVariants';
import { getLeaderboard } from 'lib/api';
import { QuoteItem, ROAdvisor } from 'lib/api/repairOrders/types';
import { VideoTypeSales } from 'lib/const/VideoType';
import { useAuth } from 'lib/context';
import { AutomotiveDepartment } from 'lib/const/AutomotiveDepartment';
import { QUOTE_SETTINGS } from 'app/pages/repairOrders/components/RepairOrderListing';

type ServiceItem = {
  advisor?: ROAdvisor;
  quoteItems?: QuoteItem[];
};

type SalesItem = {
  duration?: string;
  name?: string;
  recorded?: string;
  sent?: string;
  views?: number;
  engagments?: {
    engagement: number;
  };
  videoTypes: string[];
};

type LeaderboardObject = {
  quoteItems?: QuoteItem[];
  roCount: number;
  advisor?: ROAdvisor;
};
type LeaderboardItem = {
  firstName?: string;
  lastName?: string;
  roCount?: number;
  roiTotal?: number;
  roiApproved?: number;
  roiReq?: number;
  roiOpt?: number;
  total?: number;
  approved?: number;
  rank?: number;
  closeRatioDollars?: number;
  closeRatioItems?: number;
  quoteItems?: QuoteItem[];
  advisor?: string;
  recorded?: number;
  sent?: number;
  engagment?: number;
  marketing?: number | string;
  prospecting?: number | string;
  rti?: number | string;
  tyfb?: number | string;
  views?: number;
};

interface IExportDataProps {
  title: string;
  data: { [key: string]: string | number }[];
}
interface ITopVariantsProps {
  params: IParams;
  variant: string;
  addDataForExport: (data: IExportDataProps) => void;
}
interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

const sortByItems = (usersToSort: LeaderboardItem[]) => {
  return usersToSort.sort((a: LeaderboardItem, b: LeaderboardItem) => {
    const closeRatioItemsA = a.closeRatioItems || 0;
    const closeRatioItemsB = b.closeRatioItems || 0;

    if (closeRatioItemsA === closeRatioItemsB) {
      const roiApprovedA = a.roiApproved || 0;
      const roiApprovedB = b.roiApproved || 0;
      return roiApprovedB - roiApprovedA;
    }

    return closeRatioItemsB - closeRatioItemsA;
  });
};

const sortByDollar = (usersToSort: LeaderboardItem[]) => {
  return usersToSort.sort((a: LeaderboardItem, b: LeaderboardItem) => {
    const closeRatioDollarsA = a.closeRatioDollars || 0;
    const closeRatioDollarsB = b.closeRatioDollars || 0;

    if (closeRatioDollarsA === closeRatioDollarsB) {
      const approvedA = a.approved || 0;
      const approvedB = b.approved || 0;
      return approvedB - approvedA;
    }

    return closeRatioDollarsB - closeRatioDollarsA;
  });
};

const sortByVideos = (usersToSort: LeaderboardItem[]) => {
  return usersToSort.sort((a: LeaderboardItem, b: LeaderboardItem) => {
    const recordedA = a.recorded || 0;
    const recordedB = b.recorded || 0;

    if (recordedA === recordedB) {
      const sentA = a.sent || 0;
      const sentB = b.sent || 0;
      return sentB - sentA;
    }

    return recordedB - recordedA;
  });
};

const prepareDataForService = (data: ServiceItem[]) => {
  let leaderboard: { [key: string]: LeaderboardObject } = {};
  data.forEach(order => {
    const advisorId = order?.advisor?.id || '';
    if (!leaderboard[advisorId]) {
      leaderboard[advisorId] = {
        quoteItems: order.quoteItems,
        roCount: 1,
        advisor: order.advisor,
      };
    } else {
      leaderboard[advisorId] = {
        quoteItems: [
          ...(leaderboard[advisorId].quoteItems || []),
          ...(order.quoteItems || []),
        ],
        roCount: leaderboard[advisorId].roCount + 1,
        advisor: order.advisor,
      };
    }
  });
  const leaderboardData = Object.values(leaderboard).map(
    (leaderboardItem: LeaderboardObject) => {
      const quoteItems = leaderboardItem.quoteItems || [];
      const roiTotal = quoteItems.length;
      const roiReq = quoteItems.filter((q: QuoteItem) => q.required).length;
      const roiApproved = quoteItems.filter(
        (q: QuoteItem) => q.approved
      ).length;
      const roiOpt = roiTotal - roiReq;
      const total = quoteItems.reduce(
        (sum: number, q: QuoteItem) => sum + (q.price ? Number(q.price) : 0),
        0
      );
      const approved = quoteItems
        .filter((q: QuoteItem) => q.approved)
        .reduce(
          (sum: number, q: QuoteItem) => sum + (q.price ? Number(q.price) : 0),
          0
        );
      const closeRatioDollars = total
        ? Math.round((approved / total) * 100)
        : 0;
      const closeRatioItems = roiTotal
        ? Math.round((roiApproved / roiTotal) * 100)
        : 0;
      return {
        ...leaderboardItem,
        roiTotal,
        roiApproved,
        roiReq,
        roiOpt,
        total,
        approved: Number(approved.toFixed(2)),
        closeRatioDollars,
        closeRatioItems,
        advisor:
          (leaderboardItem.advisor?.firstName || '') +
          ' ' +
          (leaderboardItem.advisor?.lastName || ''),
      };
    }
  );
  return leaderboardData;
};

const prepareDataForSales = (data: SalesItem[]) => {
  const leaderboardData = data.map((row: SalesItem) => {
    const advisor = row.name || '';
    const sent = !!row.sent ? parseInt(row.sent, 10) : 0;
    const recorded = !!row.recorded ? parseInt(row.recorded, 10) : 0;
    const views = row.views || 0;
    const engagement = Math.round(row.engagments?.engagement || 0) || 0;
    let marketing = 0;
    let prospecting = 0;
    let rti = 0;
    let tyfb = 0;
    row.videoTypes.forEach((videoType: string) => {
      if (videoType === VideoTypeSales.Marketing) ++marketing;
      if (videoType === VideoTypeSales.Prospecting) ++prospecting;
      if (videoType === VideoTypeSales.RespondToInquiry) ++rti;
      if (videoType === VideoTypeSales.ThanksForYourBusiness) ++tyfb;
    });
    return {
      advisor,
      sent,
      recorded,
      views,
      engagement,
      marketing,
      prospecting,
      rti,
      tyfb,
      duration: row.duration,
    };
  });
  return leaderboardData;
};

const Leaderboard = ({
  variant,
  params,
  addDataForExport,
}: ITopVariantsProps) => {
  const { userData } = useAuth();
  const { customer, isAutomotiveServiceRole } = userData;
  const [users, setUsers] = useState([] as LeaderboardItem[]);
  const [salesUsers, setSalesUsers] = useState([] as LeaderboardItem[]);
  const [serviceUsers, setServiceUsers] = useState([] as LeaderboardItem[]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(
    isAutomotiveServiceRole
      ? WIDGET_TABS.VARIANTS_LEADERBOARD[0]
      : WIDGET_TABS.VARIANTS_LEADERBOARD[1]
  );

  const isService = selected.id === AutomotiveDepartment.SERVICE;

  const serviceTableHeaders = [
    'Rank',
    'Advisor',
    'No. of ROs',
    'Amount approved',
    'Close ratio',
  ];
  const salesTableHeaders = [
    'Rank',
    'Advisor',
    'Videos Sent',
    'Recorded',
    'Views',
  ];
  const variantData = {
    [VariantTypes.USERS]: {
      label: 'Leaderboard',
      tabItems: WIDGET_TABS.VARIANTS_LEADERBOARD,
      tableHeaders: isService ? serviceTableHeaders : salesTableHeaders,
      flexHeaders: isService
        ? ['0.1', '0.2', '0.2', '0.3', '0.2']
        : ['0.1', '0.3', '0.3', '0.3'],
    },
  } as VariantData;

  const preferItem = customer.quoteSettings === QUOTE_SETTINGS.ITEM;
  const preferDollar = customer.quoteSettings === QUOTE_SETTINGS.DOLLAR;
  const showServiceSaleToggle = false;

  const sortUsersForService = (usersToSort: LeaderboardItem[]) => {
    const sortedUsers =
      customer.quoteSettings && preferItem
        ? sortByItems(usersToSort)
        : sortByDollar(usersToSort);
    return sortedUsers;
  };

  const sortUsersForSales = (usersToSort: LeaderboardItem[]) => {
    const sortedUsers = sortByVideos(usersToSort);
    return sortedUsers;
  };

  const rankUsersForService = (usersToRank: LeaderboardItem[]) => {
    let lastSharedRank = 0;
    const rankedUsers = usersToRank.map(
      (user: LeaderboardItem, index: number) => {
        const prev = usersToRank[index - 1];
        const itemRankShared =
          !!prev &&
          user.closeRatioItems === prev.closeRatioItems &&
          user.roiApproved === prev.roiApproved;
        const dollarRankShared =
          !!prev &&
          user.closeRatioDollars === prev.closeRatioDollars &&
          user.approved === prev.approved;
        let rank = index + 1;
        if (
          (preferItem && itemRankShared) ||
          (preferDollar && dollarRankShared)
        ) {
          rank = lastSharedRank ? lastSharedRank : index;
          lastSharedRank = rank;
        } else {
          lastSharedRank = 0;
        }
        return { ...user, rank };
      }
    );
    return rankedUsers;
  };

  const rankUsersForSales = (usersToRank: LeaderboardItem[]) => {
    let lastSharedRank = 0;
    const rankedUsers = usersToRank.map(
      (user: LeaderboardItem, index: number) => {
        const prev = usersToRank[index - 1];
        const rankShared =
          !!prev && user.sent === prev.sent && user.recorded === prev.recorded;
        let rank = index + 1;
        if (rankShared) {
          rank = lastSharedRank ? lastSharedRank : index;
          lastSharedRank = rank;
        } else {
          lastSharedRank = 0;
        }
        return { ...user, rank };
      }
    );
    return rankedUsers;
  };

  const prepareData = (
    data: ServiceItem[] | SalesItem[],
    isServiceData: boolean
  ) => {
    const leaderboardData = isServiceData
      ? prepareDataForService(data as ServiceItem[])
      : prepareDataForSales(data as SalesItem[]);
    const sortedUsers = isServiceData
      ? sortUsersForService(leaderboardData)
      : sortUsersForSales(leaderboardData);
    return isServiceData
      ? rankUsersForService(sortedUsers)
      : rankUsersForSales(sortedUsers);
  };
  const prepareLeaderboardUsers = async () => {
    const from = new Date(params.from);
    const to = new Date(params.to);
    setLoading(true);
    try {
      const data = await getLeaderboard({
        startDate: from,
        endDate: to,
        department: selected.id,
      });
      const serviceUsersData = prepareData(data.serviceUsers, true);
      const salesUsersData = prepareData(data.salesUsers, false);
      setUsers(isService ? serviceUsersData : salesUsersData);
      setSalesUsers(salesUsersData);
      setServiceUsers(serviceUsersData);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const mapDataForExport = (data: {
    serviceUsers: LeaderboardItem[];
    salesUsers: LeaderboardItem[];
  }) => {
    let exportData: IExportDataProps = { title: '', data: [] };
    exportData.title = WidgetLabels.LEADERBOARD;
    const maxLength = Math.max(
      data.serviceUsers.length,
      data.salesUsers.length
    );
    for (let i = 0; i < maxLength; i++) {
      const service = data.serviceUsers[i];
      const sales = data.salesUsers[i];
      let singleObject = {
        'Service Advisor': service?.advisor ?? '',
        'No. of ROs': service?.roCount ?? '',
        'Amount approved': service?.approved ?? '',
        'Close ratio': preferItem
          ? service?.closeRatioItems ?? ''
          : service?.closeRatioDollars ?? '',
        '': '',
        'Sales Advisor': sales?.advisor ?? '',
        'Videos Sent': sales?.sent ?? '',
        Recorded: sales?.recorded ?? '',
        Views: sales?.views ?? '',
      };
      exportData.data.push(singleObject);
    }

    return exportData;
  };

  useEffect(() => {
    prepareLeaderboardUsers();
  }, [params.from, params.to]);

  useEffect(() => {
    if (!!salesUsers?.length && !!serviceUsers.length) {
      setUsers(isService ? serviceUsers : salesUsers);
      return;
    }
    prepareLeaderboardUsers();
  }, [selected]);

  useEffect(() => {
    addDataForExport(
      mapDataForExport({
        serviceUsers,
        salesUsers,
      })
    );
  }, [serviceUsers, salesUsers]);

  if (loading) {
    return <SkeletonLoader imageName='line-report' />;
  }

  return (
    <WidgetWrapper>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          {variantData[variant].label} {loading && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>

      {showServiceSaleToggle && variantData[variant].tabItems.length ? (
        <WidgetTabs
          items={variantData[variant].tabItems}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <></>
      )}
      {users?.length ? (
        <>
          <Header
            selectedLabel={selected?.label}
            variantData={variantData}
            variant={variant}
            hasAdditionalHeader={false}
          />
          {users.map((item: LeaderboardItem, index: number) => (
            <Body key={index}>
              <BodyParagraph flex='0.1' bold>
                {item.rank}
              </BodyParagraph>
              <BodyParagraph textAlign='left' flex={isService ? '0.2' : '0.35'}>
                {item.advisor}
              </BodyParagraph>
              <BodyParagraph
                bold
                textAlign='right'
                flex={isService ? '0.18' : '0.3'}
              >
                {isService ? item.roCount : item.sent}
              </BodyParagraph>
              <BodyParagraph bold textAlign='right' flex='0.3'>
                {isService ? '$' + item.approved : item.recorded}
              </BodyParagraph>
              <BodyParagraph
                bold
                textAlign='right'
                flex={isService ? '0.2' : '0.3'}
              >
                {isService
                  ? preferItem
                    ? item.closeRatioItems + '%'
                    : item.closeRatioDollars + '%'
                  : item.views}
              </BodyParagraph>
            </Body>
          ))}
        </>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default Leaderboard;
