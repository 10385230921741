import * as React from 'react';
import styled from 'styled-components/macro';
import { Field, Form, Formik, FieldAttributes } from 'formik';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdDeleteForever } from 'react-icons/md';
import { Contact } from 'lib/api';
import { ModalDelete } from 'lib/components/modal';
import { Dispatch, SetStateAction } from 'react';

const Content = styled.div`
  width: 100%;
  max-width: 528px;
`;

const ModalDeleteContainer = styled.div`
  z-index: 999;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 40px;
  align-items: center;
  padding: 12px 0 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
`;

const Header = styled.div`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-top: 15px; /* SUS-908 changes */
`;

const TextInput = styled.input`
  display: flex;
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  margin-bottom: 10px; /*SUS-908 changes */
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const Text = styled.div`
  color: ${theme.palette.buttonDanger};
  margin-top: -27px;
`;
const InputField = (props: FieldAttributes<any>) => {
  return (
    <div style={{ display: 'flex' }}>
      <Field {...props} />
    </div>
  );
};
type Props = {
  contact: Contact;
  setContact: Function;
  onDelete: Function;
  setFormErrors: Dispatch<SetStateAction<boolean>> /* SUS-908 changes */;
};

export const ContactDetailsForm = ({
  contact,
  setContact,
  onDelete,
  setFormErrors /* SUS-908 changes */,
}: Props) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstName: '' /* SUS-908 changes */,
    lastName: '' /* SUS-908 changes */,
    companyName: '' /* SUS-908 changes */,
    email: '',
    phone: '',
    fax: '' /*** SUS-893 changes ***/,
    address: '' /* SUS-908 changes */,
    city: '' /* SUS-908 changes */,
    state: '' /* SUS-908 changes */,
    zip: '' /* SUS-908 changes */,
    country: '' /* SUS-908 changes */,
  });
  const [emailAndPhoneMissingError, setEmailAndPhoneMissingError] =
    React.useState('');

  let modalDeleteText = 'This action can’t be undone.';
  let whiteButtonText = 'Yes, Delete';
  let orangeButtonText = "No, Don't Delete";
  let modalDeleteTitle = 'Delete contact';

  /* SUS-908 changes */
  React.useEffect(() => {
    setFormErrors(
      !Object.values(errors).every(value => !value || !value.length) ||
        !!emailAndPhoneMissingError
    );
  }, [errors]);

  return (
    <Content>
      <Header style={{ marginTop: 24, marginBottom: 16 }}>
        Contact Details
      </Header>
      <Formik
        enableReinitialize
        initialValues={contact || {}}
        onSubmit={async data => {
          alert(JSON.stringify(data));
        }}
      >
        {({ values }) => (
          <Form>
            <Row>
              <div style={{ marginRight: 12, flex: 1 }}>
                <Label>First Name</Label>
                <InputField
                  name='firstName'
                  as={TextInput}
                  type='text'
                  onKeyUp={() => setErrors(setContact({ ...values }))}
                />
                {/* SUS-908 changes */}
                {errors.firstName && (
                  <Label>
                    <Text>{errors.firstName}</Text>
                  </Label>
                )}
              </div>
              <div style={{ marginLeft: 12, flex: 1 }}>
                <Label>Last Name</Label>
                <InputField
                  name='lastName'
                  as={TextInput}
                  type='text'
                  onKeyUp={() => setErrors(setContact({ ...values }))}
                />
                {/* SUS-908 changes */}
                {errors.lastName && (
                  <Label>
                    <Text>{errors.lastName}</Text>
                  </Label>
                )}
              </div>
            </Row>
            <Label>Email</Label>
            <InputField
              name='email'
              as={TextInput}
              type='text'
              onKeyUp={() => {
                if (values.email === '' && values.phone === '') {
                  setErrors({ ...errors, email: '', phone: '' });
                  setEmailAndPhoneMissingError(
                    'Please enter email address or phone number'
                  );
                  return;
                }
                setEmailAndPhoneMissingError('');
                setErrors(setContact({ ...values }));
              }}
            />
            {emailAndPhoneMissingError && (
              <Label>
                <Text>{emailAndPhoneMissingError}</Text>
              </Label>
            )}
            {errors.email && (
              <Label>
                <Text>{errors.email}</Text>
              </Label>
            )}

            <Label>Phone</Label>
            <InputField
              name='phone'
              as={TextInput}
              type='text'
              onKeyUp={() => {
                if (values.email === '' && values.phone === '') {
                  setErrors({ ...errors, email: '', phone: '' });
                  setEmailAndPhoneMissingError(
                    'Please enter email address or phone number'
                  );
                  return;
                }
                setEmailAndPhoneMissingError('');
                setErrors(setContact({ ...values }));
              }}
            />
            {emailAndPhoneMissingError && (
              <Label>
                <Text>{emailAndPhoneMissingError}</Text>
              </Label>
            )}
            {errors.phone && (
              <Label>
                <Text>{errors.phone}</Text>
              </Label>
            )}

            <Label>Fax</Label>
            <InputField
              name='fax'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {errors.fax && (
              <Label>
                <Text>{errors.fax}</Text>
              </Label>
            )}
            <Label>Company</Label>
            <InputField
              name='companyName'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.companyName && (
              <Label>
                <Text>{errors.companyName}</Text>
              </Label>
            )}
            <Header style={{ marginTop: 56, marginBottom: 16 }}>
              Address Information
            </Header>
            <Label>Street</Label>
            <InputField
              name='address'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.address && (
              <Label>
                <Text>{errors.address}</Text>
              </Label>
            )}
            <Label>City</Label>
            <InputField
              name='city'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.city && (
              <Label>
                <Text>{errors.city}</Text>
              </Label>
            )}
            <Label>State</Label>
            <InputField
              name='state'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.state && (
              <Label>
                <Text>{errors.state}</Text>
              </Label>
            )}
            <Label>Zip</Label>
            <InputField
              name='zip'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.zip && (
              <Label>
                <Text>{errors.zip}</Text>
              </Label>
            )}
            <Label>Country</Label>
            <InputField
              name='country'
              as={TextInput}
              type='text'
              onKeyUp={() => setErrors(setContact({ ...values }))}
            />
            {/* SUS-908 changes */}
            {errors.country && (
              <Label>
                <Text>{errors.country}</Text>
              </Label>
            )}
          </Form>
        )}
      </Formik>

      <Button
        onClick={() => setShowDeleteModal(true)}
        text='Delete Contact'
        variant='destructive'
        icon={<MdDeleteForever color='#e84c3d' width={20} />}
      ></Button>
      {showDeleteModal && (
        <ModalDeleteContainer>
          <ModalDelete
            whiteButtonText={whiteButtonText}
            orangeButtonText={orangeButtonText}
            title={modalDeleteTitle}
            text={modalDeleteText}
            hasConfirm={true}
            handleModalClose={() => setShowDeleteModal(false)}
            onClickWhiteButton={() => onDelete()}
            onClickOrangeButton={() => setShowDeleteModal(false)}
          />
        </ModalDeleteContainer>
      )}
    </Content>
  );
};
