import * as React from 'react';
import { theme } from 'lib/style';
import { TimelineRange } from './components';
import { VideoPlayer } from '../../../videoPlayer';
import { VideoData } from 'lib/hooks';
import { useAuth } from 'lib/context';
import { useHistory } from 'react-router-dom';
import { GrRevert } from 'react-icons/gr';
import { toMMSS } from 'lib/utils/functions';
import { ModalTrimCreateNew } from 'lib/components/modal/ModalTrimCreateNew';
import { getVideosCount, trimVideo } from 'lib/api';
import RouteLeavingGuard from '../RouteLeavingGuard';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Heading, ParagraphSmall } from 'lib/components/styles/typography';
import {
  FormGroup,
  InputLabel,
  InputPlaceholder,
  InputPlaceholderText,
  InputWrapper,
  ItemContainer,
  ItemsWrapper,
} from '../../styles/layout';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { isVideoCountValid } from 'lib/utils/productFeature';
import { successToast } from 'lib/components/toasts/success';
import { useQueryClient } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import SaveIcon from 'lib/images/SaveIcon';
import { Button } from 'react-covideo-common';
import { DurationInput } from 'lib/components/inputs/DurationInput';

type Props = {
  videoDetails: VideoData;
  setReloadVideoDetails: Function;
};

export const VideoTrim = ({ videoDetails, setReloadVideoDetails }: Props) => {
  const valueRef = React.useRef<Array<number>>([0, 0]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [videoDuration, setVideoDuration] = React.useState<number>(0);
  const [trimDuration, setTrimDuration] = React.useState<number>(0);
  const [start, setStart] = React.useState<number>(0);
  const [actionHistory, setActionHistory] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<ReadonlyArray<number>>([
    0,
    videoDuration,
  ]);
  const [isNewodalVisible, setIsNewodalVisible] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const { userData } = useAuth();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isFetching, setIsFetching] = React.useState(false);
  const isGuide = !!videoDetails.isGuide;

  React.useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    getVideosCount()
      .then(result => {
        setIsFetching(false);
        if (!isVideoCountValid(userData, result.count, true)) {
          window.location.href = '/upgrade-plan';
        }
      })
      .catch(() => setIsFetching(false));
  }, [userData]);

  React.useEffect(() => {
    setIsDirty(
      value[0] !== 0 || value[1].toFixed(1) !== videoDuration.toFixed(1)
    );
  }, [value]);

  React.useEffect(() => setIsTouched(isTouched || isDirty), [isDirty]);

  const videoRef = React.createRef<HTMLVideoElement>();

  const onloadedmetadata = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      setVideoDuration(duration);
      setTrimDuration(duration);
      setActionHistory([[0, duration]]);
    }
  };

  const onPlay = (event: Event) => {
    const video = event.target as HTMLVideoElement;
    if (video.currentTime >= valueRef.current[1]) {
      //on trim end set start time
      video.currentTime = valueRef.current[0];
    } else if (video.currentTime < valueRef.current[0]) {
      // go to trim start if currentTime less
      video.currentTime = valueRef.current[0];
    }
  };
  const onTimeupdate = (event: Event) => {
    const video = event.target as HTMLVideoElement;
    if (video.currentTime > valueRef.current[1] && valueRef.current[1] != 0) {
      video.pause();
    }
  };
  React.useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.onloadedmetadata = () => onloadedmetadata();
      videoRef.current.addEventListener('play', e => onPlay(e));
      videoRef.current.addEventListener('timeupdate', e => onTimeupdate(e));
    }
    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.onloadedmetadata = null;
        videoRef.current.removeEventListener('play', e => onPlay(e));
        videoRef.current.removeEventListener('timeupdate', e =>
          onTimeupdate(e)
        );
      }
    };
  }, [videoRef]);

  const addHistory = () => {
    if (value && videoDuration !== 0) {
      let actions = actionHistory.slice();
      actions.push(value);
      if (actionHistory.length > 4) {
        actions.shift();
      }
      setActionHistory(actions);
    }
  };

  const revert = () => {
    let actions = actionHistory.slice();
    const last = actions.pop();
    if (last) {
      setValue(last);
    }
    setActionHistory(actions);
  };

  const trimVideoCreate = async (title: string) => {
    setIsLoading(true);
    try {
      await trimVideo(videoDetails.id, {
        title: title,
        duration: trimDuration,
        start: start,
      });
      setIsLoading(false);
      setIsDirty(false);
      setIsTouched(false);
      history.push(`/`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleTrimVideoGuide = async () => {
    setIsLoading(true);
    try {
      await trimVideo(videoDetails.id, {
        duration: trimDuration,
        start: start,
      });
      setReloadVideoDetails(true);
      await queryClient.refetchQueries(
        guidesKeyes.details(Number(videoDetails.id))
      );
      setIsLoading(false);
      setIsDirty(false);
      setIsTouched(false);
      successToast({
        title: 'Video trimmed successfully!',
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const seconds = toMMSS(trimDuration).split('.')[0];
  return (
    <>
      <HelpDesk name={EHelpDesk.TRIMMING} />
      <RouteLeavingGuard
        when={true}
        stay={true}
        onStay={() =>
          isGuide ? handleTrimVideoGuide() : setIsNewodalVisible(true)
        }
        navigate={path => history.push(path)}
        onConfirm={() => {}}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
      />
      <HeaderWrapper>
        <Heading>{videoDetails.title}</Heading>
        <Gap>
          <Button variant='secondary' icon={<GrRevert />} onClick={revert} />
          <Button
            text='Save Changes'
            icon={<SaveIcon color={theme.palette.white} width={16} />}
            disabled={!isTouched}
            onClick={() =>
              isGuide ? handleTrimVideoGuide() : setIsNewodalVisible(true)
            }
          />
        </Gap>
      </HeaderWrapper>
      <ItemsWrapper>
        <ItemContainer>
          <FormGroup direction='column'>
            <FormGroup>
              <InputWrapper>
                <InputLabel>New Start Time</InputLabel>
                <DurationInput
                  min={0}
                  max={value[1]}
                  value={value[0]}
                  onChange={(startValue: number) => {
                    if (startValue >= 0 && startValue <= value[1]) {
                      const updatedArray = [startValue, value[1]];
                      setValue(updatedArray);
                      setTrimDuration(value[1] - startValue);
                      setStart(startValue);
                      addHistory();
                    }
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>New End Time</InputLabel>
                <DurationInput
                  min={value[0]}
                  max={videoDuration}
                  value={value[1]}
                  onChange={(endValue: number) => {
                    if (endValue >= value[0] && endValue <= videoDuration) {
                      const updatedArray = [value[0], endValue];
                      setValue(updatedArray);
                      setTrimDuration(endValue - value[0]);
                      setStart(value[0]);
                      addHistory();
                    }
                  }}
                />
              </InputWrapper>
            </FormGroup>
            <InputWrapper>
              <InputLabel>Video duration: </InputLabel>
              <InputPlaceholder>
                <InputPlaceholderText>{seconds}</InputPlaceholderText>
              </InputPlaceholder>
              <ParagraphSmall m='16px 0 0 0'>
                Drag the handles or enter the desired start and end times to
                trim the video.
              </ParagraphSmall>
            </InputWrapper>
          </FormGroup>
        </ItemContainer>
        <ItemContainer>
          <VideoPlayer
            playerBackgroundColor={videoDetails.playerBackgroundColor}
            videoSource={videoDetails.videoSource}
            videoId={videoDetails.id}
            playButtonPosition={videoDetails.playButtonPosition}
            playerIconsColor={videoDetails.playerIconsAndTextColor}
            videoRef={videoRef}
            autoplay={false}
            height='280px'
          />
        </ItemContainer>
      </ItemsWrapper>

      <TimelineRange
        videoRef={videoRef}
        videoDuration={videoDuration}
        videoSource={videoDetails.videoSource}
        setTrimDuration={setTrimDuration}
        setStart={setStart}
        setValue={setValue}
        value={value}
        addHistory={addHistory}
        valueRef={valueRef}
      />

      {isNewodalVisible && (
        <ModalTrimCreateNew
          disabled={isLoading}
          isLoading={isLoading}
          initTitle={videoDetails.title}
          handleSubmit={title => trimVideoCreate(title)}
          handleModalClose={() => setIsNewodalVisible(false)}
        />
      )}
    </>
  );
};
