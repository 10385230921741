import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';

export interface ICategory {
  guideCategoryId: number;
  userId: number;
  customerId: number;
  name: string;
  private: boolean;
  createdAt: string;
  updatedAt: string;
}

const addCategory = async ({ name }: { name: string }): Promise<ICategory> => {
  return (
    await EXPRESS_API.post(`guide-category`, {
      name,
    })
  ).data as ICategory;
};
export const useAddCategoryMutation = (closeModalHandler: () => void) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(addCategory, {
    onError: err => showError(err),
    onSuccess: () => {
      successToast({ title: 'You have successfully added category!' });
      queryClient.invalidateQueries(guidesKeyes.categorized());
      queryClient.invalidateQueries(guidesKeyes.editableCategories());
      closeModalHandler();
    },
  });
};
