import * as React from 'react';
import styled from 'styled-components/macro';
import { IoIosAdd } from 'react-icons/io';
import { useLandingPageBuilderContext } from '../../context';
import { BinIcon } from '../Constants';
import { Button } from 'react-covideo-common';
interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

interface TextAreaProps {
  onChange: any;
  value?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  .alignRight {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const Textarea = styled.textarea<TextAreaProps>`
  width: 90%;
  height: 156px;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
`;

export const EditEmbedVideoSection = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
  } = useLandingPageBuilderContext();
  const [emebedVideoElementArray, setEmebedVideoElementArray] = React.useState<
    Array<string>
  >([]);

  const removeEmbed = (index: number) => {
    let newEmebedVideoElementArray = emebedVideoElementArray;
    newEmebedVideoElementArray.splice(index, 1);
    setEmebedVideoElementArray(newEmebedVideoElementArray);
    sectionDetails.videos = newEmebedVideoElementArray;
    data[elementSectionKey] = sectionDetails;
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  React.useEffect(() => {
    setEmebedVideoElementArray(sectionDetails.videos);
  }, []);

  const data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = data[elementSectionKey];
  if (!sectionDetails) {
    return <></>;
  }

  const isVisible = sectionDetails.isVisible;
  if (!isVisible) {
    return <></>;
  }

  const count = emebedVideoElementArray.length;

  const handleAddNewEmbed = () => {
    let newEmebedVideoElementArray = [...emebedVideoElementArray, ''];
    setEmebedVideoElementArray(newEmebedVideoElementArray);
    sectionDetails.videos = newEmebedVideoElementArray;
    data[elementSectionKey] = sectionDetails;
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  const handleTextChange = (event: any, index: number) => {
    let embedCode = event.target.value;
    if (
      embedCode &&
      embedCode.includes('class="cv-embed"') &&
      embedCode.includes(
        '<script defer src="https://code.covideo.com/embed/js/init.js"></script>'
      )
    ) {
      embedCode = embedCode.replace(
        '<script defer src="https://code.covideo.com/embed/js/init.js"></script>',
        ''
      );
    }

    let newEmebedVideoElementArray: any = [...emebedVideoElementArray];
    newEmebedVideoElementArray[index] = embedCode;
    sectionDetails.videos = newEmebedVideoElementArray;
    data[elementSectionKey] = sectionDetails;
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
    setEmebedVideoElementArray(newEmebedVideoElementArray);
  };

  return (
    <>
      {emebedVideoElementArray.map((element: any, index: number) => {
        let label = 'Embed Code';
        if (count > 1) {
          label = label + ' ' + (index + 1);
        }
        return (
          <Row key={index + '-embed'}>
            <TopLabel>{label}</TopLabel>
            <Textarea
              value={element.toString()}
              onChange={(event: any) => {
                handleTextChange(event, index);
              }}
            />
            <BinIcon
              onClick={() => {
                removeEmbed(index);
              }}
              className={'alignRight'}
            />
          </Row>
        );
      })}
      <Row>
        <Button
          variant='secondary'
          icon={<IoIosAdd />}
          text={'Add New'}
          onClick={handleAddNewEmbed}
        />
      </Row>
    </>
  );
};
