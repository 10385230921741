import * as React from 'react';
import styled from 'styled-components/macro';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { EmailInput, SelectInput, TextInput } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from '../../../../../../lib/style';
import { checkUsername } from 'lib/api';
import debounce from 'lodash/debounce';
import selectors from '../../../../../../cypress/selectors';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { AccessRole } from 'lib/const';

// Added (userId) in FormProps interface for SUS-521

interface FormProps {
  isSubmitting?: boolean;
  setFieldValue: (field: string, value: any) => void;
  deptList?: any;
  setDeptValue?: (id: any) => void;
  deptLoading?: any;
  userId?: string;
  setUsernameValid: React.Dispatch<React.SetStateAction<boolean>>;
  isUsernameValid: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  .user__desc {
    font-size: 12px;
    line-height: 20px;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const FieldLabel = styled.label`
  width: 120px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  max-width: 700px;
  margin-left: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #eeeff2;
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

export const UserForm = (props: FormProps) => {
  const {
    isSubmitting,
    deptList,
    deptLoading,
    setUsernameValid,
    isUsernameValid,
  } = props;

  const usernameRef = React.useRef<HTMLInputElement | null>(null);
  const handleDebounceFn = async () => {
    const trimmedUserName = usernameRef?.current?.value.trim();
    if (trimmedUserName !== '') {
      // Added argument for SUS-521
      const userValid = await checkUsername(
        trimmedUserName ? trimmedUserName : '',
        props.userId ? props.userId : ''
      );
      setUsernameValid(!userValid.userExist);
    } else {
      setUsernameValid(true);
    }
  };
  const debounceFn = React.useMemo(() => debounce(handleDebounceFn, 500), []);

  const {
    userData: { eleadEnabled, isAutomotive, customer, access, customerId },
  } = useAuth();

  const customerIdsForReadOnlyPhone = [66766, 66765];
  const isReadOnlyPhone =
    customerIdsForReadOnlyPhone.includes(
      parseInt(customerId?.toString(), 10)
    ) && access === AccessRole.USER;

  return (
    <>
      {/* Personal info */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Personal info</SectionTitle>
        <RowWrapper>
          {/* Sign Up Date */}
          <Row>
            <FieldLabel>Sign Up Date</FieldLabel>
            <Field name='signupDate'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      style={{ backgroundColor: theme.palette.gray10 }}
                      placeholder='Sign Up Date'
                      required={true}
                      disabled={true}
                    />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* First Name */}
          <Row>
            <FieldLabel>First Name</FieldLabel>
            <Field name='firstName'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      placeholder='First Name'
                      required={true}
                      disabled={isSubmitting}
                      dataCy={selectors.editUserProfile.firstNameField}
                    />
                    <ErrorMessage name='firstName' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Last Name */}
          <Row>
            <FieldLabel>Last Name</FieldLabel>
            <Field name='lastName'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      placeholder='Last Name'
                      required={
                        Number(customer.resellerId) ===
                        NAPLETON_AUTOMOTIVE_RESELLER_ID
                          ? false
                          : true
                      }
                      disabled={isSubmitting}
                      dataCy={selectors.editUserProfile.lastNameField}
                    />
                    <ErrorMessage name='lastName' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Email */}
          <Row>
            <FieldLabel>Email</FieldLabel>
            <Field name='email'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <EmailInput
                      {...field}
                      type='email'
                      required={true}
                      disabled={isSubmitting}
                      data-cy={selectors.editUserProfile.emailField}
                    />
                    <ErrorMessage name='email' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Username */}
          <Row>
            <FieldLabel>Username</FieldLabel>
            <Field name='username'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      placeholder='Username'
                      autoComplete='new-password'
                      required={true}
                      disabled={false}
                      ref={usernameRef}
                      dataCy={selectors.editUserProfile.userNameField}
                      onKeyUp={() => {
                        debounceFn();
                      }}
                    />
                    <ErrorMessage name='username' component={ErrorMsg} />
                    {isUsernameValid && (
                      <p className='user__desc'>
                        Username should only contain letters, numbers, and dots
                        without spaces, only @-_+. special characters can be
                        used.
                      </p> // Updated message as per SUS-784 changes
                    )}
                    {!isUsernameValid && (
                      <ErrorMsg>Username is already taken.</ErrorMsg>
                    )}
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      </div>
      <Divider />

      {/* Contact */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Contact</SectionTitle>
        <RowWrapper>
          {/* Phone 1 */}
          <Row>
            <FieldLabel>Phone 1</FieldLabel>
            <Field name='phone1'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      required={false}
                      readOnly={isReadOnlyPhone}
                      disabled={isSubmitting || isReadOnlyPhone}
                    />
                    <ErrorMessage name='phone1' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Phone 2 */}
          <Row>
            <FieldLabel>Phone 2</FieldLabel>
            <Field name='phone2'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      disabled={isSubmitting || isReadOnlyPhone}
                      readOnly={isReadOnlyPhone}
                    />
                    <ErrorMessage name='phone2' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Cell Phone */}
          <Row>
            <FieldLabel>Cell Phone</FieldLabel>
            <Field name='cellPhone'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      disabled={isSubmitting || isReadOnlyPhone}
                      readOnly={isReadOnlyPhone}
                    />
                    <ErrorMessage name='cellPhone' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Website */}
          <Row>
            <FieldLabel>Website</FieldLabel>
            <Field name='website'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' disabled={isSubmitting} />
                    <ErrorMessage name='website' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      </div>
      <Divider />

      {/* Job position */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Job position</SectionTitle>
        <RowWrapper>
          {/* Title */}
          <Row>
            <FieldLabel>Title</FieldLabel>
            <Field name='userTitle'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' disabled={isSubmitting} />
                    <ErrorMessage name='userTitle' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>

          {/* Department */}
          <Row>
            <FieldLabel>Department</FieldLabel>
            <Field type='select' name='department'>
              {({ field }: FieldProps) => {
                const { value, ...rest } = field;
                if (deptLoading) return null;
                return (
                  <SelectInput
                    {...rest}
                    defaultValue={value}
                    disabled={isSubmitting}
                  >
                    <option value=''>Select Department</option>
                    {deptList.map((dept: any, index: number) => (
                      <option key={index} value={dept.DepartmentID.toString()}>
                        {dept.Name}
                      </option>
                    ))}
                  </SelectInput>
                );
              }}
            </Field>
          </Row>

          {/* NMLS */}
          {!isAutomotive && (
            <Row>
              <FieldLabel>NMLS</FieldLabel>
              <Field name='nmls'>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <TextInput
                        {...field}
                        type='text'
                        disabled={isSubmitting}
                      />
                      <ErrorMessage name='nmls' component={ErrorMsg} />
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}

          {/* eleadEnabled?.toString() to show eleadField if it is enabled SUS-646 */}
          {eleadEnabled?.toString() === '1' && (
            <>
              {/* Elead DMS Employee ID */}
              <Row>
                <FieldLabel>Elead DMS Employee ID</FieldLabel>
                <Field name='eleadEmployeeId'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type='text'
                          placeholder='Elead DMS Employee ID'
                          required={true} // changing this to true in case user has enabled the Elead settings SUS-646
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name='eleadEmployeeId'
                          component={ErrorMsg}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>
            </>
          )}
        </RowWrapper>
      </div>
    </>
  );
};
