import styled from 'styled-components/macro';
import { ReactComponent as SaveSvg } from './save.svg';

const SaveIcon = styled(SaveSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default SaveIcon;
