/** eslint-disable no-useless-escape */

import { CalendarPlatform } from './IntegrationsForm';
import { workingDays } from './WorkingHours';
import { IWorkingHours } from '../main/EditUserProfile';
import { PASSWORD_REGEX, EMAIL_REGEX } from 'lib/utils/regexes';
import { validateInputForCsvFormulaInjection } from 'lib/utils/functions';
import {
  NAPLETON_AUTOMOTIVE_RESELLER_ID,
  PASSWORD_ERROR_MESSAGE,
} from 'lib/const/SuperAdminConstants';
import { INotificationPreferences } from './Notifications/common/types';
export interface UserValues {
  signupDate: string;
  firstName: string;
  lastName: string;
  username: string;
  newPassword?: string;
  confirmPassword?: string;
  email: string;
  eleadEmployeeId?: string;
  phone1: string;
  phone2?: string;
  cellPhone?: string;
  userTitle?: string;
  department?: string;
  website?: string;
  timezone?: string;
  playButtonPosition: string;
  captions: string;
  captionVisibility: string;
  signature?: string;
  googleCalendarId?: string;
  calendarWebsiteUrl?: string;
  calendlyCalendarUrl?: string;
  calendlyAccessToken?: string;
  outlookRefreshToken?: string;
  platform?: number;
  nmls?: string;
  reactionsPreference?: any;
  workingHours: IWorkingHours;
  resellerId?: number;
  videoShareLinkText?: string;
  notificationPreferences?: INotificationPreferences;
}

interface ErrorValues {
  firstName?: string;
  lastName?: string;
  username?: string;
  newPassword?: string;
  confirmPassword?: string;
  email?: string;
  eleadEmployeeId?: string;
  phone1?: string;
  phone2?: string;
  cellPhone?: string;
  googleCalendarId?: string;
  calendarWebsiteUrl?: string;
  calendlyCalendarUrl?: string;
  calendlyAccessToken?: string;
  outlookRefreshToken?: string;
  nmls?: string /* SUS-796 changes */;
  userTitle?: string /* SUS-796 changes */;
  website?: string /* SUS-796 changes */;
  workingHours?: {
    [key: string]: Partial<
      Record<
        'from' | 'to',
        {
          hours?: string;
        }
      >
    >;
  };
}

export const PASSWORD_FORMAT_ERROR = 'passwordFormatError';

export const messages = {
  firstName: [
    'First name cannot be empty.',
    'First name can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.).',
    'Please enter your first name.',
    'First name can not contain more than 100 characters.' /* SUS-796 changes */,
    'Enter a valid first name',
  ],
  lastName: [
    'Last name cannot be empty.',
    'Last name can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.).',
    'Please enter your last name.',
    'Last name can not contain more than 100 characters.' /* SUS-796 changes */,
    'Enter a valid last name',
  ],
  email: ['Email cannot be empty.', 'Please enter a valid email address.'],

  // Changed error message for password SUS-517
  password: [PASSWORD_ERROR_MESSAGE, 'Your passwords must match.'],
  username: [
    'Please enter a valid username.',
    'The username is currently not available. Please enter another username',
  ],
  timezone: ['You must select a timezone.'],
  googleCalendarId: ['This field cannot be empty.'],
  calendarWebsiteUrl: ['This field cannot be empty.'],
  calendlyCalendarUrl: ['This field cannot be empty.'],
  calendlyAccessToken: ['This field cannot be empty.'],
  outlookRefreshToken: ['This field cannot be empty.'],
  website: [
    'Website can not contain more than 100 characters.',
    'Enter a valid website',
  ] /* SUS-796 changes */,
  userTitle: [
    'Title can not contain more than 100 characters.',
    'Enter a valid title',
  ] /* SUS-796 changes */,
  nmls: [
    'NMLS can not contain more than 100 characters.',
  ] /* SUS-796 changes */,
  phone: ['Please enter a valid phone number.'],
};

const validateWorkingHours = (values: UserValues, errors: ErrorValues) => {
  workingDays.forEach(day => {
    const { value } = day;
    if (values?.workingHours?.[value] !== null) {
      const fromHours = values?.workingHours?.[value]?.from?.hours || '';
      const toHours = values?.workingHours?.[value]?.to?.hours || '';
      const fromMeridiemValue = values?.workingHours?.[value]?.from?.meridiem;
      const toMeridiemValue = values?.workingHours?.[value]?.to?.meridiem;

      if (fromHours === '') {
        errors.workingHours = {
          ...errors.workingHours,
          [value]: { from: { hours: 'From Field not be empty' } },
        };
      } else if (fromHours?.length < 5) {
        errors.workingHours = {
          ...errors.workingHours,
          [value]: { from: { hours: 'Please enter all digits' } },
        };
      } else if (toHours === '') {
        errors.workingHours = {
          ...errors.workingHours,
          [value]: { to: { hours: 'To Field not be empty' } },
        };
      } else if (toHours?.length < 5) {
        errors.workingHours = {
          ...errors.workingHours,
          [value]: { to: { hours: 'Please enter all digits' } },
        };
      }
      if (fromHours?.length === 5 && toHours?.length === 5) {
        const to = toHours.split(':').map((item: string) => Number(item));
        const from = fromHours.split(':').map((item: string) => Number(item));
        if (fromMeridiemValue === 'PM') {
          // add 12 if FROM value is PM
          from[0] = from[0] + 12;
        }
        if (toMeridiemValue === 'PM') {
          // add 12 if TO value is PM
          to[0] = to[0] + 12;
        }
        //compare hours
        if (from[0] > to[0]) {
          errors.workingHours = {
            ...errors.workingHours,
            [value]: { from: { hours: 'From field is greater than to' } },
          };
        }
        if (from[0] === to[0]) {
          //compare minutes if hours are equal
          if (from[1] >= to[1]) {
            errors.workingHours = {
              ...errors.workingHours,
              [value]: {
                from: { hours: 'From field is greater or equal than to' },
              },
            };
          }
        }
      }
    }
  });
  return;
};

export const validateUser = (values: UserValues, usernameFound: boolean) => {
  const errors: ErrorValues = {};
  if (!values.firstName) {
    errors.firstName = messages.firstName[0];
  }

  if (
    !values.lastName &&
    Number(values.resellerId) !== NAPLETON_AUTOMOTIVE_RESELLER_ID
  ) {
    errors.lastName = messages.lastName[0];
  }
  /* SUS-796 changes */
  if (values.firstName && values.firstName.length > 100) {
    errors.firstName = messages.firstName[3];
  }

  if (!validateInputForCsvFormulaInjection(values.firstName)) {
    errors.firstName = messages.firstName[4];
  }
  if (values.lastName && values.lastName.length > 100) {
    errors.lastName = messages.lastName[3];
  }
  if (!validateInputForCsvFormulaInjection(values.lastName)) {
    errors.lastName = messages.lastName[4];
  }
  /******/

  if (!values.email) {
    errors.email = messages.email[0];
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = messages.email[1];
  } else if (!validateInputForCsvFormulaInjection(values.email)) {
    errors.email = messages.email[1];
  }

  if (values.newPassword) {
    if (values.newPassword.length > 0 && values.newPassword.length < 8) {
      errors.newPassword = messages.password[0];
    } else if (
      values.newPassword.length > 0 &&
      !PASSWORD_REGEX.test(values.newPassword)
    ) {
      errors.newPassword = PASSWORD_FORMAT_ERROR;
    }
  }
  if (values.confirmPassword || values.newPassword) {
    if (
      !values.confirmPassword?.length ||
      values.newPassword !== values.confirmPassword
    ) {
      errors.confirmPassword = messages.password[1];
    }
  }

  values.username = values.username.trim();
  if (!values.username) {
    errors.username = messages.username[0];
  } else if (usernameFound) {
    errors.username = messages.username[1];
  } else if (!validateInputForCsvFormulaInjection(values.username)) {
    errors.username = messages.username[0];
  }

  if (
    values.platform == CalendarPlatform.GOOGLE.value &&
    values.googleCalendarId === ''
  ) {
    errors.googleCalendarId = messages.googleCalendarId[0];
  } else if (values.platform == CalendarPlatform.CALENDLY.value) {
    if (values.calendlyAccessToken === '')
      errors.calendlyAccessToken = messages.calendlyAccessToken[0];
    if (values.calendlyCalendarUrl === '')
      errors.calendlyCalendarUrl = messages.calendlyCalendarUrl[0];
  } else if (
    values.platform == CalendarPlatform.OUTLOOK.value &&
    values.outlookRefreshToken === ''
  ) {
    errors.outlookRefreshToken = messages.outlookRefreshToken[0];
  } else if (
    values.platform == CalendarPlatform.WEBSITE.value &&
    values.calendarWebsiteUrl === ''
  ) {
    errors.calendarWebsiteUrl = messages.calendarWebsiteUrl[0];
  }
  /* SUS-796 changes */
  if (values.website && values.website.length > 100) {
    errors.website = messages.website[0];
  } else if (!validateInputForCsvFormulaInjection(values.website)) {
    errors.website = messages.website[1];
  }
  if (values.userTitle && values.userTitle.length > 100) {
    errors.userTitle = messages.userTitle[0];
  } else if (!validateInputForCsvFormulaInjection(values.userTitle)) {
    errors.userTitle = messages.userTitle[1];
  }
  if (values.nmls && values.nmls.length > 100) {
    errors.nmls = messages.nmls[0];
  }

  /******/
  validateWorkingHours(values, errors);

  return errors;
};
