import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { DefaultLabel, Modal, NewTextInput } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { Button } from 'react-covideo-common';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 48px;
`;

const DELETE = 'DELETE';

interface Props {
  handleModalClose: () => void;
  count: number;
  deleteItemsHandler: () => void;
}

export const ModalRecentlyDeleted = ({
  handleModalClose,
  count,
  deleteItemsHandler,
}: Props) => {
  const [deleteConfirm, setDeleteConfirm] = useState('');

  return (
    <Modal>
      <ModalItem>
        <Header>
          Delete {count} items forever
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <DefaultLabel style={{ color: '#000' }}>
            Are you sure that you want to delete{' '}
            <b>
              {count} selected {count === 1 ? 'item' : 'items'}?
            </b>
            <br />
            This action can't be undone
          </DefaultLabel>
          <div style={{ marginTop: '25px' }}>
            <DefaultLabel
              style={{ color: '#E84C3D' }}
            >{`Type '${DELETE}' to confirm deletion`}</DefaultLabel>
            <NewTextInput
              style={{ marginTop: 4, width: '280px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDeleteConfirm(e.target.value)
              }
              value={deleteConfirm}
              placeholder={DELETE}
              autoFocus={true}
              error={deleteConfirm !== DELETE}
            />
          </div>
          <ButtonsWrapper>
            <Button
              text={`No, Keep in Bin`}
              onClick={handleModalClose}
              variant='ghost'
            />
            <Button
              text={`Yes, Delete Forever`}
              disabled={deleteConfirm !== DELETE}
              onClick={deleteItemsHandler}
              variant='red'
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
