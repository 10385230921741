import * as React from 'react';
import styled from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { Button } from 'react-covideo-common';
interface Props {
  onClose: (params?: any) => void;
  onDelete: (params?: any) => void;
  data: { [key: string]: any };
  modalLoading: boolean;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 592px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin: 0;
  padding: 0;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const DeleteTextInput = styled.input`
  width: 208px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 27, 83, 0.2);
  border-radius: 6px;
  margin-bottom: 22px;
`;

export const ModalDeleteAccount = (props: Props) => {
  const [inputVal, setInputVal] = React.useState('');
  const { data, onClose, onDelete, modalLoading } = props;

  const handleChange = (e: any) => {
    setInputVal(e.target.value);
  };
  return (
    <Modal>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={theme.palette.primaryThemeColor}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row justifyContent={'space-between'}>
          <Title>{data.title}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row>
          <Text>
            Are you sure you want to delete your account? All your data,
            <br /> including videos, will be deleted.
          </Text>
        </Row>
        <Row>
          <Text>Type ‘DELETE’ to confirm</Text>
        </Row>
        <Row>
          <FieldContainer>
            <DeleteTextInput
              placeholder='DELETE'
              type='text'
              value={inputVal}
              onChange={e => handleChange(e)}
            />
          </FieldContainer>
        </Row>
        <Row justifyContent={'flex-end'}>
          <Button
            variant='secondary'
            text={'No, Keep My Account'}
            onClick={() => onClose()}
          />
          <Button
            variant='destructive'
            text={'Yes, Delete Account'}
            onClick={() => onDelete()}
            disabled={inputVal !== 'DELETE'}
          />
        </Row>
      </Content>
    </Modal>
  );
};
