import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import DeleteIcon from 'lib/images/DeleteIcon';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  display: flex;
`;

const NameContainer = styled.div`
  height: 40px;
  border-radius: 6px;
  border: 1px solid ${theme.palette.covideoBlue20};
  padding: 8px 12px;
  box-sizing: border-box;
  width: 372px;
`;

type RegionProps = {
  name: string;
  onRemove: () => void;
};

const RegionCard = ({ name, onRemove }: RegionProps) => {
  return (
    <Container>
      <NameContainer>{name}</NameContainer>
      <Button
        onClick={onRemove}
        icon={<DeleteIcon height={20} color={theme.palette.red100} />}
        text='Remove'
        variant='destructive'
      />
    </Container>
  );
};

export default RegionCard;
