import styled from 'styled-components/macro';
import { ReactComponent as RenewSvg } from './renew.svg';

const RenewIcon = styled(RenewSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  g {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default RenewIcon;
