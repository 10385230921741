import { statuses } from 'app/pages/library/components/Constants';
import axios from 'axios';
import { removeAttachmentExpress } from 'lib/api';
import AttachmentPreview from 'lib/components/AttachmentPreview';
import FileProgress from 'lib/components/files/FileProgress';
import ModalAttachment from 'lib/components/modal/ModalAttachment';
import { errorToast } from 'lib/components/toasts/error';
import { useAuth, AttachedFile, FileUploadStatus } from 'lib/context';
import PaperclipIcon from 'lib/images/PaperclipIcon';
import {
  checkUploadedSize,
  EXCEEDED_MESSAGE,
  MAX_ATTACHMENT_SIZE,
  StatusOptions,
  uploadAttachmentWithProgress,
} from 'lib/utils/files';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';

const Container = styled.div``;

const AttachmentProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileProgressContainer = styled.div`
  margin: 0 50px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const AttachmentWrapper = styled.div`
  width: fit-content;
  margin-top: 16px;
`;

type Props = {
  attachments: AttachedFile[];
  setAttachments: React.Dispatch<React.SetStateAction<AttachedFile[]>>;
  attachmentUploadStatus: FileUploadStatus[];
  setAttachmentUploadStatus: React.Dispatch<
    React.SetStateAction<FileUploadStatus[]>
  >;
};

const Attachments = ({
  attachments,
  setAttachments,
  attachmentUploadStatus,
  setAttachmentUploadStatus,
}: Props) => {
  const { userData } = useAuth();
  // attachments
  const fileRef = useRef<HTMLInputElement>(null);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const hasFilesAddon = userData.droplrAccess;
  const [cancelUpload, setCancelUpload] = useState<any>(null);
  const [isChooseCanceled, setIsChooseCanceled] = useState(true);

  const isFilePickerDisabled =
    !hasFilesAddon &&
    !!attachmentUploadStatus?.filter(item => item.status === statuses.UPLOADING)
      .length;

  //
  // ATTACHMENTS
  // only for users without files addon
  const onChooseFile = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsChooseCanceled(false);
    const files = e.target.files;
    if (files && files.length) {
      const file = files[0];
      const uploadedSize = checkUploadedSize(attachments, MAX_ATTACHMENT_SIZE);
      if (uploadedSize.remaningSize < file.size) {
        errorToast({ title: EXCEEDED_MESSAGE });
        setIsAttachmentModalOpen(false);
        setIsChooseCanceled(true);
        return;
      }

      await onUploadAttachment(file);
    }
    setIsAttachmentModalOpen(false);
  };
  const onUploadAttachment = async (file: any) => {
    const fileIndex = attachments.length;
    const attachmentStatuses = [
      ...attachmentUploadStatus,
      {
        status: statuses.WAITING,
        currentUpload: 0,
        progress: 0,
      },
    ];

    const id = `attachments-${Math.floor(Math.random() * 1000)}`;
    let newAttachments = [
      ...attachments,
      {
        name: file.name,
        size: file.size,
        type: file.type,
        url: '', // will need to be updated
        id,
      },
    ];

    setAttachmentUploadStatus(attachmentStatuses);
    setAttachments(newAttachments);

    let itemStatuses = [...attachmentStatuses];
    const cancelToken = axios.CancelToken.source(); // in case download is canceled
    setCancelUpload(cancelToken);
    try {
      const uploadedFile = await uploadAttachmentWithProgress(
        file,
        (options: StatusOptions) => {
          const { status, currentUpload, progress } = options;
          itemStatuses = [...itemStatuses];
          const currentFileStatus = itemStatuses[fileIndex];

          itemStatuses[fileIndex] = {
            ...currentFileStatus,
            status: status || currentFileStatus.status,
            currentUpload: currentUpload || currentFileStatus.currentUpload,
            progress: progress || currentFileStatus.progress,
          };

          setAttachmentUploadStatus(itemStatuses);
        },
        cancelToken.token
      );
      if (uploadedFile) {
        // add missing url
        newAttachments = [...newAttachments];
        newAttachments[fileIndex].url = uploadedFile.url;
        setAttachments(newAttachments);
      }
    } catch (e) {}
  };

  const onRemoveAttachment = async (id: string, fileName: string) => {
    let filteredAttachments = [...attachments];
    const index = attachments.findIndex(item => item.id === id);

    if (index === -1) return;

    filteredAttachments.splice(index, 1);
    setAttachments(filteredAttachments);

    if (!hasFilesAddon) {
      // reset statuses
      let filteredStatuses = [...attachmentUploadStatus];
      filteredStatuses.splice(index, 1);
      setAttachmentUploadStatus(filteredStatuses);
      // cancel upload
      if (cancelUpload) cancelUpload.cancel();
      setIsAttachmentModalOpen(false);
    }

    try {
      await removeAttachmentExpress(fileName);
    } catch (e) {
      errorToast({ title: 'There was an error removing your attachment' });
    }
  };

  useEffect(() => {
    if (isAttachmentModalOpen && !hasFilesAddon && fileRef && fileRef.current) {
      // open file picker
      fileRef.current.click();
    }
  }, [isAttachmentModalOpen]);

  useEffect(() => {
    if (isAttachmentModalOpen && isChooseCanceled && !hasFilesAddon)
      setIsAttachmentModalOpen(false);
  }, [isAttachmentModalOpen, isChooseCanceled]);

  return (
    <Container>
      <>
        <AttachmentWrapper>
          <Button
            variant='secondary'
            disabled={isFilePickerDisabled}
            text='Add Attachments'
            onClick={() => setIsAttachmentModalOpen(true)}
            icon={<PaperclipIcon height={19} width={13} />}
          />
        </AttachmentWrapper>
        {!hasFilesAddon && (
          <input
            ref={fileRef}
            type='file'
            style={{
              display: 'none',
            }}
            disabled={hasFilesAddon || isFilePickerDisabled}
            onChange={onChooseFile}
          />
        )}
      </>

      <div
        style={{
          ...(attachments.length && {
            marginTop: '18px',
          }),
        }}
      >
        {attachments.map((attachment: AttachedFile) => {
          const index = attachments.findIndex(
            attach => attachment.id === attach.id
          );
          let progress = 0;
          let status = '';

          if (index > -1 && !hasFilesAddon && attachmentUploadStatus) {
            progress = attachmentUploadStatus[index]?.progress;
            status = attachmentUploadStatus[index]?.status;
          }

          return (
            <AttachmentProgressContainer key={attachment.id}>
              <AttachmentPreview
                onRemove={onRemoveAttachment}
                isRemoveDisabled={isFilePickerDisabled}
                attachment={attachment}
                style={{ marginBottom: '16px' }}
              >
                {!hasFilesAddon && status && progress < 100 && (
                  <FileProgressContainer>
                    <FileProgress progress={progress} max={100} />
                  </FileProgressContainer>
                )}
              </AttachmentPreview>
            </AttachmentProgressContainer>
          );
        })}
      </div>
      {isAttachmentModalOpen && hasFilesAddon && (
        <ModalAttachment
          handleModalClose={() => setIsAttachmentModalOpen(false)}
          setUploadedFiles={setAttachments}
          uploadedFiles={attachments}
          setAttachmentUploadStatus={setAttachmentUploadStatus}
        />
      )}
    </Container>
  );
};

export default Attachments;
