import { TableField } from 'lib/context';

export enum HistoryTableColumns {
  DATE = 'date',
  RECIPIENTS = 'recipients',
  ACTION = 'action',
}

export const tableFields: TableField[] = [
  {
    value: HistoryTableColumns.DATE,
    label: 'Sent Date',
    sortable: true,
    sortKey: 'DATE',
  },
  {
    value: HistoryTableColumns.RECIPIENTS,
    label: 'Recipient(s)',
  },
  {
    value: HistoryTableColumns.ACTION,
    label: '',
    height: '100%',
  },
];
