import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import CalendarIcon from 'lib/images/CalendarIcon';
import DatePicker from 'react-datepicker';
import { ButtonSwitch } from 'lib/components';
import ClockIcon from 'lib/images/ClockIcon';
import { combineDateAndTime } from 'lib/utils/datetime';
import { theme } from 'lib/style';

const CustomTimeWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const InputDateWrapper = styled.div<{ width?: string }>`
  position: relative;
  height: 40px;
  input {
    padding: 6px 8px 8px 40px;
    width: ${({ width }) => width};
    border-radius: 6px;
    border: 1px solid rgba(0, 27, 83, 0.2);

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${theme.palette.gray100};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: ${theme.palette.gray60};
  top: 8px;
  left: 8px;
  z-index: 1;
`;

const ErrorMessage = styled.div`
  margin-top: 10px;
  color: red;
`;
interface CustomDateTimePickerProps {
  setDate: (date: Date) => void;
  date: Date | null;
  useTimeSelectDropdown?: boolean;
  disabled?: boolean;
  hideDatePicker?: boolean;
  setErrorMessage?: (value: string) => void;
  errorMessage?: string;
}

const meridiemValues = [
  {
    value: 'am',
    text: 'AM',
  },
  {
    value: 'pm',
    text: 'PM',
  },
];

const isAmPm = (hours?: any) => {
  if (hours) {
    return hours >= 12 ? 'pm' : 'am';
  } else {
    return 'am';
  }
};

const CustomDateTimePicker = ({
  setDate,
  date,
  useTimeSelectDropdown = false,
  disabled = false,
  hideDatePicker = false,
  setErrorMessage,
  errorMessage,
}: CustomDateTimePickerProps) => {
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [meridiem, setMeridiem] = useState<string>('am');

  const handleDateTimeChange = (
    startDate: Date,
    startTime: Date,
    meridiem: string
  ) => {
    setDate(combineDateAndTime(startDate, startTime, meridiem));
  };

  const parseDate = (date: Date) => {
    return new Date(Date.parse(date as unknown as string));
  };

  useEffect(() => {
    if (date) {
      setMeridiem(isAmPm(parseDate(date).getHours()));
      setStartDate(parseDate(date));
      setStartTime(parseDate(date));
    }
  }, [date]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value || startDate.toString();
    if (setErrorMessage) {
      setErrorMessage(
        isNaN(Date.parse(inputValue)) ? 'Please enter a valid date/time' : ''
      );
    }
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (setErrorMessage) {
      setErrorMessage(
        !/^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(inputValue)
          ? 'Please enter a valid date/time'
          : ''
      );
    }
  };

  return (
    <>
      <CustomTimeWrapper>
        {!hideDatePicker && (
          <InputDateWrapper width='97px'>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>

            <DatePicker
              onChange={(date: Date) => {
                if (!date) {
                  return;
                }
                handleDateTimeChange(date, startTime, meridiem);
              }}
              onChangeRaw={handleDateChange}
              selected={startDate}
              minDate={new Date()}
              disabled={disabled}
            />
          </InputDateWrapper>
        )}
        <InputDateWrapper width='40px'>
          <IconWrapper>
            <ClockIcon />
          </IconWrapper>
          <DatePicker
            selected={startTime}
            onChange={(time: Date) => {
              if (!time) {
                return;
              }
              handleDateTimeChange(startDate, time, meridiem);
            }}
            onChangeRaw={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat='h:mm'
            timeCaption='Time'
            dateFormat='h:mm'
            open={useTimeSelectDropdown ? undefined : false}
            disabled={disabled}
          />
        </InputDateWrapper>
        <InputDateWrapper width='23px'>
          <ButtonSwitch
            key={meridiem}
            defaultValue={meridiem}
            values={meridiemValues}
            onChange={newValue => {
              handleDateTimeChange(startDate, startTime, newValue);
            }}
            disabled={disabled}
          />
        </InputDateWrapper>
      </CustomTimeWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default CustomDateTimePicker;
