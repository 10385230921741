import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { SMSUserState, GlobalAction } from '.';
import { smsReducer } from './smsReducer';

type Props = {
  children: ReactNode;
};

type ContextProps = {
  state: SMSUserState;
  dispatch: React.Dispatch<GlobalAction>;
};

const SmsUserContext = createContext({} as ContextProps);

const initialState: SMSUserState = {
  users: {
    items: [],
    count: 0,
    loading: true,
  },
  messages: {
    items: [],
    count: 0,
    loading: true,
  },
  overview: {
    sent: 0,
    received: 0,
    mostActiveUsers: [],
    chatCount: 0,
    unansweredChats: [[], 0],
    loading: true,
    graph: [],
  },
};

export const SmsUserContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(smsReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <SmsUserContext.Provider value={value}>{children}</SmsUserContext.Provider>
  );
};

export const useSmsUserContext = () => useContext(SmsUserContext);
