import { GlobalAction, SMSUserState } from '.';

const GET_SMS_USERS_START = 'GET_SMS_USERS_START';
const GET_SMS_USERS_ERROR = 'GET_SMS_USERS_ERROR';
const GET_SMS_USERS_SUCCESS = 'GET_SMS_USERS_SUCCESS';
const GET_SMS_USER_START = 'GET_SMS_USER_START';
const GET_SMS_USER_ERROR = 'GET_SMS_USER_ERROR';
const GET_SMS_USER_SUCCESS = 'GET_SMS_USER_SUCCESS';

const GET_MESSAGES_START = 'GET_MESSAGES_START';
const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
const GET_MESSAGE_START = 'GET_MESSAGE_START';
const GET_MESSAGE_ERROR = 'GET_MESSAGE_ERROR';
const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';

const GET_SMS_OVERVIEW_START = 'GET_SMS_OVERVIEW_START';
const GET_SMS_OVERVIEW_ERROR = 'GET_SMS_OVERVIEW_ERROR';
const GET_SMS_OVERVIEW_SUCCESS = 'GET_SMS_OVERVIEW_SUCCESS';

export const smsReducer = (
  state: SMSUserState,
  action: GlobalAction
): SMSUserState => {
  const { payload, type } = action;
  switch (type) {
    case GET_SMS_USERS_START:
    case GET_SMS_USER_START: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    }
    case GET_SMS_USERS_ERROR: {
      return {
        ...state,
        users: {
          ...state.messages,
          loading: false,
        },
      };
    }
    case GET_SMS_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          items: [...payload.users.items],
          count: parseInt(payload.count),
          loading: false,
        },
      };
    }
    case GET_MESSAGES_START:
    case GET_MESSAGE_START: {
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
        },
      };
    }
    case GET_MESSAGES_ERROR: {
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
        },
      };
    }
    case GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: {
          items: [...payload.messages.items],
          count: payload.count,
          loading: false,
        },
      };
    }
    case GET_SMS_OVERVIEW_START: {
      return {
        ...state,
        overview: {
          ...state.overview,
          loading: true,
        },
      };
    }
    case GET_SMS_OVERVIEW_ERROR: {
      return {
        ...state,
        overview: {
          ...state.overview,
          loading: false,
        },
      };
    }
    case GET_SMS_OVERVIEW_SUCCESS: {
      return {
        ...state,
        overview: {
          sent: payload.sent,
          received: payload.received,
          mostActiveUsers: payload.mostActiveUsers,
          chatCount: payload.chatCount,
          unansweredChats: payload.unansweredChats,
          loading: false,
          graph: payload.graph,
        },
      };
    }
    default:
      return state;
  }
};

export const SMS_USER_ACTIONS = {
  GET_SMS_USERS_START,
  GET_SMS_USERS_ERROR,
  GET_SMS_USERS_SUCCESS,
  GET_SMS_USER_START,
  GET_SMS_USER_ERROR,
  GET_SMS_USER_SUCCESS,
  GET_MESSAGES_START,
  GET_MESSAGES_ERROR,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGE_START,
  GET_MESSAGE_ERROR,
  GET_MESSAGE_SUCCESS,
  GET_SMS_OVERVIEW_START,
  GET_SMS_OVERVIEW_ERROR,
  GET_SMS_OVERVIEW_SUCCESS,
};
