import React from 'react';
import { VideosProps } from '../main/videoMerge';
import styled from 'styled-components/macro';
import { theme } from '../../../../../lib/style';
import { OnScreenPlayButton } from '../../videoPlayer/components';
import { Spinner } from 'lib/components';

type PlayerProps = {
  displayVideos: VideosProps[];
  width?: number;
  height?: number;
  hasProcessingVideos: boolean;
};

type VideoProps = {
  width?: number;
  height?: number;
};

const VideoContainer = styled.div<VideoProps>`
  position: relative;
  background-color: ${theme.palette.themeDark75Percent};
  border-radius: 6px;
  width: ${props => props.width || 600}px;
  height: ${props => props.height || 300}px;
`;

const ControlsContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  margin-top: -25px;
  z-index: 2;
`;

type SpinnerWrapperProps = {
  width?: number;
  height?: number;
};
const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
  background-color: ${theme.palette.themeDark};

  height: ${props => (props.height ? props.height + 'px' : '332px')};
  width: ${props => (props.width ? props.width + 'px' : '592px')};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Player = ({
  width,
  height,
  displayVideos,
  hasProcessingVideos,
}: PlayerProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const currentVideoIndex = React.useRef(0);
  const [_, setIsLoading] = React.useState(true);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [trimmedDuration, setTrimmedDuration] = React.useState(0);
  const [currentPlaylistTime, setCurrentPlaylistTime] = React.useState(0);

  React.useEffect(() => {
    currentVideoIndex.current = 0;
    let playlistDuration = 0;
    for (let i = 0; i < displayVideos.length; i++) {
      let videoStartTime = displayVideos[i].startSec
        ? displayVideos[i].startSec
        : 0;
      let videoEndTime = displayVideos[i].endSec
        ? displayVideos[i].endSec
        : displayVideos[i].duration;
      let videoDuration = videoEndTime - videoStartTime;
      playlistDuration += videoDuration;
    }
    setTrimmedDuration(playlistDuration || 1);
  }, [displayVideos]);

  const loadedmetadata = () => {
    setIsLoading(false);
  };
  const updateProgress = () => {
    if (videoRef.current) {
      const currentVideo = currentVideoIndex.current;
      const video = videoRef.current;
      let currentTime = video.currentTime;
      let videoStartTime = displayVideos[currentVideo].startSec
        ? displayVideos[currentVideo].startSec
        : 0;
      currentTime = currentTime - videoStartTime;
      currentTime = currentTime < 0 ? 0 : currentTime;

      for (let i = 0; i < currentVideo; i++) {
        let videoStartTime = displayVideos[i].startSec
          ? displayVideos[i].startSec
          : 0;
        let videoEndTime = displayVideos[i].endSec
          ? displayVideos[i].endSec
          : displayVideos[i].duration;
        let videoDuration = videoEndTime - videoStartTime;
        currentTime += videoDuration;
      }

      if (displayVideos[currentVideo].endSec <= video.currentTime) {
        playNextVideo();
      }
      setCurrentPlaylistTime(currentTime);
    }
  };

  const playNextVideo = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      setIsLoading(true);
      const currentPlaylistVideo = ++currentVideoIndex.current;
      if (displayVideos.length > currentPlaylistVideo) {
        video.src = displayVideos[currentPlaylistVideo].source;
        let videoStartTime = displayVideos[currentPlaylistVideo].startSec
          ? displayVideos[currentPlaylistVideo].startSec
          : 0;
        video.currentTime = videoStartTime;
      } else {
        currentVideoIndex.current = 0;
        video.src = displayVideos[0].source;
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const skip = (event: any) => {
    const skipTo = parseFloat(event.currentTarget.value);
    if (videoRef.current) {
      const video = videoRef.current;
      const currentVideo = currentVideoIndex.current;
      let videoStart = 0;
      let videoDurationStep = 0;
      for (let i = 0; i < displayVideos.length; i++) {
        let videoStartTime = displayVideos[i].startSec
          ? displayVideos[i].startSec
          : 0;
        let videoEndTime = displayVideos[i].endSec
          ? displayVideos[i].endSec
          : displayVideos[i].duration;
        let videoDuration = videoEndTime - videoStartTime;
        videoDurationStep += videoDuration;
        if (skipTo >= videoStart && skipTo < videoDurationStep) {
          if (currentVideo !== i) {
            setIsLoading(true);
            currentVideoIndex.current = i;
            video.src = displayVideos[i].source;
          }
          video.currentTime = skipTo - videoStart + videoStartTime;
          break;
        }
        videoStart = videoDurationStep;
      }
    }
  };
  const autoPlay = () => {
    if (videoRef.current && currentVideoIndex.current > 0 && isPlaying) {
      videoRef.current.play();
    }
  };

  const togglePlay = () => {
    const currentVideo = videoRef.current;
    if (currentVideo) {
      isPlaying ? currentVideo.pause() : currentVideo.play();
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <VideoContainer width={width} height={height}>
      {hasProcessingVideos ? (
        <SpinnerWrapper width={width} height={height}>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <video
          preload='metadata'
          width={width}
          height={height}
          ref={videoRef}
          onTimeUpdate={updateProgress}
          onLoadedMetadata={loadedmetadata}
          onEnded={playNextVideo}
          onCanPlayThrough={autoPlay}
          src={`${displayVideos[currentVideoIndex.current].source}`}
          onClick={togglePlay}
        />
      )}
      {!hasProcessingVideos && (
        <OnScreenPlayButton
          playerBackgroundColor='#fff'
          playButtonPosition='center'
          isVideoPaused={!isPlaying}
          togglePlay={togglePlay}
          playerIconsColor={theme.palette.themeDark75Percent}
        />
      )}
      <ControlsContainer>
        <Input
          onChange={e => {
            skip(e);
          }}
          value={currentPlaylistTime}
          max={trimmedDuration}
          min='0'
          type='range'
          step='0.01'
        />
      </ControlsContainer>
    </VideoContainer>
  );
};
