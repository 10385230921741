import LinkExternalIcon from '../images/LinkExternalIcon';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import FilePreviewModal from '../components/modal/FilePreviewModal';
import { ModalVideoListVideo } from './modal';
import { Button } from 'react-covideo-common';
import { Spinner } from './Spinner';
import { theme } from 'lib/style';

const Container = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border: 1px solid #f7f8fa;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid transparent;
  margin-bottom: 8px;
`;

const SrcContainer = styled.div`
  height: 100%;
  width: 96px;
  position: relative;
  cursor: pointer;
`;
const Image = styled.img`
  height: 100%;
  width: 96px;
  object-fit: contain;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    height: 30px;
    width: 30px;
  }
`;
const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  display: flex;
  justify-content: center;
  align-items: center;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 96px);
  padding: 0 12px 0 24px;
  font-weight: 600;
`;

const TitleContainer = styled.div`
  max-width: 230px;
  max-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

type FileCardProps = {
  id: string;
  title: string;
  src: string;
  icon?: JSX.Element;
  onSelectDrop: (arg1: any) => void;
  previewSrc?: string;
  type: string;
  disabled?: boolean;
  isUploading?: boolean;
};

const FileCard = ({
  title,
  src,
  onSelectDrop,
  id,
  icon,
  previewSrc,
  type,
  disabled,
  isUploading,
}: FileCardProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const onFilePreview = () => {
    if (src) {
      setIsPreviewOpen(true);
    }
  };
  return (
    <>
      <Container>
        <SrcContainer onClick={onFilePreview}>
          {!src ? (
            <IconContainer>{icon}</IconContainer>
          ) : (
            <Image src={src} alt='File' />
          )}
          {src && (
            <OverlayContainer>
              <LinkExternalIcon color='white' height='18px' width='18px' />
            </OverlayContainer>
          )}
        </SrcContainer>
        <BodyContainer>
          <TitleContainer>{title}</TitleContainer>
          <Button
            variant='secondary'
            onClick={() => {
              if (disabled) return;
              onSelectDrop(id);
            }}
            text={isUploading ? 'Uploading...' : 'Select file'}
            disabled={disabled}
            icon={
              isUploading ? (
                <SpinnerContainer>
                  <Spinner color={theme.palette.covideoBlue100} size={8} />
                </SpinnerContainer>
              ) : (
                <></>
              )
            }
          />
        </BodyContainer>
      </Container>

      {isPreviewOpen && !type.includes('video') && (
        <FilePreviewModal
          handleModalClose={() => setIsPreviewOpen(false)}
          src={previewSrc || src}
        />
      )}

      {isPreviewOpen && type.includes('video') && (
        <ModalVideoListVideo
          videoId={''}
          source={previewSrc || src}
          handleModalClose={() => setIsPreviewOpen(false)}
        />
      )}
    </>
  );
};

export default FileCard;
