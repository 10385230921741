import React, { useState } from 'react';

import { ModalVideoListVideo } from '../modal';
import { HelpDeskWrapper, ItemParagraph, ItemWrapper } from './index.styled';
import { items } from './const';
import { HelpDeskTypes, IHelpDeskItem, ItemsTypes } from './utils';
import { useAuth } from 'lib/context';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

export const HelpDesk = ({ name }: { name: HelpDeskTypes }) => {
  const [modal, setModal] = useState({ show: false, url: '' });
  const { userData, whitelabel } = useAuth();

  const selectedItem = items?.[name];
  if (
    !(name || !selectedItem) ||
    !(whitelabel && whitelabel.name === WHITELABEL_NAME.COVIDEO)
  ) {
    return <div style={{ height: 83 }}></div>;
  }
  return (
    <HelpDeskWrapper name={name}>
      {selectedItem.map(
        ({ icon, title, url, type, id, automotiveUrl }: IHelpDeskItem) => {
          const videoUrl = userData.isAutomotive ? automotiveUrl : url;
          return (
            <ItemWrapper
              key={id}
              onClick={() =>
                type === ItemsTypes.LINK
                  ? window.open(url, '_blank', 'noopener, noreferrer')
                  : setModal({ show: true, url: videoUrl || url })
              }
            >
              {icon}
              <ItemParagraph>{title}</ItemParagraph>
            </ItemWrapper>
          );
        }
      )}
      {modal.show && (
        <ModalVideoListVideo
          videoId=''
          source={modal.url}
          handleModalClose={() => {
            setModal({ show: false, url: '' });
          }}
        />
      )}
    </HelpDeskWrapper>
  );
};
