import React, { useRef, useState } from 'react';
import {
  ModalBody,
  ModalFullscreen,
} from 'app/pages/library/videos/videoList/components/SendAndShare';
import { LoadingIndicator } from 'lib/components';
import { useGetMeetingDetailsQuery } from 'lib/api/meetings/useGetMeetingDetailsQuery';
import { MEETING_STATUSES, MeetingStatus } from 'lib/api/meetings/types';
import { SendEmailNotificationsMeetingModal } from 'app/pages/meetings/components/modals/SendEmailNotificationsMeetingModal';
import duration from 'dayjs/plugin/duration';
import { MeetingsDetailsHeader } from '../components/MeetingsDetailsHeader';
import { MeetingDetailsBodyWrapper } from '../styles';
import { MEETING_TABS } from '../constants';
import { MeetingTabChat } from '../meetingTabs/MeetingTabChat';
import { MeetingTabRecording } from '../meetingTabs/MeetingTabRecording';
import { MeetingTabDetails } from '../meetingTabs/MeetingTabDetails';
import { Form, Formik, FormikProps } from 'formik';
import dayjs from 'dayjs';
import { useAuth } from 'lib/context';
import { TimezonesOption } from 'app/pages/account/userAccount/schedule/types';

import { useGetUsersCustomerQuery } from 'lib/api/users/getUsersCustomers';
import { getDisplayName } from 'lib/utils/functions';
import {
  getCustomDateTimePickerValue,
  getDeliveryTime,
  getDurationHours,
  getDurationMinutes,
  getUserTimezone,
} from '../utils';
import { useUpdateMeetingMutation } from 'lib/api/meetings/useUpdateMeetingMutation';
import { useCreateMeetingMutation } from 'lib/api/meetings/useCreateMeetingMutation';
import { DeleteMeetingModal } from '../components/modals/DeleteMeetingModal';
import { CancelMeetingModal } from '../components/modals/CancelMeetingModal';
import { useSendInviteMeetingMutation } from 'lib/api/meetings/useSendInviteMeetingMutation';

dayjs.extend(duration);
type Props = {
  meetingId: string | null;
  createMeetingStatus: MeetingStatus | null;
  handleCloseFullScreenModal: () => void;
  userId: string;
};

interface ICreateMeetingPayload {
  meetingStatus: MEETING_STATUSES;
  duration: number;
  attendees: { email: string }[];
  title: string;
  description: string;
  userId: number;
  deliveryTime: string;
  enableGuestScreenShare: boolean;
  hostApproval: boolean;
  frequency: string | undefined;
}

interface IUpdateMeetingPayload {
  meetingId: string;
  title: string;
  description: string;
  duration: number;
  userId: number;
  deliveryTime: string;
  enableGuestScreenShare: boolean;
  hostApproval: boolean;
  attendees: { email: string }[];
  frequency: string | undefined;
}

export type FormikMeetingsValues = {
  meetingStatus: string | undefined;
  meetingId: string | null;
  title: string;
  description: string;
  duration: number;
  userId: number;
  deliveryTime: string;
  enableGuestScreenShare: boolean;
  hostApproval: boolean;
  attendees: { email: string }[];
  // formik helpers fields
  durationHours: { value: number; label: string };
  durationMinutes: { value: number; label: string };
  preferedUserTimezone: TimezonesOption | null;
  customDateTimePicker: Date;
  sendEmail: boolean;
  frequency: string | undefined;
};

export type ModalMeetingsDetails =
  | 'send-notifications'
  | 'delete'
  | 'cancel'
  | null;

export const MeetingDetails = ({
  meetingId,
  createMeetingStatus,
  handleCloseFullScreenModal,
  userId,
}: Props) => {
  const [activeTab, setActiveTab] = useState(MEETING_TABS.DETAILS.value);
  const { userData } = useAuth();
  const { data: meetingDetails, isLoading } = useGetMeetingDetailsQuery(
    meetingId || ''
  );
  const { isCompanyAdmin, customerId, timezone } = userData;
  const { data: companyUsers = [] } = useGetUsersCustomerQuery(
    { customerId },
    !!isCompanyAdmin
  );
  const isRecurring =
    createMeetingStatus === MEETING_STATUSES.RECURRING ||
    meetingDetails?.meetingStatus === MEETING_STATUSES.RECURRING;

  const formikRef = useRef<React.Ref<FormikProps<FormikMeetingsValues>>>();

  const [showDetailsModals, setShowDetailsModals] =
    useState<ModalMeetingsDetails>(null);

  const handleCloseAllModals = () => {
    setShowDetailsModals(null);
    handleCloseFullScreenModal();
  };

  const { mutateAsync: updateMeetingAsync } =
    useUpdateMeetingMutation(handleCloseAllModals);

  const { mutateAsync: createMeetingAsync } =
    useCreateMeetingMutation(handleCloseAllModals);

  const { mutateAsync: sendInvite } = useSendInviteMeetingMutation();

  const onSubmitHandler = async (values: FormikMeetingsValues) => {
    const minutes = values.durationMinutes.value;
    const hours = values.durationHours.value;
    const duration = hours * 60 + minutes;
    if (meetingId) {
      const payload: IUpdateMeetingPayload = {
        meetingId,
        title: values.title,
        description: values.description,
        duration,
        userId: values.userId,
        deliveryTime: values.deliveryTime,
        enableGuestScreenShare: values.enableGuestScreenShare,
        hostApproval: values.hostApproval,
        frequency: values.frequency,
        attendees: values.attendees,
      };
      updateMeetingAsync({
        meetingData: payload,
        ...(values.sendEmail ? { sendEmail: true } : {}),
      });
      return;
    }

    const payload: ICreateMeetingPayload = {
      title: values.title,
      description: values.description,
      duration,
      userId: values.userId,
      deliveryTime: values.deliveryTime,
      enableGuestScreenShare: values.enableGuestScreenShare,
      hostApproval: values.hostApproval,
      attendees: values.attendees,
      frequency: values.frequency,
      meetingStatus: isRecurring
        ? MEETING_STATUSES.RECURRING
        : MEETING_STATUSES.SCHEDULED,
    };
    const meeting = await createMeetingAsync({ ...payload });
    if (meeting?.attendees?.length) {
      await sendInvite(meeting.meetingId);
    }
  };

  if (isLoading) {
    return (
      <ModalFullscreen>
        <LoadingIndicator isLoading={true} height='300px' />
      </ModalFullscreen>
    );
  }

  const INITIAL_FORMIK_VALUES: FormikMeetingsValues = {
    meetingId: meetingDetails?.meetingId || null,
    meetingStatus: meetingDetails?.meetingStatus,
    duration: meetingDetails?.duration || 0,
    attendees:
      meetingDetails?.attendees.map(email => ({ email: email.email })) || [],
    title: meetingDetails?.title || '',
    description: meetingDetails?.description || '',
    userId: meetingDetails?.userId || Number(userId),
    deliveryTime:
      meetingDetails?.deliveryTime || getDeliveryTime(meetingDetails, timezone),
    enableGuestScreenShare: meetingDetails?.enableGuestScreenShare || false,
    hostApproval: meetingDetails?.hostApproval || false,
    // formik helpers filed
    durationHours: getDurationHours(meetingDetails),
    durationMinutes: getDurationMinutes(meetingDetails),
    preferedUserTimezone: getUserTimezone(meetingDetails, timezone),
    customDateTimePicker: getCustomDateTimePickerValue(meetingDetails),
    sendEmail: false,
    frequency: meetingDetails?.frequency,
  };

  const userOptions = companyUsers.map(user => ({
    value: user.id.toString(),
    label: getDisplayName([
      user.id.toString() === userData.id.toString() ? '(Me)' : '',
      user.lastName,
      user.firstName,
      user.email && (user.lastName || user.firstName)
        ? `- ${user.email}`
        : user.email,
    ]),
  }));

  return (
    <>
      <ModalFullscreen>
        {showDetailsModals === 'delete' && meetingId && (
          <DeleteMeetingModal
            meetingId={meetingId}
            handleModalClose={() => setShowDetailsModals(null)}
            handleCloseAllModals={handleCloseAllModals}
          />
        )}

        {showDetailsModals === 'cancel' && meetingId && (
          <CancelMeetingModal
            attendees={meetingDetails?.attendees || []}
            meetingId={meetingId}
            handleModalClose={() => setShowDetailsModals(null)}
            handleCloseAllModals={handleCloseAllModals}
          />
        )}

        <MeetingsDetailsHeader
          ref={formikRef}
          handleCloseFullScreenModal={handleCloseFullScreenModal}
          meetingDetails={meetingDetails}
          meetingId={meetingId}
          setShowDetailsModals={setShowDetailsModals}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isRecurring={isRecurring}
        />

        <ModalBody>
          {MEETING_TABS.DETAILS.value === activeTab && (
            <Formik
              initialValues={INITIAL_FORMIK_VALUES}
              onSubmit={onSubmitHandler}
              innerRef={
                formikRef as React.Ref<FormikProps<FormikMeetingsValues>>
              }
            >
              {({ handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <MeetingTabDetails
                      meetingDetails={meetingDetails}
                      userOptions={userOptions}
                      createMeetingStatus={createMeetingStatus}
                    />
                    {showDetailsModals === 'send-notifications' && (
                      <SendEmailNotificationsMeetingModal
                        handleModalClose={handleCloseAllModals}
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
          {MEETING_TABS.RECORDING.value === activeTab && (
            <MeetingDetailsBodyWrapper>
              <MeetingTabRecording meetingId={meetingId} />
            </MeetingDetailsBodyWrapper>
          )}
          {MEETING_TABS.CHAT.value === activeTab && (
            <MeetingDetailsBodyWrapper>
              <MeetingTabChat meetingId={meetingId} />
            </MeetingDetailsBodyWrapper>
          )}
        </ModalBody>
      </ModalFullscreen>
    </>
  );
};
