export const VDP_IntegrationTypes: {
  [key: 'homenet' | 'ipacket' | 'salespages' | string]: {
    value: string;
    label: string;
  };
} = {
  homenet: { value: 'homenet', label: 'HomeNet' },
  ipacket: { value: 'ipacket', label: 'iPacket' },
  salespages: { value: 'salespages', label: 'Landing Page' },
};
