import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';
import { ICombinedUserData } from 'lib/context';

export const calculateDiff = (pastDate: string) => {
  const now = dayjs();
  const diffInSeconds = now.diff(pastDate, 'second', true);

  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_WEEK = 604800;
  const SECONDS_IN_MONTH = 2592000;
  const SECONDS_IN_YEAR = 31536000;

  if (diffInSeconds < SECONDS_IN_MINUTE) {
    return 'just now';
  }

  if (diffInSeconds < SECONDS_IN_HOUR) {
    const minutes = Math.floor(diffInSeconds / SECONDS_IN_MINUTE);
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }

  if (diffInSeconds < SECONDS_IN_DAY) {
    const hours = Math.floor(diffInSeconds / SECONDS_IN_HOUR);
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  }

  if (diffInSeconds < SECONDS_IN_WEEK) {
    const days = Math.floor(diffInSeconds / SECONDS_IN_DAY);
    return `${days} day${days === 1 ? '' : 's'} ago`;
  }

  if (diffInSeconds < SECONDS_IN_MONTH) {
    const weeks = Math.floor(diffInSeconds / SECONDS_IN_WEEK);
    return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
  }

  if (diffInSeconds < SECONDS_IN_YEAR) {
    const months = Math.floor(diffInSeconds / SECONDS_IN_MONTH);
    return `${months} month${months === 1 ? '' : 's'} ago`;
  }

  const years = Math.floor(diffInSeconds / SECONDS_IN_YEAR);
  return `${years} year${years === 1 ? '' : 's'} ago`;
};

export const isMessageRead = (readAt: string | null, createdAt: string) => {
  if (readAt === null) {
    return true;
  }
  const diff = dayjs(readAt).diff(dayjs(createdAt), 'millisecond');
  return diff >= 0 ? true : false;
};

export const serverTimeToUserTime = (
  time: string,
  userData: ICombinedUserData,
  format = 'YYYY-MM-DD HH:mm:ss',
  useProfileTimezone = true
) => {
  let userTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || dayjs.tz.guess();

  if (useProfileTimezone && userData.timezone) {
    userTimeZone = userData.timezone;
  }

  const videoDate = dayjs(time, [
    'M/DD/YYYY h:mm:ss A',
    'M/DD/YYYY, h:mm:ss A',
    'M/D/YYYY h:mm:ss A',
    'M/D/YYYY, h:mm:ss A',
    STANDARD_DATE_FORMAT,
  ]);

  const serverUtcOffset = -dayjs()
    .tz('America/New_York')
    .utcOffset();

  const userTimezoneOffset = dayjs()
    .tz(userTimeZone)
    .utcOffset();

  const utcDateTime = videoDate.add(serverUtcOffset, 'minute');

  return dayjs(utcDateTime)
    .add(userTimezoneOffset, 'minute')
    .format(format);
};

export const utcTimeToUserTime = (
  time: string,
  userData: ICombinedUserData,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const userTimeZone =
    userData.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone ||
    dayjs.tz.guess();

  return dayjs(time)
    .tz(userTimeZone)
    .format(format);
};

export const utcAnalyticsTimeToUserTime = (
  time: string,
  userData: ICombinedUserData,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const userTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || dayjs.tz.guess();
  return dayjs
    .utc(time)
    .tz(userTimeZone)
    .format(format);
};
