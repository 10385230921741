import * as React from 'react';
import styled from 'styled-components/macro';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Formik, ErrorMessage, Field, FieldProps, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import { TextInput, CheckboxInput, LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import { createUser } from 'lib/api';
import { UpgradeLayout } from './../../upgrade/upgrades/components';
import { getCouponDetails } from 'lib/api';
import { updateAxiosInstaceToken } from 'configs/api/helpers';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { useToastError } from 'lib/hooks';
import LogoIcon from 'lib/images/LogoIcon';
import { theme } from 'lib/style';
import { subscriptionDataType } from 'lib/const/Subscription';
import { PASSWORD_ERROR_MESSAGE } from 'lib/const/SuperAdminConstants';
import { Button } from 'react-covideo-common';

interface InputContainerProps {
  width?: string;
}

const Layout = styled.div`
  color: #4e5461;
  background-color: ${theme.palette.neutral10};
  min-height: 100vh;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  align-items: center;
  padding: 15px;
  width: 100vw;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
`;

const H3 = styled.h3`
  font-weight: 800;
  color: #001b53;
  font-size: 24px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #9297a2;
`;

const UserExistsError = styled.div`
  font-size: 13px;
  color: red;
  font-weight: 400;
  margin-top: 10px;
`;

const FlexForm = styled(Form)`
  width: 600px;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 16px;
  background-color: ${theme.palette.white};
  padding: 20px;
  margin-top: 32px;
  row-gap: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 400px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 300px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 600px;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 400px;
    .margin-top {
      margin-top: 4%;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 300px;
  }
`;

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  .label {
    color: #4e5461;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  @media (max-width: 800px) {
    width: 400px;
  }
  @media (max-width: 600px) {
    width: 300px;
  }
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  .error {
    color: red;
    font-size: 14px;
    padding: 0.1rem;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const EyeField = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  .hyperlink {
    text-decoration: none;
    color: ${props => props.theme.colors.primary[100]};
  }
  @media (max-width: 800px) {
    width: 410px;
  }
  @media (max-width: 600px) {
    font-size: 11.5px;
    width: 350px;
    .gHXTPM {
      width: 16px;
      height: 16px;
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  min-height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const fieldsSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name is too small'),
  email: Yup.string().email('Email is not valid').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, PASSWORD_ERROR_MESSAGE),
  business: Yup.string()
    .required('Company name is required')
    .min(2, 'Company name is too small'),
  url: Yup.string(),
  phone1: Yup.string()
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .required('Your phone no. cannot be empty'),
});

enum SignupScreen {
  PACKAGE_DETAILS = 1,
  SIGNUP = 2,
  PAYMENT = 3,
  EMAIL_VERIFICATION = 4,
}

export const Signup = () => {
  const [loading, setLoading] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [currentView, setCurrentView] = React.useState(
    SignupScreen.PACKAGE_DETAILS
  );
  const [passwordView, setPasswordView] = React.useState('password');
  const [code, setCode] = React.useState('');
  const [couponData, setCouponData] = React.useState<any>({});
  const [showUserExistsError, setShowUserExistsError] = React.useState(false);
  const [signupData, setSignupData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    business: '',
    url: '',
    phone1: '',
  });
  const { whitelabel } = useAuth();
  const { showError } = useToastError();
  const location = useLocation();
  const isBuy =
    window.location.pathname && window.location.pathname.includes('signup');

  const [subscriptionData, setSubscriptionData] = React.useState<
    subscriptionDataType | undefined
  >(undefined);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const response = await createUser(signupData);
      setLoading(false);

      // Code added for Affiliate Tracking
      if (
        response &&
        response.accessToken &&
        signupData.email &&
        window &&
        // @ts-ignore
        window.fpr
      ) {
        try {
          // @ts-ignore
          window.fpr('referral', { email: signupData.email });
        } catch (ex) {}
      }

      if (isBuy) {
        updateAxiosInstaceToken(response.accessToken);
        setCurrentView(SignupScreen.PAYMENT);
        if (code) setTimeout(() => populateCouponData(), 200);
        return;
      } else {
        successToast({ title: `User created successfully` });
      }
      setTimeout(() => {
        window.location.href = 'https://www.covideo.com/login/';
      }, 3000);
    } catch (error) {
      setLoading(false);
      if (
        error.response.data.name === 'Error' &&
        error.response.data.message === 'User already exists'
      )
        setShowUserExistsError(true);

      // Show error message on Signup Page other than User already Exists SUS-517
      if (
        error.response.data.name === 'Error' &&
        error.response.data.message !== 'User already exists'
      ) {
        errorToast({ title: error.response.data.message });
      }
    }
  };

  const handleContinue = () => {
    fieldsSchema
      .validate(signupData)
      .then(async function () {
        await onSubmit();
      })
      .catch(function (err) {
        setLoading(false);
        showError(err);
      });
  };

  const populateCouponData = async (couponCode: string = '') => {
    try {
      if (couponCode === '') {
        couponCode = code;
      }

      const couponDataTmp = await getCouponDetails(couponCode);
      if (couponDataTmp && Object.keys(couponDataTmp).length) {
        setCouponData(couponDataTmp);
      }
    } catch (error) {
      console.log('invalid coupon');
    }
  };

  React.useEffect(() => {
    const jwt = Cookies.get('jwt');
    if (jwt) {
      setCurrentView(SignupScreen.PAYMENT);
    }

    if (code !== '') {
      populateCouponData(code);
    }
  }, [code]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const codeTmp = urlParams.get('code') || '';
    if (codeTmp) {
      setCode(codeTmp);
    }
  }, []);

  return (
    <Layout>
      <Wrapper>
        <LogoWrapper>
          <a href={whitelabel.homepage}>
            <LogoIcon height='40px' />
          </a>
        </LogoWrapper>
        {loading && (
          <LoaderContainer>
            <LoadingIndicator isLoading={true} />
          </LoaderContainer>
        )}

        {(currentView === SignupScreen.PACKAGE_DETAILS ||
          currentView === SignupScreen.PAYMENT) && (
          <UpgradeLayout
            signupPage={true}
            couponData={couponData}
            couponCode={code}
            prevSubscriptionData={subscriptionData}
            onSignup={newSubscriptionData => {
              setSubscriptionData(newSubscriptionData);
              setCurrentView(SignupScreen.SIGNUP);
            }}
            showPayment={currentView === SignupScreen.PAYMENT}
          />
        )}

        {currentView === SignupScreen.SIGNUP && (
          <>
            <H3>Welcome to Covideo!</H3>
            <Description>Unlimited video recording & sending.</Description>
            <Formik
              enableReinitialize
              initialValues={signupData}
              validationSchema={fieldsSchema}
              onSubmit={() => {}}
            >
              {({ values }) => (
                <FlexForm>
                  <Row>
                    <InputContainer width='292px'>
                      <div className='label'>First name</div>
                      <div>
                        <Field name='firstName'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='firstName'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                    </InputContainer>
                    <InputContainer width='292px' className='margin-top'>
                      <div className='label'>Last name</div>
                      <div>
                        <Field name='lastName'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='lastName'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                    </InputContainer>
                  </Row>
                  <Row>
                    <InputContainer width='600px'>
                      <div className='label'>Email</div>
                      <div>
                        <Field name='email'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='email'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                      {showUserExistsError && (
                        <UserExistsError>
                          Email address is already registered.<br></br>
                          <a
                            href='https://www.covideo.com/login/'
                            style={{ color: 'red', fontWeight: 600 }}
                          >
                            Log in here
                          </a>{' '}
                          or contact us at 1-800-306-1445 or{' '}
                          <a
                            href='mailto:support@covideo.com'
                            style={{ color: 'red', fontWeight: 600 }}
                          >
                            support@covideo.com
                          </a>
                        </UserExistsError>
                      )}
                    </InputContainer>
                  </Row>
                  <Row>
                    <InputContainer width='600px'>
                      <div className='label'>Password</div>
                      <div style={{ position: 'relative' }}>
                        <Field name='password'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type={passwordView}
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='password'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                        <EyeField
                          onClick={() => {
                            setPasswordView(
                              passwordView == 'password' ? 'text' : 'password'
                            );
                          }}
                        >
                          {passwordView == 'password' ? (
                            <IoMdEyeOff />
                          ) : (
                            <IoMdEye />
                          )}
                        </EyeField>
                      </div>
                    </InputContainer>
                  </Row>
                  <Row>
                    <InputContainer width='292px'>
                      <div className='label'>Company Name</div>
                      <div>
                        <Field name='business'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='business'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                    </InputContainer>
                    <InputContainer width='292px' className='margin-top'>
                      <div className='label'>Website URL</div>
                      <div>
                        <Field name='url'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={false}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage name='url' component={ErrorMsg} />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                    </InputContainer>
                  </Row>
                  <Row>
                    <InputContainer width='292px'>
                      <div className='label'>Phone</div>
                      <div>
                        <Field name='phone1'>
                          {({ field }: FieldProps) => {
                            return (
                              <FieldContainer>
                                <TextInput
                                  {...field}
                                  type='text'
                                  required={true}
                                  autoFocus={false}
                                  disabled={loading}
                                  onKeyUp={() => {
                                    // setUser({ ...values });
                                    setSignupData({ ...values });
                                  }}
                                />
                                <ErrorMessage
                                  name='phone1'
                                  component={ErrorMsg}
                                />
                              </FieldContainer>
                            );
                          }}
                        </Field>
                      </div>
                    </InputContainer>
                  </Row>

                  <CheckboxWrapper>
                    <CheckboxInput
                      checked={agree}
                      className={'check123'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let { checked } = e.target;
                        setAgree(checked);
                      }}
                    />
                    &nbsp;I agree to{' '}
                    <a
                      href='https://www.covideo.com/terms-and-conditions/'
                      className='hyperlink'
                      target='_blank'
                      rel='noreferrer'
                    >
                      &nbsp;Terms & Conditions&nbsp;
                    </a>{' '}
                    and{' '}
                    <a
                      href='https://www.covideo.com/privacy/'
                      className='hyperlink'
                      target='_blank'
                      rel='noreferrer'
                    >
                      &nbsp;Privacy Policy
                    </a>
                  </CheckboxWrapper>
                  <Row>
                    <Button
                      text={'Continue'}
                      type='submit'
                      disabled={!agree || loading}
                      onClick={handleContinue}
                    />
                  </Row>
                </FlexForm>
              )}
            </Formik>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};
