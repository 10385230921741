import { VDP_IntegrationTypes } from 'lib/const/vdp';
import { ICombinedUserData } from 'lib/context';

export const createVDPIntegrationOptions = (userData: ICombinedUserData) => {
  const hasHomenet = !!userData.customer.dealerComId;
  const hasIPacket = !!userData.customer.iPacketDealerId;

  const options = [];
  if (hasHomenet) {
    options.push(VDP_IntegrationTypes.homenet);
  }

  if (hasIPacket) {
    options.push(VDP_IntegrationTypes.ipacket);
  }
  options.push(VDP_IntegrationTypes.salespages);

  return options;
};
