import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { RiPencilFill } from 'react-icons/ri';
import DeleteIcon from 'lib/images/DeleteIcon';
import { priceFormatter } from 'lib/utils/functions';
import { InventoryItem } from 'lib/context';
import { Button } from 'react-covideo-common';

const Wrapper = styled.div`
  padding: 16px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 5px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const TitleWrapper = styled.div`
  display: block;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;

const Price = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const VehicleInfoGrid = styled.div`
  display: grid;
  row-gap: 24px;
  column-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const GridItem = styled.div`
  display: block;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;
const vehicleProperties = [
  { value: 'vin', label: 'VIN' },
  { value: 'stockNumber', label: 'Stock Number' },
  { value: 'itemCondition', label: 'Condition' },
  { value: 'engine', label: 'Engine' },
  { value: 'make', label: 'Make' },
  { value: 'model', label: 'Model' },
  { value: 'year', label: 'Year' },
  { value: 'mileage', label: 'Mileage' },
  { value: 'trim', label: 'Trim' },
  { value: 'exteriorColor', label: 'Ext. Color' },
  { value: 'interiorColor', label: 'Int. Color' },
  { value: 'transmission', label: 'Transmission' },
];

type Props = {
  vehicle: InventoryItem;
  onChangeVehicle: (vehicle: InventoryItem) => void;
};

export const VehiclePropertiesGrid = ({ vehicle, onChangeVehicle }: Props) => {
  const priceAvailable =
    !!vehicle.price &&
    !!parseInt(vehicle.price) &&
    !isNaN(parseInt(vehicle.price));
  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{vehicle.title}</Title>
          {priceAvailable && (
            <Price>{priceFormatter(parseInt(vehicle.price))}</Price>
          )}
          {!priceAvailable && <Price>Please call for price</Price>}
        </TitleWrapper>
        <ButtonsWrapper>
          <Button
            variant='secondary'
            onClick={() => onChangeVehicle(vehicle)}
            text='Change Vehicle'
            icon={<RiPencilFill onClick={() => {}} size={20} />}
          />
          <Button
            variant='destructive'
            onClick={() => onChangeVehicle(vehicle)}
            icon={<DeleteIcon color={theme.palette.buttonDelete} />}
          />
        </ButtonsWrapper>
      </Header>
      <VehicleInfoGrid>
        {vehicleProperties.map((property, i) => (
          <GridItem key={i}>
            <Label>{property.label}</Label>
            <Value>{vehicle[property.value as keyof InventoryItem]}</Value>
          </GridItem>
        ))}
      </VehicleInfoGrid>
    </Wrapper>
  );
};
