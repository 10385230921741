import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import {
  ButtonSwitch,
  Dropdown,
  LoadingIndicator,
  Modal,
  Search,
} from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CtaBoardsModalContent } from '../../../app/pages/design/callsToAction/CtaBoardsModalContent';
import { MdKeyboardBackspace } from 'react-icons/md';
import SearchAndFolders from '../SearchAndFolders';
import { useGetVideos } from 'lib/api/videos/useGetVideos';
import dayjs from 'dayjs';
import SmallCalendarIcon from '../../images/SmallCalendarIcon';
import { CDSVideoListFilter, useAuth } from 'lib/context';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { DropItem } from 'lib/api/droplr/types';
import { DROPTYPE_OPTIONS, SortFields } from 'lib/const/Droplr';
import { getDrops, getSharedDrops } from 'lib/api/droplr/getDrops';
import { Button } from 'react-covideo-common';
import { Folder, useGetFolders } from 'lib/api/folders/getFolders';
import { VideoListItem } from 'lib/api';
import { ALL_FOLDERS } from '../../const/Folders';

const fileTypes = [
  {
    value: 'all',
    label: 'All Filetypes',
  },
  {
    value: 'IMAGE',
    label: 'Images',
  },
  {
    value: 'VIDEO',
    label: 'Videos',
  },
  {
    value: 'AUDIO',
    label: 'Sound',
  },
  {
    value: 'FILE',
    label: 'Other',
  },
];
const Header = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButtonWrap = styled.div`
  margin-left: 10px;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Menu = styled.div`
  justify-content: space-between;
  margin-top: 24px;
`;

const FilesSelector = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

const FilesItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 5px;
  margin-bottom: 8px;
  height: 64px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
`;

const FilesItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
`;

type ThumbnailProps = {
  image: string;
};

const FilesItemThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 96px;
  height: 100%;
  flex-shrink: 0;
`;

const FilesItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 24px;
  width: 220px;
`;

const FilesItemDate = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

const FilesSelectButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary[100]};
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  position: static;
  height: 36px;
  right: 12px;
  top: calc(50% - 36px / 2);
  background: rgba(255, 139, 34, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  margin-right: 24px;
  box-sizing: border-box;
  border-radius: 5px;
  text-wrap: nowrap;
`;

const FilesSelectedButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary[100]};
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  position: static;
  height: 36px;
  right: 12px;
  top: calc(50% - 36px / 2);
  background: rgba(255, 139, 34, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  border-radius: 5px;
  margin-right: 24px;
  box-sizing: border-box;
  cursor: default;
  width: 85px;
`;

const EmptyFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

interface TabStyleProperties {
  width?: string;
}

const Tab = styled.div<TabStyleProperties>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  color: ${theme.palette.coal};
  align-items: center;
  color: #9297a2;
  width: 45%;
  margin: 0;
  justify-content: center;
  height: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${({ theme }) => theme.colors.primary[100]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const FieldWrapper = styled.div<{ width: string }>`
  width: 100%;
  > div {
    width: ${props =>
      props.width ? props.width : '272px'}; /*** SUS-826 changes ***/
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
`;

const Bull = styled.span`
  :after {
    content: '•';
    margin: 0 6px;
    font-size: 10px;
    color: ${theme.palette.gray80};
  }
`;

const itemTypes = [
  {
    value: 'video',
    text: 'Videos',
  },
  {
    value: 'file',
    text: 'Files',
  },
  {
    value: 'board',
    text: 'Boards',
  },
];

interface ExtendedVideoListItem extends VideoListItem {
  selected?: boolean;
}
type Props = {
  selectedFile?: any;
  handleModalClose: () => void;
  onSelect: (drop: DropItem | ExtendedVideoListItem, itemType: string) => void;
};

export const QRCodeLibraryModal = (props: Props) => {
  const { data: folders } = useGetFolders({ getShared: true });
  const { handleModalClose, onSelect, selectedFile } = props;
  const [page, setPage] = useState(0);
  const [res, setRes] = useState<{ count: number; hasMore: string }>({
    count: 0,
    hasMore: 'false',
  });
  const [drops, setDrops] = useState<DropItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [field, setField] = useState(SortFields[1]);
  const [itemType, setItemType] = useState('video');
  const [isHover, setIsHover] = useState('');
  const [dropType, setDropType] = useState<'owned' | 'shared'>('owned');
  const [type, setType] = useState(fileTypes[0]);
  const [selectedFolderOption, setSelectedFolderOption] = useState<
    Folder | undefined
  >(undefined);

  const filter: CDSVideoListFilter = {
    size: 1000,
    page: 0,
    searchQuery,
  };

  let folderName = selectedFolderOption?.name.toString() || '';

  if (selectedFolderOption?.name === ALL_FOLDERS.label) {
    folderName = ALL_FOLDERS.value;
  }

  const { data: videosData, isLoading: videosLoading } = useGetVideos(
    filter,
    folderName,
    !!selectedFolderOption
  );

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => escPress(e.key);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const fetchDrops = async () => {
    const data = {
      sort: field.key,
      order: field.order,
      search: searchQuery,
      page: page,
      type: type.value,
      dropType: dropType as DROPTYPE_OPTIONS,
    };
    let response;
    if (dropType === DROPTYPE_OPTIONS.OWNED) {
      response = await getDrops(data);
    } else {
      response = await getSharedDrops({
        page: data.page,
        size: 10,
      });
    }

    setPage(page + 1);
    setRes(response);
    setDrops([...drops, ...response.drops]);
  };

  const refreshDrops = async () => {
    if (itemType !== 'file') return;

    const data = {
      sort: field.key,
      order: field.order,
      search: searchQuery,
      page,
      type: type.value,
      dropType: dropType as DROPTYPE_OPTIONS,
    };

    let response;
    if (dropType === DROPTYPE_OPTIONS.OWNED) {
      response = await getDrops(data);
    } else {
      response = await getSharedDrops({
        page: data.page,
        size: 10,
      });
    }

    setRes(response);
    const f = response.drops.find(r => r.shortlink === selectedFile.linkValue);
    if (f) {
      f.selected = true;
      response.drops = response.drops.filter(r => r !== f);
      response.drops.unshift(f);
    } else if (selectedFile.fileTitle && selectedFile.itemType === 'file') {
      response.drops.unshift({
        code: selectedFile.fileTitle,
        title: selectedFile.fileTitle,
        previewSmall: selectedFile.fileThumbnail,
        shortlink: selectedFile.linkValue,
        selected: true,
      } as DropItem);
    }
    setDrops(response.drops);
  };

  useEffect(() => {
    if (folders?.length) {
      setSelectedFolderOption(folders[0]);
    }
  }, [folders]);

  useEffect(() => {
    if (itemType !== 'file' && itemType !== 'video') return;

    refreshDrops();
  }, [field, searchQuery, itemType, dropType, type, selectedFolderOption]);

  const handleSearch = async (newSearchTerm: string = '') => {
    setSearchQuery(newSearchTerm);
  };

  const onSelectFolder = async (folderId: string | number) => {
    const folder = folders?.find(folder => folder.folderId === folderId);
    if (folder) {
      setSelectedFolderOption(folder);
      setPage(0);
    }
  };

  /*** SUS-826 changes ***/
  const { userData } = useAuth();
  if (!userData.customer.droplrEnabled || !userData.droplrAccess) {
    const i = itemTypes.find(i => i.value.includes('file'));
    i && itemTypes.indexOf(i) >= 0 && itemTypes.splice(itemTypes.indexOf(i), 1);
    const j = itemTypes.find(j => j.value.includes('board'));
    j && itemTypes.indexOf(j) >= 0 && itemTypes.splice(itemTypes.indexOf(j), 1);
  }
  /******/

  return (
    <Modal
      style={{ position: 'fixed' }}
      widthOnDesktop={'37%'}
      maxHeight={'550px'}
      overrideBodyHeight={true}
    >
      <div ref={ref} style={{ padding: '20px' }}>
        <Header>
          <Button
            icon={<MdKeyboardBackspace />}
            text={''}
            variant='white'
            onClick={handleModalClose}
          />
          {/**** SUS-826 changes ****/}
          <FieldWrapper width={itemTypes.length === 1 ? '90px' : '272px'}>
            <ButtonSwitch
              defaultValue={itemType}
              values={itemTypes}
              onChange={newValue => {
                setItemType(newValue);
              }}
            />
          </FieldWrapper>

          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        {itemType === 'video' && (
          <>
            <Menu>
              <Row>
                <SearchAndFolders
                  currentFolder={selectedFolderOption} /** SUS-953 changes  **/
                  folders={folders}
                  handleSearch={handleSearch}
                  handleEmptySearch={() => {}}
                  onSelectFolder={onSelectFolder}
                  searchDisabled={false}
                  hideVideoCount={true}
                  hideNewFolder={true}
                />
              </Row>
            </Menu>
            {videosLoading && (
              <LoadingIndicator isLoading={videosLoading} height='200px' />
            )}
            {!videosLoading && (
              <FilesSelector>
                {videosData?.videos?.map((video: any) => (
                  <FilesItem
                    key={video.id}
                    onMouseEnter={() => setIsHover(video.id + '')}
                    onMouseLeave={() => setIsHover(0 + '')}
                    onClick={() => onSelect(video, itemType)}
                  >
                    <FilesItemWrapper>
                      <FilesItemThumbnail image={video.thumbnail} />
                      <FilesItemTitle>
                        {video.title} <br />
                        <FilesItemDate>{video.recordDate}</FilesItemDate>
                      </FilesItemTitle>
                    </FilesItemWrapper>
                    {!video.selected && video.id == isHover && (
                      <FilesSelectButton>Select Video</FilesSelectButton>
                    )}
                    {video.selected && (
                      <FilesSelectedButton>Selected</FilesSelectedButton>
                    )}
                  </FilesItem>
                ))}
                {!videosData?.count && (
                  <EmptyFiles>No videos found.</EmptyFiles>
                )}
              </FilesSelector>
            )}
          </>
        )}

        {itemType === 'file' && (
          <>
            <TabContainer>
              <Tab
                className={dropType === 'owned' ? 'activeTab' : ''}
                onClick={() => {
                  setDropType('owned');
                }}
                title={'myFiles'}
              >
                &nbsp;My Files
              </Tab>
              <Tab
                className={dropType === 'shared' ? 'activeTab' : ''}
                onClick={() => {
                  setDropType('shared');
                }}
                title={'sharedWithMe'}
              >
                &nbsp;Shared with me
              </Tab>
            </TabContainer>
            <Menu>
              <Row>
                <Search
                  placeholder={`Search files and tags...`}
                  onSearch={onSearch}
                  prevSearch={searchQuery}
                  width={'100%'}
                />
              </Row>
              <Row style={{ marginTop: '12px' }}>
                <Col style={{}}>
                  <Dropdown
                    value={field}
                    onChange={newValue => {
                      setField(newValue);
                    }}
                    options={SortFields}
                    dropdownHeight={450}
                    height={40}
                  />
                </Col>
                <Col style={{ marginLeft: '12px' }}>
                  <Dropdown
                    value={type}
                    onChange={newValue => {
                      setType(newValue);
                    }}
                    options={fileTypes}
                    height={40}
                  />
                </Col>
              </Row>
            </Menu>
            {videosLoading && (
              <LoadingIndicator isLoading={videosLoading} height='200px' />
            )}
            {!videosLoading && !!drops.length && (
              <FilesSelector>
                <InfiniteScroll
                  dataLength={page * res.count}
                  next={fetchDrops}
                  hasMore={res.hasMore === 'true'}
                  loader={<h4>Loading...</h4>}
                  height={300}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>No more files</b>
                    </p>
                  }
                >
                  {drops.map(drop => (
                    <FilesItem
                      key={drop.code}
                      onMouseEnter={() => setIsHover(drop.code)}
                      onMouseLeave={() => setIsHover(0 + '')}
                      onClick={() => onSelect(drop, itemType)}
                    >
                      <FilesItemWrapper>
                        <FilesItemThumbnail image={drop.previewSmall} />
                        <FilesItemTitle>
                          {drop.title} <br />
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <SmallCalendarIcon
                              style={{ marginRight: '10px' }}
                            />
                            <FilesItemDate>
                              {dayjs(drop.createdAt).format(
                                STANDARD_DATE_FORMAT
                              )}
                            </FilesItemDate>
                            <Bull />
                            <FilesItemDate>
                              {dayjs(drop.createdAt).format('hh:mm:ss A')}
                            </FilesItemDate>
                          </div>
                        </FilesItemTitle>
                      </FilesItemWrapper>
                      {!drop.selected && drop.code === isHover && (
                        <FilesSelectButton>Select File</FilesSelectButton>
                      )}
                      {drop.selected && (
                        <FilesSelectedButton>Selected</FilesSelectedButton>
                      )}
                    </FilesItem>
                  ))}
                </InfiniteScroll>
              </FilesSelector>
            )}
            {!videosLoading && !drops.length && (
              <EmptyFiles>No files found.</EmptyFiles>
            )}
          </>
        )}
        {itemType === 'board' && (
          <>
            <CtaBoardsModalContent
              onSelect={onSelect}
              selectedFile={selectedFile}
              isQrModal={true}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
