import * as React from 'react';
import './css/spoke-spinner-loader.css';

type Props = {
  loaderMessage: string;
};
export const SpokeSpinnerLoader = ({ loaderMessage }: Props) => {
  return (
    <div className="spoke-spinner-loader-container">
      <div className="spoke-spinner-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>{loaderMessage}</div>
    </div>
  );
};
