import React from 'react';
import { ToastContainer } from 'react-toastify';
import { IntercomWrap, LoadingIndicator } from '../../components';
import { SocketProvider } from '../socket';
import { AuthProvider } from '../auth';
import { Theme } from '../theme/provider';
import { NotFound } from '../../../app/pages/notFound/NotFound';
import * as Sentry from '@sentry/react';

export const AppProvider = ({ children }: any) => {
  return (
    <Sentry.ErrorBoundary
      fallback={() => <LoadingIndicator isLoading={true} />}
    >
      <AuthProvider>
        <Theme>
          <Sentry.ErrorBoundary fallback={() => <NotFound />}>
            <SocketProvider>
              <IntercomWrap>{children}</IntercomWrap>
              <ToastContainer style={{ minWidth: 492 }} />
            </SocketProvider>
          </Sentry.ErrorBoundary>
        </Theme>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};
