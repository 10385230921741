import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';
import {
  FacebookIconXs,
  YouTubeIconXs,
  LinkedInIconXs,
} from 'lib/images/social-profiles';
import React from 'react';
import { SOCIAL_PROFILES } from './constants';
import { IAnalyticsProps } from './SocialInsights';
import {
  TableContainer,
  TableHeader,
  SocialName,
  Social,
  PublishingDate,
  TableBody,
  TableRow,
  RowName,
  RowValue,
} from './styles/insightsTable';

interface ISocialStatsTableProps {
  data: IAnalyticsProps;
}

const SocialStatsTable = ({ data }: ISocialStatsTableProps) => {
  const {
    comments,
    likes,
    views,
    dislikes,
    publishedAt,
    integrationType,
    organizationId,
  } = data;

  const handleSocialProfileInfo = () => {
    switch (integrationType) {
      case SOCIAL_PROFILES.FACEBOOK:
        return {
          color: '#1877F2',
          name: 'Facebook',
          icon: <FacebookIconXs />,
        };
      case SOCIAL_PROFILES.YOUTUBE:
        return {
          color: '#EF3E37',
          name: 'YouTube',
          icon: <YouTubeIconXs />,
        };
      case SOCIAL_PROFILES.LINKEDIN:
        return {
          color: '#007AB6',
          name: 'Linkedin',
          icon: <LinkedInIconXs />,
        };
      default:
        return {
          color: '#fff',
          name: null,
          icon: null,
        };
    }
  };

  return (
    <TableContainer>
      <TableHeader>
        <Social>
          {handleSocialProfileInfo().icon}
          <SocialName color={handleSocialProfileInfo().color}>
            {handleSocialProfileInfo().name}
          </SocialName>
        </Social>
        <PublishingDate>
          Published: {dayjs(publishedAt).format(STANDARD_DATE_FORMAT)}
        </PublishingDate>
      </TableHeader>
      <TableBody>
        {((integrationType === SOCIAL_PROFILES.LINKEDIN && !!organizationId) ||
          integrationType !== SOCIAL_PROFILES.LINKEDIN) && (
          <TableRow>
            <RowName>Views</RowName>
            <RowValue>{views}</RowValue>
          </TableRow>
        )}
        <TableRow>
          <RowName>Likes</RowName>
          <RowValue>{likes}</RowValue>
        </TableRow>
        <TableRow>
          <RowName>Comments</RowName>
          <RowValue>{comments}</RowValue>
        </TableRow>
        {integrationType === SOCIAL_PROFILES.YOUTUBE && (
          <TableRow>
            <RowName>Dislikes</RowName>
            <RowValue>{dislikes}</RowValue>
          </TableRow>
        )}
      </TableBody>
    </TableContainer>
  );
};

export default SocialStatsTable;
