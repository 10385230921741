import React, { useState } from 'react';
import styled from 'styled-components/macro';
import DefaultManagerImage from 'lib/images/support-badge-pic.png';
import { useAuth } from 'lib/context';
import { getCrmAccountManagers } from 'lib/api/crmIntegrationsApi';
import AliVelez from 'lib/images/account-managers/Ali-Velez.jpg';
import EmilyG1 from 'lib/images/account-managers/EmilyG1.jpg';
import EmilyS1 from 'lib/images/account-managers/EmilyS1.jpg';
import KeirstinMinton from 'lib/images/account-managers/Keirstin-Minton .jpg';
import Mariah from 'lib/images/account-managers/Mariah.jpg';
import McKenzie from 'lib/images/account-managers/McKenzie.jpg';
import MonicaMuniz from 'lib/images/account-managers/Monica-Muniz.jpg';
import Caitlin1 from 'lib/images/account-managers/Caitlin1.jpg';
import ChrisBill from 'lib/images/account-managers/ChriBill_AM.jpg';
import AshleyManner from 'lib/images/account-managers/AshleyManner_AM.jpg';
import { FaMinus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const ShowWrapper = styled.div`
  transition: 0.3s;
`;

const SupportContainer = styled.div`
  width: 233px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgb(29 30 36 / 6%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 65px;
  left: 15px;
  z-index: 999;
  &:not(:hover) {
    ${ShowWrapper} {
      height: 0px;
      overflow: hidden;
    }
  }
`;
const ImageContainer = styled.div`
  justify-content: center;
  margin: auto;
  padding-top: 28px;
  padding-bottom: 12px;
`;

const TitleContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  text-align: center;
  padding-bottom: 4px;
`;

const NameContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #001b53;
  text-align: center;
  padding-bottom: 24px;
`;

const ContactContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #001b53;
  cursor: pointer;
`;

const EmailContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #001b53;
  padding-bottom: 16px;
  cursor: pointer;
`;
const ManagerImages = styled.div.attrs(
  (props: { image: string; border: boolean }) => props
)`
  background-image: url(${props => props.image});
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  border: ${props => (props.border ? `5px solid #ffff` : 'none')};
  cursor: pointer;
`;
const MinusIcon = styled.div`
  margin-top: 10px;
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

const MinimiseContainer = styled.div`
  position: fixed;
  bottom: 65px;
  left: 70px;
  z-index: 999;
`;
export const SupportBadge = () => {
  const [managerData, setManagerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone1: '',
  });
  const [agentId, setAgentId] = useState<number>(0);
  const [isManager, setManager] = useState(false); // Use to show hide support badge on the basis of manager available.
  const [isMinimise, setIsMinimise] = useState(
    localStorage?.getItem('supportBadgeState') === 'true'
  );
  const { userData } = useAuth(); // To ger user information from the context store.

  const { isAutomotive } = userData;

  const { accountManager } = userData.customer;

  const location = useLocation();

  // CDS-78 hide support badge for send&share pages
  const hideSupportBadge = location.pathname.includes('/home/send-and-share/');

  // To fetch CRM Manager data.
  const fetchCrmManager = async () => {
    const managerResponse = await getCrmAccountManagers();
    if (managerResponse?.accountManagers) {
      const filterAccountManager = managerResponse?.accountManagers?.find(
        (item: any) => item?.agentId == accountManager
      );
      if (filterAccountManager) {
        setManager(true);
        setAgentId(filterAccountManager?.agentId);
        setManagerData(filterAccountManager?.user);
      }
    }
  };

  React.useEffect(() => {
    if (isAutomotive && accountManager !== '-1') {
      fetchCrmManager();
    }
  }, [accountManager, isAutomotive]);

  const managerImagesMapping: any = {
    2: Caitlin1,
    3: KeirstinMinton,
    4: EmilyS1,
    8: EmilyG1,
    12: MonicaMuniz,
    13: McKenzie,
    14: AliVelez,
    15: Mariah,
    17: ChrisBill,
    20: AshleyManner,
  };

  const changeSupportBadgeState = (type: boolean) => {
    localStorage.setItem('supportBadgeState', `${type}`);
    setIsMinimise(type);
  };
  return (
    <>
      {hideSupportBadge ||
      accountManager === '-1' ||
      !isManager ||
      !isAutomotive ? null : isMinimise ? (
        <MinimiseContainer>
          <ImageContainer onClick={() => changeSupportBadgeState(false)}>
            <ManagerImages
              image={managerImagesMapping[agentId] || DefaultManagerImage}
              border={true}
            ></ManagerImages>
          </ImageContainer>
        </MinimiseContainer>
      ) : (
        <SupportContainer>
          <MinusIcon onClick={() => changeSupportBadgeState(true)}>
            <FaMinus size={15} />
          </MinusIcon>
          <ImageContainer>
            <ManagerImages
              image={managerImagesMapping[agentId] || DefaultManagerImage}
              border={false}
            ></ManagerImages>
          </ImageContainer>
          <TitleContainer>Your personal support manager</TitleContainer>
          <NameContainer>
            {managerData?.firstName} {managerData?.lastName}
          </NameContainer>
          <ShowWrapper>
            <ContactContainer
              onClick={() =>
                (window.location.href = `tel:${managerData?.phone1}`)
              }
            >
              {managerData?.phone1}{' '}
            </ContactContainer>
            <EmailContainer
              onClick={() =>
                (window.location.href = `mailto:${managerData?.email}`)
              }
            >
              {managerData?.email}
            </EmailContainer>
          </ShowWrapper>
        </SupportContainer>
      )}
    </>
  );
};
