import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal } from 'lib/components';
import { Button } from 'react-covideo-common';

type Props = {
  handleModalClose: () => void;
  handleSubmit: (addon?: any) => void;
  title: string;
  handleCheckBox?: boolean;
};

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  padding-top: 24px;
  p {
    font-size: 14px;
  }
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 48px;
`;

const mapping: any = {
  'Files & Boards': 'droplr',
  SMS: 'smsTierOne',
  'Video Landing Page Builder': 'lpb',
  'Captions & Translations': 'captions',
};

export const ModalActivateSubscription = ({
  handleModalClose,
  handleSubmit,
  title,
  handleCheckBox,
}: Props) => {
  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          Activate subscription to enable add-on
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          Thank you for your interest in “{title}”!
          <p>
            To be able to activate this add-on, you first need to subscribe to a
            monthly or annual Covideo plan.
          </p>
        </Content>

        <ButtonsWrapper>
          <Button
            text='View Subscription Options'
            onClick={() => handleSubmit(handleCheckBox ? [mapping[title]] : [])}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
