import { useHistory } from 'react-router';
import { useGetVideos } from 'lib/api/videos/useGetVideos';
import {
  CheckboxInput,
  LoadingIndicator,
  RadioInput,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'lib/const/PaginationConstants';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { IOnPaginationChange } from 'app/pages/account/userAccount/recentlyDeleted/types';
import { useFormikContext } from 'formik';
import FolderDropdown from './FolderDropdown';
import { usePrepareVideos } from 'lib/api/guides/prepareVideo';
import { FormikValue } from '../modals/AddGuideModal';
import { theme } from 'lib/style';
import { replaceGuide } from 'lib/api/guides/replaceGuideMutation';
import ItemCard from './ItemCard';
import { VideoListAutomotiveItem } from 'lib/context';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  margin-top: 16px;
`;

const Body = styled.div`
  overflow-y: auto;
  height: 216px;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const NoVideosMessage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

type Props = {
  isMultiple?: boolean;
  submitButtonText?: string;
  videoId?: number;
  proceed?: boolean;
  limit?: number;
  setChosenVideoCount?: (arg: number) => void;
  setIsConfirmModalOpen?: (arg: boolean) => void;
  onAdd?: (data: number[]) => void;

  closeModalHandler: () => void;
};

export const LibraryTabContent = ({
  videoId,
  isMultiple = true,
  submitButtonText = '',
  proceed,
  limit,
  setIsConfirmModalOpen,
  setChosenVideoCount,
  closeModalHandler,
  onAdd,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE);

  const [selectedVideos, setSelectedVideos] = useState<number[]>(
    videoId ? [videoId] : []
  );

  const { values } = useFormikContext<FormikValue>();
  const history = useHistory();

  const videoFilter = {
    videoType: '',
    advisorId: '',
    userId: '',
    status: '',
    emailStatus: '',
    folderId: 0,
    page,
    size,
    searchQuery,
  };

  const {
    data: videoData,
    isLoading: areVideosLoading,
    refetch: refetchVideos,
  } = useGetVideos(videoFilter, values.folder.label);

  const { count = 0, videos = [] } = videoData || {};
  const { mutateAsync: prepareVideos } = usePrepareVideos();

  const hasVideos = !!videos.length;
  const hasConfirmation = setIsConfirmModalOpen;

  const onSelectVideo = (video: VideoListAutomotiveItem) => {
    if (!isMultiple) {
      setSelectedVideos([Number(video.id)]);
      return;
    }

    const exists = selectedVideos.some(
      (selectedVideo: number) => selectedVideo === Number(video.id)
    );
    if (exists) {
      if (setChosenVideoCount) setChosenVideoCount(selectedVideos.length - 1);
      setSelectedVideos(
        selectedVideos.filter(
          (selectedVideo: number) => selectedVideo !== Number(video.id)
        )
      );
      return;
    }

    if (setChosenVideoCount) setChosenVideoCount(selectedVideos.length + 1);
    setSelectedVideos([...selectedVideos, Number(video.id)]);
  };

  const onReplaceVideo = async () => {
    if (!videoId || !selectedVideos.length) return;

    const preparedVideos = await prepareVideos({
      videoIds: [selectedVideos[0]],
    });
    if (!!preparedVideos.videos.length) {
      await replaceGuide({
        videoId,
        newVideoId: preparedVideos.videos[0].id,
      });
    }

    history.push(`/classic/edit-video/${preparedVideos.videos[0].id}/details`);
    closeModalHandler();
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const onPrepareVideo = async () => {
    const { videos } = await prepareVideos({
      videoIds: selectedVideos,
    });

    if (videos && videos.length) {
      history.push({
        pathname: '/review-guides',
        state: {
          videos: videos.map((video: any) => video.id),
          category: values.guideCategoryId,
        },
      });
    }
  };

  const onSubmit = async () => {
    if (setIsConfirmModalOpen && !proceed) {
      setIsConfirmModalOpen(true);
      return;
    }

    if (hasConfirmation && proceed && !isMultiple) {
      await onReplaceVideo();
      return;
    }

    await onPrepareVideo();
  };

  useEffect(() => {
    refetchVideos();
  }, [page, size]);

  useEffect(() => {
    if (proceed && hasConfirmation) onAdd ? onAdd(selectedVideos) : onSubmit();
  }, [proceed]);

  const isAddMoreDisabled =
    isMultiple && !!limit && selectedVideos.length === limit;

  const isChecked = (video: VideoListAutomotiveItem) =>
    selectedVideos.some(
      (selectedVideo: number) => selectedVideo === Number(video.id)
    );

  return (
    <Container>
      <Gap gap={'10px'}>
        <Search
          placeholder={'Search library videos'}
          onSearch={(search: string) => setSearchQuery(search)}
          prevSearch={searchQuery}
          width={'280px'}
        />
        <FolderDropdown />
      </Gap>
      <TableContextProvider
        total={count}
        initSize={size}
        initPage={page}
        onChange={onPaginationChange}
      >
        <Body key={page}>
          {areVideosLoading ? (
            <LoadingIndicator isLoading={true} height='250px' />
          ) : hasVideos ? (
            videos.map((video: VideoListAutomotiveItem, index: number) => {
              return (
                <Gap
                  key={video.id}
                  style={{
                    ...(index !== videos.length - 1 && {
                      borderBottom: `2px solid ${theme.palette.neutral20}`,
                    }),
                  }}
                >
                  {!isMultiple ? (
                    <RadioInput
                      onChange={() => {
                        onSelectVideo(video);
                      }}
                      onClick={() => {
                        onSelectVideo(video);
                      }}
                      name=''
                      value=''
                      checked={Number(video.id) === selectedVideos[0]}
                    />
                  ) : (
                    <CheckboxInput
                      onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                        event.stopPropagation();
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        event.stopPropagation();
                        onSelectVideo(video);
                      }}
                      checked={isChecked(video)}
                      disabled={isAddMoreDisabled && !isChecked(video)}
                    />
                  )}
                  <ItemCard
                    disabled={isAddMoreDisabled && !isChecked(video)}
                    data={{
                      ...video,
                      type: 'video/mp4',
                      source: video.videoSource,
                      createdAt: video?.recordDate || '',
                    }}
                  />
                </Gap>
              );
            })
          ) : (
            <NoVideosMessage>
              There is no videos matching your criteria.
            </NoVideosMessage>
          )}
        </Body>
        {hasVideos ? (
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew />
          </TableFooter>
        ) : (
          <div style={{ height: '56px' }}></div>
        )}
      </TableContextProvider>

      <ButtonContainer>
        <Button
          onClick={() => (onAdd ? onAdd(selectedVideos) : onSubmit())}
          text={submitButtonText || 'Add Video Guide'}
          disabled={!selectedVideos.length}
          variant='primary'
        />
      </ButtonContainer>
    </Container>
  );
};
