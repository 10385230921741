import * as React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal } from 'lib/components';
import { Search } from 'lib/components/inputs/Search';
import { SnippetType } from 'lib/const';
import { useState } from 'react';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { SnippetSimplifiedForm } from '.';
import { removeHTMLTags } from 'lib/utils/functions';
import { MdAdd } from 'react-icons/md';
import { HiArrowLeft } from 'react-icons/hi';
import { useQuerySnippets } from 'lib/api/snippets/useQuerySnippets';
import { useCreateSnippetMutation } from 'lib/api/snippets/useCreateSnippetMutation';
import { useUpdateSnippetMutation } from 'lib/api/snippets/useUpdateSnippetMutation';
import { SnippetItem } from 'lib/api/snippets/types';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 692px;
  max-width: 100%;
  min-height: 520px;
  padding: 32px;
  background-color: #fff;
`;

type HeaderProps = {
  justify: string;
};

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.black_1_100};
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;
`;

const SearchStyled = styled(Search)`
  width: 378px;
  max-width: 100%;
  height: 42px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.themeDark50Percent};
  cursor: pointer;
`;

const Content = styled.div`
  margin-top: 28px;
  max-height: 338px;
  overflow-y: auto;
`;

const SnippetWrapper = styled.div`
  padding: 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
`;
const SnippetInfo = styled.div`
  width: 364px;
  max-width: 100%;
`;
const SnippetTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SnippetContent = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GoBack = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

enum View {
  Choose,
  Create,
}

type Props = {
  handleModalClose: () => void;
  snippetType: SnippetType;
  handleSubmit: (content: string) => void;
};

export const ModalChooseSnippet = ({
  handleModalClose,
  snippetType,
  handleSubmit,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedView, setSelectedView] = useState(View.Choose);

  const queryParams = {
    limit: 100,
    start: 0,
    sort: '',
    search: searchQuery,
    sharedWith: '',
  };

  const { data: snippets } = useQuerySnippets(queryParams);
  const { mutateAsync: addSnippet } = useCreateSnippetMutation();
  const { mutateAsync: editSnippet } = useUpdateSnippetMutation(queryParams);

  const modifiedSnippets = snippets?.snippets
    .filter(
      (snippet: SnippetItem) =>
        (snippetType === SnippetType.Email && snippet.email) ||
        (snippetType === SnippetType.Sms && snippet.sms)
    )
    .sort((a: SnippetItem, b: SnippetItem) => +b.bookmarked - +a.bookmarked);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const insertSnippet = (snippet: SnippetItem) => {
    const content =
      snippetType === SnippetType.Email
        ? snippet.emailText
        : snippetType === SnippetType.Sms
          ? snippet.smsText
          : '';
    handleSubmit(content || '');
    handleModalClose();
  };

  const createSnippet = async (snippetData: any) => {
    const isEmailType = snippetType === SnippetType.Email;
    const isSmsType = snippetType === SnippetType.Sms;
    const newSnippetData = {
      title: snippetData.title,
      email: isEmailType,
      emailText: isEmailType ? snippetData.content : '',
      sms: isSmsType,
      smsText: isSmsType ? snippetData.content : '',
    };

    const newSnippet = await addSnippet({ data: newSnippetData });

    if (newSnippet) {
      insertSnippet(newSnippet);
    }
  };

  const bookmarkSnippet = async (snippet: SnippetItem) => {
    await editSnippet({
      ...snippet,
      bookmarked: !snippet.bookmarked,
    });
  };

  const chooseTitle = `${snippetType} Templates`;
  const addTitle = `New ${snippetType} Template`;

  return (
    <Modal
      disableFullScreen={true}
      style={{ position: 'fixed' }}
      bodyOverflowY={'visible'}
      bodyOverflowX={'visible'}
    >
      <ModalItem>
        {selectedView === View.Choose && (
          <>
            <Header justify='space-between'>
              <Title>{chooseTitle}</Title>
              <CloseButtonWrapper>
                <CloseIcon
                  width={'24px'}
                  height={'24px'}
                  onClick={handleModalClose}
                />
              </CloseButtonWrapper>
            </Header>
            <SubHeader>
              <SearchStyled
                placeholder='Search templates...'
                onSearch={onSearch}
              />
              <Button
                variant='primary'
                onClick={() => setSelectedView(View.Create)}
                text='Create New'
                icon={<MdAdd />}
              />
            </SubHeader>
            <Content>
              {modifiedSnippets?.map((snippet: SnippetItem) => (
                <SnippetWrapper key={snippet.snippetId}>
                  <SnippetInfo>
                    <SnippetTitle>{snippet.title}</SnippetTitle>
                    <SnippetContent>
                      {snippetType === SnippetType.Email
                        ? removeHTMLTags(snippet.emailText)
                        : snippetType === SnippetType.Sms
                          ? removeHTMLTags(snippet.smsText)
                          : null}
                    </SnippetContent>
                  </SnippetInfo>
                  {snippet?.bookmarked ? (
                    <BsBookmarkFill
                      style={{ marginTop: '10px', cursor: 'pointer' }}
                      onClick={() => bookmarkSnippet(snippet)}
                      color={theme.palette.covideoBlue100}
                    />
                  ) : (
                    <BsBookmark
                      style={{ marginTop: '10px', cursor: 'pointer' }}
                      onClick={() => bookmarkSnippet(snippet)}
                      color={theme.palette.covideoBlue100}
                    />
                  )}
                  <Button
                    text='Insert'
                    onClick={() => insertSnippet(snippet)}
                    variant='secondary'
                  />
                </SnippetWrapper>
              ))}
            </Content>
          </>
        )}
        {selectedView === View.Create && (
          <>
            <Header justify='flex-start'>
              <GoBack onClick={() => setSelectedView(View.Choose)}>
                <HiArrowLeft color={theme.palette.gray60} />
              </GoBack>
              <Title>{addTitle}</Title>
            </Header>
            <SnippetSimplifiedForm
              snippetType={snippetType}
              handleSubmit={createSnippet}
            />
          </>
        )}
      </ModalItem>
    </Modal>
  );
};
