import * as React from 'react';
import styled from 'styled-components/macro';

const Icon = styled.path`
  fill: ${props => props.color || 'currentColor'};
  opacity: ${props => props.opacity || 'inherit'};
`;

export const IntegrationsIcon = props => {
  const { onClick, width, height, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16px'}
      height={height || '16px'}
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      <Icon
        {...rest}
        fillRule="evenodd"
        d="M15.539 9.316c-.29-.384-.698-.576-1.222-.576-.275 0-.54.05-.796.147-.256.097-.462.206-.618.327-.157.12-.326.23-.508.327s-.344.146-.484.146c-.288 0-.572-.15-.853-.449-.224-.24-.336-.621-.336-1.142 0-.618.077-1.415.23-2.39.007-.02.018-.09.034-.21.016-.12.032-.232.048-.333.016-.1.027-.157.033-.17v-.02c-.3.013-.82.068-1.562.166-.741.098-1.316.146-1.725.146-.39 0-.72-.086-.988-.258-.268-.173-.402-.444-.402-.815 0-.24.056-.472.167-.693.112-.221.234-.405.365-.552.13-.146.252-.338.364-.575.112-.238.168-.49.168-.757 0-.514-.173-.916-.518-1.205-.345-.29-.773-.434-1.284-.434-.543 0-1.003.148-1.38.444-.377.296-.566.71-.566 1.244 0 .28.048.55.144.81.096.26.203.47.32.63.12.16.226.332.322.517.096.186.144.35.144.493 0 .293-.147.582-.441.869-.237.227-.61.341-1.122.341-.607 0-1.39-.078-2.348-.234-.02-.007-.088-.018-.206-.035C.4 5.06.292 5.043.193 5.027.094 5.01.038 4.999.025 4.993v9.994c0 .006.007.01.02.01.012.006.022.01.028.01l.125.019c.057.013.145.026.263.039.119.013.207.026.264.039.959.156 1.741.234 2.348.234.512 0 .885-.114 1.122-.342.294-.286.44-.576.44-.868 0-.144-.047-.308-.143-.493-.096-.186-.203-.358-.321-.517-.118-.16-.225-.37-.321-.63s-.144-.53-.144-.81c0-.534.189-.949.566-1.245.377-.296.834-.444 1.37-.444.518 0 .95.145 1.294.435.345.29.518.691.518 1.205 0 .267-.056.519-.168.756-.112.238-.233.43-.364.576-.131.147-.253.33-.364.552-.112.22-.168.452-.168.692 0 .372.134.643.402.816.269.172.598.258.988.258.294 0 .607-.016.939-.049.332-.032.701-.076 1.107-.131.406-.056.717-.093.934-.113.07-.006.176-.01.317-.01v-.048c.006-.254-.042-.628-.144-1.123-.102-.494-.153-.868-.153-1.122 0-.807.351-1.21 1.054-1.21.236 0 .463.057.68.17.218.114.398.238.542.372.144.133.333.257.566.37.233.114.48.171.742.171.505 0 .9-.175 1.184-.527.285-.351.427-.79.427-1.317 0-.547-.145-1.012-.436-1.396z"
      />
    </svg>
  );
};
