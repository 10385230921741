import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';

export interface EditableGuides {
  guideCategoryId: number;
  userId: number;
  customerId: number;
  name: string;
  order: number;
  private: boolean;
  createdAt: string;
  updatedAt: string;
}

const renameCategory = async ({
  name,
  categoryId,
}: {
  name: number[];
  categoryId: number;
}): Promise<EditableGuides[]> => {
  return (
    await EXPRESS_API.patch(`guide-category/${categoryId}/rename`, {
      name,
    })
  ).data as EditableGuides[];
};
export const useRenameCategoryMutation = (closeModalHandler: () => void) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(renameCategory, {
    onError: err => showError(err),
    onSuccess: (_, variables) => {
      successToast({ title: 'You have successfully renamed category!' });
      queryClient.invalidateQueries(guidesKeyes.categorized());
      queryClient.refetchQueries(
        guidesKeyes.singleCategory(`${variables.categoryId}`)
      );
      queryClient.invalidateQueries(guidesKeyes.editableCategories());
      closeModalHandler();
    },
  });
};
