import { Button } from 'react-covideo-common';
import { ParagraphSmall } from 'lib/components/styles/typography';
import EditIcon from 'lib/images/EditIcon';
import { theme } from 'lib/style';
import React from 'react';
import { ItemContainer } from '../ItemContainer';

interface IProps {
  label: string | null;
  openModalHandler: () => void;
  customerId: number | null;
}
export const LocationItem = ({
  label,
  openModalHandler,
  customerId,
}: IProps) => {
  return (
    <ItemContainer
      width='100%'
      justifyContent='space-between'
      data-set={customerId}
    >
      <ParagraphSmall
        color={theme.palette.gray100}
        ellipsis
        style={{ whiteSpace: 'nowrap', flex: 1, fontSize: 12 }}
      >
        {label || 'N/A'}
      </ParagraphSmall>
      <Button
        onClick={openModalHandler}
        variant='secondary'
        size='small'
        icon={
          <EditIcon
            color={theme.palette.covideoBlue100}
            height={'18px'}
            width={'18px'}
          />
        }
      />
    </ItemContainer>
  );
};
