import styled from 'styled-components/macro';
import { ReactComponent as DownloadV3IconSvg } from './download-v3-icon.svg';

const DownloadV3Icon = styled(DownloadV3IconSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
  g {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default DownloadV3Icon;
