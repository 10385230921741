import {
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from '../../../../../lib/components';
import { theme } from '../../../../../lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import DeleteIcon from '../../../../../lib/images/DeleteIcon';
import ImageIcon from '../../../../../lib/images/ImageIcon';
import DocumentIcon from '../../../../../lib/images/DocumentIcon';
import VideoIcon from '../../../../../lib/images/VideoIcon';
import LinkIcon from '../../../../../lib/images/LinkIcon';
import FolderIcon from '../../../../../lib/images/FolderIcon';
import { Button } from 'react-covideo-common';
import { MdEdit } from 'react-icons/md';

const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 16px;
  cursor: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Text = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 101%;
`;

const tableFields = [
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'qrUrl',
    label: 'Target',
  },
  {
    value: 'landing',
    label: 'Landing Page',
  },
  {
    value: 'overlay',
    label: 'Overlay URL / CTA Set',
  },
  {
    value: 'hits',
    label: 'Hits',
  },
];

const getIcon = (type: string) => {
  switch (type) {
    case 'image':
      return <ImageIcon width='24px' height='24px' color='#4e5461' />;
    case 'file':
      return <DocumentIcon width='24px' height='24px' color='#D0D3D9' />;
    case 'video':
      return <VideoIcon width='24px' height='24px' color='#D0D3D9' />;
    case 'board':
      return <FolderIcon width='24px' height='24px' color='#D0D3D9' />;
    default:
      return <LinkIcon width='24px' height='24px' color='#D0D3D9' />;
  }
};

const getTitle = (qrVideo: any) => {
  switch (qrVideo.itemType) {
    case 'video':
      return qrVideo.video?.title;
    case 'file':
      return qrVideo.title;
    case 'board':
      return qrVideo.title;
    default:
      return qrVideo.qrUrl;
  }
};

const generateTableCells = (qrVideo: any) => {
  return [
    <TableCell width={250}>{qrVideo.title}</TableCell>,
    <TableCell width={260}>
      <CellWrapper>
        {getIcon(qrVideo.itemType)}
        <Text>{getTitle(qrVideo)}</Text>
      </CellWrapper>
    </TableCell>,
    <TableCell width={140}>
      <Text>{qrVideo.itemType !== null ? qrVideo.template?.title : '-'}</Text>
    </TableCell>,
    <TableCell width={150}>
      <Text>{qrVideo.itemType !== null ? qrVideo.cta?.title : '-'}</Text>
    </TableCell>,
    <TableCell width={35}>
      <Text>{qrVideo.qrHits.length}</Text>
    </TableCell>,
  ];
};

export const QRCodesTable = ({
  data,
  onEdit,
  onDelete,
  onPaginationChange,
  page,
  size,
}: any) => {
  const columnWidths = [50, 50, 95, 155, 35];

  return (
    <Layout>
      <TableContextProvider
        total={data.count}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          style={{
            overflowX: 'auto',
            marginTop: '3%',
          }}
        >
          <Table
            compact={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell width={columnWidths[index]} onClick={() => {}}>
                    {item.label}
                  </TableCell>
                );
              }),
              <TableCell width={columnWidths[tableFields.length]} />,
            ]}
            hoverable={false}
            columnWidths={columnWidths}
            rows={data.qrVideos.map((qrVideo: any, index: number) => ({
              key: index,
              columns: [
                ...generateTableCells(qrVideo),
                <TableCell
                  style={{
                    display: 'flex',
                    gap: '8px',
                  }}
                  width={115}
                >
                  <Button
                    icon={<MdEdit size={20} />}
                    onClick={() => onEdit && onEdit(qrVideo)}
                    variant='secondary'
                  />

                  <Button
                    icon={<DeleteIcon width='20px' height='20px' />}
                    onClick={() => onDelete && onDelete(qrVideo)}
                    variant='destructive'
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
        </div>
        <TableFooter>
          <TablePaginationNew />
          <TablePaginationSizeNew globalName='qr_codes' />
        </TableFooter>
      </TableContextProvider>
    </Layout>
  );
};
