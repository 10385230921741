import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmall } from 'lib/components/styles/typography';
import EditIcon from 'lib/images/EditIcon';
import { theme } from 'lib/style';
import React from 'react';
import { MdClose } from 'react-icons/md';
import { ItemContainer } from '../ItemContainer';

interface IProps {
  label: string | null;
  id?: string | undefined;
  onRemoveClick?: () => void;
  onEditClick?: () => void;
  onCreateClick?: () => void;
  customerId: number | null;
  isParentItem?: boolean;
}
export const UserItem = ({
  label,
  onRemoveClick,
  onEditClick,
  id,
  onCreateClick,
  customerId,
  isParentItem,
}: IProps) => {
  const hasAccess = !!label;
  return (
    <ItemContainer data-set={customerId} width='100%' gap='8px'>
      {hasAccess && (
        <>
          <Gap
            justifyContent={id ? 'space-between' : 'flex-start'}
            style={{ flex: 1 }}
            gap='8px'
          >
            {onRemoveClick && (
              <Button
                onClick={onRemoveClick}
                variant='destructive'
                size='small'
                icon={<MdClose color={theme.palette.buttonDelete} size={18} />}
              />
            )}
            <div style={{ width: 230 }}>
              <ParagraphSmall
                color={theme.palette.gray100}
                ellipsis
                style={{ whiteSpace: 'nowrap', width: 230, fontSize: 12 }}
              >
                {label}
              </ParagraphSmall>
              {!isParentItem && (
                <ParagraphSmall
                  color={theme.palette.gray100}
                  ellipsis
                  style={{ whiteSpace: 'nowrap', width: 230, fontSize: 12 }}
                >
                  {id}
                </ParagraphSmall>
              )}
            </div>
            {id && isParentItem && (
              <ParagraphSmall
                ellipsis
                color={theme.palette.gray60}
                textAlign='right'
                style={{ whiteSpace: 'nowrap', width: 70, fontSize: 12 }}
              >
                {id}
              </ParagraphSmall>
            )}
          </Gap>
          {onEditClick && (
            <Button
              onClick={onEditClick}
              variant='secondary'
              size='small'
              icon={
                <EditIcon
                  color={theme.palette.covideoBlue100}
                  height={'18px'}
                  width={'18px'}
                />
              }
            />
          )}
        </>
      )}
      {!hasAccess && (
        <Button
          onClick={onCreateClick}
          text='Create or Connect Username'
          variant='primary'
          size='small'
        />
      )}
    </ItemContainer>
  );
};
