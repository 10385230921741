import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components/macro';
import { MdAdd, MdEdit, MdLibraryAdd, MdPlayArrow } from 'react-icons/md';
import { theme } from 'lib/style';
import { useTeleprompterContext, removeScript, ScriptItem } from 'lib/context';
import {
  LoadingIndicator,
  ModalDelete,
  NewModal,
  Switch,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import DeleteIcon from 'lib/images/DeleteIcon';
import { successToast } from 'lib/components/toasts/success';
import {
  Container,
  Gap,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import DuplicateIcon from 'lib/images/DuplicateIcon';
import { useShareWithCompany } from 'lib/api/teleprompter/shareScript';
import { useCopyScript } from 'lib/api/teleprompter/copyScript';
import { useGetTeleprompterScripts } from 'lib/api/teleprompter/getScripts';
import { SCOPE } from 'lib/api/teleprompter/types';
import { Button } from 'react-covideo-common';

const ScriptCard = styled.div`
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const ScriptTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.coal};
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
`;

const MyScriptsContainer = styled.div`
  margin-top: 32px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.palette.gray20};
`;

type TabProps = {
  active?: boolean;
  primaryColor?: string;
};

const Tab = styled.div<TabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;

  color: ${props => (props.active ? props.primaryColor : theme.palette.gray60)};
  border-bottom: 4px solid
    ${props => (props.active ? props.primaryColor : 'transparent')};

  cursor: pointer;
  user-select: none;
  div {
    margin-left: 8px;
  }
`;
const TabLeft = styled.div`
  display: flex;
`;
const PillContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-left: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.palette.gray60};
  align-items: left;
`;

const TELEPROMPTER_TABS = {
  MY_SCRIPTS: 'My Scripts',
  SHARED: 'Shared with Company',
};

export const List = () => {
  const themes = useTheme();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState('');
  const [activeTab, setActiveTab] = useState(TELEPROMPTER_TABS.MY_SCRIPTS);
  const [scope, setScope] = useState(SCOPE.PERSONAL);
  const [showShareModal, setShowShareModal] = useState(0);
  const { dispatch } = useTeleprompterContext();

  const [isToggled, setIsToggled] = useState(false);

  const { userData } = useAuth();
  const { mutateAsync: shareWithCompany } = useShareWithCompany(scope);
  const { mutateAsync: copyScript } = useCopyScript(scope);

  const {
    data: scriptData = {},
    refetch: refetchScripts,
    isLoading: loading,
  } = useGetTeleprompterScripts({
    scope,
    limit: size,
    start: page * size,
  });

  const { count, scripts = [] } = scriptData;
  const history = useHistory();

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const goToScript = (scriptId: string) =>
    history.push(`/profile/teleprompter/${scriptId}`);

  //TODO: Preview is based on constant values as of now
  const goToPreview = (scriptId: any) => {
    history.push({
      pathname: `/profile/teleprompter/${scriptId}/preview`,
      state: {
        scriptSpeed: {
          value: 20,
          label: '240 words / min',
        },
        position: 'top',
        size: 'large',
      },
    });
  };

  const handleModalWhiteButton = async () => {
    try {
      await removeScript({
        dispatch,
        data: {
          scriptId: showDeleteModal,
        },
      });

      refetchScripts();

      successToast({
        title: 'You successfully moved script to recently deleted bin!',
      });

      setShowDeleteModal('');
    } catch (e) {
      console.log(e);
    }
  };

  const isPersonalTab = activeTab === TELEPROMPTER_TABS.MY_SCRIPTS;
  const isSharedTab = activeTab === TELEPROMPTER_TABS.SHARED;

  const handleShowOnlyPersonal = () => {
    if (isToggled) {
      setScope(SCOPE.PERSONAL_SHARED);
      setIsToggled(false);
      return;
    }
    setScope(SCOPE.SHARED);
    setIsToggled(true);
  };

  const onShareWithCompany = async (scriptId: number) => {
    await shareWithCompany(scriptId);
  };

  const onCopyScript = async (scriptId: number) => {
    await copyScript(scriptId);
  };

  useEffect(() => {
    refetchScripts();
  }, [scope, page, size]);

  useEffect(() => {
    setPage(0);
    if (isPersonalTab) setScope(SCOPE.PERSONAL);
    if (isSharedTab && !isToggled) setScope(SCOPE.SHARED);
    if (isSharedTab && isToggled) setScope(SCOPE.PERSONAL_SHARED);
  }, [activeTab, isToggled]);

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Heading>Teleprompter Scripts</Heading>
          <Button
            text='Write New'
            icon={<MdAdd size={20} />}
            onClick={() => goToScript('add')}
          />
        </HeaderWrapper>

        <TabContainer>
          <TabLeft>
            {Object.values(TELEPROMPTER_TABS).map(tab => (
              <Tab
                primaryColor={themes.colors.primary[100]}
                active={tab === activeTab}
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                <MdLibraryAdd fontSize={24} />
                <div>{tab}</div>
              </Tab>
            ))}
          </TabLeft>

          {isSharedTab && (
            <PillContainer>
              Show only mine
              <Switch
                id='my-scripts'
                handleToggle={handleShowOnlyPersonal}
                isOn={isToggled}
                onColor={themes.colors.primary[100]}
              />
            </PillContainer>
          )}
        </TabContainer>

        <MyScriptsContainer>
          <TableContextProvider
            total={count}
            onChange={onPaginationChange}
            initPage={page}
            initSize={size}
          >
            {!!count &&
              !loading &&
              scripts.map((script: ScriptItem) => {
                const isScriptOwner = script.userId.toString() === userData.id;

                const isEditable =
                  !script.sharedWithCompany ||
                  (script.sharedWithCompany && isScriptOwner);

                const isShareable =
                  !script.sharedWithCompany && isScriptOwner && isPersonalTab;

                const canBeCopied =
                  !isScriptOwner && script.sharedWithCompany && isSharedTab;

                const isDeletable =
                  (!script.sharedWithCompany &&
                    isScriptOwner &&
                    isPersonalTab) ||
                  (script.sharedWithCompany &&
                    userData.isCompanyAdmin &&
                    isSharedTab);

                return (
                  <ScriptCard key={script.scriptId}>
                    <ScriptTitle>{script.title}</ScriptTitle>

                    <RightSide>
                      {isSharedTab ? (
                        <Subtitle>
                          {isScriptOwner
                            ? 'You'
                            : `${script.user.firstName} ${script.user.lastName}`}
                        </Subtitle>
                      ) : (
                        <Subtitle></Subtitle>
                      )}
                      <ActionButtons>
                        {isShareable && (
                          <Button
                            onClick={() =>
                              setShowShareModal(Number(script.scriptId))
                            }
                            variant='secondary'
                            text='Share with Company'
                          />
                        )}
                        <Button
                          icon={<MdPlayArrow size={20} />}
                          onClick={() => goToPreview(script.scriptId)}
                          text='Preview'
                          variant='secondary'
                        />

                        {canBeCopied && (
                          <Button
                            variant='secondary'
                            text='Copy to My Scripts'
                            icon={<DuplicateIcon />}
                            onClick={async () => {
                              await onCopyScript(Number(script.scriptId));
                            }}
                          />
                        )}

                        {isEditable && (
                          <Button
                            icon={<MdEdit size={20} />}
                            onClick={() => goToScript(script.scriptId)}
                            variant='secondary'
                          />
                        )}

                        {isDeletable && (
                          <Button
                            icon={<DeleteIcon width='24px' height='24px' />}
                            onClick={() => setShowDeleteModal(script.scriptId)}
                            variant='destructive'
                          />
                        )}
                      </ActionButtons>
                    </RightSide>
                  </ScriptCard>
                );
              })}

            {!scripts.length && !loading ? (
              <Text>No Script found.</Text>
            ) : (
              <LoadingIndicator
                isLoading={loading}
                height={loading ? '500px' : '0'}
              />
            )}
            {count > 0 && (
              <TableFooter>
                <TablePaginationNew />
                <TablePaginationSizeNew text='Show scripts:' />
              </TableFooter>
            )}
          </TableContextProvider>
          {showDeleteModal && (
            <ModalDelete
              whiteButtonText='Yes, Delete'
              orangeButtonText='No, Cancel'
              title={`You want to move script to recently deleted bin?`}
              text={`Scripts are automatically deleted after 30 days.`}
              handleModalClose={() => setShowDeleteModal('')}
              onClickWhiteButton={() => handleModalWhiteButton()}
              onClickOrangeButton={() => setShowDeleteModal('')}
            />
          )}
          {!!showShareModal && (
            <NewModal
              closeModal={() => setShowShareModal(0)}
              headerText='Share Teleprompter Script with Company?'
            >
              <div>Once shared, only the admin could delete it.</div>
              <Gap justifyContent='right' m='40px 12px 0 0'>
                <Button
                  text='Share with Company'
                  variant='primary'
                  onClick={async () => {
                    await onShareWithCompany(showShareModal);
                    setShowShareModal(0);
                  }}
                />
              </Gap>
            </NewModal>
          )}
        </MyScriptsContainer>
      </MainWrapper>
    </Container>
  );
};
