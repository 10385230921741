import * as React from 'react';
import { useState } from 'react';
import { some } from 'lodash';
import styled from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import LaptopIcon from 'lib/images/LaptopIcon';
import AccountIcon from 'lib/images/AccountIcon';
import { useHistory } from 'react-router';
import { theme } from 'lib/style';
import SettingsIcon from 'lib/images/SettingsIcon';
import { ModalRecordingSettings } from 'lib/components/modal/ModalRecordingSettings';
import { IncompatibleMobile } from './IncompatibleMobile';
import { useAuth } from 'lib/context';
import { getVideosCount } from 'lib/api';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { getDevices } from '../../../../../lib/utils/functions';
import { Gap, MainWrapper } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import { RecordOptionPath } from '../../const';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

const isOpera =
  //@ts-ignore
  (!!window.opr && !!opr.addons) ||
  //@ts-ignore
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

//@ts-ignore
const isFirefox = typeof InstallTrigger !== 'undefined';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
  ? true
  : !!navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
const isAndroid = /(android)/i.test(navigator.userAgent);

const Content = styled.div`
  padding: 56px 112px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 592px;
  height: 261px;
  padding: 32px 104px 51px;
  border-radius: 8px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

const ContainerHeader = styled.span`
  margin: 0 118px 32px 151px;
  ${theme.fontBold700}
  font-size: 24px;
  line-height: 1.67;
  text-align: center;
  color: ${theme.palette.black_1_100};
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 176px;
  width: ${!isFirefox && !isOpera ? 592 : 385}px;
  justify-content: space-between;
`;

const RecordOption = styled.div`
  display: flex;
  flex-direction: column;
  width: 108px;
  height: 108px;
  padding: 32px;
  border-radius: 4px;
  border: solid 2px #eeeff2;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #4e5461;
  span {
    color: ${theme.palette.black_1_100};
  }
  &:hover {
    cursor: pointer;
    border: solid 2px #d0d3d9;
  }
`;

const ScreenCamContainer = styled.div`
  .account {
    background-color: #4e5461;
    border-radius: 50px;
    position: absolute;
    margin-left: -80px;
    margin-top: 42.5px;
    border: 2px solid white;
  }
`;

const SettingsContainer = styled.div`
  width: 800px;
  display: flex;
  margin-top: 32px;
  justify-content: center;
`;

const RecordError = styled.div`
  margin-top: 24px;
`;

const LegacyLink = styled.a`
  margin-top: 36px;
  color: #0000ee;
  &:visited {
    color: #0000ee;
  }
`;

type Props = {
  dispatch?: any;
  loading?: boolean;
  showHelpDesk?: boolean;
  showLegacyRecorderSwitch?: boolean;
  onRecordOptionClick?: (path: RecordOptionPath | null) => void;
};

export const Main = ({
  loading = false,
  showHelpDesk = true,
  showLegacyRecorderSwitch = true,
  onRecordOptionClick,
}: Props) => {
  const history = useHistory();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = React.useState({
    videoSource: { value: '' } as any,
    videoQuality: 'standard',
    audioSource: { value: 'default' } as any,
  });

  const [userVideoCount, setUserVideoCount] = useState(0);

  const { userData, whitelabel } = useAuth();

  React.useEffect(() => {
    fetchUserVideoCount();
  }, []);

  const fetchUserVideoCount = async () => {
    const { count } = await getVideosCount();
    setUserVideoCount(count);
  };
  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id != 0) {
        if (
          userPackageDetails.maxVideosCreated != 0 &&
          userVideoCount >= userPackageDetails.maxVideosCreated
        ) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, [userData, userVideoCount]);

  React.useEffect(() => {
    if (isSafari) {
      return;
    }
    const saved = JSON.parse(localStorage.getItem('record_settings') || '{}');
    const audio = [] as any[];
    const video = [] as any[];

    getDevices()
      .then((devices: any) => {
        if (!some(devices, { kind: 'videoinput' })) {
          throw new Error('No camera detected');
        }
        devices.forEach((device: any) => {
          if (!device || !device.label) {
            return;
          }
          if (device.kind == 'audioinput') {
            audio.push({ label: device.label, value: device.deviceId });
          }
          if (device.kind == 'videoinput') {
            video.push({ label: device.label, value: device.deviceId });
          }
        });
        audio.push({
          label: 'No Audio',
          value: false,
        });

        if (
          !saved.videoSource ||
          !video.find(v => v.value === saved.videoSource.value)
        ) {
          saved.videoSource = video[0];
        }

        if (
          !saved?.audioSource ||
          !audio.find(a => a.value === saved?.audioSource?.value)
        ) {
          saved.audioSource = audio[0];
        }

        localStorage.setItem('record_settings', JSON.stringify(saved));
        if (
          ('' + localStorage.getItem('record_error')).indexOf(
            'No camera detected'
          ) > -1
        ) {
          localStorage.setItem('record_error', '');
        }

        setSettings({
          ...settings,
          ...saved,
        });
      })
      .catch(e => {
        video.push({
          label: 'No Video',
          value: false,
        });

        if (
          !saved.videoSource ||
          !video.find(v => v.value === saved.videoSource.value)
        ) {
          saved.videoSource = video[0];
        }

        audio.push({
          label: 'No Audio',
          value: false,
        });
        if (
          !saved.audioSource ||
          !audio.find(a => a.value === saved.audioSource.value)
        ) {
          saved.audioSource = audio[0];
        }

        setSettings({ ...settings, ...saved });
        if (e.message.indexOf('Permission denied') > -1) {
          localStorage.setItem(
            'record_error',
            'Permission required. Please allow access to your camera and mic then try again.'
          );
        } else if (
          e.message &&
          (e.message.indexOf('No camera detected') > -1 ||
            e.message.indexOf('device not found') > -1)
        ) {
          localStorage.setItem(
            'record_error',
            'No camera detected. Please check your recording settings and make sure the camera is plugged in.'
          );
        } else {
          setError(e);
        }
      });

    return () => {
      localStorage.setItem('record_error', '');
      try {
        //@ts-ignore
        window.stream.getTracks().forEach((t: any) => t.stop());
      } catch (e) {}
    };
  }, []);

  const saveSettings = (s: any) => {
    setSettings(s);
    setSettingsOpen(false);
  };

  const handleRecordOptionClick = (path: RecordOptionPath) => {
    if (!onRecordOptionClick) {
      history.push(path);
      return;
    }
    onRecordOptionClick(path);
  };

  if (isAndroid || isIOS) {
    return <IncompatibleMobile isIOS={isIOS} />;
  }

  return (
    <MainWrapper resetPadding className='recordHomeMainWrapper'>
      {showHelpDesk && <HelpDesk name={EHelpDesk.RECORDED} />}
      <Content>
        <LoadingIndicator isLoading={loading} />
        {!loading && (
          <>
            <Container>
              <ContainerHeader>Choose recording option</ContainerHeader>
              <OptionsContainer>
                {!isOpera && !isFirefox && !isSafari && (
                  <RecordOption
                    onClick={() =>
                      handleRecordOptionClick(RecordOptionPath.Both)
                    }
                  >
                    <ScreenCamContainer>
                      <LaptopIcon className='laptop' height={72} width={72} />
                      <AccountIcon
                        className='account'
                        height={24}
                        width={24}
                        color='#fff'
                      />
                    </ScreenCamContainer>
                    <span>Screen+Cam</span>
                  </RecordOption>
                )}
                <RecordOption
                  onClick={() =>
                    handleRecordOptionClick(RecordOptionPath.Screen)
                  }
                >
                  <LaptopIcon height={72} width={72} />
                  <span>Screen Only</span>
                </RecordOption>
                <RecordOption
                  onClick={() => handleRecordOptionClick(RecordOptionPath.Cam)}
                >
                  <AccountIcon height={72} width={72} />
                  <span>Cam Only</span>
                </RecordOption>
              </OptionsContainer>
            </Container>
          </>
        )}
        {!!error && (
          <RecordError>
            Please allow access to your camera and/or mic then try again.
          </RecordError>
        )}
        {!error && localStorage.getItem('record_error') && (
          <RecordError>{localStorage.getItem('record_error')}</RecordError>
        )}
        <SettingsContainer>
          <Gap width='180px' justifyContent='center'>
            <Button
              icon={<SettingsIcon opacity={1} />}
              variant='secondary'
              text={'Recording settings'}
              onClick={() => setSettingsOpen(true)}
            />
          </Gap>
        </SettingsContainer>
        {whitelabel.name === WHITELABEL_NAME.COVIDEO &&
          showLegacyRecorderSwitch && (
            <LegacyLink href='/Email_RecordVideo'>
              Switch to Legacy Recorder
            </LegacyLink>
          )}
      </Content>
      {settingsOpen && (
        <ModalRecordingSettings
          initialSettings={settings}
          handleModalClose={() => setSettingsOpen(false)}
          handleSubmit={saveSettings}
        />
      )}
    </MainWrapper>
  );
};
