import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import CalendarIcon from 'lib/images/CalendarIcon';
import { Button } from 'react-covideo-common';

import 'react-datepicker/dist/react-datepicker.css';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const InputDatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  color: #9297a2;
`;

const InputDateWrapper = styled.div`
  position: relative;
  input {
    padding: 8px 8px 8px 40px;
    border-radius: 4px;
    border: solid 1px #d0d3d9;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #272a32;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  top: 8px;
  left: 8px;
  z-index: 1;
`;

const DatePickerWrapper = styled.div`
  width: 100%;
  height: 260px;
  margin-top: 20px;
  & .react-datepicker {
    width: 100%;
  }
  & .react-datepicker__month-container {
    width: 50%;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

type Props = {
  initStartDate: Date;
  initEndDate: Date;
  minDate?: Date;
  onRangeSelected: (startDate: Date, endDate: Date) => void;
  handleModalClose: () => void;
};

export const ModalDateRangeSelector = (props: Props) => {
  const {
    initStartDate,
    initEndDate,
    handleModalClose,
    onRangeSelected,
    minDate,
  } = props;

  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const onEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Custom Date Range</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <InputDatesWrapper>
            <InputDateWrapper>
              <IconWrapper>
                <CalendarIcon />
              </IconWrapper>
              <DatePicker
                onChange={onStartDateChange}
                selected={startDate}
                open={false}
                minDate={minDate}
                maxDate={new Date()}
              />
            </InputDateWrapper>

            <div style={{ margin: '0 12px' }}>-</div>
            <InputDateWrapper>
              <IconWrapper>
                <CalendarIcon />
              </IconWrapper>
              <DatePicker
                onChange={onEndDateChange}
                selected={endDate}
                open={false}
                minDate={minDate}
                maxDate={new Date()}
              />
            </InputDateWrapper>
          </InputDatesWrapper>
          <DatePickerWrapper>
            <DatePicker
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              monthsShown={2}
              minDate={minDate}
              maxDate={new Date()}
              selectsRange
              inline
            />
          </DatePickerWrapper>
        </Content>
        <Footer>
          <Button
            text='Done'
            onClick={() => onRangeSelected(startDate, endDate)}
          />
        </Footer>
      </div>
    </Modal>
  );
};
