import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal, NewTextInput, DefaultLabel } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { MdSave } from 'react-icons/md';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { Button } from 'react-covideo-common';
import { useIsMutating } from 'react-query';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 16px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
`;

const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

type Props = {
  disabled?: boolean;
  title?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (data: { name: string }) => void;
};

export const ModalMergeGroup = ({ handleModalClose, handleSubmit }: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [group, setGroup] = React.useState({
    name: '',
  });
  const [error, setError] = React.useState('');

  const { data: groups } = useQueryGroups({ search: group.name, size: 10 });

  const checkIfExists = () => {
    let err = '';
    if (
      groups?.items.find(g => g.name.toLowerCase() == group.name.toLowerCase())
    ) {
      err = `Group ${group.name} already exists`;
    }
    setError(err);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!group.name) {
      return;
    }
    checkIfExists();
  }, [groups?.items]);

  const validateName = () => {
    let err = '';
    if (!group.name) {
      err = "Group name can't be empty";
    } else if (group.name.length > 60) {
      err = `Group name can not contain more than 60 characters.`;
    }
    if (
      groups?.items.find(g => g.name.toLowerCase() == group.name.toLowerCase())
    ) {
      err = `Group ${group.name} already exists`;
    }
    /*** SUS-908 changes ***/
    if (group.name && group.name.length > 60) {
      err = `Group name can not contain more than 60 characters.`;
    }
    setError(err);
  };

  return (
    <Modal>
      <ModalItem>
        <Header>
          Merge groups
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <DefaultLabel>{`Merge Group Name`}</DefaultLabel>
          <NewTextInput
            style={{ marginTop: 8 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGroup({ ...group, name: e.target.value })
            }
            onBlur={validateName}
            placeholder={'Name'}
          />
          {error && <Text>{error}</Text>}

          <ButtonsWrapper>
            <Button
              variant='secondary'
              text='Cancel'
              onClick={handleModalClose}
              disabled={isDisabled}
            />
            <Button
              icon={<MdSave color='white' style={{ fontSize: 24 }} />}
              text='Save'
              onClick={() => handleSubmit(group)}
              disabled={!!error || isDisabled}
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
