import React, { useEffect, useState, createRef } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { RecordCamFreemium } from './components/RecordCamFreemium';
import bgImage from 'lib/images/lines-bg-recording.png';
import { useAuth } from 'lib/context';
import { VideoPlayer } from '../../video/videoPlayer';
import { DocumentHead } from 'lib/components';
import { Button } from 'react-covideo-common';
import RestartIcon from 'lib/images/RestartIcon';
import { getTitle } from 'lib/utils/functions';
import { useHistory } from 'react-router';
import { Upload } from '../../record/Upload';
import { MdOutlineSentimentSatisfied } from 'react-icons/md';
import { CardAlert } from 'lib/components/CardAlert';

type ColWrapperProps = {
  marginTop?: string;
  padding: string;
};
const MainDiv = styled.div`
  background-image: url(${bgImage});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  height: 100vh;
  ${theme.mediaQueryMaxWidth.mb} {
    background-image: none;
  }
`;
const MainWrapper = styled.div`
  display: grid;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  ${theme.mediaQueryMaxWidth.mb} {
    grid-auto-flow: row;
  }
`;
const ColWrapper = styled.div<ColWrapperProps>`
  padding: ${props => props.padding};
  font-family: 'Work Sans', serif;
  font-style: normal;
  min-width: 300px;
  margin-top: ${props => props.marginTop};
`;
const PreviewWrapper = styled.div`
  display: grid;
  margin-left: auto;
  margin-right: auto;
  gap: 12px;
  input {
    left: auto;
    right: auto;
  }
`;
const MainHeading = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: ${theme.palette.covideoBlue100};
`;
const Heading = styled.h2`
  font-family: 'Work Sans', serif;
  margin-top: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${theme.palette.covideoBlue100};
`;
const Text = styled.div`
  width: 518px;
  height: 84px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.covideoBlue100};
  margin-top: 40px;
  span {
    margin-top: 10px;
  }
`;
const Skip = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.covideoBlue100};
  margin-bottom: 12px;
  cursor: pointer;
`;
const Image = styled.img`
  height: 40px;
  width: 200px;
  margin-left: 112px;
  top: 48px;
  cursor: pointer;
  margin-top: 48px;
`;
const TitleInput = styled.input`
  display: inline-flex;
  font-size: 15px;

  font-style: normal;
  font-weight: 400;
  color: ${theme.palette.gray100};
  border-radius: 6px;
  height: 25px;
  width: 255px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.covideoBlue100};
  padding: 8px 12px;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 12px;
`;

const UploadContainer = styled.div`
  margin: 32px auto 0;
  width: 600px;
  height: 340px;
  border-radius: 24px;
  background-color: ${theme.palette.gray10};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Main = () => {
  const { userData } = useAuth();

  const [recordingBlobUrl, setRecordingBlobUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(true);
  const [videoTitle, setVideoTitle] = useState(getTitle());
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState('');
  const [cardVisible, setCardVisible] = useState(false);

  const videoRef = createRef<HTMLVideoElement>();
  const showPreview = !!recordingBlobUrl && !isRecording;
  const history = useHistory();

  const handleUpload = async () => {
    let file = await fetch(recordingBlobUrl || '').then((r: any) => r.blob());
    setFile(file);
    setFileUrl(recordingBlobUrl);
    setUploading(true);
    setRecordingBlobUrl('');
    setCardVisible(false);
  };

  useEffect(() => {
    if (navigator.mediaDevices.getUserMedia === null) {
      return;
    }
    const options = {
      video: true,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(options)
      .then(() => {
        setError(false);
      })
      .catch(error => {
        setError(true);
        console.log(error);
      });
  }, []);

  return (
    <>
      <DocumentHead title='Onboarding' />
      <MainDiv>
        <Image src={'https://www.covideo.com/images/covideo-logo-dark.svg'} />
        <MainWrapper>
          {!showPreview && !isRecording && !recordingBlobUrl && !uploading && (
            <ColWrapper marginTop={'26px'} padding={'40px 0 0 112px'}>
              <MainHeading>Well done, {userData?.firstName}!</MainHeading>
              <Heading>
                Record your first video, <span>it's easy!</span>
              </Heading>
              <Text>
                It's easy to get started. We'll walk you through how to use our
                recording tool.
                <span>
                  Don't worry about getting it perfect on the first try! Covideo
                  stores your videos securely in your library until you're ready
                  to share with your contacts.
                </span>
              </Text>
            </ColWrapper>
          )}
          <ColWrapper padding={'40px 0 0 0'}>
            {!showPreview && !uploading && (
              <React.Fragment>
                {!isRecording && (
                  <Skip onClick={() => history.push('/')}>Skip for now</Skip>
                )}
                <RecordCamFreemium
                  isError={error}
                  isRecording={isRecording}
                  setIsRecording={setIsRecording}
                  onRecordingUrlGeneration={(url: string) => {
                    setIsRecording(false);
                    setRecordingBlobUrl(url);
                    setCardVisible(true);
                  }}
                />
              </React.Fragment>
            )}
            {showPreview && !uploading && (
              <PreviewWrapper>
                <Button
                  text={'Rerecord'}
                  size='small'
                  variant='destructive'
                  onClick={() => {
                    setRecordingBlobUrl('');
                    setCardVisible(false);
                  }}
                  icon={
                    <RestartIcon
                      color={theme.palette.red100}
                      className={'alignCenter'}
                    />
                  }
                />
                <>
                  <VideoPlayer
                    id=''
                    videoSource={recordingBlobUrl}
                    videoRef={videoRef}
                    preventWideSize={true}
                    width={'592'}
                    height={'334px'}
                    playButtonPosition={'center'}
                    borderRadius={'24px'}
                    disableControls={true}
                    isFreemium={true}
                    playerBackgroundColor={theme.palette.covideoBlue60}
                    playerIconsColor={theme.palette.white}
                  />
                </>
                <TitleWrapper>
                  <TitleInput
                    value={videoTitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setVideoTitle(e.target.value)
                    }
                    name={'videoTitle'}
                    type='text'
                  />
                  <Button text={'Title Video & Save'} onClick={handleUpload} />
                </TitleWrapper>
              </PreviewWrapper>
            )}
            {cardVisible && (
              <CardAlert
                handleClose={() => setCardVisible(false)}
                title='Wow, your video turned out great - way to go!'
                icon={
                  <MdOutlineSentimentSatisfied
                    size={22}
                    color={theme.palette.green100}
                  />
                }
                backgroundColor={theme.palette.green02}
                width={'460px'}
                top={'32px'}
                titleColor={theme.palette.green100}
                content={
                  <>
                    Now you can give your video a title and save it to your
                    library.
                  </>
                }
              />
            )}
            {uploading && file && fileUrl && (
              <UploadContainer>
                <Upload
                  file={file}
                  fileName={videoTitle}
                  continueToSendShare={true}
                  isFreemium={true}
                />
              </UploadContainer>
            )}
          </ColWrapper>
        </MainWrapper>
      </MainDiv>
    </>
  );
};
