import React from 'react';
import { useHistory } from 'react-router';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import {
  ListDropdownMenu,
  LoadingIndicator,
  ModalDelete,
  Search,
} from 'lib/components';
import { HiPencil } from 'react-icons/hi';
import { DotsIcon } from 'lib/images/DotsIcon';
import DeleteIcon from 'lib/images/DeleteIcon';
import PlusIcon from 'lib/images/PlusIcon';
import { BoardModal, EmptyBoards } from '../../components';
import { MdAddToPhotos, MdFolderShared } from 'react-icons/md';
import { BiAddToQueue } from 'react-icons/bi';
import { successToast } from 'lib/components/toasts/success';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { MainWrapper, Container } from 'lib/components/styles/layout';
import {
  Actions,
  ButtonWrapper,
  GridContent,
  GridItem,
  GridThumbnail,
  GridView,
  ICON_COLOR,
  LinkButton,
  Menu,
  Tab,
  TabContainer,
  Title,
} from './style';
import { BOARDS_FALLBACK_THUMBNAIL, DROPTYPE_OPTIONS } from 'lib/const/Droplr';
import { useGetBoards } from 'lib/api/droplr/getBoards';
import { useRemoveBoard } from 'lib/api/droplr/removeBoard';
import { Button } from 'react-covideo-common';
import { CombinedBoard } from 'lib/api/droplr/types';

export const List = () => {
  const { userData } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = React.useState('');
  const [menuOpenedForBoard, setMenuOpenedForBoard] = React.useState('');
  const [showBoardModal, setShowBoardModal] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [currentTab, setCurrentTab] = React.useState<
    DROPTYPE_OPTIONS.OWNED | DROPTYPE_OPTIONS.SHARED
  >(DROPTYPE_OPTIONS.OWNED);

  const {
    userData: { userId, customerId },
  } = useAuth();

  const { mutateAsync: removeBoard } = useRemoveBoard({
    userId: Number(userId),
    customerId: Number(customerId),
  });
  const {
    data: boardsResponse,
    isLoading: loading,
    refetch: refetchBoards,
  } = useGetBoards({
    userId: Number(userId),
    customerId: Number(customerId),
  });
  const boards = boardsResponse?.boards || [];

  const ownedBoards = boards.filter(board => {
    return board.userStatus === 'creator';
  });

  const sharedBoards = boards.filter(board => {
    return board.userStatus !== 'creator';
  });

  const boardsToShow =
    currentTab === DROPTYPE_OPTIONS.OWNED ? ownedBoards : sharedBoards;

  const history = useHistory();

  const copyLink = (board: CombinedBoard) => {
    const cb = navigator.clipboard;
    cb.writeText(board.shortlink || '').then(() => {
      successToast({ title: 'Link to board copied successfully!' });
    });
  };

  const goToBoard = (boardId: string) =>
    history.push(`/boards/${boardId}/${currentTab}`);

  const actionItems = [
    {
      title: 'Edit',
      icon: <HiPencil color={ICON_COLOR} size={20} />,
      onClick: (id: string) => {
        setShowBoardModal(id);
      },
    },
    {
      title: 'Delete',
      icon: <DeleteIcon color={ICON_COLOR} width={20} height={20} />,
      onClick: (id: string) => {
        setShowDeleteModal(id);
      },
    },
  ];

  const actionComponent = (board: CombinedBoard) => (
    <Actions>
      <LinkButton onClick={() => copyLink(board)}>Copy Link</LinkButton>
      {board.userStatus === 'creator' && (
        <>
          <ButtonWrapper>
            <DotsIcon
              onClick={() =>
                setMenuOpenedForBoard(
                  menuOpenedForBoard !== board.id ? board.id : ''
                )
              }
              color={theme.palette.blue80}
              style={{ cursor: 'pointer' }}
            />
          </ButtonWrapper>

          <ListDropdownMenu
            className={'alignDropdownMenu'}
            isMenuOpen={menuOpenedForBoard === board.id}
            items={actionItems}
            id={board.id}
            setIsMenuOpen={() => setMenuOpenedForBoard('')}
          />
        </>
      )}
    </Actions>
  );

  if (!userData.droplrAccess) {
    history.push('/profile/add-ons');
    return null;
  }

  return (
    <Container>
      <MainWrapper resetPadding>
        {loading && <LoadingIndicator isLoading={loading} height='500px' />}
        {!loading && (
          <>
            <>
              <HelpDesk name={EHelpDesk.BOARDS} />
              <TabContainer>
                <Tab
                  className={
                    currentTab === DROPTYPE_OPTIONS.OWNED ? 'activeTab' : ''
                  }
                  onClick={() => {
                    setCurrentTab(DROPTYPE_OPTIONS.OWNED);
                  }}
                  title={'myBoards'}
                >
                  <BiAddToQueue />
                  &nbsp;My Boards
                </Tab>
                <Tab
                  className={
                    currentTab === DROPTYPE_OPTIONS.SHARED ? 'activeTab' : ''
                  }
                  onClick={() => {
                    setCurrentTab(DROPTYPE_OPTIONS.SHARED);
                  }}
                  title={'sharedWithMe'}
                >
                  <MdAddToPhotos />
                  &nbsp;Shared with me
                </Tab>
              </TabContainer>
              <br />
            </>
            {!!boardsToShow.length && (
              <div>
                <Menu>
                  <div style={{ width: '320px', marginRight: 'auto' }}>
                    <Search
                      placeholder={`Search boards...`}
                      onSearch={setSearchQuery}
                      onChange={(e: any) => setSearchQuery(e.target.value)}
                      prevSearch={searchQuery}
                    />
                  </div>
                  {currentTab === DROPTYPE_OPTIONS.OWNED && (
                    <Button
                      text='Create new'
                      icon={<PlusIcon />}
                      onClick={() => setShowBoardModal('new')}
                    />
                  )}
                </Menu>
                {!!boardsToShow.length && (
                  <GridView>
                    {boardsToShow.map((board: CombinedBoard) => {
                      if (searchQuery) {
                        if (
                          board.name
                            .toLowerCase()
                            .search(searchQuery.toLowerCase()) < 0
                        ) {
                          return <React.Fragment key={board.id} />;
                        }
                      }

                      return (
                        <GridItem key={board.id}>
                          <GridContent>
                            <Title onClick={() => goToBoard(board.id)}>
                              {board.name}
                              {board.userStatus !== 'creator' && (
                                <MdFolderShared
                                  style={{
                                    color: '#001B53',
                                    float: 'right',
                                    fontSize: '24px',
                                  }}
                                />
                              )}
                            </Title>
                            <GridThumbnail
                              image={
                                board.thumbnailUrl || BOARDS_FALLBACK_THUMBNAIL
                              }
                              onClick={() => goToBoard(board.id)}
                            />

                            <div style={{ marginTop: '18px' }}>
                              {actionComponent(board)}
                            </div>
                          </GridContent>
                        </GridItem>
                      );
                    })}
                  </GridView>
                )}
              </div>
            )}
            {!boardsToShow.length && <EmptyBoards boardType={currentTab} />}
            {!!showDeleteModal && (
              <ModalDelete
                whiteButtonText='Yes, Delete'
                orangeButtonText='No, Cancel'
                title={`You want to move board to recently deleted bin?`}
                text={`Boards are automatically deleted after 30 days.`}
                handleModalClose={() => setShowDeleteModal('')}
                onClickWhiteButton={async () => {
                  setShowDeleteModal('');
                  await removeBoard({ boardId: showDeleteModal });
                }}
                onClickOrangeButton={() => setShowDeleteModal('')}
              />
            )}
            {showBoardModal && (
              <BoardModal
                board={boards.find(b => b.id === showBoardModal)}
                handleModalClose={() => {
                  setShowBoardModal('');
                  refetchBoards();
                }}
              />
            )}
          </>
        )}
      </MainWrapper>
    </Container>
  );
};
