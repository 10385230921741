import { ButtonPillSwitch, LoadingIndicator, NewModal } from 'lib/components';
import { formatBytes } from 'lib/utils/functions';
import React, { useState } from 'react';
import UploadDropTab from './uploadDropTabs/UploadDropTab';
import { ExtendedFile } from './Constants';
import FilesTab from './uploadDropTabs/FilesTab';
import { LibraryTabContent } from 'app/pages/guides/components/LibraryTabContent';
import { Formik } from 'formik';
import { DefaultFolderIds } from 'lib/const/Folders';
import { useCreateDropFromVideo } from 'lib/api/droplr/createDropFromVideo';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { ThumbnailTabContent } from './ThumbnailTabContent';
import { GuideDetails } from 'lib/api/guides/guideDetailsQuery';
import { DropItem } from 'lib/api/droplr/types';

const LIBRARY_LIMIT = 10;

type Props = {
  closeModal: () => void;
  boardId?: string;
  refreshDrops?: () => void;
  excludeFilesTab?: boolean;
  isGuide?: boolean;
  updateGuide?: (param: Partial<GuideDetails>) => void;
  acceptedFileTypes?: string[];
};

const TABS = {
  UPLOAD: 'Upload',
  LIBRARY: 'Library',
  FILES: 'Files',
};

const getTabs = (excludeFilesTab = false, isGuide = false) => {
  return {
    UPLOAD: TABS.UPLOAD,
    ...(!isGuide ? { FILES: TABS.LIBRARY } : {}),
    ...(!excludeFilesTab ? { FILES: TABS.FILES } : {}),
  };
};

const AddNewDropModal = ({
  closeModal,
  boardId,
  refreshDrops,
  excludeFilesTab = false,
  isGuide = false,
  updateGuide,
  acceptedFileTypes,
}: Props) => {
  const [activeTab, setActiveTab] = useState(TABS.UPLOAD);

  const [files, setFiles] = useState<ExtendedFile[]>([]);
  const { mutateAsync: createDropFromVideo } = useCreateDropFromVideo();
  const [isDropProcessing, setIsDropProcessing] = useState(false);
  const [chosenVideoCount, setChosenVideoCount] = useState(0);
  const [guidesThumbnailStep, setGuideThumbnailStep] = useState(0);
  const [croppedImageUrl, setCroppedImageUrl] = React.useState('');
  const [editorImage, setEditorImage] = React.useState('');
  const isSecondStep = guidesThumbnailStep === 1;

  const isLibraryTab = activeTab === TABS.LIBRARY;
  const isUploadTab = activeTab === TABS.UPLOAD;
  const isFilesTab = activeTab === TABS.FILES;

  const uploadingFiles = !!files.length;
  let sumOfFiles = 0;
  let numberOfUploadedFiles = 0;

  const handleSelectFileClick = (drop: DropItem) => {
    setEditorImage(drop.previewMedium || drop.content || '');
    setCroppedImageUrl(drop.previewMedium || drop.content || '');
    if (isGuide) setGuideThumbnailStep(1);
  };

  const handleUploadThumbnail = async (thumbnailUrl: string) => {
    const guideUpdateBody = {
      customThumbnail: thumbnailUrl,
    };
    updateGuide && (await updateGuide(guideUpdateBody));
    closeModal();
  };

  const onAddFromLibrary = async (videosData: number[]) => {
    setIsDropProcessing(true);
    await Promise.allSettled(
      videosData.map(async (videoId: number) => {
        await createDropFromVideo({
          videoId: videoId,
          boardId: boardId || '',
        });
      })
    );

    setIsDropProcessing(false);
    refreshDrops && refreshDrops();
    closeModal();
  };

  const closeAndRefresh = () => {
    if (!isDropProcessing) {
      refreshDrops && refreshDrops();
      closeModal(); // do not close modal until procesing is done
    }
  };

  const closeIcon = isDropProcessing ? (
    <></>
  ) : (
    <CloseIcon
      color={theme.palette.gray60}
      width={'24px'}
      height={'24px'}
      onClick={closeModal}
    />
  );

  let headerText = !isGuide ? 'Add new' : 'Upload Thumbnail';
  if (isUploadTab && !isGuide) {
    headerText = uploadingFiles
      ? `${numberOfUploadedFiles} / ${
          files.length
        } files uploading (${formatBytes(sumOfFiles, 2)})`
      : 'Add new';
  }
  if (isLibraryTab) {
    headerText = `${chosenVideoCount} / ${LIBRARY_LIMIT} videos chosen`;
  }

  const tabValues = getTabs(excludeFilesTab, isGuide);

  return (
    <NewModal
      closeModal={isDropProcessing || uploadingFiles ? () => {} : closeModal}
      headerText={headerText}
      style={{
        content: {
          height: '540px',
          width: '665px',
          boxSizing: 'border-box',
          ...(isUploadTab &&
            uploadingFiles && {
              padding: '32px 32px 22px 32px',
            }),
        },
      }}
      closeIcon={closeIcon}
    >
      {isDropProcessing ? (
        <LoadingIndicator
          height='400px'
          isLoading
          text={`Adding video${chosenVideoCount > 1 ? 's' : ''}${
            boardId ? ' to board' : ''
          }, please wait...`}
        />
      ) : (
        <Formik
          initialValues={{
            folder: {
              value: DefaultFolderIds.PRIMARY,
              label: 'Primary',
            },
          }}
          onSubmit={() => {}}
        >
          <>
            {!isSecondStep && Object.keys(tabValues)?.length > 1 && (
              <ButtonPillSwitch
                defaultValue={activeTab}
                values={tabValues}
                onChange={(newTab: string) => setActiveTab(newTab)}
                pillStyle={{
                  width:
                    excludeFilesTab || isGuide || !!boardId ? '49%' : '32.9%',
                }}
                textStyle={{
                  width:
                    excludeFilesTab || isGuide || !!boardId ? '49%' : '32.9%',
                }}
              />
            )}
            {isUploadTab && !isSecondStep && (
              <UploadDropTab
                files={files}
                setFiles={setFiles}
                uploadingFiles={uploadingFiles}
                handleModalClose={closeAndRefresh}
                boardId={boardId}
                dropContentStyle={{
                  margin: '16px 0 0 0',
                }}
                uploadButtonText='Choose'
                isGuide={isGuide}
                handleUploadComplete={handleSelectFileClick}
                acceptedFileTypes={acceptedFileTypes}
                style={{
                  content: {
                    marginTop: '16px',
                  },
                  footer: {
                    padding: 0,
                  },
                }}
              />
            )}
            {isLibraryTab && !isSecondStep && (
              <LibraryTabContent
                closeModalHandler={closeAndRefresh}
                submitButtonText='Add'
                onAdd={onAddFromLibrary}
                setChosenVideoCount={setChosenVideoCount}
                limit={LIBRARY_LIMIT}
              />
            )}
            {isFilesTab && !isSecondStep && (
              <FilesTab
                onFileClick={handleSelectFileClick}
                closeModal={closeModal}
                boardId={boardId || ''}
                isGuide={isGuide}
                refetch={() => {
                  refreshDrops && refreshDrops();
                }}
              />
            )}

            {isGuide && isSecondStep && (
              <div>
                <ThumbnailTabContent
                  editorImage={editorImage}
                  setEditorImage={setEditorImage}
                  croppedImageUrl={croppedImageUrl}
                  setCroppedImageUrl={setCroppedImageUrl}
                  showPreview={false}
                  isCustomImage={true}
                  isGuide={true}
                  handleUploadThumbnail={handleUploadThumbnail}
                  fileProp={files[0]}
                />
              </div>
            )}
          </>
        </Formik>
      )}
    </NewModal>
  );
};

export default AddNewDropModal;
