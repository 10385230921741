import * as React from 'react';
import {
  MdModeEdit,
  MdDeleteForever,
  MdContentCopy,
  MdSend,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BsPlus } from 'react-icons/bs';
import { SimpleRow } from 'lib/components';
import { theme } from 'lib/style';
import emptyDesign from 'lib/images/empty-design-bg.svg';
import { CTA, ValidTinyInt } from 'lib/api';
import { AccessRole } from 'lib/const';
import { Button, Tooltip } from 'react-covideo-common';
// Types and Interfaces
export type CTASetListItem = {
  id: number;
  company?: ValidTinyInt;
  defaultLinks?: ValidTinyInt;
  title: string;
  isCompany: boolean;
  isDefault: boolean;
  links: CTA[];
  isHeader?: boolean;
  userId?: string;
};
export interface ModalState {
  mode: 'add' | 'delete' | 'edit' | 'duplicate' | 'none' | 'set_users';
  data?: CTASetListItem;
}
interface ComponentProps {
  items: CTASetListItem[];
  userId: string;
  loading: boolean;
  isCompanyAdmin: boolean;
  setAsDefault: (id: number, isDefault: boolean) => Promise<void>;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  access: string;
}
// Styled Components
const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${emptyDesign});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 100%;
  height: 430px;
`;
const EmptyTitle = styled.p`
  font-size: 18px;

  font-weight: 700;
  margin-bottom: 32px;
`;

// Methods
const getRowButtons = (
  item: CTASetListItem,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
  isCompanyAdmin: boolean,
  userId: string,
  history: any,
  access: string
): React.ReactNode[] => {
  const disabled = !isCompanyAdmin && !!item.userId && item.userId !== userId;

  const buttons = [
    <Button
      variant='secondary'
      icon={<MdContentCopy size={24} />}
      onClick={() => setModalState({ mode: 'duplicate', data: item })}
    />,
    <Button
      variant='secondary'
      icon={<MdModeEdit size={24} />}
      onClick={() => setModalState({ mode: 'edit', data: item })}
      disabled={disabled}
    />,
  ];

  /*
   If the cta set is company shared and logged in user is admin or 
   if logged in user is anyone but cta set is not company shared 
   then we are showing delete functionality else we are restricting 
   user to delete company shared cta set. (Change required in SUS-618)
   Added check for supervisor as supervisor can also delete the CTA that are not 
   created by them.
   */
  if (
    access === AccessRole.ADMIN ||
    (access === AccessRole.SUPERVISOR && item.isCompany) ||
    !item?.isCompany
  ) {
    buttons.push(
      <Button
        variant='destructive'
        icon={<MdDeleteForever size={24} />}
        onClick={() => setModalState({ mode: 'delete', data: item })}
        disabled={item.isDefault || disabled}
      />
    );
  }

  if (isCompanyAdmin) {
    buttons.unshift(
      <>
        {!!item.isCompany && (
          <Tooltip popup='This Cta Set is already shared with the company.'>
            <Button
              disabled={!!item?.isCompany}
              variant='secondary'
              icon={<MdSend size={24} />}
              onClick={() => setModalState({ mode: 'set_users', data: item })}
            />
          </Tooltip>
        )}
        {!item.isCompany && (
          <Button
            variant='secondary'
            icon={<MdSend size={24} />}
            onClick={() => setModalState({ mode: 'set_users', data: item })}
          />
        )}
      </>
    );
  }

  return buttons;
};
// Component and Rendering
const Component = ({
  items = [],
  loading = false,
  userId,
  isCompanyAdmin,
  setAsDefault,
  setModalState,
  access,
}: ComponentProps) => {
  const ctaSets = [...items];
  ctaSets.unshift({
    id: -1,
    title: 'Set Name',
    isCompany: false,
    isDefault: false,
    links: [],
    isHeader: true,
  });
  ctaSets.sort((ctaA, ctaB) => {
    if (ctaA.id < ctaB.id) {
      return -1;
    }
    if (ctaA.id > ctaB.id) {
      return 1;
    }
    return 0;
  });

  const history = useHistory();

  return (
    <>
      {items.length > 0
        ? ctaSets.map((item, index) => {
            const fields = [];
            fields.push(item.title);
            const isCompany = item.isCompany ? 'yes' : '-';
            const secondField = item.isHeader ? 'Company shared' : isCompany;
            fields.push(secondField);
            const isAdminView =
              access === AccessRole.ADMIN; /* SUS-1299 changes */
            return (
              <SimpleRow
                key={index}
                isHeader={item.id === -1}
                isDefault={item.isDefault}
                fields={fields}
                mainButtonText={'Set as Default'}
                allowDefaultRemoval={true}
                onClickMainButton={() => setAsDefault(item.id, true)}
                onClickRemoveDefault={() => setAsDefault(item.id, false)}
                optionButtons={getRowButtons(
                  item,
                  setModalState,
                  isCompanyAdmin,
                  userId,
                  history,
                  access
                )}
                isAdminView={isAdminView} /* SUS-1299 changes */
              />
            );
          })
        : !loading && (
            <EmptyListContainer>
              <EmptyTitle>You don’t have any Calls-to-Action</EmptyTitle>
              <Button
                text={'Add New'}
                icon={<BsPlus />}
                onClick={() => setModalState({ mode: 'add' })}
              />
            </EmptyListContainer>
          )}
    </>
  );
};

export default Component;
