import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Contact } from 'lib/api';

import {
  LoadingIndicator,
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  useTableContext,
} from 'lib/components';
import { NoHistory } from './NoHistory';
import { useQueryEmailReceiver } from 'lib/api/emailReceiver/useQueryEmailReceiver';
import { EmailEvent } from 'lib/api/emailReceiver/types';

const Content = styled.div`
  width: 100%;
`;

const TableCellHeader = styled.div`
  text-align: left;
`;

const TableCell = styled.div`
  text-align: left;
`;

const ReceiverStatus = {
  SENT: {
    value: 0,
    text: 'Sent',
    prefix: 'Email: ',
    color: 'black',
    defaultText: 'No Subject',
  },
  OPENED: {
    value: 1,
    text: 'Opened',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
  WATCHED: {
    value: 2,
    text: 'Video Play',
    prefix: 'Video: ',
    color: 'blue',
    defaultText: 'Missing Video Title',
  },
  CLICKED: {
    value: 3,
    text: 'Video Clicked',
    prefix: 'Exit Link: ',
    color: 'blue',
    defaultText: 'Missing Video Title',
  },
  EXITLINK: {
    value: 4,
    text: 'CTA Click',
    prefix: 'Exit Link: ',
    color: 'blue',
    defaultText: 'Missing ExitLink Title',
  },
  BOUNCED: {
    value: 5,
    text: 'Bounced',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
  DROPPED: {
    value: 6,
    text: 'Dropped',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
};
const getReceiverStatus = (item: any) => {
  let status = ReceiverStatus.SENT;
  switch (item.EventType) {
    case 'OPEN':
      status = ReceiverStatus.OPENED;
      break;
    case 'SENT':
      status = ReceiverStatus.SENT;
      break;
    case 'CLICKED':
      status = ReceiverStatus.CLICKED;
      break;
    case 'EXITLINK':
      status = ReceiverStatus.EXITLINK;
      break;
    case 'WATCHED':
      status = ReceiverStatus.WATCHED;
      break;
    case 'BOUNCE':
      status = ReceiverStatus.BOUNCED;
      break;
    case 'DROPPED':
      status = ReceiverStatus.DROPPED;
      break;
  }
  return status;
};

type Props = {
  contact: Contact;
  preloadData?: boolean;
};
export const ContactActivityHistory = ({
  contact,
  preloadData = false,
}: Props) => {
  const tableContext = useTableContext();

  const contactEventsParams = {
    search: contact.email,
    start: tableContext.page * tableContext.size,
    limit: tableContext.size,
    searchExactEmail: true,
  };

  const {
    data: contactEventsData,
    isLoading,
    refetch,
  } = useQueryEmailReceiver({
    params: contactEventsParams,
  });
  const contactEvents = contactEventsData?.events ?? [];
  const count = contactEventsData?.count;

  useEffect(() => {
    if (contactEventsData && count) {
      tableContext.setCount(count);
    }
  }, [contactEventsData]);

  useEffect(() => {
    if (!preloadData || isLoading || !contact) {
      return;
    }
    refetch();
  }, [contact, preloadData, tableContext.page, tableContext.size]);

  return (
    <Content>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : !isLoading && contactEvents.length == 0 ? (
        <NoHistory />
      ) : (
        <div style={{ marginTop: 16 }}>
          <Table
            compact={true}
            columnWidths={[200, 230]}
            headers={[
              <TableCellHeader>Date</TableCellHeader>,
              <TableCellHeader>Action</TableCellHeader>,
              <TableCellHeader>Activity</TableCellHeader>,
            ]}
            hoverable={false}
            rows={contactEvents.map((x: EmailEvent, index: number) => {
              let status = getReceiverStatus(x);
              return {
                key: index,
                columns: [
                  <TableCell>
                    {
                      new Date(parseInt(x.StatusUpdateTime + '000'))
                        .toLocaleString()
                        .split(',')[0]
                    }
                  </TableCell>,
                  <TableCell>{status.text}</TableCell>,
                  <TableCell>
                    {status.prefix + (x.Title ? x.Title : status.defaultText)}
                  </TableCell>,
                ],
                onClick: () => {},
              };
            })}
          />
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='contact_activity' />
          </TableFooter>
        </div>
      )}
    </Content>
  );
};
