import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { theme } from 'lib/style';

import { LoadingIndicator } from 'lib/components';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { ManageSubscriptionModal } from './ManageSubscriptionModal';
import { useAuth } from 'lib/context';

import { PackageName } from 'lib/const/PackageAdditionalInfo';
import {
  CurrentSubscription,
  PaymentMethodOverview,
  UsersOverview,
  AddonsOverview,
  PaymentMethodEnterpriseOverview,
} from '.';
import {
  Addon,
  useSubscriptionQuery,
} from 'lib/api/billing/useSubscriptionQuery';
import { useCardsQuery } from 'lib/api/billing/useCardsQuery';

const Content = styled.div`
  width: 100%;
  margin-top: 24px;
  color: #272a32;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

const OverviewsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 32px;
`;

const OverviewCard = styled.div`
  box-sizing: border-box;
  display: block;
  width: 314px;
  max-width: 100%;
  background: ${theme.palette.white};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 4px;
  padding: 16px 24px;
  min-height: 128px;
`;

const DEFAULT_PACKAGE = PackageName.LEGACY;

export const BillingOverview = withRouter(() => {
  const { userData } = useAuth();
  const [showManageModal, setShowManageModal] = React.useState(false);
  const [showAmModal, setShowAmModal] = React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState<any>({});
  const [addonData, setAddonData] = React.useState<any>([]);
  const [currentPackageId, setCurrentPackageId] =
    React.useState(DEFAULT_PACKAGE);

  const {
    data: subscription,
    isLoading: loadingSubscription,
    refetch: refetchSubscription,
  } = useSubscriptionQuery();
  const { data: cardsData, isLoading: loadingCards } = useCardsQuery({
    limit: 10,
  });

  useEffect(() => {
    if (subscription?.addons) {
      const activeAddons = subscription.addons.filter(
        (addon: Addon) => addon.active
      );
      setAddonData(activeAddons);
    }
  }, [subscription]);

  useEffect(() => {
    if (userData?.user?.packageDetails?.id) {
      setCurrentPackageId(userData?.user?.packageDetails?.id);
    }
  }, [userData]);

  const handleMangeModal = (data: any) => {
    setSubscriptionData(data);
    setShowManageModal(true);
  };

  return (
    <>
      {!(loadingSubscription || loadingCards) ? (
        <Content>
          <CurrentSubscription
            handleMangeModal={handleMangeModal}
            addonData={addonData}
            setShowAmModal={setShowAmModal}
            subscription={subscription}
          />
          {currentPackageId !== PackageName.FREE && (
            <OverviewsWrapper>
              <OverviewCard>
                {currentPackageId !== PackageName.ENTERPRISE && (
                  <PaymentMethodOverview card={cardsData?.methods[0]} />
                )}
                {currentPackageId === PackageName.ENTERPRISE && (
                  <PaymentMethodEnterpriseOverview />
                )}
              </OverviewCard>
              {currentPackageId !== PackageName.PRO && (
                <OverviewCard>
                  <UsersOverview
                    totalActiveUsers={subscription?.totalActiveUsers}
                    totalAllowedUsers={subscription?.totalAllowedUsers}
                  />
                </OverviewCard>
              )}
              <OverviewCard>
                <AddonsOverview addonData={addonData || []} />
              </OverviewCard>
            </OverviewsWrapper>
          )}
        </Content>
      ) : (
        <LoadingIndicator isLoading={loadingSubscription || loadingCards} />
      )}
      {showManageModal && (
        <ManageSubscriptionModal
          subscriptionData={subscriptionData}
          handleModalClose={shouldRefresh => {
            setSubscriptionData({});
            setShowManageModal(false);
            if (shouldRefresh) {
              // Refetch the subscription data if needed
              refetchSubscription();
            }
          }}
        />
      )}
      {showAmModal && (
        <ModalContactAM
          handleModalClose={() => setShowAmModal(false)}
          subject='Covideo Legacy Plan Update'
          title='Would you like to manage your subscription?'
          description={
            <div>
              We are unable to view your <b>current pricing plan</b>. If you
              want to change your subscription, <b>contact your AM</b>.
            </div>
          }
        />
      )}
    </>
  );
});
