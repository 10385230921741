import styled from 'styled-components/macro';
import { ReactComponent as Svg } from './level-down.svg';

const LevelDownIcon = styled(Svg)`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
  fill: ${props => props.color || 'currentColor'};
`;

export default LevelDownIcon;
