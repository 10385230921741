import { getAttributeValueMetrics } from 'lib/api';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { BreadCrumbs } from 'lib/components/breadcrumbs/BreadCrumbs';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components/macro';
import Header from './Header';
import FilterStrip from './FilterStrip';
import {
  loadFilteringData,
  SORTING,
  VIDEO_ATTRIBUTES,
} from 'lib/utils/videoAttributes';
import { OrderTableArrowIcon } from 'lib/images/OrderTableArrow';
import queryString from 'query-string';
import { AccessRole, reportData } from 'lib/const';
import { MainWrapper } from 'lib/components/styles/layout';
import { NotFound } from 'app/pages/notFound/NotFound';
import dayjs from 'dayjs';

const TableCell = styled.div`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;

const tableFields = [
  {
    value: 'value',
    label: 'Value',
  },
  {
    value: 'totalViews',
    label: 'Total Views',
  },
  {
    value: 'averageViews',
    label: 'Avg. Views',
  },
  {
    value: 'videoCount',
    label: 'Total Videos',
  },
];

const VideoAttributeDetails = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [videoAttribute, setVideoAttribute] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [search, setSearch] = useState('');
  const [activeTotalVideosSort, setActiveTotalVideosSort] = useState('');
  const { userData } = useAuth();
  const isAdmin = !(userData.access.toString() === AccessRole.USER);
  const history = useHistory();
  const location = useLocation();
  const report = reportData[VIDEO_ATTRIBUTES];

  const queryParams: any = queryString.parse(location.search);
  const [startDate, setStartDate] = React.useState<Date>(
    queryParams.start ? new Date(queryParams.start) : new Date()
  );
  const [endDate, setEndDate] = React.useState<Date>(
    queryParams.end ? new Date(queryParams.end) : new Date()
  );
  const [selectedUser, setSelectedUser] = useState(queryParams?.user || '');
  const selectData = isAdmin
    ? [
        {
          data: departments,
          value: selectedDepartment,
          queryParam: 'department',
          setValue: setSelectedDepartment,
          onChange: (option: any) => {
            setPage(0);
            setSelectedDepartment(option.value);
          },
        },

        {
          data: users,
          value: selectedUser,
          queryParam: 'user',
          setValue: setSelectedUser,
          onChange: (option: any) => {
            setPage(0);
            setSelectedUser(option.value);
          },
        },
      ]
    : [];
  selectData.forEach(select => {
    queryParams[select.queryParam] = select.value;
  });

  const breadCrumbList = [
    {
      url: '/reports/data/all',
      title: 'All Data',
    },
    {
      url: '/reports/overview/video-attributes',
      title: 'Video Attributes',
    },
    {
      url: '/reports/overview/video-attributes/' + videoAttribute?.attributeId,
      title: videoAttribute?.title,
    },
  ];

  const loadData = async () => {
    const id = window.location.pathname.split('/').pop() || '';
    setLoading(true);
    try {
      const attributeData = await getAttributeValueMetrics(id, {
        from: dayjs(startDate).format('YYYY-MM-DD'),
        to: dayjs(endDate).format('YYYY-MM-DD'),
        user: selectedUser,
        department: selectedDepartment,
        search,
        sort: activeTotalVideosSort,
      });

      if (attributeData.data.length) setData(attributeData.data);
      setVideoAttribute(attributeData.videoAttribute);
    } catch (e) {
      setVideoAttribute(null);
      console.log(e);
    }
    setLoading(false);
  };

  const onSort = () => {
    setActiveTotalVideosSort(
      activeTotalVideosSort === SORTING.ASC ? SORTING.DESC : SORTING.ASC
    );
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const formatTableData = (attributeValue: any) => {
    return tableFields.map((item, index) => {
      const value = attributeValue[item.value];
      const isFirst = !index;
      const isLast = index === tableFields.length - 1;
      const isNumber = !isNaN(Number(value));
      const isEmpty = !value;

      let displayValue = value;
      let tableCellStyle = {};

      if (isEmpty) displayValue = '-';
      else if (isNumber) {
        const numValue = Number(value).toFixed(2);
        displayValue = addThousandCommaSeparator(Number(numValue));
      }

      if (isFirst)
        tableCellStyle = {
          textAlign: 'left',
        };
      if (isLast) {
        tableCellStyle = {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        };
        displayValue = (
          <>
            <div>{displayValue}</div>
            <FaChevronRight
              size={13}
              color={theme.palette.gray40}
              onClick={() => {
                history.push(
                  '/reports/overview/video-attributes/' +
                    videoAttribute.attributeId +
                    '/' +
                    attributeValue.valueId +
                    '?' +
                    queryString.stringify(queryParams)
                );
              }}
              style={{ cursor: 'pointer', marginLeft: '18px' }}
            />
          </>
        );
      }

      return <TableCell style={tableCellStyle}>{displayValue}</TableCell>;
    });
  };

  const formatTableHeaders = () => {
    return tableFields.map((item, index) => {
      const isFirst = !index;
      const isLast = index === tableFields.length - 1;
      if (isLast)
        return (
          <TableCell
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              outline: 'none !important',
              userSelect: 'none',
              marginRight: '20px',
            }}
            onClick={onSort}
          >
            <div style={{ marginRight: '10px' }}>{item.label}</div>
            <OrderTableArrowIcon
              color={
                activeTotalVideosSort === SORTING.ASC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              rotate={180}
            />
            <OrderTableArrowIcon
              color={
                activeTotalVideosSort === SORTING.DESC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
            />
          </TableCell>
        );

      return (
        <TableCell
          style={{
            ...(isFirst && { textAlign: 'left' }),
          }}
        >
          {item.label}
        </TableCell>
      );
    });
  };

  useEffect(() => {
    loadData();
  }, [
    startDate,
    endDate,
    selectedDepartment,
    selectedUser,
    search,
    activeTotalVideosSort,
  ]);

  useEffect(() => {
    loadFilteringData(userData.customerId, setDepartments, setUsers);
  }, []);

  useEffect(() => {
    const start = page * size;
    setFilteredData([...data.slice(start, start + size)]);
  }, [data, page, size]);

  if (!videoAttribute) {
    return <NotFound />;
  }

  return (
    <MainWrapper resetPadding={false}>
      <BreadCrumbs
        links={breadCrumbList}
        queryParams={queryString.stringify(queryParams)}
      />
      <Header
        onDateRangeChange={(start: Date, end: Date) => {
          setStartDate(start);
          setEndDate(end);
        }}
        downloadData={{
          reportTitle: `video-attributes-${videoAttribute.title}.csv`,
          url: `${report.url}/download`,
          from: startDate,
          to: endDate,
          data: {
            user: selectedUser,
            department: selectedDepartment,
            search,
            sort: activeTotalVideosSort,
            downloadType: 'VALUE_DETAILS',
            videoAttributeId: videoAttribute.attributeId,
          },
        }}
        sendUrl={report.url}
        title={'Video Attributes'}
      />
      <FilterStrip
        onSearch={(term: string) => setSearch(term)}
        selectData={selectData}
      />
      {loading ? (
        <LoadingIndicator isLoading={loading} height='300px' />
      ) : data.length ? (
        <TableContextProvider
          total={data.length}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <Table
            compact={true}
            hoverable={false}
            headers={[...formatTableHeaders()]}
            rows={filteredData.map((attributeValue: any, index: number) => ({
              key: index,
              columns: [...formatTableData(attributeValue)],
            }))}
            columnWidths={['50%']}
            style={{ tableLayout: 'fixed', marginTop: '20px' }}
          ></Table>

          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='video_attributes_report' />
          </TableFooter>
        </TableContextProvider>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          No data to show.
        </div>
      )}
    </MainWrapper>
  );
};

export default VideoAttributeDetails;
