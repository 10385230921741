import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Field, Form, ErrorMessage, FieldProps, FormikErrors } from 'formik';
import { Button } from 'react-covideo-common';
import { RadioInput, SelectInput } from 'lib/components';
import { TextInput, EmailInput } from 'lib/components';
import { IoMdSave } from 'react-icons/io';
import { QUOTE_SETTINGS } from 'app/pages/repairOrders/components/RepairOrderListing';
import Switch from '../../../../design/landingPageBuilder/components/Switch';
import { Region, useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import { MdClear, MdHelp, MdLock } from 'react-icons/md';
import { ReactionPreferenceOptionsForCompany } from 'lib/const';
import { Heading, ParagraphExtraSmall } from 'lib/components/styles/typography';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import CreatableSelect from 'react-select/creatable';
import { creatableSelectStyle } from 'lib/style/commonStyles';
import { Option } from 'lib/components/dropdown/Dropdown';
import { MarkAsSentOptions } from 'lib/const/MarkAsSentOptions';
import { CompanyValues } from './companyValidator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  GOOGLE_MAPS_API_KEY,
  GooglePlace,
  generateGoogleMapsPreview,
} from 'lib/utils/googleMapsEmbed';
import selectors from '../../../../../../cypress/selectors';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { Tooltip } from 'react-covideo-common';
import { theme } from 'lib/style';

interface FormProps {
  isSubmitting?: boolean;
  editCompanyName?: boolean;
  setFieldValue: any;
  values: any;
  isAutomotiveServiceRole: boolean;
  regions?: Region[];
  isAutomotive: boolean;
  dirty: boolean;
  isValid: boolean;
  errors: FormikErrors<CompanyValues>;
  resellerId: string;
}
interface SectionTitleProps {
  margin?: string;
}
const FlexForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .upgrade-button {
    display: flex;
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;

  :first-child {
    padding: 0 0 0.5rem 0;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const FieldLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 115px;
  color: rgba(146, 151, 162, 1);
  margin-right: 16px;
`;

const SectionTitle = styled.p<SectionTitleProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: ${props => (props.margin ? props.margin : '0')};
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const SectionSubtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  margin: 0 0 16px 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  max-width: 700px;
  margin-left: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #eeeff2;
`;

const ToggleParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowWrapper = styled.div`
  width: 100%;
`;

const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(39, 42, 50, 1);
  }
`;

const ReactionCommentsWrapper = styled.div`
  padding-top: 6px !important;
  width: 94px;
  padding-right: 8px;
`;
const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 34px;
`;

const IFrameWrapper = styled.div`
  > iframe {
    width: 100%;
    height: 250px;
    margin-top: 1rem;
    border-radius: 4px;
  }
`;

const ClearMapWrapper = styled.div`
  cursor: pointer;
`;

const GoogleAutocompleteWrapper = styled.div`
  width: 100%;
`;

export const CompanyForm = (props: FormProps) => {
  const {
    editCompanyName = true,
    isAutomotiveServiceRole,
    isAutomotive,
    isSubmitting,
    setFieldValue,
    values,
    dirty,
    isValid,
    regions = [],
    resellerId,
  } = props;
  const themes: any = useTheme();
  const [isReplyHidden, setIsReplyHidden] = React.useState(true);
  const [chosenGooglePlace, setChosenGooglePlace] =
    React.useState<GooglePlace | null>();
  const [googleMapPreview, setGoogleMapPreview] = React.useState('');
  const { userData } = useAuth();

  const isAutominerCustomer =
    userData.customer.autominerEnabled?.toString() === '1';
  const regionOptions = userData.hasRegions
    ? regions.map((region: Region) => ({
        label: region.name,
        value: region.regionId,
      }))
    : [];

  React.useEffect(() => {
    const isReplyDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_REPLY
    );

    isReplyDisabled && setFieldValue('allowReplies', false);
    setIsReplyHidden(isReplyDisabled);
  }, []);

  return (
    <FlexForm>
      <HeaderWrapper>
        <Heading>Company Profile</Heading>
        <Button
          text={isSubmitting ? 'Saving' : 'Save changes'}
          type='submit'
          disabled={isSubmitting || !isValid || !dirty}
          variant='primary'
          icon={<IoMdSave />}
          data-cy={selectors.companyProfile.button.save}
        />
      </HeaderWrapper>
      {/* Business info */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Business info</SectionTitle>
        <RowWrapper>
          <Row>
            <FieldLabel>Business Name *</FieldLabel>
            <Field name='businessName'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      required={true}
                      readOnly={isSubmitting || !editCompanyName}
                      dataCy={selectors.companyProfile.field.business}
                    />
                    <ErrorMessage name='businessName' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          {userData.hasRegions && (
            <Row>
              <FieldLabel>Region</FieldLabel>
              <Field name='region'>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <CreatableSelect
                        {...field}
                        isDisabled={isSubmitting}
                        components={{
                          IndicatorSeparator: () => {
                            return null;
                          },
                        }}
                        formatCreateLabel={() => <></>}
                        styles={{
                          ...creatableSelectStyle,
                          indicatorsContainer: (base: any) => ({
                            ...base,
                            cursor: 'pointer',
                          }),
                          valueContainer: (base: any) => ({
                            ...base,
                            overflow: 'unset',
                          }),
                        }}
                        placeholder={'Add region...'}
                        optionHeight={40}
                        isMulti={false}
                        noOptionsMessage={() => null}
                        isClearable={true}
                        isSearchable={false}
                        options={regionOptions}
                        value={regionOptions.find(
                          (opt: Option) =>
                            opt?.value?.toString() ===
                            values?.region?.value?.toString()
                        )}
                        onChange={(opt: any) => {
                          setFieldValue('region', {
                            value: Number(opt?.value) || 0,
                            label: opt?.label || '',
                          });
                        }}
                      />
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
          <Row>
            <FieldLabel>First Name *</FieldLabel>
            <Field name='firstNameBusiness' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' required={true} />
                    <ErrorMessage
                      name='firstNameBusiness'
                      component={ErrorMsg}
                    />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          <Row>
            <FieldLabel>
              Last Name{' '}
              {Number(resellerId) !== NAPLETON_AUTOMOTIVE_RESELLER_ID
                ? '*'
                : ''}
            </FieldLabel>
            <Field name='lastNameBusiness' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput
                      {...field}
                      type='text'
                      required={
                        Number(resellerId) !== NAPLETON_AUTOMOTIVE_RESELLER_ID
                      }
                    />
                    <ErrorMessage
                      name='lastNameBusiness'
                      component={ErrorMsg}
                    />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          <Row>
            <FieldLabel>Email *</FieldLabel>
            <Field name='businessEmail' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <EmailInput {...field} type='email' />
                    <ErrorMessage name='businessEmail' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      </div>
      <Divider />
      {/* Contact info */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Contact</SectionTitle>
        <RowWrapper>
          <Row>
            <FieldLabel>Phone 1 *</FieldLabel>
            <Field name='businessPhone1' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' required={true} />
                    <ErrorMessage name='businessPhone1' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          <Row>
            <FieldLabel>Phone 2</FieldLabel>
            <Field name='businessPhone2' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' />
                    <ErrorMessage name='businessPhone2' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          <Row>
            <FieldLabel>Fax</FieldLabel>
            <Field name='businessFax' readOnly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          <Row>
            <FieldLabel>URL</FieldLabel>
            <Field
              name='businessUrl'
              readOnly={isSubmitting}
              placeholder='ex: http://www.mywebsite.com'
            >
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextInput {...field} type='text' />
                    <ErrorMessage name='businessUrl' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          </Row>
          {userData.isAutomotive && (
            <Row>
              <FieldLabel>Google Map</FieldLabel>
              <Field name='mapIframeEmbedCode'>
                {({ field, form }: FieldProps) => {
                  setGoogleMapPreview(generateGoogleMapsPreview(field.value));
                  return (
                    <FieldContainer>
                      <TextInput {...field} type='hidden' />
                      <Gap
                        gap='8px'
                        justifyContent='space-between'
                        flexWrap='nowrap'
                      >
                        <GoogleAutocompleteWrapper>
                          <GooglePlacesAutocomplete
                            apiKey={GOOGLE_MAPS_API_KEY}
                            selectProps={{
                              value: chosenGooglePlace,
                              isClearable: false,
                              placeholder: `Start typing to ${
                                field.value ? 'change' : 'find'
                              } your location...`,
                              components: {
                                IndicatorSeparator: null,
                                DropdownIndicator: null,
                              },
                              onChange: value => {
                                form.setFieldValue(
                                  'mapIframeEmbedCode',
                                  value?.value?.place_id || ''
                                );
                                setChosenGooglePlace(value as GooglePlace);
                              },
                            }}
                          />
                        </GoogleAutocompleteWrapper>
                        {!!field.value && (
                          <ClearMapWrapper>
                            <MdClear
                              onClick={() => {
                                form.setFieldValue('mapIframeEmbedCode', '');
                                setChosenGooglePlace(null);
                                setGoogleMapPreview('');
                              }}
                            />
                          </ClearMapWrapper>
                        )}
                      </Gap>
                      {!!googleMapPreview && (
                        <IFrameWrapper
                          dangerouslySetInnerHTML={{
                            __html: googleMapPreview,
                          }}
                        />
                      )}
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
        </RowWrapper>
      </div>
      <Divider />
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Video Reply</SectionTitle>
        <RowWrapper>
          {!isReplyHidden && (
            <>
              <Field name='allowReplies' readonly={isSubmitting}>
                {() => {
                  return (
                    <ToggleWrapper>
                      <ToggleParagraph>Enable Video Reply</ToggleParagraph>
                      <Switch
                        id={'switch-video-reply'}
                        isOn={!!values.allowReplies}
                        onColor={themes.colors.primary[100]}
                        handleToggle={() => {
                          setFieldValue(`allowReplies`, !values.allowReplies);
                        }}
                      />
                    </ToggleWrapper>
                  );
                }}
              </Field>
              <Field name='reactionsPreference' readonly={isSubmitting}>
                {() => {
                  return (
                    <ReplyWrapper>
                      <ReactionCommentsWrapper>
                        Reactions & Comments
                      </ReactionCommentsWrapper>
                      <div>
                        <RadioInputWrapper>
                          <RadioInput
                            checkedColor={themes.colors.primary[100]}
                            checked={
                              values.reactionsPreference ==
                              ReactionPreferenceOptionsForCompany.ALL_USERS
                            }
                            name='reactionsPreference'
                            value={''}
                            onChange={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.ALL_USERS
                              )
                            }
                            onClick={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.ALL_USERS
                              )
                            }
                          />
                          <span>Disable for all users</span>
                        </RadioInputWrapper>
                        <RadioInputWrapper>
                          <RadioInput
                            checkedColor={themes.colors.primary[100]}
                            checked={
                              values.reactionsPreference ==
                              ReactionPreferenceOptionsForCompany.TURN_ON_DEFAULT
                            }
                            name='reactionsPreference'
                            value={''}
                            onChange={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.TURN_ON_DEFAULT
                              )
                            }
                            onClick={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.TURN_ON_DEFAULT
                              )
                            }
                          />
                          <span>Enable and turn on by default</span>
                        </RadioInputWrapper>
                        <RadioInputWrapper>
                          <RadioInput
                            checkedColor={themes.colors.primary[100]}
                            checked={
                              values.reactionsPreference ==
                              ReactionPreferenceOptionsForCompany.TURN_OFF_DEFAULT
                            }
                            name='reactionsPreference'
                            value={''}
                            onChange={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.TURN_OFF_DEFAULT
                              )
                            }
                            onClick={() =>
                              setFieldValue(
                                'reactionsPreference',
                                ReactionPreferenceOptionsForCompany.TURN_OFF_DEFAULT
                              )
                            }
                          />
                          <span>Enable and turn off by default</span>
                        </RadioInputWrapper>
                      </div>
                    </ReplyWrapper>
                  );
                }}
              </Field>
            </>
          )}
          {isReplyHidden && (
            <Button
              text={'Upgrade to Pro'}
              disabled={isSubmitting}
              className={'upgrade-button'}
              variant='white'
              icon={<MdLock />}
              onClick={() => (window.location.href = '/upgrade-plan')}
            />
          )}
        </RowWrapper>
      </div>

      <Divider />

      {isAutomotiveServiceRole && (
        <div style={{ display: 'flex', maxWidth: 700 }}>
          <SectionTitle>Quote settings</SectionTitle>
          <RowWrapper>
            <SectionSubtitle>Calculate close ratio based on:</SectionSubtitle>
            <RadioInputWrapper>
              <RadioInput
                checked={values.quoteSettings === QUOTE_SETTINGS.DOLLAR}
                name='quoteSettings'
                value={QUOTE_SETTINGS.DOLLAR}
                onChange={() =>
                  setFieldValue('quoteSettings', QUOTE_SETTINGS.DOLLAR)
                }
                onClick={() =>
                  setFieldValue('quoteSettings', QUOTE_SETTINGS.DOLLAR)
                }
              />
              <span>dollar amount</span>
            </RadioInputWrapper>
            <RadioInputWrapper>
              <RadioInput
                checked={values.quoteSettings === QUOTE_SETTINGS.ITEM}
                name='quoteSettings'
                value='item'
                onClick={() =>
                  setFieldValue('quoteSettings', QUOTE_SETTINGS.ITEM)
                }
                onChange={() =>
                  setFieldValue('quoteSettings', QUOTE_SETTINGS.ITEM)
                }
              />
              <span>line items</span>
            </RadioInputWrapper>
          </RowWrapper>
        </div>
      )}

      {isAutomotive && (
        <div style={{ display: 'flex', maxWidth: 700 }}>
          <Tooltip
            extendStyles={{
              element: {
                width: '100%',
                maxWidth: '200px',
              },
              popup: {
                padding: '10px',
              },
            }}
            popup={
              <ParagraphExtraSmall>
                Mark videos as “Sent” when video link is copied.
              </ParagraphExtraSmall>
            }
          >
            <SectionTitle>
              Mark 'Sent' on Copy <MdHelp color={theme.palette.midGrey} />
            </SectionTitle>
          </Tooltip>
          <RowWrapper>
            <Field name='markVideosAsSent' readonly={isSubmitting}>
              {({ field }: FieldProps) => {
                const { value, ...rest } = field;
                return (
                  <SelectInput
                    {...rest}
                    defaultValue={value}
                    disabled={isSubmitting}
                  >
                    {Object.values(MarkAsSentOptions).map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </SelectInput>
                );
              }}
            </Field>
          </RowWrapper>
        </div>
      )}

      {isAutomotive && (
        <>
          <Divider />
          <div style={{ display: 'flex', maxWidth: 700 }}>
            <SectionTitle margin='auto'>700Credit URL</SectionTitle>
            <RowWrapper>
              <Field name='credit700Url' readonly={isSubmitting}>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <TextInput
                        {...field}
                        type='text'
                        required={false}
                        readOnly={isSubmitting || !editCompanyName}
                      />
                    </FieldContainer>
                  );
                }}
              </Field>
            </RowWrapper>
          </div>
        </>
      )}

      {isAutominerCustomer && (
        <>
          <Divider />
          <div style={{ display: 'flex', maxWidth: 700 }}>
            <SectionTitle margin='auto'>Autominer Group ID</SectionTitle>
            <RowWrapper>
              <Field name='autominerGroupId' readonly={isSubmitting}>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <TextInput {...field} type='text' required={false} />
                    </FieldContainer>
                  );
                }}
              </Field>
            </RowWrapper>
          </div>
        </>
      )}
    </FlexForm>
  );
};
