import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { QueryListParams, VideoChapterItem } from '../types';

import { guidesKeyes } from './guidesKeyes';
import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';

interface GuidesResponse {
  count: number;
  guides: Guides[];
}

export interface Guides {
  videoId: number;
  userId: number;
  guideCategoryId: number;
  name: string;
  description: null;
  order: null;
  links: Links[] | null;
  video: Video;
  isEditable?: boolean;
  customThumbnail?: string | null;
  isNew?: boolean;
}
export interface Links {
  link: string;
  label: string;
}

export interface Video {
  id: number;
  thumbnail?: string;
  videoLength?: number;
  title?: string;
  videoSource?: string;
  playButtonPosition?: string;
  playerBackgroundColor?: string;
  playerIconsAndTextColor?: string;
  chapters?: VideoChapterItem[] | null;
  isGuide?: boolean;
  processing?: VideoEncodingStatus;
}

const getGuides = async ({
  page = 0,
  size = 10,
  search,
}: QueryListParams): Promise<GuidesResponse> => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
  };

  return (
    await EXPRESS_API.get(`guides`, {
      params,
    })
  ).data as GuidesResponse;
};

export const useGuidesQuery = (options: QueryListParams, enabled: boolean) => {
  const { showError } = useToastError();
  const searchValue = options?.search || '';
  return useQuery(guidesKeyes.search(searchValue), () => getGuides(options), {
    onError: showError,
    enabled,
    refetchOnMount: true,
  });
};
