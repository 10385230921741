import { EXPRESS_API } from 'configs/api/expressApi';

export const getYoutubeAuthUrl = async () => {
  try {
    const response = await EXPRESS_API.get('/youtube/auth-url', {
      data: {
        from: 'covideo',
      },
    });
    return await response.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkYoutubeIntegration = async () => {
  try {
    const response = await EXPRESS_API.get('/youtube/integration');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getChannelData = async () => {
  try {
    const response = await EXPRESS_API.get('/youtube/channel');
    return await response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVideoAnalytics = async (id: number) => {
  try {
    const response = await EXPRESS_API.get(`/youtube/video/${id}/analytics`);
    return await response.data;
  } catch (error) {
    console.log(error);
  }
};

export const saveYoutubeAccessToken = async (code: string) => {
  try {
    const response = await EXPRESS_API.post('/youtube/token', {
      code,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const savePrivacySetting = async (privacy: string) => {
  try {
    const response = await EXPRESS_API.patch('/youtube/privacy', {
      privacy,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uploadVideo = async (
  videoId: string,
  title: string,
  description: string
) => {
  try {
    const response = await EXPRESS_API.post('/youtube/share-video', {
      title,
      description,
      videoId,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const deleteIntegration = async () => {
  try {
    const response = await EXPRESS_API.delete('/youtube/integration');
    return response;
  } catch (error) {
    console.log(error);
  }
};
