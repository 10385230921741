import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundTemplate } from 'app/pages/notFound';
import { TeleprompterContextProvider } from 'lib/context';
import { List } from './List';
import { Details } from './Details';
import { Preview } from './Preview';

export const Main = () => (
  <TeleprompterContextProvider>
    <Switch>
      <Route path="/profile/teleprompter" component={List} exact={true} />
      <Route
        path="/profile/teleprompter/:scriptId"
        component={Details}
        exact={true}
      />
      <Route
        path="/profile/teleprompter/:scriptId/preview"
        component={Preview}
        exact={true}
      />
      <Route path="*" component={NotFoundTemplate} />
    </Switch>
  </TeleprompterContextProvider>
);
