import * as React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components/macro';
import CreatableSelect from 'react-select/creatable';
import Select, {
  components,
  ControlProps,
  FormatOptionLabelMeta,
  IndicatorProps,
  MenuPlacement,
  MenuPosition,
  OptionProps,
} from 'react-select';
import { DropdownIcon } from 'lib/images/DropdownIcon';
import { theme } from 'lib/style';
import CheckmarkIcon from 'lib/images/CheckmarkIcon';
import FolderIcon from '../../images/FolderIcon';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import { SelectComponents } from 'react-select/src/components';

export type Option = {
  value: string | number;
  label: string | number;
  isFolderShared?: boolean;
  access?: boolean | string;
};

type Icon = 'checkmark' | 'folder' | '';

type Props = {
  icon?: Icon;
  value: any;
  defaultValue?: any;
  options: Array<Option> | any;
  placeholder?: string;
  isLoading?: boolean;
  name?: string;
  className?: string;
  dropdownHeight?: string | number;
  height?: number;
  width?: number;
  disabled?: boolean;
  zIndexProp?: number;
  menuZIndexProp?: number;
  creatable?: boolean;
  error?: boolean;
  inputId?: any;
  menuPortalTarget?: HTMLElement | null;
  menuPosition?: MenuPosition;
  menuPlacement?: MenuPlacement;
  ariaLabel?: string;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onChange: (value: any, actionMeta?: any) => void;
  onInputChange?: (inputValue: any, actionMeta?: any) => void;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  extendStyles?: {
    container?: React.CSSProperties;
    menu?: React.CSSProperties;
    control?: React.CSSProperties;
    menuList?: React.CSSProperties;
    valueContainer?: React.CSSProperties;
    input?: React.CSSProperties;
  };
  showIndicator?: boolean;
  chipValueSelected?: boolean;
  isClearable?: boolean;
  isMulti?: any /* used as any because creatable select not considering it as boolean */;
  onCreateOption?: (e?: any) => void;
  isShareVideoModal?: boolean;
  isSearchable?: boolean;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  menuShouldBlockScroll?: boolean;
  minMenuHeight?: number;
  closeMenuOnScroll?: boolean | EventListener | undefined;
  filterOption?:
    | ((option: Option, rawInput: string) => boolean)
    | null
    | undefined;
  formatOptionLabel?:
    | ((
        option: any,
        labelMeta: FormatOptionLabelMeta<any, any>
      ) => React.ReactNode)
    | undefined;
  customComponents?: Partial<SelectComponents<any, any>> | undefined;
};

type DropdownArrowProps = {
  menuIsOpen?: boolean;
};

export type DropdownIndicatorProps = React.PropsWithChildren<
  IndicatorProps<Option, false>
>;

export const DropdownArrowIcon = styled.span<DropdownArrowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  svg {
    ${props => props.menuIsOpen && 'transform: rotate(180deg);'}
  }
`;

export const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const OptionChildWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const OptionIconWrapper = styled.div`
  text-align: center;
`;

//generate icon if selected
const getIcon = (
  iconName: Icon | undefined,
  isSelected: boolean,
  id: string
) => {
  if (typeof iconName === 'undefined') return null;
  switch (iconName) {
    case 'checkmark':
      return isSelected && <CheckmarkIcon />;
    case 'folder':
      return id.includes('option-0') ? (
        <div style={{ marginRight: '-6px', marginBottom: '-3px' }}>
          <FolderIcon height={16} width={20} />
        </div>
      ) : (
        isSelected && <CheckmarkIcon />
      );
    default:
      return null;
  }
};

// show different option UI if icon exist
const CustomOption = ({ children, ...props }: OptionProps<Option, false>) => {
  const { selectProps, isSelected, innerProps } = props;
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <OptionChildWrapper>{children}</OptionChildWrapper>
        <OptionIconWrapper>
          {getIcon(selectProps.icon, isSelected, innerProps.id)}
        </OptionIconWrapper>
      </OptionWrapper>
    </components.Option>
  );
};

export const getControlStyles = (props: ControlProps<Option, false>) => {
  const { isDisabled, menuIsOpen, selectProps } = props;

  if (isDisabled) {
    return { borderColor: theme.palette.transparent };
  }
  if (selectProps.error) {
    return { borderColor: theme.palette.error };
  }
  if (menuIsOpen) {
    return { borderColor: theme.palette.covideoBlue100 };
  }
  return { borderColor: theme.palette.covideoGray40 };
};

export const getOptionStyles = (
  props: OptionProps<Option, false>,
  themes: DefaultTheme,
  isShareVideoModal?: boolean /* SUS-1302 changes */
) => {
  const { isFocused, isSelected, isDisabled } = props;
  if (isDisabled) {
    return {
      color: theme.palette.gray40,
      backgroundColor: theme.palette.gray10,
      cursor: 'not-allowed',
    };
  }
  if (isFocused || isSelected) {
    /* SUS-1302 changes */
    if (isShareVideoModal) {
      return {
        color: theme.palette.black_1_100,
        backgroundColor: 'rgb(222, 235, 255)',
      };
    }
    /*****/
    return {
      color: themes.colors.primary[100],
      backgroundColor: themes.colors.secondary[5],
    };
  }

  return {
    color: theme.palette.covideoGray100,
    backgroundColor: theme.palette.white,
  };
};

export const Dropdown = (props: Props) => {
  const {
    ariaLabel = 'Aria label is missing',
    name,
    options,
    value,
    defaultValue = undefined,
    placeholder = 'No Folders',
    onChange,
    getOptionLabel,
    getOptionValue,
    menuPosition = 'absolute',
    menuPlacement = 'auto',
    menuPortalTarget,
    inputId,
    className,
    dropdownHeight = 'auto',
    height = 48,
    width,
    disabled = false,
    creatable = true,
    error = false,
    onMenuScrollToBottom,
    onMenuClose,
    extendStyles,
    icon,
    showIndicator = true,
    chipValueSelected = false,
    isClearable = false,
    onInputChange /* SUS-1302 changes */,
    onCreateOption /* SUS-1302 changes */,
    isMulti = false /* SUS-1302 changes */,
    isShareVideoModal = false /* SUS-1302 changes */,
    zIndexProp = 9999,
    menuZIndexProp = 2,
    isSearchable,
    formatCreateLabel,
    closeMenuOnScroll = undefined,
    menuShouldBlockScroll = false,
    isLoading = false,
    filterOption,
    formatOptionLabel,
    customComponents,
  } = props;
  const themes = useTheme();

  //common props
  const commonProps = {
    icon,
    error,
    options,
    className,
    name,
    placeholder,
    value,
    defaultValue,
    getOptionLabel,
    getOptionValue,
    onChange,
    menuPlacement,
    menuPosition,
    isDisabled: disabled,
    menuPortalTarget,
    'aria-label': ariaLabel,
    onMenuScrollToBottom,
    onMenuClose,
    isClearable,
    onInputChange,
    onCreateOption /* SUS-1302 changes */,
    isMulti /* SUS-1302 changes */,
    isSearchable,
    filterOption,
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return showIndicator ? (
      <DropdownArrowIcon menuIsOpen={props.selectProps.menuIsOpen}>
        <DropdownIcon width={'12px'} height={'12px'} opacity={0.5} />
      </DropdownArrowIcon>
    ) : (
      <></>
    );
  };

  const CustomSingleValue = (props: any) => {
    return chipValueSelected ? (
      <components.SingleValue {...props}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {props.children}
          <Chip
            bgColor={theme.palette.destructiveBg}
            fontColor={theme.palette.red80}
            padding='2px 4px'
            fontSize='11px'
            lineHeight='16px'
          >
            {'Active'}
          </Chip>
        </div>
      </components.SingleValue>
    ) : (
      props.children
    );
  };

  //dropdown styles
  const styles = {
    container: (base: React.CSSProperties) => {
      return {
        ...base,
        ...(extendStyles?.container || {}),
      };
    },

    control: (
      base: React.CSSProperties,
      props: ControlProps<Option, false>
    ): React.CSSProperties => {
      return {
        ...base,
        width: width,
        boxShadow: 'none',
        borderRadius: 6,
        minHeight: '40px',
        cursor: 'pointer',
        ...getControlStyles(props),
        ...(extendStyles?.control || {}),
        //@ts-ignore
        '&:hover': {
          borderColor: isShareVideoModal
            ? 'rgb(128, 189, 255)'
            : theme.palette.covideoBlue100 /* SUS-1302 changes */,
        },
        '&:focus-within': {
          borderColor: isShareVideoModal
            ? 'rgb(128, 189, 255)'
            : theme.palette.covideoBlue100 /* SUS-1302 changes */,
        },
      };
    },
    singleValue: (base: React.CSSProperties) => ({
      ...base,
      width: '95%',
    }),
    valueContainer: (base: React.CSSProperties) => ({
      ...base,
      ...(extendStyles?.valueContainer || {}),
    }),
    input: (base: React.CSSProperties) => ({
      ...base,
      ...(extendStyles?.input || {}),
    }),
    menuPortal: (base: React.CSSProperties) => ({
      ...base,
      zIndex: zIndexProp || 9999,
    }),
    menuList: (base: React.CSSProperties) => ({
      ...base,
      ...(extendStyles?.menuList || {}),
      height: dropdownHeight,
    }),
    menu: (base: React.CSSProperties) => ({
      ...base,
      ...(extendStyles?.menu || {}),
      marginTop: 2,
      zIndex: menuZIndexProp || 2,
      color: theme.palette.primaryDarkBlue,
    }),
    option: (
      base: React.CSSProperties,
      props: OptionProps<Option, false>
    ): React.CSSProperties => {
      return {
        ...base,
        height: isShareVideoModal ? 35 : height /* SUS-1302 changes */,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        fontWeight: isShareVideoModal ? 'normal' : 600 /* SUS-1302 changes */,
        fontSize: 15,
        ...getOptionStyles(
          props,
          themes,
          isShareVideoModal
        ) /* SUS-1302 changes */,
      };
    },
  };

  if (creatable) {
    return (
      <CreatableSelect
        {...commonProps}
        optionHeight={48}
        isLoading={false}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
          ...customComponents,
        }}
        styles={styles}
        isSearchable={isSearchable}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
      />
    );
  }

  return (
    <Select
      {...commonProps}
      inputId={inputId}
      optionHeight={48}
      maxMenuHeight={220}
      menuShouldScrollIntoView={false}
      menuPortalTarget={menuPortalTarget}
      isLoading={isLoading}
      components={{
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: () => null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        ...customComponents,
      }}
      styles={styles}
      isSearchable={isSearchable}
      closeMenuOnScroll={closeMenuOnScroll}
      menunShouldBlockScroll={menuShouldBlockScroll}
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
    />
  );
};
