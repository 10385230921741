import dayjs from 'dayjs';
import { MeetingResponse } from 'lib/api/meetings/types';
import {
  getTimezoneByUtc,
  timezonesOptions,
  updateDateToNext15MinuteInterval,
} from '../account/userAccount/schedule/utils';

export const addLinksToText = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
  );
};
export const getUserTimezone = (
  meetingDetails: MeetingResponse | undefined,
  timezone: string
) => {
  // get edit time zone
  if (meetingDetails) {
    if (meetingDetails?.deliveryTime?.length > 0) {
      const deliveryTimeDetails = meetingDetails.deliveryTime.split(';');
      return getTimezoneByUtc(deliveryTimeDetails[3]);
    }
  }
  const userPreferredTimezone = timezonesOptions.find(tz =>
    tz.utc.includes(timezone)
  );

  return userPreferredTimezone || timezonesOptions[0];
};

export const getDurationHours = (
  meetingDetails: MeetingResponse | undefined
) => {
  const hours = dayjs
    .duration(meetingDetails?.duration || 0, 'minutes')
    .hours();
  return { value: hours, label: `${hours}h` };
};

export const getDurationMinutes = (
  meetingDetails: MeetingResponse | undefined
) => {
  const minutes = dayjs
    .duration(meetingDetails?.duration || 15, 'minutes')
    .minutes();
  return { value: minutes, label: `${minutes}min` };
};

export const getCustomDateTimePickerValue = (
  meetingDetails: MeetingResponse | undefined
) => {
  if (meetingDetails) {
    const splitDate =
      meetingDetails.deliveryTime && meetingDetails.deliveryTime.split(';');
    let combinedDateTime: Date = new Date();
    if (splitDate) {
      combinedDateTime = new Date(
        `${splitDate[0]} ${splitDate[1]} ${splitDate[2]}`
      );
      return combinedDateTime;
    }
  }
  return updateDateToNext15MinuteInterval(new Date());
};
export const getDeliveryTime = (
  meetingDetails: MeetingResponse | undefined,
  timezone: string
) => {
  return `${dayjs(updateDateToNext15MinuteInterval(new Date())).format(
    'YYYY-MM-DD;hh:mm;A'
  )};${getUserTimezone(meetingDetails, timezone)?.utc[0]}`;
};
