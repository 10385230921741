import axios from 'axios';

import {
  GetLandingPagesResponse,
  GetLandingPagesParams,
  SetDefaultLandingPageResponse,
  GetLandingPagePreviewResponse,
  GetWebsiteOverlaysParams,
  GetWebsiteOverlaysResponse,
  SetDefaultWebsiteOverlayResponse,
  CreateWebsiteOverlayParams,
  CreateWebsiteOverlayResponse,
  UpdateWebsiteOverlayParams,
  UpdateWebsiteOverlayResponse,
  CreateCTASetParams,
  CreateCTASetResponse,
  GetCtaSetParams,
  GetCtaSetResponse,
  SetCTAAsDefaultResponse,
  SetLockForSocialLinkResponse,
  UpdateCTASetParams,
  UpdateCTASetResponse,
  UpdateCTAParams,
  UpdateCTAResponse,
  DeleteCTAResponse,
  CTA,
  CallToActionBase,
  GetEmailThumbnailsResponse,
  SetEmailIconDefaultsBody,
  SetEmailIconDefaultResponse,
  CreateThumbnailRecordParams,
  CreateThumbnailRecordResponse,
  UploadEmailThumbnailParams,
  UpdateThumbnailRecordParams,
} from './types';
import { EXPRESS_API } from 'configs/api/expressApi';
import {
  SetSocialLinksParams,
  SetSocialLinksResponse,
} from './socialMediaLinks/types';

// Landing Pages

export const getLandingPages = async (
  params: GetLandingPagesParams
): Promise<GetLandingPagesResponse> => {
  const response = await EXPRESS_API.get('/templates', {
    params,
  });
  return response.data;
};

export const setDefaultLandingPage = async (
  designId: number
): Promise<SetDefaultLandingPageResponse> => {
  const response = await EXPRESS_API.patch(`/templates/${designId}`);
  return response.data;
};

export const getLandingPagePreview = async (
  landingPageId: number
): Promise<GetLandingPagePreviewResponse> => {
  const response = await EXPRESS_API.post(
    '/videos/design-preview',
    {},
    {
      params: {
        landingPageId,
      },
    }
  );
  return response.data;
};

// Website Overlay Urls

export const getWebsiteOverlays = async (
  params: GetWebsiteOverlaysParams
): Promise<GetWebsiteOverlaysResponse> => {
  const response = await EXPRESS_API.get('/overlays', {
    params,
  });
  return response.data;
};

export const setDefaultWebsiteOverlay = async (
  overlayId: number
): Promise<SetDefaultWebsiteOverlayResponse> => {
  const response = await EXPRESS_API.patch(`/overlays/${overlayId}`);
  return response.data;
};

export const createWebsiteOverlay = async (
  data: CreateWebsiteOverlayParams
): Promise<CreateWebsiteOverlayResponse> => {
  const response = await EXPRESS_API.post('/overlays', data);
  return response.data;
};

export const updateWebsiteOverlay = async (
  data: UpdateWebsiteOverlayParams
): Promise<UpdateWebsiteOverlayResponse> => {
  const response = await EXPRESS_API.put('/overlays', data);
  return response.data;
};

export const deleteWebsiteOverlay = async (
  overlayId: number
): Promise<{ success: boolean; message: string }> => {
  const response = await EXPRESS_API.delete(`/overlays/${overlayId}`);
  return response.data;
};

// Calls-to-Action and Social Links
// Sets of Calls-to-Action
export const createCTASet = async (
  data: CreateCTASetParams
): Promise<CreateCTASetResponse> => {
  const response = await EXPRESS_API.post('/exitlinksets', data);
  return response.data;
};

export const getCTASets = async (
  params: GetCtaSetParams
): Promise<GetCtaSetResponse> => {
  const { search = '', start = 0, limit = 0 } = params;
  const response = await EXPRESS_API.get('/exitlinksets', {
    params: {
      search,
      start,
      limit,
    },
  });
  return response.data;
};

export const updateCTASet = async (
  params: UpdateCTASetParams
): Promise<UpdateCTASetResponse> => {
  const data = {
    id: params.id,
    title: params.title,
    isDefault: params.isDefault,
    isCompany: params.isCompany,
  };

  const { data: updateSetRes } = await EXPRESS_API.put('/exitlinksets', data);

  const updatedItems: any = [];
  if (params.ctas.length > 0) {
    for (const cta of params.ctas) {
      const updateCtaRes = await updateCTA({ data: cta }).catch(err => err);
      if (updateCtaRes instanceof Error) {
        throw updateCtaRes;
      }
      updatedItems.push(updateCtaRes);
    }
  }

  const res = { ...updateSetRes };
  res.ctaSet.links = updatedItems;

  return res;
};

export const setCTASetAsDefault = async (
  id: number,
  isDefault: boolean = true
): Promise<SetCTAAsDefaultResponse> => {
  const response = await EXPRESS_API.patch(`/exitlinksets/${id}`, {
    isDefault,
  });
  return response.data;
};

export const deleteCTASet = async (id: number): Promise<any> => {
  const response = await EXPRESS_API.delete(`/exitlinksets/${id}`);
  return response.data;
};

// Individual Call-to-Action
export const shareUsersCtaSets = async (
  id: number,
  payload: {
    userIds: string[];
  }
) => {
  const response = await EXPRESS_API.post(`/exitlinksets/${id}/share`, payload);
  return response.data;
};

export const createCTA = async (data: CallToActionBase): Promise<CTA> => {
  const response = await EXPRESS_API.post('/exitlink', data);
  return response.data;
};

export const createLandingPage = async (data: any): Promise<any> => {
  const response = await EXPRESS_API.post('/landing-pages', data);
  return response.data;
};

export const updateCTA = async ({
  data,
}: UpdateCTAParams): Promise<UpdateCTAResponse> => {
  const response = await EXPRESS_API.put(`/exitlink/${data.id}`, data);
  return response.data;
};

export const deleteCTA = async (id: number): Promise<DeleteCTAResponse> => {
  const response = await EXPRESS_API.delete(`/exitlink/${id}`);
  return response.data;
};

export const deleteCustomLandingPage = async (
  id: number | string,
  backupId: number | string
): Promise<DeleteCTAResponse> => {
  const response = await EXPRESS_API.delete(`/landing-pages/${id}`, {
    data: {
      backupDesignId: backupId,
    },
  });
  return response.data;
};

export const setSocialLinks = async (
  data: SetSocialLinksParams
): Promise<SetSocialLinksResponse> => {
  const response = await EXPRESS_API.post('/social-link', data);
  return response.data;
};

export const setLockForSocialLink = async (
  isLocked: boolean
): Promise<SetLockForSocialLinkResponse> => {
  const response = await EXPRESS_API.patch('/social-link/lock-social-links', {
    isLocked,
  });
  return response.data;
};

// Email Thumbnails
export const getEmailThumbnails = async (p: {
  search: string;
  start: number;
  limit: number;
}): Promise<GetEmailThumbnailsResponse> => {
  const { search = '', start = 0, limit = 0 } = p;
  const response = await EXPRESS_API.get('/emailicons', {
    params: {
      search,
      start,
      limit,
    },
  });
  return response.data;
};

export const createEmailThumbnailRecord = async (
  data: CreateThumbnailRecordParams
): Promise<CreateThumbnailRecordResponse> => {
  const response = await EXPRESS_API.post('/emailicons', data);
  return response.data;
};

export const uploadEmailThumbnail = async ({
  data,
  onUploadProgress,
}: UploadEmailThumbnailParams): Promise<any> => {
  const response = await axios.put(data.uploadURL, data.file, {
    onUploadProgress,
    headers: {
      'Content-Type': data.mimeType,
    },
  });
  return response.data;
};

export const setEmailThumbnailAsDefault = async (
  data: SetEmailIconDefaultsBody
): Promise<SetEmailIconDefaultResponse> => {
  const response = await EXPRESS_API.patch('/emailicons/set-default', data);
  return response.data;
};

export const editEmailIcon = async (
  data: UpdateThumbnailRecordParams
): Promise<any> => {
  const response = await EXPRESS_API.put('/emailicons/update', data);
  return response.data;
};

export const deleteEmailIcon = async (id: number): Promise<any> => {
  const response = await EXPRESS_API.delete(`/emailicons/${id}`);
  return response.data;
};
