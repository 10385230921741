import React, { useEffect } from 'react';
import {
  HeaderCenter,
  HeaderSide,
  LinkBtn,
  LinkWrapper,
  ModalBody,
  ModalFullscreen,
  ModalHeader,
} from 'app/pages/library/videos/videoList/components/SendAndShare/styles';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { Main as RecordCamMain } from '../recordCam/main';
import { Main as RecordScreenMain } from '../recordScreen/main';
import { Main as RecordBothMain } from '../recordBoth/main';
import { RecordOptionPath } from '../const';

const ModalBodyStyled = styled(ModalBody)`
  overflow-y: auto;
  padding: 0;
  .recordOptionWrapper {
    margin: 0 auto;
    min-height: calc(100vh - 183px);
  }
`;

type Props = {
  recordOption: RecordOptionPath | null;
  handleModalClose: () => void;
  handleShowRecordHome: () => void;
  handleRecordingBlobReady: (recordingBlobUrl: string) => void;
};

export const ModalRecordOption = ({
  handleModalClose,
  handleShowRecordHome,
  recordOption,
  handleRecordingBlobReady,
}: Props) => {
  useEffect(() => {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }

    return () => {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ''
        );
      }
    };
  }, []);

  return (
    <ModalFullscreen>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter></HeaderCenter>
        <HeaderSide></HeaderSide>
      </ModalHeader>
      <ModalBodyStyled>
        {recordOption === RecordOptionPath.Cam && (
          <RecordCamMain
            handleShowRecordHome={handleShowRecordHome}
            handleRecordingBlobReady={handleRecordingBlobReady}
          />
        )}
        {recordOption === RecordOptionPath.Screen && (
          <RecordScreenMain
            handleShowRecordHome={handleShowRecordHome}
            handleRecordingBlobReady={handleRecordingBlobReady}
          />
        )}
        {recordOption === RecordOptionPath.Both && (
          <RecordBothMain
            handleShowRecordHome={handleShowRecordHome}
            handleRecordingBlobReady={handleRecordingBlobReady}
          />
        )}
      </ModalBodyStyled>
    </ModalFullscreen>
  );
};
