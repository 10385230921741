import styled from 'styled-components/macro';
import { ReactComponent as AccountSvg } from './account-icon.svg';

const IconAccount = styled(AccountSvg)`
  width: ${props => props.width || '18px'};
  height: ${props => props.height || '20px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default IconAccount;
