import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { FreemiumOnboardingPageTemplate } from '../components';
import { Dropdown, LoadingIndicator } from 'lib/components';
import {
  CDS_DEPARTMENTS,
  INDUSTRIES,
  cdsDepartmentOptions,
  companySizeOptions,
  covideoIndustryOptions,
} from '../const';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import companyIcon from 'lib/images/freemiumOnboarding/company-icon.svg';
import companyIconCds from 'lib/images/freemiumOnboarding/company-icon-cds.svg';
import { useAuth } from 'lib/context';
import { Heading, Label, MainHeading, TextInputField } from './index.styled';
import { checkIfMobile } from 'lib/utils/functions';

type SelectCompanySizeItemProps = {
  selected?: boolean;
  backgroundColor?: string;
};
const SelectCompanySizeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

const SelectCompanySizeItem = styled.div<SelectCompanySizeItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : theme.palette.blue05};
  border: 1px solid ${theme.palette.covideoGray40};
  border-radius: 5px;
  flex: none;
  &:hover {
    cursor: pointer;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    flex: 1;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    min-width: 58px;
  }
  span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    white-space: pre;
    color: ${props =>
      props.selected ? theme.palette.white : theme.palette.covideoGray60};
  }
`;

const IndustryDropdownWrapper = styled.div`
  display: flex;
`;

const IndustryDropdown = styled.div`
  flex: 1;
`;

const OtherIndustry = styled.div`
  flex: 2.5;
  padding-left: 8px;
`;

const HighlightedText = styled.div<{ color: string }>`
  display: inline;
  color: ${props =>
    props.color ? props.color : theme.palette.primaryThemeColor};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const MyTeam = () => {
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const themes = useTheme();
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [companyName, setCompanyName] = useState(
    freemiumOnboardingData?.companyName || ''
  );
  const [companySize, setCompanySize] = useState(
    freemiumOnboardingData?.companySize || ''
  );

  const [industry, setIndustry] = React.useState(
    freemiumOnboardingData?.industry ||
      (isAutomotive ? INDUSTRIES.AUTOMOTIVE : '')
  );
  const [otherIndustry, setOtherIndustry] = React.useState('');

  const [department, setDepartment] = React.useState('');

  const [otherDepartment, setOtherDepartment] = React.useState('');

  const isMobile = checkIfMobile();

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  const getRightSideGraphic = () => {
    if (isAutomotive) {
      return (
        <img
          alt='icon'
          src={companyIconCds}
          width={'70%'}
          style={{ marginLeft: isMobile ? '10%' : 75 }}
        />
      );
    }
    return <img alt='icon' src={companyIcon} />;
  };

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={1}
      stepText='About my company'
      rightColumnChildren={getRightSideGraphic()}
      goToNextStepDisabled={
        !companyName ||
        !companySize ||
        !industry ||
        (!isAutomotive && industry === INDUSTRIES.OTHER && !otherIndustry) ||
        (isAutomotive && !department) ||
        (isAutomotive &&
          department === CDS_DEPARTMENTS.OTHER &&
          !otherDepartment)
      }
      nextStepRoute='/onboarding/myself'
      dataToBeSaved={{
        companyName,
        companySize,
        industry: industry !== INDUSTRIES.OTHER ? industry : otherIndustry,
        department:
          department !== CDS_DEPARTMENTS.OTHER ? department : otherDepartment,
      }}
      leftColumnHeader={
        <>
          <MainHeading>Hi {userData?.firstName}!</MainHeading>
          <Heading>
            Let’s personalize your Covideo experience{' '}
            <HighlightedText color={themes.colors.primary[100]}>
              in just 2 steps!
            </HighlightedText>
          </Heading>
        </>
      }
      leftColumnChildren={
        <>
          <Label margin='0 0 8px 0'>Company Name</Label>
          <TextInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCompanyName(e.target.value)
            }
            value={companyName}
            autoFocus={true}
          />
          <Label margin='16px 0 8px 0'>How big is your team?</Label>
          <SelectCompanySizeOptionsWrapper>
            {companySizeOptions.map((item, index) => (
              <SelectCompanySizeItem
                key={`company-select-${index}`}
                onClick={() => setCompanySize(item)}
                selected={item === companySize}
                backgroundColor={
                  item === companySize
                    ? themes.colors.primary[100]
                    : theme.palette.blue05
                }
              >
                <span>{item}</span>
              </SelectCompanySizeItem>
            ))}
          </SelectCompanySizeOptionsWrapper>
          <Label margin='16px 0 8px 0'>
            {isAutomotive ? 'Department' : 'Industry'}
          </Label>
          <IndustryDropdownWrapper>
            {!isAutomotive && (
              <>
                <IndustryDropdown>
                  <Dropdown
                    creatable={false}
                    onChange={item => setIndustry(item.value)}
                    options={covideoIndustryOptions}
                    value={covideoIndustryOptions.find(
                      v => v.value === industry
                    )}
                    placeholder='Select Industry'
                    className='dropdown'
                  />
                </IndustryDropdown>
                {industry === INDUSTRIES.OTHER && (
                  <OtherIndustry>
                    <TextInputField
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOtherIndustry(e.target.value)
                      }
                      value={otherIndustry}
                      autoFocus={true}
                    />
                  </OtherIndustry>
                )}
              </>
            )}
            {isAutomotive && (
              <>
                <IndustryDropdown>
                  <Dropdown
                    creatable={false}
                    onChange={item => setDepartment(item.value)}
                    options={cdsDepartmentOptions}
                    value={covideoIndustryOptions.find(
                      v => v.value === department
                    )}
                    placeholder='Select Department'
                    className='dropdown'
                  />
                </IndustryDropdown>
                {department === CDS_DEPARTMENTS.OTHER && (
                  <OtherIndustry>
                    <TextInputField
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOtherDepartment(e.target.value)
                      }
                      value={otherDepartment}
                      autoFocus={true}
                    />
                  </OtherIndustry>
                )}
              </>
            )}
          </IndustryDropdownWrapper>
        </>
      }
    />
  );
};
