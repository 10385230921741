import React from 'react';
import DeleteIcon from 'lib/images/DeleteIcon';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { useParams } from 'react-router';
import { UseMutateAsyncFunction } from 'react-query';
import { AxiosError } from 'axios';
import { useAuth } from 'lib/context';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { ModalsType } from '../conversations.types';
import { ReactComponent as AssignIcon } from '../icons/assign.svg';
import { ReactComponent as ReassignIcon } from '../icons/reassign.svg';

type AxiosErrorType = AxiosError<{ message: string }>;

type DeleteActionType = UseMutateAsyncFunction<any, AxiosErrorType, string>;

interface IActions {
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
  deleteAction: DeleteActionType;
  singleConversation: ISingleConversation;
}

export const useThreeDotsActions = ({
  deleteAction,
  singleConversation,
  setModals,
}: IActions) => {
  const { id } = useParams() as { id: string };
  const {
    userData: { isCompanyAdmin },
  } = useAuth();

  const isUnassigned = singleConversation.userId === null;

  return [
    /*     {
      id: 'snooze',
      text: 'Snooze for...',
      onClick: () => null,
      icon: <MdOutlineAccessTimeFilled />,
      disabled: true,
    }, */
    {
      id: 'send_transcript',
      text: 'Send Transcript',
      onClick: () => setModals('send_transcript'),
      icon: <BsFileEarmarkTextFill size={14} />,
      disabled: false,
    },
    //if company admin return assign/reassign action
    ...(!!isCompanyAdmin
      ? [
          {
            id: 'reassign',
            text: isUnassigned ? 'Assign Conversation' : 'Reassign',
            onClick: () => setModals('reassign'),
            icon: isUnassigned ? <AssignIcon /> : <ReassignIcon />,
            disabled: false,
          },
        ]
      : []),
    //if conversation deleted remove it from items
    ...(singleConversation.deleted
      ? []
      : [
          {
            id: 'delete',
            text: 'Delete',
            onClick: () => deleteAction(id),
            icon: <DeleteIcon width='20px' height='20px' />,
            disabled: false,
          },
        ]),
  ];
};
