import { DocumentHead, PageTemplate, SidebarNavigation } from 'lib/components';
import {
  IntegrationRoles,
  ForbiddenFeaturesForWhitelabels,
  ManageCustomerRoles,
  ManageUsageReportRoles,
  ManageUserRoles,
  ResellerReportRoles,
  ManageOrganizationRoles,
  ManageIMSRoles,
  ManageMLMRoles,
  SuperAdminRole,
} from 'lib/const/SuperAdminRole';
import { useAuth } from 'lib/context';
import OrganizationIcon from 'lib/images/OrganizationIcon';
import { MdAddLink, MdLocationPin } from 'react-icons/md';
import { InventoryManagmentSystem } from './ims/InventoryManagmentSystem';
import { MultiLocationsPages } from './multiLocation/main';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountsIcon from '../../../lib/images/AccountsIcon';
import ContactsIcon from '../../../lib/images/ContactsIcon';
import { IntegrationsIcon } from '../../../lib/images/IntegrationsIcon';
import ReportsIcon from '../../../lib/images/ReportsIcon';
import ResellerIcon from '../../../lib/images/ResellerIcon';
import { Customers } from './customers';
import Integrations from './integrations/Main';
import { Organizations } from './organizations/Organizations';
import { Resellers } from './resellers';
import { UsageReports } from './usageReports';
import { Users } from './users';

export const AdminRoutes = () => {
  const { userData } = useAuth();
  const showCustomers = ManageCustomerRoles.includes(userData.superAdminRole);
  const showResellers = ResellerReportRoles.includes(userData.superAdminRole);
  const showUsageReports = ManageUsageReportRoles.includes(
    userData.superAdminRole
  );
  const showUsers = ManageUserRoles.includes(userData.superAdminRole);
  const showOrganizations = ManageOrganizationRoles.includes(
    userData.superAdminRole
  );
  const showIms = ManageIMSRoles.includes(userData.superAdminRole);
  const showMlm = ManageMLMRoles.includes(userData.superAdminRole);
  const showIntegrations = IntegrationRoles.includes(userData.superAdminRole);
  let sidebarItems = [
    {
      title: 'MLM',
      icon: <MdLocationPin size={24} />,
      path: '/admin/multilocations',
      showItem: showMlm,
    },
    {
      title: 'IMS',
      icon: <MdAddLink size={24} />,
      path: '/admin/ims',
      showItem: showIms,
    },
    {
      title: 'Organizations',
      icon: <OrganizationIcon />,
      path: '/admin/organizations',
      showItem: showOrganizations,
    },
    {
      title: 'Resellers',
      icon: <ResellerIcon />,
      path: '/admin/resellers',
      showItem: showResellers,
    },
    {
      title:
        userData.superAdminRole === SuperAdminRole.ADMIN
          ? 'Company Profile'
          : 'Customers',
      icon: <ContactsIcon />,
      path:
        userData.superAdminRole === SuperAdminRole.ADMIN
          ? `/admin/customers/${userData.customerId}`
          : '/admin/customers',
      showItem: showCustomers,
    },
    {
      title: 'Users',
      icon: <AccountsIcon />,
      path: '/admin/users',
      showItem: showUsers,
    },
    {
      title: 'Usage Reports',
      icon: <ReportsIcon />,
      path: '/admin/usage-reports',
      showItem: showUsageReports,
    },
    {
      title: 'Integrations',
      icon: <IntegrationsIcon />,
      path: '/admin/integrations',
      showItem: showIntegrations,
    },
  ];

  if (!showIntegrations) {
    sidebarItems = sidebarItems.filter(
      item => !ForbiddenFeaturesForWhitelabels[item.title]
    );
  }

  return (
    <>
      <DocumentHead title='Admin' />
      <PageTemplate
        main={
          <>
            <SidebarNavigation
              items={sidebarItems.filter(item => item.showItem)}
              hasBack={false}
            />
            <Switch>
              <>
                {showMlm && (
                  <Route
                    path='/admin/multilocations'
                    component={MultiLocationsPages}
                  />
                )}
                {showIntegrations && (
                  <Route path='/admin/integrations' component={Integrations} />
                )}
                {showOrganizations && (
                  <Route
                    path='/admin/organizations'
                    component={Organizations}
                  />
                )}
                {showIms && (
                  <Route
                    path='/admin/ims'
                    component={InventoryManagmentSystem}
                  />
                )}
                {showUsageReports && (
                  <Route path='/admin/usage-reports' component={UsageReports} />
                )}
                {showCustomers && (
                  <Route path='/admin/customers' component={Customers} />
                )}
                <Route path='/admin/resellers' component={Resellers} />
                {showUsers && <Route path='/admin/users' component={Users} />}
              </>
            </Switch>
          </>
        }
      />
    </>
  );
};
