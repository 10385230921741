import { DropdownIcon } from 'lib/images/DropdownIcon';
import * as React from 'react';
import {
  Color,
  ColorSelect,
  ColorTitle,
  OptionItems,
  Picker,
} from './ManageDefaultComponents';
import { useOnClickOutside } from 'lib/utils';
import { ColorResult } from 'react-color';

export type IdOption = { title: string; id: number };

type Props = {
  value?: string;
  onChange: (color: ColorResult) => void;
};

export const ColorSelector = (props: Props) => {
  const { value, onChange } = props;
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [rotate, setRotate] = React.useState('down');

  const { ref } = useOnClickOutside(() => setIsPickerOpen(false));

  const handleColorSelect = () => {
    rotate === 'down' ? setRotate('up') : setRotate('down');
    isPickerOpen ? setIsPickerOpen(false) : setIsPickerOpen(true);
  };

  return (
    <OptionItems>
      Player color
      <div ref={ref} className='option-container'>
        <ColorSelect onClick={handleColorSelect} isPickerOpen={isPickerOpen}>
          <Color color={value} />
          <ColorTitle title={value}>
            {value === '' ? 'Do not change' : value}
          </ColorTitle>
          <div
            style={{
              position: 'absolute',
              right: 22,
              transform: `rotate(${isPickerOpen ? 180 : 0}deg)`,
            }}
          >
            <DropdownIcon width={'12px'} height={'12px'} opacity={0.5} />
          </div>
        </ColorSelect>
        <Picker
          isPickerOpen={isPickerOpen}
          width='250'
          color={value}
          onChangeComplete={onChange}
        />
      </div>
    </OptionItems>
  );
};
