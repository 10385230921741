import * as React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { CheckboxInput, Modal, Switch } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { MdArrowBack } from 'react-icons/md';
import dayjs from 'dayjs';
import { Button } from 'react-covideo-common';
import { useVideosForCustomerQuery } from 'lib/api/videoDeletes/useVideosForCustomerQuery';
import { useIsMutating } from 'react-query';
import { Gap } from '../styles/layout';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.gray100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4e5461;
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonsWrapper = styled.div<{ row?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'row-reverse')};
  margin-top: 32px;
`;

const SelectWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 40px;
  box-sizing: border-box;
  border: solid 1px #f2f8ff;
  background-color: #f2f8ff;
  margin: 0 6px;
`;

const DaysSelect = styled.input.attrs({ type: 'number' })`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  background-color: #ffffff;
  border: 1px solid rgba(0, 27, 83, 0.2);
  border-radius: 6px;
  color: black;
  display: flex;
  opacity: ${props => props.disabled && '.6'};
  width: 100%;
  height: 100%;
  text-align: center;
  &::-webkit-inner-spin-button {
    display: none;
  }
  -moz-appearance: textfield;
  &:focus {
    outline: 0;
  }
`;

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CheckboxSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

const SwitchWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #f6f7f9;
  margin: 32px 0px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #272a32;
`;

const CheckBoxRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  margin: 24px 0 4px;
`;

const ReviewWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f7f9;
  border-radius: 6px;
  padding: 8px 16px;
  gap: 8px;
`;
const VideoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
`;
const VideoTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
`;
const VideoDateTime = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
`;

type Props = {
  videoDeletion?: any;
  handleModalClose: () => void;
  handleSubmit: Function;
  days: number;
  setDays: (days: number) => void;
};

export const ModalVideoDeletion = ({
  videoDeletion = {},
  handleModalClose,
  handleSubmit,
  days,
  setDays,
}: Props) => {
  const themes = useTheme();

  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;

  const [notify, setNotify] = useState<boolean>(
    !!videoDeletion.notify ?? false
  );
  const [exclude, setExclude] = useState<boolean>(
    !!videoDeletion.exclude ?? false
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [reviewContent, setReviewContent] = useState(false);

  const { data } = useVideosForCustomerQuery(days);
  const videosLength = data?.count ?? 0;
  const customerVideos = data?.videos ?? [];

  const updateNotify = () => {
    setNotify(!notify);
  };
  const updateExclude = () => {
    setExclude(!exclude);
  };
  const toggleConfirmation = () => {
    setShowConfirm(!showConfirm);
  };
  const toggleReviewContent = () => {
    setReviewContent(!reviewContent);
  };

  const submit = async () => {
    handleSubmit({
      ...videoDeletion,
      days,
      notify,
      exclude,
    });
  };

  const dataContent = (
    <Content>
      Delete videos after
      <SelectWrapper>
        <DaysSelect
          value={days}
          min={1}
          max={100}
          step={1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDays(Number(e.target.value))
          }
        />
      </SelectWrapper>
      days
      <SwitchWrapper>
        <SwitchRow>
          <CheckboxSpan>Show to users when video will be deleted</CheckboxSpan>
          <Switch
            id={'switch-deleted'}
            isOn={notify}
            onColor={themes.colors.primary[100]}
            handleToggle={updateNotify}
          />
        </SwitchRow>
        <SwitchRow>
          <CheckboxSpan>
            Allow users to request to exclude videos from being deleted
          </CheckboxSpan>
          <Switch
            id={'switch-exclude'}
            isOn={exclude}
            onColor={themes.colors.primary[100]}
            handleToggle={updateExclude}
          />
        </SwitchRow>
      </SwitchWrapper>
      <Line />
      <ReviewWrapper>
        <Text>
          {`There ${videosLength === 1 ? 'is' : 'are'} ${videosLength} ${
            videosLength === 1 ? 'video' : 'videos'
          }  that ${videosLength === 1 ? 'is' : 'are'} older than ${
            days + ' day' + (days > 1 || days == 0 ? 's' : '')
          }.`}
        </Text>
        {!!videosLength && (
          <Button
            text={'Review Videos'}
            variant='secondary'
            onClick={toggleReviewContent}
          />
        )}
      </ReviewWrapper>
      <CheckBoxRow>
        <CheckboxInput
          className='checkbox'
          checked={showConfirm}
          onChange={toggleConfirmation}
        />
        <CheckboxSpan>
          {`I understand that ${videosLength}  videos will be deleted if I continue`}
        </CheckboxSpan>
      </CheckBoxRow>
      <Gap justifyContent='flex-end' width='100%' gap='12px' m='16px 0 0 0'>
        <Button
          text={'Cancel'}
          onClick={handleModalClose}
          variant='secondary'
          disabled={isDisabled}
        />
        <Button
          text={`${
            videoDeletion.deleteId ? 'Update' : 'Activate'
          } Video Deletion`}
          onClick={submit}
          variant='primary'
          disabled={!showConfirm || isDisabled}
        />
      </Gap>
    </Content>
  );

  const contentReview = (
    <Content>
      <RowContent>
        {customerVideos &&
          videosLength > 0 &&
          customerVideos?.map((video: any) => (
            <VideoRow key={video.id}>
              <VideoTitle>{video.title}</VideoTitle>
              <VideoDateTime>
                {dayjs(video.recordDate).format('hh:mm YYYY-MM-DD')}
              </VideoDateTime>
            </VideoRow>
          ))}
      </RowContent>

      <ButtonsWrapper row>
        <Button
          variant='secondary'
          text='Go Back'
          icon={<MdArrowBack size={20} />}
          onClick={toggleReviewContent}
        />
      </ButtonsWrapper>
    </Content>
  );

  return (
    <Modal>
      <ModalItem>
        <Header>
          {!reviewContent
            ? 'Delete Older Videos?'
            : 'Review videos that will be deleted'}
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        {reviewContent ? contentReview : dataContent}
      </ModalItem>
    </Modal>
  );
};
