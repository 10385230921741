import React from 'react';
import './VideoMultipleUploadList.css';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import CloseIcon from 'lib/images/CloseIcon';

const toMB = (KB: any) => {
  return (KB / 1000).toFixed(2);
};
const progressState = (status: any) => {
  if (status === 0) {
    return `Waiting...`;
  } else if (status === 1) {
    return `Uploading...`;
  } else {
    return `Encoding...`;
  }
};

const ErrorMessage = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.red80};
  height: 20px;
  margin-top: 24px;
`;

type InputProps = {
  width?: any;
};

interface ITitleArrayValues {
  elementId: number;
  isTitleNotEmpty: boolean;
  title?: string;
}

const Input = styled.input<InputProps>`
  ${theme.fontNormal500}
  font-size: ${theme.fontSizes.md};
  color: black;
  outline: none;
  border: 1px solid ${theme.palette.grayBorder};
  box-sizing: border-box;
  display: block;
  width: ${props => props.width || '82px'};
  padding: 12px 16px;
  height: 40px;
  border-radius: 4px;
  margin: 24px 0 0;
`;

export const MultipleUploadList = (props: any) => {
  const themes = useTheme();
  const uplVideo: any = [];
  let uploadingArr: any = [];
  let uploadingTotal: any = [];
  let uploadActiveVideo: any = [];
  const [titleValidationArray, setTitleValidationArray] = React.useState<
    ITitleArrayValues[]
  >([{ elementId: 0, isTitleNotEmpty: true }]);
  for (let x in props.uploadingData) {
    uplVideo.push(props.uploadingData[x]);
    uploadActiveVideo = uplVideo.filter((ele: any) => {
      return ele.showActiveVideo === 1;
    });
    uploadingArr = uploadActiveVideo.filter((ele: any) => {
      return ele.status === 1;
    });
    uploadingTotal = uploadActiveVideo.reduce(
      (total: any, obj: any) => obj.progressEvent.total + total,
      0
    );
  }

  const deleteVideo = (i: number) => {
    const ud = { ...props.uploadingData };
    delete ud[i];
    props.setUploadingData(ud);
  };

  const editTitle = (i: number, title: string) => {
    let filteredArray = titleValidationArray.filter(el => el.elementId === i);
    filteredArray.forEach(i => {
      const index = titleValidationArray.indexOf(i);
      if (index >= 0) {
        setTitleValidationArray(titleValidationArray.splice(index, 1));
      }
    });
    setTitleValidationArray([
      ...titleValidationArray,
      {
        elementId: i,
        isTitleNotEmpty: !!title.trimStart(),
        title: title.trimStart(),
      },
    ]);

    props.setUploadingData((ud: any) => ({ ...ud, [i]: { ...ud[i], title } }));
  };

  // const uplVideo = Array(_.mapKeys(props.uploadingData , (key , value) => {return value}))
  return (
    <>
      {!!props.progressState && (
        <div className='header'>
          <span>
            <b>
              {uploadingArr.length} of {uploadActiveVideo.length}
            </b>
          </span>
          &nbsp;videos uploading ({toMB(uploadingTotal)} MB)
        </div>
      )}
      <div className='pd30'>
        {uplVideo.map((ele: any) => (
          <div key={`Video-list-${ele.id}`}>
            {' '}
            {ele.showActiveVideo ? (
              <div>
                <Input
                  maxLength={100} // SUS-797 changes
                  style={{ display: 'inline' }}
                  type='text'
                  value={ele.title}
                  readOnly={!!props.progressState}
                  width={ele.status < 1 ? '82%' : '100%'}
                  onChange={e => editTitle(ele.id, e.target.value)}
                />
                {ele.status < 1 && (
                  <Button
                    text=''
                    icon={<CloseIcon width={24} height={24} />}
                    variant='destructive'
                    onClick={() => deleteVideo(ele.id)}
                    style={{ margin: '0 0 0 36px', verticalAlign: 'bottom' }}
                  />
                )}
                {ele.status >= 1 && (
                  <div
                    style={{ width: ele.status < 1 ? '82%' : '100%' }}
                    className='container'
                  >
                    <div
                      key={`Video-list-${ele.id}`}
                      className='skill'
                      style={{
                        width: ele.progressEvent.percentage + '%',
                        backgroundColor: themes.colors.primary[100],
                      }}
                    />
                    <div className='containerDiv'>
                      <div className='progressData'>
                        {progressState(ele.status)}
                      </div>
                      <div className='uploadData'>
                        {toMB(ele.progressEvent.loaded) +
                          ' MB/' +
                          toMB(ele.progressEvent.total) +
                          ' MB'}
                      </div>
                    </div>
                  </div>
                )}
                {titleValidationArray.map((element: any) => {
                  if (element.elementId === ele.id) {
                    if (!element.isTitleNotEmpty) {
                      return (
                        <ErrorMessage>Video Title is Required!</ErrorMessage>
                      );
                    }
                    if (element.title?.includes('.')) {
                      return (
                        <ErrorMessage>
                          Video Title can not contain dot(.)!
                        </ErrorMessage>
                      );
                    }
                  }
                  return <div></div>;
                })}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </>
  );
};
