import { getScript, createScript, updateScript, deleteScript } from 'lib/api';
import {
  SCRIPTS_ACTIONS,
  CreateParams,
  GetScriptParams,
  UpdateScriptParams,
  ScriptItem,
} from '.';

const {
  GET_SCRIPT_START,
  GET_SCRIPT_ERROR,
  GET_SCRIPT_SUCCESS,
  ADD_SCRIPTS_START,
  ADD_SCRIPTS_ERROR,
  ADD_SCRIPTS_SUCCESS,
  EDIT_SCRIPTS_START,
  EDIT_SCRIPTS_ERROR,
  EDIT_SCRIPTS_SUCCESS,
  DELETE_SCRIPTS_START,
  DELETE_SCRIPTS_ERROR,
  DELETE_SCRIPTS_SUCCESS,
} = SCRIPTS_ACTIONS;

export const loadScript = async ({
  scriptId,
  dispatch,
}: GetScriptParams): Promise<void> => {
  dispatch({ type: GET_SCRIPT_START });

  const response = await getScript(scriptId).catch(err => err);
  if (response instanceof Error) {
    dispatch({
      type: GET_SCRIPT_ERROR,
      error: response.message,
    });
    return;
  }
  dispatch({
    type: GET_SCRIPT_SUCCESS,
    payload: {
      script: response,
    },
  });
};

export const addScript = async ({
  data,
  dispatch,
}: CreateParams): Promise<ScriptItem | undefined> => {
  dispatch({ type: ADD_SCRIPTS_START });

  const script: ScriptItem = await createScript({ data }).catch(err => err);

  if (script instanceof Error) {
    dispatch({
      type: ADD_SCRIPTS_ERROR,
      error: script.message,
    });
    return;
  }

  dispatch({
    type: ADD_SCRIPTS_SUCCESS,
    payload: {
      script,
    },
  });

  return script;
};

export const editScript = async (
  params: UpdateScriptParams
): Promise<ScriptItem | undefined> => {
  const { data, dispatch, scriptId } = params;
  dispatch({ type: EDIT_SCRIPTS_START });

  const script = await updateScript({
    scriptId,
    data,
  }).catch(err => err);

  if (script instanceof Error) {
    dispatch({
      type: EDIT_SCRIPTS_ERROR,
      error: script.message,
    });
    return;
  }

  dispatch({
    type: EDIT_SCRIPTS_SUCCESS,
    payload: {
      scriptId,
      script,
    },
  });
  return script;
};

export const removeScript = async ({
  data,
  dispatch,
}: CreateParams): Promise<void> => {
  dispatch({ type: DELETE_SCRIPTS_START });

  const response = await deleteScript(data.scriptId).catch(err => err);
  if (response instanceof Error) {
    dispatch({
      type: DELETE_SCRIPTS_ERROR,
      error: response.message,
    });
    return;
  }
  const payload = {
    scriptId: data.scriptId,
  };
  dispatch({ type: DELETE_SCRIPTS_SUCCESS, payload });
};
