import React, { useEffect, useState } from 'react';
import { theme } from 'lib/style';
import {
  DocumentHead,
  Dropdown,
  LoadingIndicator,
  PageTemplate,
} from 'lib/components';
import SaveIcon from 'lib/images/SaveIcon';
import {
  ButtonsBox,
  ButtonsWrapper,
  Container,
  Content,
  DetailsWrapper,
  ErrorMessageText,
  GuideForm,
  GuidesWrapper,
  Header,
  InputWrapper,
  List,
  TextArea,
  TextInput,
  VideoTitle,
  VideoWrapper,
  ErrorMessage as CustomErrorMessage,
} from './styles';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from 'formik';
import DeleteIcon from 'lib/images/DeleteIcon';
import { Gap } from 'lib/components/styles/layout';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import {
  EditableGuides,
  useEditableGuideCategoriesQuery,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';
import { GuideUploadProps, useCreateGuides } from 'lib/api/guides/createGuides';
import { Button } from 'react-covideo-common';
import { Fragment } from 'react';
import { CustomizedState, IOption } from './constants';
import { VideoData } from 'lib/hooks';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import {
  FieldWrapper,
  LinkItem,
  LinksHeader,
  Input as ExtendedInput,
  Label,
} from 'app/pages/design/callsToAction/InteractiveCTAs';
import LinkIcon from 'lib/images/LinkIcon';
import LinkExternalIcon from 'lib/images/LinkExternalIcon';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { wrapWithHttp } from 'lib/utils/functions';
import { URL_REGEX } from 'lib/utils/regexes';

const regex = new RegExp(URL_REGEX);

const reviewUploadGudesValidationSchema = Yup.object()
  .shape({
    guides: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        guideCategoryId: Yup.number().nullable().required('Required'),
        videoId: Yup.string().required('Required'),
        description: Yup.string()
          .max(1000, 'Description has a maximum limit of 1000 characters.')
          .nullable(),
        links: Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string().required('Display name is required'),
              link: Yup.string()
                .required('URL is required')
                .matches(regex, 'Invalid URL'),
            })
          )
          .notRequired()
          .nullable(true),
      })
    ),
  })
  .required();

export const ReviewUploadedGuides = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const history = useHistory();
  const { mutateAsync: createGuides, isLoading: isCreatingGuides } =
    useCreateGuides();
  const { data, isLoading: isLoadingCategories } =
    useEditableGuideCategoriesQuery();
  const [videoArray, setVideoArray] = useState<VideoData[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownOptions = (data || []).map((category: EditableGuides) => ({
    value: category.guideCategoryId,
    label: category.name,
  }));

  useEffect(() => {
    if (state && state.videos && state.videos.length) {
      const videoIds = state.videos;
      setIsLoading(true);
      Promise.all(
        videoIds.map((id: string) => {
          return getVideo(id);
        })
      )
        .then(results => {
          setVideoArray(results);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsSaved(true);
    }
  }, []);

  //Initial Guide values
  const initialValues: GuideUploadProps[] = videoArray.map(
    (video: VideoData) => ({
      guideCategoryId: state?.category ?? null,
      name: video.title,
      videoId: video.id,
      description: '',
      videoSource: video.videoSource,
      links: [],
      playerBackgroundColor: video.playerBackgroundColor,
      playButtonPosition: video.playButtonPosition,
      playerIconsAndTextColor: video.playerIconsAndTextColor,
    })
  );

  const handleCreateGuides = async (payload: GuideUploadProps[]) => {
    const formattedPayload = payload.map(guide => ({
      ...guide,
      links: guide.links?.map(link => ({
        ...link,
        link: wrapWithHttp(link.link),
      })),
    }));
    await createGuides(formattedPayload);
    setIsSaved(true);
  };

  useEffect(() => {
    if (isSaved) {
      history.push(`/guides/${state.category}`);
    }
  }, [isSaved]);

  const loading = isLoading || isLoadingCategories;

  if (loading) {
    return <LoadingIndicator isLoading={loading} text={'Loading...'} />;
  }

  const ReviewContent = () => (
    <Content>
      <Container>
        <List>
          <div>
            <Formik
              initialValues={{ guides: initialValues }}
              validationSchema={reviewUploadGudesValidationSchema}
              enableReinitialize
              onSubmit={values => handleCreateGuides(values.guides)}
              render={({ values, setFieldValue, errors }) => (
                <Form>
                  <FieldArray
                    name='guides'
                    render={arrayHelpers => (
                      <GuidesWrapper>
                        <ButtonsBox>
                          <ButtonsWrapper>
                            <Button
                              type='submit'
                              icon={
                                <SaveIcon
                                  height={14}
                                  width={14}
                                  color={theme.palette.white}
                                />
                              }
                              text={`Save Video Guide${
                                videoArray.length > 1 ? 's' : ''
                              }`}
                              disabled={
                                isCreatingGuides ||
                                Object.keys(errors).length !== 0
                              }
                            />
                          </ButtonsWrapper>
                        </ButtonsBox>
                        {values.guides &&
                          values.guides.length > 0 &&
                          values.guides.map(
                            (guide: GuideUploadProps, index: number) => {
                              const hasLinks =
                                !!values?.guides[index].links?.length;
                              const links = values?.guides[index].links;
                              const description =
                                values?.guides[index].description;
                              return (
                                <Fragment key={guide.videoId}>
                                  <VideoTitle>{guide.name}</VideoTitle>
                                  <GuideForm>
                                    <Gap
                                      alignItems='self-start'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <InputWrapper>
                                        <Label>Video Guide Title</Label>
                                        <Field
                                          name={`guides.${index}.name`}
                                          as={TextInput}
                                        />
                                        <ErrorMessage
                                          name={`guides.${index}.name`}
                                          component={ErrorMessageText}
                                        />
                                      </InputWrapper>
                                      <Button
                                        onClick={() => {
                                          if (values.guides.length - 1 === 0) {
                                            history.push('/home');
                                            return;
                                          }
                                          arrayHelpers.remove(index);
                                          setVideoArray(prevVideos =>
                                            prevVideos.filter(
                                              video =>
                                                video.id !== guide.videoId
                                            )
                                          );
                                        }}
                                        text={'Remove this video'}
                                        variant='destructive'
                                        icon={
                                          <DeleteIcon
                                            color={theme.palette.buttonDelete}
                                          />
                                        }
                                      />
                                      <DetailsWrapper>
                                        <Header marginBottom='16px'>
                                          Details
                                        </Header>
                                        <Gap>
                                          <InputWrapper>
                                            <Label>Description</Label>
                                            <Field
                                              name={`guides.${index}.description`}
                                              as={TextArea}
                                              maxRowLength={54}
                                              textLength={
                                                description?.length || 0
                                              }
                                            />
                                            <ErrorMessage
                                              name={`guides.${index}.description`}
                                              component={ErrorMessageText}
                                            />
                                          </InputWrapper>
                                          <InputWrapper>
                                            <Label>Category</Label>
                                            <Dropdown
                                              options={dropdownOptions}
                                              value={
                                                dropdownOptions.find(
                                                  item =>
                                                    item.value ===
                                                    values.guides[index]
                                                      .guideCategoryId
                                                ) || null
                                              }
                                              onChange={(item: IOption) => {
                                                setFieldValue(
                                                  `guides.${index}.guideCategoryId`,
                                                  item.value
                                                );
                                              }}
                                              creatable={false}
                                              extendStyles={{
                                                container: {
                                                  width: '100%',
                                                  margin: '0px 0 0px 0',
                                                },
                                              }}
                                              isSearchable={false}
                                              placeholder='Select Category'
                                              menuPortalTarget={document.body}
                                              menuShouldBlockScroll={true}
                                              menuPosition='absolute'
                                              menuPlacement='bottom'
                                            />
                                            <ErrorMessage
                                              name={`guides.${index}.guideCategoryId`}
                                              component={ErrorMessageText}
                                            />
                                          </InputWrapper>
                                          <Gap
                                            flexDirection='column'
                                            alignItems='stretch'
                                            gap='22px'
                                            m='32px 0 0 0'
                                          >
                                            <Header style={{ height: '12px' }}>
                                              Assigned Articles & Links
                                            </Header>
                                            <InputWrapper>
                                              {hasLinks && (
                                                <LinksHeader>
                                                  <Label width='178px'>
                                                    Display Name
                                                  </Label>
                                                  <Label width='198px'>
                                                    URL
                                                  </Label>
                                                  <Label width='84px'>
                                                    Preview
                                                  </Label>
                                                </LinksHeader>
                                              )}
                                              <FieldArray
                                                name={`guides[${index}].links`}
                                                render={arrHelpers => (
                                                  <>
                                                    {links?.map(
                                                      (
                                                        ctaValue,
                                                        idx: number
                                                      ) => (
                                                        <LinkItem
                                                          key={idx}
                                                          height='auto'
                                                        >
                                                          <FieldWrapper
                                                            width={`178px`}
                                                          >
                                                            <Field
                                                              name={`guides[${index}].links[${idx}].label`}
                                                            >
                                                              {({
                                                                field,
                                                                meta,
                                                              }: FieldProps) => (
                                                                <>
                                                                  <ExtendedInput
                                                                    {...field}
                                                                    type='text'
                                                                    placeholder='Display Name'
                                                                    maxLength={
                                                                      35
                                                                    }
                                                                    hasError={
                                                                      meta.touched &&
                                                                      !!meta.error
                                                                    }
                                                                  />
                                                                  {meta.touched &&
                                                                    meta.error && (
                                                                      <CustomErrorMessage>
                                                                        {
                                                                          meta.error
                                                                        }
                                                                      </CustomErrorMessage>
                                                                    )}
                                                                </>
                                                              )}
                                                            </Field>
                                                          </FieldWrapper>
                                                          <FieldWrapper
                                                            width={`186px`}
                                                          >
                                                            <Field
                                                              name={`guides[${index}].links[${idx}].link`}
                                                            >
                                                              {({
                                                                field,
                                                                meta,
                                                              }: FieldProps) => (
                                                                <>
                                                                  <ExtendedInput
                                                                    {...field}
                                                                    type='text'
                                                                    placeholder='URL'
                                                                    maxLength={
                                                                      35
                                                                    }
                                                                    hasError={
                                                                      meta.touched &&
                                                                      !!meta.error
                                                                    }
                                                                  />
                                                                  {meta.touched &&
                                                                    meta.error && (
                                                                      <CustomErrorMessage>
                                                                        {
                                                                          meta.error
                                                                        }
                                                                      </CustomErrorMessage>
                                                                    )}
                                                                </>
                                                              )}
                                                            </Field>
                                                          </FieldWrapper>
                                                          <FieldWrapper width='46px'>
                                                            <Button
                                                              variant='secondary'
                                                              text=''
                                                              icon={
                                                                <LinkExternalIcon
                                                                  width='18px'
                                                                  height='18px'
                                                                />
                                                              }
                                                              onClick={() => {
                                                                window.open(
                                                                  ctaValue.link,
                                                                  '_blank'
                                                                );
                                                              }}
                                                              disabled={
                                                                !ctaValue.link
                                                              }
                                                            />
                                                          </FieldWrapper>

                                                          <FieldWrapper width='46px'>
                                                            <Button
                                                              variant='destructive'
                                                              text=''
                                                              icon={
                                                                <DeleteIcon
                                                                  width='24px'
                                                                  height='24px'
                                                                  color={
                                                                    theme
                                                                      .palette
                                                                      .buttonDelete
                                                                  }
                                                                />
                                                              }
                                                              onClick={(
                                                                e?:
                                                                  | React.SyntheticEvent
                                                                  | React.SyntheticEvent<
                                                                      Element,
                                                                      Event
                                                                    >
                                                                  | React.MouseEvent
                                                              ) => {
                                                                e?.preventDefault();
                                                                arrHelpers.remove(
                                                                  idx
                                                                );
                                                              }}
                                                            />
                                                          </FieldWrapper>
                                                        </LinkItem>
                                                      )
                                                    )}
                                                    <Button
                                                      onClick={() => {
                                                        arrHelpers.push({
                                                          link: '',
                                                          label: '',
                                                        });
                                                      }}
                                                      text={
                                                        links.length === 0
                                                          ? 'New Link'
                                                          : 'Add Links...'
                                                      }
                                                      variant='secondary'
                                                      icon={
                                                        <LinkIcon
                                                          color={
                                                            theme.palette
                                                              .covideoBlue100
                                                          }
                                                          height={'20px'}
                                                          width={'20px'}
                                                        />
                                                      }
                                                      disabled={
                                                        links
                                                          ? links?.length >= 10
                                                          : false
                                                      }
                                                    />
                                                    {links?.length >= 10 && (
                                                      <ParagraphSmallBold
                                                        style={{
                                                          color:
                                                            theme.palette
                                                              .red100,
                                                        }}
                                                      >
                                                        This video guide can’t
                                                        have any more assigned
                                                        articles and links.
                                                      </ParagraphSmallBold>
                                                    )}
                                                  </>
                                                )}
                                              />
                                            </InputWrapper>
                                          </Gap>
                                        </Gap>
                                      </DetailsWrapper>
                                    </Gap>
                                    <VideoWrapper>
                                      <VideoPlayer
                                        playerBackgroundColor={
                                          guide.playerBackgroundColor
                                        }
                                        playButtonPosition={
                                          guide.playButtonPosition
                                        }
                                        playerIconsColor={
                                          guide.playerIconsAndTextColor
                                        }
                                        videoSource={guide.videoSource}
                                        videoRef={React.createRef()}
                                        videoId={guide.videoId.toString()}
                                      />
                                    </VideoWrapper>
                                  </GuideForm>
                                </Fragment>
                              );
                            }
                          )}
                      </GuidesWrapper>
                    )}
                  />
                </Form>
              )}
            />
          </div>
        </List>
      </Container>
    </Content>
  );

  return (
    <>
      <DocumentHead title='Review Guides' />
      <PageTemplate
        main={
          <>
            <RouteLeavingGuard
              when={true}
              stay={true}
              onConfirm={() => {}}
              navigate={path => history.push(path)}
              shouldBlockNavigation={() => {
                return !isSaved;
              }}
              title='Leave without saving details?'
              text='Your changes will not be saved. This action can’t be undone.'
              confirmButtonText='Continue'
              discardButtonText='Leave'
            />
            <ReviewContent />
          </>
        }
      />
    </>
  );
};
