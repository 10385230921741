import * as React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps, ErrorMessage } from 'formik';
import { SelectInput, Spinner } from 'lib/components';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import { keyBy } from 'lodash';
import { useState } from 'react';
import LinkExternalIcon from 'lib/images/LinkExternalIcon';
import { getGraphAuthUrl } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';

interface FormProps {
  isSubmitting?: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
  button {
    margin-left: 102px;
  }
`;

const FieldLabel = styled.label`
  width: 120px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;
interface TextInputProps {
  margin?: string;
  background?: string | null;
}

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TextInput = styled.input.attrs(({ type }) => ({
  type: type || 'text',
}))<TextInputProps>`
  display: block;
  width: 100%;
  max-width: 800px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: ${props => props.background || '#fff'};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin: ${props => props.margin || ''};
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

export const CalendarPlatform = {
  NOT_SELECTED: {
    value: 0,
    label: 'Not selected',
  },
  GOOGLE: {
    value: 1,
    label: 'Google Calendar',
  },
  CALENDLY: {
    value: 2,
    label: 'Calendly',
  },
  OUTLOOK: {
    value: 3,
    label: 'Outlook Calendar',
  },
  WEBSITE: {
    value: 4,
    label: 'Other',
  },
};

const platformOptions = Object.values(keyBy(CalendarPlatform, 'value'));

export const IntegrationsForm = (props: FormProps) => {
  const { isSubmitting } = props;
  const [platform, setPlatform] = useState<number | undefined>();
  const [authenticating, setAuthenticating] = useState(false);
  const authenticateWithOutlook = async () => {
    setAuthenticating(true);
    const res = await getGraphAuthUrl();
    if (res) {
      window.location.href = res;
    } else {
      errorToast({ title: 'There was a problem with authentication. ' });
      setAuthenticating(false);
    }
  };
  return (
    <>
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Calendar Sync</SectionTitle>
        <RowWrapper>
          <Row>
            <FieldLabel>Platform</FieldLabel>
            <Field name='platform'>
              {({ field }: FieldProps) => {
                const { value, ...rest } = field;
                setPlatform(value);
                return (
                  <SelectInput
                    {...rest}
                    defaultValue={value}
                    disabled={isSubmitting}
                  >
                    {platformOptions.map((o, i) => (
                      <option key={i} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </SelectInput>
                );
              }}
            </Field>
          </Row>
          {platform == CalendarPlatform.GOOGLE.value && (
            <Row>
              <FieldLabel>Calendar ID</FieldLabel>
              <Field name='googleCalendarId'>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <TextInput
                        {...field}
                        type='text'
                        placeholder='Calendar ID'
                        required={true}
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name='googleCalendarId'
                        component={ErrorMsg}
                      />
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
          {platform == CalendarPlatform.CALENDLY.value && (
            <>
              <Row>
                <FieldLabel>Calendar Link</FieldLabel>
                <Field name='calendlyCalendarUrl'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type='text'
                          placeholder='Calendar Link'
                          required={true}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name='calendlyCalendarUrl'
                          component={ErrorMsg}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>
              <Row>
                <FieldLabel>Access Token</FieldLabel>
                <Field name='calendlyAccessToken'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type='text'
                          placeholder='Access Token'
                          required={true}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name='calendlyAccessToken'
                          component={ErrorMsg}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>
            </>
          )}
          {platform == CalendarPlatform.OUTLOOK.value && (
            <>
              <Row>
                <Button
                  text='Authenticate with Outlook'
                  onClick={() => authenticateWithOutlook()}
                  variant='primary'
                  disabled={authenticating}
                  icon={
                    authenticating ? (
                      <Spinner />
                    ) : (
                      <LinkExternalIcon
                        width='14px'
                        height='14px'
                        color={theme.palette.white}
                      />
                    )
                  }
                />
              </Row>
              <Row>
                <FieldLabel>Access Token</FieldLabel>
                <Field name='outlookRefreshToken'>
                  {({ field }: FieldProps) => {
                    return (
                      <FieldContainer>
                        <TextInput
                          {...field}
                          type={'text'}
                          placeholder='Access Token'
                          required={true}
                          disabled={true}
                        />
                        <ErrorMessage
                          name='outlookRefreshToken'
                          component={ErrorMsg}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </Row>
            </>
          )}
          {platform == CalendarPlatform.WEBSITE.value && (
            <Row>
              <FieldLabel>Website Link</FieldLabel>
              <Field name='calendarWebsiteUrl'>
                {({ field }: FieldProps) => {
                  return (
                    <FieldContainer>
                      <TextInput
                        {...field}
                        type='text'
                        placeholder='Website Link'
                        required={true}
                        disabled={isSubmitting}
                      />
                      <ErrorMessage
                        name='calendarWebsiteUrl'
                        component={ErrorMsg}
                      />
                    </FieldContainer>
                  );
                }}
              </Field>
            </Row>
          )}
        </RowWrapper>
      </div>
    </>
  );
};
