import * as React from 'react';
import { useAuth } from 'lib/context';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { FlexForm, UserFormProps } from '../../components/AdminFormComponents';
import { Divider } from '../../index.styled';
import { CustomerSection } from './userDetails/CustomerSection';
import { PersonalInfoSection } from './userDetails/PersonalInfoSection';
import { PasswordSection } from './userDetails/PasswordSection';
import { ContactSection } from './userDetails/ContactSection';
import { CovideoSection } from './userDetails/CovideoSection';
import { JobDescriptionSection } from './userDetails/JobDescriptionSection';
import { AddonSection } from './userDetails/AddonSection';
import { DefaultSection } from './userDetails/DefaultSection';
import { getCustomer } from 'lib/api/superAdminApi';

export const UserDetailsForm = (props: UserFormProps) => {
  const { isNewUser, dirty, onFormDirty, setFieldValue, values } = props;
  const [isVerifiedDisabled, setIsVerifiedDisabled] = React.useState(false);
  const [isAutomotive, setIsAutomotive] = React.useState(false);

  const { userData } = useAuth();
  const showCustomerSection =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;
  const showOtheSections =
    userData.superAdminRole !== SuperAdminRole.ADMIN &&
    userData.superAdminRole !== SuperAdminRole.ACCESS;

  // For enabling Save button on value change
  React.useEffect(() => {
    onFormDirty(dirty);
  }, [dirty]);

  const loadCustomer = async () => {
    const customerDetails = await getCustomer(values.customerId);
    if (customerDetails) {
      if (isNewUser) {
        const verified = customerDetails.userCount < customerDetails.maxUsers;
        setIsVerifiedDisabled(!verified);
        setFieldValue(`verified`, verified);
      }
      setIsAutomotive(!!customerDetails.versionCDSEnabled);
    }
  };

  React.useEffect(() => {
    if (values.customerId > 0) {
      loadCustomer();
    }
  }, [values.customerId]);

  return (
    <FlexForm>
      {showCustomerSection && (
        <>
          <CustomerSection {...props} />
          <Divider />
        </>
      )}
      <PersonalInfoSection {...props} />
      <>
        <Divider />
        <PasswordSection {...props} />
      </>
      <Divider />
      <ContactSection {...props} />
      {showOtheSections && (
        <>
          <Divider />
          <CovideoSection
            {...props}
            isAutomotive={isAutomotive}
            isVerifiedDisabled={isVerifiedDisabled}
          />
        </>
      )}
      <Divider />
      <JobDescriptionSection {...props} />

      {isNewUser && (
        <>
          <Divider /> <DefaultSection {...props} />
        </>
      )}
      {showOtheSections && (
        <>
          <Divider />
          {!isNewUser && <AddonSection {...props} />}
        </>
      )}
    </FlexForm>
  );
};
