import * as React from 'react';
import { Formik, ErrorMessage, Field, FieldProps, Form } from 'formik';
import styled, { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import {
  generatePassword,
  validPassword,
  validateInputForCsvFormulaInjection,
} from 'lib/utils/functions';
import { CopyIcon } from 'lib/images/CopyIcon';
import LockIcon from 'lib/images/LockIcon';
import Select from 'react-select';
import * as Yup from 'yup';
import { debounce, uniqBy } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { LoadingIndicator, TextInput, RadioInput } from 'lib/components';
import { useAuth } from 'lib/context';
import {
  getLandingPages,
  updateUser,
  getDepartments,
  createDepartment,
  checkUsername,
  getWebsiteOverlays,
} from 'lib/api';
import { SendPasswordModal } from './SendPasswordModal';
import { SubscriptionDetailModal } from './SubscriptionDetailModal';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import {
  AutomotiveRole,
  automotiveRoleOptions,
} from 'lib/const/AutomotiveRole';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import { successToast } from 'lib/components/toasts/success';
import { creatableSelectStyle } from 'lib/style/commonStyles';
import {
  messages,
  PASSWORD_FORMAT_ERROR,
} from 'app/pages/account/userAccount/editMyProfile/components';
import { Option } from 'lib/components/dropdown/DropdownOptions';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import {
  getTooltip,
  PasswordGuide,
} from 'app/pages/admin/components/PasswordTooltip';
import { useGetLinkSetsQuery } from 'lib/api/exitLinkSets/getExitLinksSets';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { getManagedUsers } from 'lib/api/users/useManagedUsersQuery';

interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
}

const FlexForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const CircleAdd = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 1.5;
  background: ${props => props.theme.colors.primary[100]};
  svg {
    padding-left: 2px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RowInner = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 0 0.5rem 50px;
  .nested__innerrow {
    padding: 0.5rem 0 0.5rem 5px;
    .margin--zero {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldLabel = styled.label`
  max-width: 170px;
  width: 100%;
  margin-right: 10px;
`;

const FieldLabelInner = styled.label`
  max-width: 170px;
  width: 100%;
  margin-right: 10px;
  color: #9297a2;
`;

const FieldContainer = styled.section`
  display: flex;
  width: 55%;
  flex-direction: column;
  position: relative;
  .user__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4e5461;
  }
  .error {
    color: red;
    font-size: 14px;
    padding: 0.1rem;
  }
`;

const EyeField = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const Red = styled.label`
  color: ${props => props.theme.colors.primary[100]};
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #eeeff2;
`;

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.width || '100%'};
  height: 64px;
  box-sizing: border-box;
  label {
    min-width: 250px;
  }
`;

const Processing = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2109094a;
  top: 0;
  right: 0;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 690px;
`;

const InputSwitchWrapper = styled.div`
  width: 640px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SwitchWrapper = styled.div`
  display: block;
  input {
    position: absolute;
  }
`;

const SwitchLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const fieldsSchema = Yup.object({
  phone1: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .test('isNotSuspicious', 'Phone is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  cellPhone: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(
      7,
      'Please enter a cell phone number equal or longer than 7 characters.'
    )
    .test(
      'isNotSuspicious',
      'Cell Phone number is not valid',
      function (value = '') {
        if (!value) {
          return true;
        }

        return validateInputForCsvFormulaInjection(value);
      }
    ),
  email: Yup.string()
    .email('Email is not valid')
    .required('Email is required')
    .test('isNotSuspicious', 'Email is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  username: Yup.string()
    .trim()
    .required('Username is required')
    .min(2, 'Username is too small')
    .test('isNotSuspicious', 'Username is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  lastName: Yup.string().when(['resellerId'], (resellerId: number) => {
    if (resellerId === NAPLETON_AUTOMOTIVE_RESELLER_ID) {
      return Yup.string()
        .nullable()
        .transform((v, o) => (o === '' ? undefined : v))
        .min(2, 'Last name is too small')
        .max(100, 'Last name cannot be more than 100 characters.')
        .test(
          'isNotSuspicious',
          'Last name is not valid',
          function (value = '') {
            if (!value) {
              return true;
            }
            return validateInputForCsvFormulaInjection(value);
          }
        );
    }
    return Yup.string()
      .required('Last name is required')
      .min(2, 'Last name is too small')
      .max(100, 'Last name cannot be more than 100 characters.')
      .test('isNotSuspicious', 'Last name is not valid', function (value = '') {
        if (!value) {
          return true;
        }
        return validateInputForCsvFormulaInjection(value);
      });
  }),
  firstName: Yup.string()
    .required('First name is required')
    .max(100, 'First name cannot be more than 100 characters.')
    .test('isNotSuspicious', 'First name is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  phone2: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Phone no. is not valid')
    .test('isNotSuspicious', 'Phone is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
});

let fields = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  email: '',
  phone1: '',
  cellPhone: '',
  phone2: '',
  title: '',
  Dept: '',
  captions: 'disabled',
  template: '',
  ctaSet: '',
  access: 1,
  manualPassword: true,
  automotiveRole: null,
  vdpEnabled: '',
  overlayUrl: '',
  resellerId: 0,
};

type UserUpdateProps = {
  setAction?: React.Dispatch<React.SetStateAction<any>>;
  fetchedUserData?: any;
  setUpdateVerifyFun?: React.Dispatch<React.SetStateAction<any>>;
  setVerifiedStatus?: React.Dispatch<React.SetStateAction<any>>;
  subscription?: any;
  setUsernameValid?: any;
  isUsernameValid?: Boolean;
  setAutomotiveRole?: React.Dispatch<React.SetStateAction<string | null>>;
  automotiveRole?: string | null;
};

const loadFields = (data: any) => {
  return {
    firstName: data.firstName ? data.firstName : '',
    lastName: data.lastName ? data.lastName : '',
    username: data.username ? data.username : '',
    password: '',
    email: data.email ? data.email : '',
    phone1: data.phone1 ? data.phone1 : '',
    phone2: data.phone2 ? data.phone2 : '',
    cellPhone: data.cellPhone ? data.cellPhone : '',
    title: data.title ? data.title : '',
    Dept: data.Dept ? data.Dept : '',
    template: data.designId ? data.designId : '',
    ctaSet: data.ctaSetId ? data.ctaSetId : '',
    access: data.access ? data.access : 1,
    manualPassword: true,
    verified: data.verified ? data.verified : 0,
    automotiveRole: data.automotiveRole ? data.automotiveRole : '',
    vdpEnabled: data.vdpEnabled ? data.vdpEnabled : '',
    nmls: data.nmls ? data.nmls : '',
    overlayUrl: data.overlayUrl || '',
    resellerId: data.resellerId || 0,
  };
};

export const UserEditor = (props: UserUpdateProps) => {
  const [isEditView, setIsEditView] = React.useState<boolean>(
    !!Object.keys(props.fetchedUserData).length
  );
  const [optionalError, setOptionalError] = React.useState<any>({});
  const [verifiedVal, setVerifiedVal] = React.useState<any>(false);
  const [user, setUser] = React.useState<any>(fields);
  const [userCopy, setUserCopy] = React.useState<any>(fields);
  const [templateCount, setTemplateCount] = React.useState<any>(0);
  const [templateInitialLoaded, setTemplateInitialLoaded] =
    React.useState<any>(false);
  const [search, setSearch] = React.useState<any>('');
  const [templateLoading, setTemplateLoading] = React.useState<any>(false);
  const [start, setStart] = React.useState<any>(0);
  const [startCopy, setStartCopy] = React.useState<any>(0);
  const [loading, setLoading] = React.useState<any>(true);
  const [deptOption, setDeptOption] = React.useState([]);
  const [inputValue, onInputChange] = React.useState('');
  const [showEmailSendModal, setShowEmailSendModal] =
    React.useState<any>(false);
  const [templateOptions, setTemplateOptions] = React.useState<any>([]);
  const [passwordView, setPasswordView] = React.useState<any>('password');
  const [processing, setProcessing] = React.useState<any>(false);
  const [loaded, setLoaded] = React.useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [defaultCta, setDefaultCta] = React.useState<string | undefined>('');
  const [defaultOverlayUrl, setDefaultOverlayUrl] = React.useState<
    number | undefined
  >(0);
  const [defaultTemplate, setDefaultTemplate] = React.useState<string | number>(
    ''
  );
  const [ctaOptions, setCtaOptions] = React.useState<Option[]>([]);
  const [overlaysOptions, setOverlaysOptions] = React.useState<Option[]>([]);
  const usernameRef = React.useRef<HTMLInputElement | null>(null);
  const {
    setAction,
    fetchedUserData,
    setUpdateVerifyFun,
    setVerifiedStatus,
    subscription,
    setUsernameValid,
    isUsernameValid,
  } = props;

  const accessType = [
    { value: 1, label: 'Standard' },
    { value: 3, label: 'Admin' },
    { value: 4, label: 'Supervisor' },
  ];
  const limit = 100;

  const handleDebounceFn = async () => {
    const trimmedUserName = usernameRef?.current?.value.trim();
    if (trimmedUserName !== '') {
      // Added (id) argument for SUS-521
      const userValid = await checkUsername(
        trimmedUserName ? trimmedUserName : '',
        fetchedUserData.id ? fetchedUserData.id : ''
      );
      setUsernameValid(!userValid.userExist);
    } else {
      setUsernameValid(true);
    }
  };
  const debounceFn = React.useMemo(() => debounce(handleDebounceFn, 500), []);

  const { userData, invalidateUser } = useAuth();
  const { customerId, access, isAutomotive } = userData;
  const showVdpSwitch = userData.isVdpEnabled;

  const { showError } = useToastError();

  const history = useHistory();

  const { data: linksetsData } = useGetLinkSetsQuery({ limit: 1000 });
  const themes = useTheme();

  const [passwordErrorHide, setPasswordErrorHide] =
    React.useState(true); /* SUS-1181 changes */

  const prepareDataToSend = async (value: any) => {
    return new Promise(async (resolve, reject) => {
      const payload: any = {};
      const error: any = { message: '' };
      payload.firstName = value.firstName;
      payload.lastName = value.lastName;
      payload.email = value.email;
      payload.phone1 = value.phone1;
      payload.cellPhone = value.cellPhone;
      payload.access = value.access;
      payload.automotiveRole = value.automotiveRole;
      payload.vdpEnabled = value.vdpEnabled;
      payload.active = verifiedVal ? 1 : 0;
      payload.jobTitle = value.title;
      payload.username = value.username.trim();
      payload.nmls = value.nmls;

      if (value.Dept) {
        payload.departmentIds = [value.Dept];
      }
      if (value.phone2) {
        payload.phone2 = value.phone2;
        if (value.phone2.length < 7) {
          error['phone2'] = 'Phone no. is not valid';
          error['message'] += 'Phone no. is not valid';
        }
      }
      if (value.password && isEditView) {
        payload.newPassword = value.password;
      }

      if (
        isEditView &&
        !!value.password.length &&
        (value.password.length < 8 || !validPassword(value.password))
      ) {
        error['password'] =
          value.password.length < 8
            ? Array.isArray(messages.password)
              ? messages.password[0]
              : ''
            : PASSWORD_FORMAT_ERROR;
        error['message'] +=
          (error['message'].length ? ', ' : '') +
          Array.isArray(messages.password)
            ? messages?.password[0]
            : '';
      }
      /******/
      if (!isEditView) {
        if (
          value.manualPassword &&
          (value.password?.length < 8 || !validPassword(value.password))
        ) {
          error['password'] =
            value.password.length < 8
              ? Array.isArray(messages.password)
                ? messages.password[0]
                : ''
              : PASSWORD_FORMAT_ERROR;
          error['message'] +=
            (error['message'].length ? ', ' : '') + messages.password[0];
        }
        if (userData.isAutomotive && value.automotiveRole === null) {
          error['automotiveRole'] = 'Please select a suitable automotive role.';
          error['message'] +=
            (error['message'].length ? ', ' : '') +
            'Please select a suitable automotive role.';
        }
        const response = await getManagedUsers({
          customerId: userData.customer.customerId,
        });
        payload.active = 1;
        if (response.totalActiveUsers >= response.totalAllowedUsers) {
          payload.active = 0;
        }
        payload.transcriptionAccess =
          value.transcriptionAccess == 'enabled' ? true : false;
        payload.jobTitle = value.title;
        payload.password = value.manualPassword ? value.password : undefined;
        payload.ctaSetId = value.ctaSet ? value.ctaSet : undefined;
        payload.designId = value.template ? value.template : undefined;
        payload.isResetPassword = !value.manualPassword ? true : undefined;
        payload.overlayUrlId = value.overlayUrl || undefined;
      }
      if (Object.keys(error).length > 1 && error.message != '')
        return reject(error);
      return resolve(payload);
    });
  };

  const saveChanges = async (forceSave = false) => {
    fieldsSchema
      .validate(user)
      .then(async function (value) {
        value.lastName = value.lastName || '';
        const mainData: any = value;
        if (
          !forceSave &&
          subscription &&
          Object.keys(subscription).length &&
          subscription.totalActiveUsers >= subscription.totalAllowedUsers
        ) {
          if (
            (isEditView && // if edit view AND
              ((!userCopy.verified && verifiedVal && mainData.access != 4) || // with edit-view initially user is not verfied after that user verified with button toggle and user type is not supervisor
                (verifiedVal &&
                  userCopy.access == 4 &&
                  mainData.access != 4))) || // or with edit view user is updated to verified or already verified and initial user type is supervisor and now changed to admin/standard
            (!isEditView && // if new user add view
              mainData.access != 4) // and user type is not supervisor
          ) {
            setShowSubscriptionModal(true);
            return;
          }
        }
        setProcessing(true);
        localStorage.setItem('showTabChangePopup', '0');
        const payload = await prepareDataToSend(value);
        setOptionalError({});
        await updateUser(
          fetchedUserData.id,
          payload,
          isEditView,
          CHANGE_ORIGIN.manageUsers
        );
        await refreshUser();
        setProcessing(false);
        successToast({ title: `Data saved successfully` });
        history.push('/users/list');
      })
      .catch(function (err) {
        setProcessing(false);
        showError(
          err ||
            'An error occurred saving your information, please try again later.'
        );
        setOptionalError(err);
      });
  };

  const refreshUser = async () => {
    // UPDATE USER
    await invalidateUser();
  };

  const searchTemplate = debounce((e: any) => {
    setSearch(e);
    e ? setStart(0) : setStart(startCopy);
  }, 600);

  const fetchTemplates = async () => {
    setTemplateLoading(true);
    const templates = await getLandingPages({
      start: start * limit,
      limit,
      search,
      showUserCustomLandingPages: false,
    });
    let options: any = JSON.parse(JSON.stringify(templateOptions));
    templates.templates.forEach((e: any) => {
      options = [...options, { label: e.title, value: e.id }];
    });
    if (templates.default && templates.default.id) {
      options = [
        ...options,
        { label: templates.default.title, value: templates.default.id },
      ];
      if (!templateInitialLoaded) {
        setDefaultTemplate(templates.default.id);
      }
    }

    options = uniqBy(options, 'value');
    setTemplateOptions(options);
    setTemplateCount(templates.count);
    setTemplateLoading(false);
    setTemplateInitialLoaded(true);
  };

  const allotDept = async (obj: any) => {
    if (obj.__isNew__) {
      setProcessing(true);
      try {
        const respDeptCreate = await createDepartment({ name: obj.value });
        setUser({ ...user, Dept: respDeptCreate.data.id });
        const newArray: any = deptOption;
        newArray.push([
          { label: respDeptCreate.data.name, value: respDeptCreate.data.id },
        ]);
        setDeptOption(newArray);
        setProcessing(false);
      } catch (error) {
        showError(error);
        setProcessing(false);
      }
      setProcessing(false);
    } else {
      setUserCopy({ ...userCopy, Dept: obj.value });
      setUser({ ...user, Dept: obj.value });
    }
  };

  const copyPassword = (password: any) => {
    const cb = navigator.clipboard;
    cb.writeText(password || '').then(() => {
      successToast({ title: 'Password copied successfully!' });
    });
  };

  React.useEffect(() => {
    if (!isEditView) {
      const companyLinkSets = linksetsData?.linksets.filter(
        cta => cta.company !== 0
      );
      const defaultCta = companyLinkSets?.find(cta => cta.defaultLinks === 1);
      if (defaultCta && !user.ctaSet) {
        setDefaultCta(`${defaultCta?.id}`);
      }
      setCtaOptions(
        companyLinkSets?.map(cta => {
          return { value: cta.id, label: cta.title };
        }) || []
      );
    }
  }, [linksetsData, user]);

  const loadWebsiteOverlays = async () => {
    const overlays = await getWebsiteOverlays({});
    const defaultOverlay = overlays.websiteUrls?.find(
      overlay => overlay.defaultflag === 1
    );
    if (defaultOverlay && !user.overlayUrl) {
      setDefaultOverlayUrl(defaultOverlay?.id);
    }

    setOverlaysOptions(
      overlays.websiteUrls?.map(overlay => {
        return { value: overlay.id, label: overlay.title };
      })
    );
  };

  React.useEffect(() => {
    localStorage.setItem('showTabChangePopup', '0');
    if (setAction) setAction({ save: saveChanges });
    if (setUpdateVerifyFun) setUpdateVerifyFun({ update: setVerifiedVal });
    if (JSON.stringify(user) != JSON.stringify(userCopy)) {
      localStorage.setItem('showTabChangePopup', '1');
    }
  }, [user, verifiedVal]);
  React.useEffect(() => {
    fetchTemplates();
  }, [start, search]);

  React.useEffect(() => {
    const handleFetchedUser = () => {
      if (!!Object.keys(fetchedUserData).length) {
        setUserCopy({ ...fields, ...loadFields(fetchedUserData) });
        setUser({ ...fields, ...loadFields(fetchedUserData) });
        setIsEditView(true);
        if (setVerifiedStatus) {
          setVerifiedStatus(fetchedUserData.verified ? true : false);
          setVerifiedVal(fetchedUserData.verified ? true : false);
        }
      }
      setLoading(false);
    };
    handleFetchedUser();
  }, [fetchedUserData]);

  React.useEffect(() => {
    const deptCall = async () => {
      const dept = await getDepartments(customerId, {});
      if (dept.data && dept.data.length) {
        const mapArr: any = [];
        dept.data.forEach((e: any) => {
          mapArr.push({ label: e.Name, value: e.DepartmentID });
        });
        setDeptOption(mapArr);
      }
      setLoaded(true);
    };
    deptCall();
    if (!isEditView) {
      loadWebsiteOverlays();
    }
  }, []);

  const setDefaults = () => {
    setUser({
      ...user,
      template: defaultTemplate,
      ctaSet: defaultCta,
      overlayUrl: defaultOverlayUrl,
    });
    setUserCopy({
      ...userCopy,
      template: defaultTemplate,
      ctaSet: defaultCta,
      overlayUrl: defaultOverlayUrl,
    });
  };

  React.useEffect(() => {
    if (!isEditView && (defaultCta || defaultOverlayUrl || defaultTemplate)) {
      setDefaults();
    }
  }, [defaultCta, defaultOverlayUrl, defaultTemplate]);

  const onChangeAutomotiveRole = (role: string) => {
    if (!role) {
      setUser({
        ...user,
        automotiveRole: role,
      });
      return;
    }
    const setAdmin =
      role === AutomotiveRole.SERVICE_MANAGER ||
      role === AutomotiveRole.SALES_MANAGER;
    setUser({
      ...user,
      automotiveRole: role,
      access: setAdmin ? 3 : 1,
    });
  };

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='500px' />;
  }

  return (
    <>
      {loaded ? (
        <Formik
          enableReinitialize
          initialValues={user}
          validationSchema={fieldsSchema}
          onSubmit={() => {}}
        >
          {({ isSubmitting, values }) => (
            <FlexForm>
              {/* First Name */}
              <Row>
                <FieldLabel>Personal info:</FieldLabel>
                <RowInner>
                  <Row>
                    <FieldLabelInner>
                      First Name:<Red>*</Red>
                    </FieldLabelInner>
                    <Field name='firstName'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='First Name'
                              required={true}
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            <ErrorMessage
                              name='firstName'
                              component={ErrorMsg}
                            />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  <Row>
                    <FieldLabelInner>
                      Last Name:{' '}
                      {values.resellerId !==
                        NAPLETON_AUTOMOTIVE_RESELLER_ID && <Red>*</Red>}
                    </FieldLabelInner>
                    <Field name='lastName'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Last Name'
                              required={true}
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            <ErrorMessage
                              name='lastName'
                              component={ErrorMsg}
                            />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  <Row>
                    <FieldLabelInner>
                      Email:<Red>*</Red>
                    </FieldLabelInner>
                    <Field name='email'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Email'
                              autoComplete='off'
                              required={true}
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                debounceFn();
                                setUser({ ...values });
                                if (!isEditView) {
                                  setUser({
                                    ...values,
                                    username: values.email,
                                  });
                                }
                              }}
                            />
                            <ErrorMessage name='email' component={ErrorMsg} />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  <Row>
                    <FieldLabelInner>
                      Username:<Red>*</Red>
                    </FieldLabelInner>
                    <Field name='username'>
                      {({ field }: FieldProps) => {
                        const trimmedUserName = user?.username?.trim();
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Username'
                              autoComplete='off'
                              required={true}
                              autoFocus={false}
                              disabled={access !== '3' && access !== '4'}
                              ref={usernameRef}
                              background={isEditView ? '#F6F7F9' : null}
                              onKeyUp={() => {
                                debounceFn();
                                setUser({ ...values });
                              }}
                            />
                            {isUsernameValid &&
                              trimmedUserName.length === 0 && (
                                <p className='user__desc'>
                                  Username should only contain letters, numbers,
                                  and dots without spaces, only @-_+. special
                                  characters can be used.
                                </p> // Updated message as per SUS-784 changes
                              )}
                            <ErrorMessage
                              name='username'
                              component={ErrorMsg}
                            />
                            {!isUsernameValid && trimmedUserName.length > 0 && (
                              <ErrorMsg>Username is already taken.</ErrorMsg>
                            )}
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                </RowInner>
              </Row>
              <Divider />
              <Row>
                <FieldLabel>
                  {isEditView ? 'Create New Password' : 'Password:'}
                </FieldLabel>
                {isEditView && (
                  <RowInner>
                    <Row>
                      <FieldLabelInner>
                        New password:
                        {getTooltip()}
                      </FieldLabelInner>
                      <Field name='password'>
                        {({ field }: FieldProps) => {
                          return (
                            <FieldContainer style={{ maxWidth: '800px' }}>
                              <TextInput
                                {...field}
                                type={passwordView}
                                placeholder='Password'
                                autoComplete='new-password'
                                required={true}
                                autoFocus={false}
                                disabled={isSubmitting}
                                onKeyUp={() => {
                                  setUser({ ...values });
                                  /* SUS-1229 changes */
                                  setPasswordErrorHide(true);
                                  if (
                                    values.password &&
                                    !validPassword(values.password)
                                  ) {
                                    setPasswordErrorHide(false);
                                  }
                                  /******/
                                }}
                              />
                              {/* SUS-1229 changes */}
                              {optionalError.password &&
                                optionalError.password.length &&
                                !passwordErrorHide && (
                                  <p className='error'>
                                    {optionalError.password !==
                                    PASSWORD_FORMAT_ERROR ? (
                                      optionalError.password
                                    ) : (
                                      <PasswordGuide
                                        color={theme.palette.red100}
                                      />
                                    )}
                                  </p>
                                )}
                              <p className='user__desc'>
                                Leave blank if you don't want to change the
                                password.
                              </p>
                              <EyeField
                                onClick={() => {
                                  setPasswordView(
                                    passwordView == 'password'
                                      ? 'text'
                                      : 'password'
                                  );
                                }}
                              >
                                {passwordView == 'password' ? (
                                  <IoMdEyeOff />
                                ) : (
                                  <IoMdEye />
                                )}
                              </EyeField>
                              <ErrorMessage
                                name='password'
                                component={ErrorMsg}
                              />
                            </FieldContainer>
                          );
                        }}
                      </Field>
                    </Row>
                  </RowInner>
                )}
                {!isEditView && (
                  <>
                    <RowInner style={{ marginLeft: '-5px' }}>
                      <RadioButtonsWrapper
                        width={'90%'}
                        justifyContent={'flex-start'}
                      >
                        <RadioInput
                          name={'password'}
                          value={'#FFFFFF'}
                          text={'Enter manually'}
                          checkedColor={themes.colors.primary[100] || '#FF8B22'}
                          checked={user.manualPassword}
                          onChange={() => {}}
                          onClick={() => {
                            if (!user.manualPassword)
                              setUser({
                                ...user,
                                manualPassword: true,
                                password: '',
                              });
                          }}
                        />
                        <RadioInput
                          name={'password'}
                          value={'#000000'}
                          text={'Let user set a password'}
                          checkedColor={
                            themes.colors.primary[100] || '#FF8B22'
                          } /* SUS-878 changes */
                          checked={!user.manualPassword}
                          onChange={() => {}}
                          onClick={() => {
                            if (user.manualPassword)
                              setUser({
                                ...user,
                                manualPassword: false,
                                password: generatePassword(10),
                              });
                          }}
                        />
                      </RadioButtonsWrapper>
                      <RowInner className='nested__innerrow'>
                        {user.manualPassword && (
                          <Row>
                            <FieldLabelInner>
                              Password:<Red>*</Red>
                              {getTooltip()}
                            </FieldLabelInner>
                            <Field name='password'>
                              {({ field }: FieldProps) => {
                                return (
                                  <FieldContainer>
                                    <Gap
                                      gap='10px'
                                      flexDirection='row'
                                      flexWrap='nowrap'
                                    >
                                      <PasswordContainer>
                                        <TextInput
                                          {...field}
                                          type={passwordView}
                                          placeholder='Password'
                                          autoComplete='new-password'
                                          required={true}
                                          autoFocus={false}
                                          disabled={isSubmitting}
                                          onKeyUp={() => {
                                            setUser({ ...values });
                                            /* SUS-1181 changes */
                                            setPasswordErrorHide(true);
                                            if (
                                              values.password &&
                                              !validPassword(values.password)
                                            ) {
                                              setPasswordErrorHide(false);
                                            }
                                            /******/
                                          }}
                                        />
                                        {/* SUS-1181 changes */}
                                        {optionalError.password &&
                                          optionalError.password.length &&
                                          !passwordErrorHide && (
                                            <p className='error'>
                                              {optionalError.password !==
                                              PASSWORD_FORMAT_ERROR ? (
                                                optionalError.password
                                              ) : (
                                                <PasswordGuide
                                                  color={theme.palette.red100}
                                                />
                                              )}
                                            </p>
                                          )}
                                        <EyeField
                                          onClick={() => {
                                            setPasswordView(
                                              passwordView == 'password'
                                                ? 'text'
                                                : 'password'
                                            );
                                          }}
                                        >
                                          {passwordView == 'password' ? (
                                            <IoMdEyeOff />
                                          ) : (
                                            <IoMdEye />
                                          )}
                                        </EyeField>
                                      </PasswordContainer>
                                      <Button
                                        variant='secondary'
                                        text='Copy'
                                        icon={
                                          <CopyIcon
                                            color={theme.palette.covideoBlue100}
                                            width='18px'
                                            height='18px'
                                          />
                                        }
                                        onClick={() => {
                                          copyPassword(user.password);
                                        }}
                                      />
                                    </Gap>
                                    <ErrorMessage
                                      name='password'
                                      component={ErrorMsg}
                                    />
                                  </FieldContainer>
                                );
                              }}
                            </Field>
                          </Row>
                        )}
                        {!user.manualPassword && (
                          <Row>
                            <Field name='password'>
                              {() => {
                                return (
                                  <FieldContainer>
                                    <div style={{ display: 'flex' }}>
                                      <Button
                                        variant='secondary'
                                        text='Password will be set by a user'
                                        icon={
                                          <LockIcon
                                            color={theme.palette.covideoBlue100}
                                          />
                                        }
                                        onClick={() => {}}
                                        disabled={true}
                                      />
                                    </div>
                                  </FieldContainer>
                                );
                              }}
                            </Field>
                          </Row>
                        )}
                      </RowInner>
                    </RowInner>
                  </>
                )}
              </Row>
              <Divider />
              <Row>
                <FieldLabel>Contact numbers:</FieldLabel>
                <RowInner>
                  <Row>
                    <FieldLabelInner>Phone 1:</FieldLabelInner>
                    <Field name='phone1'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Phone number'
                              required={true}
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            <ErrorMessage name='phone1' component={ErrorMsg} />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  <Row>
                    <FieldLabelInner>Phone 2:</FieldLabelInner>
                    <Field name='phone2'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Phone number'
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            {optionalError.phone2 &&
                              optionalError.phone2.length && (
                                <p className='error'>{optionalError.phone2}</p>
                              )}
                            {/* <ErrorMessage name="phone2" component={ErrorMsg} /> */}
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  <Row>
                    <FieldLabelInner>Cell Phone:</FieldLabelInner>
                    <Field name='cellPhone'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Cell Phone'
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            <ErrorMessage
                              name='cellPhone'
                              component={ErrorMsg}
                            />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                </RowInner>
              </Row>
              <Divider />
              <Row>
                <FieldLabel>Job position:</FieldLabel>
                <RowInner>
                  <Row>
                    <FieldLabelInner>Title:</FieldLabelInner>
                    <Field name='title'>
                      {({ field }: FieldProps) => {
                        return (
                          <FieldContainer>
                            <TextInput
                              {...field}
                              type='text'
                              placeholder='Title'
                              required={true}
                              autoFocus={false}
                              disabled={isSubmitting}
                              onKeyUp={() => {
                                setUser({ ...values });
                              }}
                            />
                            <ErrorMessage name='title' component={ErrorMsg} />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  {/* {!isEditView && ( */}
                  <Row>
                    <FieldLabelInner>Department:</FieldLabelInner>
                    <Field name='Dept'>
                      {() => {
                        return (
                          <FieldContainer>
                            <CreatableSelect
                              inputValue={inputValue}
                              onInputChange={onInputChange}
                              components={{
                                IndicatorSeparator: () => {
                                  return null;
                                },
                              }}
                              formatCreateLabel={inputValue => (
                                <div
                                  style={{
                                    color: themes.colors.primary[100],
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <CircleAdd>
                                    <MdAdd color={theme.palette.white} />
                                  </CircleAdd>{' '}
                                  &nbsp;Create {`"${inputValue}"`}
                                </div>
                              )}
                              styles={creatableSelectStyle}
                              placeholder={'Add department...'}
                              value={deptOption.find((o: any) => {
                                return o.value == user.Dept;
                              })}
                              onChange={(e: any) => {
                                allotDept(e);
                              }}
                              options={deptOption}
                              noOptionsMessage={() => null}
                              isMulti={false}
                              clearable={false}
                              optionHeight={40}
                            />
                            <ErrorMessage name='Dept' component={ErrorMsg} />
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  {/* NMLS */}
                  {!isAutomotive && (
                    <Row>
                      <FieldLabelInner>NMLS:</FieldLabelInner>
                      <Field name='nmls'>
                        {({ field }: FieldProps) => {
                          return (
                            <FieldContainer>
                              <TextInput
                                {...field}
                                type='text'
                                disabled={isSubmitting}
                                onKeyUp={() => {
                                  setUser({ ...values });
                                }}
                              />
                              <ErrorMessage name='nmls' component={ErrorMsg} />
                            </FieldContainer>
                          );
                        }}
                      </Field>
                    </Row>
                  )}
                  {/* )} */}
                </RowInner>
              </Row>
              <Divider />
              {!isEditView && (
                <>
                  <Row>
                    <FieldLabel>Set defaults:</FieldLabel>
                    <RowInner>
                      <Row>
                        <FieldLabelInner>Landing Page:</FieldLabelInner>
                        <FieldContainer>
                          <Select
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: '40px',
                                maxWidth: '800px',
                              }),
                              indicatorSeparator: () => ({ display: 'none' }),
                              menuPortal: (base: any) => ({
                                ...base,
                                zIndex: 5,
                              }),
                            }}
                            options={templateOptions}
                            menuPortalTarget={document.body}
                            menuPlacement={'bottom'}
                            value={templateOptions.find((o: any) => {
                              return o.value == user.template;
                            })}
                            onChange={(option: any) => {
                              setUser({ ...user, template: option.value });
                              setTemplateLoading(false);
                            }}
                            onMenuScrollToBottom={() => {
                              if (templateOptions.length < templateCount)
                                setStart(start + 1);
                              if (!search) setStartCopy(start + 1);
                            }}
                            onInputChange={searchTemplate}
                            isLoading={templateLoading}
                            onMenuClose={() => setTemplateLoading(false)}
                          />
                        </FieldContainer>
                      </Row>
                      <Row>
                        <FieldLabelInner>CTA set:</FieldLabelInner>
                        <FieldContainer>
                          <Select
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: '40px',
                                maxWidth: '800px',
                              }),
                              indicatorSeparator: () => ({ display: 'none' }),
                            }}
                            options={ctaOptions}
                            menuPortalTarget={document.body}
                            menuPlacement={'top'}
                            value={ctaOptions?.find(o => {
                              return o.value === user.ctaSet;
                            })}
                            onChange={(option: any) => {
                              setUser({ ...user, ctaSet: option.value });
                            }}
                          />
                        </FieldContainer>
                      </Row>
                      <Row>
                        <FieldLabelInner>Website Overlay:</FieldLabelInner>
                        <FieldContainer>
                          <Select
                            styles={{
                              control: (base: any) => ({
                                ...base,
                                height: '40px',
                                maxWidth: '800px',
                              }),
                              indicatorSeparator: () => ({ display: 'none' }),
                            }}
                            options={overlaysOptions}
                            menuPortalTarget={document.body}
                            menuPlacement={'top'}
                            value={overlaysOptions?.find(o => {
                              return o.value === user.overlayUrl;
                            })}
                            onChange={(option: any) => {
                              setUser({ ...user, overlayUrl: option.value });
                            }}
                          />
                        </FieldContainer>
                      </Row>
                    </RowInner>
                  </Row>
                  <Divider />
                </>
              )}
              <Row>
                <FieldLabel>Access level:</FieldLabel>
                <RowInner>
                  <Row>
                    <FieldLabelInner>User type:</FieldLabelInner>
                    <Field type='select'>
                      {() => {
                        const { access } = user;
                        return (
                          <FieldContainer>
                            <Select
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  height: '40px',
                                  maxWidth: '800px',
                                }),
                                indicatorSeparator: () => ({ display: 'none' }),
                              }}
                              options={accessType}
                              menuPortalTarget={document.body}
                              menuPlacement={'top'}
                              value={accessType.find((o: any) => {
                                return o.value == access;
                              })}
                              onChange={(option: any) => {
                                setUser({ ...user, access: option.value });
                              }}
                            />
                            {subscription &&
                            Object.keys(subscription).length &&
                            subscription.totalActiveUsers >=
                              subscription.totalAllowedUsers &&
                            ((isEditView &&
                              ((!userCopy.verified &&
                                verifiedVal &&
                                values.access != 4) ||
                                (verifiedVal &&
                                  userCopy.access == 4 &&
                                  values.access != 4))) ||
                              (!isEditView && values.access != 4)) ? (
                              <p className='error'>
                                New user with this access level will increase
                                number of seats in your subscription.
                              </p>
                            ) : null}
                          </FieldContainer>
                        );
                      }}
                    </Field>
                  </Row>
                  {userData.isAutomotive && (
                    <Row>
                      <FieldLabelInner>
                        Automotive role:<Red>*</Red>
                      </FieldLabelInner>
                      <Field type='select'>
                        {() => {
                          const { automotiveRole } = user;
                          return (
                            <FieldContainer>
                              <Select
                                placeholder={'Select...'}
                                styles={{
                                  control: (base: any) => ({
                                    ...base,
                                    height: '40px',
                                    maxWidth: '800px',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                }}
                                options={automotiveRoleOptions}
                                menuPortalTarget={document.body}
                                menuPlacement={'top'}
                                value={automotiveRoleOptions.find((o: any) => {
                                  return o.value == automotiveRole;
                                })}
                                onChange={(option: any) => {
                                  if (props.setAutomotiveRole) {
                                    props.setAutomotiveRole(option.value);
                                  }
                                  onChangeAutomotiveRole(option.value);
                                }}
                              />
                              {(optionalError.automotiveRole &&
                                optionalError.automotiveRole.length) ||
                                (user.automotiveRole === null && (
                                  <p className='error'>
                                    {
                                      'Please select a suitable automotive role.'
                                    }
                                  </p>
                                ))}
                            </FieldContainer>
                          );
                        }}
                      </Field>
                    </Row>
                  )}
                  {showVdpSwitch && (
                    <Row>
                      <Field type='select'>
                        {() => {
                          const { vdpEnabled } = user;
                          return (
                            <FieldContainer>
                              <InputSwitchWrapper>
                                <SwitchLabel>
                                  User can push videos to a VDP
                                </SwitchLabel>
                                <SwitchWrapper>
                                  <Switch
                                    id={'switch-vdp'}
                                    isOn={vdpEnabled}
                                    onColor={themes.colors.primary[100]}
                                    handleToggle={() =>
                                      setUser({
                                        ...user,
                                        vdpEnabled: !user.vdpEnabled,
                                      })
                                    }
                                  />
                                </SwitchWrapper>
                              </InputSwitchWrapper>
                            </FieldContainer>
                          );
                        }}
                      </Field>
                    </Row>
                  )}
                </RowInner>
              </Row>
            </FlexForm>
          )}
        </Formik>
      ) : null}
      {processing && (
        <Processing>
          <LoadingIndicator
            isLoading={true}
            height='500px'
            zIndex={2}
            text='Updating'
          />
        </Processing>
      )}
      {showEmailSendModal && (
        <SendPasswordModal
          handleModalClose={() => {
            setShowEmailSendModal(false);
          }}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionDetailModal
          subscription={subscription}
          handleModalClose={force => {
            setShowSubscriptionModal(false);
            if (force) saveChanges(true);
          }}
        />
      )}
    </>
  );
};
