import html2canvas from 'html2canvas';
import videoImg from 'lib/images/no-video-shape.svg';

const generateImageURIFromHTML = async element => {
  const canvas = await html2canvas(element, {
    allowTaint: true,
    logging: false,
    useCORS: true,
    scale: 0.5,
    onclone: cloneDoc => {
      // Handle setting the VideoPlayer background color
      const figureElements = cloneDoc.getElementsByTagName('figure');
      if (figureElements && figureElements.length > 0) {
        for (const figure of figureElements) {
          figure.style.backgroundColor = 'black';
        }
      }

      // Handle preparing video player controls color
      const videoPlayerTimeDisplayElements = cloneDoc.querySelectorAll(
        '[ class*="TimeDisplay" ]'
      );
      let videoPlayerControlsColor;
      if (
        videoPlayerTimeDisplayElements &&
        videoPlayerTimeDisplayElements.length > 0
      ) {
        videoPlayerControlsColor = window
          .getComputedStyle(videoPlayerTimeDisplayElements[0])
          .getPropertyValue('color');
      } else {
        videoPlayerControlsColor = 'white';
      }

      // Handle setting the VideoPlayer progress element background color
      const videoPlayerProgressElements = cloneDoc.getElementsByTagName(
        'progress'
      );
      if (
        videoPlayerProgressElements &&
        videoPlayerProgressElements.length > 0
      ) {
        for (const videoPlayerProgress of videoPlayerProgressElements) {
          videoPlayerProgress.style.height = `${videoPlayerProgress.clientHeight}px`;
          videoPlayerProgress.style.backgroundColor = videoPlayerControlsColor;
          videoPlayerProgress.style.opacity = '0.25';
        }
      }

      // Handle setting the VideoPlayer volume element background color
      const videoPlayerVolumeRangeElements = cloneDoc.querySelectorAll(
        '[ class*="VolRange" ]'
      );
      if (
        videoPlayerVolumeRangeElements &&
        videoPlayerVolumeRangeElements.length > 0
      ) {
        for (const videoPlayerVolumeRange of videoPlayerVolumeRangeElements) {
          videoPlayerVolumeRange.style.backgroundColor = videoPlayerControlsColor;
        }
      }

      // Handle removing large logo image from Custom LP because of CORS error
      const logoImageElements = cloneDoc.getElementsByName('logoImage');
      if (logoImageElements && logoImageElements.length > 0) {
        for (const logoImage of logoImageElements) {
          logoImage.remove();
        }
      }

      // Handle removing small logo image elemet in Video Player from Custom LP because of CORS error
      const smallLogoElement = cloneDoc.querySelector('[aria-label="Logo"]');
      if (smallLogoElement) {
        smallLogoElement.remove();
      }

      // Handle setting the Primary Button Text element position to relative
      const primaryButtonTextElements = cloneDoc.querySelectorAll(
        '[ class*="PrimaryButton__Text" ]'
      );
      if (primaryButtonTextElements && primaryButtonTextElements.length > 0) {
        for (const primaryButtonText of primaryButtonTextElements) {
          primaryButtonText.style.position = 'relative';
        }
      }

      // Handle setting the Embedded Video background image
      const embeddedVideoElements = cloneDoc.querySelectorAll(
        '[ id*="slideId" ]'
      );
      if (embeddedVideoElements && embeddedVideoElements.length > 0) {
        for (const embeddedVideo of embeddedVideoElements) {
          const iframeElements = embeddedVideo.getElementsByTagName('iframe');
          if (iframeElements && iframeElements.length > 0) {
            for (const iframe of iframeElements) {
              iframe.style.backgroundImage = `url(${videoImg})`;
              iframe.style.backgroundSize = '100%';
              iframe.style.backgroundRepeat = 'no-repeat';
              iframe.style.backgroundPosition = 'center';
            }
          }
        }
      }

      return cloneDoc;
    },
  });
  const image = canvas.toDataURL('image/png', 1.0);
  return image;
};

const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (
    dataURI
      .toString()
      .split(',')[0]
      .indexOf('base64') >= 0
  )
    byteString = atob(dataURI.toString().split(',')[1]);
  else byteString = unescape(dataURI.toString().split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI
    .toString()
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const generateImageFromHTML = async (element, imageFileName) => {
  const imageURI = await generateImageURIFromHTML(element);
  const blob = dataURItoBlob(imageURI);
  const file = new File([blob], `${imageFileName}.png`, { type: blob.type });
  return file;
};
