import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { MdImage } from 'react-icons/md';
import { TextInput } from 'lib/components';
import { useLandingPageBuilderContext } from '../../context';
import {
  getSignedURLs,
  GetSignedUrlsParams,
  GetSignedUrlsResponse,
} from 'lib/api';
import { ICombinedUserData, useAuth } from 'lib/context';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { BinIcon } from '../Constants';
import Select from 'react-select';
import Switch from '../Switch';
import { errorToast } from 'lib/components/toasts/error';

const MAX_IMAGE_WIDTH = 436;
const MAX_IMAGE_HEIGHT = 200;
const MAX_IMAGE_WIDTH_PERCENTAGE = 100;
const MAX_IMAGE_HEIGHT_PERCENTAGE = 100;

const widthPercentageOptions = [
  {
    value: '10%',
    label: '10%',
  },
  {
    value: '20%',
    label: '20%',
  },
  {
    value: '30%',
    label: '30%',
  },
  {
    value: '40%',
    label: '40%',
  },
  {
    value: '50%',
    label: '50%',
  },
  {
    value: '60%',
    label: '60%',
  },
  {
    value: '70%',
    label: '70%',
  },
  {
    value: '80%',
    label: '80%',
  },
  {
    value: '90%',
    label: '90%',
  },
  {
    value: '100%',
    label: '100%',
  },
];
interface Props {
  width?: string;
  setShowBackButton: any;
}

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  .adjustWidth {
    width: 90%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .alignRight {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const FileInputHandler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  word-break: break-all;
  height: fit-content;
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

const Thumbnail = styled.img`
  max-width: 80%;
  height: auto;
  border-radius: 5px;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
  // margin: 18px auto 0 auto;
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

const calculateImageDimensions = (
  width: number,
  height: number,
  ratio = 0,
  useImagePercentage: boolean = false
) => {
  if (!ratio) {
    ratio = width / height;
  }

  const maxAllowedImageWidth =
    useImagePercentage === true ? MAX_IMAGE_WIDTH_PERCENTAGE : MAX_IMAGE_WIDTH;
  const maxAllowedImageHeight =
    useImagePercentage === true
      ? MAX_IMAGE_HEIGHT_PERCENTAGE
      : MAX_IMAGE_HEIGHT;

  if (width > height) {
    width = width > maxAllowedImageWidth ? maxAllowedImageWidth : width;
    height =
      Math.floor(width / ratio) > maxAllowedImageHeight
        ? maxAllowedImageHeight
        : Math.floor(width / ratio);
  } else {
    height = height > maxAllowedImageHeight ? maxAllowedImageHeight : height;
    width =
      Math.floor(height * ratio) > maxAllowedImageWidth
        ? maxAllowedImageWidth
        : Math.floor(height * ratio);
  }

  return {
    width,
    height,
    ratio,
  };
};

export const EditLogoSection = (props: Props) => {
  const [_, setImageSource] = React.useState('');
  const [imageFile, setImageFile] = React.useState<File>();
  const [__, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    elementToEdit,
    setElementToEdit,
    elementSectionKey,
    elementIndex,
    undoState,
  } = useLandingPageBuilderContext();
  const data = { ...selectedLayoutData };
  const themes = useTheme();

  const [useImagePercentage, setUseImagePercentage] = React.useState(
    data[elementSectionKey].childSections[elementIndex]?.useImagePercentage ||
      false
  );
  const onUploadProgress = handleUploadProgress(setUploadProgress);
  const { userData } = useAuth();

  const currentLogoDimensions = getCurrentLogoDimensions();
  const [logoWidth, setLogoWidth] = React.useState(
    currentLogoDimensions ? currentLogoDimensions.width : MAX_IMAGE_WIDTH
  );
  const [logoHeight, setLogoHeight] = React.useState(
    currentLogoDimensions ? currentLogoDimensions.height : MAX_IMAGE_HEIGHT
  );
  const [logoAspectRatio, setLogoAspectRation] = React.useState(
    currentLogoDimensions ? currentLogoDimensions.ratio : logoWidth / logoHeight
  );

  const [altText, setAltText] = React.useState(
    data[elementSectionKey].childSections[elementIndex]?.altText || ''
  );
  const generateName = handleNameGeneration(userData);

  const { setShowBackButton } = props;
  function getCurrentLogoDimensions() {
    const getElement = data[elementSectionKey].childSections[elementIndex];
    const currentWidth = parseInt(getElement.logoWidth, 10);
    const currentHeight = parseInt(getElement.logoHeight, 10);
    const currentAspectRatio =
      getElement.logoAspectRatio || currentWidth / currentHeight;
    let computedDimenions = calculateImageDimensions(
      currentWidth,
      currentHeight,
      currentAspectRatio,
      useImagePercentage
    );

    return computedDimenions;
  }

  const calculatePercentageOnChange = (widthPercentage: string) => {
    const { width, height, ratio } = calculateImageDimensions(
      parseInt(widthPercentage, 10),
      0,
      logoAspectRatio,
      useImagePercentage
    );

    return { width, height, ratio };
  };

  React.useEffect(() => {
    setUseImagePercentage(
      data[elementSectionKey].childSections[elementIndex]?.useImagePercentage ||
        false
    );
  }, [undoState]);

  React.useEffect(() => {
    const newData = { ...selectedLayoutData };
    const data = elementToEdit;
    if (data) {
      let width = logoWidth;
      let height = logoHeight;
      if (!useImagePercentage) {
        data.logoWidth = width + 'px';
        data.logoHeight = height + 'px';
      } else {
        data.logoWidth = width + '%';
        data.logoHeight = height + '%';
      }

      data.logoAspectRatio = logoAspectRatio;

      if (isElementCase) {
        newData[elementSectionKey].childSections[elementIndex] = data;
      } else {
        newData[elementSectionKey] = data;
      }
    }

    setSelectedLayoutData(newData);
    setElementToEdit(data);
  }, [logoWidth, logoHeight]);

  let fileRef = React.createRef<HTMLInputElement>();
  const layoutData = { ...selectedLayoutData };

  if (!elementSectionKey || !layoutData) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  const isVisible = isElementCase
    ? data[elementSectionKey].childSections[elementIndex]?.isVisible
    : data[elementSectionKey]?.isVisible;

  if (!isVisible) {
    return <></>;
  }

  let logoUrl = sectionDetails.logoUrl;

  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    if (target && target.files && target.files[0]) {
      let orgFile = target.files[0];
      const isValidType = /.*\.(jpeg|jpg|png|bmp|gif|webp|svg)/i.test(
        orgFile.name
      );
      if (isValidType) {
        let filename = orgFile.name.split('.');
        setImageSource(filename[0]);
        let newFile = new File(
          [orgFile],
          generateName(filename[filename.length - 1]),
          { type: orgFile.type, lastModified: orgFile.lastModified }
        );
        setImageFile(newFile);
        setImageSource(URL.createObjectURL(newFile));

        const reader = new FileReader();

        reader.onload = function (file: any) {
          const image = new Image();
          image.src = file.target.result;

          image.onload = function () {
            // @ts-ignore
            let imageWidth = image.width;
            // @ts-ignore
            let imageHeight = image.height;
            let { width, height, ratio } = calculateImageDimensions(
              imageWidth,
              imageHeight,
              0,
              useImagePercentage
            );

            if (useImagePercentage === true) {
              width = 40;
              let newDimensions = calculateImageDimensions(
                width,
                0,
                ratio,
                useImagePercentage
              );

              height = newDimensions.height;
            }

            setLogoWidth(width);
            setLogoHeight(height);
            setLogoAspectRation(ratio);
            sectionDetails.logoAspectRatio = ratio;
          };
        };

        reader.readAsDataURL(newFile);
        handleUpload(newFile);
      } else {
        console.log('Invalid format.');
      }
    } else {
      console.log('Invalid file.');
    }
  };
  const handleUpload = async (newFile: File) => {
    setShowBackButton(false);
    const signedUrlData: GetSignedUrlsParams = {
      fileName: newFile.name,
      mimeType: newFile.type,
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      errorToast({ title: 'Server is currently unavailable, try again!' });
      setShowBackButton(true);
      return;
    }
    const data = {
      file: newFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };
    uploadEmailThumbnail({ data, onUploadProgress })
      .then(() => {
        logoUrl = signedURLs.downloadUrl;
        sectionDetails.logoUrl = logoUrl;
        if (isElementCase) {
          layoutData[elementSectionKey].childSections[elementIndex] =
            sectionDetails;
        } else {
          layoutData[elementSectionKey] = sectionDetails;
        }
        setTimeout(() => {
          setElementToEdit(sectionDetails);
          setSelectedLayoutData(layoutData);
          setShowBackButton(true);
        }, 50);
      })
      .catch(() => {
        setShowBackButton(true);
      });
  };

  const removeLogo = () => {
    logoUrl = '';
    sectionDetails.logoUrl = logoUrl;
    setImageFile(undefined);
    if (isElementCase) {
      layoutData[elementSectionKey].childSections[elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }

    setLogoHeight(0);
    setLogoWidth(0);
    setLogoAspectRation(0);
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  const handleUseImagePercentageToggle = () => {
    const newValue = !useImagePercentage;
    sectionDetails.useImagePercentage = newValue;
    if (newValue === true) {
      sectionDetails.widthPercentage = 40;
      sectionDetails.logoWidth = '40%';
      setLogoWidth(40);
    } else {
      const logo = document.querySelector(
        '[name="logoImage"]'
      ) as HTMLImageElement;
      const currentWidth = logo.width;
      const currentHeight = logo.height;
      let computedDimenions = calculateImageDimensions(
        currentWidth,
        currentHeight,
        logoAspectRatio,
        newValue
      );

      if (logo) {
        setLogoWidth(computedDimenions.width);
        setLogoHeight(computedDimenions.height);
        setLogoAspectRation(computedDimenions.ratio);
      }
    }
    if (isElementCase) {
      layoutData[elementSectionKey].childSections[elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }

    setUseImagePercentage(newValue);
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  const handleAltTextChange = (e: any) => {
    if (!e || !e.target) {
      return;
    }

    const text = e.target.value;
    setAltText(text);
    const details = { ...sectionDetails };
    details.altText = text;
    if (isElementCase) {
      layoutData[elementSectionKey].childSections[elementIndex] = details;
    } else {
      layoutData[elementSectionKey] = details;
    }

    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  return (
    <>
      <Row>
        <input
          type={'file'}
          ref={fileRef}
          style={{ display: 'none' }}
          name={'filePicker2'}
          onChange={handleSelectedFile}
          accept={
            'image/png, image/jpeg, image/bmp, image/gif, image/webp, image/svg+xml'
          }
        />

        {(!logoUrl || logoUrl == '') && (
          <FileInputHandler
            className={logoUrl ? 'adjustWidth' : ''}
            onClick={() => fileRef && fileRef.current?.click()}
          >
            {logoUrl || (imageFile ? imageFile.name : 'Upload image...')}
            <MdImage size={24} color={'#9297A2'} />
          </FileInputHandler>
        )}

        {logoUrl && (
          <>
            <Thumbnail src={logoUrl} />
            <BinIcon onClick={removeLogo} className={'alignRight'} />
          </>
        )}
      </Row>

      <Row alignItems={'center'} padding={'0px 10px 0px 10px'}>
        <TopLabel>Alt Text</TopLabel>
        <TextInput
          id={'logoAltText'}
          margin={'5px 0px 0px 0px'}
          type={'text'}
          placeholder={'Alt Text'}
          value={altText}
          onChange={e => {
            handleAltTextChange(e);
          }}
        />
      </Row>

      <Row alignItems={'center'} padding={'20px 10px 0px 10px'}>
        <Switch
          id={'switch-189'}
          isOn={useImagePercentage}
          onColor={themes.colors.primary[100]}
          handleToggle={handleUseImagePercentageToggle}
        />
        <span
          onClick={handleUseImagePercentageToggle}
          style={{ marginLeft: '12px', cursor: 'pointer' }}
        >
          Use width percentage
        </span>
      </Row>

      {useImagePercentage === false ? (
        <>
          <Row alignItems={'center'} padding={'0px 10px 0px 10px'}>
            <TopLabel>Width</TopLabel>
            <TextInput
              id={'logoWidthInput'}
              margin={'5px 0px 0px 0px'}
              type={'text'}
              placeholder={'Logo Width'}
              value={logoWidth || 0}
              onChange={e => {
                const dimensions = calculateImageDimensions(
                  parseInt(e.target.value, 10),
                  0,
                  logoAspectRatio,
                  useImagePercentage
                );
                setLogoWidth(dimensions.width);
                setLogoHeight(dimensions.height);
              }}
            />
          </Row>
          <Row alignItems={'center'} padding={'0px 10px 0px 10px'}>
            <TopLabel>Height</TopLabel>
            <TextInput
              id={''}
              margin={'5px 0px 0px 0px'}
              type={'text'}
              placeholder={'Logo Height'}
              value={logoHeight || 0}
              onChange={e => {
                const dimensions = calculateImageDimensions(
                  0,
                  parseInt(e.target.value, 10),
                  logoAspectRatio,
                  useImagePercentage
                );
                setLogoWidth(dimensions.width);
                setLogoHeight(dimensions.height);
              }}
            />
          </Row>
        </>
      ) : (
        <>
          <Row>
            <TopLabel>Image width percentage</TopLabel>
            <SelectInput
              styles={{
                control: (base: any) => ({ ...base, height: '40px' }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
              options={widthPercentageOptions}
              menuPlacement={'bottom'}
              value={widthPercentageOptions.find(o => {
                return o.value == logoWidth + '%';
              })}
              onChange={(option: any) => {
                const dimensions = calculatePercentageOnChange(option.value);
                setLogoWidth(dimensions.width);
                setLogoHeight(dimensions.height);
              }}
            />
          </Row>
        </>
      )}
    </>
  );
};

function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
