import { getSmsOverview, getSmsUsers } from 'lib/api';
import { SMS_USER_ACTIONS } from './smsReducer';
import { GlobalAction } from '.';

const {
  GET_SMS_USERS_START,
  GET_SMS_USERS_ERROR,
  GET_SMS_USERS_SUCCESS,
  GET_SMS_OVERVIEW_START,
  GET_SMS_OVERVIEW_ERROR,
  GET_SMS_OVERVIEW_SUCCESS,
} = SMS_USER_ACTIONS;

type LoadUsersParams = {
  params: any;
  dispatch: React.Dispatch<GlobalAction>;
};

type LoadOverviewParams = {
  params: any;
  dispatch: React.Dispatch<GlobalAction>;
};

export const loadSmsOverview = async ({
  params,
  dispatch,
}: LoadOverviewParams): Promise<void> => {
  dispatch({ type: GET_SMS_OVERVIEW_START });
  const response = await getSmsOverview(params).catch((err: any) => err);
  if (response instanceof Error) {
    dispatch({
      type: GET_SMS_OVERVIEW_ERROR,
      error: response.message,
    });
    return;
  }

  dispatch({
    type: GET_SMS_OVERVIEW_SUCCESS,
    payload: {
      sent: response.sent || 0,
      received: response.received || 0,
      mostActiveUsers: response.mostActiveUsers,
      chatCount: response.chatCount,
      unansweredChats: response.unansweredChats,
      graph: response.graph,
    },
  });
};

export const loadSmsUsers = async ({
  params,
  dispatch,
}: LoadUsersParams): Promise<void> => {
  dispatch({ type: GET_SMS_USERS_START });
  const response = await getSmsUsers(params).catch((err: any) => err);
  if (response instanceof Error) {
    dispatch({
      type: GET_SMS_USERS_ERROR,
      error: response.message,
    });
    return;
  }

  dispatch({
    type: GET_SMS_USERS_SUCCESS,
    payload: {
      users: { items: response.users || [] },
      count: parseInt(response.count, 10),
    },
  });
};
