import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { FilesTabContent } from './FilesTabContent';

const Container = styled.div`
  width: 592px;
  height: 648px;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  borde-rradius: 4px;
  padding: 25px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  width: 275px;
  height: 24px;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
`;

const CloseButtonWrap = styled.div`
  margin-left: 20px;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

type Props = {
  handleModalClose: () => void;
  onSelect: any;
  selectedVirtualBackgroundUrl: string;
};

export const VirtualBackgroundLibraryModal = ({
  handleModalClose,
  selectedVirtualBackgroundUrl,
  onSelect,
}: Props) => {
  return (
    <Modal
      style={{
        position: 'fixed',
      }}
    >
      <Container
        style={{
          width: '592px',
          height: '648px',
          background: '#FFFFFF',
          border: '1px solid #F7F8FA',
          boxShadow: '0px 4px 12px rgba(29, 30, 36, 0.04)',
          borderRadius: '4px',
        }}
      >
        <Header>
          <Title>Select your virtual background</Title>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <br />
        <FilesTabContent
          onSelect={onSelect}
          handleModalClose={handleModalClose}
          selectedVirtualBackgroundUrl={selectedVirtualBackgroundUrl}
        />
      </Container>
    </Modal>
  );
};
