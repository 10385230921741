import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { theme } from 'lib/style';
import { useGetReport } from 'lib/hooks';
import { LoadingIndicator } from 'lib/components';
import { reportData } from 'lib/const';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import { TooltipChart } from './TooltipChart';
import { useAuth } from 'lib/context';
import { VIDEO_ATTRIBUTES } from 'lib/utils/videoAttributes';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { reportFieldType } from 'lib/const/ReportData';

const Container = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  @media print {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #001b53;
  margin: 8px 0 16px 0;
`;

const SeeMore = styled(NavLink)`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #001b53cc;
  text-decoration: underline;
  @media print {
    display: none;
  }
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 200px;
  & .recharts-wrapper {
    @media print {
      width: calc(100%) !important;
    }
  }
  & .recharts-surface {
    @media print {
      width: calc(100%) !important;
    }
  }
`;

const queryDateFormat = STANDARD_DATE_FORMAT;

type Props = {
  reportType: string;
  dateRange: string;
  from: Date;
  to: Date;
  users?: string[];
  customer?: string;
  reportId?: string;
};

export const AreaChart = (props: Props) => {
  const { reportType, from, to, dateRange, users, customer, reportId } = props;
  const [retryCount, setRetryCount] = useState(0);
  const isVideoAttributes = reportType.includes(VIDEO_ATTRIBUTES);

  let report = isVideoAttributes
    ? reportData[VIDEO_ATTRIBUTES]
    : reportData[reportType];

  const allowDecimals = !isVideoAttributes ? !!report?.allowDecimals : false;

  const { data, error, loading, label } = useGetReport({
    reportType: isVideoAttributes ? VIDEO_ATTRIBUTES : reportType,
    from,
    to,
    dateRange,
    users: !reportId ? users : [],
    customer,
    retryCount,
    allowDecimals,
    reportId,
  });

  const showLoading = error || loading;

  const location = useLocation();
  const { userData } = useAuth();

  const queryParams: any = queryString.parse(location.search);

  queryParams.start = dayjs(from).format(queryDateFormat);
  queryParams.end = dayjs(to).format(queryDateFormat);
  queryParams.range = dateRange;
  queryParams.customer = customer;

  let url = `/reports/overview/${
    isVideoAttributes ? VIDEO_ATTRIBUTES : reportType
  }`;
  // custom reports can contain more than 1000 users, send report id to load users from custom report
  if (reportId) {
    queryParams.reportId = reportId;
  } else {
    queryParams.users = users;
  }
  if (isVideoAttributes) {
    queryParams.user = userData.userId;
  }

  // fix for chart showing only dot when stats have only one data, for TODAY
  if (data && data.stats && data.stats.length === 1) {
    data.stats.push(data.stats[0]);
  }

  if (!report) {
    report = reportData.views;
  }

  const isFieldPercentage = report.isPercentage || false;
  let value;
  if (isFieldPercentage) {
    //@ts-ignore
    value = data[report.field] || data[reportFieldType.AVERAGE] || 0;
  } else {
    //@ts-ignore
    value = data[report.field] || 0;
  }

  return (
    <Container>
      {showLoading && (
        <LoadingIndicator
          isLoading={loading}
          height='300px'
          error={error}
          onRetry={() => setRetryCount(retryCount + 1)}
          zIndex={0}
        />
      )}
      {!showLoading && (
        <>
          <Header>
            <div>
              <Title>{label}</Title>
              <Value>
                {`${addThousandCommaSeparator(value, isFieldPercentage)}${
                  isFieldPercentage ? ' %' : ''
                }`}
              </Value>
            </div>
            <SeeMore to={`${url}?${queryString.stringify(queryParams)}`}>
              See more
            </SeeMore>
          </Header>
          <ChartContainer>
            <ResponsiveContainer>
              <ComposedChart data={data.stats}>
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop
                      offset='45%'
                      stopColor='rgba(30, 164, 95, 0.8)'
                      stopOpacity={0.5}
                    />
                    <stop
                      offset='95%'
                      stopColor='rgba(30, 164, 95, 0.3)'
                      stopOpacity={0.5}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid stroke={theme.palette.gray} />
                <XAxis dataKey='date' />
                <YAxis
                  allowDecimals={allowDecimals}
                  tickFormatter={(tick: number) =>
                    addThousandCommaSeparator(tick)
                  }
                />
                <Tooltip content={TooltipChart} />
                <Line
                  type='linear'
                  strokeWidth={2}
                  dataKey='cnt'
                  stroke='rgba(30, 164, 95, 1)'
                  legendType='none'
                />
                <Area
                  type='linear'
                  dataKey='cnt'
                  stroke={''}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill='url(#colorUv)'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>
        </>
      )}
    </Container>
  );
};
