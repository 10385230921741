import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';

import ShowLessIcon from 'lib/images/ShowLessIcon';
import ShowMoreIcon from 'lib/images/ShowMoreIcon';

import { DepartmentsCheckboxDropdown } from 'app/pages/reports/components';
import { useAuth } from 'lib/context';
import { UsersList } from './UsersList';
import { ResellerCustomer } from 'lib/api/superadmin/useCustomersResellersQuery';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { Gap, Stack } from 'lib/components/styles/layout';

const CustomerItemTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
`;

const MyCompanyLabel = styled.div`
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #eeeff2;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #4e5461;
`;

const CHECKBOX_COLOR = 'rgba(0, 27, 83, 0.6)';

interface IProps {
  customer: ResellerCustomer;
  setSelectedCustomersHandler: (customer: ResellerCustomer) => void;
  setSelectedUsersHandler: (userId: string, customerId: string) => void;
  customerState: {
    [key: string]: string[];
  };
}

export const Customer = ({
  customer,
  setSelectedCustomersHandler,
  setSelectedUsersHandler,
  customerState,
}: IProps) => {
  const { userData } = useAuth();
  const [showUserList, setShowUserList] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<string[]>([]);
  const selectedCustomerLength = customerState?.[customer?.customerId]?.length;
  const usersLength = customer?.users?.length;

  const filteredUsers = customer.users.filter(user =>
    selectedDepartment.includes(user.departmentId.toString())
  );

  const departments = customer.departments.map(dept => ({
    value: `${dept.id}`,
    label: dept.name,
  }));

  return (
    <div key={customer.customerId}>
      <Gap
        justifyContent='space-between'
        style={{ padding: '16px 16px 16px 0' }}
      >
        <CustomerItemTitle
          onClick={() => {
            setShowUserList(prevState => !prevState);
          }}
        >
          {showUserList ? (
            <ShowLessIcon color={CHECKBOX_COLOR} />
          ) : (
            <ShowMoreIcon color={CHECKBOX_COLOR} />
          )}
          <CheckboxInput
            checked={selectedCustomerLength === usersLength}
            checkGroupIndicator={
              selectedCustomerLength > 0 &&
              selectedCustomerLength !== usersLength
            }
            onChange={() => setSelectedCustomersHandler(customer)}
          />
          <span style={{ marginLeft: '8px' }}>{customer.business}</span>

          <ParagraphSmall color='#9297a2'>
            {customerState?.[customer?.customerId]?.length} /{' '}
            {customer.users.length}
          </ParagraphSmall>

          {userData.customerId === `${customer.customerId}` && (
            <MyCompanyLabel>My Company</MyCompanyLabel>
          )}
        </CustomerItemTitle>

        {!!departments.length && (
          <DepartmentsCheckboxDropdown
            departments={departments}
            menuPortalTarget={null}
            menuShouldBlockScroll={false}
            onDepartmentsSelected={departments => {
              setSelectedDepartment(departments);
            }}
          />
        )}
      </Gap>
      <Stack alignItems='flex-start' m='0 0 0 68px'>
        {showUserList ? (
          <UsersList
            users={!!selectedDepartment.length ? filteredUsers : customer.users}
            selectedUsers={customerState[customer.customerId]}
            customerId={`${customer.customerId}`}
            setSelectedUsersHandler={setSelectedUsersHandler}
          />
        ) : null}
      </Stack>
    </div>
  );
};
