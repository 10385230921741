import * as React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal } from 'lib/components';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.coal};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.palette.gray100};
  }
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  gap: 12px;
`;

type Props = {
  count: number;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  handleModalClose: () => void;
};

export const ModalDeleteSnippets = ({
  count,
  onClickSecondaryButton,
  onClickPrimaryButton,
  handleModalClose,
}: Props) => {
  const title = `Delete ${count > 1 ? count : ''} Template${
    count > 1 ? 's' : ''
  }?`;
  const text = `Are you sure you want to delete ${count} template${
    count > 1 ? 's' : ''
  }?`;
  const primaryButtonText = 'Yes, Delete';
  const secondaryButtonText = 'No, Cancel';
  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          {title}
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <p>{text}</p>
        </Content>
        <ButtonsWrapper>
          <Button
            data-cy='template-delete-cancel-button'
            text={secondaryButtonText}
            onClick={onClickSecondaryButton}
            variant='secondary'
          />
          <Button
            data-cy='template-delete-confirm-button'
            text={primaryButtonText}
            onClick={onClickPrimaryButton}
            variant='red'
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
