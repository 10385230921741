import { AttachedFile } from 'lib/context';
import CloseCircleIcon from 'lib/images/CloseCircleIcon';
import { theme } from 'lib/style';
import { formatBytes } from 'lib/utils/functions';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
`;

const Size = styled.div`
  font-size: 14px;
  color: ${theme.palette.covideoGray80};
  margin-left: 10px;

  white-space: nowrap;
`;

const Title = styled.div`
  margin-left: 5px;
  margin-right: 75px;
  font-size: 16px;

  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
type CloseProps = {
  disabled?: boolean;
};

const CloseContainer = styled.div<CloseProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
`;

type AttachmentProps = {
  attachment: AttachedFile;
  onRemove: (arg1: string, arg2: string) => void;
  isRemoveDisabled?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
};
const AttachmentPreview = ({
  attachment,
  onRemove,
  children,
  style,
  isRemoveDisabled,
}: AttachmentProps) => {
  const { name, size, id } = attachment;
  const sizeString = formatBytes(size, 2);

  return (
    <Container style={style}>
      <CloseContainer
        onClick={() => {
          if (!isRemoveDisabled) {
            onRemove(id || '', name);
          }
        }}
        disabled={isRemoveDisabled}
      >
        <CloseCircleIcon
          width={'20px'}
          height={'20px'}
          color={theme.palette.covideoGray60}
        />
      </CloseContainer>

      <Title
        style={{
          ...(!!children && {
            width: '248px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 0,
          }),
        }}
      >
        {name}
      </Title>
      {children}
      <Size
        style={{
          ...(!!children && {
            textAlign: 'right',
          }),
        }}
      >
        {sizeString}
      </Size>
    </Container>
  );
};

export default AttachmentPreview;
