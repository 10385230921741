import React, { useState, useEffect } from 'react';
import { MdDownload, MdSend } from 'react-icons/md';
import styled from 'styled-components/macro';
import { Dropdown, Search } from 'lib/components';
import {
  deliveryMethodOptions,
  Filters,
  IDropdown,
  Stats,
  statusOptions,
} from '../const';
import { downloadAnalyticsReport } from 'lib/api';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';

const QuickFilterWrapper = styled.div`
  display: flex;
  width: 100%;
  row-gap: 12px;
  column-gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const SearchInput = styled(Search)`
  width: calc(25% - 12px);
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: calc(25% + 44px);
  justify-content: flex-end;
`;

interface IProps {
  stats: Stats;
  filter: {
    filters: Filters | { [key: string]: string };
    setFilters: React.Dispatch<React.SetStateAction<{} | Filters>>;
  };
  setModal: React.Dispatch<
    React.SetStateAction<'report' | 'email_preview' | null>
  >;
  videoId: string;
}

export const CardStatsViewersQuickFilters = ({
  filter: { setFilters, filters },
  setModal,
  videoId,
}: IProps) => {
  const [statusOptionValue, setStatusOptionValue] = useState<IDropdown | null>(
    null
  );
  const [deliveryOptionValue, setDeliveryOptionValue] =
    useState<IDropdown | null>(null);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const { showError } = useToastError();

  useEffect(() => {
    const option =
      statusOptions.find(({ value }) => filters.emailStatus === value) || null;
    setStatusOptionValue(option);
  }, [filters]);

  const isBlankValue = (val: any) =>
    val === 'all' || val === null || val === undefined || val === '';

  const setFilter = (e: string | null, key: keyof Filters) => {
    setFilters((prevState: Filters) => {
      const { [key]: _, ...rest } = prevState;
      return {
        ...(!isBlankValue(e) ? { ...prevState, [key]: e } : { ...rest }),
      };
    });
  };

  const downloadReport = async () => {
    setDownloading(true);
    try {
      await downloadAnalyticsReport({ videoId, params: filters });
    } catch (error) {
      showError(error);
    }
    setDownloading(false);
  };

  return (
    <QuickFilterWrapper>
      <SearchInput onSearch={e => setFilter(e, 'search')} />
      <Dropdown
        value={deliveryOptionValue}
        placeholder='All Delivery Methods'
        options={deliveryMethodOptions}
        onChange={(e: IDropdown) => {
          setDeliveryOptionValue(e);
          setFilter(e.value, 'SMS');
        }}
        extendStyles={{
          container: {
            width: 'calc(25% - 40px)',
          },
        }}
      />
      <Dropdown
        dropdownHeight={200}
        value={statusOptionValue}
        placeholder='All Statuses'
        options={statusOptions}
        onChange={(e: IDropdown) => {
          setStatusOptionValue(e);
          setFilter(e.value, 'emailStatus');
        }}
        extendStyles={{
          container: {
            width: 'calc(25% - 40px)',
          },
        }}
      />
      <BtnWrapper>
        <Button
          text='Send'
          icon={<MdSend size={'24px'} />}
          onClick={() => setModal('report')}
          title='Send Report'
        />

        <Button
          onClick={downloadReport}
          text={isDownloading ? 'Downloading' : 'Download'}
          disabled={isDownloading}
          icon={<MdDownload size={'24px'} />}
          title='Download Report'
        />
      </BtnWrapper>
    </QuickFilterWrapper>
  );
};
