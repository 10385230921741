import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { ManageAddons } from './main/ManageAddons';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ManageSMSAddon } from './main/ManageSMS';
import { ManageAnnotations } from './main/ManageAnnotations';
import { ManageCaptions } from './main/ManageCaptions';
import { ManageFiles } from './main/ManageFiles';
import { CodirectAddon } from './main/Codirect';
import { ManageLPBAddon } from './main/LandingPageBuilder';
import { WheelsTVAddon } from './main/WheelsTV';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { NotFoundTemplate } from 'app/pages/notFound';

export const AddOns = () => {
  const [isSMSDisabled, setIsSmsDisabled] = React.useState(false);
  const [isLpbDisabled, setIsLpbDisabled] = React.useState(false);
  const [isCaptionsDisabled, setIsCaptionsDisabled] = React.useState(false);
  const [isAnnotationsDisabled, setIsAnnotationsDisabled] = React.useState(
    false
  );
  const [isFilesDisabled, setIsFilesDisabled] = React.useState(false);
  const [isWheelsTvDisabled, setIsWheelsTvDisabled] = React.useState(false);
  const [isCodirectDisabled, setIsCodirectDisabled] = React.useState(false);

  const { userData } = useAuth();

  React.useEffect(() => {
    const packageDetails = userData.user?.packageDetails;
    if (!packageDetails || packageDetails.id == 0 || !packageDetails.products) {
      return;
    }

    const isSMSDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.SMS_ADMIN_CONTROLS
    );

    const isLpbDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.LANDING_PAGE_BUILDER
    );

    const isCaptionsDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.CAPTIONS
    );

    const isAnnotationsDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.ANNOTATIONS
    );

    const isFilesDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.FILES_AND_BOARDS
    );

    const isWheelsTvDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.WTV
    );

    const isCodirectDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.CODIRECT
    );

    setIsAnnotationsDisabled(isAnnotationsDisabled);
    setIsCaptionsDisabled(isCaptionsDisabled);
    setIsCodirectDisabled(isCodirectDisabled);
    setIsFilesDisabled(isFilesDisabled);
    setIsWheelsTvDisabled(isWheelsTvDisabled);
    setIsSmsDisabled(isSMSDisabled);
    setIsLpbDisabled(isLpbDisabled);
  }, []);

  return (
    <Switch>
      <Route path='/profile/add-ons/sms/manage'>
        {!isSMSDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<ManageSMSAddon />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/lpb/manage'>
        {!isLpbDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<ManageLPBAddon />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/sf/manage'>
        {!isAnnotationsDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<ManageAnnotations />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/captions/manage'>
        {!isCaptionsDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<ManageCaptions />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/files/manage'>
        {!isFilesDisabled ? (
          <>
            <DocumentHead title='Files Add-On' />
            <PageTemplate main={<ManageFiles />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/codirect/details'>
        {!isCodirectDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<CodirectAddon />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons/wheels-tv/details'>
        {!isWheelsTvDisabled ? (
          <>
            <DocumentHead title='Add-Ons' />
            <PageTemplate main={<WheelsTVAddon />} showNavigation={false} />
          </>
        ) : (
          <Redirect to='/upgrade-plan' />
        )}
      </Route>
      <Route path='/profile/add-ons' exact>
        <>
          <DocumentHead title='Add-Ons' />
          <PageTemplate main={<ManageAddons />} showUpgradeToday={true} />
        </>
      </Route>
      <Route path='*' component={NotFoundTemplate} />
    </Switch>
  );
};
