import * as React from 'react';
import styled from 'styled-components/macro';
import { ModalAddContact } from 'lib/components/modal/ModalAddContact';

import { theme } from 'lib/style';
import { MdAdd } from 'react-icons/md';
import { IoIosLogIn } from 'react-icons/io';
import placeholder from 'lib/images/tablePlaceholderBig.jpg';
import { Button } from 'react-covideo-common';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
type Props = {
  handleAddContact: (
    newContacts: object,
    updatedContacts: object,
    updatedGroupsOnlyContacts: object
  ) => void;
};

export const NoContacts = ({ handleAddContact }: Props) => {
  const [isUploadVisible, setIsUploadVisible] = React.useState(false);
  const [isCreateVisible, setIsCreateVisible] = React.useState(false);

  return (
    <>
      {isUploadVisible && (
        <ModalAddContact
          modalType='bulk'
          title='Import Contacts '
          text=''
          handleModalClose={() => setIsUploadVisible(false)}
          handleSubmit={handleAddContact}
        />
      )}

      {isCreateVisible && (
        <ModalAddContact
          modalType='one'
          title='Add Contact'
          text=''
          handleModalClose={() => setIsCreateVisible(false)}
          handleSubmit={handleAddContact}
        />
      )}
      <NoContactLayout>
        <Text>You have no contacts</Text>
        <Row>
          <Button
            text='Create'
            onClick={() => {
              setIsCreateVisible(true);
            }}
            icon={<MdAdd color='#fff' />}
          />
          <Button
            variant='ghost'
            icon={<IoIosLogIn color={theme.palette.black_1_100} />}
            text={'Import'}
            onClick={() => {
              setIsUploadVisible(true);
            }}
          />
        </Row>
      </NoContactLayout>
    </>
  );
};
