import * as React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import placeholder from 'lib/images/tablePlaceholderBig.jpg';
import { theme } from 'lib/style';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
type Props = {
  setShowAddToGroupModal: (data: boolean) => void;
};

export const NoContacts = ({ setShowAddToGroupModal }: Props) => {
  return (
    <>
      <NoContactLayout>
        <Text>The Contact is not in a group</Text>
        <Row>
          <Button
            text='Add to group'
            onClick={() => {
              setShowAddToGroupModal(true);
            }}
          />
        </Row>
      </NoContactLayout>
    </>
  );
};
