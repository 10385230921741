import dayjs from 'dayjs';
import { freemiumOnboardingListKeys } from 'lib/api/freemiumOnboarding/freemiumOnboardingListKeys';
import { successToast } from 'lib/components/toasts/success';
import {
  Customer,
  ICombinedUserData,
  ReactionWithIcon,
  VideoReactionItem,
  ReactionsPosition,
} from 'lib/context';
import { default as unescape, some } from 'lodash';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  CSV_FORMULA_INJECTION_PATTERN,
  COMMAND_KEYWORDS_REGEX,
  CSV_FORMULA_INJECTION_REGEX,
} from 'lib/const/inputValidationConstants';
import { PASSWORD_REGEX } from './regexes';
import { getQuickshareDetails } from 'lib/api/quickshare/useGetQuickshareDetails';
import { IQuickShareMarkAsSentParams } from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import axios from 'axios';
import {
  VideoReactionsDictionary,
  VideoReactionsDictionaryType,
  VideoReactionType,
} from 'lib/const/VideoReactionsDictionary';
import { PROMPT_USE_CASE } from 'lib/api/magic/const';

dayjs.extend(utc);
dayjs.extend(timezone);

export const toMMSS = (sec_num: number, fractionDigits = 1) => {
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  if (isNaN(minutes)) {
    return '00:00.0';
  }

  let time = '';
  // if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) {
    time += '0';
  }
  time += minutes + ':';
  if (seconds < 10) {
    time += '0';
  }
  time += seconds.toFixed(fractionDigits);
  return time;
};

export const toMMSSTimestamp = (sec_num: number, fractionDigits = 1) => {
  const minutes = Math.floor(sec_num / 60);
  const seconds = Math.floor(sec_num - minutes * 60);

  if (isNaN(minutes)) {
    return '00.0';
  }

  let time = '';
  if (minutes < 10) {
    time += '0';
  }
  time += minutes + ':';
  if (seconds < 10) {
    time += '0';
  }

  time += seconds.toFixed(fractionDigits);

  return time;
};

export const toHHMMSSFF = (sec_num: number, frame_rate: number = 30) => {
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  const milliseconds = parseInt((seconds % 1).toFixed(3).substring(2));
  let frame = parseInt(
    Math.floor(milliseconds / (1000 / frame_rate)).toFixed(0)
  );
  seconds = parseInt(Math.floor(seconds).toFixed(0));

  if (isNaN(minutes)) {
    return '00:00:00:00';
  }

  let time = '';
  if (hours < 10) {
    time = '0';
  }
  time += hours + ':';
  if (minutes < 10) {
    time += '0';
  }
  time += minutes + ':';
  if (seconds < 10) {
    time += '0';
  }
  time += seconds + ':';

  if (frame < 10) {
    time += '0';
  }
  return time + frame;
};

export const toString = (o: any) => {
  if (!o) {
    return o;
  }
  Object.keys(o).forEach(k => {
    if (typeof o[k] === 'object') {
      return toString(o[k]);
    }

    if (typeof o[k] !== 'boolean') {
      o[k] = '' + o[k];
    }
  });

  return o;
};

export const wrapWithHttp = (url: string) => {
  if (url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
    return 'https://' + (url || '').replace(/(^\w+:|^)\/\//, '');
  }
  return url;
};

export function sanitizeCSVValue(value: string) {
  if (!value || typeof value !== 'string' || !value.trim()) {
    return value;
  }

  const tabChar = '0x09';
  const firstChar = value.charAt(0);
  // detect if first char of data has one of the special characters
  if (CSV_FORMULA_INJECTION_PATTERN.indexOf(firstChar) > -1) {
    // Remove a tab character else escape a special character
    if (`${firstChar}` === tabChar) {
      value = value.replace(firstChar, '');
    } else {
      value = value.replace(firstChar, `'${firstChar}`);
    }
  }
  return value;
}

export function convertToCSV(headers: any, selectedContacts: any[]) {
  const rows = selectedContacts.filter(contact => !!contact);
  const header = Object.keys(headers);
  var csv = Object.values(headers).join(',') + '\r\n';

  for (let i = 0; i < rows.length; i++) {
    var line = '';
    const row = rows[i];
    for (let j = 0; j < header.length; j++) {
      const column = header[j];
      row[column] = row[column]?.toString().includes(',')
        ? `"${row[column]}"`
        : row[column];
      line += sanitizeCSVValue(row[column] ?? '');
      if (j < header.length - 1) {
        line += ','; // to not make extra column
      }
    }
    csv += line + '\r\n';
  }
  return csv;
}

export function exportCSVFile(headers: any, items: any[], fileTitle: string) {
  var csv = convertToCSV(headers, items);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const addThousandCommaSeparator = (
  n: number,
  roundDecimal: boolean = false
) => {
  return n.toLocaleString(
    'en',
    roundDecimal
      ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : {}
  );
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    addThousandCommaSeparator(
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
    ) +
    ' ' +
    sizes[i]
  );
};

export const generatePassword = (length: number) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const password = [];
  for (let i = 0; i < length - 1; i++) {
    password.push(charset[Math.floor(Math.random() * charset.length)]);
  }
  const specialCharset = '!@#$%^&*';
  password.push(
    specialCharset[Math.floor(Math.random() * specialCharset.length)]
  );
  return password.join('');
};

export const calculateRange = (value: any, format?: any) => {
  const range: any = {
    from: '',
    to: dayjs().utc().format(),
    formatFrom: '',
    formatTo: '',
  };
  let startDate = 6;
  const today = dayjs();
  switch (value) {
    case '1d':
      startDate = 0;
      break;
    case '7d':
      startDate = 6;
      break;
    case '30d':
      startDate = 29;
      break;
    case 'MTD':
      const monthStartDate = dayjs(
        `${dayjs().year()}-${dayjs().month() + 1}-01`
      );
      startDate = today.diff(monthStartDate, 'days');
      break;
    case 'YTD':
      const startDateOfYear = dayjs(new Date(dayjs().year(), 0, 1));
      startDate = today.diff(startDateOfYear, 'days');
      break;
  }
  const tempFrom = new Date(
    new Date().getTime() - startDate * 24 * 60 * 60 * 1000
  );
  range.from = dayjs(
    new Date(
      tempFrom.getFullYear(),
      tempFrom.getMonth(),
      tempFrom.getDate(),
      0,
      0,
      0,
      0
    )
  )
    .utc()
    .format();
  if (format) {
    range.formatFrom = dayjs(range.from).format(format.formatFrom);
    range.formatTo = dayjs(range.to).format(format.formatTo);
  }
  return range;
};

export const truncate = (str: string, n: number) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1);
  return subString.substring(0, subString.lastIndexOf(' ')) + '...';
};

export const priceFormatter = (
  price: number | string | undefined,
  useGrouping = true
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping,
  });
  return formatter.format(Number(price));
};

export const removeHTMLTags = (str: any) => {
  if (!str) return '';
  return str.toString().replace(/(<([^>]+)>)/gi, '');
};

export const prepareTextForSMS = (str: string) => {
  return unescape(removeHTMLTags(str.trim())).replace(/\&nbsp;/g, ' ');
};

export const getQueryVariable = (param: string) => {
  const params = window.location.search.substring(1).split('&');
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');
    if (pair[0] == param) {
      return pair[1];
    }
  }
  return undefined;
};

export const checkNumeric = (value: any): boolean => {
  return /^[0-9]+$/.test(value);
};

export const toHHMMSS = (secs: number) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(val => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':')
    .replace(/^0/, '');
};
export const getSecondsFromHHMMSS = (value: string) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

// Function to format time duration to string of mm:ss.
export const formatTimeToString = (time: number) => {
  let timeMinutes = Math.floor(time / 60);
  let timeMinutesString = timeMinutes.toString();
  let timeSeconds = `${Math.floor(time - timeMinutes * 60)}`;
  if (timeMinutesString.length === 1) timeMinutesString = `0${timeMinutes}`;
  if (timeSeconds.length === 1) timeSeconds = `0${timeSeconds}`;
  return `${timeMinutes}:${timeSeconds}`;
};

/* SUS-1229 changes */
export const validPassword = (password: string) => {
  return PASSWORD_REGEX.test(password);
};

export const getTitle = () => {
  const dateOfMonth = dayjs(new Date()).date();
  let ordinalLetters = 'th';
  if (dateOfMonth === 1 || dateOfMonth === 21 || dateOfMonth === 31) {
    ordinalLetters = 'st';
  } else if (dateOfMonth === 2 || dateOfMonth === 22) {
    ordinalLetters = 'nd';
  } else if (dateOfMonth === 3 || dateOfMonth === 23) {
    ordinalLetters = 'rd';
  }

  return dayjs(new Date()).format(`MMM D[${ordinalLetters}] YYYY @ h:mm A`);
};

export const getDevices = () => {
  return new Promise(async (resolve, reject) => {
    let devices = await navigator.mediaDevices.enumerateDevices();
    if (some(devices, { label: '' })) {
      try {
        const s = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        devices = await navigator.mediaDevices.enumerateDevices();
        s.getTracks().forEach((t: any) => t.stop());
      } catch (e) {
        reject(e);
      }
    }
    resolve(devices);
  });
};

export const capitalizeWords = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const checkIfFreemiumAndInvalidateForCheckList = (
  userData: ICombinedUserData,
  queryClient: any
) => {
  checkIfFreemium(userData) &&
    queryClient.invalidateQueries(
      freemiumOnboardingListKeys.freemium_onboarding_list()
    );
};

export const checkIfFreemium = (userData: ICombinedUserData) => {
  return userData?.customer.packageId?.toString() === '1';
};

export const checkOnboardingPhaseForFreemium = (
  userData: ICombinedUserData,
  onboardedValue: string
) => {
  return userData?.onboardedFreemium?.toString() === onboardedValue;
};

export const checkParamForString = (value: string | (string | null)[] | null) =>
  value && typeof value === 'string';

export const getCTATypes = (droplrAccess: boolean, has700Credit: boolean) => {
  const ctaTypes = [
    {
      value: 'url',
      label: 'URL',
    },
    {
      value: 'email',
      label: 'Send Email',
    },
    {
      value: 'video',
      label: 'Video',
    },
  ];
  if (droplrAccess) {
    ctaTypes.push(
      {
        value: 'library',
        label: 'File',
      },
      {
        value: 'board',
        label: 'Board',
      }
    );
  }
  if (has700Credit) {
    ctaTypes.push({
      value: 'url700Credit',
      label: '700 Credit URL',
    });
  }
  return ctaTypes;
};

export const copyField = (value: string, field: string) => {
  const cb = navigator.clipboard;
  cb.writeText(value || '').then(() => {
    successToast({ title: `${field} copied successfully` });
  });
};

export const convertToHttps = (urlString: string) => {
  try {
    const url = new URL(urlString);
    if (url.protocol === 'http:') {
      url.protocol = 'https:';
      return url.toString();
    }

    return urlString;
  } catch (err) {
    return urlString;
  }
};

export const checkIfOverlayValid = (
  templateId: string | number,
  overlayId: string | number
) => {
  // overlay is not valid if template is Website Overlay and overlay id is empty
  return templateId?.toString() !== '-1' || !!overlayId;
};

export function validateInputForCsvFormulaInjection(value?: string | number) {
  if (value === undefined || !value || !value.toString().trim()) {
    return true;
  }
  if (!CSV_FORMULA_INJECTION_REGEX.test(value.toString())) {
    return !COMMAND_KEYWORDS_REGEX.test(value.toString());
  }

  return true;
}

export const getDisplayName = (input: string[]) =>
  input.filter(Boolean).join(' ');

export const checkIfMobile = () => {
  const userAgent = navigator.userAgent;
  const isAndroid = /android/i.test(userAgent);
  const isIos = /iPad|iPhone|iPod/.test(userAgent);
  return isAndroid || isIos;
};

export const decodeAndReplaceText = (
  value: string,
  isReplace: boolean = false
) => {
  try {
    return !!isReplace
      ? decodeURIComponent(value).replaceAll('&nbsp;', ' ')
      : decodeURIComponent(value);
  } catch (error) {
    return value;
  }
};

export const copyToClipboardQuickShare = async (url: string, html?: string) => {
  const listener = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      e.clipboardData.setData('text/html', html || '');
      e.clipboardData.setData('text/plain', url);
    }
    e.preventDefault();
  };

  document.addEventListener('copy', e => listener(e), {
    once: true,
  });
  document.execCommand('copy');
};

export const copyToClipboardQuickShareSafari = async (
  payload: IQuickShareMarkAsSentParams
) => {
  const quickshareDetails = async () => {
    const { videoId, videoId: _, ...rest } = payload;
    const getQuickshareData = await getQuickshareDetails(videoId, {
      ...rest,
    });
    return {
      url: getQuickshareData.data.url,
      html: getQuickshareData.data.html,
    };
  };

  const textArea = document.createElement('textArea') as HTMLTextAreaElement;

  await navigator.clipboard
    // @ts-ignore
    .write([
      // @ts-ignore
      await new ClipboardItem({
        'text/plain': new Promise(async resolve => {
          resolve(
            new Blob([(await quickshareDetails()).url], {
              type: 'text/plain',
            })
          );
        }),

        'text/html': new Promise(async resolve => {
          textArea.style.visibility = 'hidden';
          textArea.setAttribute('contenteditable', 'true');
          textArea.value = (await quickshareDetails()).html;
          document.body.appendChild(textArea);
          const range = document.createRange();
          range.selectNodeContents(textArea);
          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
          textArea?.setSelectionRange(0, 999999);
          resolve(
            new Blob([textArea.value], {
              type: 'text/html',
            })
          );
        }),
      }),
    ]);

  if (textArea && textArea.remove) {
    textArea.remove();
  }
};

export const downloadFileFromUrl = async (
  downloadUrl: string,
  fileName: string
) => {
  const response = await axios({
    url: downloadUrl,
    method: 'GET',
    responseType: 'blob', // Ensure axios returns a blob
  });

  var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getClassicLayoutVisibility = (customer: Customer): boolean => {
  try {
    const webAppVersion = JSON.parse(customer?.webAppVersionAccess?.toString());
    if (webAppVersion) {
      return !!webAppVersion.v2;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const summarizeReactionsSums = (videoReactions: {
  [id: string]: VideoReactionItem;
}): { commentSum: number; emoteSums: any } => {
  const commentSum = Object.values(videoReactions || {}).filter(
    r => r.type === VideoReactionType.COMMENT
  ).length;
  let emoteSums: { [key: number]: number } = {};
  Object.values(videoReactions || {})
    .filter(r => r.type === VideoReactionType.EMOTE)
    .forEach(r => {
      emoteSums[r.emoticonId || 0] = !emoteSums[r.emoticonId || 0]
        ? 1
        : emoteSums[r.emoticonId || 0] + 1;
    });

  return { commentSum, emoteSums };
};

export const summarizePlayerReactions = (
  videoReactions: { [id: string]: VideoReactionItem },
  videoLengthSeconds: number
): {
  reactionsPositions: {
    [key: number]: ReactionsPosition;
  };
} => {
  const reactionsPositions: {
    [key: number]: { position: number; reactions: ReactionWithIcon[] };
  } = {};

  for (const reaction of Object.values(videoReactions || {})) {
    let position =
      Math.round((reaction.playbackPosition / videoLengthSeconds) * 10) * 10;
    position = position > 90 ? 90 : position;
    const icon =
      reaction.type === VideoReactionType.COMMENT
        ? VideoReactionsDictionary[7].icon
        : reaction?.emoticonId &&
            VideoReactionsDictionary[
              reaction.emoticonId as keyof VideoReactionsDictionaryType
            ]
          ? VideoReactionsDictionary[
              reaction.emoticonId as keyof VideoReactionsDictionaryType
            ].icon
          : null;
    const smallIcon =
      reaction.type === VideoReactionType.COMMENT
        ? VideoReactionsDictionary[7].smallIcon
        : reaction?.emoticonId &&
            VideoReactionsDictionary[
              reaction.emoticonId as keyof VideoReactionsDictionaryType
            ]
          ? VideoReactionsDictionary[
              reaction.emoticonId as keyof VideoReactionsDictionaryType
            ].smallIcon
          : null;
    const reactionWithIcon = { ...reaction, icon, smallIcon };
    reactionsPositions[position] = !reactionsPositions[position]
      ? { position, reactions: [reactionWithIcon] }
      : {
          ...reactionsPositions[position],
          reactions: [
            ...reactionsPositions[position].reactions,
            reactionWithIcon,
          ],
        };
  }
  return { reactionsPositions };
};

export const getDefaultPromptUseCase = (
  isAutomotive: boolean
): PROMPT_USE_CASE => {
  return isAutomotive
    ? PROMPT_USE_CASE.INITIAL_REACH_OUT
    : PROMPT_USE_CASE.NONE;
};

export const getQueryParamValue = (key: string) => {
  try {
    return new URLSearchParams(window.location.search).get(key);
  } catch (ex) {
    return null;
  }
};
