import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { EXPRESS_API } from 'configs/api/expressApi';

export type UsersParams = {
  search?: string;
  sort?: string;
  page?: number;
  size?: number;
  userActiveStatus?: string;
  departmentId?: string | number;
};

export type DepartmentsParams = {
  search?: string;
  sort?: string;
};

export const getDepartments = async (
  customerId: any,
  { search = '', sort = '' }: DepartmentsParams
) => {
  const params = {};

  if (search) {
    // @ts-ignore
    params.search = search;
  }

  if (sort) {
    // @ts-ignore
    params.sort = sort;
  }

  const data = {};

  const response = await EXPRESS_API.get(
    `/departments/${customerId}/managed-departments`,
    { data, params }
  );
  return response.data;
};

export const getAllDepartmentsForCustomer = async (customerId: any) => {
  const response = await EXPRESS_API.get(`/departments/${customerId}/all`);
  return response.data;
};

export const updateUser = async (
  userId: any,
  data: any,
  isEditView = false,
  changeOrigin?: CHANGE_ORIGIN
) => {
  const response = isEditView
    ? await EXPRESS_API.put(`/users/${userId}`, data, {
        params: { changeOrigin },
        withCredentials: true,
      })
    : await EXPRESS_API.post(`/users`, data, {
        params: { changeOrigin },
        withCredentials: true,
      });
  return response.data;
};

export const createDepartment = async (data: any) => {
  const response = await EXPRESS_API.post(`/departments`, data);
  return response.data;
};

export const updateUserDepartment = async (data: any) => {
  const response = await EXPRESS_API.post(
    `/departments/attachDepartments`,
    data
  );
  return response.data;
};

export const updateDepartmentDefault = async (
  id: number | string,
  criteria: string | number
) => {
  const params = {
    removeDefault: criteria,
  };
  const data = {};
  const response = await EXPRESS_API.put(`/departments/${id}`, data, {
    params,
  });
  return response.data;
};

export const removeUserFromDepartment = async (data: any, customerId: any) => {
  const response = await EXPRESS_API.put(
    `/departments/${customerId}/dettachDepartments`,
    data
  );
  return response.data;
};

export const mergeDepartment = async (data: any, customerId: any) => {
  const response = await EXPRESS_API.put(
    `/departments/${customerId}/mergeDepartments`,
    data
  );
  return response.data;
};

export const updateDepartment = async (data: any, departmentId: any) => {
  const response = await EXPRESS_API.put(
    `/departments/${departmentId}/update`,
    data
  );
  return response.data;
};

export const deleteDepartment = async (departmentIds: any) => {
  const data = { departmentIds };
  const response = await EXPRESS_API.delete(`/departments/delete-departments`, {
    data,
  });
  return response.data;
};

export const updateDefaults = async (data: any) => {
  const response = await EXPRESS_API.put(`/users/set-defaults/bulk`, data);
  return response.data;
};

export const importUsers = async (data: any) => {
  const response = await EXPRESS_API.post(`/users/bulkUsers`, data);
  return response.data;
};

export const fetchCustomersByResellerId = async (
  customerId: number,
  take: number = 35,
  skip: number = 0,
  hasBusiness: boolean = false
) => {
  const response = await EXPRESS_API.get(
    `/customers/${customerId}/getByReseller`,
    { params: { take, skip, hasBusiness } }
  );
  return response.data;
};

export const updateMultipleUsersAutomotiveRole = async (
  userIds: number[],
  automotiveRole: number
) => {
  const data = {
    userIds,
    automotiveRole: Number(automotiveRole),
  };
  const response = await EXPRESS_API.patch(`/users/automotive-role`, data);
  return response.data;
};
