import styled from 'styled-components/macro';
import { ReactComponent as LinkExternalIconSvg } from './icon-link-external.svg';

const LinkExternalIcon = styled(LinkExternalIconSvg)`
  width: ${props => props.width || '21px'};
  height: ${props => props.height || '21px'};
  fill: ${props => props.color || 'currentColor'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default LinkExternalIcon;
