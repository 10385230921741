import React from 'react';
import { theme } from 'lib/style';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { Layout, NormalText, TableCell } from '../../index.styled';
import { Gap } from 'lib/components/styles/layout';
import { MdEdit } from 'react-icons/md';
import { MultiLocationTableColumns, tableFields } from '../const';
import { PaginationWrapper } from '../../ims/components/style';
import DeleteIcon from 'lib/images/DeleteIcon';
import { Button } from 'react-covideo-common';
import { SelectedCustomer } from '../pages/MultiLocationList';
import { MultilocationData } from 'lib/api/multiLocations/multilocationsQuery';
import { useHistory } from 'react-router-dom';
import { SortButtons } from '../../components/SortButtons';
import { PaginationConstants } from 'lib/const/PaginationConstants';

type Props = {
  count: number;
  data: MultilocationData[];
  page: number;
  size: number;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<SelectedCustomer | null>
  >;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
};

export const MultiLocationTable = ({
  count,
  data,
  page,
  size,
  onPaginationChange,
  setSelectedCustomer,
  onSortingUpdate,
  order,
  sortKey,
}: Props) => {
  const history = useHistory();
  return (
    <Layout>
      <TableContextProvider
        total={count}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div style={{ paddingBottom: '20px' }}>
          <Table
            compact={true}
            borderColor={theme.palette.neutral10}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell
                    key={index}
                    width={item.width}
                    onClick={() => {}}
                    justifyContent={index !== 0 ? 'flex-end' : 'flex-start'}
                  >
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            rows={data?.map((customer: any, index: number) => ({
              key: index,
              columns: [
                ...tableFields.map((item, index) => {
                  let columnValue = <></>;
                  switch (item.value) {
                    case MultiLocationTableColumns.MAIN_CUSTOMER:
                      columnValue = (
                        <NormalText>{customer.business}</NormalText>
                      );
                      break;
                    case MultiLocationTableColumns.LOCATIONS:
                      columnValue = (
                        <NormalText>{customer.locationCount}</NormalText>
                      );
                      break;
                    case MultiLocationTableColumns.USERS:
                      columnValue = (
                        <NormalText key={index}>
                          {customer.userCount}
                        </NormalText>
                      );
                      break;
                    default:
                      columnValue = <></>;
                  }
                  return (
                    <TableCell
                      key={index}
                      width={item.width}
                      justifyContent={index !== 0 ? 'flex-end' : 'flex-start'}
                    >
                      {columnValue}
                    </TableCell>
                  );
                }),
                <TableCell justifyContent={'flex-end'}>
                  <Gap
                    flexDirection='row'
                    gap='8px'
                    alignItems='start'
                    justifyContent='end'
                  >
                    <Button
                      onClick={() =>
                        history.push(
                          `/admin/multilocations/${customer.customerId}/edit`
                        )
                      }
                      variant='primary'
                      icon={<MdEdit size={20} />}
                      size='small'
                    />
                    <Button
                      onClick={() =>
                        setSelectedCustomer({
                          customerId: customer.customerId,
                          business: customer.business,
                        })
                      }
                      size='small'
                      variant='destructive'
                      icon={
                        <DeleteIcon
                          color={theme.palette.buttonDelete}
                          width={20}
                          height={20}
                        />
                      }
                    />
                  </Gap>
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
