import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { Dropdown, NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { accessTypeOptions } from 'lib/const/AccessRole';
import { useUpdateUsersAccessRoleMutation } from 'lib/api/users/useUpdateUsersAccessRoleMutation';

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: () => void;
  selectedUsers: number[];
};

export const ChangeUsersAccessRoleModal = ({
  handleModalClose,
  selectedUsers,
}: Props) => {
  const [accessRole, setAccessRole] = useState<number>(0);
  const headerText = `Edit User Type for ${
    selectedUsers.length
  } user${selectedUsers.length > 1 ? 's' : ''}!`;

  const { mutateAsync: updateAccessRole, isLoading } =
    useUpdateUsersAccessRoleMutation();

  const handleUpdateUsersAccessRole = async () => {
    await updateAccessRole({
      userIds: selectedUsers,
      accessRole,
    });

    handleModalClose();
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Content>
        <Dropdown
          menuPortalTarget={document.body}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          creatable={false}
          options={accessTypeOptions}
          placeholder='Select User Type'
          value={accessTypeOptions.find(o => {
            return o.value === accessRole;
          })}
          onChange={option => {
            setAccessRole(option.value);
          }}
          isLoading={isLoading}
          className='option-container'
        />
        <SubmitButtonWrapper>
          <Button
            text={'Save Changes'}
            onClick={() => {
              handleUpdateUsersAccessRole();
            }}
            disabled={isLoading}
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
