import { HoverPopup, Spinner } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React, { useState } from 'react';

import { ModalQuickShareMarkAsSent } from 'app/pages/library/videos/components';
import { useQuickShare } from 'lib/hooks/useQuickShare';
import { VIDE0_ACTIVITY_TYPE } from 'lib/api/videoActivities/types';
import CopyEmailShareIcon from 'lib/images/CopyEmailShareIcon';
import { Button } from 'react-covideo-common';
// import CopySmsShareIcon from 'lib/images/CopySmsShareIcon';
import { Gap } from 'lib/components/styles/layout';
import { MeetingRecording } from 'lib/api/meetings/types';

export const RecordingQuickshare = ({
  video,
}: Pick<MeetingRecording, 'video'>) => {
  const { id, videoRequest } = video;
  const { userData } = useAuth();
  const [activeType, setActiveType] = useState<null | string>(null);
  const [showModalQuickShareMarkAsSent, setShowModalQuickShareMarkAsSent] =
    useState(false);

  const successMutationCallback = () => {
    setActiveType(null);
  };

  const markAsSentPayload = {
    videoId: id.toString(),
    useAutomotive: !!videoRequest?.vin,
    vin: videoRequest?.vin || '',
  };

  const {
    isQuickshareLoading,
    onQuickShareButtonClick,
    onQuickShareMutationHandler,
  } = useQuickShare({
    setShowModalQuickShareMarkAsSent,
    handleOnMarkAsSentSuccessCallback: successMutationCallback,
  });

  return (
    <Gap>
      <HoverPopup
        width='100px'
        position='above'
        padding='8px 12px'
        hoverable={
          <Button
            variant='secondary'
            onClick={() => {
              setActiveType(VIDE0_ACTIVITY_TYPE.COPY_EMAIL);
              onQuickShareButtonClick({
                videoActivityType: VIDE0_ACTIVITY_TYPE.COPY_EMAIL,
                toastType: 'copyEmail',
                ...markAsSentPayload,
              });
            }}
            icon={
              activeType === VIDE0_ACTIVITY_TYPE.COPY_EMAIL ? (
                <Spinner color={theme.palette.primaryDarkBlue} />
              ) : (
                <CopyEmailShareIcon />
              )
            }
            disabled={isQuickshareLoading || !userData.webShare}
          />
        }
        popup={<>Copy Video</>}
      />
      {/*<HoverPopup*/}
      {/*  width='165px'*/}
      {/*  position='above'*/}
      {/*  padding='8px 12px'*/}
      {/*  hoverable={*/}
      {/*    <Button*/}
      {/*      variant='secondary'*/}
      {/*      icon={*/}
      {/*        activeType === VIDE0_ACTIVITY_TYPE.COPY_SMS ? (*/}
      {/*          <Spinner color={theme.palette.primaryDarkBlue} />*/}
      {/*        ) : (*/}
      {/*          <CopySmsShareIcon />*/}
      {/*        )*/}
      {/*      }*/}
      {/*      onClick={() => {*/}
      {/*        setActiveType(VIDE0_ACTIVITY_TYPE.COPY_SMS);*/}
      {/*        onQuickShareButtonClick({*/}
      {/*          videoActivityType: VIDE0_ACTIVITY_TYPE.COPY_SMS,*/}
      {/*          toastType: 'copySms',*/}
      {/*          ...markAsSentPayload,*/}
      {/*        });*/}
      {/*      }}*/}
      {/*      disabled={isQuickshareLoading || !userData.webShare}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  popup={<>Copy Video for SMS</>}*/}
      {/*/>*/}
      {showModalQuickShareMarkAsSent && (
        <ModalQuickShareMarkAsSent
          markAsSentPayload={{
            toastType:
              activeType === VIDE0_ACTIVITY_TYPE.COPY_SMS
                ? 'copySms'
                : 'copyEmail',
            ...(activeType === null ? {} : { videoActivityType: activeType }),
            ...markAsSentPayload,
          }}
          isQuickShareLoading={isQuickshareLoading}
          quickShareMarkAsSentHandler={onQuickShareMutationHandler}
          handleModalClose={() => {
            setShowModalQuickShareMarkAsSent(false);
          }}
        />
      )}
    </Gap>
  );
};
