import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IoMdSave } from 'react-icons/io';
import { MdDeleteForever, MdUpload } from 'react-icons/md';
import styled from 'styled-components/macro';

import { FormColorPicker } from 'app/pages/video/videoDetails/main/videoAnnotations/components/FormColorPicker';
import { uploadImage } from 'lib/api';
import {
  getAutoMotiveLanding,
  updateAutoMotiveLanding,
} from 'lib/api/automotiveLandingApi';
import { LoadingIndicator } from 'lib/components';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import EmptyIcon from 'lib/images/empty-icon.svg';
import quotePlaceholder from 'lib/images/quote.placeholder.png';
import videoPlaceholder from 'lib/images/quote.video-placeholder.png';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';

const UploadBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 4px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: rgba(39, 42, 50, 1);
  background-color: rgba(238, 239, 242, 1);
  position: relative;
  width: 136px;
  border-radius: 5px;
`;

const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  top: -5px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  font-size: 0;
`;

const Upload = styled.div``;
const AdditionalTextInputLabel = styled.p`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #9297a2;
  margin-bottom: 8px;
`;
const PreviewBody = styled.div<{ bg: string }>`
  padding: 32px 104px 0 104px;
  display: flex;
  gap: 32px;
  background-color: ${({ bg }) => bg};
`;
const MainFormWrapper = styled.div`
  align-items: center;
  margin-bottom: 32px;
`;
const FlexFormWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
`;
const Label = styled.label`
  display: flex;
  height: 20px;
  ${theme.fontNormal500}
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-bottom: 8px;
  font-size: 15px;
`;
const PreviewWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid rgb(226, 230, 235);
  overflow: hidden;
`;
const PreviewHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  text-align: center;
  position: relative;
  z-index: 1;
  color: #d0d3d9;
  border-bottom: 1px solid rgb(226, 230, 235);
`;
const PreviewTitle = styled.p`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  margin-bottom: 8px;
`;
const ImageControlWrapper = styled.div<{ imageExist: boolean }>`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  .delete {
    border-radius: 5px;
    background: #fef6f5;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 8px;
    color: ${({ imageExist }) =>
      imageExist ? '#e84c3d' : 'rgb(255, 199, 193)'}!important;
    cursor: ${({ imageExist }) =>
      imageExist ? 'pointer' : 'not-allowed'}!important;
  }
`;
interface InitialFormValues {
  bgColor: string;
  additionalTextForSales: string;
  additionalTextColorForSales: string;
  additionalTextForService: string;
  additionalTextColorForService: string;
  image: {
    url: string | null;
    file: File | null;
  };
}
const INITIAL_DATA = {
  bgColor: '',
  additionalTextForSales: '',
  additionalTextColorForSales: '',
  additionalTextForService: '',
  additionalTextColorForService: '',
  image: {
    url: '',
    file: null,
  },
};

const additionalTextEditorToolbar = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
      icon: EmptyIcon,
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
      icon: EmptyIcon,
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
      icon: EmptyIcon,
    },
  },
};
export const LandingQuote = () => {
  const { userData } = useAuth();
  const { showError } = useToastError();
  const { userId } = userData;
  const [initialFromData, setInitialFormData] =
    useState<InitialFormValues>(INITIAL_DATA);
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultBgColor, setDefaultBgColor] = useState('');
  const [defaultAdditionalTextColor, setDefaultAdditionalTextColor] =
    useState('');

  //get initial data
  const getInitialData = async () => {
    setLoading(true);
    try {
      const {
        bgColor,
        additionalTextForSales,
        additionalTextColorForSales,
        additionalTextForService,
        additionalTextColorForService,
        imageUrl,
      } = await getAutoMotiveLanding();
      setInitialFormData({
        bgColor,
        additionalTextForSales,
        additionalTextColorForSales,
        additionalTextForService,
        additionalTextColorForService,
        image: {
          url: imageUrl,
          //on first load file is null because we we dont have uploaded files in form
          file: null,
        },
      });
      setDefaultBgColor(bgColor);
      setDefaultAdditionalTextColor(
        userData.isAutomotiveServiceRole
          ? additionalTextColorForService
          : additionalTextColorForSales
      );
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  //update data
  const submitData = async (values: InitialFormValues) => {
    const {
      image,
      bgColor,
      additionalTextForSales,
      additionalTextColorForSales,
      additionalTextForService,
      additionalTextColorForService,
    } = values;

    //if file is null we didn't upload any files
    const imageUrl: string | null = image.file
      ? await uploadImage(image.file, userId)
      : image.url;
    const payload = {
      imageUrl: imageUrl === '' ? null : imageUrl,
      bgColor,
      additionalTextForSales:
        additionalTextForSales === '' ? null : additionalTextForSales,
      additionalTextColorForSales,
      additionalTextForService:
        additionalTextForService === '' ? null : additionalTextForService,
      additionalTextColorForService,
    };
    try {
      await updateAutoMotiveLanding(payload);
      setInitialFormData(values);
      successToast({ title: 'Your landing page is updated!' });
    } catch {
      errorToast({
        title: 'Something went wrong updating landing page!',
      });
    }
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const file = e.target?.files?.[0];

    if (typeof file === 'undefined') return;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setFieldValue('image', { file: file, url: reader.result });
      }
    };
    reader.readAsDataURL(file);
  };

  if (loading) {
    return <LoadingIndicator isLoading={true} />;
  }

  return (
    <MainWrapper resetPadding={false}>
      <Formik
        initialValues={initialFromData}
        onSubmit={submitData}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values, dirty }) => {
          const { image } = values;
          return (
            <Form>
              <HeaderWrapper>
                <Heading>
                  {userData.isAutomotiveServiceRole ? 'Service' : 'Sales'} Pages
                </Heading>
                <Button
                  text={isSubmitting ? 'Saving' : 'Save changes'}
                  type='submit'
                  disabled={isSubmitting || !dirty}
                  icon={<IoMdSave />}
                />
              </HeaderWrapper>
              <MainFormWrapper>
                <FlexFormWrapper>
                  <FormColorPicker
                    label='Background color'
                    name='bgColor'
                    width='200px'
                    menuPlacement='bottom'
                    defaultColor={defaultBgColor}
                    isClearable={true}
                  />
                  <FormColorPicker
                    label='Text color'
                    name={
                      userData.isAutomotiveServiceRole
                        ? 'additionalTextColorForService'
                        : 'additionalTextColorForSales'
                    }
                    width='200px'
                    menuPlacement='bottom'
                    defaultColor={defaultAdditionalTextColor}
                    isClearable={true}
                  />
                  <ImageControlWrapper imageExist={!!image.url}>
                    <Upload>
                      <Label htmlFor='profile'>Logo</Label>
                      <UploadBtnWrapper>
                        <UploadInput
                          id='file'
                          name='profile'
                          type='file'
                          onChange={e => handleFileChange(e, setFieldValue)}
                          accept={'image/png, image/jpeg, image/svg+xml'}
                        />
                        <MdUpload
                          size={24}
                          color={theme.palette.covideoGray100}
                        />
                        {image.url ? 'Replace logo...' : 'Update logo...'}
                      </UploadBtnWrapper>
                    </Upload>
                    <MdDeleteForever
                      onClick={() => {
                        if (!image.url) return;
                        const current_file = document.getElementById(
                          'file'
                        ) as HTMLInputElement;
                        if (!current_file) return;
                        current_file.value = '';
                        setFieldValue('image', { url: '', file: null });
                      }}
                      color={theme.palette.primaryRed50Percent}
                      size={11}
                      className='delete'
                    />
                  </ImageControlWrapper>
                </FlexFormWrapper>
                <AdditionalTextInputLabel>
                  Additional Text Below Gallery
                </AdditionalTextInputLabel>
                <TextEditor
                  initialContent={
                    userData.isAutomotiveServiceRole
                      ? values.additionalTextForService
                      : values.additionalTextForSales
                  }
                  onTextEditorChange={newValue => {
                    if (userData.isAutomotiveServiceRole) {
                      setFieldValue('additionalTextForService', newValue);
                      return;
                    }
                    setFieldValue('additionalTextForSales', newValue);
                  }}
                  height={'160px'}
                  customPadding={'60px 15px 15px 15px'}
                  placeholder={'Put your disclaimer or additional info here'}
                  toolbar={additionalTextEditorToolbar}
                  hideAddVariableButton={true}
                  editorWrapperClassname='automotive-wrapper service-page-additional-text-input'
                />
              </MainFormWrapper>
              <PreviewTitle>Preview</PreviewTitle>
              <PreviewWrapper>
                <PreviewHeader>
                  {image.url ? (
                    <img
                      src={image.url}
                      alt='logo '
                      style={{ maxHeight: 50 }}
                    />
                  ) : (
                    'No logo uploaded'
                  )}
                </PreviewHeader>
                <PreviewBody bg={values.bgColor}>
                  <img src={videoPlaceholder} alt='Video placeholder'></img>
                  <img src={quotePlaceholder} alt='Quote placeholder'></img>
                </PreviewBody>
              </PreviewWrapper>
            </Form>
          );
        }}
      </Formik>
    </MainWrapper>
  );
};
