import * as React from 'react';

export const MenuBarsIcon = props => {
  const { onClick, color, width, height, opacity } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        fill={color || '#001B53'}
        fillRule="evenodd"
        d="M24 18v4H0v-4h24zm0-8v4H0v-4h24zm0-8v4H0V2h24z"
        opacity={opacity}
      />
    </svg>
  );
};
