import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

import { guidesKeyes } from './guidesKeyes';
import { Guides } from './guidesQuery';

export interface SingleCategoryResponse {
  count: number;
  guides: Guides[];
  guideCategory: GuideCategory;
}

export interface GuideCategory {
  guideCategoryId: number;
  name: string;
  private: boolean;
  isEditable: boolean;
}

export type QueryListParams = {
  categoryId: string;
  page?: number;
  size?: number;
  search?: string;
};

const getGuides = async ({
  page = 0,
  size = 10,
  search,
  categoryId,
}: QueryListParams): Promise<SingleCategoryResponse> => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
  };

  return (
    await EXPRESS_API.get(`guide-category/${categoryId}/category`, {
      params,
    })
  ).data as SingleCategoryResponse;
};

export const useSingleCategorizedGuidesQuery = (
  id: string,
  options: Omit<QueryListParams, 'categoryId'>,
  name?: string
) => {
  return useQuery(
    guidesKeyes.singleCategory(`${id}${name ? `-${name}` : ''}`),
    () => getGuides({ categoryId: id, ...options }),
    { enabled: false, refetchOnReconnect: true, refetchOnMount: true }
  );
};
