import React, { useState } from 'react';
import { CategoryDropdownLabel } from '../components/styles';
import { CategorizedGuide } from 'lib/api/guides/guideCategoryQuery';
import {
  ButtonPillSwitch,
  Dropdown,
  LoadingIndicator,
  NewModal,
} from 'lib/components';
import { Formik } from 'formik';
import {
  useEditableGuideCategoriesQuery,
  EditableGuides,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import { LibraryTabContent } from '../components/LibraryTabContent';
import { DefaultFolderIds } from 'lib/const/Folders';
import { UploadTabContent } from '../components/UploadTabContent';
import { SimplifiedVideo } from './ReplaceGuideModal';
import { ModalPrompt } from 'lib/components/modal';
import { IOption } from '../main/pages/constants';

type Props = {
  closeModalHandler: () => void;
  selectedGuideCategory?: Pick<
    CategorizedGuide,
    'guideCategoryId' | 'name'
  > | null;
  video?: SimplifiedVideo;
  isMultiple?: boolean;
  hasConfirmation?: boolean;
};

export type FormikValue = {
  guideCategoryId: number;
  folder: IOption;
};

export const AddGuideModal = ({
  closeModalHandler,
  selectedGuideCategory,
  video,
  hasConfirmation,
  isMultiple = true,
}: Props) => {
  const { data, isLoading } = useEditableGuideCategoriesQuery();
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [proceed, setProceed] = useState(false);

  const TABS = {
    LIBRARY: {
      value: 'Library',
      isHidden: false,
      isLocked: false,
      isLive: true,
      isDisabled: isTabDisabled,
    },
    UPLOAD: {
      value: 'Upload',
      isHidden: false,
      isLocked: false,
      isLive: true,
      isDisabled: isTabDisabled,
    },
  };

  const [activeTab, setActiveTab] = useState(TABS.LIBRARY.value);
  const [chosenVideosCount, setChosenVideosCount] = useState(0);

  const dropdownOptions = (data || []).map((category: EditableGuides) => ({
    value: category.guideCategoryId,
    label: category.name,
  }));
  const isLibraryTab = activeTab === TABS.LIBRARY.value;
  const isUploadTab = activeTab === TABS.UPLOAD.value;
  const hasPreselectedVideo = !!video?.videoId;
  const submitButtonText =
    !isMultiple && hasPreselectedVideo
      ? 'Reupload Video Guide'
      : `Add Video Guide${chosenVideosCount > 1 ? 's' : ''}`;
  const guideCategoryId = selectedGuideCategory?.guideCategoryId;
  const dropdownValue = !!dropdownOptions.length
    ? dropdownOptions[0]?.value
    : null;

  return (
    <>
      <NewModal
        closeModal={closeModalHandler}
        headerText={isMultiple ? 'Add Video Guide' : 'Reupload Video'}
        style={{
          content: {
            width: '675px',
            boxSizing: 'border-box',
          },
        }}
      >
        {isLoading ? (
          <div>
            <LoadingIndicator isLoading={true} />
          </div>
        ) : (
          <Formik
            initialValues={{
              guideCategoryId: guideCategoryId ?? dropdownValue,
              folder: {
                value: DefaultFolderIds.PRIMARY,
                label: 'Primary',
              },
            }}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => {
              return (
                <>
                  {isMultiple && (
                    <>
                      <CategoryDropdownLabel>Category</CategoryDropdownLabel>
                      <Dropdown
                        options={dropdownOptions}
                        value={
                          dropdownOptions.find(
                            item => item.value === values.guideCategoryId
                          ) || null
                        }
                        onChange={(item: IOption) => {
                          setFieldValue('guideCategoryId', item.value);
                        }}
                        creatable={false}
                        extendStyles={{
                          container: {
                            width: '100%',
                            margin: '10px 0 15px 0',
                          },
                        }}
                        isSearchable={false}
                        placeholder='Select Category'
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        menuPosition='absolute'
                        menuPlacement='bottom'
                      />
                    </>
                  )}
                  <ButtonPillSwitch
                    defaultValue={activeTab}
                    values={TABS}
                    onChange={(newTab: string) => setActiveTab(newTab)}
                    pillStyle={{ width: '49.2%' }}
                    textStyle={{ width: '49%' }}
                    disabledTextStyle={{ width: '49%' }}
                  />

                  <div>
                    {isLibraryTab && (
                      <LibraryTabContent
                        videoId={video?.videoId}
                        setIsConfirmModalOpen={
                          hasConfirmation ? setIsConfirmModalOpen : undefined
                        }
                        isMultiple={isMultiple}
                        submitButtonText={submitButtonText}
                        closeModalHandler={closeModalHandler}
                        proceed={proceed}
                        setChosenVideoCount={setChosenVideosCount}
                      />
                    )}
                    {isUploadTab && (
                      <UploadTabContent
                        closeModal={closeModalHandler}
                        setIsTabDisabled={setIsTabDisabled}
                        setChosenVideosCount={setChosenVideosCount}
                        videoId={video?.videoId}
                        setIsConfirmModalOpen={
                          hasConfirmation ? setIsConfirmModalOpen : undefined
                        }
                        proceedUpload={proceed}
                        isMultiple={isMultiple}
                        uploadButtonText={submitButtonText}
                      />
                    )}
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </NewModal>
      {isConfirmModalOpen && (
        <ModalPrompt
          title='Reupload Video'
          secondaryButtonText={'No, Keep Original'}
          primaryButtonText={'Yes, Replace'}
          primaryButtonType='destructive'
          content={
            <div>
              You will replace <b>"{video?.title}"</b>. This action cannot be
              undone.
            </div>
          }
          handleSubmit={() => {
            setProceed(true);
            setIsConfirmModalOpen(false);
          }}
          handleModalClose={() => {
            closeModalHandler();
            setIsConfirmModalOpen(false);
          }}
          style={{ zIndex: 999 }}
        />
      )}
    </>
  );
};
