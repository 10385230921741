import React from 'react';
import { Field, FieldProps } from 'formik';
import { SelectInput } from 'lib/components';
import { getResellers } from 'lib/api';
import { Reseller } from 'lib/context';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ResellerChangeConfirmationModal } from './ResellerChangeConfirmationModal';
import {
  FieldLabel,
  FormProps,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from 'app/pages/admin/components/AdminFormComponents';

export const Red = styled.label`
  color: ${theme.palette.primaryThemeColor};
`;

export const ResellerSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values, isNewCustomer } = props;

  const [loadingResellers, setLoadingResellers] = React.useState(false);
  const [resellers, setResellers] = React.useState<Reseller[]>([]);

  const [
    showResellerConfirmModal,
    setShowResellerConfirmModal,
  ] = React.useState(false);

  const [newReseller, setNewReseller] = React.useState<Reseller | undefined>(
    undefined
  );
  const [oldResellerId, setOldResellerId] = React.useState<number | undefined>(
    undefined
  );

  const loadResellers = async () => {
    setLoadingResellers(true);
    try {
      const [_resellers] = await getResellers(
        undefined,
        0,
        500,
        'ASC',
        'RESELLER_NAME'
      );

      setResellers(_resellers);
      setLoadingResellers(false);
    } catch (error) {
      setLoadingResellers(false);
    }
  };

  React.useEffect(() => {
    loadResellers();
  }, []);

  return (
    <>
      <Section>
        <SectionTitle>Reseller</SectionTitle>
        <RowWrapper>
          <Row>
            <FieldLabel>
              Reseller <Red>*</Red>
            </FieldLabel>
            <Field type='select' name='resellerId'>
              {({ field }: FieldProps) => {
                const { value, ...rest } = field;
                if (loadingResellers) return null;
                return (
                  <SelectInput
                    {...rest}
                    value={value}
                    disabled={isSubmitting}
                    onChange={event => {
                      const newResellerId = parseInt(event.target.value, 10);
                      const newReseller = resellers.find(
                        r => r.resellerId === newResellerId
                      );
                      setNewReseller(newReseller);
                      setShowResellerConfirmModal(true);
                      setOldResellerId(values.resellerId);
                      setFieldValue('resellerId', newReseller?.resellerId);
                    }}
                  >
                    {resellers.map((reseller: Reseller, index: number) => (
                      <option key={index} value={reseller.resellerId}>
                        {`${reseller.resellerName} - ${reseller.resellerId}`}
                      </option>
                    ))}
                  </SelectInput>
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      </Section>
      {!isNewCustomer && showResellerConfirmModal && (
        <ResellerChangeConfirmationModal
          customer={values}
          newResellerName={newReseller?.resellerName ?? ''}
          handleModalClose={confirm => {
            setShowResellerConfirmModal(false);
            if (confirm) {
              setFieldValue('resellerId', newReseller?.resellerId);
            } else {
              setFieldValue('resellerId', oldResellerId);
            }

            setNewReseller(undefined);
            setOldResellerId(undefined);
          }}
        />
      )}
    </>
  );
};
