import * as React from 'react';
import styled from 'styled-components/macro';
import {
  IoMdEye,
  IoMdEyeOff,
  IoIosCloseCircle,
  IoMdCheckmarkCircle,
} from 'react-icons/io';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Spinner } from 'lib/components';
import { useAuth } from 'lib/context';
import { verifyAndResetPassword } from 'lib/api';
import { AxiosError } from 'axios';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { Button } from 'react-covideo-common';

interface MatchParams {
  token: string;
}

const Layout = styled.div`
  height: 100vh;
  color: #4e5461;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ErrorMsg = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  label {
    margin: 0 20px 16px 20px;
  }
`;

const Image = styled.img`
  padding-top: 30px;
  padding-bottom: 30px;
  cursor: pointer;
`;

const Input = styled.input`
  margin: 5px 20px 16px 20px;
  background: transparent;
  border: 1px solid #001b53;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  display: block;
  height: 38px;
  padding: 6px 12px;
  font-size: 18px;
  outline: none !important;
  max-width: 335px;
  width: 100%;

  &::placeholder {
    color: white;
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  width: 100vw;
`;

const ParaHeader = styled.div`
  font-size: 14px;
  padding: 25px;
  text-align: justify;
`;

const EyeField = styled.div`
  position: absolute;
  right: 30px;
  top: 40px;
  cursor: pointer;
`;

const ValidationContent = styled.div`
  padding: 0 20px 16px 20px;
  .single-validator {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .active {
      color: green;
    }
    .active--false {
      color: red;
    }
  }
`;

const ButtonContainer = styled.div`
  padding: 0 20px 16px 20px;
`;

const RequiredField = styled.span`
  color: red;
`;

const Paragraph = styled.p`
  font-size: 16px;
`;
const Bold = styled.b``;

export const ResetContent = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [passwordView, setPasswordView] = React.useState('password');
    const [validator, setValidator] = React.useState<any>({
      character: 0,
      case: 0,
      number: 0,
    });
    const { whitelabel } = useAuth();

    const logoSrc =
      'https://' +
      whitelabel?.domainV2 +
      whitelabel?.contentPath +
      `${
        whitelabel?.name === WHITELABEL_NAME.COVIDEO
          ? '/images/reset-password-logo.svg'
          : '/images/logo-secondary.svg'
      }`;

    const { token } = props.match.params;
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        setLoading(true);
        await verifyAndResetPassword({ token, password });
        setLoading(false);
        window.location.href = 'https://www.covideo.com/login/';
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };

    const handleChange = (value: string) => {
      setPassword(value);
      const validateObj = { character: 1, case: 1, number: 1 };
      if (value.length > 8) validateObj.character = 2;
      if (/\d/.test(value)) validateObj.number = 2;
      if (/[A-Z]/.test(value)) validateObj.case = 2;
      setValidator(validateObj);
    };

    React.useEffect(() => {
      if (!token) {
        window.location.href = 'https://www.covideo.com/login/';
      }
    }, []);

    const getErrorMessageForDisplay = (error: AxiosError) => {
      if (
        error.response?.data?.message === 'Invalid token' ||
        error.response?.data?.message === 'Password reset link is expired'
      ) {
        return (
          <ErrorMsg>
            <Paragraph>
              <Bold>Link Expired!</Bold>
            </Paragraph>
            <Paragraph>
              To reset your password, return to login page and select{' '}
              <Bold>Forgot Password</Bold> to send a new email.
            </Paragraph>
          </ErrorMsg>
        );
      }

      return <ErrorMsg>{error.message || 'Something went wrong!'}</ErrorMsg>;
    };

    return (
      <Layout>
        <Wrapper>
          <LogoWrapper>
            <a href={whitelabel?.homepage}>
              <Image src={logoSrc} />
            </a>
          </LogoWrapper>
          <h3 style={{ color: '#001B53' }}>Activate Covideo Account</h3>
          <Form onSubmit={onSubmit}>
            {error && <ErrorMsg>{getErrorMessageForDisplay(error)}</ErrorMsg>}
            <ParaHeader>
              Set up your password for Covideo account. Make sure to include at
              least:
            </ParaHeader>
            <ValidationContent>
              <div className='single-validator'>
                {validator.character != 2 ? (
                  <IoIosCloseCircle
                    className={validator.character == 1 ? 'active--false' : ''}
                  />
                ) : (
                  <IoMdCheckmarkCircle className='active' />
                )}
                &nbsp; 8+ characters
              </div>
              <div className='single-validator'>
                {validator.case != 2 ? (
                  <IoIosCloseCircle
                    className={validator.case == 1 ? 'active--false' : ''}
                  />
                ) : (
                  <IoMdCheckmarkCircle className='active' />
                )}
                &nbsp; 1 upper-case letter
              </div>
              <div className='single-validator'>
                {validator.number != 2 ? (
                  <IoIosCloseCircle
                    className={validator.number == 1 ? 'active--false' : ''}
                  />
                ) : (
                  <IoMdCheckmarkCircle className='active' />
                )}
                &nbsp; 1 number
              </div>
            </ValidationContent>
            <div style={{ position: 'relative' }}>
              <label>
                Enter password here <RequiredField>*</RequiredField>
              </label>
              <Input
                name='password'
                placeholder='Password'
                type={passwordView}
                required={true}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.value)
                }
              />
              <EyeField
                onClick={() => {
                  setPasswordView(
                    passwordView == 'password' ? 'text' : 'password'
                  );
                }}
              >
                {passwordView == 'password' ? <IoMdEyeOff /> : <IoMdEye />}
              </EyeField>
            </div>
            <ButtonContainer>
              <Button
                text={'Set Password and Log In'}
                type='submit'
                disabled={Object.keys(validator).some(
                  (e: any) => validator[e] != 2
                )}
              />
            </ButtonContainer>
            <Row>{loading ? <Spinner /> : null}</Row>
          </Form>
        </Wrapper>
      </Layout>
    );
  }
);
