import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

export interface ICustomReportCreateReccuringPayload {
  frequency: string;
  deliveryTime: string;
  recipients: string[];
  reportId: number;
}

export interface ICustomReportReccuringResponse {
  frequency: string;
  deliveryTime: string;
  recipients: string[];
  reportId: string;
  lastDate: string;
  recurringId: number;
}

const createReccuringReport = async (
  payload: ICustomReportCreateReccuringPayload
): Promise<ICustomReportReccuringResponse> => {
  const { reportId, ...rest } = payload;
  const response = await EXPRESS_API.post(
    `/custom-reports/${reportId}/recurrings`,
    {
      ...rest,
    }
  );
  return response.data;
};

export const useCreateReccuringReportMutation = () => {
  return useMutation(createReccuringReport, {
    onSuccess: () => {
      successToast({ title: 'Reccuring report created successfully' });
    },
    onError: () => {
      errorToast({ title: 'Something went wrong creating reccuring' });
    },
  });
};
