import React from 'react';
import { School, Player } from './index.styled';
import { IHelpDeskItems, ItemsTypes, EHelpDesk } from './utils';

const common = (key: string, type: ItemsTypes, icon: JSX.Element) => ({
  id: key,
  type: type,
  icon: icon,
});

export const items: IHelpDeskItems = {
  //home
  [EHelpDesk.VIDEOS]: [
    {
      ...common('videos_1', ItemsTypes.LINK, <School />),
      title: 'A walkthrough of your Covideo library',
      url:
        'https://support.covideo.com/en/articles/4554477-how-do-i-navigate-my-video-library',
    },
    {
      ...common('videos_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Video Library',
      url: 'https://videos.covideo.com/videos/71305_3_j14tu1zsam1603210470.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_49mrl3b1dlh1673863102.mp4',
    },
  ],
  //record/Record_Home
  [EHelpDesk.RECORDED]: [
    {
      ...common('records_1', ItemsTypes.LINK, <School />),
      id: 'records_1',
      title: 'Recording your first Covideo',
      url:
        'https://support.covideo.com/en/articles/4557886-how-do-i-record-a-video-from-my-online-account',
    },
    {
      ...common('records_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Record a Covideo',
      url: 'https://videos.covideo.com/videos/71305_3_f15fc9p81w1612371501.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_2amiqqxkht1673863375.mp4',
    },
  ],
  ///classic/edit-video/id/analytics
  [EHelpDesk.ANALYTICS]: [
    {
      ...common('analytics_1', ItemsTypes.LINK, <School />),
      id: 'analytics_1',
      title: 'A look into video analytics',
      url:
        'https://support.covideo.com/en/articles/4553669-what-kind-of-analytics-can-i-get-on-my-covideos',
    },
    {
      ...common('analytics_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Video Analytics',
      url: 'https://videos.covideo.com/videos/71305_3_lxclgpjxxv1603210115.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_88rhhqx5e2k1673863208.mp4',
    },
  ],
  [EHelpDesk.WEBSITE_OVERLAY]: [
    {
      ...common('website_overlay_1', ItemsTypes.LINK, <School />),
      title: 'What is Website Overlay',
      url:
        'https://support.covideo.com/en/articles/4557873-what-is-website-overlay-and-how-do-i-use-it',
    },
    {
      ...common('website_overlay_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Website Overlay',
      url:
        'https://videos.covideo.com/videos/71305_3_wnvt6jy9e8h1616783599.mp4',
    },
  ],
  //design/calls-to-action
  [EHelpDesk.CALLS_TO_ACTIONS]: [
    {
      ...common('calls_to_actions_1', ItemsTypes.LINK, <School />),
      title: 'Creating Calls To Action Buttons',
      url:
        'https://support.covideo.com/en/articles/4557815-how-do-i-create-edit-my-call-to-action-buttons',
    },
    {
      ...common('calls_to_actions_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Calls To Action',
      url:
        'https://videos.covideo.com/videos/71305_3_04szuzasptk1631559083.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_e782xrfbrq1673863112.mp4',
    },
  ],
  [EHelpDesk.LANDING_PAGES]: [
    {
      ...common('landing_pages_1', ItemsTypes.LINK, <School />),
      title: 'Setting up a Landing Page',
      url:
        'https://support.covideo.com/en/articles/4557836-how-do-i-set-up-my-default-video-landing-page',
    },
    {
      ...common('landing_pages_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Landing Page',
      url: 'https://videos.covideo.com/videos/71305_3_bxn49eteg11633373909.mp4',
    },
  ],
  [EHelpDesk.LANDING_PAGES_BUILDER]: [
    {
      ...common('landing_pages_builder_1', ItemsTypes.LINK, <School />),
      title: 'Using the Landing Page Builder',
      url:
        'https://support.covideo.com/en/articles/5613029-how-to-use-the-video-landing-page-builder',
    },
    {
      ...common('landing_pages_builder_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Landing Page Builder',
      url: 'https://videos.covideo.com/videos/71305_3_bxn49eteg11633373909.mp4',
    },
  ],
  //users/list
  [EHelpDesk.MANAGE_USERS]: [
    {
      ...common('manage_users_1', ItemsTypes.MODAL, <Player />),
      title: 'How to: Manage Users',
      url:
        'https://videos.covideo.com/videos/71305_3_4q2m5s054yf1661877886.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_y7z1vpjnns1673525489.mp4',
    },
  ],
  //reports/overview
  [EHelpDesk.MY_STATS]: [
    {
      ...common('my_stats_1', ItemsTypes.LINK, <School />),
      title: 'How to use advanced reporting',
      url:
        'https://support.covideo.com/en/articles/5253614-how-do-i-use-individual-reporting',
    },
    {
      ...common('my_stats_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Advanced reporting for individuals',
      url: 'https://videos.covideo.com/videos/71305_3_le4papyecm1635363364.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_8kgul4aczv1673863353.mp4',
    },
  ],
  //reports/users
  [EHelpDesk.MY_TEAM]: [
    {
      ...common('my_team_1', ItemsTypes.LINK, <School />),
      title: 'How to use Admin reporting',
      url:
        'https://support.covideo.com/en/articles/5297724-how-do-i-use-admin-reporting',
    },
    {
      ...common('my_team_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Advanced reporting for Admins',
      url: 'https://videos.covideo.com/videos/71305_3_zwzju3to551638816632.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_i3f7adp76rk1673863270.mp4',
    },
  ],
  //classic/edit-video/:id/captions
  [EHelpDesk.CAPTIONS]: [
    {
      ...common('captions_1', ItemsTypes.LINK, <School />),
      title: 'How to enable and use Captions',
      url:
        'https://support.covideo.com/en/articles/3693051-how-do-i-use-captions',
    },
    {
      ...common('captions_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Captions',
      url: 'https://videos.covideo.com/videos/71305_3_s8bpww567v1603210166.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_a3c2i8bhc0l1673863032.mp4 ',
    },
  ],
  //classic/edit-video/id/trim
  [EHelpDesk.TRIMMING]: [
    {
      ...common('trimming_1', ItemsTypes.LINK, <School />),
      title: 'How to trim your  video',
      url:
        'https://support.covideo.com/en/articles/4840588-how-do-i-trim-my-video',
    },
    {
      ...common('trimming_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Trimming',
      url: 'https://videos.covideo.com/videos/71305_3_u1otlk81fk1603210376.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_18691a4e991673525299.mp4',
    },
  ],
  //classic/edit-video/16770828/annotations
  [EHelpDesk.ANNOTATIONS]: [
    {
      ...common('annotations_1', ItemsTypes.LINK, <School />),
      title: 'How to add video annotations',
      url:
        'https://support.covideo.com/en/articles/4707647-how-to-add-video-annotations',
    },
    {
      ...common('annotations_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Using annotations',
      url: 'https://videos.covideo.com/videos/71305_3_2985m7v3sz1649343547.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_gm71vygw9y1673863138.mp4',
    },
  ],
  //classic/edit-video/id/security
  [EHelpDesk.SECURITY]: [
    {
      ...common('security_1', ItemsTypes.LINK, <School />),
      title: 'How to use the password protect feature',
      url:
        'https://support.covideo.com/en/articles/4986293-how-to-create-a-password-for-your-video',
    },
    {
      ...common('security_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Video passwords',
      url: 'https://videos.covideo.com/videos/71305_3_o2jaeqr3fo1617042121.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_s9zmllpehi1673525310.mp4',
    },
  ],
  //files
  [EHelpDesk.FILES]: [
    {
      ...common('files_1', ItemsTypes.LINK, <School />),
      title: 'How to use Covideo Files',
      url:
        'https://support.covideo.com/en/articles/5585733-how-to-use-covideo-files',
    },
    {
      ...common('files_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Files',
      url:
        'https://videos.covideo.com/videos/71305_3_2s6zxrkakkk1631823039.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_ur2oc19usn1673525601.mp4',
    },
  ],
  //boards
  [EHelpDesk.BOARDS]: [
    {
      ...common('boards_1', ItemsTypes.LINK, <School />),
      title: 'How to use Covideo Boards',
      url:
        'https://support.covideo.com/en/articles/5586459-how-to-use-covideo-boards',
    },
    {
      ...common('boards_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Boards',
      url: 'https://videos.covideo.com/videos/71305_3_bso2ezjgp31631823047.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_zae01ln3yy1673523996.mp4',
    },
  ],
  [EHelpDesk.CONTACTS]: [
    {
      ...common('contacts_1', ItemsTypes.LINK, <School />),
      title: 'Importing contacts into your Covideo account',
      url:
        'https://support.covideo.com/en/articles/4558042-how-can-i-import-my-contacts-to-covideo',
    },
    {
      ...common('contacts_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Importing multiple contacts',
      url:
        'https://videos.covideo.com/videos/71305_3_ibcl8tm6q7i1603210258.mp4',
    },
  ],
  //classic/merge?videos?id, id
  [EHelpDesk.MERGE]: [
    {
      ...common('merge_1', ItemsTypes.LINK, <School />),
      title: "How to Use Covideo's Merge Feature",
      url:
        'https://support.covideo.com/en/articles/4612532-how-to-use-covideo-s-merge-feature',
    },
    {
      ...common('merge_2', ItemsTypes.MODAL, <Player />),
      title: 'How to: Merge videos',
      url: 'https://videos.covideo.com/videos/71305_3_fwveanuwoy1627929779.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_mo7w6xgg41673863126.mp4',
    },
  ],
  [EHelpDesk.WHEELS]: [
    {
      ...common('wheels_1', ItemsTypes.LINK, <School />),
      title: 'How To Use WheelsTV in Covideo',
      url:
        'https://support.covideo.com/en/articles/4612493-how-to-use-wheelstv-video-content-in-covideo',
    },
    {
      ...common('wheels_2', ItemsTypes.MODAL, <Player />),
      title: 'How To: Wheels TV',
      url: '',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_v2la5v747q1673525661.mp4',
    },
  ],
  [EHelpDesk.REACTIONS]: [
    {
      ...common('reactions_1', ItemsTypes.LINK, <School />),
      title: 'How To Use Reactions & Comment',
      url:
        'https://support.covideo.com/en/articles/6694700-how-do-i-use-reactions-and-comments',
    },
    {
      ...common('reactions_2', ItemsTypes.MODAL, <Player />),
      title: 'How To: Reactions & Comment',
      url: 'https://videos.covideo.com/videos/71305_3_7rm4lajgmt1666623946.mp4',
      automotiveUrl:
        'https://videos.covideo.com/videos/150871_3_l13w824jfn1673525672.mp4',
    },
  ],
};
