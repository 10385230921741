import React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';
import { Modal } from 'lib/components';
import { updateDefaultUser, fetchCustomerUsers } from 'lib/api';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

type Props = {
  handleModalClose: (refresh?: boolean) => void;
  chatOwnerName?: string;
  userId: string | number;
  isDefaultUser: boolean;
  customerId: string;
  defaultUser?: string;
};

export const SetDefaultModal = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState<any>([]);
  const [selectedUser, setSelectedUser] = React.useState('');

  const { handleModalClose, userId, isDefaultUser, customerId, defaultUser } =
    props;

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const setDefault = async () => {
    try {
      setLoading(true);
      await updateDefaultUser({
        userId: +selectedUser || +userId,
      });
      setLoading(false);
      successToast({ title: 'Default settings updated!' });
      handleModalClose(true);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isDefaultUser) {
      fetchCustomerUsers(customerId).then((data: any) => {
        setUsers(data);
        setLoading(false);
      });
    }
  }, [isDefaultUser]);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            {isDefaultUser
              ? 'Remove and Add New Default User'
              : 'Overwrite current Default User?'}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {isDefaultUser ? (
            <>
              If you continue, <b>{defaultUser}</b> won’t be Default User
              anymore. Please, select another user to take on the role.
              <Row>
                <Select
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      height: '40px',
                      width: '100%',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    container: () => ({ width: '100%' }),
                    singleValue: (base, state) => ({
                      ...base,
                      opacity: state.selectProps.menuIsOpen ? 0.1 : 1,
                    }),
                    menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
                  }}
                  options={users}
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                  menuPlacement={'bottom'}
                  // components={{
                  //   DropdownIndicator: props => {
                  //     return (
                  //       <SearchIconWrapper>
                  //         <IoMdSearch />
                  //       </SearchIconWrapper>
                  //     );
                  //   },
                  // }}
                  getOptionValue={option => option.id}
                  getOptionLabel={option =>
                    `${option.firstName} ${option.lastName}`
                  }
                  value={users.find((o: any) => {
                    return o.id == selectedUser;
                  })}
                  onChange={(option: any) => {
                    setSelectedUser(option.id);
                  }}
                  placeholder='Select User'
                />
              </Row>
            </>
          ) : (
            `If you continue, ${defaultUser} won’t be Default User anymore.`
          )}
        </Content>
        <Footer>
          {isDefaultUser ? (
            <Button
              text='Save New Default User'
              onClick={setDefault}
              disabled={loading}
            />
          ) : (
            <Button
              text='Yes, Overwrite'
              variant='destructive'
              onClick={setDefault}
              disabled={loading}
            />
          )}
        </Footer>
      </div>
    </Modal>
  );
};
