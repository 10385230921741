import styled from 'styled-components/macro';
import { ReactComponent as RestartSvg } from './restart-icon.svg';

const RestartIcon = styled(RestartSvg)`
  width: ${props => props.width || '14px'};
  height: ${props => props.height || '14px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default RestartIcon;
