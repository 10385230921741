import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import { MdOutlineChevronRight } from 'react-icons/md';
import { TabsManager } from 'app/pages/leads/components';
import LinkExternalIcon from 'lib/images/LinkExternalIcon';
import { ItemDetails, MediaGallery, ItemVideosRecorded } from '../components';
import { AutomotiveRole } from 'lib/const';
import { useGetInventoryItem } from 'lib/api/inventoryItem';
import { NotFound } from 'app/pages/notFound/NotFound';
import { useVideoMoveCopy } from 'lib/api/videos/useVideoMoveCopy';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';

const Container = styled.div`
  width: 1216px;
  max-width: 100%;
  margin: 64px auto 0 auto;
  padding: 32px 0;
  box-sizing: border-box;
  min-height: calc(100vh - 112px);
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span {
    color: ${theme.palette.gray80};
    font-weight: normal;
    font-size: 16px;
    margin-left: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: ${theme.palette.gray100};
`;

const MediaGalleryWrapper = styled.div`
  width: 800px;
`;

enum TABS {
  DETAILS,
  VIDEOS_RECORDED,
  VIDEOS_SENT,
}

interface MatchParams {
  itemId: string;
}

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const { itemId } = props.match.params;
  const {
    data: item,
    isLoading: loading,
    refetch: refreshInventoryItem,
  } = useGetInventoryItem({
    itemId,
  });

  const { mutateAsync: editVideoRequestMutation } =
    useEditVideoRequestMutation();
  const [videoIdsToUpdate, setVideoIdsToUpdate] = React.useState<string[]>([]);

  const { userData } = useAuth();

  const { mutate: videoMoveMutation, isLoading } = useVideoMoveCopy();

  const history = useHistory();

  const [activeTab, setActiveTab] = useState(TABS.DETAILS);

  const goToList = () => history.push(`/inventory`);

  const goToExternalUrl = (url: string) => window.open(url, '_blank');

  const shareWithCompany = async (videoId: string, videoRequestId: string) => {
    videoIdsToUpdate.push(videoId);
    setVideoIdsToUpdate(videoIdsToUpdate);
    const obj = {
      videoId,
      copy: true,
      videoFolder: 'Company',
      successMessage: 'Shared with company!',
      itemId: Number(itemId),
    };
    try {
      await videoMoveMutation(obj);
      await editVideoRequestMutation({
        videoRequestId: videoRequestId,
        data: {
          sharedWithCompany: true,
        },
      });
      await refreshInventoryItem();
      const indexToRemove = videoIdsToUpdate.indexOf(videoId);
      videoIdsToUpdate.splice(indexToRemove, 1);
    } catch (err) {
      console.log('err', err);
    }
  };

  const recordedVideos = item?.videoRequests ? item.videoRequests.length : 0;
  const tabs = [
    { value: TABS.DETAILS, label: 'Specs & Media' },
    {
      value: TABS.VIDEOS_RECORDED,
      label: `${recordedVideos || 0} Recorded Video${
        recordedVideos === 1 ? '' : 's'
      }`,
    },
  ];

  const isSalesManager =
    userData.automotiveRole === AutomotiveRole.SALES_MANAGER;

  if (loading) {
    return (
      <Container>
        <LoadingIndicator isLoading={loading} height='300px' />
      </Container>
    );
  }

  if (!item) {
    return <NotFound />;
  }

  return (
    <Container>
      <>
        <Breadcrumbs>
          <Button
            variant='transparent'
            onClick={() => goToList()}
            text='Inventory'
          />

          <MdOutlineChevronRight
            size={16}
            color={theme.palette.covideoBlue100}
          />
          <span>{item.title}</span>
        </Breadcrumbs>
        <Header>
          <Title>{item.title}</Title>
          {item.detailsPageUrl && (
            <Button
              text='Vehicle Details Page'
              variant='secondary'
              onClick={() => goToExternalUrl(item.detailsPageUrl)}
              icon={
                <LinkExternalIcon
                  width='18px'
                  height='18px'
                  color={theme.palette.blue100}
                />
              }
            />
          )}
        </Header>
        {isSalesManager && (
          <TabsManager
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabTitlePadding='0px 24px 2px 24px'
            tabTitleMargin='0'
            roundedBorder={false}
          />
        )}
        {activeTab === TABS.DETAILS && (
          <Row>
            <MediaGalleryWrapper>
              {item.media?.length ? (
                <MediaGallery media={item.media} />
              ) : (
                <>No media to show...</>
              )}
            </MediaGalleryWrapper>
            <ItemDetails item={item} />
          </Row>
        )}
        {activeTab === TABS.VIDEOS_RECORDED && (
          <ItemVideosRecorded
            item={item}
            shareWithCompany={shareWithCompany}
            isLoading={isLoading}
            videoIdsToUpdate={videoIdsToUpdate}
          />
        )}
      </>
    </Container>
  );
});
