import {
  GetCtaSetResponse,
  InteractiveCTA,
  SetCTAAsDefaultResponse,
  updateUser,
} from 'lib/api';
import {
  deleteCTASet,
  getCTASets,
  setCTASetAsDefault,
  setLockForSocialLink,
  setSocialLinks,
} from 'lib/api/designApi';
import { ModalDelete } from 'lib/components';
import { Dropdown } from 'lib/components/dropdown/Dropdown';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { ModalCTASetHandler } from 'lib/components/modal';
import { ModalCtaSetUsers } from 'lib/components/modal/ModalCtaSetUsers';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useAuth } from 'lib/context';
import { useSocialLinksQuery } from 'lib/api/socialMediaLinks/useSocialLinksQuery';
import {
  checkIfFeatureIsEnabled,
  PackageNameLabel,
  productFeature,
} from 'lib/utils/productFeature';
import { wrapWithHttp } from 'lib/utils/functions';
import { isEqual, sortBy } from 'lodash';
import * as React from 'react';
import { BsPlus } from 'react-icons/bs';
import { IoMdLock, IoMdSave } from 'react-icons/io';
import { MoonLoader } from 'react-spinners';
import styled, { useTheme } from 'styled-components/macro';
import CTASetsList, { CTASetListItem, ModalState } from './ctaSetsList';
import { InteractiveCTAs } from './InteractiveCTAs';
import SocialLinks, { FormFields, getFormattedData } from './SocialLinks';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { useQueryClient } from 'react-query';
import { EXIT_LINKS_QUERY } from 'lib/const/SendAndShare';
import selectors from '../../../../cypress/selectors';
import { Button } from 'react-covideo-common';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { ModalSwitchCta } from './components/modal/ModalSwitchCta';

// Interfaces and Types
type ListType = 'ctas' | 'social' | 'interactiveCTAs';
type FilterOption = {
  value: string;
  label: string;
};
interface SetAsDefaultParams {
  id: number;
  isDefault: boolean;
  items: CTASetListItem[];
  setItems: React.Dispatch<React.SetStateAction<CTASetListItem[]>>;
}

type RunFetchParams = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setItems: React.Dispatch<React.SetStateAction<CTASetListItem[]>>;
};

// Styled Components
const TabsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin: 21px 0 0 0;
  padding: 0;
  border-bottom: 1px solid #eeeff2;
`;

const Tab = styled.li<any>`
  position: relative;
  display: flex;
  align-items: center;

  font-weight: 600;
  color: ${props => (props.isSelected ? props.color : '#9297a2')};
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  height: calc(22px + 24px);
  margin-right: 32px;
  list-style: none;
  cursor: ${props => (props.isSelected ? 'normal' : 'pointer')};
`;

const TabSelected = styled.div<any>`
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 2px solid ${props => props.color};
  border-bottom: 2px solid ${props => props.color};
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
`;

const AddButtonContainer = styled.div`
  width: 119px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  margin-top: 32px;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  position: fixed;
  height: 50vh;
  width: 50vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModifiersRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  .dropdown {
    width: 208px;
    & > div {
      width: 208px;
      border-radius: 4px;
    }
  }
`;

// Constants
const Filters: FilterOption[] = [
  {
    label: 'Show All',
    value: 'Show All',
  },
  {
    label: 'Company Shared',
    value: 'Company Shared',
  },
  {
    label: 'Only Mine',
    value: 'Only Mine',
  },
];

// Component Rendering and State
const CallsToAction = () => {
  const { userData, invalidateUser } = useAuth();

  const [loading, setLoading] = React.useState<boolean>(true);
  const urlHash = window.location.hash || '';
  const [listType, setListType] = React.useState<ListType>(
    urlHash === '#socialLinks' ? 'social' : 'ctas'
  );
  const [isCTASubmitting, setIsCTASubmitting] = React.useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = React.useState(Filters[0]);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [modalState, setModalState] = React.useState<ModalState>({
    mode: 'none',
  });
  const [isSocialLinkDirty, setIsSocialLinkDirty] = React.useState(false);
  const [isInteractiveCtaDirty, setIsInteractiveCtaDirty] =
    React.useState(false);
  const [isSocialLinkValid, setIsSocialLinkValid] = React.useState(false);
  const [showModalSwitchCta, setShowModalSwitchCta] = React.useState(false);
  const [nextTab, setNextTab] = React.useState<ListType>(listType);
  const [updatedData, setUpdatedData] = React.useState<FormFields>(
    {} as FormFields
  );
  const [items, setItems] = React.useState<CTASetListItem[]>([]);
  const [interactiveCTAsFormState, setInteractiveCTAsFormState] =
    React.useState([]);
  const themes: any = useTheme();
  const queryClient = useQueryClient();
  const history = useHistory();

  const switchFilters = (f: FilterOption) => setCurrentFilter(f);
  const switchLists = async (t: ListType) => {
    if (isSocialLinkDirty || isInteractiveCtaDirty) {
      setNextTab(t);
      setShowModalSwitchCta(true);
      return;
    }
    setListType(t);
  };
  const onCloseModal = () => {
    setModalState({ mode: 'none', data: undefined });
    refetchSocialLinks();
    initializePages({ setLoading, setItems });
  };
  const onItemDeletion = handleCTASetDelete(
    setModalLoading,
    onCloseModal,
    setItems,
    items
  );
  const onSubmitInteractiveCTAs = async () => {
    setIsCTASubmitting(true);
    const isError = interactiveCTAsFormState.some(
      (cta: InteractiveCTA) =>
        (cta.url === '' && cta.linkValue === '' && cta.fileTitle === '') ||
        cta.title === ''
    );
    if (isError) {
      errorToast({
        title:
          'Display Name and URL/File fields are required. Changes will not be saved',
      });
      setIsCTASubmitting(false);
      return;
    }
    try {
      const interactiveCTAs = interactiveCTAsFormState.map(
        (cta: InteractiveCTA) => {
          if (cta.type === 'url') {
            return {
              ...cta,
              url: wrapWithHttp(cta.url),
            };
          }
          if (cta.type === 'url700Credit') {
            return {
              ...cta,
              linkValue: wrapWithHttp(cta.linkValue),
            };
          }
          return cta;
        }
      );

      const payload = {
        interactiveCTAs,
      };
      await updateUser(userData.userId, payload, true);
      await invalidateUser();
      successToast({ title: 'Interactive CTAs has been saved.' });
    } catch (error) {
      errorToast({
        title:
          'An error occurred saving your information, please try again later.',
      });
    }
    setIsCTASubmitting(false);
  };

  const filteredItems = getFilteredItems(items, currentFilter);

  React.useEffect(() => {
    initializePages({ setLoading, setItems });
  }, []);

  const { data: socialMediaLinks, refetch: refetchSocialLinks } =
    useSocialLinksQuery();

  React.useEffect(() => {
    if (listType === 'social') {
      refetchSocialLinks();
    }
  }, [listType]);

  React.useEffect(() => {
    if (userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails?.id) {
        const isCtaAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.CTAS
        );
        if (!isCtaAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, [userData.user]);

  const isCompanyAdmin = userData.isCompanyAdmin;

  const isInteractiveCtasEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.INTERACTIVE_CTAS
  );
  const updateSocialLinks = async (values: FormFields) => {
    const formValues = getFormattedData(values);
    const { lockSocialLinks, ...data } = formValues;
    const socialLinksRes = await setSocialLinks(data).catch(err => err);
    if (lockSocialLinks !== socialMediaLinks?.lockedByAdmin) {
      const lockRes = await setLockForSocialLink(lockSocialLinks).catch(
        err => err
      );
      if (lockRes instanceof Error) {
        errorToast({ title: 'Could not lock social links for all users.' });
      } else if (!lockRes.success) {
        errorToast({ title: 'Could not lock social links for all users.' });
      }
    }
    if (socialLinksRes instanceof Error) {
      errorToast({ title: 'An error occurred! Try again.' });
    } else {
      successToast({ title: 'Social Links successfully updated!' });
      const bc = new BroadcastChannel('covideo_social_link_channel');
      bc.postMessage(socialLinksRes?.data);
    }
  };
  return (
    <MainWrapper resetPadding>
      {modalState.mode === 'add' && (
        <ModalCTASetHandler
          type={modalState.mode}
          modalLoading={modalLoading}
          data={undefined}
          onClose={onCloseModal}
          isCompanyAdmin={isCompanyAdmin}
        />
      )}
      {modalState.mode === 'set_users' && (
        <ModalCtaSetUsers onClose={onCloseModal} data={modalState.data} />
      )}
      {modalState.mode === 'duplicate' && modalState.data && (
        <ModalCTASetHandler
          type={modalState.mode}
          modalLoading={modalLoading}
          data={modalState.data}
          onClose={onCloseModal}
          isCompanyAdmin={isCompanyAdmin}
        />
      )}
      {modalState.mode === 'edit' && modalState.data && (
        <ModalCTASetHandler
          type={modalState.mode}
          modalLoading={modalLoading}
          data={modalState.data}
          onClose={onCloseModal}
          isCompanyAdmin={isCompanyAdmin}
        />
      )}
      {modalState.mode === 'delete' && modalState.data && (
        <ModalDelete
          disabled={false}
          title={'Move CTA Set to recently deleted bin?'}
          orangeButtonText={'Keep CTA Set'}
          whiteButtonText={'Delete CTA Set'}
          text={`You are about to move the CTA Set "${modalState.data?.title}" to recently deleted bin. This CTA Set will be permanently deleted after 30 days.`}
          text1={''}
          hasConfirm={false}
          onClickOrangeButton={() => onCloseModal()}
          onClickWhiteButton={() => {
            onItemDeletion(modalState.data?.id);
            queryClient.invalidateQueries([EXIT_LINKS_QUERY]);
          }}
          handleModalClose={() => onCloseModal()}
        />
      )}
      <HelpDesk name={EHelpDesk.CALLS_TO_ACTIONS} />
      <RouteLeavingGuard
        when={true}
        navigate={path => {
          history.push(path);
        }}
        onConfirm={() => {
          if (listType === 'interactiveCTAs') {
            onSubmitInteractiveCTAs();
          } else if (listType === 'social') {
            if (isSocialLinkValid) {
              updateSocialLinks(updatedData);
            }
          }
        }}
        onDiscard={() => {}}
        shouldBlockNavigation={() => {
          return (
            isSocialLinkDirty || isSocialLinkValid || isInteractiveCtaDirty
          );
        }}
      />
      <HeaderWrapper>
        <Heading>Calls-to-Action</Heading>
        {listType === 'interactiveCTAs' && (
          <Button
            text={isCTASubmitting ? 'Saving' : 'Save changes'}
            onClick={onSubmitInteractiveCTAs}
            type='submit'
            disabled={
              isCTASubmitting ||
              isEqual(
                sortBy(userData.interactiveCTAs),
                sortBy(interactiveCTAsFormState)
              )
            }
            icon={<IoMdSave />}
          />
        )}
      </HeaderWrapper>
      <TabsWrapper>
        <Tab
          onClick={() => switchLists('ctas')}
          isSelected={listType === 'ctas'}
          color={themes.colors.primary[100]}
        >
          CTA Sets
          {listType === 'ctas' && (
            <TabSelected color={themes.colors.primary[100]} />
          )}
        </Tab>
        <Tab
          onClick={() => switchLists('social')}
          isSelected={listType === 'social'}
          color={themes.colors.primary[100]}
        >
          Social Links
          {listType === 'social' && (
            <TabSelected color={themes.colors.primary[100]} />
          )}
        </Tab>
        <Tab
          onClick={() =>
            isInteractiveCtasEnabled && switchLists('interactiveCTAs')
          }
          isSelected={listType === 'interactiveCTAs'}
          color={themes.colors.primary[100]}
        >
          {!isInteractiveCtasEnabled && (
            <ProductInfoTooltip
              width={'fit-content'}
              style={{
                left: '-30px',
                top: '40px',
              }}
              productFeatureId={productFeature.INTERACTIVE_CTAS}
              nextPlan={PackageNameLabel.TEAMS}
            >
              <IoMdLock /> Interactive CTAs
            </ProductInfoTooltip>
          )}
          {isInteractiveCtasEnabled && (
            <>
              Interactive CTAs
              {listType === 'interactiveCTAs' && (
                <TabSelected color={themes.colors.primary[100]} />
              )}
            </>
          )}
        </Tab>
      </TabsWrapper>
      {listType === 'ctas' && (
        <ModifiersRow>
          <Dropdown
            className={'dropdown'}
            value={currentFilter}
            onChange={value => switchFilters(value)}
            placeholder={'Select filter...'}
            options={Filters}
          />
          <AddButtonContainer>
            <Button
              text={'Add New'}
              variant='primary'
              icon={<BsPlus />}
              onClick={() => setModalState({ mode: 'add', data: undefined })}
              data-cy={selectors.ctaPage.new}
            />
          </AddButtonContainer>
        </ModifiersRow>
      )}
      <Content>
        {loading && (
          <LoaderWrapper>
            <MoonLoader
              size={64}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        {listType === 'ctas' && (
          <CTASetsList
            items={filteredItems}
            loading={loading}
            userId={userData.userId}
            isCompanyAdmin={isCompanyAdmin}
            setAsDefault={(id, isDefault) =>
              setAsDefault({ id, isDefault, items, setItems })
            }
            setModalState={setModalState}
            access={userData.access}
          />
        )}
        {listType === 'social' && socialMediaLinks !== undefined && (
          <SocialLinks
            data={{
              ...socialMediaLinks.data,
              lockedByAdmin: socialMediaLinks.lockedByAdmin,
            }}
            isCompanyAdmin={isCompanyAdmin}
            onSubmit={() => refetchSocialLinks()}
            setIsSocialLinkDirty={setIsSocialLinkDirty}
            setIsSocialLinkValid={setIsSocialLinkValid}
            setUpdatedData={setUpdatedData}
          />
        )}
        {listType === 'interactiveCTAs' && (
          <InteractiveCTAs
            interactiveCTAsData={userData.interactiveCTAs || []}
            hasDroplrAccess={userData.droplrAccess}
            setInteractiveCTAsFormState={setInteractiveCTAsFormState}
            credit700Url={userData.credit700Url}
            setIsInteractiveCtaDirty={setIsInteractiveCtaDirty}
          />
        )}
      </Content>
      {showModalSwitchCta && (
        <ModalSwitchCta
          onDelete={() => {
            setListType(nextTab);
            setIsInteractiveCtaDirty(false);
            setIsSocialLinkDirty(false);
            setShowModalSwitchCta(false);
          }}
          modalLoading={false}
          onClose={() => {
            setShowModalSwitchCta(false);
          }}
        />
      )}
    </MainWrapper>
  );
};

export default CallsToAction;

// Methods
function getFilteredItems(
  items: CTASetListItem[],
  currentFilter: FilterOption
) {
  let filteredItems = items;

  if (currentFilter.value === 'Company Shared') {
    filteredItems = items.filter(item => item.isCompany);
  } else if (currentFilter.value === 'Only Mine') {
    filteredItems = items.filter(item => !item.isCompany);
  }
  return filteredItems;
}

async function runFetch({ setLoading, setItems }: RunFetchParams) {
  setLoading(true);
  const res = await getCTASets({
    start: 0,
    limit: 0,
    search: '',
  }).catch(err => err);
  if (res instanceof Error) {
    setLoading(false);
    return;
  }
  const { linksets } = res as GetCtaSetResponse;
  const listItems = linksets.map(s => {
    const listItem = {} as CTASetListItem;
    listItem.id = s.id;
    listItem.title = s.title;
    listItem.isCompany = !!s.company;
    listItem.isDefault = !!s.defaultLinks;
    listItem.links = s.links;
    listItem.userId = s.userId.toString();
    return listItem;
  });
  setLoading(false);
  const sortedItems = listItems.sort((a, b) => {
    if (a.isDefault) {
      return -1;
    }
    if (!a.isDefault === !b.isDefault) {
      return 0;
    }
    return 1;
  });
  setItems(sortedItems);
}

async function setAsDefault(params: SetAsDefaultParams): Promise<void> {
  const { id, isDefault, items, setItems } = params;
  const response: SetCTAAsDefaultResponse = await setCTASetAsDefault(
    id,
    isDefault
  ).catch(err => err);
  if (response instanceof Error) {
    errorToast({
      title: 'Something went wrong setting Call-to-Action as default',
    });
    return;
  }
  if (!response.success) {
    return;
  }
  if (response.success && response.ctaSetId) {
    const newDefaultId = response.ctaSetId;
    const listItems = items.map(currentItem => {
      const listItem = { ...currentItem };
      if (listItem.id === newDefaultId) {
        listItem.isDefault = response.isDefault;
      } else {
        listItem.isDefault = false;
      }
      return listItem;
    });
    successToast({
      title: response.isDefault
        ? 'New default set successfully!'
        : 'CTA Set is no longer default!',
    });
    setItems(listItems);
  } else {
    errorToast({
      title: 'Something went wrong setting CTA Set as default',
    });
  }
}

async function initializePages({ setLoading, setItems }: any) {
  setLoading(true);
  await runFetch({ setLoading, setItems });
}

function handleCTASetDelete(
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onCloseModal: () => void,
  setItems: React.Dispatch<React.SetStateAction<CTASetListItem[]>>,
  items: CTASetListItem[]
) {
  return async (id?: number) => {
    if (!id) return;
    setModalLoading(true);
    const res = await deleteCTASet(id).catch(err => err);
    if (res instanceof Error) {
      errorToast({
        title: 'Something went wrong deleting Call-to-Action',
      });
      return;
    }
    if (!res.success) {
      errorToast({
        title: 'Something went wrong deleting Call-to-Action',
      });
      return;
    }

    setModalLoading(false);
    onCloseModal();
    setItems(items.filter(i => i.id !== id));
    successToast({ title: 'Call-to-Action deleted successfully' });
  };
}
