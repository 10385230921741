export const privacy = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const privacyOptions = [
  {
    value: privacy.PRIVATE,
    text: 'Private',
  },
  {
    value: privacy.PUBLIC,
    text: 'Public',
  },
];

export interface CustomizedState {
  videos: string[];
  category: number;
}

export interface IOption {
  value: number;
  label: string;
}
export type SelectedGuideCategoryType = {
  guideCategoryId: number;
  name: string;
  guidesCount: number;
  private: boolean;
  isEditable: boolean;
} | null;
