import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import VideoIcon from 'lib/images/VideoIcon';
import { CountDown } from 'lib/components/CountDown';
import PauseIcon from 'lib/images/pauseButton';
import RouteLeavingGuard from '../../../video/videoDetails/main/RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { MIME_TYPE, useMediaRecorder } from 'lib/hooks/useMediaRecorder';
import { MdVideocam } from 'react-icons/md';
import CheckmarkIcon from 'lib/images/CheckmarkIcon';
import { useAuth } from 'lib/context';
import { toMMSS } from '../../../../../lib/utils/functions';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import * as workerTimers from 'worker-timers';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

const FinishButtonWrapper = styled.div`
  margin-left: calc(50% - 48.5px - 150px);
`;

const Timer = styled.div`
  position: relative;
  top: -28px;
  left: 125px;
`;

type WrapperProps = {
  isTrial?: boolean;
};

const RecordBtnWrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 9;
  top: ${props => (props.isTrial ? '250px' : '250px')};
  margin-top: 32px;
`;

const ControlsContainer = styled.div`
  width: 150px;
`;

const QUALITY_MAP: { [key: string]: any } = {
  low: { width: { ideal: 640 }, height: { ideal: 360 } },
  standard: { width: { ideal: 1280 }, height: { ideal: 720 } },
  high: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

const DEFAULT_SETTINGS = {
  videoSource: { value: '' },
  videoQuality: 'standard',
  audioSource: { value: 'default' },
};

// Firefox 1.0+
//@ts-ignore
const isFirefox = typeof InstallTrigger !== 'undefined';

let countdownBeforeStart: any = null;

export const RecordCam = ({
  onRecordingStart,
  onRecordingEnd,
  uploadDisabled,
  virtualBackgroundUrl = null,
  showRecordingButton = true,
  setShowVirtualBackgroundButton,
  virtualBackgroundType,
  virtualBackgroundPrivacy,
  isVBEnabled,
  onRecordingUrlGeneration,
  setIsTeleprompterPlaying,
  useTeleprompter,
  handleGoToRecordHome,
}: any) => {
  const history = useHistory();
  const themes = useTheme();
  const videoTagPos = history.location.pathname.includes('/preview')
    ? undefined
    : 'absolute';
  const canvasRef = React.useRef<any>(null);
  const previewRef = useRef<any>(null);
  const backgroundRef = useRef<any>(null);
  const [countdown, setCountdown] = useState(3);
  const [isRecording, setIsRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(new Date());
  const [seconds, setSeconds] = useState(0);
  const [secondsBeforePause, setSecondsBeforePause] = useState(0);

  const settings = {
    videoSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoSource as any,
    videoQuality: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoQuality,
    audioSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).audioSource as any,
  };
  const [showCanvas, setShowCanvas] = React.useState(!!virtualBackgroundUrl);
  const [selfieSegmentation, setSelfieSegmentation] = React.useState<any>(null);
  const { userData } = useAuth();
  const [isCallbackRegistered, setIsCallbackRegistered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [vbEnable, setVbEnable] = React.useState(true);

  let audio: any;
  try {
    audio = { deviceId: settings.audioSource.value };
  } catch (e) {}
  if (!settings || !settings.audioSource || !settings.audioSource.value) {
    audio = false;
  }

  const quality =
    settings && settings.videoQuality && settings.videoQuality.value
      ? QUALITY_MAP[settings.videoQuality]
      : QUALITY_MAP.standard;

  const {
    status,
    startRecording,
    startRecordingForCanvas,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    previewStream,
    error,
  } = useMediaRecorder({
    video: {
      ...quality,
      frameRate: 24,
      deviceId: settings.videoSource && settings.videoSource.value,
    },
    audio,
    quality: settings.videoQuality,
    blobPropertyBag: { type: MIME_TYPE },
    mimeType: MIME_TYPE,
  });

  React.useEffect(() => {
    if (userData.user?.packageDetails?.id) {
      const status = checkIfFeatureIsEnabled(
        userData,
        productFeature.VIRTUAL_BACKGROUNDS
      );
      setVbEnable(status);
    }
  }, [userData]);

  let virtualBackgroundEnabled: boolean = vbEnable;

  React.useEffect(() => {
    if (virtualBackgroundUrl !== '' && !mediaBlobUrl && showCanvas) {
      const newBg = new Image();
      newBg.crossOrigin = 'Anonymous';
      newBg.src = virtualBackgroundUrl;
      if (
        virtualBackgroundType === 'custom' &&
        virtualBackgroundPrivacy === 'PUBLIC'
      ) {
        newBg.src += '?t=' + Date.now();
      }

      newBg.onload = () => {
        backgroundRef.current = newBg;
      };
    }
  }, [showCanvas, virtualBackgroundUrl]);

  React.useEffect(() => {
    localStorage.setItem('record_error', '');
    if (!settings.videoSource || !settings.videoSource.value) {
      localStorage.setItem(
        'record_error',
        'No camera detected. Please check your recording settings and make sure the camera is plugged in.'
      );
      handleGoToRecordHome();
    }
    return () => {
      closeAndClear();
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      return;
    }
    if (previewRef && previewRef.current && previewStream) {
      if (isVBEnabled) {
        loadMediapipe();
      }

      previewRef.current.srcObject = previewStream;
    }
  }, [previewStream, previewRef, isVBEnabled]);

  useEffect(() => {
    if (isRecording) {
      return;
    }
    if (
      previewStream &&
      virtualBackgroundUrl !== '' &&
      virtualBackgroundUrl != null &&
      !mediaBlobUrl
    ) {
      setShowCanvas(true);
    } else if (!virtualBackgroundUrl || mediaBlobUrl) {
      setShowCanvas(false);
      backgroundRef.current = null;
    }
  }, [virtualBackgroundUrl, previewStream, mediaBlobUrl]);

  useEffect(() => {
    let myInterval = workerTimers.setInterval(() => {
      if (isRecording && !paused && !countdown) {
        setSeconds(
          secondsBeforePause +
            (new Date().getTime() - recordingStartTime.getTime()) / 1000
        );
      }
    }, 1000);
    return () => {
      workerTimers.clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (
      userData &&
      userData.user?.packageDetails &&
      userData.user?.packageDetails?.maxLength &&
      Math.ceil(seconds) >= userData.user?.packageDetails?.maxLength
    ) {
      handleStopRecording();
    }
  }, [seconds]);

  const haltRecording = () => {
    if (isRecording && countdown > 0) {
      clearTimeout(countdownBeforeStart);
      setIsRecording(false);
      setCountdown(3);
      setShowVirtualBackgroundButton(true);
    }
  };

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = async () => {
    onRecordingEnd && onRecordingEnd();
    setIsRecording(false);
    setSecondsBeforePause(0);
    await stopRecording();
    try {
      //@ts-ignore
      const streams = [...window.streams];
      //@ts-ignore
      streams.filter(Boolean).forEach(s => stopStream(s));
      //@ts-ignore
      window.streams = [];
    } catch (e) {}
  };

  const handlePauseRecording = () => {
    pauseRecording();
    setSecondsBeforePause(seconds);
    setPaused(true);
    if (useTeleprompter) {
      setIsTeleprompterPlaying(false);
    }
  };

  const handleResumeRecording = () => {
    resumeRecording();
    setRecordingStartTime(new Date());
    setPaused(false);
    if (useTeleprompter) {
      setIsTeleprompterPlaying(true);
    }
  };

  React.useEffect(() => {
    if (error === 'permission_denied' || (isFirefox && error === undefined)) {
      handleGoToRecordHome();
    }
  }, [error]);

  React.useEffect(() => {
    if (countdown > 0 && isRecording) {
      setShowVirtualBackgroundButton(false);
      countdownBeforeStart = setTimeout(
        () => setCountdown(countdown - 1),
        1000
      );
    } else if (isRecording) {
      onRecordingStart && onRecordingStart();
      if (virtualBackgroundUrl && showCanvas) {
        try {
          startRecordingForCanvas(canvasRef.current.captureStream(30));
          if (window.Intercom) {
            window.Intercom('trackEvent', 'virtual-background-used');
          }
        } catch (ex) {
          console.log('EXCEPTION :: ', ex);
          startRecording();
        }
      } else {
        startRecording();
      }

      setRecordingStartTime(new Date());
    }
  }, [isRecording, countdown]);

  React.useEffect(() => {
    if (
      selfieSegmentation &&
      virtualBackgroundUrl &&
      !isCallbackRegistered &&
      previewRef.current &&
      previewStream
    ) {
      previewRef.current.srcObject = previewStream;
      inits();
    }
  }, [
    selfieSegmentation,
    virtualBackgroundUrl,
    isCallbackRegistered,
    previewRef,
    previewStream,
  ]);

  React.useEffect(() => {
    if (mediaBlobUrl) {
      onRecordingUrlGeneration(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);
  const inits = async () => {
    setIsLoading(true);
    setIsCallbackRegistered(true);
    const videoTrack = previewStream?.getVideoTracks()[0];
    if (
      !videoTrack ||
      !videoTrack.getSettings().width ||
      videoTrack.readyState !== 'live'
    ) {
      inits();
      return;
    }

    setTimeout(async () => {
      try {
        await selfieSegmentation.send({
          image: previewRef.current,
        });

        previewRef.current.requestVideoFrameCallback(processFrame);
        setIsLoading(false);
      } catch (ex) {
        await selfieSegmentation.reset();
        previewRef?.current?.requestVideoFrameCallback(processFrame);
        setIsLoading(false);
      }
    }, 250);
  };

  React.useEffect(() => {
    return () => {
      closeAndClear();
    };
  }, []);
  // TODO: handle this
  const closeAndClear = () => {
    //@ts-ignore
    const streams = [...window.streams];
    //@ts-ignore
    streams.filter(Boolean).forEach(s => stopStream(s));
    //@ts-ignore
    window.streams = [];
    setIsRecording(false);
    if (countdownBeforeStart) {
      clearTimeout(countdownBeforeStart);
    }
  };

  const stopStream = (s: any) => {
    try {
      s.getTracks().forEach((t: any) => t.stop());
    } catch (e) {}
  };

  const loadMediapipe = () => {
    if (selfieSegmentation) return;
    createSelfieSegmentation();
  };

  const processFrame = async () => {
    try {
      await selfieSegmentation.send({
        image: previewRef.current,
      });
    } catch (ex) {
      await selfieSegmentation.reset();
    }

    if (previewRef && previewRef.current) {
      previewRef.current.requestVideoFrameCallback(processFrame);
      return true;
    }
  };

  const createSelfieSegmentation = () => {
    const newSelfieSegmentation: any =
      selfieSegmentation ||
      new SelfieSegmentation({
        locateFile: (file: any) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}?t=${Date.now()}`;
        },
      });
    newSelfieSegmentation.setOptions({
      selfieMode: false,
      modelSelection: 0,
      effect: 'background',
    });
    newSelfieSegmentation.onResults(onResults);
    setSelfieSegmentation(newSelfieSegmentation);
  };

  function onResults(results: any) {
    if (!results || !results.image || !results.image.width) {
      return;
    }

    render(results.image, results.segmentationMask);
  }

  function render(image: any, segmentation: any) {
    let segmentationMask = segmentation;
    runPostProcessing(image, segmentationMask);
  }

  function runPostProcessing(image: any, segmentation: any) {
    clearCanvas();

    let ctx = canvasRef.current.getContext('2d');
    ctx.globalCompositeOperation = 'copy';
    ctx.filter = 'none';

    if (virtualBackgroundEnabled) {
      // TODO: Find alternative for blur in case of Safari
      ctx.filter = 'blur(4px)';
      drawSegmentationMask(segmentation);
      ctx.globalCompositeOperation = 'source-in';
      ctx.filter = 'none';
    }

    ctx.drawImage(
      image,
      0,
      0,
      previewRef.current.width,
      previewRef.current.height
    );

    if (backgroundRef && backgroundRef.current && backgroundRef.current.width) {
      applyVirtualBackground(backgroundRef.current);
    }

    ctx.restore();
  }

  function drawSegmentationMask(segmentation: any) {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    let ctx = canvasRef.current.getContext('2d');
    ctx.drawImage(
      segmentation,
      0,
      0,
      previewRef.current.width,
      previewRef.current.height
    );
  }

  function drawImageProp(
    ctx: any,
    img: any,
    x: any,
    y: any,
    w: any,
    h: any,
    offsetX?: any,
    offsetY?: any
  ) {
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }

    // select center offset
    offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
    offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r, // new prop. width
      nh = ih * r, // new prop. height
      cx,
      cy,
      cw,
      ch,
      ar = 1;

    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;
    nw *= ar;
    nh *= ar;

    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  function applyVirtualBackground(image: any) {
    if (!canvasRef || !canvasRef.current || !image || !image.width) {
      return;
    }

    let ctx = canvasRef.current.getContext('2d');
    ctx.globalCompositeOperation = 'destination-over';
    if (
      image.naturalHeight &&
      image.naturalWidth &&
      image.naturalHeight >= image.naturalWidth
    ) {
      drawImageProp(
        ctx,
        image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    } else {
      ctx.drawImage(
        image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }
  }

  function clearCanvas() {
    if (!canvasRef || !canvasRef.current || canvasRef.current.hidden) {
      return;
    }

    let ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  }

  const body = React.useMemo(
    () => (
      <>
        {!mediaBlobUrl && previewStream && (
          <>
            {vbEnable && (
              <LoadingIndicator
                isLoading={isLoading}
                text={'Applying Virtual Background'}
              />
            )}
            <video
              style={{ position: videoTagPos }}
              hidden={showCanvas}
              ref={previewRef}
              width={770}
              height={434}
              autoPlay
            />
            <canvas
              ref={canvasRef}
              hidden={!showCanvas}
              width={770}
              height={434}
              style={{ position: 'absolute' }}
            />
          </>
        )}
        {showRecordingButton ? (
          <RecordBtnWrapper isTrial={userData.trialAccount}>
            {status === 'recording' && (
              <ControlsContainer>
                {!paused && (
                  <Button
                    variant='secondary'
                    icon={<PauseIcon />}
                    text={'Pause'}
                    onClick={handlePauseRecording}
                  />
                )}
                {paused && (
                  <Button
                    variant='red'
                    icon={<MdVideocam size={24} />}
                    text={'Resume'}
                    onClick={handleResumeRecording}
                  />
                )}
                <Timer>{toMMSS(seconds, 0)}</Timer>
              </ControlsContainer>
            )}
            {status === 'recording' && (
              <FinishButtonWrapper>
                <Button
                  icon={<CheckmarkIcon color={themes.colors.white[100]} />}
                  variant='primary'
                  text={'Finish'}
                  onClick={handleStopRecording}
                />
              </FinishButtonWrapper>
            )}
            {isRecording && status !== 'recording' && (
              <Gap justifyContent='center' width='100%'>
                <Button
                  variant='secondary'
                  text='Cancel'
                  onClick={haltRecording}
                />
              </Gap>
            )}
            {!isRecording && status === 'idle' && (
              <Gap justifyContent='center' width='100%'>
                <Button
                  variant='red'
                  icon={<VideoIcon width={24} height={24} opacity={1} />}
                  text='Start recording'
                  onClick={handleStartRecording}
                />
              </Gap>
            )}
            {status === 'stopped' && <></>}
            {status === 'stopped' && <></>}
          </RecordBtnWrapper>
        ) : null}
      </>
    ),
    [
      status,
      seconds,
      isRecording,
      paused,
      mediaBlobUrl,
      settings,
      uploadDisabled,
    ]
  );

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return status === 'recording';
        }}
        title='Leave without saving the recording?'
        text='Your recording will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
      <CountDown
        height={'434px'}
        width={'770px'}
        counter={countdown}
        willStart={countdown > 0 && isRecording}
        onClick={() => {}}
        hide={!countdown}
      />
      {body}
    </>
  );
};
