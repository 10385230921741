import styled from 'styled-components/macro';
import { ReactComponent as DeleteBtn } from '../icons/DeleteBtn.svg';

const DeleteIcon = styled(DeleteBtn)`
  width: 40px;
  height: 40px;
  pointer-events: none;
  path: first-of-type {
    fill: ${props => props.color || '#FEF6F5'};
  }
`;

export default DeleteIcon;
