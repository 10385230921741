import * as React from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import { HotspotBar } from 'lib/components';
import { FaQuestionCircle } from 'react-icons/fa';
import {
  Table,
  TableFooter,
  TablePaginationGroup,
  TablePaginationSize,
  useTableContext,
} from 'lib/components';
import { theme } from 'lib/style';
import { Hotspot } from 'lib/api/videos/types';

const CardStatsLayout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 300px;
`;

const NoStats = styled.div`
  margin-top: 34px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
`;
const LT = styled.span`
  flex: 1;
`;

const FaQuestionCircleIcon = styled(FaQuestionCircle)`
  padding-left: 8px;
  cursor: pointer;
`;

type QuestionIconPopUpProps = {
  isQuestionIconHovered: boolean;
};

const QuestionIconPopUp = styled.div<QuestionIconPopUpProps>`
  font-size: 12px;
  line-height: 1.43;
  display: ${props => (props.isQuestionIconHovered ? 'block' : 'none')};
  position: absolute;
  top: 28px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  min-width: 240px;
  border-radius: 4px;
  z-index: 200;
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 4px 0 rgba(52, 58, 64, 0.1),
    0 4px 24px 0 rgba(52, 58, 64, 0.15);
`;

type Props = {
  loading: boolean;
  error: boolean | null;
  hotspots: Hotspot[];
};

export const CardStatsHotspot = (props: Props) => {
  const { loading, hotspots, error } = props;
  const { page, size, setCount } = useTableContext();
  const [data, setData] = React.useState<Hotspot[]>([]);
  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const handlePopUp = (condition: boolean) => {
    setIsPopUpOpen(condition);
  };
  const [paginationCounter, setPaginationCounter] = React.useState(false);

  /*** SUS-798 changes ***/
  React.useEffect(() => {
    if (hotspots && paginationCounter) {
      setCount(hotspots.length);
    }
  }, [paginationCounter]);

  React.useEffect(() => {
    if (hotspots) {
      setCount(hotspots.length);
      const start = page * size;
      setData([...hotspots.slice(start, start + size)]);
      setPaginationCounter(true);
    }
  }, [hotspots, page, size]);
  /******/

  const legend = (
    <div>
      <p>
        HotSpots give you a visual way to see which parts of your video are
        engaging your recipients the most.
      </p>
      <Legend>
        <LT>0</LT>
        <LT>1</LT>
        <LT>2</LT>
        <LT>3</LT>
        <LT>4+</LT>
      </Legend>
      <HotspotBar data={['0', '1', '2', '3', '4']} />
      <p>
        The color scale goes from white (unwatched portion) to red (most watched
        portion). This gives you at-a-glance information on which parts of your
        video your recipients like the most, and which parts they don't like.
      </p>
      <p>
        The percentage after the bar let's you know how much of your video was
        viewed.
      </p>
    </div>
  );

  return (
    <CardStatsLayout>
      {loading ? (
        <LoadingIndicator isLoading={loading} />
      ) : (!loading && hotspots && hotspots.length === 0) || error ? (
        <>
          <NoStats>No data to show.</NoStats>
        </>
      ) : (
        <>
          <Table
            headers={[
              '',
              'Date Viewed',
              'Email Address',
              <>
                HotSpots
                <FaQuestionCircleIcon onMouseEnter={() => handlePopUp(true)} />
                <QuestionIconPopUp
                  onMouseLeave={() => handlePopUp(false)}
                  isQuestionIconHovered={isPopUpOpen}
                >
                  {legend}
                </QuestionIconPopUp>
              </>,
              'Watched %',
              '',
            ]}
            hoverable={false}
            rows={data.map((x: Hotspot, index: number) => ({
              key: index,
              columns: [
                '',
                x.date,
                x.email || '-',
                <HotspotBar key={index + x.date} data={x.heatmapArray} />,
                x.percentage + ' %',
                '',
              ],
              onClick: () => {},
            }))}
            isLoading={false}
            error={false}
          />
          <TableFooter>
            <TablePaginationSize />
            <TablePaginationGroup />
          </TableFooter>
        </>
      )}
    </CardStatsLayout>
  );
};
