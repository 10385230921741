import styled from 'styled-components/macro';
import { ReactComponent as FolderSvg } from './reassign-icon.svg';

const FolderVideoMoveIcon = styled(FolderSvg)`
  width: ${props => props.width || '16px'};
  height: ${props => props.height || '20px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default FolderVideoMoveIcon;
