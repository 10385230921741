import { EXPRESS_API } from 'configs/api/expressApi';

export const saveLinkedinAccessToken = async (url: string, code: string) => {
  const data = {
    code,
  };
  const response = await EXPRESS_API.post(url, data);
  return response.data;
};

export const getLinkedinIntegration = async () => {
  const response = await EXPRESS_API.get('/linkedin/integration');
  return response.data;
};

export const deleteLinkedinIntegration = async (url: string) => {
  const response = await EXPRESS_API.delete(url);
  return response.data;
};

export const getLinkedInVideoAnalytics = async (id: number) => {
  const response = await EXPRESS_API.get(`/linkedin/video/${id}/analytics`);
  return response.data;
};

export const getLinkedInOrganizations = async () => {
  const response = await EXPRESS_API.get(`/linkedin/organizations`);
  return response.data;
};

export const updateLinkedinIntegration = async (data: {
  organizationId?: string;
  organizationName?: string;
}) => {
  const response = await EXPRESS_API.put(`/linkedin/organizations`, data);
  return response.data;
};
