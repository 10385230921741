const selectors = {
  companyProfile: {
    field: {
      business: 'company-profile-field-business',
    },
    button: {
      save: 'company-profile-button-save',
    },
  },
  editUserProfile: {
    firstNameField: 'edit-user-firstName',
    lastNameField: 'edit-user-lastName',
    userNameField: 'edit-user-userName',
    emailField: 'edit-user-email',
    saveButton: 'edit-user-save-btn',
  },
  contactsPage: {
    searchField: 'contacts-search',
    searchFieldIcon: 'contacts-search-icon',
    contactsTable: 'contacts-table',
    contactsNoResults: 'contacts-no-results',
  },
  libraryPage: {
    sendAndShareModal: {
      all: 'send-and-share-modal',
      recipientSearch: 'recipient-search',
      recipientOption: 'recipient-option',
      sendEmailButton: 'send-and-share-send-email',
      sendWithoutQuote: 'send-without-quote',
    },
    showMoreOptionsDropdown: 'show-more-options-dropdown',
    videoListTable: 'library-video-list-table',
    sendAndShareButton: 'send-and-share-button',
    videoTitle: 'video-title',
    videoItemCheckbox: 'video-item-checkbox',
    selectedVideosDuplicateAction: 'selected-videos-duplicate-action',
  },
  modals: {
    modalVideoTags: {
      selectedVideosAddTagsHeaderAction:
        'selected-videos-add-tags-header-action',
      addTagDropdown: 'add-tag-dropdown',
      getAddTagDropdownItem: (index: number) => `add-tag-item-${index}`,
      addTagsSubmitButton: 'add-tags-submit-button',
    },
  },
  buttons: {
    manageTagsButton: 'manage-tags-button',
    moreOptions: 'more-options',
    duplicate: 'duplicate',
  },

  ctaPage: {
    ctaCreate: (fieldName: string) => `cta-create-${fieldName}`,
    ctaDropdown: (index: string) => `#cta-create-dropdown-${index}`,
    titile: 'cta-create-title',
    new: 'cta-create-new',
  },
  integrationsPage: {
    searchInput: 'integrations-search',
    searchIcon: 'integrations-search-icon',
    integrationsList: 'integrations-list',
    integrationsNoResults: 'integrations-no-results',
  },
};
export default selectors;
