import * as React from 'react';
import ContactsIcon from '../images/ContactsIcon';
import AccountsIcon from '../images/AccountsIcon';
import ReportsIcon from '../images/ReportsIcon';
import ImageIcon from '../images/ImageIcon';
import ShopIcon from '../images/ShopIcon';
import TasksIcon from '../images/TasksIcon';
import { IntegrationsIcon } from '../images/IntegrationsIcon';
import { productFeature } from './productFeature';
import OrganizationIcon from 'lib/images/OrganizationIcon';
import { MdAddLink, MdLocationPin, MdLaunch } from 'react-icons/md';
import styled from 'styled-components/macro';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 19px;
    width: 19px;
  }
`;

export interface ProfileNavigationItem {
  onItemClick?: () => void;
  title: string;
  icon?: JSX.Element;
  path: string;
  customLink?: string;
  isDisabled?: boolean;
  productFeatureId?: number;
  isLabel?: boolean;
  iconColor?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}

export interface NavigationItem {
  title: string;
  shortTitle?: string;
  icon?: JSX.Element;
  path: string;
  customLink?: string;
  innerMenu?: NavigationItem[];
  isDisabled?: boolean;
  productFeatureId?: number;
  onItemClick?: () => void;
}

export const horizontalItemsAll = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsAllAssignment = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
  {
    title: 'Assignments',
    path: '/company/Company_ManageAssignedUsers',
    isDisabled: false,
  },
];

export const horizontalItemsSupervisor = [
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsUser = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsAutomotive = [
  {
    title: 'Repair Orders',
    path: '/repair-orders',
    isDisabled: false,
  },
];

export const horizontalItemsAutomotiveSales = [
  {
    title: 'Inventory',
    path: '/inventory',
    isDisabled: false,
  },
];

export const horizontalItemsCovideoDevAdminCustomer = [
  {
    title: 'Admin',
    path: '/admin/customers',
    innerMenu: [
      {
        title: 'Multi-Location Manager',
        shortTitle: 'MLM',
        icon: <MdLocationPin size={24} />,
        path: '/admin/multilocations',
        isDisabled: false,
      },
      {
        title: 'IMS',
        shortTitle: 'IMS',
        icon: <MdAddLink size={24} />,
        path: '/admin/ims',
        isDisabled: false,
      },
      {
        title: 'Organizations',
        shortTitle: 'Organizations',
        icon: <OrganizationIcon />,
        path: '/admin/organizations',
        isDisabled: false,
      },
      {
        title: 'Resellers',
        shortTitle: 'Resellers',
        icon: <ShopIcon />,
        path: '/admin/resellers',
        isDisabled: false,
      },
      {
        title: 'Manage Customers',
        shortTitle: 'Customers',
        icon: <ContactsIcon />,
        path: '/admin/customers',
        isDisabled: false,
      },
      {
        title: 'Manage Users',
        shortTitle: 'Users',
        icon: <AccountsIcon />,
        path: '/admin/users',
        isDisabled: false,
      },
      {
        title: 'Manage Assignments',
        shortTitle: 'Assignments',
        icon: <TasksIcon />,
        path: '/company/Company_ManageAssignedUsers',
        isDisabled: false,
      },
      {
        title: 'Usage Reports',
        shortTitle: 'Usage Reports',
        icon: <ReportsIcon />,
        path: '/admin/usage-reports',
        isDisabled: false,
      },
      {
        title: 'Graphics',
        shortTitle: 'Graphics',
        icon: <ImageIcon />,
        path: '/company/Company_Graphics',
        isDisabled: false,
      },
      {
        title: 'Integrations',
        shortTitle: 'Integrations',
        icon: <IntegrationsIcon />,
        path: '/admin/integrations',
        isDisabled: false,
      },
      {
        title: 'Login As',
        shortTitle: 'Login As',
        icon: (
          <IconContainer>
            <MdLaunch />
          </IconContainer>
        ),
        path: '#',
        isDisabled: false,
        onItemClick: () => {},
      },
    ],
  },
];
