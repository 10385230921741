import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { ListWebsiteOverlay } from 'lib/api';
import { PageStyleListItem } from '../PageStyleListItem';
import { useLandingPageBuilderContext } from 'app/pages/design/landingPageBuilder/context';
import { defaultStyleProperties } from 'app/pages/design/landingPageBuilder/components/Constants';
import { Button } from 'react-covideo-common';
interface Props {
  onClose: (params?: any) => void;
  onMainButtonClick?: (params?: any) => void;
  data?: ListWebsiteOverlay;
  modalLoading: boolean;
  type: 'add' | 'edit';
  pageBuilderHandler?: any;
  section?: boolean;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalLandingPageSelectStyle = (props: Props) => {
  const { onClose, onMainButtonClick, modalLoading, type } = props;
  let {
    selectedStyle,
    setPageBackgroundColor,
    setSelectedStyle,
    setSelectedStyleProperties,
    setIsEnabled,
  } = useLandingPageBuilderContext();
  const title = type === 'add' ? 'Choose Style' : 'Choose Style';
  const buttonText = type === 'add' ? 'Continue' : 'Continue';

  const themes = useTheme();

  const onStyleSelect = (e: any) => {
    e.preventDefault();
    setSelectedStyle(e.target.innerText);

    const defaultProperties = defaultStyleProperties[e.target.innerText] || {};
    setSelectedStyleProperties(defaultProperties);
    setPageBackgroundColor(defaultProperties.backgroundColor);
  };

  React.useEffect(() => {}, []);

  function click() {
    setIsEnabled(true);
    onClose();
  }

  return (
    <Modal widthOnDesktop={'1020px'}>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row justifyContent={'space-between'}>
          <Title>{title}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row justifyContent={'space-between'} marginBottom={1}>
          <PageStyleListItem
            styleName={'BOLD'}
            backgroundColor={'#232224'}
            fontFamily={'Oswald-bolder'}
            color={'#F8BD25'}
            fontWeight={'bolder'}
            fontStyle={'normal'}
            onClick={e => onStyleSelect(e)}
            isSelected={selectedStyle == 'BOLD'}
          />
          <PageStyleListItem
            styleName={'SPORTY'}
            backgroundColor={'#88CB5E'}
            fontFamily={'Exo2-bolder'}
            color={'#1D232F'}
            fontWeight={'bold'}
            fontStyle={'normal'}
            onClick={e => onStyleSelect(e)}
            isSelected={selectedStyle == 'SPORTY'}
          />
          <PageStyleListItem
            styleName={'AUTO MOTO'}
            backgroundColor={'#B41631'}
            fontFamily={'Oxygen-normal'}
            color={'#FFFFFF'}
            fontWeight={'normal'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'AUTO MOTO'}
          />

          <PageStyleListItem
            styleName={'Classic'}
            backgroundColor={'#ECEADB'}
            fontFamily={'PlayfairDisplay-bolder'}
            color={'#554337'}
            fontWeight={'bold'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'Classic'}
          />
          <PageStyleListItem
            styleName={'Blank'}
            backgroundColor={'#FFFFFF'}
            fontFamily={'Roboto'}
            color={'#000'}
            fontWeight={'normal'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'Blank'}
          />
          <PageStyleListItem
            styleName={'FRESH'}
            backgroundColor={'#CED5D4'}
            fontFamily={'PlayfairDisplayItalic-bolder'}
            color={'#495F54'}
            fontWeight={'bold'}
            fontStyle={'italic'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'FRESH'}
          />
          <PageStyleListItem
            styleName={'MODERN'}
            backgroundColor={'#202E42'}
            fontFamily={'Montserrat-bolder'}
            color={'#E84C3D'}
            fontWeight={'800'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'MODERN'}
          />
          <PageStyleListItem
            styleName={'INDUSTRIAL'}
            backgroundColor={'#EFEFEF'}
            fontFamily={'BarlowCondensed-normal'}
            color={'#000000'}
            fontWeight={'lighter'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'INDUSTRIAL'}
          />
          <PageStyleListItem
            styleName={'Soft'}
            backgroundColor={'#DDE1EB'}
            fontFamily={'NunitoBlack-bolder'}
            color={'#4D5678'}
            fontWeight={'bolder'}
            fontStyle={'normal'}
            onClick={onStyleSelect}
            isSelected={selectedStyle == 'Soft'}
          />
        </Row>
        <Row justifyContent={'flex-end'} marginBottom={0.5}>
          <Button
            text={buttonText}
            onClick={() => (onMainButtonClick ? onMainButtonClick() : click())}
            disabled={selectedStyle == ''}
          />
        </Row>
      </Content>
    </Modal>
  );
};
