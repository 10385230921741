import * as React from 'react';
import { StepCard, TimelineRange } from './';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { createRef, useEffect, useRef, useState } from 'react';
import { trimVideo, TrimVideoData } from 'lib/api';
import { Spinner } from 'lib/components';
import SaveIcon from 'lib/images/SaveIcon';
import {
  AudioRecorder,
  VideoPreview,
} from 'app/pages/video/videoVoiceover/components';
import { useToastError, VideoData } from 'lib/hooks';
import { ModalTrimCreateNew } from 'lib/components/modal/ModalTrimCreateNew';
import { VideoListAutomotiveItem } from 'lib/context';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

const VideoWrapper = styled.div<{ width: number }>`
  position: relative;
  .fullscreen {
    height: auto;
  }
  figure {
    background: ${theme.palette.blue100};
    min-width: ${({ width }) => `${width}px`};
  }
  video {
    max-height: ${({ width }) => `${width * 0.5625}px`};
    object-position: top;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

const RETRY_DELAY = 500;

enum Step {
  TRIM,
  VOICEOVER,
}

enum StatusTrim {
  IN_PROGRESS = '',
  SKIPPED = 'Trimming Skipped',
  SAVED = 'Trimming Saved',
}

type Props = {
  video: VideoListAutomotiveItem;
  width: number;
  trimVideoData: TrimVideoData;
  setTrimVideoData: (trimVideoData: TrimVideoData) => void;
  showTickTime?: boolean;
  showFloatingPreview?: boolean;
  isVoiceoverValid: boolean;
  onChangeVoiceoverValid: (valid: boolean) => void;
  onChangeRecordingBlob: (blob: string) => void;
  onChangeVideoToApprove: (video: VideoListAutomotiveItem) => void;
  skipTrim: boolean;
  setFormIsTouched: (arg: boolean) => void;
};

export const VideoCropAndVoiceover = ({
  video,
  width,
  trimVideoData,
  setTrimVideoData,
  showTickTime = true,
  showFloatingPreview = true,
  onChangeVoiceoverValid,
  isVoiceoverValid,
  onChangeRecordingBlob,
  onChangeVideoToApprove,
  skipTrim = false,
  setFormIsTouched,
}: Props) => {
  const { showError } = useToastError();
  const valueRef = useRef<Array<number>>([0, 0]);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [trimDuration, setTrimDuration] = React.useState<number>(0);
  const [start, setStart] = useState<number>(0);
  const [value, setValue] = useState<ReadonlyArray<number>>([0, videoDuration]);
  const [isDirty, setIsDirty] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [activeStep, setActiveStep] = useState(
    skipTrim ? Step.VOICEOVER : Step.TRIM
  );
  const [trimStatus, setTrimStatus] = useState(
    skipTrim ? StatusTrim.SAVED : StatusTrim.IN_PROGRESS
  );
  const [isTrimming, setIsTrimming] = useState(false);
  const [showModalTrimTitle, setShowModalTrimTitle] = useState(false);
  const [retryTimeout, setRetryTimeout] = useState(0);
  const [isErrorLoadingVoiceoverVideo, setIsErrorLoadingVoiceoverVideo] =
    useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    setIsDirty(
      value[0] !== 0 || value[1].toFixed(1) !== videoDuration.toFixed(1)
    );
  }, [value]);

  useEffect(() => {
    setTrimVideoData({
      duration: trimDuration,
      start: start,
    });
  }, [trimDuration, start]);

  useEffect(() => setIsTouched(isTouched || isDirty), [isDirty]);

  const trimVideoRef = createRef<HTMLVideoElement>();
  const voiceoverVideoRef = createRef<HTMLVideoElement>();

  const onloadedmetadata = () => {
    if (trimVideoRef.current && activeStep === Step.TRIM) {
      const duration = trimVideoRef.current.duration;
      setVideoDuration(duration);
      setTrimVideoData({
        ...trimVideoData,
        duration: duration,
      });
    }
  };

  const onPlay = (event: Event) => {
    const video = event.target as HTMLVideoElement;
    if (video.currentTime >= valueRef.current[1]) {
      //on trim end set start time
      video.currentTime = valueRef.current[0];
    } else if (video.currentTime < valueRef.current[0]) {
      // go to trim start if currentTime less
      video.currentTime = valueRef.current[0];
    }
  };
  const onTimeupdate = (event: Event) => {
    const video = event.target as HTMLVideoElement;
    if (video.currentTime > valueRef.current[1] && valueRef.current[1] != 0) {
      video.pause();
    }
  };

  const trimVideoCreate = async (title: string) => {
    setIsTrimming(true);
    try {
      const croppedVideo = await trimVideo(video.id, {
        ...trimVideoData,
        title,
      });
      onChangeVideoToApprove(croppedVideo);
      setActiveStep(Step.VOICEOVER);
      setTrimStatus(StatusTrim.SAVED);
      setIsTrimming(false);
      setShowModalTrimTitle(false);
      setFormIsTouched(true);
    } catch (error) {
      showError(error);
      console.log(error);
      setIsTrimming(false);
    }
  };

  const onClickSkipTrim = () => {
    setActiveStep(Step.VOICEOVER);
    setTrimStatus(StatusTrim.SKIPPED);
    setTrimVideoData({ duration: videoDuration, start: 0 });
  };

  const handleLoad = () => {
    setIsErrorLoadingVoiceoverVideo(false);
    clearTimeout(retryTimeout);
  };

  const handleError = () => {
    if (voiceoverVideoRef.current && !isErrorLoadingVoiceoverVideo) {
      setIsErrorLoadingVoiceoverVideo(true);
    }
  };

  useEffect(() => {
    if (trimVideoRef && trimVideoRef.current) {
      trimVideoRef.current.onloadedmetadata = () => onloadedmetadata();
      trimVideoRef.current.addEventListener('play', e => onPlay(e));
      trimVideoRef.current.addEventListener('timeupdate', e => onTimeupdate(e));
    }
    return () => {
      if (trimVideoRef && trimVideoRef.current) {
        trimVideoRef.current.onloadedmetadata = null;
        trimVideoRef.current.removeEventListener('play', e => onPlay(e));
        trimVideoRef.current.removeEventListener('timeupdate', e =>
          onTimeupdate(e)
        );
      }
    };
  }, [trimVideoRef]);

  useEffect(() => {
    if (isErrorLoadingVoiceoverVideo) {
      setRetryCount(retryCount + 1);
    }
  }, [isErrorLoadingVoiceoverVideo]);

  useEffect(() => {
    if (retryCount > 0 && isErrorLoadingVoiceoverVideo) {
      clearTimeout(retryTimeout);
      const timeout = setTimeout(setRetryCount, RETRY_DELAY, retryCount + 1);
      setRetryTimeout(timeout);
    }
  }, [retryCount]);

  useEffect(() => {
    if (voiceoverVideoRef.current) {
      voiceoverVideoRef.current.addEventListener(
        'loadedmetadata',
        handleLoad,
        true
      );
      voiceoverVideoRef.current.addEventListener('error', handleError, true);
    }

    return () => {
      if (voiceoverVideoRef.current) {
        voiceoverVideoRef.current.removeEventListener(
          'loadedmetadata',
          handleLoad,
          true
        );
        voiceoverVideoRef.current.removeEventListener(
          'error',
          handleError,
          true
        );
      }
    };
  }, [voiceoverVideoRef]);

  return (
    <>
      {activeStep === Step.TRIM && (
        <>
          <VideoWrapper width={width}>
            <VideoPlayer
              width={`${width}px`}
              playerBackgroundColor={video.playerBackgroundColor || ''}
              videoSource={video.videoSource}
              videoId={video.id}
              playButtonPosition={video.playButtonPosition || ''}
              playerIconsColor={video.playerIconsAndTextColor || ''}
              videoRef={trimVideoRef}
              autoplay={false}
            />
          </VideoWrapper>
          <StepsWrapper>
            <StepCard title='1. Trim Video' expandedDefault={true}>
              <TimelineRange
                width={`${width - 32}px`}
                videoRef={trimVideoRef}
                videoDuration={videoDuration}
                videoSource={video.videoSource}
                setTrimDuration={setTrimDuration}
                setStart={setStart}
                setValue={setValue}
                value={value}
                valueRef={valueRef}
                showTickTime={showTickTime}
                showFloatingPreview={showFloatingPreview}
              />
              <Gap m='12px 0 0'>
                <Button
                  variant='primary'
                  text='Save Trimmed Video'
                  onClick={() => setShowModalTrimTitle(true)}
                  disabled={!isDirty || isTrimming || trimDuration < 1}
                  icon={
                    isTrimming ? (
                      <Spinner color={theme.palette.covideoBlue100} />
                    ) : (
                      <SaveIcon color={theme.palette.white} />
                    )
                  }
                />
                <Button
                  text='Skip Trimming'
                  onClick={() => onClickSkipTrim()}
                  variant='secondary'
                />
              </Gap>
            </StepCard>
            <StepCard
              title='2. Record Voiceover'
              disabled={true}
              badgeStatus='danger'
              badgeText='Save or Skip Trim First'
            />
          </StepsWrapper>
        </>
      )}
      {activeStep === Step.VOICEOVER && (
        <>
          <VideoPreview
            video={video as unknown as VideoData}
            videoRef={voiceoverVideoRef}
            width={width}
            useCovideoPlayer={true}
          />
          <StepsWrapper>
            <StepCard
              title='1. Trim Video'
              disabled={trimStatus === StatusTrim.SAVED || isVoiceoverValid}
              badgeText={trimStatus}
              badgeStatus={
                trimStatus === StatusTrim.SAVED ? 'success' : 'default'
              }
              onExpand={() => {
                setTrimStatus(StatusTrim.IN_PROGRESS);
                setActiveStep(Step.TRIM);
              }}
            />
            <StepCard
              title='2. Record Voiceover'
              expandedDefault={true}
              badgeText={
                isVoiceoverValid
                  ? 'Voiceover ready'
                  : isErrorLoadingVoiceoverVideo
                    ? 'Still trimming...'
                    : ''
              }
              badgeStatus={isErrorLoadingVoiceoverVideo ? 'danger' : 'success'}
            >
              <AudioRecorder
                videoRef={voiceoverVideoRef}
                video={video as unknown as VideoData}
                onChangeVoiceoverValid={onChangeVoiceoverValid}
                onRecordingUrlGeneration={(url: string) => {
                  onChangeRecordingBlob(url);
                }}
                width={width - 32}
                disabled={isErrorLoadingVoiceoverVideo}
                setFormIsTouched={setFormIsTouched}
              />
            </StepCard>
          </StepsWrapper>
        </>
      )}
      {showModalTrimTitle && (
        <ModalTrimCreateNew
          disabled={isTrimming}
          isLoading={isTrimming}
          initTitle={video.title}
          handleSubmit={title => trimVideoCreate(title)}
          handleModalClose={() => setShowModalTrimTitle(false)}
        />
      )}
    </>
  );
};
