import * as React from 'react';
import styled from 'styled-components/macro';

import { ButtonSwitch } from 'lib/components'; //IoIosAdd;
import { ModalLandingPageDesignAddOrEdit } from './modal/ModalLandingPageDesignAddOrEdit';
import { ModalLandingPageSelectStyle } from './modal/ModalLandingPageSelectStyle';
import { MdImage } from 'react-icons/md';
import { EnhancedColorPicker } from '../../../video/videoDetails/components';
import { ColorResult } from 'react-color';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { ICombinedUserData, useAuth } from 'lib/context';
import { useLandingPageBuilderContext } from '../context';
import { getSignedURLs, GetSignedUrlsResponse } from 'lib/api';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { BinIcon, ResetIcon } from './Constants';
import {
  backgroundPositionOptions,
  backgroundSizeOptions,
} from './editSectionComponents/ChangeBackgroundComponent';
import Select from 'react-select';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';

interface Props {
  setShowBackButton: any;
}

type GetSignedUrlsParams = {
  fileName: string;
  mimeType: string;
  folder: string;
};

interface ModalWithData {
  name: 'layout' | 'style' | 'none';
  type: 'add' | 'edit' | 'none';
}
interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px')};
  flex-direction: row;
  .adjustWidth {
    width: 85%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .rightAlign {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  .adjustToggleMargin {
    margin: 0px;
  }
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  .adjustWidth {
    width: 85%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .rightAlign {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  margin: 20px auto 0 auto;
`;

const FileInputHandler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  margin: 20px auto 0 auto;
  word-break: break-all;
  height: fit-content;
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

const Thumbnail = styled.img`
  max-width: 80%;
  height: auto;
  border-radius: 5px;
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

export const PageTabContent = (props: Props) => {
  const {
    selectedStyleProperties,
    setPageBackgroundColor,
    pageBackgroundImageUrl,
    setPageBackgroundImageUrl,
    pageBackgroundColor,
    pageBackgroundSize,
    pageBackgroundPosition,
    setPageBackgroundPosition,
    setPageBackgroundSize,
    undoState,
  } = useLandingPageBuilderContext();
  const { setShowBackButton } = props;
  const [_, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });

  const [selectedBackgroundColor, setSelectedBackgroundColor] = React.useState(
    selectedStyleProperties.backgroundColor
  );
  const [__, setBackgroundImageSource] = React.useState('');
  const [backgroundImageFile, setBackgroundImageFile] = React.useState<File>();
  const [modalWithData, setModalWithData] = React.useState<ModalWithData>({
    name: 'none',
    type: 'none',
  });

  const { userData } = useAuth();
  const generateName = handleNameGeneration(userData);

  const onUploadProgress = handleUploadProgress(setUploadProgress);

  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  const handleChangeBackgroundColor = (color: ColorResult | string) => {
    let hexAlpha: string;
    if (typeof color != 'string') {
      hexAlpha = calculateHexAlpha(color as ColorResult);
    } else {
      hexAlpha = selectedStyleProperties.backgroundColor;
    }
    const checkIfPageLayout = undoState?.find(
      (item: any) => item?.type === 'page-layout'
    );
    if (selectedBackgroundColor != selectedStyleProperties.backgroundColor) {
      setSelectedBackgroundColor(hexAlpha);
      setPageBackgroundColor(hexAlpha);
      return;
    } else if (!checkIfPageLayout) {
      setSelectedBackgroundColor(hexAlpha);
      setPageBackgroundColor(hexAlpha);
      return;
    }
    setSelectedBackgroundColor(hexAlpha);
    setPageBackgroundColor(hexAlpha);
  };

  const removeBackgroundImage = () => {
    setPageBackgroundImageUrl('');
    setBackgroundImageFile(undefined);
  };

  const onCloseModal = () => {
    setModalWithData({ name: 'none', type: 'none' });
  };

  let fileRef = React.createRef<HTMLInputElement>();

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    if (target && target.files && target.files[0]) {
      let orgFile = target.files[0];
      const isValidType = /.*\.(jpeg|jpg|png|bmp|gif|webp|svg)/i.test(
        orgFile.name
      );
      if (isValidType) {
        let filename = orgFile.name.split('.');
        setBackgroundImageSource(filename[0]);
        let newFile = new File(
          [orgFile],
          generateName(filename[filename.length - 1]),
          { type: orgFile.type, lastModified: orgFile.lastModified }
        );
        setBackgroundImageFile(newFile);
        setBackgroundImageSource(URL.createObjectURL(newFile));
        handleUpload(newFile);
      } else {
        // setError('Invalid format.');
        errorToast({ title: 'Invalid format.' });
      }
    } else {
      errorToast({ title: 'Invalid file.' });
    }
  };

  const handleUpload = async (newFile: File) => {
    setShowBackButton(false);
    const signedUrlData: GetSignedUrlsParams = {
      fileName: newFile.name,
      mimeType: newFile.type,
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      errorToast({ title: 'Server is currently unavailable, try again!' });
      setShowBackButton(true);
      return;
    }
    const data = {
      file: newFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };

    uploadEmailThumbnail({ data, onUploadProgress })
      .then(() => {
        setPageBackgroundImageUrl(signedURLs.downloadUrl);
        setShowBackButton(true);
      })
      .catch(() => {
        setShowBackButton(true);
      });
  };

  const rowWidth = '85%';
  React.useEffect(() => {
    setSelectedBackgroundColor(pageBackgroundColor);
  }, [selectedStyleProperties, pageBackgroundColor]);

  return (
    <>
      {modalWithData.name === 'layout' ? (
        <ModalLandingPageDesignAddOrEdit
          type={'add'}
          modalLoading={false}
          onClose={onCloseModal}
          onContinueButtonClick={onCloseModal}
        />
      ) : modalWithData.name === 'style' ? (
        <ModalLandingPageSelectStyle
          type={'add'}
          section={true}
          modalLoading={false}
          onClose={onCloseModal}
          onMainButtonClick={onCloseModal}
        />
      ) : (
        <></>
      )}
      <Container>
        <Row width={rowWidth}>
          <Button
            variant='secondary'
            text={'Change layout'}
            onClick={() => setModalWithData({ name: 'layout', type: 'add' })}
          />
        </Row>
        <Row width={rowWidth}>
          <Button
            variant='secondary'
            text={'Change style'}
            onClick={() => setModalWithData({ name: 'style', type: 'add' })}
          />
        </Row>
        <Row width={rowWidth}>
          <TopLabel>Main Color</TopLabel>
          <EnhancedColorPicker
            disableZIndex={true}
            handleColorChange={handleChangeBackgroundColor}
            playerColor={selectedBackgroundColor}
            wrapperWidth={'100%'}
            wrapperMargin={'5px auto 0 0'}
            className={
              pageBackgroundColor &&
              selectedStyleProperties.backgroundColor != pageBackgroundColor
                ? 'adjustWidth'
                : ''
            }
          />
          {pageBackgroundColor &&
            selectedStyleProperties.backgroundColor != pageBackgroundColor && (
              <ResetIcon
                onClick={() => handleChangeBackgroundColor('')}
                className={'rightAlign'}
              />
            )}
        </Row>
        <Row justifyContent={'center'} padding={'0px'}>
          <Row width={rowWidth}>
            <input
              type={'file'}
              ref={fileRef}
              style={{ display: 'none' }}
              name={'filePicker'}
              onChange={handleSelectedFile}
              accept={
                'image/png, image/jpeg, image/bmp, image/gif, image/webp, image/svg+xml'
              }
            />

            {(!pageBackgroundImageUrl || pageBackgroundImageUrl == '') && (
              <FileInputHandler
                className={pageBackgroundImageUrl ? 'adjustWidth' : ''}
                onClick={() => fileRef && fileRef.current?.click()}
              >
                {pageBackgroundImageUrl ||
                  (backgroundImageFile
                    ? backgroundImageFile.name
                    : 'Upload image...')}
                <MdImage size={24} color={'#9297A2'} />
              </FileInputHandler>
            )}

            {pageBackgroundImageUrl && (
              <>
                <Thumbnail src={pageBackgroundImageUrl} />
                <BinIcon
                  onClick={removeBackgroundImage}
                  className={'rightAlign'}
                />
              </>
            )}
          </Row>
          {pageBackgroundImageUrl && (
            <>
              <Row justifyContent={'center'} width={rowWidth} padding={'0px'}>
                <Row width={'100%'}>
                  <ButtonSwitch
                    className={'adjustToggleMargin'}
                    defaultValue={pageBackgroundSize || 'cover'}
                    values={backgroundSizeOptions}
                    onChange={(option: any) => {
                      setPageBackgroundSize(option);
                    }}
                  />
                </Row>
              </Row>
              <Row justifyContent={'center'} width={rowWidth} padding={'0px'}>
                <Row width={'100%'}>
                  <SelectInput
                    styles={{
                      control: (base: any) => ({ ...base, height: '40px' }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    options={backgroundPositionOptions}
                    menuPlacement={'top'}
                    value={backgroundPositionOptions.find(o => {
                      return o.value === (pageBackgroundPosition || 'left top');
                    })}
                    onChange={(option: any) => {
                      setPageBackgroundPosition(option.value);
                    }}
                  />
                </Row>
              </Row>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};
function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
