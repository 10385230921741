export interface ITableFields {
  value: TableFieldsValue;
  label: string;
}
export interface IOnPaginationChange {
  page: number;
  size: number;
}

export enum TableFieldsValue {
  RECIPIENTS = 'recipients',
  SCHEDULED_FOR = 'scheduledFor',
  VIDEO = 'video',
  SEND_EMAIL = 'sendEmail',
  SEND_SMS = 'sendSms',
}

export type TimezonesOption = {
  value: string;
  label: string;
  offset: number;
  utc: string[];
};
