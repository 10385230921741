import { useDeleteTemplateMutation } from 'lib/api/annotations/deleteTemplate';
import { InfoQuestionMark } from 'lib/components';
import AnnotationsIcon from 'lib/images/AnnotationsIcon';
import DeleteIcon from 'lib/images/DeleteIcon';
import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { selectTemplate } from '../util/functions';

const TemplateDeleteWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #eeeff2;
  position: absolute;
  right: -20px;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  z-index: 3;
`;

const TemplateInfo = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 5px;
  background-color: #b6e0ec33;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const TemplateInfoHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
`;

const TemplateWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: flex-start;
`;

const TemplateBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  width: 176px;
  height: 176px;
  border-radius: 4px;
  border: solid 1px #f6f7f9;
  background-color: white;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    ${TemplateDeleteWrapper} {
      visibility: visible;
    }
  }
`;

const TemplatePreview = styled.div`
  width: 100%;
  height: 120px;
  background-color: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const TemplateTitle = styled.div`
  display: flex;
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.palette.black_1_100};
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Annotation = styled.div<any>`
  box-sizing: border-box;
  align-items: center;
  border-radius: 5px;
`;

const AnnotationText = styled(Annotation)<any>`
  padding: 12px 16px;
  font-size: ${props => props.fontSize}px;
  font-family: ${props => props.fontFamily};
  color: ${props => props.textColor};
  background: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-width: ${props => props.borderWidth}px;
  border-style: ${props => (props.borderWidth ? 'solid' : '')};
  text-decoration: none;
  outline: none;
  white-space: pre-line;
  ${props => (props.textBold ? `font-weight: 700;` : '')}
  ${props => (props.textItalic ? `font-style: italic;` : '')}
  ${props => (props.textUnderline ? `text-decoration: underline;` : '')}
  &:active {
    color: ${props => props.textColor};
  }
`;

const AnnotationImage = styled(Annotation).attrs({ as: 'img' })`
  background-size: contain;
  width: ${props => `${props.calcImageWidth || 100}px`};
  height: ${props => `${props.calcImageHeight || 50}px`};
  opacity: ${props => props.imageOpacity};
  object-fit: contain;
`;

type TemplateProps = {
  isSaveTemplateStep: boolean;
  isSelectTemplateStep: boolean;
  templates: Array<any>;
  setShowTemplateNameModal: (arg: boolean) => void;
  setShowTemplateUpdateModal: (arg: boolean) => void;
  setFieldValue: (arg1: string, arg2: any) => void;
  setStep: (arg: string) => void;
  setSelectedTemplate: (arg: object) => void;
};

export const Template = ({
  isSaveTemplateStep,
  templates,
  isSelectTemplateStep,
  setShowTemplateNameModal,
  setFieldValue,
  setShowTemplateUpdateModal,
  setStep,
  setSelectedTemplate,
}: TemplateProps) => {
  const { mutateAsync: deleteTemplate } = useDeleteTemplateMutation();

  return (
    <div style={{ width: '100%' }}>
      {isSaveTemplateStep && (
        <TemplateInfo>
          <InfoQuestionMark />
          <div style={{ marginLeft: '12px' }}>
            <TemplateInfoHeader>
              What are Annotation Templates?
            </TemplateInfoHeader>
            <div style={{ marginTop: '8px' }}>
              Once saved, this annotation template can be applied to a different
              video. Content, style, and URL will be saved. <br />
              <strong>
                Updating existing template won’t update already added
                annotation.
              </strong>
            </div>
          </div>
        </TemplateInfo>
      )}
      <TemplateWrapper>
        {templates.map((template: any) => {
          const isBlank = template.templateId === 'blank';
          return (
            <TemplateBody
              key={template.templateId}
              onClick={() => {
                if (isSelectTemplateStep) {
                  return selectTemplate({
                    template,
                    setStep,
                    setSelectedTemplate,
                  });
                }
                if (isBlank) {
                  return setShowTemplateNameModal(true);
                }
                setFieldValue('templateId', template.templateId);
                setFieldValue('title', template.title);
                return setShowTemplateUpdateModal(true);
              }}
            >
              <TemplatePreview>
                {isBlank && <AnnotationsIcon width={'64px'} height={'64px'} />}
                {!isBlank && (
                  <>
                    {template.type === 'text' && (
                      <AnnotationText {...template}>
                        {template.text}
                      </AnnotationText>
                    )}
                    {template.type === 'image' && (
                      <AnnotationImage {...template} src={template.imageUrl} />
                    )}
                    {isSelectTemplateStep && (
                      <TemplateDeleteWrapper
                        onClick={async event => {
                          event.stopPropagation();
                          deleteTemplate(template.templateId);
                        }}
                      >
                        <DeleteIcon width={24} height={24} color={'#4e5461'} />
                      </TemplateDeleteWrapper>
                    )}
                  </>
                )}
              </TemplatePreview>
              <TemplateTitle>{template.title}</TemplateTitle>
            </TemplateBody>
          );
        })}
      </TemplateWrapper>
    </div>
  );
};
