import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { checkIfOverlayValid } from 'lib/utils/functions';
import { IQuickShareMarkAsSentParams } from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import { EXPRESS_API } from 'configs/api/expressApi';

type Props = {
  videoId: string | number;
  enabledOnMount?: boolean;
  key?: string;
};

export type GetQuickshareParams = {
  templateId?: number | string;
  linksetId?: number | string;
  overlayId?: number | string;
  iconId?: number | string;
  vin?: number | string;
  format?: string;
  useAutomotive?: boolean;
  allowVideoReply?: boolean;
  allowReactions?: boolean;
  notifyUser?: boolean;
  expirationDate?: Date | null;
};

export const getQuickshareDetails = async (
  videoId: string | number | undefined,
  params?: IQuickShareMarkAsSentParams | GetQuickshareParams
) => {
  const response = await EXPRESS_API.get(`/share/quickshare/${videoId}`, {
    params,
  });

  return response.data;
};

export const useGetQuickshareDetails = ({
  videoId,
  enabledOnMount = true,
  key = 'quickshare',
}: Props) => {
  const [enabled, setEnabled] = useState(!!videoId && enabledOnMount);
  const [params, setParams] = useState<IQuickShareMarkAsSentParams>();
  const valid = checkIfOverlayValid(
    params?.templateId || '',
    params?.overlayId || ''
  );
  const response = useQuery(
    [key, params, videoId],
    async () => getQuickshareDetails(videoId, params),
    { enabled: enabled && valid, staleTime: 500_000_000 }
  );

  useEffect(() => {
    if (!params || !videoId) {
      return;
    }
    // changing params enables query
    setEnabled(true);
    return () => {
      setEnabled(false);
    };
  }, [params]);

  return { ...response, changeQuickshareParams: setParams };
};
