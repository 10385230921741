import { EXPRESS_API } from 'configs/api/expressApi';
import axios from 'axios';
interface ReceivedFacebookUserData {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  name: string;
  name_format: string;
  picture: {
    data: {
      height: number;
      width: number;
      url: string;
      is_silhouette: boolean;
    };
  };
  short_name: string;
  eventKey?: any;
}

export interface UpdateAnalyticsPageTokensData {
  pageId: string;
  pageToken: string;
}

export const uploadVideoFacebook = async (
  id: number,
  title?: string,
  description?: string
) => {
  const response = await EXPRESS_API.post(`/facebook/video-upload/${id}`, {
    title,
    description,
  });
  return response.data;
};

export const getFacebookVideoAnalytics = async (id: number) => {
  const response = await EXPRESS_API.get(`/facebook/video/${id}/analytics`);
  return response.data.data;
};

export const hasFacebookIntegration = async () => {
  const response = await EXPRESS_API.get('/facebook/auth/integration');
  return response.data;
};

export const removeFacebookIntegration = async () => {
  const response = await EXPRESS_API.delete('/facebook');
  return response;
};

export const authenticateFB = async (data: ReceivedFacebookUserData) => {
  const response = await EXPRESS_API.post(
    '/facebook/auth/confirm-identity',
    data
  );
  return response.data;
};

export const updateIntegration = async (data: {
  pageName?: string;
  pageToken?: string;
  pageId?: string;
  accessToken?: string;
}) => {
  const response = await EXPRESS_API.put('/facebook/auth/update-page', data);
  return response.data;
};

export const updateAnalyticsPageTokens = async (
  data: UpdateAnalyticsPageTokensData[]
) => {
  const response = await EXPRESS_API.put('/facebook/page-tokens', {
    data: data,
  });
  return response.data;
};

export const getAccountPages = async (
  accountId: string,
  accessToken: string
) => {
  const url = `https://graph.facebook.com/${accountId}/accounts?access_token=${accessToken}`;
  const response = await axios.get(url);
  return response.data;
};

export const getTokenStatus = async (accessToken: string) => {
  const url = `https://graph.facebook.com/me?access_token=${accessToken}`;
  const response = await axios.get(url);
  return response.data;
};
