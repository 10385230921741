import {
  SuperAdminCustomerDetails,
  SuperAdminUser,
  TableField,
  UserListFilter,
} from 'lib/context';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { UsersSearchAndFilter } from './UsersSearchAndFilter';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { useUserQuery } from 'lib/api';
import { LoadingIndicator } from 'lib/components';
import { NoList } from '../index.styled';
import { UserTable } from './UserTable';
import { debounce } from 'lodash';
import { SelectedUsers } from './SelectedUsers';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';
import {
  ImportUserModal,
  ModalOrigin,
  ModalType,
} from 'app/pages/management/components/ImportUserModal';
import styled from 'styled-components/macro';

type Props = {
  fromScreen?: string;
  customer: SuperAdminCustomerDetails;
  prevSearch?: string;
  statusFilter?:
    | VerificationStatus.ACTIVE
    | VerificationStatus.ALL
    | VerificationStatus.INACTIVE;
  sortKey?: string;
  order?: PaginationConstants;
  page?: number;
  size?: number;
  tableFields: TableField[];
  showSuperAdminOptions: boolean;
  showEditRoleButton: boolean;
  showImportModal: boolean;
  closeUploadModal(): void;
  onSelectStatus(statusValue: VerificationStatus): void;
  onSearch(search: string): void;
  onSorting(sortKey: string, order: PaginationConstants): void;
  onPageAndSizeChange(page: number, size: number): void;
  refreshCustomer(): void;
};

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

export const UserListing = ({
  fromScreen,
  customer,
  prevSearch,
  statusFilter,
  sortKey,
  order,
  page,
  size,
  tableFields,
  showSuperAdminOptions,
  showEditRoleButton,
  showImportModal,
  closeUploadModal,
  onSelectStatus,
  onSearch,
  onSorting,
  onPageAndSizeChange,
  refreshCustomer,
}: Props) => {
  const [selectedUsersData, setSelectedUsersData] = useState<SuperAdminUser[]>(
    []
  );

  const history = useHistory();

  // All filters are fetched from the URL
  const filter: UserListFilter = {
    status: statusFilter ?? VerificationStatus.ALL,
    sortKey: sortKey,
    order: order ?? PaginationConstants.ASCENDING,
    page: page ?? DEFAULT_PAGE,
    size: size ?? DEFAULT_SIZE,
    searchQuery: prevSearch,
    customerId: customer.customerId,
  };

  const {
    count: userCount,
    userArray,
    refetch: refreshUserList,
    isLoading: showLoading,
  } = useUserQuery({
    params: {
      start: (filter.page || DEFAULT_PAGE) * (filter.size || DEFAULT_SIZE),
      limit: filter.size || DEFAULT_SIZE,
      search: filter.searchQuery,
      status: filter.status ?? VerificationStatus.ALL,
      sortKey: filter.sortKey,
      order: filter.order,
      customerId: filter.customerId,
    },
    filter,
    enabled: true,
    onSuccess: () => setSelectedUsersData([]),
  });

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      onPageAndSizeChange(newPage, newSize);
    },
    300
  );

  return (
    <>
      {!!selectedUsersData.length && (
        <SelectedUsers
          handleDeselected={() => {}}
          handleSelectAll={() => {}}
          userArray={userArray}
          selectedUsers={selectedUsersData}
          showChangeCustomerButton={showSuperAdminOptions}
          showEditRoleButton={showEditRoleButton}
          setSelectedUsers={(selectedUsers: SuperAdminUser[]) => {
            setSelectedUsersData(selectedUsers);
          }}
          onSuccessfullUpdate={() => {
            refreshUserList();
            refreshCustomer();
          }}
        />
      )}
      <UsersSearchAndFilter
        statusFilter={statusFilter}
        prevSearch={prevSearch}
        onSearch={onSearch}
        onSelectStatus={onSelectStatus}
      />
      {showLoading && (
        <LoadingWrapper>
          <LoadingIndicator isLoading={showLoading} height='300px' />
        </LoadingWrapper>
      )}
      {!showLoading && !!userArray.length && (
        <UserTable
          userCount={userCount}
          userArray={userArray}
          selectedUsersData={selectedUsersData}
          page={filter.page}
          size={filter.size}
          sortKey={filter.sortKey}
          order={filter.order}
          tableFields={tableFields}
          onPaginationChange={onPaginationChange}
          onSortingUpdate={onSorting}
          openUserDetails={(user: SuperAdminUser) => {
            history.push(
              `/admin/users/${user.id}/${
                fromScreen === SCREEN_VALUES.RESELLERS
                  ? SCREEN_VALUES.RESELLERS
                  : SCREEN_VALUES.CUSTOMERS
              }`
            );
          }}
          onUserSelect={(user: SuperAdminUser, checked: boolean) => {
            setSelectedUsersData(
              checked
                ? [...selectedUsersData, user]
                : selectedUsersData.filter(
                    (e: SuperAdminUser) => e.id != user.id
                  )
            );
          }}
        />
      )}
      {!showLoading && !userArray.length && <NoList>No users to show.</NoList>}
      {showImportModal && (
        <ImportUserModal
          modalType={ModalType.BULK}
          title='Import users '
          customerId={customer.customerId}
          origin={ModalOrigin.SUPER_ADMIN_USERS}
          automotiveEnabled={customer.automotiveAccess}
          text='Please make sure your .csv file contains the required fields'
          handleModalClose={shouldRefresh => {
            if (shouldRefresh) {
              refreshUserList();
              refreshCustomer();
            }

            closeUploadModal();
          }}
          handleSubmit={() => {}}
        />
      )}
    </>
  );
};
