import React from 'react';
import VinSolutionsImage from 'lib/images/VinSolutions-logo.png';
import edealer from 'lib/images/edealer.png';
import autoSweet from 'lib/images/autoSweet.png';
import predian from 'lib/images/predian.png';
import dealerInspire from 'lib/images/dealerInspire.png';
import homenet from 'lib/images/homenet.png';
import vauto from 'lib/images/vauto.png';
import dealerslink from 'lib/images/dealerslink.png';
import promax from 'lib/images/promax.png';
import acvmMax from 'lib/images/acvmax.png';
import autominer from 'lib/images/autominer.jpg';
import vincue from 'lib/images/VINCUE_DealerCue_Logo.jpg';
import autorevolution from 'lib/images/autorevolution.png';
import dealerCenter from 'lib/images/dealerCenter.png';
import tms from 'lib/images/tms.png';
import dealercloud from 'lib/images/dealercloud.png';
import dealercom from 'lib/images/dealercom.png';
import wheelstv from 'lib/images/wheelstv.png';
import credit700 from 'lib/images/700credit.png';
import youtube from 'lib/images/youtube-logo.png';
import facebook from 'lib/images/facebook-logo.png';
import linkedin from 'lib/images/linkedin.png';
import lesa from 'lib/images/lesa.png';
import vinmotion from 'lib/images/vinmotion.png';
import dealercarsearch from 'lib/images/dealercarsearch.jpg';
import carsforsale from 'lib/images/carsforsale.jpg';

import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import {
  ANDROID_INSTALL_LINK,
  CDS_CATEGORIES,
  CHROME_DOWNLOAD_LINK,
  CHROME_HOW_TO_LINK,
  DISCOVER_BY_FILTERS,
  ELEAD_HELP_DESK_LINK,
  GMAIL_INSTALL_LINK,
  INTEGRATION,
  INTEGRATION_TYPE,
  IntegrationProps,
  INTEGRATIONS_CATEGORIES,
  IOS_URL,
  IPACKET_HELP_DESK_LINK,
  LINKEDIN_HELP_DESK_LINK,
  LINKEDIN_INSTALL_LINK,
  MOBILE_FAQ,
  MOBILE_HELP_DESK_LINK,
  OFFICE_365_HELP_DESK_LINK,
  OUTLOOK_HELP_DESK_LINK,
  SALESFORCE_HELP_DESK_LINK,
  VIN_SOLUTIONS_HELP_DESK_LINK,
  WHEELSTV_HELP_DESK_LINK,
} from './constants';
import {
  ActionTypes,
  IntegrationDetailsPage,
} from '../details/IntegrationDetailsPage';
import { Zoom } from '../Zoom';
import { Salesforce } from '../Salesforce';
import { useAuth } from 'lib/context';
import {
  checkIfDealerSocketAccessible,
  checkIfHubspotAndElementAccessible,
} from 'lib/utils/automotiveRolePermissionChecks';
import { Gap } from 'lib/components/styles/layout';

export const useIntegrationData = () => {
  const { userData, whitelabel } = useAuth();

  /* SUS-1234 changes integrations/dealersocket route are accessible to CDS account with SERVICE_MANAGER, SERVICE_ADVISOR, SALES_MANAGER, TECHNICIAN, SALESPERSON role only and Supervisor CDS user  */
  const isDealerSocketAccessible = checkIfDealerSocketAccessible(userData);

  /* SUS-1234 changes integrations/hubspot and integrations/element451 route are accessible to Supervisor CDS user, Legacy User and standard user with no CDS  */
  const isHubspotAndElementAccessible =
    checkIfHubspotAndElementAccessible(userData);

  /******/
  const isSalesforceFeatureEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.SALESFORCE_INTEGRATION_BASIC
  );

  const INTEGRATIONS: IntegrationProps[] = [
    {
      key: 'chrome',
      name: INTEGRATION.CHROME,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Use Covideo directly inside your Chrome browser with this helpful
            integration. Record your webcam, screen, or both with the click of a
            button or easily take and markup screenshots of your page. You can
            also access existing videos from your library and access your custom
            landing pages.
          </div>
          <div>
            Need help with installation?{' '}
            <a href={CHROME_HOW_TO_LINK} target='_blank' rel='noreferrer'>
              Check out our help article
            </a>{' '}
            for help downloading this extension. You can download the Covideo
            Chrome extension{' '}
            <a href={CHROME_DOWNLOAD_LINK} target='_blank' rel='noreferrer'>
              here
            </a>
            .
          </div>
        </Gap>
      ),
      path: '/integrations/chrome',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(CHROME_DOWNLOAD_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(CHROME_DOWNLOAD_LINK, '"_new"');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/chrome.png',
      thumbnail: 'https://images.covideo.com/thumbnails/chrome_integration.png',
      videoSource: 'https://videos.covideo.com/website/chrome_integration.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'gmail',
      name: INTEGRATION.GMAIL,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Use Covideo without leaving your Gmail account! This integration
            adds a “record video” button to your inbox, as well as a way to
            access your Covideo library when composing a new email. You can
            record from your webcam, screen, or both directly into Gmail. When
            you click upload, it will automatically be added to a new email.
            Check your activity sidebar to get insights into the videos you’ve
            sent through Gmail—seeing if they were delivered, opened, or played.
          </div>
          <div>
            <a href={GMAIL_INSTALL_LINK} target='_blank' rel='noreferrer'>
              Install Covideo for Gmail
            </a>{' '}
            and give it a try!
          </div>
        </Gap>
      ),
      path: '/integrations/gmail',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(GMAIL_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(GMAIL_INSTALL_LINK, '"_new"');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/gmail.png',
      productFeatureId: productFeature.GMAIL_INTEGRATION,
      thumbnail: 'https://images.covideo.com/thumbnails/gmail_integration.png',
      videoSource: 'https://videos.covideo.com/website/gmail_integration.mp4 ',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'linkedIn',
      name: INTEGRATION.LINKEDIN,
      categories: [INTEGRATIONS_CATEGORIES.SOCIAL],
      description: (
        <Gap>
          <div>
            There are two ways to use Covideo on LinkedIn. The first is to
            download the Covideo extension for your Chrome browser. This
            extension automatically allows you to send Covideos through direct
            messages in LinkedIn. You can also add Covideo links into your
            public posts.
          </div>
          <div>
            Additionally, you can connect your LinkedIn to your social profiles.
            Click your profile icon and select “social profiles.” There will be
            an option to connect your LinkedIn account. You can push videos
            directly to your personal profile and to your company account, if
            you are a manager on LinkedIn.
          </div>
          <div>
            For more information on installing and using the LinkedIn
            integration,{' '}
            <a href={LINKEDIN_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              check out our Help Desk article
            </a>
            .
          </div>
          <div>
            Install the Chrome extension to access the LinkedIn integration{' '}
            <a href={LINKEDIN_INSTALL_LINK} target='_blank' rel='noreferrer'>
              here
            </a>
            .
          </div>
        </Gap>
      ),
      path: '/integrations/linkedIn',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          connectSocialProfile
          onClick={() => {
            window.open(LINKEDIN_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(LINKEDIN_INSTALL_LINK, '"_new"');
      },
      imgSrc: linkedin,
      productFeatureId: productFeature.LINKEDIN,
      thumbnail:
        'https://covideosystems.s3.amazonaws.com/thumbnails/LinkedInIntegration.png',
      videoSource:
        'https://videos.covideo.com/videos/59777_3_23q42qgv87j1631813018.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'office',
      name: INTEGRATION.OFFICE_365,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Do you use Microsoft Office 365? You can access Covideo directly
            from your email inbox.
          </div>
          <div>
            When you create a new email, click the Covideo icon to add video
            messages from your library into your email. You can also record new
            videos or screen recordings without ever leaving the email platform.
            Gain additional insights from the Covideo add-in: see data from past
            interactions with your contacts, including emails sent, emails
            opened, and videos viewed. The Office 365 add-in is available online
            and on your PC.
          </div>
          <div>
            You can learn more about using and installing this integration{' '}
            <a
              href={OFFICE_365_HELP_DESK_LINK}
              target='_blank'
              rel='noreferrer'
            >
              on our Help Desk
            </a>
            . Download this integration{' '}
            <a
              href={OFFICE_365_HELP_DESK_LINK}
              target='_blank'
              rel='noreferrer'
            >
              on Microsoft AppSource
            </a>
            .
          </div>
        </Gap>
      ),
      path: '/integrations/office',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(OFFICE_365_HELP_DESK_LINK, '_blank', 'noopener');
          }}
        />
      ),
      onClick: () => {
        window.open(OFFICE_365_HELP_DESK_LINK, '_blank', 'noopener');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/office-365.png',
      enabled: true,
      thumbnail:
        'https://images.covideo.com/thumbnails/office_365_integration.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_q29w1p3fkhf1620740226.mp4',
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'outlook',
      name: INTEGRATION.OUTLOOK,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Download Covideo’s Outlook add-in to your computer to install. You
            then can find Covideo under the add-ins tab in Outlook.
          </div>
          <div>
            Use this integration to access Covideo directly from inside your
            email platform, allowing you to create and send video messages
            without jumping between software. This also allows you to track
            stats—like when and how many times your email was opened—when you
            send videos through Outlook.
          </div>
          <div>
            <a href={OUTLOOK_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              Check out our installation guide
            </a>{' '}
            for assistance with downloading the add-in.
          </div>
        </Gap>
      ),
      path: '/integrations/outlook',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.DOWNLOAD}
          onClick={() => {
            window.open(whitelabel.outlookURL, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(whitelabel.outlookURL, '"_new"');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/outlook-blue.svg',
      thumbnail:
        'https://images.covideo.com/thumbnails/outlook_integration.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_z2hegk5bjq1667236480.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'zoom',
      name: INTEGRATION.ZOOM,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Connect Zoom to Covideo to turn your recordings into engaging
            content! This integration allows any Zoom recording you’ve saved to
            the Cloud to automatically upload into your Covideo account.
          </div>
          <div>
            Unlimited Covideo storage means you’ll never need to delete
            recordings to make space. You can take advantage of Covideo’s
            helpful editing tools, like the ability to generate captions, trim
            and merge recordings, annotate your videos, and more. You can also
            easily share and track these videos.
          </div>
          <div>
            <a href={LINKEDIN_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              Check out our Help Desk article
            </a>{' '}
            for information on setting up the Covideo Zoom Integration.
          </div>
        </Gap>
      ),
      path: '/integrations/zoom',
      component: Zoom,
      imgSrc: 'https://www.covideo.com/backoffice/images/zoom-logo.svg',
      thumbnail: 'https://images.covideo.com/thumbnails/zoom_integration.png',
      videoSource: 'https://videos.covideo.com/website/zoom_integration.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'facebook',
      name: INTEGRATION.FACEBOOK,
      categories: [INTEGRATIONS_CATEGORIES.SOCIAL],
      description:
        'You can connect your Facebook profile with Covideo. Click your profile icon and select “social profiles.” There will be an option to connect your Facebook account. This will allow you to push videos directly to your personal or company profile',
      path: '/integrations/facebook',
      component: () => <IntegrationDetailsPage connectSocialProfile />,
      imgSrc: facebook,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'youtube',
      name: INTEGRATION.YOUTUBE,
      categories: [INTEGRATIONS_CATEGORIES.SOCIAL],
      description:
        'You can connect your YouTube profile with Covideo. Click your profile icon and select “social profiles.” There will be an option to connect your YouTube account. This will allow you to push videos directly to your personal or company profile.',
      path: '/integrations/youtube',
      component: () => <IntegrationDetailsPage connectSocialProfile />,
      imgSrc: youtube,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'iOS',
      name: INTEGRATION.IOS,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Get the Covideo mobile app on your Apple device! Enjoy all Covideo’s
            desktop features—like the ability to record, send, and track your
            videos—on the go.
          </div>
          <div>
            For help installing the Covideo app,{' '}
            <a href={MOBILE_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              check out our Help Desk article
            </a>
            . You can also see a full list of our{' '}
            <a href={MOBILE_FAQ} target='_blank' rel='noreferrer'>
              mobile app FAQ here.
            </a>{' '}
          </div>
        </Gap>
      ),
      path: '/integrations/iOS',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(IOS_URL, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(IOS_URL, '"_new"');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/apple-black-logo.png',
      thumbnail: 'https://images.covideo.com/thumbnails/mobile_integration.png',
      videoSource: 'https://videos.covideo.com/website/mobile_integration.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'android',
      name: INTEGRATION.ANDROID,
      categories: [INTEGRATIONS_CATEGORIES.COMMUNICATIONS],
      description: (
        <Gap>
          <div>
            Get the Covideo mobile app on your Android device! Enjoy all
            Covideo’s desktop features—like the ability to record, send, and
            track your videos—on the go.
          </div>
          <div>
            For help installing the Covideo app,{' '}
            <a href={MOBILE_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              check out our Help Desk article
            </a>
            . You can also see a full list of our{' '}
            <a href={MOBILE_FAQ} target='_blank' rel='noreferrer'>
              mobile app FAQ here.
            </a>{' '}
          </div>
        </Gap>
      ),
      path: '/integrations/android',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(ANDROID_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(ANDROID_INSTALL_LINK, '"_new"');
      },
      imgSrc: 'https://www.covideo.com/backoffice/images/android-logo.png',
      thumbnail: 'https://images.covideo.com/thumbnails/mobile_integration.png',
      videoSource: 'https://videos.covideo.com/website/mobile_integration.mp4 ',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'salesforce',
      name: INTEGRATION.SALESFORCE,
      categories: [INTEGRATIONS_CATEGORIES.CRM],
      description: (
        <Gap>
          <div>
            Connecting Covideo with your Salesforce account is easy! Simply use
            the connect button on this page and enter your Salesforce info. Once
            you have enabled this integration, any time you interact with an
            existing lead or contact through Covideo, it will show up in your
            Salesforce Activity History. You can also choose which data from
            Covideo to sync with your Salesforce account.
          </div>
          <div>
            With our native Covideo for Salesforce integration you can record
            new or insert previous videos without leaving Salesforce. This
            native integration is an add-on, so please contact your Covideo
            account manager if you would like to learn more and to enable this
            integration.
          </div>
          <div>
            For more information on how to add and use the Salesforce
            integration,{' '}
            <a
              href={SALESFORCE_HELP_DESK_LINK}
              target='_blank'
              rel='noreferrer'
            >
              Check out our Help Desk article
            </a>
            .
          </div>
        </Gap>
      ),
      path: '/integrations/salesforce',
      component: Salesforce,
      imgSrc: 'https://www.covideo.com/backoffice/images/Salesforce_logo.png',
      thumbnail:
        'https://images.covideo.com/thumbnails/SalesforceIntegration_Thumbnail.png',
      videoSource:
        'https://d2k7766kmw4j3n.cloudfront.net/website/SalesforceIntegration.mp4',
      enabled: isSalesforceFeatureEnabled,
      show: [INTEGRATION_TYPE.AUTOMOTIVE, INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'element451',
      name: INTEGRATION.ELEMENT_451,
      categories: [
        INTEGRATIONS_CATEGORIES.CRM,
        INTEGRATIONS_CATEGORIES.MARKETING,
      ],
      description: (
        <Gap>
          <div>
            Record and share personalized videos inside your conversations with
            the Covideo integration for Element451. You’ll see the Covideo icon
            below the dialogue box for quick access to your recorded videos and
            tools to record a new video. It’s an easy, convenient way to
            connect!
          </div>
          <div>
            This integration is now available within your Element451 account.
            Please reach out to your Element451 account manager to begin using
            this integration.
          </div>
        </Gap>
      ),
      path: '/integrations/element451',
      component: () => <IntegrationDetailsPage />,
      imgSrc: 'https://covideosystems.s3.amazonaws.com/website/Element451.png',
      productFeatureId: productFeature.ELEMENT451_INTEGRATION,
      thumbnail:
        'https://images.covideo.com/thumbnails/71305_3_skdzlktmb31634912808_0001.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_skdzlktmb31634912808.mp4',
      enabled: isHubspotAndElementAccessible,
      automotiveHidden: true,
      show: [INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: 'hubspot',
      name: INTEGRATION.HUBSPOT,
      categories: [
        INTEGRATIONS_CATEGORIES.CRM,
        INTEGRATIONS_CATEGORIES.MARKETING,
      ],
      description: (
        <Gap>
          <div>
            Access your entire library of videos, record new videos, and share
            them within HubSpot. Covideo also works in your notes for each
            contact. You can add Zoom recordings and meeting recaps for easy
            access. Use video within your HubSpot conversations and track your
            impact with in-depth analytics.
          </div>
          <div>
            Install the Covideo Chrome extension to access the HubSpot
            integration{' '}
            <a href={CHROME_DOWNLOAD_LINK} target='_blank' rel='noreferrer'>
              here
            </a>
            .
          </div>
        </Gap>
      ),
      path: '/integrations/hubspot',
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(CHROME_DOWNLOAD_LINK, '"_new"');
          }}
        />
      ),
      imgSrc: 'https://covideosystems.s3.amazonaws.com/website/hubspot.png',
      productFeatureId: productFeature.HUBSPOT,
      thumbnail:
        'https://images.covideo.com/thumbnails/71305_3_40ywfogudq1639153694_0001.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_40ywfogudq1639153694.mp4',
      enabled: isHubspotAndElementAccessible,
      automotiveHidden: true,
      show: [INTEGRATION_TYPE.UNIVERSAL],
    },
    {
      key: '700Credit',
      name: INTEGRATION.CREDIT700,
      categories: [CDS_CATEGORIES.FINANCE],
      description:
        '700Credit is the leading provider of credit reports, compliance solutions, and authentication services for the automotive industry. 700Credit easily integrates with Covideo to combine this useful information with your personalized videos. Contact your account manager to set up this integration.',
      path: '/integrations/700Credit',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: credit700,
      thumbnail: '',
      videoSource: '',
      enabled: true,
      automotiveHidden: false,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealersocket',
      name: INTEGRATION.DEALERSOCKET,
      categories: [INTEGRATIONS_CATEGORIES.CRM],
      description: (
        <Gap>
          <div>
            Introducing a revolutionary upgrade in automotive communication: the
            seamless integration of Covideo’s video messaging tool with
            DealerSocket’s CRM!
          </div>
          <div>
            This partnership enables DealerSocket users to easily record, send,
            and track Covideos all directly from inside its desktop and the
            mobile app. Your contacts from DealerSocket will also be available
            in Covideo.
          </div>
          <div>
            The integration instantly logs activities to customer records in
            DealerSocket CRM and provides valuable analytics, connecting videos
            watched with vehicles sold.
          </div>
          <div>
            Please contact your account manager if you would like to get
            started.
          </div>
        </Gap>
      ),
      path: '/integrations/dealersocket',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc:
        'https://covideosystems.s3.amazonaws.com/website/dsextraspace.png',
      productFeatureId: productFeature.DEALERSOCKET_INTEGRATION,
      thumbnail: '',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_omlgq0sytg1671199780.mp4',
      enabled: isDealerSocketAccessible,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'vinsolutions',
      name: INTEGRATION.VIN_SULUTIONS,
      categories: [INTEGRATIONS_CATEGORIES.CRM, CDS_CATEGORIES.INVENTORY],
      description: (
        <Gap>
          <div>
            The Covideo integration for VinSolutions makes recording, sending,
            and tracking videos easier than ever. When a contact engages with
            your video, that activity will automatically show up under their
            Customer Record in VinSolutions—no extra manual work on your part
            required. Videos sent, opens, views, and call-to-action clicks
            record directly in the customer’s “Notes & History.”
          </div>
          <div>
            Contact your account manager for directions on setting up your
            VinSolutions integration.. You can learn more about this
            <a
              href={VIN_SOLUTIONS_HELP_DESK_LINK}
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              our Help Desk article
            </a>
          </div>
        </Gap>
      ),
      path: '/integrations/vinsolutions',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: VinSolutionsImage,
      productFeatureId: productFeature.VINSOLUTIONS,
      thumbnail:
        'https://images.covideo.com/thumbnails/71305_3_tppn4s0ebj1642096847_0001.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_tppn4s0ebj1642096847.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'elead',
      name: INTEGRATION.ELEAD,
      categories: [INTEGRATIONS_CATEGORIES.CRM, DISCOVER_BY_FILTERS.FEATURED],
      description: (
        <Gap>
          <div>
            Covideo’s integration with Elead offers a fast, streamlined process
            for recording, sending, and tracking videos. No need to jump back
            and forth between applications--you can do everything from start to
            finish from a single screen, in a fraction of the time.
          </div>
          <div>
            Once your administrator grants you access, you can click the
            “videos” button in your Elead interface in your email composer and
            log in to Covideo. You can record new videos, merge videos, insert
            existing videos, and add voiceovers. You can also search your
            library for videos you have saved. Plus, you can track Covideos in
            your customer’s completed activity history and access more in-depth
            analytics in your Covideo library.
          </div>
          <div>
            <a href={ELEAD_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              Our Help Desk article
            </a>{' '}
            for more information about activating and using this integration.
          </div>
        </Gap>
      ),
      path: '/integrations/elead',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: 'https://www.covideo.com/backoffice/images/elead-logo.png',
      productFeatureId: productFeature.ELEAD_INTEGRATION,
      videoSource:
        'https://videos.covideo.com/videos/71305_3_t42qvgur9k1626123705.mp4',
      thumbnail:
        'https://images.covideo.com/thumbnails/71305_3_nd7pr3z6f0l1625769252_0001.png',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'iPacket',
      name: INTEGRATION.IPACKET,
      categories: [
        CDS_CATEGORIES.VEHICLE_PRESENTATION,
        CDS_CATEGORIES.CONTENT,
        CDS_CATEGORIES.INVENTORY,
        DISCOVER_BY_FILTERS.FEATURED,
      ],
      description: (
        <Gap>
          <div>
            Covideo and iPacket have teamed up on a video integration that
            allows you to easily combine your personalized Covideos with
            iPacket’s vehicle presentations and sales materials. Give your
            buyers a white-glove experience by enhancing your presentations with
            personalized intro videos or wow them by including a walkaround of
            the exact vehicle in their iPacket.
          </div>
          <div>
            And, since it’s all done with a few clicks directly within the
            iPacket mobile app or dealer portal, there’s practically no extra
            effort required.
          </div>
          <div>
            To see a step-by-step guide to how the Covideo integration for
            iPacket works, check out this helpful{' '}
            <a href={IPACKET_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              how-to-article
            </a>{' '}
            in the Covideo Knowledge Base.
          </div>
        </Gap>
      ),
      path: '/integrations/iPacket',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc:
        'https://covideosystems.s3.amazonaws.com/thumbnails/iPacketLogoLarge.png',
      productFeatureId: productFeature.IPACKET,
      thumbnail:
        'https://covideosystems.s3.amazonaws.com/thumbnails/71305_3_8eegw8xomt1631124108_0001.png',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_byp1k3tbcz1669919304.mp4',
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'promax',
      name: INTEGRATION.PROMAX,
      categories: [INTEGRATIONS_CATEGORIES.CRM],
      description: (
        <Gap>
          <div>
            ProMax’s CRM helps dealerships simplify processes, communicate with
            leads, and make more sales from one platform. You can share your
            Covideos over emails, texts, and more while using the ProMax CRM
            integration.
          </div>
          <div>
            Contact your account manager to learn more about this free
            integration.
          </div>
        </Gap>
      ),
      path: '/integrations/promax',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: promax,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'tms',
      name: INTEGRATION.TMS,
      categories: [INTEGRATIONS_CATEGORIES.CRM],
      description: (
        <Gap>
          <div>
            Showroom Traffic Manager is a lead management solution for
            automotive companies. If you send Covideos and have this integration
            enabled, the stats for your videos will show up in your TMS history.
          </div>
          <div>
            Contact your account manager to set up this free integration with
            the TMS CRM.
          </div>
        </Gap>
      ),
      path: '/integrations/tms',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: tms,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'autominer',
      name: INTEGRATION.AUTOMINER,
      categories: [
        CDS_CATEGORIES.DATA,
        INTEGRATIONS_CATEGORIES.MARKETING,
        DISCOVER_BY_FILTERS.FEATURED,
      ],
      description: (
        <Gap>
          <div>
            Update your AutoMiner campaigns by integrating with Covideo for
            greater impact!
          </div>
          <div>Contact your account manager to set up this integration.</div>
        </Gap>
      ),
      path: '/integrations/autominer',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autominer,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'edealer',
      name: INTEGRATION.EDEALER,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: ` Edealer’s inventory management solution integrates with Covideo.
      Contact your account manager to set up the free Edealer integration.`,
      path: '/integrations/edealer',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: edealer,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'autoSweet',
      name: INTEGRATION.AUTO_SWEET,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: ` AutoSweet helps dealerships drive quality website traffic, convert leads and match back 
      offline sales from their online advertising. 
      Contact your Covideo account manager to integrate your AutoSweet vehicle 
      inventory with Covideo.`,
      path: '/integrations/autoSweet',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autoSweet,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'predian',
      name: INTEGRATION.PREDIAN,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: ` Predian helps dealerships accurately appraise the prices of the vehicles 
      and ensures every vehicle is well-represented across all marketing channels 
      for maximum visibility. Contact your Covideo account manager to integrate your 
      Predian vehicle inventory with Covideo.`,
      path: '/integrations/predian',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: predian,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealerInspire',
      name: INTEGRATION.DEALER_INSPIRE,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: ` Dealer Inspire helps dealerships increase online visibility, 
      engage customers and drive sales. Contact your Covideo account manager to 
      integrate your Dealer Inspire vehicle inventory with Covideo.`,
      path: '/integrations/dealerInspire',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerInspire,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'homenet',
      name: INTEGRATION.HOMENET,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `HomeNet Automotive helps dealerships get their inventory in front of
      online shoppers and simplifies the merchandising process. Contact
      your account manager to integrate Covideo with HomeNet.`,
      path: '/integrations/homenet',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: homenet,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'vauto',
      name: INTEGRATION.VAUTO,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `vAuto’s inventory management solution integrates with Covideo.
      Contact your account manager to get started.`,
      path: '/integrations/vauto',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vauto,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealerslink',
      name: INTEGRATION.DEALERSLINK,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `Dealerslink’s inventory management solution integrates with Covideo.
      Contact your account manager to get started.`,
      path: '/integrations/dealerslink',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerslink,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'acvmax',
      name: INTEGRATION.ACV_MAX,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `ACV MAX’s inventory management solution integrates with Covideo.
          Contact your account manager to get started`,
      path: '/integrations/acvmax',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: acvmMax,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'vincue',
      name: INTEGRATION.VIN_CUE,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'Sync your VinCue inventory data with Covideo. Contact your account manager to get started.',
      path: '/integrations/vincue',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vincue,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'autorevolution',
      name: INTEGRATION.AUTO_REVOLUTION,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'Connect your Auto Revolution inventory with Covideo. To integrate your Auto Revolution account with Covideo, contact your account manager to get started.',
      path: '/integrations/autorevolution',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autorevolution,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealerCenter',
      name: INTEGRATION.DEALER_CENTER,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'DealerCenter’s inventory management tool integrates with Covideo. To access this integration, contact your account manager to get started.',
      path: '/integrations/dealerCenter',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerCenter,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'wheelsTV',
      name: INTEGRATION.WHEELS_TV,
      categories: [CDS_CATEGORIES.INVENTORY, CDS_CATEGORIES.CONTENT],
      description: (
        <Gap>
          <div>
            Covideo’s WheelsTV integration allows you to combine personalization
            with polished, professional vehicle videos. WheelTV offers a library
            of more than 5,600 OEM-approved walkaround and test drive videos,
            and you can use them all from your Covideo account! Our merge
            feature enables you to easily combine your personal messages with
            professional vehicle walkarounds, giving your buyers an
            unforgettable experience.
          </div>
          <div>
            And, since it’s all done with a few clicks directly within the
            iPacket mobile app or dealer portal, there’s practically no extra
            effort required.
          </div>
          <div>
            Check out{' '}
            <a href={WHEELSTV_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
              our HelpDesk article
            </a>{' '}
            for more information on using WheelsTV with Covideo. To activate the
            integration, contact your account manager. To see a step-by-step
            guide to how the Covideo integration for iPacket works, check out
            this helpful{' '}
          </div>
        </Gap>
      ),
      path: '/integrations/wheelsTV',
      videoSource:
        'https://videos.covideo.com/videos/71305_3_7xkp2xad461669919301.mp4',
      thumbnail:
        'https://images.covideo.com/thumbnails/71305_3_7xkp2xad461669919301_0001.png',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: wheelstv,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealerCloud',
      name: INTEGRATION.DEALER_CLOUD,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'dealer.cloud’s inventory management tool integrates with Covideo. To access this integration, contact your account manager to get started.',
      path: '/integrations/dealerCloud',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercloud,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealerCom',
      name: INTEGRATION.DEALER_COM,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'Dealer.com’s inventory management tool integrates with Covideo. To access this integration, contact your account manager to get started.',
      path: '/integrations/dealerCom',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercom,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'lesa',
      name: INTEGRATION.LESA,
      categories: [CDS_CATEGORIES.INVENTORY, CDS_CATEGORIES.CONTENT],
      description: (
        <Gap>
          <div>
            If you use LESA for high-quality, full-motion videos, you can
            integrate its tool with Covideo. Combine personalization with
            polished, professional vehicle videos for an experience your
            customers won’t forget.
          </div>
          <div>
            Contact your Covideo account manager for help setting up this
            integration.
          </div>
        </Gap>
      ),
      path: '/integrations/lesa',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: lesa,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'vinmotion',
      name: INTEGRATION.VINMOTION,
      categories: [CDS_CATEGORIES.INVENTORY],
      description:
        'VinMotion by Dealer Specialties is an inventory merchandising software enabling dealerships to manage images and create AI-powered vehicle listings. Contact your representative to integrate Covideo with this tool.',
      path: '/integrations/vinmotion',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vinmotion,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'dealercarsearch',
      name: INTEGRATION.DEALER_CAR_SEARCH,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `${INTEGRATION.DEALER_CAR_SEARCH} inventory management solution integrates with Covideo. Contact your account manager to get started.`,
      path: '/integrations/dealercarsearch',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercarsearch,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
    {
      key: 'carsforsale',
      name: INTEGRATION.CARS_FOR_SALE,
      categories: [CDS_CATEGORIES.INVENTORY],
      description: `${INTEGRATION.CARS_FOR_SALE} inventory management solution integrates with Covideo. Contact your account manager to get started.`,
      path: '/integrations/carsforsale',
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: carsforsale,
      enabled: true,
      show: [INTEGRATION_TYPE.AUTOMOTIVE],
    },
  ];

  return {
    INTEGRATIONS,
  };
};
