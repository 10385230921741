import { useMutation } from 'react-query';
import { useToastError } from 'lib/hooks';
import axios from 'axios';
import { useState } from 'react';

export interface UploadData {
  url: string;
  file: File;
  contentType?: string;
  id?: number;
}

interface IProgress {
  loaded: number;
  total: number;
  percentage: number;
}

const S3UploadService = async (
  data: UploadData,
  onProgress?: (progress: IProgress) => void
): Promise<any> => {
  const { url, file, contentType } = data;
  const isFirefox = navigator?.userAgent?.indexOf('Firefox') !== -1;

  const config = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      if (onProgress) {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        onProgress({
          loaded: Math.floor(loaded / 1024),
          total: Math.floor(total / 1024),
          percentage: percentCompleted,
        });
      }
    },
  };

  const response = await axios.put(url, file, {
    headers: {
      'content-type': !isFirefox && contentType ? contentType : file.type,
    },
    ...config,
  });

  return response;
};

export const useS3UploadMutation = () => {
  const [uploadToS3Progress, setUploadToS3Progress] = useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  const { showError } = useToastError();
  return {
    ...useMutation(
      (data: UploadData) => S3UploadService(data, setUploadToS3Progress),
      {
        onError: err => showError(err),
      }
    ),
    progress: uploadToS3Progress,
  };
};
