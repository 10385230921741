import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { uniqBy, debounce } from 'lodash';
import { SketchPicker, ColorResult } from 'react-color';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdArrowForward } from 'react-icons/md';
import { useOnClickOutside } from 'lib/utils';
import { Modal, Search, Dropdown } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import {
  getDepartments,
  getLandingPages,
  updateDefaults,
  getWebsiteOverlays,
  WebsiteOverlay,
} from 'lib/api';
import { useAuth } from 'lib/context';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { InvertColor } from 'lib/utils';
import { useToastError } from 'lib/hooks';
import { DropdownIcon } from 'lib/images/DropdownIcon';
import { successToast } from 'lib/components/toasts/success';
import { useGetLinkSetsQuery } from 'lib/api/exitLinkSets/getExitLinksSets';
import { OptionItems } from 'lib/components/modal/manageDefaults/ManageDefaultComponents';
import { OptionsSelector } from 'lib/components/modal/manageDefaults/OptionsSelector';
import {
  captionOptions,
  captionVisibilityOptions,
} from 'lib/const/ManageDefaultOptions';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { getManagedUsers } from 'lib/api/users/useManagedUsersQuery';
import { useCustomerWebsiteOverlayStatus } from 'lib/api/customers/useCustomerWebsiteOverlayStatus';
import { WEBSITE_OVERLAY_ID } from 'lib/const/templates';

type PickerProps = {
  isPickerOpen?: boolean;
};

type ColorProps = {
  color?: string;
};

type TitleProps = {
  title?: string;
};

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const Container = styled.div`
  display: flex;
`;

const ContainerList = styled.div`
  width: 500px;
  flex: 4;
`;

const ContainerArrow = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-self: center;
  margin-top: 100px;
`;

const ContainerOptions = styled.div`
  flex: 2;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContainerListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0px;
`;

const MainUserList = styled.div`
  margin-top: 10px;
  background: #f6f7f9;
  padding: 16px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 0px;
  .item--label {
    display: flex;
    align-items: center;
    label {
      padding-left: 5px;
    }
  }
  .email {
    color: #9297a2;
    font-size: smaller;
  }
`;

const PickerWrapper = styled.div``;

const Picker = styled(SketchPicker)<PickerProps>`
  display: ${props => !props.isPickerOpen && 'none'};
  position: ${props => props.isPickerOpen && 'absolute'};
  top: ${props => props.isPickerOpen && '-283px'};
  left: ${props => props.isPickerOpen && '0'};
  right: ${props => props.isPickerOpen && '0'};
  z-index: 6;
  margin-bottom: 20px; /* just to make sure we have some margin on the bottom of the screen */
`;

const ColorSelect = styled.div<PickerProps>`
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.isPickerOpen
        ? theme.palette.covideoBlue100
        : theme.palette.covideoGray40};
  border-radius: 4px;
  height: 40px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  ${theme.mediaQueryMinWidth.cmd} {
    width: 268px;
  }
  color: rgb(51, 51, 51);
  cursor: pointer;
  :hover {
    border: 1px solid ${theme.palette.covideoBlue100};
  }
`;

const Color = styled.div<ColorProps>`
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 2px;
  background-color: ${props => props.color};
`;

const ColorTitle = styled.div<TitleProps>`
  position: relative;
  right: ${props => (props.title ? '0px' : '40px')};
`;

const emailIconOptions = [
  { title: 'Do not change', id: -1 },
  { title: 'Animated snapshot', id: 135 },
  { title: 'Video snapshot', id: 8 },
];

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
};

export const ManageDefaultModal = (props: Props) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [allSelected, setAllSelected] = React.useState(false);
  const [deptList, setDeptList] = React.useState<any>([]);
  const [selectedDept, setSelectedDept] = React.useState<any>(0);
  const [selectedUser, setSelectedUser] = React.useState<any>([]);
  const [usersList, setUsersList] = React.useState<any>([]);
  const [totalUser, setTotalUser] = React.useState(0);
  const [templateLoading, setTemplateLoading] = React.useState<any>(false);
  const [templateOptions, setTemplateOptions] = React.useState<any>([
    { label: 'Do not change', value: 0 },
  ]);
  const [linkSetOptions, setLinkSetOptions] = React.useState<any>([
    { title: 'Do not change', id: 0 },
  ]);
  const [overlayOptions, setOverlayOptions] = React.useState<
    Partial<WebsiteOverlay>[]
  >([{ title: 'Do not change', id: 0 }]);
  const [pageTemplate, setPageTemplate] = React.useState(0);
  const [pageTemplateCopy, setPageTemplateCopy] = React.useState(0);
  const [searchTemplate, setSearchTemplate] = React.useState('');
  const [templateCount, setTemplateCount] = React.useState<any>(0);
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [rotate, setRotate] = React.useState('down');
  const [iconColor, setIconColor] = React.useState('#F6F7F9');
  const [defaultData, setDefaultData] = React.useState<any>({
    landingPageId: 0,
    ctaSetId: 0,
    playerColor: '',
    emailSetting: -1,
    emailIcon: -1,
    overlayUrlId: 0,
    autoTranscribe: -1,
    transcriptionDefaultEnabled: -1,
  });

  const limitTemplate = 100;
  const size = 10;
  const emailOptions = [
    { title: 'Do not change', id: -1 },
    { title: 'Enable', id: 1 },
    { title: 'Disabled', id: 0 },
  ];
  const { handleModalClose } = props;

  const loadTypes = { MORE: 'more', INITIAL: 'initial' };

  const {
    userData: { customerId },
  } = useAuth();

  const { data: linksetsData, isLoading: linksetsLoading } =
    useGetLinkSetsQuery({ limit: 1000 });

  const { data: allowWebsiteOverlay, isLoading: isStatusLoading } =
    useCustomerWebsiteOverlayStatus({
      customerId,
    });

  const { ref } = useOnClickOutside(() => setIsPickerOpen(false));

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const getData = () => {
    const payload: any = {};
    const {
      landingPageId,
      ctaSetId,
      playerColor,
      emailSetting,
      emailIcon,
      overlayUrlId,
      autoTranscribe,
      transcriptionDefaultEnabled,
    } = defaultData;
    if (landingPageId != 0) {
      payload['designId'] = landingPageId;
    }
    if (ctaSetId != 0) {
      payload['ctaSetId'] = ctaSetId;
    }
    if (playerColor != '') {
      payload['playerColor'] = { playerBackgroundColor: playerColor };
    }
    if (emailSetting != -1) {
      payload['notifyMe'] = emailSetting == 1 ? true : false;
    }
    if (selectedDept == 0 && allSelected) {
      payload['isAll'] = true;
    }
    if (selectedDept != 0 && allSelected) {
      payload['departmentId'] = selectedDept;
    }
    if (!allSelected) {
      payload['userIds'] = selectedUser;
    }
    if (emailIcon !== -1) {
      payload['emailIcon'] = emailIcon;
    }
    if (overlayUrlId !== 0) {
      payload['overlayUrlId'] = overlayUrlId;
    }
    if (autoTranscribe !== -1) {
      payload.autoTranscribe = autoTranscribe === 1;
    }
    if (transcriptionDefaultEnabled !== -1) {
      payload.transcriptionDefaultEnabled = transcriptionDefaultEnabled === 1;
    }
    return payload;
  };

  const getSelectValue = deptList.find((o: any) => {
    return o.DepartmentID == selectedDept;
  });

  const manageDefault = async () => {
    setLoading(true);
    try {
      const payload = getData();
      await updateDefaults(payload);
      setLoading(false);
      successToast({
        title: `Default applied to all selected users!`,
      });
    } catch (error) {
      showError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const searchTemplateInput = debounce((e: any) => {
    setSearchTemplate(e);
    e ? setPageTemplate(0) : setPageTemplate(pageTemplateCopy);
  }, 600);

  const handleColorSelect = () => {
    rotate === 'down' ? setRotate('up') : setRotate('down');
    isPickerOpen ? setIsPickerOpen(false) : setIsPickerOpen(true);
  };

  const handleChangePlayerBackgroundColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    setDefaultData({ ...defaultData, playerColor: hexAlpha });

    const isBlack = InvertColor(color.hex);

    if (isBlack !== iconColor) {
      setIconColor(isBlack);
    }
  };

  const fetchTemplates = async () => {
    setTemplateLoading(true);
    const templates = await getLandingPages({
      start: pageTemplate * limitTemplate,
      limit: limitTemplate,
      search: searchTemplate,
      showUserCustomLandingPages: false,
    });
    let options: any = JSON.parse(JSON.stringify(templateOptions));
    templates.templates
      .filter(
        t =>
          !(
            (isStatusLoading || !allowWebsiteOverlay) &&
            t.id === WEBSITE_OVERLAY_ID
          )
      )
      .forEach(e => {
        options = [...options, { label: e.title, value: e.id }];
      });
    options = uniqBy(options, 'value');
    setTemplateOptions(options);
    setTemplateCount(templates.count);
    setTemplateLoading(false);
  };

  const loadUsers = async (loadType: string) => {
    try {
      setLoading(true);
      const response = await getManagedUsers({
        customerId,
        page: loadType === loadTypes.INITIAL ? 0 : page,
        size: size,
        departmentId: selectedDept,
        search: searchQuery,
        userActiveStatus: '1',
      });
      if (allSelected) {
        const filterData = response.userList.filter(
          (e: any) => !selectedUser.includes(e.userId)
        );
        const selection = selectedUser;
        filterData.forEach((e: any) => {
          selection.push(e.userId);
        });
        setSelectedUser(selection);
      }

      if (loadType === loadTypes.INITIAL) {
        setPage(0);
        setUsersList([...response.userList]);
      } else {
        setUsersList([...usersList, ...response.userList]);
      }

      setTotalUser(response.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  useEffect(() => {
    //initial load, on searchQuery and on selectedDept change load
    loadUsers(loadTypes.INITIAL);
  }, [selectedDept, searchQuery]);

  useEffect(() => {
    if (page > 0) {
      //infinite load
      loadUsers(loadTypes.MORE);
    }
  }, [page, size]);

  const loadWebsiteOverlays = async () => {
    const overlays = await getWebsiteOverlays({});
    let options: Partial<WebsiteOverlay>[] = overlayOptions;
    const filterArray = overlays.websiteUrls;
    const overlayData = [...options, ...filterArray];
    setOverlayOptions(overlayData);
  };

  useEffect(() => {
    const getDept = async () => {
      try {
        setLoading(true);
        const dept = await getDepartments(customerId, {});
        const mapArr: any = [{ Name: 'All Departments', DepartmentID: 0 }];
        setDeptList([...mapArr, ...dept.data]);
        setSelectedDept(mapArr[0]?.DepartmentID); // Set 'All Departments' as default selected option
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError(error);
      }
    };
    getDept();
    loadWebsiteOverlays();
  }, []);

  const onUsersSearch = (inputValue: string) => {
    setSearchQuery(inputValue);
  };

  useEffect(() => {
    if (selectedUser.length == usersList.length && usersList.length)
      setAllSelected(true);
  }, [selectedUser, totalUser, usersList]);

  React.useEffect(() => {
    fetchTemplates();
  }, [pageTemplate, searchTemplate, allowWebsiteOverlay]);

  React.useEffect(() => {
    let options: any = JSON.parse(JSON.stringify(linkSetOptions));
    const filterArray = linksetsData?.linksets.filter(
      (e: any) => e.company != 0
    );
    const ctaData = [...options, ...(filterArray || [])];
    setLinkSetOptions(ctaData);
  }, [linksetsData]);

  return (
    <Modal style={{ position: 'fixed' }} maxHeight='95%'>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Manage Defaults</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content style={{ width: 900 }}>
          <Container>
            <ContainerList>
              <div style={{ flex: 4, gap: 12, display: 'flex' }}>
                <Search
                  placeholder='Search Users...'
                  onSearch={onUsersSearch}
                  width='50%'
                />
                <div style={{ width: '100%' }}>
                  <Dropdown
                    options={deptList}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.DepartmentID}
                    creatable={false}
                    value={getSelectValue}
                    onChange={(option: any) => {
                      setAllSelected(false);
                      setSelectedUser([]);
                      setUsersList([]);
                      setPage(0);
                      setSelectedDept(option.DepartmentID);
                    }}
                  />
                </div>
              </div>
              <ContainerListHeader>
                <SubHeader>
                  <CheckboxInput
                    blueCheck={true}
                    checkGroupIndicator={selectedUser.length && !allSelected}
                    checked={allSelected}
                    grayCheck={true}
                    ignoreGrayForAllSelect={true}
                    onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                      let { checked } = event.target as HTMLInputElement;
                      if (checked) {
                        setAllSelected(true);
                        setSelectedUser(usersList.map((e: any) => e.userId));
                      } else {
                        setAllSelected(false);
                        setSelectedUser([]);
                      }
                    }}
                  />
                  &nbsp;All users
                </SubHeader>
              </ContainerListHeader>
              <MainUserList>
                <InfiniteScroll
                  dataLength={usersList.length}
                  next={() => {
                    setPage(page + 1);
                  }}
                  hasMore={totalUser > usersList.length}
                  loader={<h4>Loading...</h4>}
                  height={300}
                  endMessage={''}
                >
                  {usersList.length ? (
                    usersList.map((e: any, i: number) => {
                      return (
                        <ListItem key={`user-item-${i}`}>
                          <div className='item--label'>
                            <CheckboxInput
                              blueCheck={true}
                              checkGroupIndicator={false}
                              checked={selectedUser.includes(e.userId)}
                              onChange={(event: React.SyntheticEvent) => {
                                event.stopPropagation();
                                let { checked } =
                                  event.target as HTMLInputElement;
                                if (checked) {
                                  setSelectedUser([...selectedUser, e.userId]);
                                } else {
                                  setAllSelected(false);
                                  setSelectedUser(
                                    selectedUser.filter(
                                      (el: any) => el != e.userId
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 140,
                              }}
                            >
                              {e.firstName} {e.lastName}
                            </label>
                          </div>
                          <label className='email'>{e.email}</label>
                        </ListItem>
                      );
                    })
                  ) : (
                    <div style={{ textAlign: 'center' }}>No data</div>
                  )}
                </InfiniteScroll>
              </MainUserList>
            </ContainerList>
            <ContainerArrow>
              <MdArrowForward size={24} color={'rgba(0, 27, 83, 0.2)'} />
            </ContainerArrow>
            <ContainerOptions>
              <div style={{ flexGrow: 1 }}>
                <b>Defaults</b>
                <OptionItems style={{ marginTop: '5px' }}>
                  Landing page
                  <Dropdown
                    creatable={false}
                    menuPosition='fixed'
                    dropdownHeight={300}
                    options={templateOptions}
                    value={templateOptions.find((o: any) => {
                      return o.value == defaultData.landingPageId;
                    })}
                    onChange={(option: any) => {
                      setDefaultData({
                        ...defaultData,
                        landingPageId: option.value,
                      });
                      setTemplateLoading(false);
                    }}
                    onMenuScrollToBottom={() => {
                      if (templateOptions.length < templateCount)
                        setPageTemplate(pageTemplate + 1);
                      if (!searchTemplate)
                        setPageTemplateCopy(pageTemplate + 1);
                    }}
                    onInputChange={searchTemplateInput}
                    isLoading={templateLoading}
                    onMenuClose={() => setTemplateLoading(false)}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  CTA set
                  <Dropdown
                    creatable={false}
                    options={linkSetOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    value={linkSetOptions.find((o: any) => {
                      return o.id == defaultData.ctaSetId;
                    })}
                    onChange={(option: any) => {
                      setDefaultData({ ...defaultData, ctaSetId: option.id });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  Website Overlay
                  <Dropdown
                    creatable={false}
                    options={overlayOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    value={overlayOptions.find((o: Partial<WebsiteOverlay>) => {
                      return o.id == defaultData.overlayUrlId;
                    })}
                    onChange={(option: WebsiteOverlay) => {
                      setDefaultData({
                        ...defaultData,
                        overlayUrlId: option.id,
                      });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  Player color
                  <PickerWrapper ref={ref} className='option-container'>
                    <ColorSelect
                      onClick={handleColorSelect}
                      isPickerOpen={isPickerOpen}
                    >
                      <Color color={defaultData.playerColor} />
                      <ColorTitle title={defaultData.playerColor}>
                        {defaultData.playerColor == ''
                          ? 'Do not change'
                          : defaultData.playerColor}
                      </ColorTitle>
                      <div
                        style={{
                          position: 'absolute',
                          right: 22,
                          transform: `rotate(${isPickerOpen ? 180 : 0}deg)`,
                        }}
                      >
                        <DropdownIcon
                          width={'12px'}
                          height={'12px'}
                          opacity={0.5}
                        />
                      </div>
                    </ColorSelect>
                    <Picker
                      isPickerOpen={isPickerOpen}
                      width='250'
                      color={defaultData.playerColor}
                      onChangeComplete={handleChangePlayerBackgroundColor}
                    />
                  </PickerWrapper>
                </OptionItems>
                <OptionItems>
                  Email notifications
                  <Dropdown
                    options={emailOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    menuPosition='fixed'
                    creatable={false}
                    value={emailOptions.find((o: any) => {
                      return o.id == defaultData.emailSetting;
                    })}
                    onChange={(option: any) => {
                      setDefaultData({
                        ...defaultData,
                        emailSetting: option.id,
                      });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  Video Thumbnail
                  <Dropdown
                    options={emailIconOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    menuPosition='fixed'
                    creatable={false}
                    value={emailIconOptions.find(o => {
                      return o.id == defaultData.emailIcon;
                    })}
                    onChange={option => {
                      setDefaultData({
                        ...defaultData,
                        emailIcon: option.id,
                      });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <OptionsSelector
                  title='Captions'
                  options={captionOptions}
                  defaultValue={defaultData.autoTranscribe}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      autoTranscribe: option.id,
                    });
                  }}
                />
                <OptionsSelector
                  title='Caption Visibility'
                  options={captionVisibilityOptions}
                  defaultValue={defaultData.transcriptionDefaultEnabled}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      transcriptionDefaultEnabled: option.id,
                    });
                  }}
                />
              </div>
              <Gap>
                <Button
                  text='Apply to selected users'
                  onClick={async () => {
                    await manageDefault();
                  }}
                  disabled={loading || !selectedUser.length || linksetsLoading}
                />
              </Gap>
            </ContainerOptions>
          </Container>
        </Content>
      </div>
    </Modal>
  );
};
