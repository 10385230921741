import React, { useEffect, useState } from 'react';
import { theme } from '../../../../../lib/style';
import { RecurringReportContainer } from '../index.styled';
import RecurringReportItem from '../../components/RecurringReportItem';
import CalendarIcon from 'lib/images/CalendarIcon';
import { ReportDataProps, ResellerReport, useAuth } from 'lib/context';
import {
  deleteResellerReport,
  fetchCustomersByResellerId,
  getResellerReports,
} from 'lib/api';
import { timeRangeConstants } from '../../components';
import CalendarMonthlyIcon from 'lib/images/CalendarMonthly';
import SelectCustomersModal from '../../components/SelectCustomersModal';
import DeactivateReportModal from '../../components/DeactivateReportModal';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import SimplifiedRecurringModal from '../../components/SimplifiedRecurringModal';

type CustomerOptions = {
  id: number;
  checked: boolean;
  business: string;
};

export const RecurringWeeklyMonthly = () => {
  const { userData } = useAuth();
  const { showError } = useToastError();
  const [resellerReports, setResellerReports] = React.useState<
    ResellerReport[]
  >([]);
  const [isCustomReportModalOpen, setIsCustomReportModalOpen] = useState(false);
  const [allCustomers, setAllCustomers] = React.useState<CustomerOptions[]>([]);
  const [isSelectCustomerModalOpen, setIsSelectCustomerModalOpen] =
    useState(false);
  const [selectedReport, setSelectedReport] = useState({
    type: '',
    reportId: 0,
  });
  const [recurringReportData, setRecurringReportData] =
    useState<ReportDataProps>({
      type: '',
    });
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState<number[]>([]);

  const fetchCustomers = async () => {
    const result = await fetchCustomersByResellerId(
      Number(userData.customerId),
      100,
      0,
      true
    );
    if (result.length) {
      const formattedResult = result.map((item: any) => ({
        id: item.customerId,
        business: item.business,
        checked: false,
      }));
      setAllCustomers(formattedResult);
    }
  };

  const fetchResellerReportData = async () => {
    try {
      const result = await getResellerReports();
      setResellerReports(result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const resellerReportingEnabled =
      userData.reseller?.resellerReportingEnabled &&
      userData.reseller.resellerReportingEnabled.toString() === '1';
    if (!resellerReportingEnabled) {
      return;
    }
    fetchCustomers();
    fetchResellerReportData();
  }, [userData]);

  const checkIfReportIsActive = (range: string) => {
    return resellerReports.find((item: any) => item.range === range);
  };

  const monthlyReport =
    checkIfReportIsActive(timeRangeConstants.LAST_30_DAYS) || null;

  const weeklyReport =
    checkIfReportIsActive(timeRangeConstants.LAST_WEEK) || null;

  const formCustomerListFromReport = (report: any) => {
    const tmp = [...allCustomers];
    tmp.forEach((item: any) => {
      const containedInReport = report.customers.find(
        (cust: any) => cust === item.id
      );
      if (containedInReport) item.checked = true;
    });
    setAllCustomers(tmp);
  };

  const formCustomerList = (selectedCustomers: Array<any>) => {
    // code to form list of emails for customer
    const result = selectedCustomers
      .filter(item => item.checked)
      .map(item => item.id);
    setCustomerList(result);
  };

  const resetStates = async () => {
    const temp = [...allCustomers];
    temp.forEach((item, index) => {
      temp[index].checked = false;
    });
    setAllCustomers(temp);
    setCustomerList([]);
    setSelectedReport({ type: '', reportId: 0 });
    setRecurringReportData({ type: '' });
    await fetchResellerReportData();
  };

  const deactivateReport = async (reportId: number) => {
    try {
      if (!reportId) return;
      await deleteResellerReport(reportId);
      resetStates();
      successToast({ title: 'Successfully deactivated report.' });
    } catch (e) {
      showError(
        'custom-error',
        'There was an error deactivating your report. Please try again.'
      );
    }
  };

  if (
    !Number(userData?.reseller?.resellerReportingEnabled) ||
    !Number(userData.isResellerAdmin)
  ) {
    return null;
  }

  return (
    <>
      <RecurringReportContainer>
        <RecurringReportItem
          isActive={!!weeklyReport}
          title={'Weekly'}
          icon={<CalendarIcon color={theme.palette.blue100} />}
          onClick={() => {
            setRecurringReportData({
              title: 'Weekly',
              type: 'weekly',
              reportData: {
                frequency: weeklyReport?.frequency || '',
                deliveryTime: weeklyReport?.deliveryTime || '',
                resellerReportId: weeklyReport?.resellerReportId || 0,
              },
            });
            if (weeklyReport) {
              formCustomerListFromReport(weeklyReport);
            }
            setIsSelectCustomerModalOpen(true);
          }}
          onDeactivate={() => {
            setSelectedReport({
              type: 'weekly',
              reportId: weeklyReport?.resellerReportId || 0,
            });
            setIsDeactivateModalOpen(true);
          }}
        />
        <RecurringReportItem
          isActive={!!monthlyReport}
          title={'Monthly'}
          icon={
            <CalendarMonthlyIcon height={20} color={theme.palette.blue100} />
          }
          onClick={() => {
            setRecurringReportData({
              title: 'Monthly',
              type: 'monthly',
              reportData: {
                frequency: monthlyReport?.frequency || '',
                deliveryTime: monthlyReport?.deliveryTime || '',
                resellerReportId: monthlyReport?.resellerReportId || 0,
              },
            });
            if (monthlyReport) {
              formCustomerListFromReport(monthlyReport);
            }
            setIsSelectCustomerModalOpen(true);
          }}
          onDeactivate={() => {
            setSelectedReport({
              type: 'monthly',
              reportId: monthlyReport?.resellerReportId || 0,
            });
            setIsDeactivateModalOpen(true);
          }}
        />
      </RecurringReportContainer>
      {isSelectCustomerModalOpen && (
        <SelectCustomersModal
          handleModalClose={() => setIsSelectCustomerModalOpen(false)}
          customersArray={allCustomers}
          setCustomersArray={setAllCustomers}
          title={`${recurringReportData.reportId ? `Edit` : ``} Recurring ${
            recurringReportData.title
          } Report`}
          onContinue={() => {
            formCustomerList(allCustomers);
            setIsSelectCustomerModalOpen(false);
            setIsCustomReportModalOpen(true);
          }}
          resetStates={resetStates}
        />
      )}
      {isDeactivateModalOpen && (
        <DeactivateReportModal
          handleModalClose={() => {
            resetStates();
            setIsDeactivateModalOpen(false);
          }}
          type={selectedReport.type}
          onCancel={() => setIsDeactivateModalOpen(false)}
          onSubmit={() => {
            deactivateReport(selectedReport.reportId);
            resetStates();
            setIsDeactivateModalOpen(false);
          }}
        />
      )}
      {isCustomReportModalOpen && (
        <SimplifiedRecurringModal
          title={`${recurringReportData.reportId ? `Edit` : ``} Recurring ${
            recurringReportData.title
          } Report`}
          customers={customerList}
          handleModalClose={() => {
            setIsCustomReportModalOpen(false);
          }}
          resetStates={resetStates}
          report={recurringReportData}
          onBack={() => {
            setIsSelectCustomerModalOpen(true);
            setIsCustomReportModalOpen(false);
          }}
        />
      )}
    </>
  );
};
