import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import bgImageDesktopPhaseOne from 'lib/images/freemiumOnboarding/lines-bg-onboarding.png';
import bgImageDesktopPhaseOneCds from 'lib/images/freemiumOnboarding/lines-bg-onboarding-cds.png';

import bgImageDesktop from 'lib/images/lines-bg-recording.png';
import bgImageMobile from 'lib/images/freemiumOnboarding/lines-bg-onboarding-mobile.png';
import cdsBgImageMobile from 'lib/images/freemiumOnboarding/lines-cds-bg-onboarding-mobile.svg';
import { StepCounter } from './StepCounter';
import { DocumentHead } from 'lib/components';
import { HiArrowRight } from 'react-icons/hi';
import { useHistory } from 'react-router';
import { useFreemiumOnboardingMutation } from 'lib/api/freemiumOnboarding/createOrUpdateFreemiumOnboarding';
import { FreemiumOnboardingData } from 'lib/api/freemiumOnboarding/types';
import { useAuth } from 'lib/context';
import { useOnboardedFreemiumFlagMutation } from 'lib/api/freemiumOnboarding/updateOnboardedFlag';
import { useToastError } from 'lib/hooks';
import Lottie from 'lottie-react';
import { Button } from 'react-covideo-common';

type MainDivProps = {
  freemiumOnboardingPhaseOneFinished?: boolean;
  bgImage?: string;
};
const MainDiv = styled.div<MainDivProps>`
  background-image: url(${props => props.bgImage});
  background-size: ${props =>
    props.freemiumOnboardingPhaseOneFinished ? '100% auto' : 'cover'};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  height: 100vh;
  ${theme.mediaQueryMaxWidth.sm} {
    background-image: none;
  }
`;
const MainWrapper = styled.div`
  display: grid;
  min-height: 100vh;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding-top: 100px;
  ${theme.mediaQueryMaxWidth.sm} {
    padding: 0;
    grid-auto-flow: row;
    place-items: center;
  }
`;
type ColWrapperProps = {
  widthOnDesktop?: string;
  widthOnTablet?: string;
  marginTopOnTablet?: string;
  paddingOnLargeDesktop?: string;
  paddingOnDesktop?: string;
  paddingOnTablet?: string;
};
const ColWrapper = styled.div<ColWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Work Sans', serif;
  font-style: normal;
  width: ${props => props.widthOnDesktop || '100%'};
  padding: ${props => props.paddingOnLargeDesktop || '0'};
  ${theme.mediaQueryMaxWidth.md} {
    width: ${props => props.widthOnTablet || '100%'};
    padding: ${props => props.paddingOnDesktop || '0'};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    padding: ${props => props.paddingOnTablet || '0'};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    margin-top: ${props => props.marginTopOnTablet || '0'};
    padding: 0;
  }
`;
type ImageWrapProps = {
  alignItems?: string;
  justifyContentOnDesktop?: string;
  hide?: boolean;
};
const ImageWrap = styled.div<ImageWrapProps>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContentOnDesktop || 'center'};
  width: 100%;
  height: 100%;
  ${theme.mediaQueryMaxWidth.sm} {
    display: flex;
    justify-content: center;
  }
`;
type StaticLogoWrapperProps = {
  hide?: boolean;
};
const StaticLogoWrapper = styled.div<StaticLogoWrapperProps>`
  display: ${props => (props.hide ? 'none' : 'default')};
  position: absolute;
  top: 48px;
  left: 112px;
  ${theme.mediaQueryMaxWidth.md} {
    left: 50px;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    left: 20px;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    display: none;
  }
`;
type ImageProps = {
  margin?: string;
  marginOnTablet?: string;
  heightOnDesktop?: string;
  widthOnDesktop?: string;
  heightOnTablet?: string;
  widthOnTablet?: string;
  displayOnlyOnTablet?: boolean;
  fullScreenWidth?: boolean;
};
const Image = styled.img<ImageProps>`
  display: ${props => (props.displayOnlyOnTablet ? 'none' : 'flex')};
  margin: ${props => props.margin};
  height: ${props => props.heightOnDesktop || ''};
  width: ${props => props.widthOnDesktop || ''};
  ${theme.mediaQueryMaxWidth.md} {
    height: ${props => props.heightOnTablet || ''};
    width: ${props => props.widthOnTablet || ''};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    display: flex;
    margin: ${props => props.marginOnTablet};
  }
`;
const StepWrapper = styled.div`
  padding: 36px 0px;
`;
const StepText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-top: 36px;
  color: ${theme.palette.covideoBlue100};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 20px;
    line-height: 28px;
  }
`;

type Props = {
  rightColumnChildren?: JSX.Element;
  leftColumnHeader?: JSX.Element;
  leftColumnChildren: JSX.Element;
  currentStep: number;
  goToNextStepDisabled?: boolean;
  nextStepRoute?: string;
  rightColumnAnimationSrc?: object;
  animationLoop?: boolean;
  stepText?: string;
  dataToBeSaved?: Partial<FreemiumOnboardingData>;
  hideStaticLogoImage?: boolean;
};
export const FreemiumOnboardingPageTemplate = (props: Props) => {
  const {
    rightColumnChildren,
    currentStep,
    nextStepRoute,
    goToNextStepDisabled,
    rightColumnAnimationSrc,
    animationLoop = false,
    stepText,
    dataToBeSaved = {},
    hideStaticLogoImage = false,
    leftColumnChildren,
    leftColumnHeader,
  } = props;
  const history = useHistory();
  const { showError } = useToastError();
  const { userData, whitelabel } = useAuth();

  const freemiumOnboardingPhaseOneFinished =
    userData.onboardedFreemium?.toString() !== '0';
  const showDefaultStepPageComponents = currentStep > 0 && currentStep < 4;
  const buttonText = currentStep === 2 ? 'Finish' : 'Continue';

  const getMainDivBgImage = () => {
    if (userData.isAutomotive) {
      return bgImageDesktopPhaseOneCds;
    }
    if (freemiumOnboardingPhaseOneFinished) {
      return bgImageDesktop;
    }

    return bgImageDesktopPhaseOne;
  };

  const logoSrc = !userData.isAutomotive
    ? 'https://www.covideo.com/images/covideo-logo-dark.svg'
    : 'https://' +
      whitelabel.domainV2 +
      whitelabel.contentPath +
      '/images/logo-secondary.svg';

  const bgImageSrc = userData.isAutomotive ? cdsBgImageMobile : bgImageMobile;
  const { mutateAsync, isLoading: isSubmitting } =
    useFreemiumOnboardingMutation();
  const {
    mutateAsync: mutateOnboardedFreemiumFlag,
    isLoading: isSubmittingFlag,
  } = useOnboardedFreemiumFlagMutation();

  const handleSaveOnboardingData = async () => {
    try {
      if (Object.keys(dataToBeSaved).length !== 0) {
        await mutateAsync(dataToBeSaved);
      }
      if (currentStep === 2) {
        await mutateOnboardedFreemiumFlag(1);
      }
      if (nextStepRoute) history.push(nextStepRoute);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <>
      <DocumentHead title='Onboarding' />
      <MainDiv bgImage={getMainDivBgImage()}>
        <StaticLogoWrapper hide={hideStaticLogoImage}>
          <Image width='200' src={logoSrc} />
        </StaticLogoWrapper>
        <MainWrapper>
          <ColWrapper
            widthOnDesktop='500px'
            widthOnTablet='85%'
            paddingOnLargeDesktop='0 0 0 112px'
            paddingOnDesktop='0 0 0 50px'
            paddingOnTablet='0 0 0 20px'
          >
            <ImageWrap
              hide={!hideStaticLogoImage}
              justifyContentOnDesktop='left'
            >
              <Image
                width='200'
                margin='0 0 66px 0'
                marginOnTablet='26px 0'
                src={logoSrc}
              />
            </ImageWrap>
            {leftColumnHeader}
            {showDefaultStepPageComponents && (
              <StepWrapper>
                <StepCounter currentStep={currentStep}></StepCounter>
                <StepText>{stepText}</StepText>
              </StepWrapper>
            )}
            {leftColumnChildren}
            {showDefaultStepPageComponents && (
              <Button
                style={{
                  marginTop: '32px',
                }}
                text={buttonText}
                onClick={handleSaveOnboardingData}
                icon={<HiArrowRight size={18} color={theme.palette.white} />}
                disabled={
                  goToNextStepDisabled || isSubmitting || isSubmittingFlag
                }
              />
            )}
          </ColWrapper>
          <Image width='100%' src={bgImageSrc} displayOnlyOnTablet={true} />
          <ColWrapper marginTopOnTablet='-30px'>
            {rightColumnChildren}
            {!rightColumnChildren && rightColumnAnimationSrc && (
              <ImageWrap>
                <Lottie
                  animationData={rightColumnAnimationSrc}
                  loop={animationLoop}
                  autoplay={true}
                />
              </ImageWrap>
            )}
          </ColWrapper>
        </MainWrapper>
      </MainDiv>
    </>
  );
};
