import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './app/App';
import './index.css';
import './select-styles.css';
import './timeline-range.css';
import './react-toggle.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from 'lib/context/app';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      // mutation options
      retry: 0,
    },
  },
});

Sentry.init({
  dsn: 'https://5b50421980da175074ac4b22e2bf8491@o109274.ingest.us.sentry.io/4507095424630784',
  integrations: [],
  environment: process.env.REACT_APP_ENV || 'development',
  enabled: ['sandbox', 'staging', 'production'].includes(
    process.env.REACT_APP_ENV as string
  ),
});

const appElement = (
  <QueryClientProvider client={queryClient}>
    <AppProvider>
      <App />
    </AppProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

ReactDOM.render(appElement, document.getElementById('root'));
