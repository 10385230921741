import { NewModal, TextInput } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import { FieldAttributes, Field, Formik, Form, FormikValues } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useAddCategoryMutation } from 'lib/api/guides/addGuideCategoryMutation';
import { Button } from 'react-covideo-common';

type InputFieldProps = FieldAttributes<{ name: string }>;

const InputField = (props: InputFieldProps) => {
  return <Field {...props} />;
};

interface IProps {
  closeModalHandler: () => void;
}

const renameModalSchema = Yup.object({
  name: Yup.string().required(),
});

const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

export const AddCategoryModal = ({ closeModalHandler }: IProps) => {
  const { mutateAsync, isLoading } = useAddCategoryMutation(closeModalHandler);

  const onSubmitHander = (values: FormikValues) => {
    mutateAsync({ name: values.name });
  };
  return (
    <NewModal headerText='Add new category' closeModal={closeModalHandler}>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={onSubmitHander}
        validationSchema={renameModalSchema}
        initialErrors={{ name: 'Name is required' }}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap>
                <Label htmlFor='name'>Enter name for new category</Label>

                <InputField
                  width='100%'
                  placeholder=''
                  name={'name'}
                  as={TextInput}
                  type='text'
                  disabled={isLoading}
                />
              </Gap>
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  disabled={isLoading}
                  onClick={async () => {
                    closeModalHandler();
                  }}
                  text='Cancel'
                  variant='secondary'
                />
                <Button
                  type='submit'
                  text='Add category'
                  disabled={!!errors.name || isLoading}
                  variant='primary'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
