export enum AutomotiveRole {
  NO_ROLE = '0',
  TECHNICIAN = '1',
  SERVICE_ADVISOR = '2',
  SERVICE_MANAGER = '3',
  SALESPERSON = '4',
  SALES_MANAGER = '5',
  BDC = '6',
}

export const automotiveRoleOptions = [
  { value: '', label: 'No role' },
  { value: '1', label: 'Technician' },
  { value: '2', label: 'Service Advisor' },
  { value: '3', label: 'Service Manager' },
  { value: '4', label: 'Salesperson' },
  { value: '5', label: 'Sales Manager' },
  { value: '6', label: 'BDC' },
];
