import styled from 'styled-components/macro';
import { ReactComponent as DropdownDownTinySvg } from './dropdown-down-tiny.svg';

const DropdownDownTiny = styled(DropdownDownTinySvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default DropdownDownTiny;
