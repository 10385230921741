import * as React from 'react';
import styled from 'styled-components/macro';
import { MdImage } from 'react-icons/md';
import { useLandingPageBuilderContext } from '../../context';
import { BinIcon, ResetIcon } from '../Constants';
import { EnhancedColorPicker } from '../../../../video/videoDetails/components';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { ColorResult } from 'react-color';
import { GetSignedUrlsParams, GetSignedUrlsResponse } from 'lib/api/types';
import { getSignedURLs } from 'lib/api/fileUploadApi';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { ICombinedUserData, useAuth } from 'lib/context';
import { ButtonSwitch } from 'lib/components';
import Select from 'react-select';
import { errorToast } from 'lib/components/toasts/error';

interface Props {
  width?: string;
  setShowBackButton: any;
}

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  .adjustWidth {
    width: 90%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .alignRight {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  .adjustToggleMargin {
    margin: 0px;
  }
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const FileInputHandler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 20px;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  word-break: break-all;
  height: fit-content;
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const Thumbnail = styled.img`
  max-width: 80%;
  height: auto;
  border-radius: 5px;
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

export const backgroundSizeOptions = [
  {
    value: 'cover',
    text: 'Fill',
  },
  {
    value: 'contain',
    text: 'Fit',
  },
];

export const backgroundPositionOptions = [
  {
    value: 'left top',
    label: 'Left Top',
  },
  {
    value: 'left center',
    label: 'Left Center',
  },
  {
    value: 'left bottom',
    label: 'Left Bottom',
  },
  {
    value: 'right top',
    label: 'Right Top',
  },
  {
    value: 'right center',
    label: 'Right Center',
  },
  {
    value: 'right bottom',
    label: 'Right Bottom',
  },
  {
    value: 'center top',
    label: 'Center Top',
  },
  {
    value: 'center center',
    label: 'Center Center',
  },
  {
    value: 'center bottom',
    label: 'Center Bottom',
  },
];

export const ChangeBackgroundComponent = (props: Props) => {
  const [_, setImageSource] = React.useState('');
  const [imageFile, setImageFile] = React.useState<File>();
  const [__, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    elementToEdit,
    setElementToEdit,
    elementSectionKey,
    selectedStyleProperties,
    elementIndex,
    setIsImageUploadInProgress,
  } = useLandingPageBuilderContext();
  const onUploadProgress = handleUploadProgress(setUploadProgress);
  const [___, setBackgroundSize] = React.useState('cover');
  const [____, setBackgroundPosition] = React.useState('left top');

  const { userData } = useAuth();
  const { setShowBackButton } = props;

  const generateName = handleNameGeneration(userData);

  let fileRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if (!elementToEdit || !elementToEdit.sectionBackgroundSize) {
      return;
    }

    const size = elementToEdit.sectionBackgroundSize;
    if (size === 'contain' || size === 'cover') {
      setBackgroundSize(size);
    }
  }, []);

  React.useEffect(() => {
    if (!elementToEdit || !elementToEdit.sectionBackgroundPosition) {
      return;
    }

    const position = elementToEdit.sectionBackgroundPosition;
    setBackgroundPosition(position);
  }, []);

  const layoutData = { ...selectedLayoutData };
  if (!elementSectionKey || !layoutData) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  let sectionBackgroundColor = '';
  let sectionBackgroundSize = '';
  let sectionBackgroundPosition = '';
  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  const isVisible = isElementCase
    ? layoutData[elementSectionKey].childSections[elementIndex]?.isVisible
    : layoutData[elementSectionKey]?.isVisible;

  if (isElementCase) {
    sectionBackgroundColor =
      layoutData[elementSectionKey].childSections[elementIndex]
        ?.sectionBackgroundColor || selectedStyleProperties.backgroundColor;
    sectionBackgroundSize =
      layoutData[elementSectionKey].childSections[elementIndex]
        ?.sectionBackgroundSize || 'cover';
    sectionBackgroundPosition =
      layoutData[elementSectionKey].childSections[elementIndex]
        ?.sectionBackgroundPosition || 'left top';
  } else {
    sectionBackgroundColor =
      layoutData[elementSectionKey].sectionBackgroundColor ||
      selectedStyleProperties.backgroundColor;
    sectionBackgroundSize =
      layoutData[elementSectionKey].sectionBackgroundSize || 'cover';
    sectionBackgroundPosition =
      layoutData[elementSectionKey]?.sectionBackgroundPosition || 'left top';
  }

  const sectionBackgroundImageUrl =
    sectionDetails.sectionBackgroundImageUrl || null;
  if (!isVisible) {
    return <></>;
  }

  function handleBackgroundSizeChange(size: string) {
    if (!size) {
      return;
    }

    sectionBackgroundSize = size;
    sectionDetails.sectionBackgroundSize = size;
    setBackgroundSize(size);
    updateContext();
  }

  const handleChangeBackgroundPosition = (position: string) => {
    if (!position) {
      return;
    }

    sectionBackgroundPosition = position;
    sectionDetails.sectionBackgroundPosition = position;
    setBackgroundPosition(position);
    updateContext();
  };

  const handleChangeBackgroundColor = (color: ColorResult | string) => {
    let hexAlpha: string;
    if (typeof color != 'string') {
      hexAlpha = calculateHexAlpha(color as ColorResult);
    } else {
      hexAlpha = '';
    }
    sectionDetails.sectionBackgroundColor = hexAlpha;
    updateContext();
  };

  const removeBackgroundImage = () => {
    sectionDetails.sectionBackgroundImageUrl = '';
    setImageFile(undefined);
    updateContext();
  };

  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsImageUploadInProgress(true);
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    if (target && target.files && target.files[0]) {
      let orgFile = target.files[0];
      const isValidType = /.*\.(jpeg|jpg|png|bmp|gif|webp|svg)/i.test(
        orgFile.name
      );
      if (isValidType) {
        let filename = orgFile.name.split('.');
        setImageSource(filename[0]);
        let newFile = new File(
          [orgFile],
          generateName(filename[filename.length - 1]),
          { type: orgFile.type, lastModified: orgFile.lastModified }
        );
        setImageFile(newFile);
        setImageSource(URL.createObjectURL(newFile));
        handleUpload(newFile);
      } else {
        setIsImageUploadInProgress(false);
        errorToast({ title: 'Invalid format.' });
        console.log('Invalid format.');
      }
    } else {
      setIsImageUploadInProgress(false);
      errorToast({ title: 'Invalid file.' });
      //   setError('Invalid file.');
      console.log('Invalid file.');
    }
  };
  const handleUpload = async (newFile: File) => {
    setShowBackButton(false);
    const signedUrlData: GetSignedUrlsParams = {
      fileName: newFile.name,
      mimeType: newFile.type,
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      setIsImageUploadInProgress(false);
      errorToast({ title: 'Server is currently unavailable, try again!' });
      setShowBackButton(true);
      return;
    }
    const data = {
      file: newFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };
    uploadEmailThumbnail({ data, onUploadProgress })
      .then(() => {
        sectionDetails.sectionBackgroundImageUrl = signedURLs.downloadUrl;
        if (isElementCase) {
          layoutData[elementSectionKey].childSections[
            elementIndex
          ] = sectionDetails;
        } else {
          layoutData[elementSectionKey] = sectionDetails;
        }
        setTimeout(() => {
          setElementToEdit(sectionDetails);
          setSelectedLayoutData(layoutData);
          setIsImageUploadInProgress(false);
          setShowBackButton(true);
        }, 10);
      })
      .catch(() => {
        setIsImageUploadInProgress(false);
        setShowBackButton(true);
      });
  };

  function updateContext() {
    if (isElementCase) {
      layoutData[elementSectionKey].childSections[
        elementIndex
      ] = sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }

    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  }

  return (
    <>
      <Row>
        <TopLabel>Section Background</TopLabel>
        <EnhancedColorPicker
          disableZIndex={true}
          handleColorChange={handleChangeBackgroundColor}
          playerColor={sectionBackgroundColor}
          wrapperWidth={'100%'}
          wrapperMargin={'5px auto 0 auto'}
          className={
            sectionBackgroundColor &&
            selectedStyleProperties.backgroundColor != sectionBackgroundColor
              ? 'adjustWidth'
              : ''
          }
        />

        {sectionBackgroundColor &&
          selectedStyleProperties.backgroundColor != sectionBackgroundColor && (
            <ResetIcon
              onClick={() => handleChangeBackgroundColor('')}
              className={'alignCenter'}
            />
          )}
      </Row>
      <Row>
        <input
          type={'file'}
          ref={fileRef}
          style={{ display: 'none' }}
          name={'filePicker2'}
          onChange={handleSelectedFile}
          accept={
            'image/png, image/jpeg, image/bmp, image/gif, image/webp, image/svg+xml'
          }
        />
        {(!sectionBackgroundImageUrl || sectionBackgroundImageUrl == '') && (
          <FileInputHandler
            className={sectionBackgroundImageUrl ? 'adjustWidth' : ''}
            onClick={() => fileRef && fileRef.current?.click()}
          >
            {sectionBackgroundImageUrl ||
              (imageFile ? imageFile.name : 'Upload image...')}
            <MdImage size={24} color={'#9297A2'} />
          </FileInputHandler>
        )}

        {sectionBackgroundImageUrl && (
          <>
            <Thumbnail src={sectionBackgroundImageUrl} />
            <BinIcon onClick={removeBackgroundImage} className={'alignRight'} />
          </>
        )}
      </Row>
      {sectionBackgroundImageUrl && (
        <>
          <Row>
            <ButtonSwitch
              className={'adjustToggleMargin'}
              defaultValue={sectionBackgroundSize}
              values={backgroundSizeOptions}
              onChange={(option: any) => {
                handleBackgroundSizeChange(option);
              }}
            />
          </Row>
          <Row>
            <SelectInput
              styles={{
                control: (base: any) => ({ ...base, height: '40px' }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
              options={backgroundPositionOptions}
              menuPlacement={'top'}
              value={backgroundPositionOptions.find(o => {
                return o.value === sectionBackgroundPosition;
              })}
              onChange={(option: any) => {
                handleChangeBackgroundPosition(option.value);
              }}
            />
          </Row>
        </>
      )}
    </>
  );
};

function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
