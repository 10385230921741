import { theme } from 'lib/style';

// STYLES

export const MAX_IMAGE_WIDTH = 436;
export const MAX_IMAGE_HEIGHT = 200;

export const opacityValues = [
  '0%',
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
  '100%',
];

export const borderWidthValues = ['0', '1', '2', '3', '4'];

export const fontSizes = [
  {
    name: 'small',
    value: '12',
    default: false,
    range: ['10', '12'],
  },
  {
    name: 'medium',
    value: '14',
    default: true,
    range: ['14'],
  },
  {
    name: 'large',
    value: '16',
    default: false,
    range: ['16', '18', '20'],
  },
];

export const fontFamilies = [
  'Verdana',
  'Arial',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Old English',
];

export const textareaFontSizes = ['10', '12', '14', '16', '18', '20'];

// SELECT STYLES

export const defaultStyle = {
  control: (base: any) => ({
    ...base,
    boxShadow: 'none',
    height: '40px',
    border: '1px solid #d0d3d9',
    borderRadius: '4px',
    '&:hover': {
      border: '1px solid #d0d3d9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

export const fontSmallStyle = {
  control: (base: any) => ({
    ...base,
    boxShadow: 'none',
    height: 24,
    minHeight: 24,
    border: '1px solid #d0d3d9',
    borderRadius: '4px',
    '&:hover': {
      border: '1px solid #d0d3d9',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '24px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '24px',
  }),
  placeholder: (base: any) => ({
    ...base,
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.33,
    color: theme.palette.title,
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.33,
    color: theme.palette.title,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  option: (base: any) => ({
    ...base,
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.33,
    color: theme.palette.title,
  }),
};
