import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MdAdd } from 'react-icons/md';
import { Field, FieldArray, Form, Formik, useField } from 'formik';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { CTA, CTASet } from 'lib/api/types';
import { CheckboxInput, LoadingIndicator } from 'lib/components';
import {
  createCTA,
  createCTASet,
  deleteCTA,
  updateCTASet,
} from 'lib/api/designApi';
import DeleteIcon from 'lib/images/DeleteIcon';
import EditIcon from 'lib/images/EditIcon';
import { URL_REGEX } from 'lib/utils/regexes';
import { getCTATypes, wrapWithHttp } from 'lib/utils/functions';
import { CtaFilesModal, ItemTypes } from './CtaFilesModal';
import { CtaVideoModal } from './CtaVideoModal';
import Select from 'react-select';
import { reorder } from 'lib/utils/array';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIcon from 'lib/images/DragIcon';
import LinkExternalIcon from 'lib/images/LinkExternalIcon';
import Switch from '../landingPageBuilder/components/Switch';
import { AccessRole } from 'lib/const'; /*** SUS-1002 changes ***/
import { useQueryClient } from 'react-query';
import { EXIT_LINKS_QUERY } from 'lib/const/SendAndShare';
import selectors from '../../../../cypress/selectors';
import { CREDIT_700_CTA_TITLE, CREDIT_700_CTA_TYPE } from 'lib/const/700credit';
import { Button } from 'react-covideo-common';

const ErrorMessage = styled.div`
  margin-top: 10px;
  color: red;
  font-size: 15px;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 442px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShareWithCompany = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
    div {
      display: block;
      padding: 8px;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  color: ${theme.palette.coal};
  z-index: 90;
  width: 300px;
  left: -100px;
  top: -100px;

  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.08);
  border: solid 1px rgb(247, 248, 250);
`;

const ActionButtons = styled.div`
  display: flex;
  > button {
    height: 40px;
    margin-left: 12px;
  }
`;

const LinksHeader = styled.div`
  display: flex;
  margin-top: 38px;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 15px;
`;

const LinkItem = styled.div<{ height?: string }>`
  border-bottom: 1px solid #f4f4f4;
  height: ${props => (props.height ? props.height : '40px')};
  padding: 8px 0;
  display: flex;
`;
type LabelProps = {
  width?: string;
  padding?: string;
};
const Label = styled.div<LabelProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  width: ${props => (props.width ? props.width : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin-right: 12px;
`;

interface TextInputProps {
  placeholder?: string;
  name: string;
  type?: string;
  width?: string;
  maxLength?: number;
  onBlur?: () => void;
  disabled?: boolean /* SUS-1002 changes */;
}

const Input = styled.input<{ width?: string }>`
  width: ${props => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  padding: 8px 12px;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const TextInput = (props: TextInputProps) => {
  const [field] = useField(props);

  if (!field.value) {
    field.value = '';
  }

  return <Input {...field} {...props} />;
};

const FieldWrapper = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  margin-right: 12px;
  &.align-center {
    display: flex;
    align-items: center;
  }
`;

const FieldToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  cursor: pointer;
  height: 40px;
  position: relative;
`;

type ThumbnailProps = {
  image: string;
  width?: string;
};

const FilesThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${theme.palette.white};
  width: ${props => (props.width ? props.width : '32px')};
  height: 32px;
  flex-shrink: 0;
  margin-left: 4px;
`;

const FilesTitle = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 944px;
  bottom: 32px;
`;

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => props.marginBottom}px;
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin: 0;
  padding: 0;
`;

const InputLabels = styled(Text)`
  color: #9297a2;

  font-weight: 500;
`;

const DraggableItem = styled.div`
  display: flex;
`;

const DragIconWrapper = styled.div`
  font-size: 16px;
  width: 48px;
  height: 56px;
  display: flex;
  align-items: center;
  min-width: 48px;
  margin-top: auto;
  margin-bottom: auto;
`;

export type CTASetParams = CTASet & {
  isCompany?: boolean;
  isDefault?: boolean;
};

const prepareCtaSet = (cta: CTASetParams, sharingAllowed: boolean) => {
  cta.company = sharingAllowed && cta.isCompany ? 1 : 0;
  cta.defaultLinks = cta.isDefault ? 1 : 0;
  cta.links.forEach((link: any) => {
    if (!link.type || link.type === 'url') {
      link.type = 'url';
      link.itemType = 'url';
    } else if (link.type === 'file') {
      link.type = 'library';
      link.itemType = 'file';
    } else if (link.type === 'board') {
      link.type = 'board';
      link.itemType = 'board';
    } else if (link.type === 'video') {
      link.type = 'video';
      link.itemType = 'video';
    }
  });
  cta.links = cta.links.filter((link: any) => link.linkValue && link.linkText);
};

export const CallsToActionForm = (props: any) => {
  const { type, item, onEnd } = props;
  const [cta, setCta] = React.useState({
    title: '',
    links: [
      {
        linkText: '',
        type: 'url',
        linkValue: '',
        newWindow: 1,
        autoOpen: true,
      },
    ] as CTA[],
    company: 0,
    defaultLinks: 0,
  } as CTASet);
  const [loading, setLoading] = React.useState(true);
  const [isNew, setIsNew] = React.useState(true);
  const [deletedLinks, setDeletedLinks] = React.useState(
    [] as (string | number)[]
  );
  const [showCtaFilesModal, setShowCtaFilesModal] = React.useState(0);
  const [showCtaVideoModal, setShowCtaVideoModal] = React.useState(0);
  const [view, setView] = React.useState(ItemTypes[0].value);
  const [allowShareWithCompany, setAllowShareWithCompany] =
    React.useState(false);
  const themes = useTheme();
  const queryClient = useQueryClient();

  const {
    userData: { access, droplrAccess, user, credit700Url },
  } = useAuth();

  const isCompanyAdmin = access === '3' || access === '4';
  const isAdminView =
    ((access === AccessRole.ADMIN || access === AccessRole.SUPERVISOR) &&
      item?.isCompany &&
      !isNew) ||
    type === 'duplicate' ||
    !item?.isCompany; /*** SUS-1002 and SUS-1185 changes ***/

  React.useEffect(() => {
    setLoading(true);
    try {
      if (item) {
        const cta: CTASet = { ...item };
        prepareCtaSet(cta, type !== 'duplicate' || isCompanyAdmin);
        setIsNew(type !== 'edit');

        setCta(cta);
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }, [type, item]);

  React.useEffect(() => {
    // allow share with company only for Business and Enterprise Package
    if (
      user &&
      user.packageDetails &&
      (user.packageDetails.id === 0 ||
        user.packageDetails.id === 3 ||
        user.packageDetails.id === 4)
    ) {
      setAllowShareWithCompany(true);
    }
  }, [user]);

  const ctaTypes = getCTATypes(droplrAccess, !!credit700Url);

  return (
    <Container>
      {loading && <LoadingIndicator isLoading={loading} height='300px' />}
      {!loading && (
        <Formik
          initialValues={{
            ...cta,
          }}
          enableReinitialize={true}
          onSubmit={async data => {
            await Promise.all(
              data.links.map(async (link, index) => {
                link.linkPosition = index + 1;
                link.ctaSetId = data.id;
                // if link is url (not sms, tel or mailto), add https if missing

                if (
                  link.type === 'email' &&
                  !link.linkValue.startsWith('mailto:')
                ) {
                  link.linkValue = 'mailto:' + link.linkValue;
                } else {
                  if (
                    URL_REGEX.test(link.linkValue) &&
                    !link.linkValue.startsWith('mailto:')
                  ) {
                    link.linkValue = wrapWithHttp(link.linkValue);
                  }
                }

                /*if (
                  URL_REGEX.test(link.linkValue) &&
                  !link.linkValue.startsWith('mailto:')
                ) {
                  link.linkValue = wrapWithHttp(link.linkValue);
                }*/

                if (link.type === 'url') {
                  // @ts-ignore
                  delete link.fileTitle;
                  // @ts-ignore
                  delete link.fileThumbnail;
                }

                if (type === 'duplicate') {
                  // @ts-ignore
                  delete link.id;
                  // @ts-ignore
                  delete link.userId;
                  // @ts-ignore
                  delete link.customerId;
                  // @ts-ignore
                  delete link.ctaSetId;
                }

                if (!link.id) {
                  const createdCTA = await createCTA(link).catch(err => err);
                  link.id = createdCTA.id;
                }
              })
            );

            if (item && item.id && type !== 'duplicate') {
              for (const id of deletedLinks) {
                if (id) {
                  await deleteCTA(parseInt(id.toString(), 10)).catch(
                    err => err
                  );
                  queryClient.invalidateQueries([EXIT_LINKS_QUERY]);
                }
              }

              await updateCTASet({
                id: item.id,
                title: data.title,
                isDefault: !!data.defaultLinks,
                isCompany: !!data.company,
                ctas: data.links,
              });
            } else {
              await createCTASet({
                ...data,
                ctaSetLinks: data.links,
                default: data.defaultLinks,
              });
            }

            queryClient.invalidateQueries([EXIT_LINKS_QUERY]);
            onEnd && onEnd();
          }}
        >
          {({ isSubmitting, values, setFieldValue, submitForm }) => {
            const onDragEnd = async (result: any) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              const updatedItems = reorder(
                values.links,
                source.index,
                destination.index
              );
              setFieldValue(`links`, updatedItems);
            };
            const trimmedTitle = values.title.trim();
            const isValid =
              !!trimmedTitle &&
              !values.links.find(
                link =>
                  !link.linkText.trim() ||
                  !link.linkValue.trim() ||
                  ((link.type === 'library' || link.type === 'board') &&
                    !link.fileTitle)
              );
            return (
              <Form>
                <Row>
                  <Row marginBottom={8}>
                    <InputLabels>Set Title</InputLabels>
                  </Row>
                  <Field name={'title'} readonly={isSubmitting}>
                    {({ field }: any) => {
                      return (
                        <>
                          <TextInput
                            {...field}
                            type={'text'}
                            placeholder={'Example Set'}
                            required={true}
                            width='100%'
                            maxLength={30}
                            disabled={!isAdminView} /* SUS-1002 changes */
                            data-cy={selectors.ctaPage.titile}
                          />
                          {!!values.title.length && !trimmedTitle.length && (
                            <ErrorMessage>
                              {'Title cannot be empty'}
                            </ErrorMessage>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </Row>
                <LinksHeader>
                  <Label width={`235px`} padding='0 0 0 20px'>
                    Display Name
                  </Label>
                  <Label width='146px'>Link Type</Label>
                  <Label width={`315px`}>URL/File</Label>
                  <Label width='64px'>Preview</Label>
                  {isAdminView /* SUS-1002 changes */ && (
                    <Label width='60px'>New Tab</Label>
                  )}
                  <Label width='54px'></Label>
                </LinksHeader>

                <FieldArray
                  name='links'
                  render={arrayHelpers => (
                    <>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='cta-dropp-container'>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {values.links.map((link, index) => (
                                <Draggable
                                  draggableId={`cta-draggable-${index}`}
                                  key={`cta-draggable-${index}`}
                                  index={index}
                                  isDragDisabled={
                                    !!showCtaFilesModal || !!showCtaVideoModal
                                  }
                                >
                                  {provided => (
                                    <DraggableItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIconWrapper>
                                        <DragIcon
                                          color={theme.palette.gray40}
                                        />
                                      </DragIconWrapper>
                                      <LinkItem
                                        height={
                                          (!!values.links[index].linkText
                                            .length &&
                                            !values.links[index].linkText.trim()
                                              .length) ||
                                          (!!values.links[index].linkValue
                                            .length &&
                                            !values.links[
                                              index
                                            ].linkValue.trim().length)
                                            ? '70px'
                                            : '40px'
                                        }
                                      >
                                        <FieldWrapper width={`200px`}>
                                          <TextInput
                                            name={`links[${index}].linkText`}
                                            type='text'
                                            disabled={
                                              !isAdminView
                                            } /* SUS-1002 changes */
                                            placeholder='Display Name'
                                            maxLength={30}
                                            data-cy={selectors.ctaPage.ctaCreate(
                                              `display-${index}`
                                            )}
                                          />
                                          {!!values.links[index].linkText
                                            .length &&
                                            !values.links[index].linkText.trim()
                                              .length && (
                                              <ErrorMessage>
                                                {'Display Name cannot be empty'}
                                              </ErrorMessage>
                                            )}
                                        </FieldWrapper>
                                        <FieldWrapper width='146px'>
                                          <Select
                                            id={selectors.ctaPage.ctaCreate(
                                              `dropdown-${index}`
                                            )}
                                            isDisabled={
                                              !isAdminView
                                            } /* SUS-1002 changes */
                                            styles={{
                                              control: (base: any) => ({
                                                ...base,
                                                height: '40px',
                                              }),
                                              indicatorSeparator: () => ({
                                                display: 'none',
                                              }),
                                              menuPortal: (base: any) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                            menuPlacement={'bottom'}
                                            options={ctaTypes}
                                            value={ctaTypes.find(o => {
                                              return (
                                                o.value ===
                                                (values.links[index].type ||
                                                  'url')
                                              );
                                            })}
                                            onChange={(option: any) => {
                                              setFieldValue(
                                                `links[${index}].type`,
                                                option.value
                                              );
                                              if (
                                                option.value ===
                                                CREDIT_700_CTA_TYPE
                                              ) {
                                                setFieldValue(
                                                  `links[${index}].linkText`,
                                                  CREDIT_700_CTA_TITLE
                                                );
                                                setFieldValue(
                                                  `links[${index}].linkValue`,
                                                  credit700Url
                                                );
                                              }

                                              setFieldValue(
                                                `links[${index}].fileTitle`,
                                                ''
                                              );
                                              setFieldValue(
                                                `links[${index}].fileThumbnail`,
                                                ''
                                              );
                                              setFieldValue(
                                                `links[${index}].itemType`,
                                                ''
                                              );
                                            }}
                                          />
                                        </FieldWrapper>
                                        <FieldWrapper width={`315px`}>
                                          {values.links[index].type ===
                                            CREDIT_700_CTA_TYPE && (
                                            <FieldWrapper width={`261px`}>
                                              <Input
                                                type='text'
                                                disabled={true}
                                                value={credit700Url}
                                              />
                                            </FieldWrapper>
                                          )}
                                          {(values.links[index].type ===
                                            'url' ||
                                            values.links[index].type ===
                                              'email') && (
                                            <>
                                              <TextInput
                                                name={`links[${index}].linkValue`}
                                                type='text'
                                                data-cy={selectors.ctaPage.ctaCreate(
                                                  `url-${index}`
                                                )}
                                                disabled={
                                                  !isAdminView
                                                } /* SUS-1002 changes */
                                              />
                                              {!!values.links[index].linkValue
                                                .length &&
                                                !values.links[
                                                  index
                                                ].linkValue.trim().length && (
                                                  <ErrorMessage>
                                                    {
                                                      'Link Value cannot be empty'
                                                    }
                                                  </ErrorMessage>
                                                )}
                                            </>
                                          )}
                                          {(values.links[index].type ===
                                            'library' ||
                                            values.links[index].type ===
                                              'board') && (
                                            <>
                                              {values.links[index]
                                                .fileTitle && (
                                                <FileWrapper
                                                  onClick={() => {
                                                    if (!isAdminView) {
                                                      /* SUS-1002 changes */
                                                      return;
                                                    }
                                                    setShowCtaFilesModal(
                                                      index + 1
                                                    );
                                                  }}
                                                >
                                                  <FilesThumbnail
                                                    width={'64px'}
                                                    image={
                                                      values.links[index]
                                                        .fileThumbnail
                                                    }
                                                  />
                                                  <FilesTitle>
                                                    {
                                                      values.links[index]
                                                        .fileTitle
                                                    }
                                                  </FilesTitle>
                                                  {isAdminView /* SUS-1002 changes */ && (
                                                    <EditIcon
                                                      width='18px'
                                                      height='18px'
                                                      color='#9297A2'
                                                      style={{
                                                        position: 'absolute',
                                                        right: '12px',
                                                      }}
                                                    />
                                                  )}
                                                </FileWrapper>
                                              )}
                                              {!values.links[index].fileTitle &&
                                                values.links[index].type ===
                                                  'library' && (
                                                  <Button
                                                    variant='secondary'
                                                    text='Select File...'
                                                    onClick={() => {
                                                      setShowCtaFilesModal(
                                                        index + 1
                                                      );
                                                      setView('file');
                                                    }}
                                                  />
                                                )}
                                              {!values.links[index].fileTitle &&
                                                values.links[index].type ===
                                                  'board' && (
                                                  <Button
                                                    variant='secondary'
                                                    text='Select Board...'
                                                    onClick={() => {
                                                      setShowCtaFilesModal(
                                                        index + 1
                                                      );
                                                      setView('board');
                                                    }}
                                                  />
                                                )}
                                              {showCtaFilesModal ===
                                                index + 1 && (
                                                <CtaFilesModal
                                                  hideToggle={true}
                                                  view={view}
                                                  selectedFile={
                                                    values.links[index]
                                                  }
                                                  handleModalClose={() => {
                                                    setShowCtaFilesModal(0);
                                                  }}
                                                  onSelect={(
                                                    drop: any,
                                                    itemType: string
                                                  ) => {
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaFilesModal - 1
                                                      }].linkValue`,
                                                      drop.shortlink
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaFilesModal - 1
                                                      }].fileTitle`,
                                                      drop.title || drop.name
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaFilesModal - 1
                                                      }].fileThumbnail`,
                                                      drop.previewSmall ||
                                                        drop.thumbnailUrl ||
                                                        drop.content
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaFilesModal - 1
                                                      }].itemType`,
                                                      itemType
                                                    );
                                                    setShowCtaFilesModal(0);
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                          {values.links[index].type ===
                                            'video' && (
                                            <>
                                              {values.links[index]
                                                .fileTitle && (
                                                <FileWrapper
                                                  onClick={() => {
                                                    if (!isAdminView) {
                                                      /* SUS-1002 changes */
                                                      return;
                                                    }
                                                    setShowCtaVideoModal(
                                                      index + 1
                                                    );
                                                  }}
                                                >
                                                  <FilesThumbnail
                                                    width={'48px'}
                                                    image={
                                                      values.links[index]
                                                        .fileThumbnail
                                                    }
                                                  />
                                                  <FilesTitle>
                                                    {
                                                      values.links[index]
                                                        .fileTitle
                                                    }
                                                  </FilesTitle>
                                                  {isAdminView /* SUS-1002 changes */ && (
                                                    <EditIcon
                                                      width='18px'
                                                      height='18px'
                                                      color='#9297A2'
                                                      style={{
                                                        position: 'absolute',
                                                        right: '12px',
                                                      }}
                                                    />
                                                  )}
                                                </FileWrapper>
                                              )}
                                              {!values.links[index]
                                                .fileTitle && (
                                                <Button
                                                  variant='secondary'
                                                  text='Select Video...'
                                                  onClick={() =>
                                                    setShowCtaVideoModal(
                                                      index + 1
                                                    )
                                                  }
                                                />
                                              )}
                                              {showCtaVideoModal ===
                                                index + 1 && (
                                                <CtaVideoModal
                                                  handleModalClose={() => {
                                                    setShowCtaVideoModal(0);
                                                  }}
                                                  onSelect={(video: any) => {
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaVideoModal - 1
                                                      }].linkValue`,
                                                      video.videoSource
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaVideoModal - 1
                                                      }].fileTitle`,
                                                      video.title
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaVideoModal - 1
                                                      }].fileThumbnail`,
                                                      video.thumbnail
                                                    );
                                                    setFieldValue(
                                                      `links[${
                                                        showCtaVideoModal - 1
                                                      }].itemType`,
                                                      ''
                                                    );
                                                    setShowCtaVideoModal(0);
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </FieldWrapper>
                                        <FieldWrapper width='64px'>
                                          <Button
                                            variant='secondary'
                                            icon={
                                              <LinkExternalIcon
                                                width='18px'
                                                height='18px'
                                              />
                                            }
                                            onClick={() => {
                                              if (
                                                values.links[index].type ===
                                                CREDIT_700_CTA_TYPE
                                              ) {
                                                window.open(credit700Url);
                                                return;
                                              }
                                              window.open(
                                                values.links[index].type ===
                                                  'email'
                                                  ? `mailto:${values.links[index].linkValue}`
                                                  : values.links[
                                                        index
                                                      ].linkValue.includes(
                                                        'http'
                                                      )
                                                    ? values.links[index]
                                                        .linkValue
                                                    : `http://${values.links[index].linkValue}`,
                                                '_blank'
                                              );
                                            }}
                                            disabled={
                                              !values.links[index].linkValue &&
                                              values.links[index].type !==
                                                CREDIT_700_CTA_TYPE
                                            }
                                          />
                                        </FieldWrapper>
                                        {isAdminView /* SUS-1002 changes */ && (
                                          <FieldWrapper
                                            width='60px'
                                            className='align-center'
                                          >
                                            <FieldToggleWrapper>
                                              <Switch
                                                id={`switch-cta-set-${index}`}
                                                isOn={!!link.newWindow}
                                                onColor={
                                                  themes.colors.primary[100]
                                                }
                                                handleToggle={() => {
                                                  setFieldValue(
                                                    `links[${index}].newWindow`,
                                                    values.links[index]
                                                      .newWindow
                                                      ? 0
                                                      : 1
                                                  );
                                                }}
                                              />
                                            </FieldToggleWrapper>
                                          </FieldWrapper>
                                        )}
                                        {isAdminView /* SUS-1002 changes */ && (
                                          <FieldWrapper width='54px'>
                                            {values.links.length > 1 && (
                                              <Button
                                                icon={
                                                  <DeleteIcon
                                                    width='24px'
                                                    height='24px'
                                                    color={
                                                      theme.palette.buttonDelete
                                                    }
                                                  />
                                                }
                                                variant='destructive'
                                                onClick={() => {
                                                  arrayHelpers.remove(index);
                                                  setDeletedLinks([
                                                    ...deletedLinks,
                                                    values.links[index].id,
                                                  ]);
                                                }}
                                              />
                                            )}
                                          </FieldWrapper>
                                        )}
                                      </LinkItem>
                                    </DraggableItem>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {isAdminView /* SUS-1002 changes */ && (
                        <LinkItem>
                          <Button
                            data-cy={selectors.ctaPage.ctaCreate(`new-link`)}
                            disabled={values.links.length >= 7}
                            text='New CTA'
                            icon={<MdAdd size={20} />}
                            variant='secondary'
                            onClick={() => {
                              arrayHelpers.push({
                                linkText: '',
                                type: 'url',
                                linkValue: '',
                                newWindow: 1,
                                autoOpen: true,
                              });
                            }}
                          />
                        </LinkItem>
                      )}
                    </>
                  )}
                />
                <Footer>
                  <Wrapper>
                    {isCompanyAdmin && allowShareWithCompany && (
                      <ShareWithCompany>
                        <CheckboxInput
                          checked={!!values.company}
                          onChange={() =>
                            setFieldValue('company', values.company ? 0 : 1)
                          }
                        />
                        <div>Share with Company</div>
                        <TooltipWrapper>
                          <Tooltip>
                            Shared CTA Set can be used by other users, and it
                            can be edited only by Admin.
                          </Tooltip>
                          <AiFillQuestionCircle
                            size={24}
                            color={theme.palette.midGrey}
                          />
                        </TooltipWrapper>
                      </ShareWithCompany>
                    )}
                  </Wrapper>
                  <Wrapper>
                    <ActionButtons>
                      {isAdminView /* SUS-1002 changes */ && (
                        <Button
                          text={isNew ? 'Create CTA Set' : 'Save Changes'}
                          onClick={submitForm}
                          disabled={isSubmitting || !isValid}
                          data-cy={selectors.ctaPage.ctaCreate('submit')}
                        />
                      )}
                    </ActionButtons>
                  </Wrapper>
                </Footer>
              </Form>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};
