import { Button } from 'react-covideo-common';
import CloseIcon from 'lib/images/CloseIcon';
import React from 'react';
import styled from 'styled-components/macro';
import searchGuideEmpty from '../assets/images/searchGuideEmpty.png';

const ThumbnailWrapper = styled.div`
  max-width: calc((100% / 4) - 10px);
  width: calc((100% / 4) - 10px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
`;

const SearchMessage = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  color: #4e5461;
`;
const AdditionalMessage = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  color: #4e5461;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  gap: 6px;
`;

const GuidesSearchEmptyWrapper = styled.div`
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  position: relative;
`;

interface IProps {
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  search?: string;
}

export const GuidesEmpty = (props: IProps) => {
  const emptyArray = [...Array(12)];
  return (
    <GuidesSearchEmptyWrapper>
      {props.search && (
        <InfoWrapper>
          <SearchMessage>
            There are no guides with “{props.search}” in the title
          </SearchMessage>
          <AdditionalMessage>
            Try using a different search term
          </AdditionalMessage>
          <Button
            onClick={() => (props.setSearch ? props.setSearch('') : null)}
            text='Clear search'
            icon={<CloseIcon />}
            variant='primary'
          />
        </InfoWrapper>
      )}
      {emptyArray.map((_, index) => (
        <ThumbnailWrapper key={index}>
          <img src={searchGuideEmpty} alt='searchGuideEmpty' />
        </ThumbnailWrapper>
      ))}
    </GuidesSearchEmptyWrapper>
  );
};
