import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { calculateRange } from 'lib/utils/functions';
import { theme } from 'lib/style';
import { FaChevronRight } from 'react-icons/fa';
import Select from 'react-select';
import { loadSmsUsers, useSmsUserContext, useAuth } from 'lib/context';
import {
  LoadingIndicator,
  MainContainer,
  Search,
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
} from 'lib/components';
import { SmsOptions } from 'lib/const';
import { fetchCustomer } from 'lib/api';
import Switch from '../../design/landingPageBuilder/components/Switch';

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    width: 500px;
  }
`;

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
  }
  .tab-container {
    max-width: 100%;
  }
  .tab-content {
    width: 100%;
  }
  .tab-navigation {
    padding: 0;
    margin: 0 32px 0 32px;
    justify-content: left;
    .tab {
      & > div {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
      }
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-right: 32px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
  }
`;

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  align-items: center;
  &.class-red {
    color: red;
  }
  &.class--right {
    justify-content: end;
  }
`;

const FieldToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: end;
  label {
    padding-right: 10px;
  }
`;

const Default = styled.div`
  background: rgba(0, 27, 83, 0.05);
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  border-radius: 5px;
  color: ${theme.palette.primaryDarkBlue};
  margin-left: 10px;
  font-size: 13px;
`;

const tableFields = [
  {
    value: 'firstName',
    label: 'Name',
  },
  {
    value: 'lastResponse',
    label: 'Last Response',
  },
  {
    value: 'sentCount',
    label: 'Sent',
  },
  {
    value: 'receivedCount',
    label: 'Received',
  },
  {
    value: 'notAnswered',
    label: 'Not answered',
  },
];

export const UsersLayout = () => {
  const [onlyUsersUsingSMS, setOnlyUsersUsingSMS] = React.useState(
    localStorage.getItem('SMS_USER_TOGGLE') == 'true' ? true : false
  );
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [sent, setSent] = React.useState(SmsOptions[1].value);
  const [receive, setReceive] = React.useState(SmsOptions[1].value);
  const [defaultUserId, setDefaultUserId] = React.useState('');

  const {
    state: {
      users: { items, count, loading },
    },
    dispatch,
  } = useSmsUserContext();

  const {
    userData: { customerId },
  } = useAuth();

  const columnWidths: any = [300];
  const history = useHistory();
  const themes = useTheme();

  const refreshSmsUser = async () => {
    const sentObj = calculateRange(sent);
    const receiveObj = calculateRange(receive);
    await loadSmsUsers({
      params: {
        page: page,
        size: size,
        search: search,
        onlyUsersUsingSMS: onlyUsersUsingSMS,
        sFrom: sentObj.from,
        sTo: sentObj.to,
        rFrom: receiveObj.from,
        rTo: receiveObj.to,
      },
      dispatch,
    });
  };
  const onSearch = (query: string) => {
    setSearch(query.toLowerCase());
    setPage(0);
  };
  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const calculateDifferenceInTime = (value: any) => {
    const dateCurrent = dayjs();
    const day: any = dateCurrent.diff(value, 'day', true);
    const hour: any = dateCurrent.diff(value, 'hour', true);
    const minute: any = dateCurrent.diff(value, 'minute', true);
    if (parseInt(minute, 10) < 60) {
      return { value: `${parseInt(day, 10)} minute(s) ago`, type: 'minute' };
    }
    if (parseInt(day, 10) > 0) {
      return { value: `${parseInt(day, 10)} day(s) ago`, type: 'day' };
    }
    return { value: `${parseInt(hour, 10)} hour(s) ago`, type: 'hour' };
  };

  const getFieldValue = (dataSet: any, data: string) => {
    if (dataSet.userId != -1) {
      switch (data) {
        case 'lastResponse':
          return !isNull(dataSet[data])
            ? calculateDifferenceInTime(dataSet[data]).value
            : '--';
        case 'sentCount':
        case 'receivedCount':
          return dataSet[data];
        case 'firstName':
          return `${dataSet.firstName} ${dataSet.lastName}`;
        case 'notAnswered':
          return '0';
        default:
          return '--';
      }
    }
    return '--';
  };

  const loadCustomer = async () => {
    const customer = await fetchCustomer(customerId);
    if (customer && customer.defaultSMSUserId) {
      setDefaultUserId(customer.defaultSMSUserId);
    }
  };

  React.useEffect(() => {
    refreshSmsUser();
  }, [page, size, search, onlyUsersUsingSMS, sent, receive]);

  React.useEffect(() => {
    loadCustomer();
  }, []);

  return (
    <>
      <MainContainer>
        <MainWrapper>
          <FiltersContainer>
            <Filter>
              <h3>Users</h3>
            </Filter>
          </FiltersContainer>
          <Content>
            <Layout>
              <Row style={{ marginBottom: 20 }}>
                <div style={{ width: 300 }}>
                  <Search
                    prevSearch={search}
                    placeholder='Search by number or name…'
                    onSearch={onSearch}
                  />
                </div>
                <Row>
                  <FieldToggleWrapper>
                    <label>Show only users using SMS</label>
                    <Switch
                      id={'switch-only-sms'}
                      isOn={onlyUsersUsingSMS}
                      onColor={themes.colors.primary[100]}
                      handleToggle={() => {
                        setOnlyUsersUsingSMS(!onlyUsersUsingSMS);
                        localStorage.setItem(
                          'SMS_USER_TOGGLE',
                          JSON.stringify(!onlyUsersUsingSMS)
                        );
                      }}
                    />
                  </FieldToggleWrapper>
                </Row>
              </Row>
              <TableContextProvider
                total={count}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <div style={{ overflowX: 'auto' }}>
                  {!loading && items.length ? (
                    <>
                      <Table
                        compact={true}
                        headers={[
                          ...tableFields.map((item, index) => {
                            return (
                              <TableCell
                                width={columnWidths[index + 1]}
                                onClick={() => {}}
                                className={
                                  item.value == 'notAnswered'
                                    ? 'class--right'
                                    : ''
                                }
                              >
                                {item.label}
                                {item.label == 'Sent' ||
                                item.label == 'Received' ? (
                                  <>
                                    &nbsp;
                                    <Select
                                      styles={{
                                        control: (base: any) => ({
                                          ...base,
                                          height: '40px',
                                          width: '130px',
                                        }),
                                        indicatorSeparator: () => ({
                                          display: 'none',
                                        }),
                                      }}
                                      options={SmsOptions}
                                      menuPortalTarget={document.body}
                                      menuPlacement={'bottom'}
                                      isSearchable={false}
                                      value={SmsOptions.find(o => {
                                        return (
                                          o.value ===
                                          (item.label == 'Sent'
                                            ? sent
                                            : receive)
                                        );
                                      })}
                                      onChange={(option: any) => {
                                        item.label == 'Sent'
                                          ? setSent(option.value)
                                          : setReceive(option.value);
                                      }}
                                    />{' '}
                                  </>
                                ) : null}
                              </TableCell>
                            );
                          }),
                          <TableCell
                            width={columnWidths[tableFields.length]}
                          ></TableCell>,
                        ]}
                        hoverable={false}
                        columnWidths={columnWidths}
                        rows={items.map((user: any, index: number) => ({
                          key: index,
                          columns: [
                            ...tableFields.map((item, index) => {
                              return (
                                <TableCell
                                  width={columnWidths[index + 1]}
                                  onClick={() => {}}
                                  className={
                                    item.value == 'notAnswered'
                                      ? `${
                                          getFieldValue(user, item.value) > 0
                                            ? 'class-red'
                                            : ''
                                        } class--right`
                                      : ''
                                  }
                                >
                                  {
                                    // @ts-ignore
                                    // contact[item.value]
                                    getFieldValue(user, item.value)
                                  }
                                  {item.value == 'firstName' &&
                                    user.userId == defaultUserId && (
                                      <Default>DEFAULT</Default>
                                    )}
                                </TableCell>
                              );
                            }),
                            <TableCell
                              style={{
                                textAlign: 'right',
                                paddingLeft: '25px',
                              }}
                              width={columnWidths[tableFields.length]}
                            >
                              <FaChevronRight
                                size={13}
                                onClick={() => {
                                  history.push(`/sms/messages/${user.userId}`);
                                }}
                              />
                            </TableCell>,
                          ],
                          onClick: () => {
                            history.push(`/sms/messages/${user.userId}`);
                          },
                        }))}
                      />
                      <TableFooter>
                        <TablePaginationNew />
                        <TablePaginationSizeNew globalName='sms_user_list' />
                      </TableFooter>
                    </>
                  ) : loading ? (
                    <LoadingIndicator isLoading={loading} height='500px' />
                  ) : items.length === 0 ? (
                    <p
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: '36px 0',
                      }}
                    >
                      No matching results found.{' '}
                    </p>
                  ) : null}
                </div>
              </TableContextProvider>
            </Layout>
          </Content>
        </MainWrapper>
      </MainContainer>
    </>
  );
};
