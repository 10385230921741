import * as React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import {
  CloseButton,
  Modal,
  Search,
  NewTextInput,
  Table,
  CheckboxInput,
} from 'lib/components';

import { GroupListItem } from 'lib/api/types';
import { MdAdd } from 'react-icons/md';
import placeholder from 'lib/images/tablePlaceholder.jpg';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { useCreateGroupMutation } from 'lib/api/group/useCreateGroupMutation';
import { useIsMutating } from 'react-query';
import { Gap } from '../styles/layout';

type Props = {
  disabled?: boolean;
  title?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (selectedGroups: any) => void;
  selectedContacts?: any;
  existingGroups?: number[];
};

const ModalItem = styled.div`
  min-height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Checkbox = styled(CheckboxInput)`
  float: left;
  margin-right: 8px;
  display: inline;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

const TableCell = styled.div`
  padding-right: 8px;
  text-align: left;
`;

const TableCellRight = styled.div`
  text-align: right;
`;

const NoGroupsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 208px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const NoGroups = styled.div`
  height: 24px;
  ${theme.fontBold700}
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
`;

/* SUS-908 changes */
const Text = styled.div`
  color: ${theme.palette.buttonDanger};
  position: absolute;
  margin-top: 35px;
`;

export const ModalAddContactToGroup = ({
  disabled = false,
  handleModalClose,
  handleSubmit,
  existingGroups,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [groupSearch, setGroupSearch] = React.useState('');
  const GROUP_SIZE = 100;

  const groupsQueryParams = {
    search: groupSearch,
    size: GROUP_SIZE,
  };

  const { data: groupsData } = useQueryGroups(groupsQueryParams);
  const groupItems = groupsData?.items ?? [];

  const { mutateAsync: addGroupMutation } = useCreateGroupMutation(
    undefined,
    groupsQueryParams
  );

  const [createGroup, setCreateGroup] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState(
    [] as GroupListItem[]
  );

  const [displayGroups, setDisplayGroups] = React.useState(
    [] as GroupListItem[]
  );
  const [group, setGroup] = React.useState({
    name: '',
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Filter out the groups that already contains the contact
  React.useEffect(() => {
    const displayGroupsTmp = groupItems.filter(
      group =>
        !existingGroups || !existingGroups.includes(parseInt(group.groupId, 10))
    );
    setDisplayGroups(displayGroupsTmp);
  }, [groupItems]);

  const removeGroupFromSelection = (id: string) => {
    const list = selectedGroups.filter(
      (item: GroupListItem) => item.groupId !== id
    );
    setSelectedGroups(list);
  };

  const onCreateGroup = async (event?: MouseEvent) => {
    if (event && event.detail && event.detail > 1) {
      return;
    }
    let addToGroup = groupsData?.items.find(
      g => g.name.toLowerCase() === group.name.toLowerCase()
    );

    if (!addToGroup) {
      addToGroup = await addGroupMutation(group.name);
    }

    if (addToGroup) {
      setSelectedGroups(
        selectedGroups.concat({
          groupId: addToGroup.groupId,
          name: addToGroup.name,
        })
      );
    }

    setGroup({ ...group, name: '' });
    setCreateGroup(false);
  };

  const onGroupCheckboxClick = (groupObj: any) => {
    const isGroupAlreadySelected = selectedGroups.find((currentGroup: any) => {
      return groupObj.groupId === currentGroup.groupId;
    });
    if (isGroupAlreadySelected) {
      removeGroupFromSelection(groupObj.groupId);
    } else {
      setSelectedGroups(selectedGroups.concat([groupObj]));
    }
  };

  return (
    <Modal>
      <ModalItem>
        <Content style={{ width: 1008 }}>
          <ContentHeaderWrap>
            <ContentHeader>Add Contacts to Group (optional)</ContentHeader>
            <CloseButton disabled={disabled} onClick={handleModalClose} />
          </ContentHeaderWrap>
          <ContentBody>
            <Row style={{ marginBottom: 32 }}>
              <div style={{ width: 300 }}>
                <Search
                  placeholder='Search Groups...'
                  onSearch={(value: string) => setGroupSearch(value)}
                />
              </div>
              {createGroup ? (
                <Gap gap='8px'>
                  <div style={{ display: 'flex' }}>
                    <NewTextInput
                      style={{ boxSizing: 'border-box' }}
                      placeholder='Group Name'
                      type='text'
                      autoFocus={true}
                      value={group.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setGroup({ ...group, name: e.target.value })
                      }
                    />
                    {/* SUS-908 changes */}
                    {group.name && group.name.length > 60 && (
                      <Text>
                        <span>
                          Group name can not contain more than 60 characters.
                        </span>
                      </Text>
                    )}
                  </div>
                  <Button
                    onClick={(event?: MouseEvent | React.SyntheticEvent) =>
                      onCreateGroup(event as MouseEvent)
                    }
                    disabled={
                      group.name.length == 0 ||
                      group.name.length > 60 ||
                      isDisabled
                    } /* SUS-908 changes */
                    text='Create Group'
                    variant='primary'
                    icon={<MdAdd />}
                  />
                  <Button
                    onClick={() => {
                      setGroup({ ...group, name: '' });
                      setCreateGroup(false);
                    }}
                    text='Cancel'
                    variant='secondary'
                    disabled={isDisabled}
                  />
                </Gap>
              ) : (
                <Button
                  onClick={() => setCreateGroup(true)}
                  text='Create Group'
                  variant='secondary'
                  icon={<MdAdd />}
                  disabled={isDisabled}
                />
              )}
            </Row>
            {displayGroups.length === 0 && !groupSearch ? (
              <NoGroupsWrap>
                <NoGroups>You have no groups</NoGroups>
              </NoGroupsWrap>
            ) : (
              <>
                <Table
                  compact={true}
                  hoverable={false}
                  rows={displayGroups.map((x, index: number) => ({
                    key: index,
                    columns: [
                      <TableCell>
                        <Checkbox
                          checked={
                            !!selectedGroups.find(
                              group => group.groupId === x.groupId
                            )
                          }
                          onChange={() => {
                            onGroupCheckboxClick(x);
                          }}
                        />
                        {x.name}
                      </TableCell>,
                      <TableCellRight>
                        {x.contacts && x.contacts.length + ' Contacts'}
                      </TableCellRight>,
                    ],
                    onClick: () => {},
                  }))}
                />
                {displayGroups.length === 0 && (
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '36px 0',
                    }}
                  >
                    No matching results found.{' '}
                  </p>
                )}
              </>
            )}
          </ContentBody>
        </Content>
        <ButtonContainer>
          <Button
            disabled={selectedGroups.length === 0 || isDisabled}
            text={`Add to ${
              selectedGroups.length > 0 ? selectedGroups.length : ''
            } Group${selectedGroups.length > 1 ? 's' : ''}`}
            onClick={() => handleSubmit(selectedGroups)}
          />
        </ButtonContainer>
      </ModalItem>
    </Modal>
  );
};
