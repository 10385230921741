import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';

import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { createDepartment, updateUserDepartment } from 'lib/api';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';
import { useManagedDepartmentsQuery } from 'lib/api/departments/useManagedDepartmentsQuery';

type ListContainerProps = {
  view: number | string;
};

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  gap: 12px;
`;

const ListContainer = styled.div<ListContainerProps>`
  background: ${props => (props.view == 1 ? '#F6F7F9' : theme.palette.white)};
  max-height: 300px;
  overflow-y: ${props => (props.view == 1 ? 'scroll' : 'auto')};

  -ms-overflow-style: scrollbar;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.gray};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 27, 83, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 27, 83, 0.4);
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 10px;
  .item--label {
    display: flex;
    label {
      padding-left: 10px;
    }
  }
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 48%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #001b53;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

type Props = {
  handleModalClose: (reportId?: string | number) => void;
  selectedUser?: any;
};

export const AssignDepartmentModal = (props: Props) => {
  const [department, setDepartment] = React.useState('');
  const [view, setView] = React.useState(1);
  const [newDeptEntry, setNewDeptEntry] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [selectedDept, setSelectedDept] = React.useState<any>([]);

  const { handleModalClose, selectedUser } = props;

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const {
    userData: { customerId },
  } = useAuth();

  const {
    data,
    isLoading: loading,
    refetch: refreshDepartment,
  } = useManagedDepartmentsQuery({
    customerId,
  });
  const themes = useTheme();

  const { showError } = useToastError();

  React.useEffect(() => {
    refreshDepartment();
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const saveDepartment = async () => {
    setLoadingModal(true);
    try {
      await createDepartment({ name: department.trim() });
      successToast({ title: 'Department added successfully!' });
      await refreshDepartment();
      setNewDeptEntry(false);
      setLoadingModal(false);
      setDepartment('');
    } catch (error) {
      showError(error);
      setLoadingModal(false);
    }
    setLoadingModal(false);
  };

  const saveUserDepartments = async () => {
    setLoadingModal(true);
    try {
      await updateUserDepartment({
        userIds: selectedUser,
        departmentIds: selectedDept,
      });
      successToast({ title: 'Department added successfully!' });
      handleModalClose(1);
      setLoadingModal(false);
    } catch (error) {
      showError(error);
      setLoadingModal(false);
    }
    setLoadingModal(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            {view == 1
              ? `Add/Change Department for ${selectedUser.length} Users`
              : `Add ${selectedUser.length} users to the selected department?`}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {!newDeptEntry && view == 1 && (
            <Button
              onClick={() => {
                setNewDeptEntry(true);
              }}
              text='Create Department'
              style={{ marginBottom: '16px' }}
              variant='primary'
              icon={<MdAdd />}
            />
          )}
          {newDeptEntry && (
            <InputWrapper>
              {/* <Label>Department name</Label> */}
              <Input
                type='text'
                value={department}
                placeholder='Department Name'
                onChange={event => setDepartment(event.target.value)}
              />
              <Button
                onClick={() => {
                  saveDepartment();
                }}
                text='Create Department'
                variant='primary'
                icon={<MdAdd color={themes.colors.white[100]} />}
                disabled={!department.trim().length}
              />
              <Button
                onClick={() => {
                  setNewDeptEntry(false);
                  setDepartment('');
                }}
                text='Cancel'
                variant='destructive'
              />
            </InputWrapper>
          )}
          <ListContainer view={view}>
            {view == 1 &&
              (data || []).map((e, i: number) => {
                return (
                  <ListItem key={`user-item-${i}`}>
                    <div className='item--label'>
                      <CheckboxInput
                        blueCheck={true}
                        checkGroupIndicator={false}
                        checked={selectedDept.includes(e.departmentId)}
                        onChange={(event: React.SyntheticEvent) => {
                          event.stopPropagation();
                          let { checked } = event.target as HTMLInputElement;
                          if (checked)
                            // setSelectedDept([...selectedDept, e.DepartmentID]);
                            setSelectedDept([e.departmentId]);
                          // setSelectedDept(selectedDept.filter((el: any) => el != e.DepartmentID));
                          else setSelectedDept([]);
                        }}
                      />
                      <label>{e.name}</label>
                    </div>
                    <label>{e.userCount} users</label>
                  </ListItem>
                );
              })}
            {view != 1 && (
              <p style={{ fontSize: '16px' }}>
                The selected users will now belong in the selected department.
              </p>
            )}
          </ListContainer>
        </Content>
        <Footer>
          {view == 2 && (
            <Button
              variant='secondary'
              text='No, Cancel'
              onClick={() => {
                handleModalClose();
              }}
              disabled={!selectedDept.length || loadingModal || loading}
            />
          )}
          <Button
            text={view == 1 ? `Save Changes` : `Yes Apply`}
            onClick={async () => {
              view == 1 ? setView(2) : await saveUserDepartments();
            }}
            disabled={!selectedDept.length || loadingModal || loading}
          />
        </Footer>
      </div>
    </Modal>
  );
};
