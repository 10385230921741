import React from 'react';
import styled from 'styled-components/macro';
import { useHistory, RouteComponentProps } from 'react-router';
import { MdKeyboardArrowRight, MdDelete, MdEdit } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { theme } from 'lib/style';

import { LoadingIndicator, MainContainer } from 'lib/components';
import { useAuth } from 'lib/context';
import { UserListing } from './UserListing';
import { DeleteDepartmentModal } from './DeleteDepartmentModal';
import { CreateDepartmentModal } from './CreateDepartmentModal';
import { MainContainer as CustomMainContainer } from '../userDepartment/index.styled';
import { getDepartments } from 'lib/api';
import { NotFound } from 'app/pages/notFound/NotFound';
import { checkNumeric } from 'lib/utils/functions';
import { Button } from 'react-covideo-common';

const MainContainerWrapper = styled(MainContainer)`
  align-items: start;
`;
const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0;
    nav {
      &:nth-of-type(1) {
        display: flex;
      }
      width: 100%;
      height: 48px;
      > div {
        float: left;
        display: block;
        background-color: ${theme.palette.white};
        border-top: 1px solid ${theme.palette.grayBorder};
        border-left: 1px solid ${theme.palette.grayBorder};
        border-right: 1px solid ${theme.palette.grayBorder};
        height: 48px;
        box-sizing: border-box;
        line-height: 1.5;
        padding-top: 12px;
        &:nth-of-type(1) {
          width: 152px;
        }
        &:nth-of-type(2) {
          width: 115px;
          border-left: none;
          border-right: none;
        }
        &:nth-of-type(3) {
          width: 100px;
        }
        &:nth-of-type(4) {
          flex-grow: 2;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid ${theme.palette.grayBorder};
          background-color: #fbfbfb;
          &:hover {
            cursor: default;
          }
          disabled: true;
        }
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 20px;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: 205px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
`;

const BreadcrumbsContainer = styled.div`
  display flex;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

interface MatchParams {
  id: string;
}

export const DepartmentDetailLayout = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const [deptObj, setDeptObj] = React.useState({
      department: '',
      reload: () => {},
    });
    const [config, setConfig] = React.useState({
      deptData: {},
      mapArr: [],
      index: -1,
    });
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showCreateModal, setShowCreateModal] = React.useState<any>(false);
    const [loading, setLoading] = React.useState(true);
    const [userActiveStatus, setUserActiveStatus] = React.useState('1');

    const history = useHistory();
    const { id } = props.match.params;

    const {
      userData: { customerId },
    } = useAuth();

    const goToUsers = () => history.push('/users/list');
    const goToDepartments = () => history.push('/users/list?view=department');

    const getDept = async () => {
      setLoading(true);
      const isNumber = checkNumeric(id);
      if (!isNumber) {
        setConfig({
          deptData: {},
          mapArr: [],
          index: -1,
        });
        setLoading(false);
        return;
      }
      const dept = await getDepartments(customerId, {});
      const mapArr: any = [{ label: 'All Departments', value: 0 }];
      dept.data.forEach((e: any) => {
        mapArr.push({ label: e.Name, value: e.DepartmentID });
      });
      const index = mapArr.findIndex((e: any) => {
        return e.value == id;
      });
      setConfig({
        deptData: dept,
        mapArr: mapArr,
        index: index,
      });
      setLoading(false);
    };

    React.useEffect(() => {
      getDept();
    }, []);

    if (loading) {
      return <LoadingIndicator isLoading={loading} />;
    }

    if (config.index === -1) {
      return <NotFound />;
    }

    return (
      <CustomMainContainer>
        <MainContainerWrapper>
          <BreadcrumbsContainer>
            <LinkBtn onClick={goToUsers}>Manage users</LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkBtn onClick={goToDepartments}>Departments</LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkText>{deptObj.department}</LinkText>
          </BreadcrumbsContainer>
          <TabsContainer>
            <FiltersContainer>
              <FieldContainer>
                <Filter>
                  <h3>Department Details</h3>
                </Filter>
              </FieldContainer>
              <ButtonContainer>
                <Button
                  variant='secondary'
                  icon={<MdEdit color={theme.palette.black_1_100} />}
                  text={'Edit'}
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                />
                <Button
                  variant='destructive'
                  icon={<MdDelete color={theme.palette.primaryRedDanger} />}
                  text={'Delete Department'}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                />
              </ButtonContainer>
            </FiltersContainer>
          </TabsContainer>
          <Content>
            <UserListing
              config={config}
              setDeptObj={setDeptObj}
              setUserActiveStatus={setUserActiveStatus}
              userActiveStatus={userActiveStatus}
              deptId={id}
            />
          </Content>
          {showDeleteModal && (
            <DeleteDepartmentModal
              handleModalClose={(shouldRefresh: boolean = false) => {
                setShowDeleteModal(false);
                if (shouldRefresh) goToDepartments();
              }}
              deptObj={{ dept: deptObj.department, ids: [id] }}
            />
          )}
          {showCreateModal && (
            <CreateDepartmentModal
              isEdit={true}
              deptName={deptObj.department}
              selectedDept={[id]}
              customerId={customerId}
              handleModalClose={shouldRefresh => {
                setShowCreateModal(false);
                if (shouldRefresh) {
                  deptObj.reload();
                }
              }}
            />
          )}
        </MainContainerWrapper>
      </CustomMainContainer>
    );
  }
);
