import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Select, { components, ControlProps, OptionProps } from 'react-select';
import { DropdownIcon } from 'lib/images/DropdownIcon';
import { theme } from 'lib/style';
import CheckmarkIcon from 'lib/images/CheckmarkIcon';
import { CommonTypography } from '../styles/typography';
import SharedFolderIcon from '../../images/SharedFolderIcon';
import AccountsIcon from 'lib/images/AccountsIcon';
import { FOLDER_GROUP_LABEL } from 'app/pages/library/videos/videoList/constants';
import {
  OptionWrapper,
  DropdownArrowIcon,
  OptionIconWrapper,
  getControlStyles,
  DropdownIndicatorProps,
  getOptionStyles,
} from './Dropdown';
import FolderIcon from '../../images/FolderIcon';
import { ActionMeta } from 'react-select/src/types';
import { ALL_FOLDERS } from 'lib/const/Folders';

type Option = {
  value: string | number;
  label: string | number;
  isFolderShared?: boolean;
  access?: boolean | string;
};

type Icon = 'folder' | 'sharedFolder' | 'accounts' | '';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px 0px 16px;
  gap: 12px;
`;

const Label = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.covideoBlue40};
`;

const Line = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.blue05};
`;

const OptionChildSubTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--covideo-grey-80, #4e5461);
  text-overflow: ellipsis;

  /* Other/Charts Label */
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const SharedOptionWrapper = styled.div`
  display: block;
  width: 100%;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const TooltipContent = styled.div`
  z-index: 99999;
  position: absolute;
  width: 100%;
  height: auto;
  display: inline-flex;
  padding: 4px 12px 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  right: 0%;
  bottom: 70%;
  left: -8%;

  border-radius: 1px;
  background: var(--white-100, #fff);

  /* Medium Shadow */
  box-shadow:
    0px 6px 12px 0px rgba(66, 79, 104, 0.06),
    0px 0px 4px 0px rgba(66, 79, 104, 0.08);
  background-color: ${theme.palette.white};
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.08);
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
`;

const TooltipTrigger = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;

  &:hover + ${TooltipContent} {
    opacity: 1;
    visibility: visible;
  }
`;

const TooltipTitle = styled.div`
  color: var(--neutral-100, #272a32);
  font-variant-numeric: lining-nums tabular-nums;

  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const TooltipSubTitle = styled.div`
  color: var(--neutral-100, #272a32);

  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

type Props = {
  value: Option;
  options: Array<Option>;
  onChange: (value: any, actionMeta?: ActionMeta<Option>) => void; // any is used as its type is unknown.
};

const OptionChildWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  color: var(--covideo-grey-80, #4e5461);
  text-overflow: ellipsis;
  white-space: nowrap;
`;

//generate icon if selected
const getIcon = (
  iconName: Icon | undefined,
  isSelected: boolean,
  id: string,
  value: number | string
) => {
  if (typeof iconName === 'undefined') return null;
  switch (iconName) {
    case 'folder':
      return id.includes('option-0') && value !== ALL_FOLDERS.value ? (
        <div style={{ marginRight: '-6px', marginBottom: '-3px' }}>
          <FolderIcon height={16} width={20} />
        </div>
      ) : (
        isSelected && <CheckmarkIcon />
      );
    case 'sharedFolder':
      return !isSelected ? (
        <div style={{ marginRight: '-6px', marginBottom: '-3px' }}>
          <SharedFolderIcon height={16} width={20} />
        </div>
      ) : (
        isSelected && <CheckmarkIcon />
      );
    case 'accounts':
      return !isSelected ? (
        <div style={{ marginRight: '-6px', marginBottom: '-3px' }}>
          <AccountsIcon height={16} width={20} />
        </div>
      ) : (
        isSelected && <CheckmarkIcon />
      );
    default:
      return null;
  }
};

// show different option UI if icon exist
const CustomOption = ({ children, ...props }: OptionProps<Option, false>) => {
  const { selectProps, isSelected, innerProps } = props;
  if (
    children === FOLDER_GROUP_LABEL.MY_FOLDERS ||
    children === FOLDER_GROUP_LABEL.SHARED_WITH_ME
  ) {
    return (
      <LabelContainer>
        <Label>{children}</Label>
        <Line />
      </LabelContainer>
    );
  }
  return (
    <components.Option {...props}>
      <TooltipWrapper>
        <TooltipTrigger>
          <SharedOptionWrapper>
            <OptionWrapper>
              <OptionChildWrapper>{children}</OptionChildWrapper>
              <OptionIconWrapper>
                {getIcon(
                  !!props.data.isFolderShared || !!props.data.access
                    ? !!props.data.access
                      ? 'accounts'
                      : 'sharedFolder'
                    : selectProps.icon,
                  isSelected,
                  innerProps.id,
                  props.data.value
                )}
              </OptionIconWrapper>
            </OptionWrapper>
            {!!props.data.sharedBy && (
              <OptionChildSubTitle>
                Shared by {props.data.sharedBy}
              </OptionChildSubTitle>
            )}
          </SharedOptionWrapper>
        </TooltipTrigger>
        {props.data.value > 0 && (
          <TooltipContent>
            <SharedOptionWrapper>
              <TooltipTitle>{children}</TooltipTitle>
              {!!props.data.sharedBy && (
                <TooltipSubTitle>
                  Shared by {props.data.sharedBy}
                </TooltipSubTitle>
              )}
            </SharedOptionWrapper>
          </TooltipContent>
        )}
      </TooltipWrapper>
    </components.Option>
  );
};

export const FolderDropdown = (props: Props) => {
  const { options, value, onChange } = props;
  const themes = useTheme();

  //common props
  const commonProps = {
    options,
    value,
    onChange,
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <DropdownArrowIcon menuIsOpen={props.selectProps.menuIsOpen}>
        <DropdownIcon width={'12px'} height={'12px'} opacity={0.5} />
      </DropdownArrowIcon>
    );
  };

  const CustomSingleValue = (props: any) => {
    return props.children;
  };

  //dropdown styles
  const styles = {
    container: (base: React.CSSProperties) => {
      return {
        ...base,
      };
    },

    control: (
      base: React.CSSProperties,
      props: ControlProps<Option, false>
    ): React.CSSProperties => {
      return {
        ...base,
        boxShadow: 'none',
        borderRadius: 6,
        minHeight: '40px',
        cursor: 'pointer',
        ...getControlStyles(props),
        //@ts-ignore
        '&:hover': {
          borderColor: theme.palette.covideoBlue100,
        },
        '&:focus-within': {
          borderColor: theme.palette.covideoBlue100,
        },
      };
    },
    singleValue: (base: React.CSSProperties) => ({
      ...base,
      width: '95%',
    }),
    valueContainer: (base: React.CSSProperties) =>
      ({
        ...base,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
      }) as React.CSSProperties,
    input: (base: React.CSSProperties) => ({
      ...base,
    }),
    menuPortal: (base: React.CSSProperties) => ({
      ...base,
      zIndex: 9999,
    }),
    menuList: (base: React.CSSProperties) => ({
      ...base,
      height: 'auto',
    }),
    menu: (base: React.CSSProperties) => ({
      ...base,
      marginTop: 2,
      color: theme.palette.primaryDarkBlue,
    }),
    option: (
      base: React.CSSProperties,
      props: OptionProps<Option, false>
    ): React.CSSProperties => {
      return {
        ...base,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        fontWeight: 600,
        fontSize: 15,
        zIndex: 1,
        //@ts-ignore
        ...getOptionStyles(props, themes),
      };
    },
  };

  return (
    <Select
      {...commonProps}
      icon='folder'
      error={false}
      className='dropdown'
      placeholder='Filter by type...'
      isDisabled={false}
      aria-label='Aria label is missing'
      menuPosition='absolute'
      menuPlacement='auto'
      optionHeight={48}
      maxMenuHeight={220}
      menuShouldScrollIntoView={false}
      menuPortalTarget={null}
      isLoading={false}
      components={{
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: () => null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      styles={styles}
      isSearchable={true}
    />
  );
};
