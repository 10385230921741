import { useQuery } from 'react-query';
import { socialMediaLinksKeys } from './socialMediaLinksKeys';
import { GetSocialLinksResponse } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getSocialMediaLinks =
  async (): Promise<GetSocialLinksResponse> => {
    const response = await EXPRESS_API.get('/social-link');
    return response.data;
  };

export const useSocialLinksQuery = () => {
  return useQuery(socialMediaLinksKeys.get(), () => getSocialMediaLinks(), {
    refetchOnMount: true,
  });
};
