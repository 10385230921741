import * as React from 'react';
import { FaStar } from 'react-icons/fa';
import {
  MdAutoDelete,
  MdDeleteForever,
  MdEmail,
  MdExtension,
  MdScheduleSend,
  MdSubtitles,
  MdWork,
  MdCreditCard,
  MdHelp,
  MdDirectionsRun,
} from 'react-icons/md';
import { BiWorld } from 'react-icons/bi';
import LabelIcon from '../images/LabelIcon';
import ShowServicesIcon from '../images/ShowServicesIcon';
import ShowSnippetsIcon from '../images/ShowSnippetsIcon';
import { productFeature } from './productFeature';
import AccountsIcon from 'lib/images/AccountsIcon';
import AccountIcon from 'lib/images/AccountIcon';
import { theme } from 'lib/style';

export const PROFILE = {
  title: 'My Profile',
  icon: <AccountIcon width='20px' height='20px' />,
  path: '/profile/Profile_EditUserInfo',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
const COMPANY = {
  title: 'Company Profile',
  icon: <MdWork size={20} />,
  path: `/profile/Profile_EditCompanyInfo`,
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const MANAGE_USERS = {
  title: 'Manage Users',
  icon: <AccountsIcon width='20px' height='20px' />,
  path: `/users`,
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const BILLING = {
  title: 'Billing',
  icon: <MdCreditCard size={20} />,
  path: '/billing',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const ADDONS = {
  title: 'Add-ons',
  icon: <MdExtension size={20} />,
  path: `/profile/add-ons`,
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
const VIDEO_LABEL = {
  title: 'VIDEOS',
  isDisabled: false,
  isLabel: true,
  path: ``,
};
export const TELEPROMPTER = {
  title: 'Teleprompter',
  shortTitle: 'Teleprompter',
  icon: <MdSubtitles size={20} />,
  path: '/profile/teleprompter',
  isDisabled: false,
  productFeatureId: productFeature.TELEPROMPTER,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const VIDEO_ATTRIBUTES = {
  title: 'Video Attributes',
  shortTitle: 'Video Attributes',
  icon: <LabelIcon width='20px' height='20px' />,
  path: '/profile/Profile_VideoAttributes',
  isDisabled: false,
  productFeatureId: productFeature.VIDEO_ATTRIBUTES,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const VIDEO_EXPIRATION = {
  title: 'Video Expiration',
  shortTitle: 'Video Expiration',
  icon: <MdAutoDelete size={20} />,
  path: '/profile/Profile_VideoDeletion',
  isDisabled: false,
  productFeatureId: productFeature.VIDEO_EXPIRATION,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const EMAILS_LABEL = {
  title: 'EMAILS',
  isDisabled: false,
  isLabel: true,
  path: ``,
  iconColor: theme.palette.covideoBlue40,
};
export const SCHEDULES = {
  title: 'Schedules',
  icon: <MdScheduleSend size={20} />,
  path: '/profile/schedules',
  isDisabled: false,
  productFeatureId: productFeature.SCHEDULES,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const EMAIL_BATCHES = {
  title: 'Email Batches',
  icon: <MdEmail size={20} />,
  path: '/profile/Email_Batches',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
const SERVICES = {
  title: 'Services',
  shortTitle: 'Services',
  icon: <ShowServicesIcon />,
  path: '/profile/services',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const TEMPLATES = {
  title: 'Templates',
  shortTitle: 'Templates',
  icon: <ShowSnippetsIcon />,
  path: '/profile/templates',
  isDisabled: false,
  productFeatureId: productFeature.TEMPLATES,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
const OTHERS_LABEL = {
  title: 'OTHERS',
  isDisabled: false,
  isLabel: true,
  path: ``,
};
const SOCIAL_PROFILES = {
  title: 'Social Profiles',
  icon: <BiWorld size={20} />,
  path: '/profile/social-profiles',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const HELP_CENTER = {
  title: 'Help Center',
  icon: <MdHelp size={20} />,
  path: '/Help',
  // @ts-ignore
  customLink: window['supportURL'] || 'https://support.covideo.com',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const AFFILIATE = {
  title: 'Become an Affiliate',
  icon: <FaStar size={20} />,
  path: '/profile/Profile_AffiliateApply',
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const RECENTLY_DELETED = {
  title: 'Recently Deleted',
  icon: <MdDeleteForever size={20} />,
  path: `/profile/recently-deleted`,
  isDisabled: false,
  iconColor: theme.palette.covideoBlue40,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};
export const LOGOUT = {
  title: 'Log Out',
  icon: <MdDirectionsRun size={20} />,
  path: '/Sign_Out',
  isDisabled: false,
  iconColor: theme.palette.red40,
  color: theme.palette.red100,
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
};

export const adminUserItems = [
  PROFILE,
  COMPANY,
  MANAGE_USERS,
  BILLING,
  ADDONS,
  VIDEO_LABEL,
  TELEPROMPTER,
  VIDEO_ATTRIBUTES,
  VIDEO_EXPIRATION,
  EMAILS_LABEL,
  SCHEDULES,
  EMAIL_BATCHES,
  OTHERS_LABEL,
  SOCIAL_PROFILES,
  HELP_CENTER,
  AFFILIATE,
  RECENTLY_DELETED,
  LOGOUT,
];

export const userItems = [
  PROFILE,
  ADDONS,
  VIDEO_LABEL,
  TELEPROMPTER,
  EMAILS_LABEL,
  SCHEDULES,
  OTHERS_LABEL,
  SOCIAL_PROFILES,
  HELP_CENTER,
  AFFILIATE,
  RECENTLY_DELETED,
  LOGOUT,
];

export const supervisorItems = [
  PROFILE,
  COMPANY,
  MANAGE_USERS,
  BILLING,
  ADDONS,
  EMAILS_LABEL,
  SCHEDULES,
  OTHERS_LABEL,
  SOCIAL_PROFILES,
  HELP_CENTER,
  AFFILIATE,
  RECENTLY_DELETED,
  LOGOUT,
];

export const automotiveServiceItems = [SERVICES, TEMPLATES];

export const templatesItem = [TEMPLATES];
