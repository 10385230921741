import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink, useLocation } from 'react-router-dom';

import { theme } from 'lib/style';
import { MenuDropdown } from './page/MenuDropdown';
import { MdArrowDropDown } from 'react-icons/md';
import { NavigationItem } from 'lib/utils/NavHorizontalItems';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { useAuth } from 'lib/context';
import { InviteFriends } from './invite-friends';
import { PackageNameLabel } from 'lib/utils/productFeature';
import { useNavigationColors } from 'lib/hooks/useNavigationColor';
import { useGetConversationCount } from 'lib/api/conversations/useConversationCount';
import { calculateRange } from 'lib/utils/functions';
import { countMessageNotifications } from 'lib/utils/messageNotifications';
import { useMessagesQuery } from 'lib/api/messages/useMessagesQuery';

type Props = {
  isSideNavOpen?: boolean;
  isDropdownOpen?: boolean;
  color?: string;
  itemsLength?: number;
};

type PropsA = {
  isSideNavOpen?: boolean;
  onUserClick: (name: string) => void;
  isDropdownOpen: string;
  items: NavigationItem[];
  isTrialAccount: boolean;
};

const Container = styled.nav<Props>`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  left: -240px;
  top: 48px;
  z-index: 11;
  background: ${({ theme }) => theme.colors.primary[100]}
  height: calc(100vh - 48px);
  padding: 15px;
 
  ${props =>
    props.isSideNavOpen &&
    css`
      margin-left: 0;
      width: ${theme.menuWidth};
      left: 0;
      transition: all 0.3s;
    `};

  ${theme.mediaQueryMinWidth.mb} {
    top: 64px;
  }

  ${theme.mediaQueryMinWidth.sm} {
    margin-left: 12px;
  }
 
  // TODO
  // since this nav doesn't exist on desktop
  // shouldn't display: none be enough?
  ${theme.mediaQueryMinWidth.md} {
    top: 80px;
    height: 100%;
    position: unset;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .disabled {
      cursor: not-allowed;
      pointer-events: all !important;
      color: rgba(255, 255, 255, 0.5);
    }
    padding: 0;
    margin-left: 65px;
  }
`;

const NavItem = styled(NavLink)<Props>`
  display: flex;
  margin-top: 1px;
  padding: 21px 0 18px 0;
  min-height: 20px;
  align-items: center;
  text-align: center; /* to center icons */
  opacity: 1;
  transition: all 0.2s;
  margin-right: 24px;

  ${theme.fontNormal500};
  font-size: ${theme.fontSizes.md};
  color: ${({ color }) => color};
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }

  &.active {
    font-weight: 600;
    color: ${({ color }) => color};
    border-bottom: 4px solid;
  }
`;

const NavItemDiv = styled.div<Props>`
  display: flex;
  margin-top: 1px;
  padding: 21px 0 18px 0;
  min-height: 20px;
  align-items: center;
  text-align: center; /* to center icons */
  opacity: 1;
  transition: all 0.2s;
  border-radius: 4px;
  margin-right: 24px;

  ${theme.fontNormal500};
  font-size: ${theme.fontSizes.md};
  color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &.active {
    color: ${theme.palette.white};
    border-bottom: 4px solid;
  }
`;

const NavWrap = styled.div<Props>`
  position: relative;
  ${props =>
    props.itemsLength &&
    css`
      margin-right: -8px;
    `};

  .Tooltip-Wrapper {
    .Tooltip-Tip.top {
      top: calc(var(--tooltip-margin) * 2) !important;
      left: 80px !important;
    }
  }
`;

const Title = styled.span<{ hasNotifications?: boolean }>`
  margin: 0;
  text-align: left;
  position: relative;

  ${({ hasNotifications, theme }) =>
    hasNotifications &&
    css`
      :after {
        content: '';
        width: 6px;
        height: 6px;
        background: ${theme.colors.primary[100]};
        position: absolute;
        border-radius: 6px;
        right: -8px;
      }
    `}

  ${theme.mediaQueryMinWidth.lg} {
    display: block;
  }
`;

const Icon = styled.span`
  margin: 0;
  padding-right: 4px;
  font-size: ${theme.iconSizeS};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const preRenderPage = (link?: string) => {
  if (link) {
    var newPreLoadLink = document.createElement('link');
    newPreLoadLink.rel = 'prefetch';
    newPreLoadLink.href = link;
    document.head.appendChild(newPreLoadLink);
  }
};

export const HorizontalNavigation = ({
  isSideNavOpen,
  onUserClick,
  isDropdownOpen,
  items,
  isTrialAccount,
}: PropsA) => {
  //remove this after we remove sms conversations v1
  const dateObj = calculateRange('7d');
  const { userData, whitelabel } = useAuth();

  const { data } = useMessagesQuery({
    search: '',
    size: 100,
    from: dateObj.from,
    ...(userData.isCompanyAdmin ? {} : { userId: userData.userId }),
  });

  const unreadMessagesCount = countMessageNotifications(
    data?.chats || [],
    userData.userId
  );

  const { data: conversationCount } = useGetConversationCount(
    userData,
    whitelabel
  );
  const location = useLocation();
  const hasInviteFriendsWidget = location.pathname === '/home';

  const { color } = useNavigationColors();

  return (
    <Container isSideNavOpen={isSideNavOpen}>
      {items.map(
        (
          {
            title,
            path,
            icon,
            customLink,
            innerMenu,
            isDisabled,
            productFeatureId,
          },
          index
        ) => (
          <NavWrap
            itemsLength={items.length}
            key={title}
            onMouseEnter={() => preRenderPage(customLink)}
          >
            {!innerMenu ? (
              isDisabled && !isTrialAccount ? (
                <ProductInfoTooltip
                  nextPlan={
                    title === 'SMS' ||
                    title === 'Reports' ||
                    title === 'Conversations'
                      ? PackageNameLabel.TEAMS
                      : PackageNameLabel.PRO
                  }
                  productFeatureId={productFeatureId}
                  direction='bottom'
                  style={{
                    left: '50px',
                    top: '60px',
                  }}
                >
                  <NavItem
                    onClick={e => {
                      if (isDisabled) {
                        e.preventDefault();
                      }
                    }}
                    className={isDisabled ? 'disabled' : ''}
                    key={index}
                    to={path}
                    color={color}
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    {icon && <Icon>{icon}</Icon>}

                    <Title>{title}</Title>
                  </NavItem>
                </ProductInfoTooltip>
              ) : (
                <NavItem
                  onClick={e => {
                    if (isDisabled) {
                      e.preventDefault();
                    }
                  }}
                  className={isDisabled ? 'disabled' : ''}
                  key={index}
                  to={path}
                  color={color}
                >
                  {icon && <Icon>{icon}</Icon>}
                  <Title
                    hasNotifications={
                      (title === 'Conversations' && !!unreadMessagesCount) ||
                      (title === 'Conversationsv2' &&
                        !!conversationCount?.unread)
                    }
                  >
                    {title}
                  </Title>
                </NavItem>
              )
            ) : (
              <>
                <NavItemDiv
                  key={index}
                  onClick={() => onUserClick(title)}
                  isDropdownOpen={isDropdownOpen === title}
                  color={color}
                >
                  {icon && <Icon>{icon}</Icon>}
                  <Title>{title}</Title>
                  <MdArrowDropDown style={{ fontSize: 20 }} />
                </NavItemDiv>

                <MenuDropdown
                  items={innerMenu}
                  isDropdownOpen={isDropdownOpen === title}
                  OnClickOutside={() => {}}
                />
              </>
            )}
          </NavWrap>
        )
      )}
      {hasInviteFriendsWidget && <InviteFriends />}
    </Container>
  );
};
