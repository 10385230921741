import React, { useEffect } from 'react';
import { Main as RecordHomeMain } from '../recordHome/main';
import {
  HeaderCenter,
  HeaderSide,
  LinkBtn,
  LinkWrapper,
  ModalBody,
  ModalFullscreen,
  ModalHeader,
} from 'app/pages/library/videos/videoList/components/SendAndShare/styles';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { RecordOptionPath } from '../const';
import { LoadingIndicator } from 'lib/components';

const ModalBodyStyled = styled(ModalBody)`
  overflow-y: auto;
  .recordHomeMainWrapper {
    margin: 0 auto;
    min-height: calc(100vh - 183px);
  }
`;

type Props = {
  loading?: boolean;
  handleModalClose: () => void;
  onRecordOptionClick: (recordOption: RecordOptionPath | null) => void;
};

export const ModalRecordHome = ({
  handleModalClose,
  onRecordOptionClick,
  loading,
}: Props) => {
  useEffect(() => {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }

    return () => {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ''
        );
      }
    };
  }, []);

  if (loading) {
    return (
      <ModalFullscreen>
        <LoadingIndicator isLoading={true} height={'400px'} />
      </ModalFullscreen>
    );
  }
  return (
    <ModalFullscreen>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter></HeaderCenter>
        <HeaderSide></HeaderSide>
      </ModalHeader>
      <ModalBodyStyled>
        <RecordHomeMain
          showHelpDesk={false}
          showLegacyRecorderSwitch={false}
          onRecordOptionClick={onRecordOptionClick}
        />
      </ModalBodyStyled>
    </ModalFullscreen>
  );
};
