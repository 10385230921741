import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdAdd, MdEmail, MdSave } from 'react-icons/md';
import { SuperAdminCustomerDetails } from 'lib/context';
import { CardType, StatsCard } from './StatsCard';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { IoIosLogIn } from 'react-icons/io';
import { MdFileDownload } from 'react-icons/md';
import { Button, Tooltip } from 'react-covideo-common';
import { ButtonSwitch } from 'lib/components';

export enum TABS {
  USERS,
  CUSTOMER_DETAILS,
  OPT_OUT_REPORTS,
  ACTIVITY,
  MANAGE_INVITES,
  USAGE,
}

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
`;

const Heading = styled.div`
  font-family: 'Work Sans';
  margin: 0;
  text-align: left;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  line-break: anywhere;
  overflow: hidden;
  height: 40px;
  max-width: 135px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const MiddleHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const RightHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 15px;
  width: 100%;
`;

const Message = styled.p`
  font-size: 14px;

  width: 100%;
  max-width: 200px;
`;

const views = [
  {
    value: 'enabled',
    text: 'Active',
  },
  {
    value: 'disabled',
    text: 'Inactive',
  },
];

type Props = {
  isNewCustomer: boolean;
  customer?: SuperAdminCustomerDetails;
  tab: TABS;
  showStatusSwitch: boolean;
  importUsers(): void;
  onAddNewUser(): void;
  onStatusChange(value: VerificationStatus): void;
  exportUsers(): void;
  sendUsage(): void;
  downloadUsage(): void;
  manageDefaults(): void;
};

type ActionProps = {
  tooltipTitle: string;
  tooltipDescription: string;
  showTooltip: boolean;
  children: React.ReactChild;
};

export const CustomersDetailsHeader = ({
  isNewCustomer,
  customer,
  tab,
  showStatusSwitch,
  onAddNewUser,
  onStatusChange,
  importUsers,
  exportUsers,
  sendUsage,
  downloadUsage,
  manageDefaults,
}: Props) => {
  const disableAddUsers =
    !isNewCustomer &&
    tab !== TABS.CUSTOMER_DETAILS &&
    !!customer &&
    (customer.userCount ?? 0) >= (customer.maxUsers ?? 0);

  const buttonText = isNewCustomer
    ? 'Create Customer'
    : tab === TABS.CUSTOMER_DETAILS
      ? 'Save Changes'
      : 'New User';
  const icon =
    isNewCustomer || tab === TABS.CUSTOMER_DETAILS ? (
      <MdSave color={theme.palette.white || '#fff'} />
    ) : (
      <MdAdd color={theme.palette.white || '#fff'} />
    );

  const ActionButtonWithTooltip = ({
    children,
    showTooltip,
    tooltipDescription,
    tooltipTitle,
  }: ActionProps) => {
    return (
      <Tooltip
        position={'bottom' as any}
        hoverable={showTooltip}
        extendStyles={{
          popup: {
            position: 'absolute',
            width: '200px',
            left: '10%',
            transform: 'translateX(-50%)',
          },
        }}
        popup={
          <ContentWrapper>
            <Title>{tooltipTitle}</Title>
            <Message>{tooltipDescription}</Message>
          </ContentWrapper>
        }
      >
        {children}
      </Tooltip>
    );
  };
  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>
          {isNewCustomer ? 'New Customer' : customer?.business}
        </Heading>
        {!isNewCustomer && showStatusSwitch && (
          <ButtonSwitch
            defaultValue={customer?.verified ? 'enabled' : 'disabled'}
            skipConfirmation={false}
            values={views}
            onChange={newView => {
              onStatusChange(
                newView === 'enabled'
                  ? VerificationStatus.ACTIVE
                  : VerificationStatus.INACTIVE
              );
            }}
          />
        )}
      </LeftHeaderWrapper>
      {!isNewCustomer && (
        <MiddleHeaderWrapper>
          <StatsCard count={customer?.userCount ?? 0} text={'active users'} />
          <StatsCard
            count={customer?.maxUsers ?? 0}
            text={'user seats'}
            cardType={CardType.SECONDARY}
          />
        </MiddleHeaderWrapper>
      )}
      <RightHeaderWrapper>
        {!isNewCustomer && tab === TABS.USERS && (
          <Button
            onClick={manageDefaults}
            text='Manage defaults'
            variant='secondary'
          />
        )}
        {!isNewCustomer && tab === TABS.USERS && (
          <Button
            variant='secondary'
            text='Export Users'
            icon={<MdFileDownload color={theme.palette.black_1_100} />}
            onClick={exportUsers}
          />
        )}
        {!isNewCustomer && tab === TABS.USERS && (
          <ActionButtonWithTooltip
            tooltipTitle='Max users limit reached'
            tooltipDescription='For adding more users, increase your user seats'
            showTooltip={disableAddUsers}
          >
            <Button
              variant='secondary'
              icon={<IoIosLogIn />}
              onClick={!disableAddUsers ? importUsers : () => {}}
              text='Import'
              disabled={disableAddUsers}
            />
          </ActionButtonWithTooltip>
        )}
        {(tab === TABS.USERS || tab === TABS.CUSTOMER_DETAILS) && (
          <ActionButtonWithTooltip
            showTooltip={disableAddUsers}
            tooltipTitle='Max users limit reached'
            tooltipDescription='For adding more users, increase your user seats'
          >
            <Button
              onClick={onAddNewUser}
              text={buttonText}
              icon={icon}
              disabled={disableAddUsers}
            />
          </ActionButtonWithTooltip>
        )}
        {!isNewCustomer && tab === TABS.USAGE && (
          <>
            <Button text='Send' icon={<MdEmail />} onClick={sendUsage} />
            <Button
              text='Download'
              icon={<MdFileDownload />}
              onClick={downloadUsage}
            />
          </>
        )}
      </RightHeaderWrapper>
    </HeaderWrapper>
  );
};
