import * as React from 'react';
import { ErrorMsg, FieldContainer } from './AdminFormComponents';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { TextInput } from 'lib/components';
import { CommonTypography } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';

type Props = {
  name: string;
  required: boolean;
  readOnly: boolean;
  type?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  value?: string;
  isUsernameValid?: boolean;
  onChange: () => void;
};

const UsernameWarning = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: ${theme.palette.gray80};
`;

export const USERNAME = 'username';

export const EmailAndUsernameInput = (props: Props) => {
  const {
    name,
    required,
    readOnly,
    type = 'text',
    disabled = false,
    isSubmitting,
    value,
    onChange,
    isUsernameValid,
  } = props;

  return (
    <Field type={type} name={name} disabled={disabled}>
      {({ field }: FieldProps) => {
        return (
          <FieldContainer>
            <TextInput
              {...field}
              type={type}
              required={required}
              autoComplete='new-password'
              readOnly={isSubmitting || readOnly}
              disabled={disabled}
              onKeyUp={onChange}
            />
            {(!isUsernameValid || !value || value?.trim().length === 0) &&
              name === USERNAME && (
                <UsernameWarning>
                  Username should only contain letters, numbers, and dots
                  without spaces, only @-_+. special characters can be used.
                </UsernameWarning>
              )}
            {name === USERNAME && !isUsernameValid && (
              <ErrorMsg>Username is already taken.</ErrorMsg>
            )}
            <ErrorMessage name={name} component={ErrorMsg} />
          </FieldContainer>
        );
      }}
    </Field>
  );
};
