import * as React from 'react';

export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';

export const PageMoveArrow = props => {
  const { onClick, color, width, height, opacity, direction } = props;
  switch (direction) {
    case LEFT: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '24px'}
          height={height || '24px'}
          viewBox="0 0 16 17"
          onClick={onClick}
        >
          <path
            fill={color || '#001B53'}
            fillRule="evenodd"
            d="M5.351 17V9.269s7.946 7.91 7.946 7.731V1c0-.18-7.946 7.731-7.946 7.731V1H2.703v16H5.35z"
            opacity={opacity}
          />
        </svg>
      );
    }
    case RIGHT: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '24px'}
          height={height || '24px'}
          viewBox="0 0 24 24"
          onClick={onClick}
        >
          <path
            fill={color || '#24272E'}
            fillRule="evenodd"
            d="M15.973 0v11.597S4.054-.27 4.054 0v24c0 .269 11.919-11.597 11.919-11.597V24h3.973V0h-3.973z"
            opacity={opacity}
          />
        </svg>
      );
    }
    default: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '24px'}
          height={height || '24px'}
          viewBox="0 0 24 24"
          onClick={onClick}
        >
          <path
            fill={color || '#24272E'}
            fillRule="evenodd"
            d="M15.973 0v11.597S4.054-.27 4.054 0v24c0 .269 11.919-11.597 11.919-11.597V24h3.973V0h-3.973z"
            opacity={opacity}
          />
        </svg>
      );
    }
  }
};
