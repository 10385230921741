import React, { ReactNode, useState } from 'react';
import {
  AutomotiveRole,
  RESELLERS,
  VideoEmailStatus,
  VideoPages,
  VideoStatus,
} from 'lib/const';
import { HoverPopup, ListDropdownMenu } from 'lib/components';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { getDefaultPromptUseCase, truncate } from 'lib/utils/functions';
import { useHistory } from 'react-router';
import DeleteIcon from 'lib/images/DeleteIcon';
import { text } from 'lib/utils';
import {
  MdBarChart,
  MdDownload,
  MdOutlineContentCut,
  MdLibraryAdd,
} from 'react-icons/md';
import { useAuth, VideoListAutomotiveItem, VideoRequest } from 'lib/context';
import { ModalReassign } from 'lib/components';
import { VideoItemType } from './VideoItem';
import FolderVideoMoveIcon from 'lib/images/FolderVideoMoveIcon';
import DuplicateIcon from 'lib/images/DuplicateIcon';
import ReassignIcon from 'lib/images/ReassignIcon';
import { Spinner } from 'lib/components';
import { DeleteVideoModal } from 'lib/components/modal/DeleteVideoModal';
import { successToast } from 'lib/components/toasts/success';
import { ModalQuickShareMarkAsSent, ModalVDP } from '.';
import { pinVideos, unpinVideos } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';
import { RepairOrderItem } from 'lib/api/repairOrders/types';
import {
  VIDEO_LIMIT_REACHED,
  VideoLimitStatus,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { SendAndShareProvider } from 'lib/context/send-and-share/provider';
import { SendAndShareModal } from '../videoList/components/SendAndShare/SendAndShareModal';
import { CovideoMagicButton } from 'react-covideo-ai-assist';
import { PROMPT_TYPE } from 'lib/api/magic/const';
import { CRMPopup } from 'lib/components/magic/popups/CRMPopup';
import { VIDE0_ACTIVITY_TYPE } from 'lib/api/videoActivities/types';
import {
  ALL_FOLDERS,
  DefaultFolderIds,
  SHARED_FOLDER_PREFIX,
} from 'lib/const/Folders';
import selectors from '../../../../../cypress/selectors';
import { getCurrentToken } from 'configs/api/token';
import { useQuickShare } from 'lib/hooks/useQuickShare';
import { Gap } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import { useCreateVdp } from 'lib/api/vdp/useCreateVdp';
// import CopyEmailShareIcon from 'lib/images/CopyEmailShareIcon';
import QuickShareIcon from 'lib/images/QuickShareIcon';
// import CopySmsShareIcon from 'lib/images/CopySmsShareIcon';
import { IQuickShareMarkAsSentParams } from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import { HiPencil } from 'react-icons/hi';
import { BsFillPinFill, BsChatLeftTextFill } from 'react-icons/bs';
import { IoMdCar } from 'react-icons/io';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { Folder } from 'lib/api/folders/getFolders';

const BsChatLeftTextFillStyled = styled(BsChatLeftTextFill)`
  opacity: 1;
`;
export const DropdownElement = styled.div<{ disabled: boolean }>`
  position: relative;
  cursor: ${disabled => (disabled ? 'not-allowed' : 'pointer')};
  .show-more-dropdown {
    right: 0;
  }
`;

const Disable = styled.div`
  opacity: 0.4;
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NoteWrapper = styled.div`
  margin-right: 10px;
  margin-top: 4px;
  &:hover ${BsChatLeftTextFillStyled} {
    opacity: 0.4;
  }
`;
export type MoreDropdownItems = {
  title: string;
  icon: ReactNode;
  onClick: any;
  isDisabled?: boolean;
  showNonFreemiumTooltip?: boolean;
  tooltipText?: string;
  productTooltipStyle?: object;
};
type Props = {
  videoItemType: VideoItemType;
  video: VideoListAutomotiveItem;
  videoRequest?: VideoRequest;
  repairOrder?: RepairOrderItem;
  videoLimitStatus?: VideoLimitStatus;
  setShowModalQuickShareMarkAsSent: (
    showModalQuickShareMarkAsSent: boolean
  ) => void;
  handleReassignSubmit: (
    advisorId: string,
    videoIds: string[],
    advisor: any
  ) => void;
  handleDeleteSubmit?: (videoIds: string[]) => void;
  handleDeleteWithROSubmit?: (videoId: string) => void;
  handleMerge?: (videoIds: string) => void;
  handleDownload?: (downloadData: { videoId: string; title: string }[]) => void;
  handleDuplicate?: (video: any) => void;
  handleAnalyticsClick: () => void;
  handleMoveCopyButtonClick?: (videoIds: string[]) => void;
  refreshVideoList?: () => void;
  disablePinOption?: boolean;
  currentFolder?: Folder | null;
  isFolderShared?: boolean;
  showModalQuickShareMarkAsSent: boolean;
  handleOnMarkAsSentSuccessCallback?: () => void;
};
export const VideoItemActions = ({
  videoItemType,
  video,
  videoRequest,
  videoLimitStatus = VideoLimitStatus.NOT_REACHED,
  setShowModalQuickShareMarkAsSent,
  handleReassignSubmit,
  handleDeleteSubmit,
  handleDeleteWithROSubmit,
  handleMerge,
  handleDownload,
  handleDuplicate,
  handleAnalyticsClick,
  repairOrder,
  handleMoveCopyButtonClick,
  refreshVideoList,
  disablePinOption,
  currentFolder = null,
  isFolderShared = false,
  handleOnMarkAsSentSuccessCallback,
  showModalQuickShareMarkAsSent,
}: Props) => {
  const { userData } = useAuth();
  const {
    isAutomotive,
    isAutomotiveSalesRole,
    automotiveRole,
    trialExpired,
    resellerId,
  } = userData;
  // use new send&share modal if covideo whitelabel and not in list of customers who don't want to use it
  const [showMoreDropdown, setShowMoreDropdown] =
    useState(''); /** SUS-973 changes **/

  const [activeType, setActiveType] = useState<null | string>(null);

  const successMutationCallback = () => {
    handleOnMarkAsSentSuccessCallback?.();
    setActiveType(null);
  };

  const {
    onQuickShareMutationHandler,
    isQuickshareLoading,
    onQuickShareButtonClick,
  } = useQuickShare({
    setShowModalQuickShareMarkAsSent,
    handleOnMarkAsSentSuccessCallback: successMutationCallback,
  });
  const [showModalReassign, setShowModalReassign] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalPushToVDP, setShowModalPushToVDP] = useState(false);
  const [vdpLoading, setVdpLoading] = useState(false);
  const [showVideoShareModal, setShowVideoShareModal] = useState(false);
  const { mutateAsync: createVdp } = useCreateVdp();

  const isPinned = !!video.pinnedVideo?.pinnedAt;
  const showDeleteOption = resellerId?.toString() !== RESELLERS.MOSS_AUTO;
  const history = useHistory();

  const markAsSentPayload: Omit<
    IQuickShareMarkAsSentParams,
    'markAsSentConfirmed' | 'toastType'
  > = {
    videoId: video.id,
    vin: videoRequest?.vin || '',
    useAutomotive: !!videoRequest?.vin,
  };

  const goToReview = (videoRequestId: string | number) =>
    history.push(`/video-requests/${videoRequestId}`);

  const handleReview = (videoRequestId: string | number) => {
    goToReview(videoRequestId);
  };

  const handleSendShare = () => {
    setShowVideoShareModal(true);
  };

  const handleViewRO = (repairOrderId: string) => {
    history.push(`/repair-orders/${repairOrderId}/`);
  };

  const submitPushToVDP = async (vin: string, integrationType: string) => {
    setVdpLoading(true);
    try {
      await createVdp({ videoId: video.id, vin, integrationType });
    } catch (error) {
      setVdpLoading(false);
      return;
    }
    setVdpLoading(false);
    setShowModalPushToVDP(false);
  };

  const handleViewDetailsClick = () => {
    history.push(`/classic/edit-video/${video.id}/${VideoPages.DETAILS}`);
  };

  /** SUS-973 changes **/
  const handleMoreClick = (videoId: string) => {
    if (trialExpired) {
      return;
    }
    setShowMoreDropdown(showMoreDropdown !== videoId ? videoId : '');
  };
  /******/

  const handleDownloadClick = async () => {
    const downloadData = { videoId: video.id, title: video.title };
    handleDownload?.([downloadData]);
  };

  const handleDuplicateClick = () => {
    handleDuplicate?.(video);
  };

  const handleTrimClick = () => {
    history.push(`/classic/edit-video/${video.id}/${VideoPages.TRIM}`);
  };

  const handleMergeClick = () => {
    handleMerge?.(video.id);
  };

  const handleReassignClick = () => {
    setShowModalReassign(true);
  };

  const handleDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleMoveCopyClick = () => {
    handleMoveCopyButtonClick?.([video.id]);
  };

  const onDeleteSubmit = () => {
    handleDeleteSubmit?.([video.id]);
    setShowModalDelete(false);
  };

  const onDeleteWithROSubmit = () => {
    handleDeleteWithROSubmit?.(video.videoRequest.videoRequestId);
    setShowModalDelete(false);
  };

  const handlePinButtonClick = async () => {
    if (!currentFolder) {
      errorToast({
        title: 'Folder not found',
      });
      return;
    }

    const folderName = isFolderShared
      ? `${SHARED_FOLDER_PREFIX}${currentFolder.folderId}`
      : currentFolder.name;
    const videoId = [parseInt(video.id, 10)];
    try {
      !isPinned
        ? await pinVideos(videoId, folderName)
        : await unpinVideos(videoId, folderName);
      successToast({
        title: `Video ${!isPinned ? 'pinned' : 'unpinned'} successfully`,
      });
    } catch (error) {}
    refreshVideoList && refreshVideoList();
  };

  const isTechnician = automotiveRole === AutomotiveRole.TECHNICIAN;
  const isServiceAdvisor = automotiveRole === AutomotiveRole.SERVICE_ADVISOR;
  const isServiceManager = automotiveRole === AutomotiveRole.SERVICE_MANAGER;
  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);
  const videoRequestDropdownItems = [
    {
      title: 'Reassign',
      icon: <ReassignIcon color={theme.palette.blue40} />,
      onClick: handleReassignClick,
    },
  ];

  const videoDropdownItems = [
    {
      title: 'More Options',
      icon: <HiPencil color={theme.palette.blue40} size={20} />,
      onClick: handleViewDetailsClick,
      dataCy: 'more-options',
    },
    {
      title: 'Duplicate',
      dataCy: 'duplicate',
      icon: (
        <DuplicateIcon width={22} height={19} color={theme.palette.blue40} />
      ),
      onClick: handleDuplicateClick,
    },
    {
      title: 'Trim',
      icon: <MdOutlineContentCut color={theme.palette.blue40} size={20} />,
      onClick: handleTrimClick,
    },
    {
      title: 'Merge',
      icon: <MdLibraryAdd size={20} color={theme.palette.blue40} />,
      onClick: handleMergeClick,
      isDisabled: !checkIfFeatureIsEnabled(
        userData,
        productFeature.VIDEO_MERGE
      ),
      productFeatureId: productFeature.VIDEO_MERGE,
    },
    {
      title: 'Move/Copy',
      icon: <FolderVideoMoveIcon color={theme.palette.blue40} />,
      onClick: handleMoveCopyClick,
    },
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} size={20} />,
      onClick: handleDownloadClick,
      isDisabled: !!video.processing,
      showLockIcon: false,
    },
    {
      title: 'Reassign',
      icon: <ReassignIcon color={theme.palette.blue40} />,
      onClick: handleReassignClick,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: (
        <DeleteIcon color={theme.palette.buttonDelete} width={20} height={20} />
      ),
      onClick: handleDeleteClick,
    },
  ];

  const salesDropdownItems = [
    {
      title: 'More Options',
      dataCy: 'more-options',
      icon: <HiPencil color={theme.palette.blue40} size={20} />,
      onClick: handleViewDetailsClick,
    },
    {
      title: 'Duplicate',
      dataCy: 'duplicate',
      icon: (
        <DuplicateIcon width={22} height={19} color={theme.palette.blue40} />
      ),
      onClick: handleDuplicateClick,
    },
    {
      title: 'Trim',
      icon: <MdOutlineContentCut color={theme.palette.blue40} size={20} />,
      onClick: handleTrimClick,
    },
    {
      title: 'Merge',
      icon: (
        <MdLibraryAdd width={20} height={20} color={theme.palette.blue40} />
      ),
      onClick: handleMergeClick,
      isDisabled: !checkIfFeatureIsEnabled(
        userData,
        productFeature.VIDEO_MERGE
      ),
      productFeatureId: productFeature.VIDEO_MERGE,
    },
    {
      title: 'Move/Copy',
      icon: <FolderVideoMoveIcon color={theme.palette.blue40} />,
      onClick: handleMoveCopyClick,
    },
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} size={20} />,
      onClick: handleDownloadClick,
      isDisabled: !!video.processing,
      showLockIcon: false,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: (
        <DeleteIcon color={theme.palette.buttonDelete} width={20} height={20} />
      ),
      onClick: handleDeleteClick,
    },
  ];

  const generalDropdownItems = [
    {
      title: 'More Options',
      dataCy: 'more-options',
      icon: <HiPencil color={theme.palette.blue40} size={20} />,
      onClick: handleViewDetailsClick,
      isDisabled: videoLimitStatus === VideoLimitStatus.EXCEEDED,
    },
    {
      title: 'Duplicate',
      dataCy: 'duplicate',
      icon: (
        <DuplicateIcon width={22} height={19} color={theme.palette.blue40} />
      ),
      onClick: handleDuplicateClick,
      isDisabled: VIDEO_LIMIT_REACHED.includes(videoLimitStatus),
    },
    {
      title: 'Trim',
      icon: <MdOutlineContentCut color={theme.palette.blue40} size={20} />,
      onClick: handleTrimClick,
      isDisabled: VIDEO_LIMIT_REACHED.includes(videoLimitStatus),
    },
    {
      title: 'Merge',
      icon: (
        <MdLibraryAdd width={20} height={20} color={theme.palette.blue40} />
      ),
      onClick: handleMergeClick,
      isDisabled:
        !checkIfFeatureIsEnabled(userData, productFeature.VIDEO_MERGE) ||
        VIDEO_LIMIT_REACHED.includes(videoLimitStatus),
      productFeatureId: productFeature.VIDEO_MERGE,
    },
    {
      title: 'Move/Copy',
      icon: <FolderVideoMoveIcon color={theme.palette.blue40} />,
      onClick: handleMoveCopyClick,
      isDisabled:
        !checkIfFeatureIsEnabled(
          userData,
          productFeature.FOLDER_ORGANIZATION
        ) || VIDEO_LIMIT_REACHED.includes(videoLimitStatus),
    },
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} size={20} />,
      onClick: handleDownloadClick,
      isDisabled: !!video.processing,
      showLockIcon: false,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: (
        <DeleteIcon color={theme.palette.buttonDelete} width={20} height={20} />
      ),
      onClick: handleDeleteClick,
    },
  ];

  const moreDropdownItemsTechnicianPending = [
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} />,
      onClick: handleDownloadClick,
      isDisabled: !!video.processing,
      showLockIcon: false,
    },
    {
      title: 'Reassign',
      icon: <ReassignIcon color={theme.palette.blue40} />,
      onClick: handleReassignClick,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: (
        <DeleteIcon color={theme.palette.buttonDelete} width={20} height={20} />
      ),
      onClick: handleDeleteClick,
    },
  ];

  const moreDropdownItemsTechnicianNotPending = [
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} />,
      onClick: handleDownloadClick,
      isDisabled: !!video.processing,
      showLockIcon: false,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: (
        <DeleteIcon color={theme.palette.buttonDelete} width={20} height={20} />
      ),
      onClick: handleDeleteClick,
    },
  ];

  const pinOption: MoreDropdownItems = {
    title: isPinned ? 'Unpin' : 'Pin',
    icon: <BsFillPinFill color={theme.palette.blue40} />,
    onClick: handlePinButtonClick,
    isDisabled: disablePinOption && !isPinned,
    showNonFreemiumTooltip: true,
    tooltipText: 'You can pin up to 5 videos',
    productTooltipStyle: {
      padding: '0px 15px',
      width: 'max-content',
      color: theme.palette.black,
      opacity: 1,
    },
  };

  let moreDropdownItems: MoreDropdownItems[] = [];

  if (!isAutomotive) {
    moreDropdownItems = generalDropdownItems;
  } else if (isAutomotiveSalesRole) {
    moreDropdownItems = salesDropdownItems;
  } else if (
    (isServiceAdvisor || isServiceManager) &&
    videoItemType === VideoItemType.VideoRequest &&
    videoRequest?.status !== VideoStatus.Denied
  ) {
    moreDropdownItems = videoRequestDropdownItems;
  } else if (
    (isServiceAdvisor || isServiceManager) &&
    videoItemType === VideoItemType.Video
  ) {
    moreDropdownItems = videoDropdownItems;
  } else if (isTechnician && videoRequest?.status !== VideoStatus.Pending) {
    moreDropdownItems = moreDropdownItemsTechnicianNotPending;
  } else if (isTechnician && videoRequest?.status === VideoStatus.Pending) {
    moreDropdownItems = moreDropdownItemsTechnicianPending;
  }

  const deleteItemIndex = moreDropdownItems.findIndex(
    item => item.title === 'Delete'
  );

  if (deleteItemIndex > 0 && currentFolder?.folderId !== ALL_FOLDERS.value) {
    moreDropdownItems.splice(deleteItemIndex, 0, pinOption);
  }

  if (!showDeleteOption) {
    moreDropdownItems = moreDropdownItems.filter(
      item => item.title !== 'Delete'
    );
  }

  const SendShareButton = () => (
    <Button
      variant='secondary'
      text='Send & Share'
      data-cy={selectors.libraryPage.sendAndShareButton}
      onClick={() => handleSendShare()}
      disabled={
        (!userData.webEmailEnabled && !userData.webShare) ||
        videoLimitStatus === VideoLimitStatus.EXCEEDED ||
        trialExpired
      }
    />
  );

  const CopyVideoForEmailButton = () => (
    <HoverPopup
      width='100px'
      position='above'
      padding='8px 12px'
      hoverable={
        <Button
          variant='secondary'
          icon={
            activeType === VIDE0_ACTIVITY_TYPE.COPY_EMAIL ? (
              <Spinner color={theme.palette.primaryDarkBlue} />
            ) : (
              <QuickShareIcon />
            )
          }
          onClick={() => {
            setActiveType(VIDE0_ACTIVITY_TYPE.COPY_EMAIL);
            onQuickShareButtonClick({
              videoActivityType: VIDE0_ACTIVITY_TYPE.COPY_EMAIL,
              toastType: 'copyEmail',
              ...markAsSentPayload,
            });
          }}
          disabled={
            isQuickshareLoading ||
            !userData.webShare ||
            videoLimitStatus === VideoLimitStatus.EXCEEDED ||
            trialExpired
          }
        />
      }
      popup={<>Copy Video</>}
    />
  );

  // const CopyVideoForSmsButton = () => (
  //   <HoverPopup
  //     width='165px'
  //     position='above'
  //     padding='8px 12px'
  //     hoverable={
  //       <Button
  //         variant='secondary'
  //         icon={
  //           activeType === VIDE0_ACTIVITY_TYPE.COPY_SMS ? (
  //             <Spinner color={theme.palette.primaryDarkBlue} />
  //           ) : (
  //             <CopySmsShareIcon />
  //           )
  //         }
  //         onClick={() => {
  //           setActiveType(VIDE0_ACTIVITY_TYPE.COPY_SMS);
  //           onQuickShareButtonClick({
  //             videoActivityType: VIDE0_ACTIVITY_TYPE.COPY_SMS,
  //             toastType: 'copySms',
  //             ...markAsSentPayload,
  //           });
  //         }}
  //         disabled={
  //           isQuickshareLoading ||
  //           !userData.webShare ||
  //           videoLimitStatus === VideoLimitStatus.EXCEEDED ||
  //           trialExpired
  //         }
  //       />
  //     }
  //     popup={<>Copy Video for SMS</>}
  //   />
  // );
  const MagicButtonWrapper = () => (
    <CovideoMagicButton
      defaultPromptType={PROMPT_TYPE.CRM_MESSAGE}
      defaultPromptUseCase={defaultPromptUseCase}
      variant='secondary'
      margin='0px 0px 0px 8px'
      iconColor='gradient'
      videoId={video.id}
      defaultVin={videoRequest?.vin || ''}
      hoverPopup={<CRMPopup />}
      token={getCurrentToken().token as string}
      handleOnCopySuccess={() => refreshVideoList && refreshVideoList()}
      userData={{
        customer: {
          business: userData.customer.business,
          hasCovideoMagic:
            userData?.customer?.hasCovideoMagic?.toString() === '1',
          markVideosAsSent: userData.customer.markVideosAsSent,
        },
        firstName: userData.firstName,
        isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
        isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
        isIMSEnabled: userData.isIMSEnabled,
        isAutomotive: userData.isAutomotive,
        isCompanyAdmin: userData.isCompanyAdmin,
      }}
      aiAssistTheme={userData.isAutomotive ? 'automotive' : 'covideo'}
      disabled={trialExpired}
      apiKey={process.env.REACT_APP_APIKEY}
    />
  );

  const AnalyticsButton = () =>
    checkIfFeatureIsEnabled(userData, productFeature.HOTSPOTS_AND_ANALYTICS) ? (
      <HoverPopup
        width='auto'
        position='above'
        padding='8px 12px'
        hoverable={
          <Button
            variant='secondary'
            onClick={() => handleAnalyticsClick()}
            disabled={trialExpired}
            icon={<MdBarChart fontSize='24px' size={24} />}
          />
        }
        popup={<>Analytics</>}
      />
    ) : (
      <ProductInfoTooltip
        productFeatureId={productFeature.HOTSPOTS_AND_ANALYTICS}
        style={{
          top: '40px',
          right: '0',
          left: '-72px',
        }}
      >
        <div style={{ cursor: 'not-allowed' }}>
          <Button
            variant='secondary'
            onClick={() => handleAnalyticsClick()}
            disabled={trialExpired}
            icon={<MdBarChart fontSize='24px' size={24} />}
          />
        </div>
      </ProductInfoTooltip>
    );

  const VDPButton = () => (
    <HoverPopup
      width='98px'
      position='above'
      padding='8px 12px'
      hoverable={
        <Button
          variant='secondary'
          onClick={() => setShowModalPushToVDP(true)}
          icon={
            vdpLoading ? (
              <Spinner color={theme.palette.covideoBlue100} size={24} />
            ) : (
              <IoMdCar color={theme.palette.covideoBlue100} size={24} />
            )
          }
          disabled={vdpLoading || trialExpired}
        />
      }
      popup={<>Push to VDP</>}
    />
  );

  return (
    <>
      {videoRequest?.note &&
        (videoItemType === VideoItemType.VideoRequest || isTechnician) && (
          <NoteWrapper>
            <HoverPopup
              width='max-content'
              maxWidth='235px'
              position='above'
              padding='8px 12px'
              hoverable={
                <BsChatLeftTextFillStyled
                  size={14}
                  color={theme.palette.covideoBlue100}
                />
              }
              popup={<>{truncate(videoRequest.note, 50)}</>}
            />
          </NoteWrapper>
        )}
      {videoItemType === VideoItemType.VideoRequest &&
        (videoRequest?.status === VideoStatus.Pending ||
          videoRequest?.status === VideoStatus.Denied) && (
          <>
            <Button
              onClick={() =>
                videoRequest.videoRequestId
                  ? handleReview(videoRequest.videoRequestId)
                  : null
              }
              variant='secondary'
              disabled={trialExpired}
              text={`Review ${
                videoRequest.status === VideoStatus.Pending
                  ? ' Request'
                  : ' Again'
              }`}
            />
          </>
        )}
      {(!isAutomotive || isAutomotiveSalesRole) && (
        <Gap gap='8px'>
          <Gap gap='0px'>
            <SendShareButton />
            <MagicButtonWrapper />
          </Gap>

          <CopyVideoForEmailButton />
          {/*<CopyVideoForSmsButton />*/}
          <AnalyticsButton />
          {userData.isVdpAllowed && <VDPButton />}
        </Gap>
      )}
      {(isServiceAdvisor || isServiceManager) &&
        videoRequest?.status === VideoStatus.Approved && (
          <Gap m='0 0 0 8px' gap='8px'>
            {repairOrder && (
              <Button
                variant='secondary'
                onClick={() => handleViewRO(repairOrder.repairOrderId)}
                text='View RO Details'
                disabled={trialExpired}
              />
            )}
            {!repairOrder && <SendShareButton />}
            <CopyVideoForEmailButton />
            {/*<CopyVideoForSmsButton />*/}
            <AnalyticsButton />
          </Gap>
        )}
      {isTechnician &&
        videoRequest?.emailStatus !== VideoEmailStatus.Deleted && (
          <>
            <Disable>
              <Button
                variant='secondary'
                onClick={() => {}}
                text='Send & Share'
                disabled={trialExpired}
              />
              <Button
                variant='secondary'
                onClick={() => {}}
                icon={<QuickShareIcon color={theme.palette.covideoBlue100} />}
                disabled={trialExpired}
              />
              {/*<Button*/}
              {/*  variant='secondary'*/}
              {/*  onClick={() => {}}*/}
              {/*  icon={<CopySmsShareIcon color={theme.palette.covideoBlue100} />}*/}
              {/*  disabled={trialExpired}*/}
              {/*/>*/}
              <Button
                variant='secondary'
                onClick={() => {}}
                icon={
                  <MdBarChart
                    color={theme.palette.covideoBlue100}
                    fontSize='24px'
                    size={24}
                  />
                }
                disabled={trialExpired}
              />
            </Disable>
          </>
        )}
      {!!moreDropdownItems.length &&
        videoRequest?.emailStatus !== VideoEmailStatus.Deleted && (
          /** SUS-973 changes **/
          <DropdownElement
            onClick={() => handleMoreClick(video.id)}
            disabled={trialExpired}
          >
            <Button
              disabled={trialExpired}
              variant='secondary'
              icon={<BiDotsVerticalRounded size={20} />}
              style={{ marginLeft: 8 }}
            />
            <ListDropdownMenu
              className={'show-more-dropdown'}
              isMenuOpen={showMoreDropdown === video.id} /** SUS-973 changes **/
              items={moreDropdownItems}
              id={'show-more-dropdown'}
              dataCy={selectors.libraryPage.showMoreOptionsDropdown}
              setIsMenuOpen={() =>
                setShowMoreDropdown('')
              } /** SUS-973 changes **/
            />
          </DropdownElement>
        )}
      {showModalReassign && (
        <ModalReassign
          assignTo={[video]}
          handleSubmit={handleReassignSubmit}
          handleModalClose={() => setShowModalReassign(false)}
          title={'Reassign video to another advisor'}
        />
      )}
      {showModalDelete &&
        (video.videoRequest ? (
          <DeleteVideoModal
            handleModalClose={() => setShowModalDelete(false)}
            handleModalSubmit={() => onDeleteWithROSubmit()}
            content={text.deleteModal.deleteModalWithROBody}
            isCompanyVideo={
              currentFolder?.folderId === DefaultFolderIds.COMPANY
            }
          />
        ) : (
          <DeleteVideoModal
            handleModalClose={() => setShowModalDelete(false)}
            handleModalSubmit={() => onDeleteSubmit()}
            content={text.deleteModal.deleteModalBody}
            isCompanyVideo={
              currentFolder?.folderId === DefaultFolderIds.COMPANY
            }
          />
        ))}
      {showModalPushToVDP && (
        <ModalVDP
          defaultValue={videoRequest?.vin || ''}
          handleSubmit={submitPushToVDP}
          handleModalClose={() => setShowModalPushToVDP(false)}
        />
      )}

      {showVideoShareModal && (
        <SendAndShareProvider videoId={Number(video.id)}>
          <SendAndShareModal
            handleModalClose={() => {
              setShowVideoShareModal(false);
              refreshVideoList && refreshVideoList();
            }}
          />
        </SendAndShareProvider>
      )}
      {showModalQuickShareMarkAsSent && (
        <ModalQuickShareMarkAsSent
          markAsSentPayload={{
            ...markAsSentPayload,
            toastType:
              activeType === VIDE0_ACTIVITY_TYPE.COPY_SMS
                ? 'copySms'
                : 'copyEmail',
            ...(activeType === null ? {} : { videoActivityType: activeType }),
          }}
          isQuickShareLoading={isQuickshareLoading}
          quickShareMarkAsSentHandler={onQuickShareMutationHandler}
          handleModalClose={async () => {
            setShowModalQuickShareMarkAsSent(false);
            setActiveType(null);
          }}
        />
      )}
    </>
  );
};
