import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Formik, FieldArray, Field, Form, FieldAttributes } from 'formik';

import { theme } from 'lib/style';
import { CheckboxInput, Dropdown, NewModal, TextInput } from 'lib/components';
import { CheckboxLabel } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';

import DeleteIcon from 'lib/images/DeleteIcon';
import AddIcon from 'lib/images/AddIcon';
import { ConvertedValuesType, IMS_PROVIDERS_VALUES } from '../types';
import { IMS_IDS, options } from '../const';
import {
  checkForEmptyStrings,
  convertIMSResponseToFormikValues,
  getUnusedOptions,
  getUsedOptions,
} from '../helpers';
import { validationSchema } from '../validation';
import {
  useCheckIDIMSConnection,
  useGetSingleIMSConnection,
  useGetUnconnectedIMSConnection,
  useMutateSingleIMSConnection,
} from 'lib/api';
import { debounce } from 'lodash';
import { Option } from 'lib/components/dropdown/DropdownOptions';
import { Button } from 'react-covideo-common';

type FormValuesType = {
  [key: string]: string | number | boolean;
};

const InputField = (props: FieldAttributes<FormValuesType>) => {
  return <Field {...props} />;
};

const RemoveLinkBtnWrapper = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #fef6f5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 1px solid rgba(232, 76, 61, 0.1);
  border-radius: 6px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(238, 239, 242, 1);
  margin: 16px 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(238, 239, 242, 1);
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

const HeaderParagraph = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(146, 151, 162, 1);
  margin: 0;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DropdownLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(146, 151, 162, 1);
`;

const ADD_NEW_INITIAL_VALUES: {
  [x: string]: string | number | string[];
} = {
  vAutoDealerId: [''],
  hideInventoryItemPrice: '0',
  selectedProviders: [IMS_PROVIDERS_VALUES.VAUTO],
};

interface Props {
  handleModalClose: () => void;
  showIMSConnectionModal: {
    modal: boolean;
    customerId: string | number | null;
  };
}

export const ModalAddEditIMSConnection = ({
  handleModalClose,
  showIMSConnectionModal,
}: Props) => {
  const [search, setSearchValue] = useState('');
  const [checkID, setCheckID] = useState<null | Record<string, string>>(null);
  const customerId = showIMSConnectionModal.customerId;
  const {
    data: unconnectedData,
    isLoading: isLoadingUnconnected,
    refetch,
  } = useGetUnconnectedIMSConnection(search);
  const { data: connection, isLoading } = useGetSingleIMSConnection(customerId);
  const { mutate, isLoading: isSaving } =
    useMutateSingleIMSConnection(handleModalClose);
  const { refetch: checkIMSConnection, isFetching: isChecking } =
    useCheckIDIMSConnection(checkID);

  const onhandleSubmitHandler = (values: {
    [x: string]: string | number | string[];
  }) => {
    const { selectedProviders: _, ...formikValues } = values;
    const convertValuesToIMSDataType = Object.entries(formikValues).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          acc[key] = value.join('|'); // Convert array to pipe-separated string
        } else if (Array.isArray(value) && value.length === 0) {
          acc[key] = null;
        } else {
          acc[key] = value as string | number;
        }
        return acc;
      },
      {} as { [key: string]: string | number | null }
    );

    mutate(convertValuesToIMSDataType);
  };
  const debouncedSearch = useCallback(
    debounce(async searchValue => {
      if (searchValue.length >= 3) {
        refetch();
      }
    }, 300),
    []
  );

  const handleInputChange = (newValue: string) => {
    setSearchValue(newValue);
    debouncedSearch(newValue);
  };

  const unconnectedOptions = useMemo(
    () =>
      (unconnectedData?.customers || []).map(item => ({
        value: item.customerId,
        label: `${item?.business || ''} - ${item.customerId}`,
      })),
    [unconnectedData]
  );

  useEffect(() => {
    if (!!checkID) {
      checkIMSConnection();
    }
  }, [checkID]);

  if (isLoading || isLoadingUnconnected) {
    return <></>;
  }

  const mappedData = Object.values(IMS_PROVIDERS_VALUES).reduce(
    (acc, key) => {
      acc[key] = connection?.[key] || null;
      return acc;
    },
    {} as { [key: string]: string | null }
  );

  const filteredConnection = Object.entries(mappedData).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'string') {
        acc[key] = value;
      }
      return acc;
    },
    {} as { [key: string]: string }
  );

  const headerText = !!customerId ? 'Edit Connection' : 'Add Connection';
  const formikDataValues = convertIMSResponseToFormikValues(
    IMS_IDS,
    filteredConnection
  );

  const INIT: ConvertedValuesType = !!customerId
    ? {
        customerId,
        hideInventoryItemPrice: `${connection?.hideInventoryItemPrice}` || '0',
        ...formikDataValues,
        selectedProviders: getUsedOptions(formikDataValues).map(
          item => item.value
        ),
      }
    : ADD_NEW_INITIAL_VALUES;

  const customerDropdownOptions = !!customerId
    ? [
        {
          value: customerId,
          label: `${connection?.business || ''} - ${customerId}`,
        },
      ]
    : unconnectedOptions;

  const customerDropdownValue = (customerId: number) =>
    customerDropdownOptions?.find(
      (item: Option) => item.value === customerId
    ) || [];

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={headerText}
      style={{
        content: {
          width: '600px',
          maxHeight: '700px',
        },
      }}
    >
      <Formik
        initialValues={INIT}
        onSubmit={onhandleSubmitHandler}
        validationSchema={validationSchema}
        //@ts-ignore
        initialErrors={
          customerId
            ? {}
            : {
                customerId: 'Customer ID is required',
                vAutoDealerId: ['vAutoDealerId cannot have empty values'],
              }
        }
      >
        {({ values, handleSubmit, setFieldValue, errors }) => {
          const unusedOptions = getUnusedOptions(values);
          const hasEmptyStrings = checkForEmptyStrings(values);
          return (
            <Form onSubmit={handleSubmit}>
              <DropdownContainer>
                <DropdownLabel>Customer Name</DropdownLabel>
                <Dropdown
                  creatable={false}
                  onChange={item => {
                    setFieldValue(`customerId`, item.value);
                    setFieldValue(`selectedProviders`, [
                      IMS_PROVIDERS_VALUES.VAUTO,
                    ]);
                    IMS_IDS.forEach(item => {
                      if (item === IMS_PROVIDERS_VALUES.VAUTO) {
                        return setFieldValue(IMS_PROVIDERS_VALUES.VAUTO, ['']);
                      }
                      setFieldValue(item, []);
                    });
                  }}
                  onInputChange={handleInputChange}
                  options={customerDropdownOptions}
                  value={customerDropdownValue(values.customerId as number)}
                  extendStyles={{ container: { marginBottom: 32 } }}
                  placeholder='Select customer ...'
                  disabled={!!customerId || isSaving}
                />
              </DropdownContainer>
              <HeaderWrapper>
                <HeaderParagraph style={{ width: 196 }}>
                  Inventory Provider
                </HeaderParagraph>
                <HeaderParagraph>Inventory ID</HeaderParagraph>
              </HeaderWrapper>

              <div
                id='scrollable_div'
                style={{ overflowY: 'auto', maxHeight: 350 }}
              >
                {(values.selectedProviders as string[]).map(
                  (provider: string) => {
                    if (Array.isArray(values[provider])) {
                      const array = values[provider] as string[];
                      return (
                        <React.Fragment key={provider}>
                          {array.length > 0 && (
                            <div style={{ marginBottom: 16 }}>
                              <FieldArray name={provider}>
                                {({ remove, push }) => (
                                  <Gap
                                    flexDirection='column'
                                    justifyContent='flex-start'
                                    alignItems='flex-start'
                                  >
                                    <Gap alignItems='flex-start' width='100%'>
                                      <Dropdown
                                        disabled={
                                          isChecking ||
                                          isSaving ||
                                          !values.customerId
                                        }
                                        creatable={false}
                                        onChange={item => {
                                          // delete old one
                                          setFieldValue(provider, []);
                                          const newSelected = (
                                            values.selectedProviders as string[]
                                          )?.filter(
                                            (item: string) => item !== provider
                                          );
                                          setFieldValue('selectedProviders', [
                                            ...newSelected,
                                            item.value,
                                          ]);
                                          // set new field value
                                          setFieldValue(`${item.value}`, ['']);
                                        }}
                                        options={unusedOptions}
                                        value={options.find(
                                          value => value.value === provider
                                        )}
                                        placeholder='All content types'
                                        className='dropdown'
                                        width={180}
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                        menuPosition='absolute'
                                        menuPlacement='bottom'
                                        closeMenuOnScroll={(event: Event) => {
                                          const targetElement =
                                            event.target as HTMLElement;
                                          return (
                                            targetElement?.id?.includes(
                                              'scrollable_div'
                                            ) || false
                                          );
                                        }}
                                      />
                                      <Gap
                                        flexWrap='noWrap'
                                        flexDirection='column'
                                        style={{ flex: 1 }}
                                        alignItems='flex-start'
                                      >
                                        {array.map(
                                          (itemId: string, index: number) => (
                                            <React.Fragment key={index}>
                                              <Gap
                                                flexDirection='row'
                                                flexWrap='noWrap'
                                              >
                                                <InputField
                                                  disabled={
                                                    isChecking || isSaving
                                                  }
                                                  width='183px'
                                                  placeholder=''
                                                  name={`${provider}[${index}]`}
                                                  as={TextInput}
                                                  type='text'
                                                />

                                                <Button
                                                  variant='white'
                                                  icon={
                                                    <AddIcon
                                                      color={
                                                        theme.palette
                                                          .covideoBlue100
                                                      }
                                                      height={21}
                                                      width={21}
                                                    />
                                                  }
                                                  text={'Check ID'}
                                                  disabled={
                                                    !!errors[provider] ||
                                                    isChecking ||
                                                    isSaving
                                                  }
                                                  onClick={() => {
                                                    const currentProvider =
                                                      options.find(
                                                        item =>
                                                          item.value ===
                                                          provider
                                                      );
                                                    setCheckID({
                                                      dealerId: itemId,
                                                      imsProvider:
                                                        currentProvider!.label,
                                                    });
                                                  }}
                                                />
                                                <RemoveLinkBtnWrapper
                                                  onClick={() => {
                                                    remove(index);
                                                    if (
                                                      (
                                                        values[
                                                          provider
                                                        ] as string[]
                                                      ).length === 1
                                                    ) {
                                                      const filteredProviders =
                                                        (
                                                          values.selectedProviders as string[]
                                                        ).filter(
                                                          item =>
                                                            item !== provider
                                                        );
                                                      setFieldValue(
                                                        'selectedProviders',
                                                        filteredProviders
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    (unusedOptions.length ===
                                                      IMS_IDS.length - 1 &&
                                                      array.length === 1) ||
                                                    isChecking ||
                                                    isSaving
                                                  }
                                                >
                                                  <DeleteIcon
                                                    color={
                                                      theme.palette.buttonDelete
                                                    }
                                                  />
                                                </RemoveLinkBtnWrapper>
                                              </Gap>
                                            </React.Fragment>
                                          )
                                        )}
                                      </Gap>
                                    </Gap>

                                    <Button
                                      style={{
                                        marginLeft: '196px',
                                      }}
                                      variant='secondary'
                                      icon={
                                        <AddIcon
                                          color={theme.palette.covideoBlue100}
                                          height={21}
                                          width={21}
                                        />
                                      }
                                      text={'Add ID'}
                                      disabled={!!errors[provider] || isSaving}
                                      onClick={() => push('')}
                                    />
                                  </Gap>
                                )}
                              </FieldArray>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }
                    return null;
                  }
                )}
              </div>
              <Divider />
              <Gap flexDirection='column' alignItems='flex-start'>
                <Button
                  variant='secondary'
                  icon={
                    <AddIcon
                      color={theme.palette.covideoBlue100}
                      height={21}
                      width={21}
                    />
                  }
                  disabled={!unusedOptions.length || isSaving}
                  text={'Add Provider'}
                  onClick={() => {
                    const item = unusedOptions[0];
                    const selectedProvider = unusedOptions[0]?.value;
                    setFieldValue('selectedProviders', [
                      ...(values.selectedProviders as string[]),
                      selectedProvider,
                    ]);
                    setFieldValue(`${item.value}`, ['']);
                  }}
                />
                <Gap
                  alignItems='center'
                  justifyContent='space-between'
                  width='100%'
                >
                  <Gap alignItems='center' gap='12px'>
                    <CheckboxInput
                      disabled={isChecking || isSaving}
                      name='hideInventoryItemPrice'
                      id='hideInventoryItemPrice'
                      checked={values.hideInventoryItemPrice === '0'}
                      onChange={() => {
                        setFieldValue(
                          'hideInventoryItemPrice',
                          values.hideInventoryItemPrice === '0' ? '1' : '0'
                        );
                      }}
                    />
                    <CheckboxLabel htmlFor='hideInventoryItemPrice'>
                      Show Prices
                    </CheckboxLabel>
                  </Gap>
                  <Button
                    onClick={e => {
                      // @ts-ignore
                      handleSubmit(e);
                    }}
                    text={
                      !!customerId
                        ? isSaving
                          ? 'Saving...'
                          : 'Save Changes'
                        : 'Add Connection'
                    }
                    disabled={
                      hasEmptyStrings ||
                      !!errors.customerId ||
                      isSaving ||
                      isChecking
                    }
                  />
                </Gap>
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
