import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import ShowLessIcon from 'lib/images/ShowLessIcon';
import ShowMoreIcon from 'lib/images/ShowMoreIcon';
import { theme } from 'lib/style';
import { removeHTMLTags } from 'lib/utils/functions';
import { isUndefined } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { Tooltip } from 'react-covideo-common';
import styled, { useTheme } from 'styled-components/macro';

const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  padding: 24px;
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Expander = styled.div`
  cursor: pointer;
`;
interface SwitchInputWrapperProps {
  disabled?: boolean;
}
const SwitchInputWrapper = styled.div<SwitchInputWrapperProps>`
  display: flex;
  align-items: center;
  > div {
    position: relative;
  }
  input {
    position: absolute;
  }
  ${props =>
    props.disabled &&
    `
    >div{
      opacity:.4;
      pointer-events:none;
    }
  `}
`;
const CardTitle = styled.div`
  margin-left: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: ${theme.palette.covideoBlue100};
`;
const CardText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  line-height: 1;
  margin: 0 0 0 12px;
  padding: 0;
  color: ${theme.palette.gray80};
`;
const SummaryList = styled.div`
  margin-top: 24px;
`;
const SummaryItem = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
const SummaryLabel = styled.div`
  min-width: 64px;
  margin-right: 8px;
  color: ${theme.palette.gray80};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
const SummaryContent = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BadgeWrapper = styled.span`
  margin: 0 0 0 12px;
`;
interface ExpandedContentWrapperProps {
  disabled?: boolean;
}
const ExpandedContentWrapper = styled.div<ExpandedContentWrapperProps>`
  margin-top: 24px;
  ${props =>
    props.disabled &&
    `
    opacity:.2;
    pointer-events:none;
  `}
`;

interface SwitchContainerProps {
  includeTooltip?: boolean;
}
const SwitchContainer = styled.div<SwitchContainerProps>`
  display: flex;
  align-items: center;
  ${props =>
    props.includeTooltip &&
    `pointer-events: auto !important;
  &:hover {
    cursor: pointer;
    opacity: 1;
    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
      line-height: 1.5;
    }
  }`}
`;

const TooltipPopup = styled.div`
  max-width: 300px;
  font-size: 13px;
  padding: 10px;

  line-height: 1.5;
`;

type Summary = {
  subject?: string;
  body?: string;
};
type Props = {
  switchId: string;
  switchIsOn: boolean;
  switchDisabled: boolean;
  handleToggle: () => void;
  title: string;
  text: string;
  summary?: Summary | null;
  expandedContent: ReactNode;
  expandedDefault?: boolean;
  badge?: ReactNode;
  includeTooltip?: boolean;
};
export const CardWithSwitch = ({
  switchId,
  switchIsOn,
  handleToggle,
  title,
  text,
  summary = null,
  switchDisabled,
  expandedContent,
  expandedDefault = false,
  badge = null,
  includeTooltip = false,
}: Props) => {
  const [expanded, setExpanded] = useState(expandedDefault);
  const themes = useTheme();
  return (
    <Card>
      <CardHeader>
        <SwitchInputWrapper disabled={switchDisabled}>
          <SwitchContainer includeTooltip={includeTooltip}>
            {includeTooltip && switchDisabled ? (
              <Tooltip
                popup={
                  <TooltipPopup>
                    Begin typing your message to automatically enable the SMS
                    toggle. Once you start, the option to send as SMS will
                    become available.
                  </TooltipPopup>
                }
              >
                <Switch
                  id={switchId}
                  isOn={switchIsOn}
                  onColor={themes.colors.primary[100]}
                  handleToggle={handleToggle}
                />
              </Tooltip>
            ) : (
              <Switch
                id={switchId}
                isOn={switchIsOn}
                onColor={themes.colors.primary[100]}
                handleToggle={handleToggle}
              />
            )}
          </SwitchContainer>
          <CardTitle>{title}</CardTitle>
          {badge && <BadgeWrapper>{badge}</BadgeWrapper>}
          {switchDisabled && <CardText>{text}</CardText>}
        </SwitchInputWrapper>
        <Expander onClick={() => setExpanded(prevExpanded => !prevExpanded)}>
          {expanded && <ShowLessIcon color={theme.palette.covideoBlue40} />}
          {!expanded && <ShowMoreIcon color={theme.palette.covideoBlue40} />}
        </Expander>
      </CardHeader>
      {switchIsOn && !expanded && summary && (
        <SummaryList>
          {!isUndefined(summary.subject) && (
            <SummaryItem>
              <SummaryLabel>Subject:</SummaryLabel>
              <SummaryContent>{summary.subject || ''}</SummaryContent>
            </SummaryItem>
          )}
          {!isUndefined(summary.body) && (
            <SummaryItem>
              <SummaryLabel>Body:</SummaryLabel>
              <SummaryContent>
                {removeHTMLTags(summary.body) || ''}
              </SummaryContent>
            </SummaryItem>
          )}
        </SummaryList>
      )}
      {expanded && (
        <ExpandedContentWrapper disabled={false}>
          {expandedContent}
        </ExpandedContentWrapper>
      )}
    </Card>
  );
};
