import React from 'react';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import styled, { useTheme } from 'styled-components/macro';
import { SharingTabContent } from './SharingTabContent';
import { useAuth } from 'lib/context';
import AccountsIcon from 'lib/images/AccountsIcon';
import { IoLinkOutline } from 'react-icons/io5';
import { FilePrivacySection } from './FilePrivacySection';
import { AiFillInfoCircle } from 'react-icons/ai';
import { generatePassword } from 'lib/utils/functions';
import { SendNotificationParams, saveNotification } from 'lib/api';
import { successToast } from 'lib/components/toasts/success';
import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_IN_APP,
} from '../../notifications/const';
import { DropItem, UpdateDropBody } from 'lib/api/droplr/types';
import { DEFAULT_FILE_THUMBNAIL, DROP_PRIVACY } from 'lib/const/Droplr';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useSendShareNotificationEmail } from 'lib/api/droplr/useSendShareNotificationEmail';
import { Button } from 'react-covideo-common';

interface TabStyleProperties {
  width?: string;
  beforeIconImage?: any;
}

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px 0;
  margin-top: 5px;
  gap: 8px;
`;

const TabContainer = styled.div<any>`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${props => props.color};
    border-bottom: 5px solid ${props => props.color};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

const Tab = styled.div<TabStyleProperties>`
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  padding: 5px 15px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.palette.coal};
  align-items: center;
  width: ${props => (props.width ? props.width : 'fit-content')};
  &::before {
    padding: 5px 10px 5px 0px;
    content: url('${props =>
      props.beforeIconImage ? props.beforeIconImage : ''}');
    line-height: 0;
  }
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9297a2;
  margin-top: 10px;
`;

const BlueBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: 24px;
  padding: 20px 16px;
  border-radius: 5px;
  background-color: rgba(182, 224, 236, 0.2);
`;

const Disclaimer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DisclaimerIconWrap = styled.section`
  margin-right: 14px;
  height: 100%;
`;

const DisclaimerText = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(39, 42, 50, 0.8);
  margin: 0;
  padding: 0;
`;

type Props = {
  drop: DropItem;
  handleModalClose: () => void;
};

const FileSharingModal = ({ drop, handleModalClose }: Props) => {
  const dropId = drop.code;
  const { mutateAsync: editDrop } = useEditDrop();
  const { mutateAsync: sendShareNotificationEmail } =
    useSendShareNotificationEmail();

  const [password, setPassword] = React.useState(
    drop && drop.password ? drop.password : generatePassword(16)
  );
  const [dropPrivacy, setDropPrivacy] = React.useState(
    drop && drop.privacy ? drop.privacy : DROP_PRIVACY.PUBLIC
  );
  const droplrAccessList = drop ? drop.groupsAccessList : [];
  const [disableButton, setDisableButton] = React.useState(false);
  const [accessList, setAccessList] = React.useState<Array<any>>(
    droplrAccessList || []
  );
  const [covideoList, setCovideoList] = React.useState<any>({});
  const [currentTab, setCurrentTab] = React.useState<'withUsers' | 'viaLink'>(
    'withUsers'
  );
  const { userData } = useAuth();
  const themes = useTheme();

  const [showShareWithMessageModal, setShowShareWithMessageModal] =
    React.useState(false);

  React.useEffect(() => {
    if (drop) {
      if (drop.privacy) {
        setDropPrivacy(drop.privacy);
      }
      // password is set only if file is private
      if (
        drop.password &&
        drop.password !== password &&
        drop.privacy === DROP_PRIVACY.PRIVATE
      ) {
        setPassword(drop.password);
      }
    }
  }, []);

  React.useEffect(() => {
    const privacyData: Partial<UpdateDropBody> = {
      privacy: dropPrivacy,
    };

    if (dropPrivacy === DROP_PRIVACY.PRIVATE) {
      if (!password || !isPasswordValid) {
        return;
      }
      privacyData.password = password;
    }
    editDrop({
      ...privacyData,
      dropId,
    });
  }, [password, dropPrivacy]);

  const isPasswordValid = /^[a-z\d]{4,32}$/i.test(password);

  const handleSubmit = async () => {
    setDisableButton(true);
    if (accessList.length) formatAndStoreNotification();
    await editDrop({
      dropId,
      groupsAccessList: accessList,
    });
    const userWithSuffix = `${accessList.length > 1 ? 'users' : 'user'}`;
    const toastMessage = `File shared with ${accessList.length} ${userWithSuffix}`;
    successToast({ title: toastMessage || 'Success' });
    setDisableButton(false);
    handleModalClose();
  };

  const getRecipientIds = (userListHashMap: any) => {
    let finalUserIds = [];
    for (let i = 0; i < accessList.length; i++) {
      if (
        accessList[i] &&
        accessList[i].id &&
        userListHashMap[accessList[i].id]
      ) {
        finalUserIds.push(
          JSON.stringify(userListHashMap[accessList[i].id].covideoUserId)
        );
      }
    }
    return finalUserIds;
  };
  const formatAndStoreNotification = () => {
    let dropTitle = drop.title;
    let dropUrl = drop.shortlink || '';
    let dropId = drop.code;
    let dropType = 'FILES';
    let recipientIds = getRecipientIds(covideoList);
    const notificationData = {
      entityName: dropTitle,
      entityUrl: dropUrl,
      recipientIds: recipientIds,
      type: dropType.toLowerCase(),
      entityId: dropId,
      senderId: parseInt(userData.userId, 10),
      senderName: `${userData.firstName} ${userData.lastName}`,
      notificationProp: [NOTIFICATION_EMAILS.FILES, NOTIFICATION_IN_APP.FILES],
      sendEmail: () => {},
    };
    storeNotification(notificationData);
  };

  const handleShareWithMessage = () => {
    setShowShareWithMessageModal(true);
  };

  const shareAndSendEmail = async (emailData: any, toastMessage: string) => {
    await editDrop({
      dropId,
      groupsAccessList: accessList,
    });

    if (!emailData.recipientEmails || !emailData.recipientEmails.length) {
      successToast({ title: toastMessage || 'Success' });
      handleModalClose();
      return;
    }
    // add notification prop to check n.preferences
    emailData.notificationProp = 'filesEmail';

    const response = await sendShareNotificationEmail(emailData);
    if (response && response.success) {
      successToast({ title: toastMessage });
    }
    handleModalClose();
  };

  const storeNotification = (notificationData: SendNotificationParams) => {
    if (!notificationData.notificationProp) {
      notificationData.notificationProp = [
        NOTIFICATION_EMAILS.FILES,
        NOTIFICATION_IN_APP.FILES,
      ];
    }
    saveNotification(notificationData);
    return;
  };

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText='Share File'
      style={{
        content: {
          minWidth: '528px',
        },
      }}
    >
      <div>
        <BlueBox>
          <DisclaimerIconWrap>
            <AiFillInfoCircle size={24} color={'rgba(0, 27, 83, 0.4)'} />
          </DisclaimerIconWrap>
          <Disclaimer>
            <DisclaimerText>
              Once you share a file with users they’ll have access to it from
              their Covideo Library. On the other hand, if you share via link
              the user can only access the file via link.
            </DisclaimerText>
          </Disclaimer>
        </BlueBox>
        <TabContainer color={themes.colors.primary[100]}>
          <Tab
            className={currentTab === 'withUsers' ? 'activeTab' : ''}
            onClick={() => {
              setCurrentTab('withUsers');
            }}
            title={'withUsers'}
            color={themes.colors.primary[100]}
          >
            <AccountsIcon />
            &nbsp;With Users
          </Tab>
          <Tab
            className={currentTab === 'viaLink' ? 'activeTab' : ''}
            onClick={() => {
              setCurrentTab('viaLink');
            }}
            title={'viaLink'}
            color={themes.colors.primary[100]}
          >
            <IoLinkOutline />
            &nbsp;Via Link
          </Tab>
        </TabContainer>
        {currentTab === 'withUsers' && (
          <>
            <SharingTabContent
              covideoList={covideoList}
              setCovideoList={setCovideoList}
              accessList={accessList}
              setAccessList={setAccessList}
              itemType='drop'
              setDisableButton={setDisableButton}
              setShowShareWithMessageModal={setShowShareWithMessageModal}
              showShareWithMessageModal={showShareWithMessageModal}
              thumbnailUrl={drop.previewMedium || DEFAULT_FILE_THUMBNAIL}
              sendEmail={shareAndSendEmail}
              storeNotification={storeNotification}
              drop={drop}
            />
            <Footer>
              <Button
                variant='secondary'
                text={'Share with Message'}
                type='button'
                disabled={disableButton}
                onClick={() => {
                  handleShareWithMessage();
                }}
              />
              <Button
                text={'Share'}
                type='button'
                disabled={disableButton}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </Footer>
          </>
        )}

        {currentTab === 'viaLink' && (
          <>
            <Label>File Privacy</Label>
            <FilePrivacySection
              drop={drop}
              password={password}
              setPassword={setPassword}
              dropPrivacy={dropPrivacy}
              setDropPrivacy={setDropPrivacy}
              showFileTitle={false}
              displayCopyUrl={true}
            />

            <Footer />
          </>
        )}
      </div>
    </NewModal>
  );
};

export default FileSharingModal;
