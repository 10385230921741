import styled from 'styled-components/macro';
import { ReactComponent as ReverseLeftRightSvg } from './reverse-left-right.svg';

const ReverseLeftRightIcon = styled(ReverseLeftRightSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default ReverseLeftRightIcon;
