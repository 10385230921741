import { theme } from 'lib/style';
import styled from 'styled-components/macro';

interface TabStyleProperties {
  width?: string;
  beforeIconImage?: string;
  active: boolean;
  activeColor: string;
}

export const Content = styled.div`
  min-width: 528px;
  width: auto;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
`;

export const InputGroup = styled.div<{ width: string; mtop?: string }>`
  width: ${props => (props.width ? props.width : '100%')};
  margin-top: ${props => (props.mtop ? props.mtop : '')};
`;

export const Label = styled.label`
  display: flex;
  height: 20px;
  ${theme.fontNormal500}
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-bottom: 8px;
  font-size: 15px;
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

export const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

export const ButtonSwitchWrapper = styled.div`
  width: 65%;
  margin-top: 24px;
  .alignSwitchButton {
    > div {
      width: 50%;
    }
    & button {
      width: 100%;
      > span {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
      }
    }
    & span {
      width: 50%;
    }
  }
  & > div {
    margin-left: 0px;
  }
`;

export const PasswordWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const ToggleLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
`;

export const InfoMessage = styled.div`
  margin-top: 8px;
  width: 60%;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  gap: 8px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

export const Tab = styled.div<TabStyleProperties>`
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  padding: 5px 15px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.palette.coal};
  border-bottom: 5px solid
    ${({ active, activeColor }) => (active ? activeColor : 'transparent')};
  align-items: center;
  width: ${props => (props.width ? props.width : 'fit-content')};
  &::before {
    padding: 5px 10px 5px 0px;
    content: url(${props =>
      props.beforeIconImage ? props.beforeIconImage : ''});
    line-height: 0;
  }
`;
