import {
  SidebarItem,
  SidebarNavigation,
} from 'lib/components/SidebarNavigation';
import { AutomotiveRole } from 'lib/const';
import { useAuth } from 'lib/context';
import DocumentIcon from 'lib/images/DocumentIcon';
import LiveVideoIcon from 'lib/images/LiveVideoIcon';
import LockIcon from 'lib/images/LockIcon';
import PlayerIcon from 'lib/images/PlayerIcon';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import * as React from 'react';
import { MdLibraryAdd, MdPlaylistAddCheck } from 'react-icons/md';
import { IoMdCar } from 'react-icons/io';
import { matchPath } from 'react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Meetings } from '../meetings';
import { NotFoundTemplate } from '../notFound';
import { ReviewUploadedFiles } from '../video/videoDetails/main/ReviewUploadedFiles';
import { Boards } from './boards';
import { Codirect } from './codirect';
import { Files } from './files';
import { VDP } from './vdp/VDP';
import { VideoList } from './videos/videoList';
import { VideoRequests } from './videos/videoRequests';
import GuideIcon from 'lib/images/Guide';
import { Guides } from '../guides/Guides';
import { ReviewUploadedGuides } from '../guides/main/pages/ReviewUploadedGuides';
import { useGetNewGuidesCount } from 'lib/api/guides/newGuidesCountQuery';
import { useGuideCategoryQuery } from 'lib/api/guides/guideCategoryQuery';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { useCountPendingVideoRequestsQuery } from 'lib/api/videoRequests/useCountPendingVideoRequestQuery';

export const LibraryRoutes = () => {
  const { userData, whitelabel } = useAuth();
  const {
    droplrAccess,
    access,
    automotiveRole,
    isAutomotiveServiceRole,
    isAutomotiveSalesRole,
  } = userData;
  const { data: latestGuidesCount } = useGetNewGuidesCount();
  const { data: guideCategories } = useGuideCategoryQuery(0, 10, false);

  const showVideoRequests =
    isAutomotiveServiceRole && automotiveRole !== AutomotiveRole.TECHNICIAN;
  const showVdps = userData.isVdpAllowed;
  const showLiveVideo = !!Number(userData.customer.liveAccess);
  const showGuides = whitelabel?.name === WHITELABEL_NAME.COVIDEO;
  const { data: pendingVideoRequestsData } =
    useCountPendingVideoRequestsQuery(showVideoRequests);
  const countPending = pendingVideoRequestsData?.count || 0;

  let automotive = '';
  if (isAutomotiveServiceRole) {
    automotive = 'service';
  }

  if (isAutomotiveSalesRole) {
    automotive = 'sales';
  }

  React.useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {
        automotive,
      });
    }
  }, [automotive]);

  const codirectId = parseInt(userData?.customer?.codirectId?.toString(), 10);
  const showCodirect = !isNaN(codirectId) && codirectId > 0;
  const location = useLocation();

  const iconSize = '24px';
  const addonPath =
    access === '3' || access === '4'
      ? '/profile/add-ons/files/manage'
      : '/profile/add-ons';

  const isFilesAndBoardsEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.FILES_AND_BOARDS
  );
  const areLiveVideosEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.LIVE_VIDEO
  );

  const items = [
    {
      isDisabled: false,
      title: 'Videos',
      icon: <PlayerIcon width={iconSize} height={iconSize} />,
      additionalIcon: null,
      path: `/home`,
    },
    {
      isDisabled: !isFilesAndBoardsEnabled,
      title: 'Files',
      icon: <DocumentIcon width={iconSize} height={iconSize} />,
      additionalIcon: <LockIcon width={18} height={18} color={'#001B53'} />,
      path: isFilesAndBoardsEnabled
        ? droplrAccess
          ? `/files`
          : addonPath
        : '/upgrade-plan',
      productFeatureId: productFeature.FILES_AND_BOARDS,
    },
    {
      isDisabled: !isFilesAndBoardsEnabled,
      title: 'Boards',
      icon: <MdLibraryAdd fontSize={24} />,
      additionalIcon: <LockIcon width={18} height={18} color={'#001B53'} />,
      path: isFilesAndBoardsEnabled
        ? droplrAccess
          ? `/boards`
          : addonPath
        : '/upgrade-plan',
      productFeatureId: productFeature.FILES_AND_BOARDS,
    },
  ] as SidebarItem[];

  if (showVdps) {
    items.splice(1, 0, {
      title: 'Pushed to VDP',
      icon: <IoMdCar size={iconSize} />,
      path: `/vdps`,
    });
  }
  if (showVideoRequests) {
    items.splice(1, 0, {
      title: 'Requests',
      icon: <MdPlaylistAddCheck size={iconSize} />,
      path: `/video-requests`,
      notificationCount: countPending,
    });
  }

  if (showLiveVideo) {
    items.push({
      isDisabled: !areLiveVideosEnabled,
      title: 'Meetings',
      icon: <LiveVideoIcon width={iconSize} height={iconSize} />,
      additionalIcon: <LockIcon width={18} height={18} color={'#001B53'} />,
      path: `/meetings`,
      productFeatureId: productFeature.LIVE_VIDEO,
    });
  }

  if (showGuides && guideCategories?.count) {
    items.push({
      isDisabled: false,
      title: 'Guides',
      icon: <GuideIcon width={iconSize} height={iconSize} />,
      additionalIcon: <LockIcon width={18} height={18} color={'#001B53'} />,
      path: `/guides`,
      notificationCount: latestGuidesCount?.count.toString(),
    });
  }

  const isFilesDetails = matchPath(location.pathname, {
    path: '/files/:dropId/:dropType',
    exact: true,
  });

  const isReviewVideos = matchPath(location.pathname, {
    path: '/review-videos',
    exact: true,
  });

  const isReviewGuides = matchPath(location.pathname, {
    path: '/review-guides',
    exact: true,
  });

  // hide sidebar for files details
  const showSidebar = !(isFilesDetails || isReviewVideos || isReviewGuides);

  const domain = window.location.hostname;
  const isCovideoDomain =
    domain.includes('covideo.com') ||
    domain.includes('localhost') ||
    domain.includes('app2vid.com');

  if (!isCovideoDomain) {
    return (
      <>
        <Route path='/home' component={VideoList} />
        <Route path='/review-videos' component={ReviewUploadedFiles} />
        <Route path='/review-guides' component={ReviewUploadedGuides} />
      </>
    );
  }

  return (
    <>
      {showSidebar && (
        <SidebarNavigation
          items={items}
          hasBack={false}
          showCodirect={showCodirect}
        />
      )}
      <Switch>
        <Route path='/home' component={VideoList} />
        <Route path='/meetings' component={Meetings} />
        {showGuides && <Route path='/guides' component={Guides} />}
        <Route path='/files' component={Files} />
        <Route path='/boards' component={Boards} />
        <Route path='/review-videos' component={ReviewUploadedFiles} />
        <Route path='/review-guides' component={ReviewUploadedGuides} />
        <Route path='/video-requests' component={VideoRequests} />
        {/* SUS-1234 changes vdps route will accessible is showVdps is true */}
        {showVdps && <Route path='/vdps' component={VDP} />}
        <Route path='/codirect' component={Codirect} />
        {/* SUS-1234 changes to show 404 page when no route found. */}
        <Route path='*' component={NotFoundTemplate} />
      </Switch>
    </>
  );
};
