import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import LevelDownIcon from 'lib/images/LevelDownIcon';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { AddChildCustomerModal } from '../../modals/AddChildCustomerModal';
import { CustomerItem } from './CustomerItem';
import { MultiItemHeader } from '../Header';
import { CustomersDropdown } from './CustomersDropdown';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';

const ColumnsWrapper = styled.div`
  min-width: 320px;
  background: ${theme.palette.white};
  padding-bottom: 20px;
`;

export const CustomerColumns = ({
  arrayHelpers,
  isEditMode,
}: {
  arrayHelpers: FieldArrayRenderProps;
  isEditMode: boolean;
}) => {
  const { values } = useFormikContext<ParentCustomer>();
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  return (
    <ColumnsWrapper>
      <MultiItemHeader headerName='Customers' additionalInfo='ID' />
      <Gap flexDirection='column' alignItems='flex-start' gap='0'>
        {values.business && values.customerId && (
          <CustomerItem
            isEditMode={isEditMode}
            business={values.business}
            customerId={values.customerId}
          />
        )}
        <>
          {values.childCustomers.map((childCustomer, index) => {
            const onRemove = () => {
              arrayHelpers.remove(index);
            };
            return (
              <CustomerItem
                isEditMode={isEditMode}
                onRemove={onRemove}
                key={childCustomer.customerId}
                business={childCustomer.business}
                customerId={childCustomer.customerId}
              />
            );
          })}
        </>
        <Gap
          flexDirection='row'
          style={{ padding: '0 20px', marginTop: '16px' }}
        >
          <LevelDownIcon />
          {isEditMode ? (
            <Button text='Add Child Customer' onClick={openModalHandler} />
          ) : (
            <CustomersDropdown arrayHelpers={arrayHelpers} />
          )}
        </Gap>
        {showModal && (
          <AddChildCustomerModal closeModalHandler={closeModalHandler} />
        )}
      </Gap>
    </ColumnsWrapper>
  );
};
