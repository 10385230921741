import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Details } from './Details';
import { CustomReports } from '../main-v2/CustomReports';
import { CreateCustomReportModal } from '../createCustomReportModal/CreateCustomReportModal';

export const Main = () => {
  return (
    <Switch>
      <Route path='/reports/custom' component={CustomReports} exact={true} />
      <Route
        path='/reports/custom/add'
        component={CreateCustomReportModal}
        exact={true}
      />
      <Route
        path='/reports/custom/edit/:reportId'
        component={CreateCustomReportModal}
        exact={true}
      />
      <Route
        path='/reports/custom/:reportId'
        component={Details}
        exact={true}
      />
    </Switch>
  );
};
