export const SUPER_ADMIN_CUSTOMER_IDS: { [key: string]: string } = {
  3: 'Covideo',
  1210: 'Covideo CDS',
  54188: 'Covideo Devs',
};

export const ALLOWED_CUSTOMERS = ['54188', '30098', '26460', '29879', '27474'];

export enum SuperAdminRole {
  COVIDEO_SUPER_ADMIN = 1,
  NON_COVIDEO_SUPER_ADMIN = 2,
  COVIDEO_RESELLER_ADMIN = 3,
  RESELLER_ADMIN = 4,
  COVIDEO_ADMIN = 5,
  ADMIN = 6,
  ACCESS = 7,
  NONE = 8,
}

export const ResellerReportRoles = [SuperAdminRole.COVIDEO_SUPER_ADMIN];

export const ManageOrganizationRoles = [SuperAdminRole.COVIDEO_SUPER_ADMIN];

export const ManageIMSRoles = [SuperAdminRole.COVIDEO_SUPER_ADMIN];
export const ManageMLMRoles = [SuperAdminRole.COVIDEO_SUPER_ADMIN];

export const ManageCustomerRoles = [
  SuperAdminRole.COVIDEO_SUPER_ADMIN,
  SuperAdminRole.NON_COVIDEO_SUPER_ADMIN,
  SuperAdminRole.COVIDEO_ADMIN,
  SuperAdminRole.ADMIN,
];

export const ManageUserRoles = [
  SuperAdminRole.COVIDEO_SUPER_ADMIN,
  SuperAdminRole.NON_COVIDEO_SUPER_ADMIN,
  SuperAdminRole.COVIDEO_ADMIN,
  SuperAdminRole.ADMIN,
  SuperAdminRole.ACCESS,
];

export const ManageOptOutReportsRoles = [
  SuperAdminRole.COVIDEO_SUPER_ADMIN,
  SuperAdminRole.NON_COVIDEO_SUPER_ADMIN,
  SuperAdminRole.COVIDEO_ADMIN,
  SuperAdminRole.ADMIN,
];

export const ManageUsageReportRoles = [
  SuperAdminRole.COVIDEO_SUPER_ADMIN,
  SuperAdminRole.COVIDEO_ADMIN,
  SuperAdminRole.NON_COVIDEO_SUPER_ADMIN,
];

export const IntegrationRoles = [
  SuperAdminRole.COVIDEO_SUPER_ADMIN,
  SuperAdminRole.COVIDEO_ADMIN,
  SuperAdminRole.COVIDEO_RESELLER_ADMIN,
];

export const ForbiddenFeaturesForWhitelabels: { [key: string]: boolean } = {
  Integrations: true,
  IMS: true,
  Organizations: true,
  Resellers: true,
  'Manage Assignments': true,
  Graphics: true,
};
