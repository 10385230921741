import * as React from 'react';

export const VolumeOffIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 24 24'
  >
    <defs>
      <path
        id='volumeOff-a'
        d='M3.63 3.635a.996.996 0 0 0 0 1.41l3.66 3.66-.29.3H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71v-4.17l4.18 4.18c-.49.37-1.02.68-1.6.91-.36.15-.58.53-.58.92 0 .72.73 1.18 1.39.91.8-.33 1.55-.77 2.22-1.31l1.34 1.34a.996.996 0 1 0 1.41-1.41L5.05 3.635c-.39-.39-1.02-.39-1.42 0zM19 12.005c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-3.83-2.4-7.11-5.78-8.4-.59-.23-1.22.23-1.22.86v.19c0 .38.25.71.61.85 2.57 1.04 4.39 3.56 4.39 6.5zm-8.71-6.29l-.17.17L12 7.765v-1.35c0-.89-1.08-1.33-1.71-.7zm6.21 6.29a4.5 4.5 0 0 0-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='volumeOff-b' fill='#fff'>
        <use xlinkHref='#volumeOff-a' />
      </mask>
      <g fill='#fff' fillOpacity='1' mask='url(#volumeOff-b)'>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);
