import * as React from 'react';
import styled from 'styled-components/macro';

import { MdImage } from 'react-icons/md';
import { TextInput } from 'lib/components';
import { useLandingPageBuilderContext } from '../../context';
import {
  getSignedURLs,
  GetSignedUrlsParams,
  GetSignedUrlsResponse,
} from 'lib/api';
import { useAuth, ICombinedUserData } from 'lib/context';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { gridSizeOptions } from '../Constants';
import Select from 'react-select';
import { IoIosAdd } from 'react-icons/io';
import { BinIcon } from '../Constants';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';

interface Props {
  width?: string;
  setShowBackButton: any;
}

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  marginTop?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px')};
  flex-direction: row;
  .adjustWidth {
    width: 90%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .alignRight {
    margin: auto 0 auto auto;
    cursor: pointer;
  }
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const FileInputHandler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  width: 80%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  word-break: break-all;
  height: fit-content;
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

const Thumbnail = styled.img`
  max-width: 80%;
  height: auto;
  border-radius: 5px;
`;

export const EditImageSection = (props: Props) => {
  const [_, setImageSource] = React.useState('');
  const [__, setImageFile] = React.useState<File>();
  const [uploading, setUploading] = React.useState(false);
  const [___, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    elementToEdit,
    setElementToEdit,
    elementSectionKey,
    elementIndex,
  } = useLandingPageBuilderContext();
  const onUploadProgress = handleUploadProgress(setUploadProgress);
  const { userData } = useAuth();

  const generateName = handleNameGeneration(userData);
  const { setShowBackButton } = props;

  const layoutData = { ...selectedLayoutData };
  if (!elementSectionKey || !layoutData) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }

  const isVisible = sectionDetails.isVisible;
  if (!isVisible) {
    return <></>;
  }

  let imageArray = sectionDetails.images || [];
  const sectionGridSize = sectionDetails.gridSize || 4;

  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  const removePhoto = (index: number) => {
    imageArray.splice(index, 1);
    sectionDetails.images = imageArray;
    if (isElementCase) {
      layoutData[elementSectionKey].childSections[elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }

    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };
  const handleSelectedFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setUploading(true);
    const target = e.target as HTMLInputElement;
    if (target && target.files && target.files[0]) {
      let orgFile = target.files[0];
      const isValidType = /.*\.(jpeg|jpg|png|bmp|gif|webp|svg|csv|txt)/i.test(
        orgFile.name
      );
      if (isValidType) {
        let filename = orgFile.name.split('.');
        setImageSource(filename[0]);
        let newFile = new File(
          [orgFile],
          generateName(filename[filename.length - 1]),
          { type: orgFile.type, lastModified: orgFile.lastModified }
        );
        setImageFile(newFile);
        setImageSource(URL.createObjectURL(newFile));
        handleUpload(newFile, index);
      } else {
        console.log('Invalid format.');
        setUploading(false);
      }
    } else {
      setUploading(false);

      console.log('Invalid file.');
    }
  };
  const handleUpload = async (newFile: File, index: number) => {
    setShowBackButton(false);
    const signedUrlData: GetSignedUrlsParams = {
      fileName: newFile.name,
      mimeType: newFile.type,
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      setUploading(false);
      errorToast({ title: 'Server is currently unavailable, try again!' });
      setShowBackButton(true);
      return;
    }
    const data = {
      file: newFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };
    uploadEmailThumbnail({ data, onUploadProgress })
      .then(() => {
        imageArray[index] = {
          name: newFile.name,
          url: signedURLs.downloadUrl,
          altText: imageArray[index]?.altText || '',
        };

        sectionDetails.images = imageArray;
        if (isElementCase && elementIndex !== undefined) {
          layoutData[elementSectionKey]['childSections'][elementIndex] =
            sectionDetails;
        } else {
          layoutData[elementSectionKey] = sectionDetails;
        }
        // setTimeout(() => {
        setElementToEdit(sectionDetails);
        setSelectedLayoutData(layoutData);
        setUploading(false);
        bringUploadedElementIntoView(signedURLs.downloadUrl);
        setShowBackButton(true);

        // }, 0).bind(signedURLs);
      })
      .catch(() => {
        setUploading(false);
        setShowBackButton(true);
      });
  };

  const bringUploadedElementIntoView = (imgUrl: string) => {
    const query = 'img[src="' + imgUrl + '"]';
    const imageElement = document.querySelector(query);
    if (imageElement) {
      imageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  };

  const handleChangeGridSize = (gridSize: number) => {
    sectionDetails.gridSize = gridSize;
    if (isElementCase && elementIndex !== undefined) {
      layoutData[elementSectionKey]['childSections'][elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  const handleAddPhoto = () => {
    imageArray.push({});
    sectionDetails.images = imageArray;
    if (isElementCase && elementIndex !== undefined) {
      layoutData[elementSectionKey]['childSections'][elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }

    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  const handleAltTextChange = (e: any, index: number) => {
    const imageDetails = imageArray[index] ? imageArray[index] : {};
    imageDetails.altText = e.target.value;
    imageArray[index] = imageDetails;
    sectionDetails.images = imageArray;
    if (isElementCase && elementIndex !== undefined) {
      layoutData[elementSectionKey]['childSections'][elementIndex] =
        sectionDetails;
    } else {
      layoutData[elementSectionKey] = sectionDetails;
    }
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(layoutData);
  };

  const isSingle = sectionDetails?.isSingle;
  const isDisabled =
    imageArray.length === 0 ? false : isSingle === undefined ? false : isSingle;

  return (
    <>
      {imageArray.map((imageDetails: any, index: number) => {
        let fileRef = React.createRef<HTMLInputElement>();
        return (
          <>
            <Row marginTop={'20px'} key={index}>
              <input
                type={'file'}
                ref={fileRef}
                style={{ display: 'none' }}
                name={'filePicker4'}
                id={'PS-' + index}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelectedFile(e, index);
                }}
                accept={
                  'image/png, image/jpeg, image/bmp, image/gif, image/webp, image/svg+xml,.csv, text/plain'
                }
              />

              {(!imageDetails ||
                !imageDetails.url ||
                imageDetails.url == '') && (
                <>
                  <FileInputHandler
                    onClick={() => fileRef && fileRef.current?.click()}
                  >
                    {imageDetails && imageDetails.name
                      ? imageDetails.name
                      : 'Upload image...'}
                    <MdImage size={24} color={'#9297A2'} />
                  </FileInputHandler>
                </>
              )}

              {imageDetails && imageDetails.url && (
                <>
                  <Thumbnail src={imageDetails.url} />
                </>
              )}

              <BinIcon
                onClick={() => {
                  removePhoto(index);
                }}
                className={'alignRight'}
              />
            </Row>
            <Row>
              <TextInput
                id={'altText' + index}
                margin={'2px 0px 0px 0px'}
                type={'text'}
                key={imageDetails?.url + index}
                placeholder={'Alt Text'}
                value={imageDetails.altText}
                width={'80%'}
                onChange={e => {
                  handleAltTextChange(e, index);
                }}
              />
            </Row>
          </>
        );
      })}

      <Row>
        <Button
          variant='secondary'
          icon={<IoIosAdd />}
          text={'Add photo'}
          onClick={handleAddPhoto}
          disabled={uploading || isDisabled}
        />
      </Row>
      {!isSingle && (
        <Row padding={'10px 10px 350px 10px'}>
          <TopLabel>Grid size</TopLabel>
          <SelectInput
            styles={{
              control: (base: any) => ({ ...base, height: '40px' }),
              indicatorSeparator: () => ({ display: 'none' }),
            }}
            options={gridSizeOptions}
            value={gridSizeOptions.find(o => o.value == sectionGridSize)}
            onChange={(option: any) => {
              handleChangeGridSize(option.value);
            }}
          />
        </Row>
      )}
    </>
  );
};

function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
