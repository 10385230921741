import React from 'react';
import DeleteIcon from 'lib/images/DeleteIcon';
import EditIcon from 'lib/images/EditIcon';
import { Customer } from 'lib/context';
import { theme } from 'lib/style';

interface IProps {
  editAction: (customerId: string | number | null) => void;
  deleteAction: (connection: Customer) => void;
  connection: Customer;
}

export const threeDotsActions = ({
  deleteAction,
  editAction,
  connection,
}: IProps) => {
  return [
    {
      id: 'edit',
      text: 'Edit',
      onClick: () => editAction(connection.customerId),
      icon: <EditIcon width='20px' height='20px' />,
      disabled: false,
    },
    {
      id: 'delete',
      text: 'Delete',
      onClick: () => deleteAction(connection),
      icon: (
        <DeleteIcon width='20px' height='20px' color={theme.palette.red100} />
      ),
      disabled: false,
    },
  ];
};
