import { useGetLocationsQuery } from 'lib/api/locations/getUserLocations';
import { HorizontalNavigation, Logo, ModalUpload } from 'lib/components';
import { UploadAndRecordButtons } from 'lib/components/buttons';
import { AccessRole, AutomotiveRole } from 'lib/const';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import {
  ALLOWED_CUSTOMERS,
  ForbiddenFeaturesForWhitelabels,
  IntegrationRoles,
} from 'lib/const/SuperAdminRole';
import { useAuth } from 'lib/context';
import { MenuBarsIcon } from 'lib/images/MenuBarsIcon';
import { UserIcon } from 'lib/images/UserIcon';
import { theme } from 'lib/style';
import {
  adminUserItems,
  horizontalItemsAll,
  horizontalItemsAllAssignment,
  horizontalItemsAutomotive,
  horizontalItemsSupervisor,
  horizontalItemsUser,
  userItems,
} from 'lib/utils';
import {
  NavigationItem,
  ProfileNavigationItem,
  horizontalItemsAutomotiveSales,
  horizontalItemsCovideoDevAdminCustomer,
} from 'lib/utils/NavHorizontalItems';
import {
  AFFILIATE,
  BILLING,
  EMAILS_LABEL,
  EMAIL_BATCHES,
  HELP_CENTER,
  LOGOUT,
  MANAGE_USERS,
  PROFILE,
  SCHEDULES,
  TELEPROMPTER,
  TEMPLATES,
  VIDEO_ATTRIBUTES,
  VIDEO_EXPIRATION,
  automotiveServiceItems,
  supervisorItems,
  templatesItem,
} from 'lib/utils/UserNavItems';
import {
  checkIfScheduleAccessible,
  checkIfTemplatesAccessible,
  checkIfUserIsTechnician,
} from 'lib/utils/automotiveRolePermissionChecks';
import {
  checkIfConversationsAvailable,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import * as React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link, useHistory, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import ModalLoginAs from '../modal/ModalSuperAdminLoginAs';
import { MenuDropdown } from './MenuDropdown';
import { checkIfTrialUser } from 'lib/utils/userRoles';
import { useNavigationColors } from 'lib/hooks/useNavigationColor';
import automotiveLogo from 'lib/images/covideodealerservices_logo.png';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

const ADMIN_CUSTOMER_IDS = ['3', '1210'];
export const CAMPING_WORLD = '138';
const CAMPING_WORLD_IDS = ['27676', '72515', '105568', '113955', '116067'];

type OverlayProps = {
  isSideNavOpen?: boolean;
};

type SidebarContainerProps = {
  backgroundColor: string;
  color: string;
};

type HamburgerMenuProps = {
  isSideNavOpen?: boolean;
};

type HistoryState = {
  from: string;
};

const MobileSection = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  flex-grow: 2;
  ${theme.mediaQueryMinWidth.md} {
    padding: 0;
    display: none;
  }
`;

const DesktopSection = styled.div<any>`
  justify-content: flex-start;
  display: none;
  align-items: center;
  padding: 0 12px;
  transition: all 0.2s ease;
  width: 89.3%;
  ${theme.mediaQueryMinWidth.md} {
    display: flex;
  }
  ${props =>
    !props.showNavigation &&
    css`
      flex: auto;
    `}
`;

const SidebarContainer = styled.div<SidebarContainerProps>`
  position: fixed;
  top: 0;
  z-index: 11; /** SUS-2567 changes **/
  height: 48px;
  width: 100%;
  background-color: ${({ backgroundColor }) => `${backgroundColor};`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.mb} {
    height: 64px;
  }
  transition: all 0.2s ease;
  box-shadow:
    0px 0px 2px rgba(0, 27, 83, 0.08),
    0px 4px 8px rgba(0, 27, 83, 0.03);
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  transition: all 0.5s ease;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0 24px;
  }
  transition: all 0.2s ease;
`;

const Welcome = styled.div`
  display: none;

  ${theme.mediaQueryMinWidth.xxlg} {
    font-size: ${theme.fontSizes.sm};
    display: flex;
    color: ${({ color }) => color};
  }
`;

const Name = styled.div<{
  loggedInLocation: string;
  isAdmin: boolean;
  color: string;
}>`
  display: none;
  color: ${({ color }) => color};
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.06;
  white-space: nowrap;

  ${({ loggedInLocation }) =>
    loggedInLocation &&
    `
    text-align: right;
    font-size: 15px;
  `}

  ${theme.mediaQueryMinWidth.mb} {
    display: inline-block;
    padding-left: 4px;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80px;
  }
  ${theme.mediaQueryMinWidth.md} {
    ${({ isAdmin }) => {
      if (isAdmin) {
        return css`
          display: none;
          padding: 0;
          margin: 0;
        `;
      }
    }}
  }
  ${theme.mediaQueryMinWidth.lg} {
    display: inline-block;
    padding-left: 4px;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
  }
`;

const User = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 64px;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const UserWrap = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  transition: all 0.2s ease;
`;

const Overlay = styled.div<OverlayProps>`
  position: fixed;

  ${props =>
    props.isSideNavOpen &&
    css`
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    `}
`;

const LogoWrap = styled(Link)<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  height: 64px;
  flex-grow: 1;
  img {
    padding: 0;
  }
  ${props =>
    props.isadmin === 'true' &&
    css`
      ${theme.mediaQueryMinWidth.md} {
        img {
          height: 24px;
        }
      }
      ${theme.mediaQueryMinWidth.lg} {
        img {
          height: 30px;
        }
      }
    `}

  transition: all 0.2s ease-in-out;
`;

const Icon = styled.span`
  margin: 0 15px 0 0;
  font-size: ${theme.iconSizeS};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const HamburgerMenu = styled.div<HamburgerMenuProps>`
  color: ${theme.palette.white};
  font-size: ${theme.iconSizeM};
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  transform: ${props =>
    props.isSideNavOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
  &:hover {
    background-color: ${theme.palette.hamburgerMenuHover};
  }
`;

const UploadAndRecordContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  transition: width 0.2s ease;
  ${theme.mediaQueryMinWidth.md} {
    margin-left: auto;
    position: static;
  }
`;

const BackButton = styled.a<{ color?: string }>`
  color: ${props => props.color || 'white'};
  text-decoration: none;
  display: flex;
  align-items: center;
  left: 75px;
  width: 95px;
  justify-content: space-between;
`;

const BackButtonLabel = styled.label`
  font-size: 15px;
  cursor: pointer;
`;

const LocationContainer = styled.div<{ color: string }>`
  font-size: 11px;
  font-weight: 400;
  color: ${({ color }) => color};
  text-align: right;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`;

export const showUploadModal = (
  setIsUploadVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsUploadVisible(true);
};

export const Topbar = withRouter((props: any) => {
  const {
    isSideNavOpen,
    handleSideNav,
    username,
    showNavigation,
    isLocationChanging,
    setIsLocationChanging,
  } = props;
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState('');
  const [isUploadVisible, setIsUploadVisible] = React.useState(false);
  const { userData, whitelabel } = useAuth();
  const leadsIntegrationActive = !!userData.customer?.leadsIntegrationActive;
  const [navitems, setNavItems] = React.useState<Array<ProfileNavigationItem>>(
    []
  );
  const [horizontalNavitems, setHorizontalNavitems] = React.useState<
    Array<NavigationItem>
  >([]);
  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = React.useState(false);

  const { color, backgroundColor } = useNavigationColors();

  const history = useHistory();
  const { isAutomotive, isMultiLocation, id, trialExpired, isResellerAdmin } =
    userData;
  const { data: locations } = useGetLocationsQuery(isMultiLocation, id);

  const isManager =
    userData.automotiveRole ===
    AutomotiveRole.SERVICE_MANAGER; /* SUS-1222 Changes */
  const logoSrc =
    userData.isAutomotive && whitelabel.name === 'Covideo'
      ? automotiveLogo
      : 'https://' +
        whitelabel.domainV2 +
        whitelabel.contentPath +
        '/images/logo-secondary.svg';

  const showIntegrations = IntegrationRoles.includes(userData.superAdminRole);

  React.useEffect(() => {
    let profileItemstemp: ProfileNavigationItem[] = [];
    let navItemstemp: NavigationItem[] = [];
    switch (userData.access) {
      case AccessRole.ADMIN:
        profileItemstemp = adminUserItems;
        navItemstemp =
          userData.customer && userData.userId == '64804'
            ? horizontalItemsAllAssignment
            : userData.customer && userData.userId == '64804'
              ? horizontalItemsAllAssignment.filter(
                  (e: any) => e.path != '/sms/overview'
                )
              : userData.customer
                ? horizontalItemsAll
                : horizontalItemsAll.filter(
                    (e: any) => e.path != '/sms/overview'
                  );
        setIsAdmin(true);
        break;

      case AccessRole.SUPERVISOR:
        profileItemstemp = supervisorItems;
        navItemstemp = horizontalItemsSupervisor;
        break;

      default:
        profileItemstemp = userItems;
        navItemstemp = horizontalItemsUser;
        /* SUS-1222 Changes */
        if (!isManager) {
          navItemstemp = navItemstemp.filter(
            item => item.title != 'Conversations'
          );
          navItemstemp.splice(3, 0, {
            title: 'Conversations',
            path: '/sms/messages',
          });
        }
        break;
    }

    if (whitelabel.supportURL) {
      profileItemstemp.forEach(item => {
        if (item.title === HELP_CENTER.title) {
          item.customLink = whitelabel.supportURL;
        }
      });
    }

    if (userData.admin === '1') {
      let updatedHorizontalInnerMenu =
        horizontalItemsCovideoDevAdminCustomer[0].innerMenu.map(item => {
          if (item.title !== 'Login As') {
            return item;
          }
          item.onItemClick = () => setIsLoginAsModalOpen(true);
          return item;
        });

      horizontalItemsCovideoDevAdminCustomer[0].innerMenu =
        updatedHorizontalInnerMenu;

      navItemstemp = [
        ...navItemstemp,
        ...horizontalItemsCovideoDevAdminCustomer,
      ];
      if (
        !CAMPING_WORLD_IDS.includes(userData.userId.toString()) &&
        !ALLOWED_CUSTOMERS.concat(ADMIN_CUSTOMER_IDS).includes(
          userData.customerId.toString()
        )
      ) {
        navItemstemp
          .find(item => item.title === 'Admin')
          ?.innerMenu?.filter(
            innerMenu => innerMenu.title !== 'Manage Assignments'
          );
      }
    }

    if (
      userData.isAutomotiveServiceRole &&
      userData.automotiveRole !== AutomotiveRole.TECHNICIAN
    ) {
      navItemstemp = [...navItemstemp, ...horizontalItemsAutomotive];
      const i = profileItemstemp.find(i =>
        i.title.includes(EMAILS_LABEL.title)
      );
      const idx = i && profileItemstemp.indexOf(i);
      idx &&
        idx > -1 &&
        profileItemstemp.splice(idx + 1, 0, ...automotiveServiceItems);
    }

    if (userData.isAutomotiveSalesRole) {
      navItemstemp = [...navItemstemp, ...horizontalItemsAutomotiveSales];
    }

    if (checkIfTemplatesAccessible(userData)) {
      const template = profileItemstemp.find(i =>
        i.title.includes('Templates')
      );
      if (!template) {
        const i = profileItemstemp.find(i =>
          i.title.includes(EMAILS_LABEL.title)
        );
        const idx = i && profileItemstemp.indexOf(i);
        idx &&
          idx > -1 &&
          profileItemstemp.splice(idx + 1, 0, ...templatesItem);
      }
    }

    profileItemstemp = profileItemstemp.filter(
      item =>
        (!!whitelabel.supportURL || item.title !== HELP_CENTER.title) &&
        (whitelabel.name === WHITELABEL_NAME.COVIDEO ||
          item.title !== BILLING.title) &&
        (userData.resellerId === '1' || item.title !== AFFILIATE.title)
    );

    if (!userData.isCompanyAdmin) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== VIDEO_EXPIRATION.title
      );
    }

    if (userData?.user?.packageDetails?.id === 1) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== AFFILIATE.title
      );
    }

    if (userData.admin !== '1' || userData.customerId !== '3') {
      const i = profileItemstemp.find(i =>
        i.title.includes(EMAIL_BATCHES.title)
      );
      const idx = i && profileItemstemp.indexOf(i);
      idx && idx > -1 && profileItemstemp.splice(idx, 1);
    }

    if (!checkIfScheduleAccessible(userData)) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== SCHEDULES.title
      );
    }

    const isManageUsersDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.MANAGE_USERS_AS_TEAMS
    );

    const isAttributesDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_ATTRIBUTES
    );
    const isTeleprompterDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.TELEPROMPTER
    );
    const isExpirationDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_EXPIRATION
    );
    const isScheduledDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.SCHEDULES
    );
    const isTemplatesDisabled = !checkIfFeatureIsEnabled(
      userData,
      productFeature.TEMPLATES
    );

    const isTrial = userData?.trialAccount;

    profileItemstemp = profileItemstemp.map(item => {
      if (trialExpired && item.title !== LOGOUT.title) {
        item.isDisabled = true;
      } else if (item.title === MANAGE_USERS.title) {
        item.isDisabled = isManageUsersDisabled;
      } else if (item.title === TELEPROMPTER.title) {
        item.isDisabled = isTeleprompterDisabled;
      } else if (item.title === VIDEO_ATTRIBUTES.title) {
        item.isDisabled = isAttributesDisabled;
      } else if (item.title === VIDEO_EXPIRATION.title) {
        item.isDisabled = isExpirationDisabled;
      } else if (item.title === SCHEDULES.title) {
        item.isDisabled = isScheduledDisabled;
      } else if (item.title === TEMPLATES.title) {
        item.isDisabled = isTemplatesDisabled;
      }
      return item;
    });

    if (
      isManageUsersDisabled &&
      userData &&
      userData.user &&
      userData.user.packageDetails &&
      userData.user.packageDetails.id &&
      (userData.user.packageDetails.id === 2 ||
        userData.user.packageDetails.id === 1)
    ) {
      const i = profileItemstemp.find(i =>
        i.title.includes(MANAGE_USERS.title)
      );
      const idx = i && profileItemstemp.indexOf(i);
      idx && idx > -1 && profileItemstemp.splice(idx, 1);
    }

    if (userData.resellerId === CAMPING_WORLD || isTrial) {
      profileItemstemp = profileItemstemp.filter(
        item => item.path != '/profile/add-ons'
      );
    }

    if (userData.preventUserEditAccess) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== PROFILE.title
      );
    }

    if (checkIfUserIsTechnician(userData)) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== EMAILS_LABEL.title
      );
    }
    if (checkIfTrialUser(userData)) {
      profileItemstemp = profileItemstemp.filter(
        item => item.title !== SCHEDULES.title
      );
    }

    setNavItems([
      ...new Set(
        profileItemstemp.filter(
          item =>
            userData.resellerId === '1' ||
            item.path !== '/profile/Profile_AffiliateApply'
        )
      ),
    ]);

    // if user is admin, change main link to reports legacy
    if (!userData.trialAccount) {
      const reportNavItem = navItemstemp.find(item => item.title === 'Reports');
      if (reportNavItem) {
        if (userData.access.toString() === AccessRole.SUPERVISOR) {
          reportNavItem.path = '/reports/dashboard';
        } else if (userData.access.toString() === AccessRole.ADMIN) {
          reportNavItem.path = isResellerAdmin
            ? '/reports/companies'
            : '/reports/users';
        } else if (userData.access.toString() === AccessRole.USER) {
          reportNavItem.path = '/reports/overview';
        }
      }
    }

    const domain = window.location.hostname;
    const isCovideoDomain =
      domain.includes('covideo.com') || domain.includes('localhost');
    if (!isCovideoDomain) {
      const navItem = navItemstemp.find(item => item.path === '/home');
      if (navItem) {
        navItem.title = 'Videos';
      }
    }

    if (userData?.user?.packageDetails && userData?.user?.packageDetails.id) {
      const packageDetails = userData.user.packageDetails;
      const smsAccess = packageDetails?.products?.includes(
        productFeature.SMS_ADMIN_CONTROLS
      );
      const conversationsAccess = packageDetails?.products?.includes(
        productFeature.CONVERSATIONS
      );
      const reportsAccess = packageDetails?.products?.includes(
        productFeature.REPORTS
      );
      const navItems = navItemstemp.map((item: NavigationItem) => {
        if (item.title === 'Reports' && !reportsAccess) {
          item.isDisabled = true;
        } else if (item.title === 'SMS' && !smsAccess) {
          item.isDisabled = true;
        } else if (item.title === 'Conversations' && !conversationsAccess) {
          item.isDisabled = true;
        }

        return item;
      });

      setHorizontalNavitems(navItems);
    } else {
      const navItems = navItemstemp.map((item: NavigationItem) => {
        if (trialExpired) {
          item.isDisabled = true;
        }

        return item;
      });

      setHorizontalNavitems(navItems);
    }

    const isLegacy = userData.user.packageDetails.id === PackageName.LEGACY;
    const isSMSActivated = userData.customer.smsEnabled === '1';
    // Remove SMS from Top Navbar for Whitelabels, Technicians
    if (
      (whitelabel && whitelabel.name !== WHITELABEL_NAME.COVIDEO) ||
      (isLegacy && !isSMSActivated) ||
      checkIfUserIsTechnician(userData)
    ) {
      navItemstemp = navItemstemp.filter(
        item => item.title !== 'Conversations'
      );
    }

    // For automotive, hide contacts page and show customers (leads) page if leads integration active
    if (isAutomotive && leadsIntegrationActive) {
      navItemstemp = navItemstemp.filter(item => item.title !== 'Contacts');
      navItemstemp.splice(2, 0, {
        title: 'Customers',
        path: '/customers',
      });
    }

    const showConversations = checkIfConversationsAvailable(userData);

    if (!showConversations || checkIfUserIsTechnician(userData)) {
      navItemstemp = navItemstemp.filter(
        item => item.title !== 'Conversationsv2'
      );
    }

    if (!showIntegrations) {
      const adminItem = navItemstemp.find(item => item.title === 'Admin');
      if (adminItem) {
        adminItem.innerMenu = adminItem.innerMenu?.filter(
          item => !ForbiddenFeaturesForWhitelabels[item.title]
        );
      }
    }

    setHorizontalNavitems(navItemstemp);
  }, [userData, whitelabel]);

  const onUserClick = (name: string) => {
    if (name === isDropdownOpen) {
      setIsDropdownOpen('');
    } else {
      setIsDropdownOpen(name);
    }
  };

  // hide and show navbar on mobile
  React.useEffect(() => {
    if (window.innerWidth < theme.screenSizes.sm) {
      let prevScrollpos = window.pageYOffset;
      const navbar = document.getElementById('sideNavbar');
      window.onscroll = () => {
        if (navbar) {
          if (prevScrollpos > window.pageYOffset) {
            navbar.style.top = '0px';
          } else {
            navbar.style.top = `-${navbar.offsetHeight.toString()}px`;
          }
        }
        prevScrollpos = window.pageYOffset;
      };
    }
  }, []);

  const loggedInLocation =
    (userData.isMultiLocation &&
      locations &&
      locations.length &&
      locations.find(
        location =>
          location.locationId.toString() ===
          userData.customer.locationId.toString()
      )?.name) ||
    '';

  const goBack = () => {
    const historyState: HistoryState = history.location.state as HistoryState;
    if (historyState?.from === 'video-details-page') {
      history.go(-2);
      return;
    }
    history.goBack();
  };

  return (
    <div>
      {isUploadVisible && (
        <ModalUpload handleModalClose={() => setIsUploadVisible(false)} />
      )}
      <SidebarContainer
        backgroundColor={backgroundColor}
        color={color}
        id='sideNavbar'
      >
        <MobileSection>
          <FlexWrapper>
            <HamburgerMenu
              onClick={handleSideNav}
              isSideNavOpen={isSideNavOpen}
            >
              <MenuBarsIcon color={theme.palette.white} />
            </HamburgerMenu>
            <HorizontalNavigation
              items={horizontalNavitems}
              isSideNavOpen={isSideNavOpen}
              isDropdownOpen={isDropdownOpen}
              onUserClick={onUserClick}
              isTrialAccount={userData.trialAccount}
            />
          </FlexWrapper>
          <LogoWrap to={'/home'}>
            <Logo noPadding={true} src={logoSrc} id='logo-mobile' alt='Logo' />
          </LogoWrap>
        </MobileSection>
        <DesktopSection>
          {!showNavigation && showNavigation !== undefined && (
            <>
              <BackButton
                color={
                  isAutomotive
                    ? theme.palette.covideoBlue80
                    : theme.palette.white
                }
                onClick={() => {
                  history.location.pathname === '/upgrade-plan'
                    ? history.push('/home')
                    : goBack();
                }}
              >
                <IoMdArrowBack width={24} height={24} />
                <BackButtonLabel>Go Back</BackButtonLabel>
              </BackButton>
            </>
          )}
          <LogoWrap isadmin={`${isAdmin}`} to={'/home'}>
            <Logo src={logoSrc} id='logo-desktop' alt='Logo' />
          </LogoWrap>
          {(showNavigation || showNavigation === undefined) && (
            <HorizontalNavigation
              items={horizontalNavitems}
              isSideNavOpen={isSideNavOpen}
              isDropdownOpen={isDropdownOpen}
              onUserClick={onUserClick}
              isTrialAccount={userData.trialAccount}
            />
          )}
          {(showNavigation || showNavigation === undefined) &&
            userData.access !== AccessRole.SUPERVISOR && (
              <UploadAndRecordContainer>
                <UploadAndRecordButtons
                  isAdmin={isAdmin}
                  onClick={() => showUploadModal(setIsUploadVisible)}
                />
              </UploadAndRecordContainer>
            )}
        </DesktopSection>
        <UserWrap>
          {(showNavigation || showNavigation === undefined) && (
            <User>
              <UserContainer onClick={() => onUserClick('user')}>
                {!userData.isMultiLocation && (
                  <Welcome color={color}>Welcome,</Welcome>
                )}
                <Name
                  loggedInLocation={loggedInLocation}
                  isAdmin={isAdmin}
                  color={color}
                >
                  <div>{username}</div>
                  {loggedInLocation && (
                    <LocationContainer color={color}>
                      {loggedInLocation}
                    </LocationContainer>
                  )}
                </Name>
                <Icon title={'My Account'}>
                  <UserIcon color={color} opacity={1} />
                </Icon>
              </UserContainer>
              <MenuDropdown
                items={navitems}
                isDropdownOpen={isDropdownOpen === 'user'}
                OnClickOutside={() => setIsDropdownOpen('')}
                aligment={true}
                showLocations={userData.isMultiLocation}
                isLocationChanging={isLocationChanging}
                setIsLocationChanging={setIsLocationChanging}
              />
            </User>
          )}
        </UserWrap>
      </SidebarContainer>
      <Overlay isSideNavOpen={isSideNavOpen} onClick={handleSideNav} />
      {isLoginAsModalOpen && (
        <ModalLoginAs handleClose={() => setIsLoginAsModalOpen(false)} />
      )}
    </div>
  );
});
