import styled from 'styled-components/macro';
import { ReactComponent as MinusSvg } from './minus.svg';

const MinusIcon = styled(MinusSvg)`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default MinusIcon;
