import React from 'react';

import leftArrow from 'lib/images/back-arrow.svg';
import rightArrow from 'lib/images/forward-arrow.svg';
import styled, { css } from 'styled-components/macro';

const ArrowStyles = styled.div<{ direction: string }>`
  display: flex;
  position: absolute;
  top: 50%;
  ${({ direction }) =>
    direction === 'right'
      ? css`
          right: 25px;
        `
      : css`
          left: 25px;
        `};
  height: 50px;
  width: 50px;
  justify-content: center;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    transform: ${({ direction }) =>
      `translateX(${direction === 'left' ? '-2' : '2'}px);`} 
    &:focus {
      outline: 0;
    }
  }
`;

interface Props {
  direction?: any;
  handleClick?: any;
}
const Arrow = ({ direction, handleClick }: Props) => (
  <ArrowStyles onClick={handleClick} direction={direction}>
    {direction === 'right' ? (
      <img src={rightArrow} alt='right arrow' />
    ) : (
      <img src={leftArrow} alt='left arrow' />
    )}
  </ArrowStyles>
);

export default Arrow;
