import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 176px;
  height: 8px;
  gap: 16px;
`;
type StepProps = {
  filled?: boolean;
};
const Step = styled.div<StepProps>`
  width: 48px;
  height: 8px;
  background: ${props =>
    props.filled ? theme.palette.covideoBlue100 : theme.palette.covideoBlue10};
  border-radius: 8px;
  flex: none;
  flex-grow: 1;
`;

type Props = {
  currentStep: number;
};
export const StepCounter = (props: Props) => {
  const { currentStep } = props;

  return (
    <Container>
      <Step key={`step-indicator-1`} filled={currentStep >= 1} />
      <Step key={`step-indicator-2`} filled={currentStep >= 2} />
    </Container>
  );
};
