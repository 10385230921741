import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { useOnClickOutside } from 'lib/utils';
import CloseIcon from 'lib/images/CloseIcon';
import { Button } from 'react-covideo-common';
import { useResendEmailBatchMutation } from 'lib/api/emailBatches/useResendEmailBatchMutation';

const Header = styled.div`
  display: flex;
  padding: 32px;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 0 32px;
  min-width: 500px;
`;
const InputWrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #001b53;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
  margin-right: 16px;
`;

export const ModalEmailBatchResend = ({
  emailBatch = {},
  handleModalClose = () => {},
}: any) => {
  const { ref } = useOnClickOutside(handleModalClose);
  const [subject, setSubject] = useState(emailBatch.subject);

  const { mutateAsync: mutateResendEmailBatch } = useResendEmailBatchMutation();
  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose && handleModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const resendBatch = async (emailBatch: { batchId: number }) => {
    await mutateResendEmailBatch({
      batchId: emailBatch.batchId,
      subject,
      handleModalClose,
    });
  };

  return (
    <Modal>
      <div ref={ref} style={{ paddingBottom: '24px' }}>
        <Header>
          <HeaderTitle>{`Resend Batch`}</HeaderTitle>
          <CloseButtonWrap title={'Close resend'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <InputWrapper>
            <Label>Subject</Label>
            <Input
              type='text'
              value={subject}
              onChange={event => setSubject(event.target.value)}
            />
          </InputWrapper>
        </Content>
        <ButtonsWrapper>
          <Button
            variant='secondary'
            text='Cancel'
            onClick={() => handleModalClose()}
          />
          <Button text='Resend' onClick={() => resendBatch(emailBatch)} />
        </ButtonsWrapper>
      </div>
    </Modal>
  );
};
