import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdEdit, MdFileDownload, MdSave } from 'react-icons/md';
import CloseIcon from 'lib/images/CloseIcon';
import DeleteIcon from 'lib/images/DeleteIcon';

import { useParams, useHistory } from 'react-router';
import { ContactListItem } from 'lib/api';
import { CheckboxInput, LoadingIndicator, ModalDelete } from 'lib/components';
import {
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
  Search,
} from 'lib/components';
import { GroupContactsHeader } from './components/GroupContactsHeader';
import { anyInMap } from 'lib/utils/object';
import { NoGroupContacts } from './components/NoGroupContacts';
import { exportCSVFile } from 'lib/utils/functions';
import { MainContainer } from '../../index.styled';
import { NotFound } from 'app/pages/notFound/NotFound';
import {
  getContacts,
  useContactsQuery,
} from 'lib/api/contacts/useContactsQuery';
import { useState } from 'react';
import { useUpdateGroupMutation } from 'lib/api/group/useUpdateGroupMutation';
import { useDeleteGroupsMutation } from 'lib/api/group/useDeleteGroupsMutation';
import { useQuerySingleGroup } from 'lib/api/group/useQuerySingleGroup';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { IContact } from 'lib/api/contacts/getSingleContact';

const exportHeader = {
  email: 'Email',
  lastName: 'Last Name',
  firstName: 'First Name',
  phone: 'Phone',
};

type SelectedContacts = {
  count: number;
  contacts: Map<string, ContactListItem | undefined>;
};

const ModalDeleteContainer = styled.div`
  z-index: 999;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 40px;
  align-items: center;
  padding: 12px 0 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
`;

const MainWrapper = styled.div`
  margin-top: 52px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
  }
`;

const Header = styled.div`
  height: 40px;
  font-size: 24px;
  ${theme.fontBold700}
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 500px;
`;

const NavLinkItem = styled(NavLink)`
  ${theme.fontNormal500};
  color: rgba(0, 27, 83, 0.8);
  font-size: ${theme.fontSizes.m};
  padding-left: 24px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    color: ${theme.palette.primaryDarkBlue};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
`;

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;
const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: 420px;
  ${theme.fontBold700}
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${theme.palette.title};
  line-height: 1.82;
  margin-right: auto;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  .blur {
    min-height: 40px;
    line-height: 1.82;
    max-width: 360px;
    overflow: hidden;
    text-align: left !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .focus {
    min-height: 40px;
    line-height: 1.82;
    border: 1px solid ${theme.palette.secondaryBlue};
    border-radius: 4px;
    max-width: none;
  }
`;

const TitleInput = styled.input`
  border-radius: 4px;
  height: 34px;
  width: 96%;
  border: solid 1px #e1e2e5;
  padding: 2px;
  font-size: 24px;
  ${theme.fontBold700}
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
  }
`;

const initialAllSelected: { [key: string]: boolean } = {};
export const GroupDetails = () => {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const themes = useTheme();
  const [search, setSearch] = React.useState('');
  const [isAllSelected, setIsAllSelected] = React.useState(initialAllSelected);
  const inputRef = React.useRef<any>(null);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [editTitle, setEditTitle] = useState(false);

  const { data: groupData, refetch: loadSingleGroup } = useQuerySingleGroup(id);
  const group = groupData?.group;

  const GROUP_SIZE = 100;

  const groupsQueryParams = {
    size: GROUP_SIZE,
  };

  const { data: groupsData, isLoading: isLoadingGroup } =
    useQueryGroups(groupsQueryParams);
  const groups = groupsData?.items ?? [];

  const [titleValue, setTitleValue] = useState('');

  React.useEffect(() => {
    setTitleValue(group?.name);
  }, [groupData]);

  const queryParams = {
    groupId: id,
    page,
    size,
    search,
  };

  const { data, isLoading: isLoadingContacts } = useContactsQuery(
    queryParams,
    true
  );

  const contacts = data?.contacts ?? [];
  const count = data?.count;

  const { mutateAsync: updateGroupMutation } = useUpdateGroupMutation();

  const onDeleteGroupsMutationSuccess = () => {
    history.goBack();
  };

  const { mutateAsync: deleteGroupsMutation } = useDeleteGroupsMutation(
    [id],
    onDeleteGroupsMutationSuccess,
    groupsQueryParams
  );

  const handleSearchContacts = (search: string) => {
    setSearch(search);
  };

  const onPaginationChange = ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(size);
    setPage(page);
  };

  const [selectedContacts, setSelectedContacts] =
    React.useState<SelectedContacts>({
      count: 0,
      contacts: new Map<string, ContactListItem | undefined>(),
    });

  const onDelete = async () => {
    await deleteGroupsMutation();
  };

  const exportGroupContacts = async () => {
    const tempGroup = groups.find(g => g.groupId == id);
    let contactsToExport: ContactListItem[] = [];
    if (tempGroup) {
      contactsToExport = tempGroup.contacts || [];
    } else {
      const { contacts: allContacts } = await getContacts({
        groupId: id,
        page: 0,
        size: count,
      });
      contactsToExport = allContacts;
    }
    exportCSVFile(exportHeader, contactsToExport, 'selected_contacts');
  };

  React.useEffect(() => {
    const selected = new Map<string, ContactListItem | undefined>(
      selectedContacts.contacts
    );
    contacts.forEach((c: IContact) => {
      const isAllSelectedOnPage = isAllSelected[page];
      if (isAllSelectedOnPage) {
        selected.set(c.contactId, c);
      } else {
        selected.set(c.contactId, undefined);
      }
    });

    setSelectedContacts({
      count: Array.from(selected.values()).filter(Boolean).length,
      contacts: selected,
    });
  }, [isAllSelected]);

  const checkIndicator = () => {
    return (
      anyInMap(contacts, selectedContacts.contacts, 'contactId') &&
      !isAllSelected[page]
    );
  };

  const selectAll = () => {
    setIsAllSelected((all: any) => ({ ...all, [page]: !all[page] }));
  };
  const columnWidths = [
    24,
    152 + 67 - 24,
    54 + 125 - 24,
    47 + 116 - 24,
    102 + 273 - 74,
    24,
  ];

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  let modalDeleteText = 'This action can’t be undone.';
  let whiteButtonText = 'Yes, Delete';
  let orangeButtonText = "No, Don't Delete";
  let modalDeleteTitle = 'Delete group';

  const handleTitleChange = async () => {
    const data = {
      groupId: id,
      name: titleValue,
    };
    await updateGroupMutation(data);
    await loadSingleGroup(id);
    closeEditTitle();
  };
  const openEditTitle = () => {
    setTitleValue(titleValue);
    setEditTitle(true);
  };

  const closeEditTitle = () => {
    setEditTitle(false);
  };

  if (group && !group.groupId) {
    if (isLoadingGroup) {
      return <LoadingIndicator isLoading={isLoadingGroup} />;
    }
    return <NotFound />;
  }

  return (
    <MainContainer>
      <GroupContactsHeader
        groupId={id}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        isAllSelected={isAllSelected[page]}
        checkIndicator={checkIndicator}
        selectAll={selectAll}
        queryParams={queryParams}
      />
      <MainWrapper>
        <NavLinkItem to='/contacts/groups'>
          <FaChevronLeft style={{ paddingRight: 12 }} />
          Back
        </NavLinkItem>
        <Row style={{ marginBottom: 20 }}>
          <Row>
            <Header>
              <TitleWrapper>
                {!editTitle && titleValue}
                {editTitle && (
                  <TitleInput
                    type='text'
                    autoFocus
                    ref={inputRef}
                    value={titleValue}
                    onChange={(e: any) => setTitleValue(e.currentTarget.value)}
                  />
                )}
              </TitleWrapper>
            </Header>
          </Row>
          <div style={{ display: 'flex', gap: 12 }}>
            {!editTitle && (
              <div style={{ width: 200, marginLeft: 32 }}>
                <Search
                  placeholder='Search Contacts...'
                  onSearch={(value: string) => handleSearchContacts(value)}
                />
              </div>
            )}
            {editTitle ? (
              <>
                <Button
                  onClick={handleTitleChange}
                  text='Save'
                  icon={<MdSave color={themes.colors.white[100]} />}
                  variant='primary'
                ></Button>
                <Button
                  onClick={() => {
                    setTitleValue(group?.name);
                    closeEditTitle();
                  }}
                  text=''
                  variant='ghost'
                  icon={<CloseIcon width={24} height={24} />}
                />
              </>
            ) : (
              <Button
                onClick={openEditTitle}
                text=''
                variant='ghost'
                icon={<MdEdit />}
              />
            )}
            <Button
              onClick={() => exportGroupContacts()}
              variant='ghost'
              text='Export Group'
              icon={<MdFileDownload />}
            ></Button>
            <Button
              variant='destructive'
              text=''
              icon={
                <DeleteIcon
                  width='24px'
                  height='24px'
                  color={theme.palette.buttonDelete}
                />
              }
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        </Row>
        <Row>
          <Layout>
            {isLoadingContacts && contacts.length == 0 ? (
              <LoadingIndicator
                isLoading={isLoadingContacts}
                text='Loading Contacts...'
                height={400}
              />
            ) : !isLoadingContacts &&
              contacts.length == 0 &&
              contacts.length == 0 ? (
              <>
                {!search ? (
                  <NoGroupContacts />
                ) : (
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '36px 0',
                    }}
                  >
                    No matching results found.{' '}
                  </p>
                )}
              </>
            ) : (
              <>
                <TableContextProvider
                  total={count}
                  onChange={onPaginationChange}
                  initPage={page}
                  initSize={size}
                >
                  <Table
                    columnWidths={columnWidths}
                    compact={true}
                    headers={[
                      '',
                      <TableCell width={columnWidths[1]}>Email</TableCell>,
                      <TableCell width={columnWidths[2]}>Last Name</TableCell>,
                      <TableCell width={columnWidths[3]}>First Name</TableCell>,
                      <TableCell width={columnWidths[4]}>Phone</TableCell>,
                      <TableCell width={columnWidths[5]}></TableCell>,
                    ]}
                    hoverable={false}
                    rows={contacts.map(
                      (contact: ContactListItem, index: number) => ({
                        key: index,
                        columns: [
                          <CheckboxInput
                            width='24px'
                            blueCheck={true}
                            checked={
                              !!selectedContacts.contacts.get(contact.contactId)
                            }
                            onChange={(e: React.SyntheticEvent) => {
                              let { checked } = e.target as HTMLInputElement;
                              let selected = new Map<
                                string,
                                ContactListItem | undefined
                              >(selectedContacts.contacts);
                              let count = selectedContacts.count;
                              if (checked) {
                                selected.set(contact.contactId, contact);
                                count++;
                              } else {
                                selected.set(contact.contactId, undefined);
                                count--;
                              }
                              setSelectedContacts({
                                count: count,
                                contacts: selected,
                              });
                            }}
                          />,
                          <TableCell
                            width={columnWidths[1]}
                            onClick={() => {
                              history.push(
                                '/contacts/list/' + contact.contactId
                              );
                            }}
                          >
                            {contact.email}
                          </TableCell>,
                          <TableCell
                            width={columnWidths[2]}
                            onClick={() => {
                              history.push(
                                '/contacts/list/' + contact.contactId
                              );
                            }}
                          >
                            {contact.lastName}
                          </TableCell>,
                          <TableCell
                            width={columnWidths[3]}
                            onClick={() => {
                              history.push(
                                '/contacts/list/' + contact.contactId
                              );
                            }}
                          >
                            {contact.firstName}
                          </TableCell>,
                          <TableCell
                            width={columnWidths[4]}
                            onClick={() => {
                              history.push(
                                '/contacts/list/' + contact.contactId
                              );
                            }}
                          >
                            {contact.phone}
                          </TableCell>,
                          <TableCell width={columnWidths[5]}>
                            <FaChevronRight
                              size={13}
                              onClick={() => {
                                history.push(
                                  '/contacts/list/' + contact.contactId
                                );
                              }}
                            />
                          </TableCell>,
                        ],
                        onClick: () => {},
                      })
                    )}
                  />
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew />
                  </TableFooter>
                </TableContextProvider>
              </>
            )}
          </Layout>
        </Row>
      </MainWrapper>
      {showDeleteModal && (
        <ModalDeleteContainer>
          <ModalDelete
            whiteButtonText={whiteButtonText}
            orangeButtonText={orangeButtonText}
            title={modalDeleteTitle}
            text={modalDeleteText}
            hasConfirm={true}
            handleModalClose={() => setShowDeleteModal(false)}
            onClickWhiteButton={() => onDelete()}
            onClickOrangeButton={() => setShowDeleteModal(false)}
          />
        </ModalDeleteContainer>
      )}
    </MainContainer>
  );
};
