import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { useGetVdps } from 'lib/api/vdp/useGetVdps';
import { useAuth } from 'lib/context';
import { VDP_IntegrationTypes } from 'lib/const/vdp';
import { VdpParams } from 'lib/api/vdp/types';
import { useDeleteVdp } from 'lib/api/vdp/useDeleteVdp';
import { useUpdateOrderVdps } from 'lib/api/vdp/useUpdateOrderVdps';
import {
  DoubleCell,
  RecordedByField,
  SortCell,
  Table,
  TableCell,
  TableHead,
  TableRowStyles,
  Tooltip,
  VideoTitleField,
  CellSecondary,
  CellPrimary,
  PromptContent,
} from '../styles';
import { ModalPrompt, ModalVideoListVideo } from 'lib/components/modal';
import {
  TableRow,
  formatTableRows,
  tableCols,
} from '../components/VdpTableUtils';
import { VdpItem } from 'lib/api';
import { OrderTableArrowIcon } from 'lib/images/OrderTableArrow';
import { theme } from 'lib/style';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { reorder } from 'lib/utils/array';
import { VdpFilters } from '../components/VdpFilters';
import { Container, MainWrapper } from 'lib/components/styles/layout';

const NoList = styled.div`
  display: block;
`;

export const List = () => {
  const { userData } = useAuth();
  const hasHomenet = !!userData.customer.dealerComId;

  const [params, setParams] = useState<VdpParams>({
    search: '',
    limit: 10,
    sort: '',
    start: 0,
    integrationType: hasHomenet
      ? VDP_IntegrationTypes.homenet.value
      : VDP_IntegrationTypes.salespages.value,
    userId: 0,
    vin: '',
  });
  const [vdpToPull, setVdpToPull] = useState<VdpItem>();
  const [tooltipIndex, setTooltipIndex] = useState(-1);
  const [showModalPreviewVideo, setShowModalPreviewVideo] = useState(0);
  const [isReorderable, setIsReorderable] = useState(false);

  const { data, isLoading } = useGetVdps(params);

  const { vdps, count } = data || { vdps: [], count: 0 };
  const { mutateAsync: deleteVdp } = useDeleteVdp();
  const { mutateAsync: updateOrderVdps, isLoading: isReordering } =
    useUpdateOrderVdps();

  const columnWidths = isReorderable
    ? [112, 361, 220, 0, 200, 150, 115]
    : [0, 237, 220, 236, 200, 140, 115];

  const tableRows = formatTableRows(vdps, setTooltipIndex, setVdpToPull);

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (params.start !== newPage || params.limit !== newSize) {
      setParams(prev => ({
        ...prev,
        limit: newSize,
        start: newPage * newSize,
      }));
    }
  };

  const handleSortClick = (sortParam: string) => {
    let newSort = '';
    const { sort } = params;
    if (sort.length && sort.includes(sortParam)) {
      newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
    } else if (!sort.includes(sortParam)) {
      newSort = `${sortParam}`;
    }
    setParams(prev => ({ ...prev, sort: newSort }));
  };

  const onDragEnd = async ({ source, destination }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    const updatedOrder = reorder(
      tableRows,
      source.index,
      destination.index
    ).map(row => row.id);
    await updateOrderVdps(updatedOrder);
  };

  useEffect(() => {
    setIsReorderable(
      !!params.vin && !params.userId && !params.search.trim().length
    );
  }, [params]);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} height='600px' />;
  }

  return (
    <Container>
      <MainWrapper resetPadding={false} style={{ paddingTop: '32px' }}>
        <VdpFilters params={params} setParams={setParams} />
        {!vdps.length ? (
          <NoList>No videos pushed to VDP to show.</NoList>
        ) : isReordering ? (
          <LoadingIndicator isLoading={true} height='300px' />
        ) : (
          <TableContextProvider
            total={count}
            initSize={params.limit}
            initPage={params.start / params.limit}
            onChange={onPaginationChange}
          >
            <Table>
              <TableHead>
                {tableCols.map((col, index) => {
                  if (col.value === 'reorder' && !isReorderable) return null;
                  if (col.value === 'vin' && isReorderable) return null;
                  return (
                    <TableCell
                      width={columnWidths[index]}
                      padding={col.padding}
                      key={`header-${index}`}
                      align={col.align}
                      style={{
                        ...(col.value === 'videoTitle' && {
                          minWidth: columnWidths[index],
                        }),
                      }}
                    >
                      {col.label}
                      {!!col.sortable && !isReorderable && (
                        <SortCell onClick={() => handleSortClick(col.value)}>
                          <OrderTableArrowIcon
                            color={
                              params.sort == `${col.value}`
                                ? theme.palette.covideoBlue100
                                : theme.palette.blue20
                            }
                            rotate={'180'}
                          />
                          <OrderTableArrowIcon
                            color={
                              params.sort == `-${col.value}`
                                ? theme.palette.covideoBlue100
                                : theme.palette.blue20
                            }
                          />
                        </SortCell>
                      )}
                    </TableCell>
                  );
                })}
              </TableHead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='attributes'>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ position: 'relative' }}
                    >
                      {tooltipIndex > -1 && (
                        <Tooltip top={`${tooltipIndex * 57 - 40}px`}>
                          Drag and Drop to rearrange
                        </Tooltip>
                      )}

                      {(tableRows || []).map((row: TableRow, index: number) => {
                        return (
                          <Draggable
                            draggableId={row.id.toString()}
                            index={index}
                            key={row.id}
                            isDragDisabled={!isReorderable}
                          >
                            {provided => (
                              <TableRowStyles
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {tableCols.map((col, index) => {
                                  if (col.value === 'reorder' && !isReorderable)
                                    return null;
                                  if (col.value === 'vin' && isReorderable)
                                    return null;
                                  return (
                                    <TableCell
                                      width={columnWidths[index]}
                                      padding={col.padding}
                                      key={`row-cell-${index}`}
                                      style={{
                                        ...(col.value === 'videoTitle' && {
                                          cursor: 'pointer',
                                        }),
                                      }}
                                    >
                                      {col.value === 'reorder' && row.reorder}

                                      {col.value === 'videoTitle' && (
                                        <VideoTitleField
                                          style={{
                                            minWidth: columnWidths[index],
                                          }}
                                          onClick={() => {
                                            setShowModalPreviewVideo(
                                              row.videoId
                                            );
                                          }}
                                        >
                                          {row.videoTitle}
                                        </VideoTitleField>
                                      )}
                                      {col.value === 'recordedBy' && (
                                        <RecordedByField>
                                          {row.recordedBy}
                                        </RecordedByField>
                                      )}
                                      {col.value === 'vin' && (
                                        <DoubleCell>
                                          <CellPrimary>{row.vin}</CellPrimary>
                                          <CellSecondary>
                                            {row.vehicleTitle}
                                          </CellSecondary>
                                        </DoubleCell>
                                      )}
                                      {col.value === 'createdAt' && (
                                        <DoubleCell>
                                          <CellPrimary>
                                            {row.createdAt}
                                          </CellPrimary>
                                          <CellSecondary>
                                            {row.pushedBy}
                                          </CellSecondary>
                                        </DoubleCell>
                                      )}
                                      {col.value === 'integrationType' && (
                                        <CellPrimary>
                                          {row.integrationType}
                                        </CellPrimary>
                                      )}
                                      {col.value === 'buttons' && row.buttons}
                                    </TableCell>
                                  );
                                })}
                              </TableRowStyles>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {!isReorderable && (
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew text='Show rows:' />
                </TableFooter>
              )}
            </Table>
          </TableContextProvider>
        )}
      </MainWrapper>
      {!!showModalPreviewVideo && (
        <ModalVideoListVideo
          videoId={showModalPreviewVideo.toString()}
          handleModalClose={() => setShowModalPreviewVideo(0)}
        />
      )}
      {!!vdpToPull?.id && (
        <ModalPrompt
          title={'Pull video from the VDP?'}
          content={
            <PromptContent>
              You are about to remove the video{' '}
              <b>“{vdpToPull.video?.title}”</b> from the Vehicle Details page
              for the <b>VIN: {vdpToPull.vin}</b>.
            </PromptContent>
          }
          secondaryButtonText={'No, Keep'}
          primaryButtonText={'Yes, Pull Video'}
          handleSubmit={() => {
            deleteVdp(vdpToPull.id);
            setVdpToPull(undefined);
          }}
          handleModalClose={() => setVdpToPull(undefined)}
          primaryButtonType={'destructive'}
        />
      )}
    </Container>
  );
};
