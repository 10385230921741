import {
  FormProps,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  getCheckboxField,
} from 'app/pages/admin/components/AdminFormComponents';
import React from 'react';

export const VdpSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;

  return (
    <Section>
      <SectionTitle>VDP</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'vdpHomeNetEnabled',
            'HomeNet',
            !!values.vdpHomeNetEnabled,
            () =>
              setFieldValue(
                'vdpHomeNetEnabled',
                values.vdpHomeNetEnabled ? 0 : 1
              ),
            isSubmitting
          )}
        </Row>
        <Row>
          {getCheckboxField(
            'vdpIPacketEnabled',
            'IPacket',
            !!values.vdpIPacketEnabled,
            () =>
              setFieldValue(
                'vdpIPacketEnabled',
                values.vdpIPacketEnabled ? 0 : 1
              ),
            isSubmitting
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
