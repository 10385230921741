import { Gap } from 'lib/components/styles/layout';
import { SubHeading } from 'lib/components/styles/typography';
import { VisibilityMessageIcon } from 'lib/images/VisibilityMessageIcon';
import React from 'react';
import { Button } from 'react-covideo-common';

interface IProps {
  openHowToShareModal: () => void;
  message: string;
}
export const VisibilityMessage = ({ message, openHowToShareModal }: IProps) => {
  return (
    <Gap style={{ height: '100%' }}>
      <Gap width='100%' alignItems='center' justifyContent='center' gap='24px'>
        <VisibilityMessageIcon />
        <SubHeading textAlign='center'>{message}</SubHeading>
        <Button
          text='How to Share?'
          variant='white'
          onClick={openHowToShareModal}
        />
      </Gap>
    </Gap>
  );
};
