import React, { useState } from 'react';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetTabs from '../tabs';
import { WidgetWrapper } from './common/styles';
import {
  IParams,
  REPORTS_STALE_TIME,
  VariantTypes,
  WidgetLabels,
  WidgetValues,
  WIDGET_TABS,
} from './types';
import { ItemsWrapper, WidgetTitle } from './styles/topVariants';
import TopVideosTableBody from './TopVideosTableBody.tsx';
import { generateWidgetData } from './helper';
import { getTopVideos } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';

interface ITopVariantsProps {
  params: IParams;
  variant: string;
  addDataForExport: (data: any) => void;
}
interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

const variantData = {
  [VariantTypes.VIDEOS]: {
    label: 'Top Videos',
    tabItems: WIDGET_TABS.VARIANTS_VIDEOS,
    tableHeaders: ['', 'Video Name', 'Owner'],
    flexHeaders: ['0.1', '0.3', '0.3', '0.3'],
  },
} as VariantData;

const TopVideos = ({
  variant,
  params,
  addDataForExport,
}: ITopVariantsProps) => {
  const [selected, setSelected] = useState(variantData[variant].tabItems[0]);

  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.TOP_VIDEOS, params),
    () => getTopVideos({ ...params, users: 'all' }),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const topVideos = data?.views;
  const topVideosByCtaClicks = data?.ctaClick;
  const topVideosByEngagment = data?.engagements;

  const mapDataForExport = (data: any) => {
    let exportData: any = { title: '', data: [], arr: [] };
    exportData.title = WidgetLabels.TOP_VIDEOS;
    let dataVideoObjects: any[] = [];
    WIDGET_TABS.VARIANTS_VIDEOS.forEach((item: any) => {
      let tmpObj: any = { data: [] };
      tmpObj.title = variant + '-' + item.id;
      let generate;

      switch (item.id) {
        case 'views':
          generate = generateWidgetData(data?.topVideos?.slice(0, 10), item.id);
          break;
        case 'engagement':
          generate = generateWidgetData(data?.topVideosByEngagment, item.id);
          break;
        case 'cta-clicks':
          generate = generateWidgetData(data?.topVideosByCtaClicks, item.id);
          break;
        default:
          return [];
      }

      generate?.forEach((el: any) => {
        const obj: any = {};
        obj['User'] = el.name;
        obj[item.label] = el.value;
        obj['Title'] = el.title;
        tmpObj.data.push(obj);
      });
      dataVideoObjects.push(tmpObj.data);
      exportData.arr.push(tmpObj);
    });
    const maxVideosLength = Math.max(...dataVideoObjects.map(e => e.length));
    for (let i = 0; i < maxVideosLength; i++) {
      let singleObject = {
        'Video Name by Views': dataVideoObjects[0][i]?.['Title'] ?? '',
        'Owner by Views': dataVideoObjects[0][i]?.['User'] ?? '',
        Views: dataVideoObjects[0][i]?.['Views'] ?? '',
        '': '',
        'Video Name by Engagement': dataVideoObjects[1][i]?.['Title'] ?? '',
        'Owner by Engagement': dataVideoObjects[1][i]?.['User'] ?? '',
        Engagement: `${dataVideoObjects[1][i]?.['Engagement']}` ?? '',
        ' ': '',
        'Video Name by Clicks': dataVideoObjects[2][i]?.['Title'] ?? '',
        'Owner by Clicks': dataVideoObjects[2][i]?.['User'] ?? '',
        Clicks: dataVideoObjects[2][i]?.['CTA Clicks'] ?? '',
        '  ': '',
      };
      exportData.data.push(singleObject);
    }
    return exportData;
  };
  React.useEffect(() => {
    addDataForExport(
      mapDataForExport({
        topVideos,
        topVideosByEngagment,
        topVideosByCtaClicks,
      })
    );
  }, [topVideos, topVideosByEngagment, topVideosByCtaClicks]);

  if (isLoading) {
    return <SkeletonLoader imageName='line-report' />;
  }

  return (
    <WidgetWrapper>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          {variantData[variant].label}{' '}
          {isRefetching && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>

      {variantData[variant].tabItems.length ? (
        <WidgetTabs
          items={variantData[variant].tabItems}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <></>
      )}
      <TopVideosTableBody
        views={topVideos?.slice(0, 10)}
        engagement={topVideosByEngagment}
        cta={topVideosByCtaClicks}
        selected={selected}
        variantData={variantData}
        variant={variant}
      />
    </WidgetWrapper>
  );
};

export default TopVideos;
