import { Customer } from 'lib/context';

export type InitialValuesType = {
  [key: string]: string | number | null;
};

export type ConvertedValuesType = {
  [key: string]: string[] | string | number;
};

export enum IMS_PROVIDERS_LABELS {
  VAUTO = 'VAuto',
  EDEALER = 'Edealer',
  HOMENET = 'Homenet',
  MAX_DIGITAL = 'MaxDigital',
  DEALERS_LINK = 'DealersLink',
  DEALER_CUE = 'DealerCue',
  AUTO_REVOLUTION = 'AutoRevolution',
  VINSOLUTIONS = 'Vinsolutions',
  DEALER_CENTER = 'DealerCenter',
  DEALR_CLOUD = 'DealrCloud',
  VIN_MOTION = 'VinMotion',
  AUTO_SWEET = 'AutoSweet',
  DEALERSOCKET = 'DealerSocket',
  PREDIAN = 'Predian',
  DEALER_INSPIRE = 'DealerInspire',
  CARS_FOR_SALE = 'CarsForSale',
  DEALER_CAR_SEARCH = 'DealerCarSearch',
}

export enum IMS_PROVIDERS_VALUES {
  VAUTO = 'vAutoDealerId',
  EDEALER = 'eDealerId',
  HOMENET = 'homeNetId',
  MAX_DIGITAL = 'maxDigitalId',
  DEALERS_LINK = 'dealersLinkId',
  DEALER_CUE = 'dealerCueId',
  AUTO_REVOLUTION = 'autoRevolutionId',
  VINSOLUTIONS = 'vinsolutionsIMSDealerId',
  DEALER_CENTER = 'dealerCenterDealerId',
  DEALR_CLOUD = 'dealrCloudId',
  VIN_MOTION = 'vinMotionId',
  AUTO_SWEET = 'autoSweetId',
  DEALERSOCKET = 'dealerSocketIMSDealerId',
  PREDIAN = 'predianId',
  DEALER_INSPIRE = 'dealerInspireId',
  CARS_FOR_SALE = 'carsForSaleId',
  DEALER_CAR_SEARCH = 'dealerCarSearchId',
}

export type IMSFields = {
  vAutoDealerId: string | null;
  vinMotionId: string | null;
  autoSweetId: string | null;
  eDealerId: string | null;
  homeNetId: string | null;
  maxDigitalId: string | null;
  dealersLinkId: string | null;
  dealerCueId: string | null;
  autoRevolutionId: string | null;
  vinsolutionsIMSDealerId: string | null;
  dealerSocketIMSDealerId: string | null;
  dealerCenterDealerId: string | null;
  dealrCloudId: string | null;
  predianId: string | null;
  dealerInspireId: string | null;
  carsForSaleId: string | null;
  dealerCarSearchId: string | null;
};

export type IIMSConnection = IMSFields & {
  customerId: number;
  business: string;
  hideInventoryItemPrice: number;
};

export interface IMSConnectedResponse {
  customers: Customer[];
  count: number;
}

export type IMSProviderOption = {
  value: IMS_PROVIDERS_VALUES;
  label: IMS_PROVIDERS_LABELS;
};
