import * as React from 'react';
import CheckmarkIcon from 'lib/images/checkmark.svg';
import PlusIcon from 'lib/images/PlusIcon';
import MinusIcon from 'lib/images/MinusIcon';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { SpokeSpinnerLoader } from 'lib/components';
import { ImageCropper } from './ImageCropper';
import { RangeSlider } from 'lib/components/range-slider/RangeSlider';
import { MdCheck } from 'react-icons/md';
import {
  getSignedURLs,
  GetSignedUrlsParams,
  GetSignedUrlsResponse,
} from 'lib/api';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { ICombinedUserData, useAuth } from 'lib/context';
import { BiImageAdd } from 'react-icons/bi';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';

export const DefaultThumbnailUrls = {
  FolderSetIcon: `https://www.covideo.com/images/emails/folderSet.png`,
  DocumentsIcon: `https://www.covideo.com/images/emails/documents.png`,
  ImageAndDocumentIcon: `https://www.covideo.com/images/emails/imageAndDocument.png`,
  ImagesIcon: `https://www.covideo.com/images/emails/images.png`,
  MusicIcon: `https://www.covideo.com/images/emails/music.png`,
  SnapshotsIcon: `https://www.covideo.com/images/emails/snapshots.png`,
  VideoAndImageIcon: `https://www.covideo.com/images/emails/videoAndImage.png`,
  VideosIcon: `https://www.covideo.com/images/emails/videos.png`,
};
interface RowStyleProperties {
  width?: string;
  height?: string;
  minHeight?: string;
  alignItems?: string;
  justifyContent?: string;
  display?: 'block' | 'inline-flex' | 'inline' | 'flex';
}
interface ColumnStyleProperties {
  width?: string;
  height?: string;
  minHeight?: string;
  flex?: number;
  alignItems?: string;
  justifyContent?: string;
  position?: 'block' | 'relative';
}

const thumbnailTabContentActionBtnStyles = {
  width: '111px',
};

const thumbnailTabContentUploadBtnStyles = {
  width: '180px',
};

const Container = styled.div`
  width: 100%;
  height: auto;
  .selectedThumbnail {
    border: 2px solid #001b53;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .checkMark {
    content: url('${CheckmarkIcon}');
    width: 20px;
    height: 20px;
    background: #001b53;
    border-radius: 20px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }
  .previewImage {
    position: absolute;
  }
  .previewContainer {
    position: relative;
  }
  .previewContainer:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, 0.3);
  }
  .previewAction1 {
    position: absolute;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    top: 50px;
  }

  .previewAction2 {
    position: absolute;
    text-align: center;
    opacity: 0;
    top: 95px;
    transition: opacity 0.35s ease;
  }

  .previewContainer:hover .previewAction1 {
    opacity: 1;
  }
  .previewContainer:hover .previewAction2 {
    opacity: 1;
  }
  .addZIndex {
    z-index: 99999;
  }
  margin-top: 25px;
`;
const Row = styled.div<RowStyleProperties>`
  display: ${props => (props.display ? props.display : 'flex')};
  flex-direction: row;
  flex-wrap: wrap;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding: 6px 0px;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'unset'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'unset')};
`;

const Column = styled.div<ColumnStyleProperties>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: ${props => (props.position ? props.position : 'block')};
  height: ${props => (props.height ? props.height : 'auto')};
  flex: ${props => (props.flex ? props.flex : '1')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'unset'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'unset')};
`;

type ImgProps = {
  img?: string;
  isSelected?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
};
const ImageContainer = styled.div`
  position: relative;
`;
const Image = styled.img<ImgProps>`
  background-color: ${theme.palette.white};
  display: block;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  border: ${props => (props.border ? props.border : 'none')};
  cursor: pointer;
  box-sizing: border-box;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};
`;

type CheckMarkProps = {
  top?: string;
  right?: string;
};
const CheckMark = styled.div<CheckMarkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 111;
  top: ${props => (props.top ? props.top : '0')};
  right: ${props => (props.right ? props.right : '0')};
  width: 20px;
  height: 20px;
  background: #001b53;
  border-radius: 20px;
  color: white;
  font-size: 18px;
`;
const CustomThumbnailSelectSection = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid ${props => props.theme.colors.primary[100]};
`;

const ThumbnailPreviewContainer = styled.div`
  width: 532px;
  max-width: 532px;
  height: 258px;
  max-height: 258px;
  background: #ffffff;
  border: 2px solid rgba(255, 139, 34, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
`;

const SliderContainer = styled.div`
  width: 80%;
  height: auto;
`;

const TextContent = styled.div`
  width: 238.14px;
  height: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary[100]};
  cursor: pointer;
`;

type Props = {
  setShowFileListModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCustomImage?: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomImage: boolean;
  thumbnailImageUrl?: string;
  setThumbnailImageUrl?: React.Dispatch<React.SetStateAction<string>>;
  showPreview: boolean;
  setShowPreview?: React.Dispatch<React.SetStateAction<boolean>>;
  croppedImageUrl: string;
  setCroppedImageUrl: React.Dispatch<React.SetStateAction<string>>;
  currentTab?: string;
  editorImage: string;
  setEditorImage: React.Dispatch<React.SetStateAction<string>>;
  isGuide?: boolean;
  handleUploadThumbnail?: (arg: string) => void;
  fileProp?: any;
};

export const ThumbnailTabContent = ({
  thumbnailImageUrl,
  setShowFileListModal,
  setIsCustomImage,
  isCustomImage,
  setThumbnailImageUrl,
  showPreview,
  setShowPreview,
  croppedImageUrl,
  setCroppedImageUrl,
  editorImage,
  setEditorImage,
  isGuide,
  handleUploadThumbnail,
}: Props) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [showCustomThumbnailOptions, setShowCustomThumbnailOptions] =
    React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState<File | undefined>(
    undefined
  );
  const [disableDoneButton, setDisableDoneButton] = React.useState(false);
  const [_, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });

  const [zoom, setZoom] = React.useState(1);
  const sampleThumbnailWidth = '185px';
  const sampleThumbnailBorderRadius = '4px';
  const sampleThumbnailBorder = '2px solid transparent';
  const { userData } = useAuth();
  const generateName = handleNameGeneration(userData);
  const onUploadProgress = handleUploadProgress(setUploadProgress);
  const themes = useTheme();

  const onCropStart = () => {
    setDisableDoneButton(true);
  };

  const onCropEnd = () => {
    setDisableDoneButton(false);
  };

  React.useEffect(() => {}, [croppedImageUrl]);
  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  React.useEffect(() => {}, [editorImage]);

  const handleSelectedFile = async (
    e?: React.ChangeEvent<HTMLInputElement>,
    file?: File,
    forcePreview?: boolean
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    const target = e?.target as HTMLInputElement;
    let orgFile;
    if (target && target.files && target.files[0]) {
      orgFile = target.files[0];
    } else {
      orgFile = file;
    }

    if (!orgFile) {
      errorToast({ title: 'Invalid file.' });
      return;
    }
    const fileExtension = orgFile.type
      ? orgFile.type.split('/').pop()
      : orgFile.name.split('/').pop();

    const isValidType =
      fileExtension && /(jpeg|jpg|png|bmp|gif|webp)/i.test(fileExtension);

    if (isValidType) {
      let filename = orgFile.name.split('.');
      let newFile = new File(
        [orgFile],
        generateName(filename[filename.length - 1]),
        { type: orgFile.type, lastModified: orgFile.lastModified }
      );

      await handleUpload(newFile, forcePreview || false);
      setIsCustomImage && setIsCustomImage(true);
    } else {
      errorToast({ title: 'Invalid format.' });
    }
  };
  const handleUpload = async (newFile: File, allowPreview: boolean) => {
    setIsUploading(true);
    const signedUrlData: GetSignedUrlsParams = {
      fileName: newFile.name,
      mimeType: newFile.type,
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      setIsUploading(false);
      errorToast({ title: 'Server is currently unavailable, try again!' });
      return;
    }
    const data = {
      file: newFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };

    try {
      await uploadEmailThumbnail({ data, onUploadProgress });
      setCroppedImageUrl(signedURLs.downloadUrl);

      setShowCustomThumbnailOptions(false);
      if (allowPreview && setThumbnailImageUrl) {
        setThumbnailImageUrl(signedURLs.downloadUrl);
      } else {
        setEditorImage(signedURLs.downloadUrl);
      }
      setShowPreview && setShowPreview(allowPreview);
      setIsUploading(false);

      if (isGuide && handleUploadThumbnail) {
        await handleUploadThumbnail(signedURLs.downloadUrl);
      }
    } catch (e) {
      setIsUploading(false);
    }
  };

  const increaseZoom = () => {
    let zoomValue = parseFloat(zoom.toString());
    zoomValue += 0.1;
    if (zoomValue > 5) {
      zoomValue = 5;
    }

    zoomValue = parseFloat(zoomValue.toFixed(1));
    setZoom(zoomValue);
  };

  const decreaseZoom = () => {
    let zoomValue = parseFloat(zoom.toString());
    zoomValue -= 0.1;
    if (zoomValue < 0) {
      zoomValue = 0;
    }

    zoomValue = parseFloat(zoomValue.toFixed(1));
    setZoom(zoomValue);
  };

  let fileRef = React.createRef<HTMLInputElement>();

  const getCustomThumbnailSection = () => {
    return (
      <>
        {showPreview && showCroppedImage()}
        {!showPreview && (
          <CustomThumbnailSelectSection
            onClick={() => {
              setThumbnailImageUrl && setThumbnailImageUrl('');
            }}
          >
            <Column height={'100%'} justifyContent={'center'}>
              <Row justifyContent={'center'} alignItems={'center'}>
                <BiImageAdd
                  onClick={() => {
                    setShowCustomThumbnailOptions(!showCustomThumbnailOptions);
                  }}
                  size={25}
                  color={themes.colors.primary[100]}
                />
              </Row>
              <Row justifyContent={'center'} alignItems={'center'}>
                <TextContent
                  onClick={() => {
                    setShowCustomThumbnailOptions(!showCustomThumbnailOptions);
                  }}
                >
                  Select your own
                </TextContent>
              </Row>
              <Row />
            </Column>
          </CustomThumbnailSelectSection>
        )}
      </>
    );
  };

  const actionsForCroppedImage = () => {
    return (
      <>
        <Button
          style={thumbnailTabContentActionBtnStyles}
          variant='white'
          text={'Reposition'}
          className={'previewAction1'}
          onClick={() => {
            setIsCustomImage && setIsCustomImage(true);
            setShowPreview && setShowPreview(false);
          }}
        />
        <Button
          style={thumbnailTabContentActionBtnStyles}
          variant='destructive'
          text={'Remove'}
          className={'previewAction2'}
          onClick={() => {
            setIsCustomImage && setIsCustomImage(false);
            setShowPreview && setShowPreview(false);
            setShowCustomThumbnailOptions(false);
            setCroppedImage(undefined);
            setCroppedImageUrl('');
            setThumbnailImageUrl && setThumbnailImageUrl('');
            setEditorImage('');
          }}
        />
      </>
    );
  };
  const showCroppedImage = () => {
    return (
      <CustomThumbnailSelectSection
        className={
          thumbnailImageUrl === croppedImageUrl ? 'selectedThumbnail' : ''
        }
      >
        <Column
          className={'previewContainer'}
          height={'195px'}
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
        >
          <Image
            isSelected={thumbnailImageUrl === croppedImageUrl}
            className={'previewImage'}
            height={'195px'}
            width={'100%'}
            src={croppedImageUrl}
            onClick={() => {
              setThumbnailImageUrl && setThumbnailImageUrl(croppedImageUrl);
            }}
          ></Image>
          {thumbnailImageUrl === croppedImageUrl && (
            <CheckMark top={'-7px'} right={'-7px'}>
              <MdCheck />
            </CheckMark>
          )}
          <div
            className='overlay'
            onClick={() => {
              setThumbnailImageUrl && setThumbnailImageUrl(croppedImageUrl);
            }}
          ></div>
          {actionsForCroppedImage()}
        </Column>
      </CustomThumbnailSelectSection>
    );
  };
  const getUploadOptions = () => {
    return (
      <>
        {!showPreview && (
          <CustomThumbnailSelectSection>
            <Column height={'100%'} justifyContent={'center'}>
              <Row justifyContent={'center'}>
                <input
                  type={'file'}
                  ref={fileRef}
                  style={{ display: 'none' }}
                  name={'filePicker'}
                  onChange={(e: any) => {
                    handleSelectedFile(e, undefined, false);
                  }}
                  accept={
                    'image/jpg, image/png, image/jpeg, image/bmp, image/gif, image/webp'
                  }
                />
                <Button
                  style={thumbnailTabContentUploadBtnStyles}
                  text={'Upload image'}
                  onClick={() => fileRef && fileRef.current?.click()}
                />
              </Row>
              <Row justifyContent={'center'}>
                <Button
                  style={thumbnailTabContentUploadBtnStyles}
                  variant='secondary'
                  text={'Select from Library'}
                  onClick={() => {
                    setShowFileListModal && setShowFileListModal(true);
                  }}
                />
              </Row>
              <Row />
            </Column>
          </CustomThumbnailSelectSection>
        )}
      </>
    );
  };

  const getThumbnailPreview = () => {
    return (
      <>
        {isCustomImage && !showPreview && (
          <Row>
            <Column justifyContent={'center'} alignItems={'center'}>
              <ThumbnailPreviewContainer>
                {isUploading && (
                  <SpokeSpinnerLoader loaderMessage={'Uploading...'} />
                )}
                {!isUploading && getImageEditor()}
              </ThumbnailPreviewContainer>

              <Row></Row>
              <Row
                width={'528px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Row
                  width={'80%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <MinusIcon
                    onClick={decreaseZoom}
                    cursor={'pointer'}
                    width={'14px'}
                    color={'#001B53'}
                  />
                  <SliderContainer>
                    <RangeSlider value={zoom} handleChange={setZoom} />
                  </SliderContainer>
                  <PlusIcon
                    onClick={increaseZoom}
                    cursor={'pointer'}
                    width={'14px'}
                    height={'14px'}
                    color={'#001B53'}
                  />
                </Row>
                {!isGuide && (
                  <Row
                    width={'20%'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <Button
                      text={'Done'}
                      disabled={disableDoneButton}
                      onClick={() => {
                        handleSelectedFile(undefined, croppedImage, true);
                      }}
                    />
                  </Row>
                )}
              </Row>
              {isGuide && (
                <Row>
                  <Column alignItems='flex-end'>
                    <Button
                      text={'Upload'}
                      onClick={() =>
                        handleSelectedFile(undefined, croppedImage, true)
                      }
                    />
                  </Column>
                </Row>
              )}
            </Column>
          </Row>
        )}
      </>
    );
  };

  const getImageEditor = () => {
    return (
      <>
        <ImageCropper
          onCropStart={onCropStart}
          onCropEnd={onCropEnd}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          imageUrl={editorImage}
          zoom={zoom}
          setZoom={setZoom}
          cropAreaSize={{
            width: 350,
            height: 196,
          }}
        />
        <Row />
      </>
    );
  };

  return (
    <Container>
      {isCustomImage && getThumbnailPreview()}
      {(!isCustomImage || showPreview) && (
        <>
          <Row height={'195px'}>
            <Column flex={1.8}>
              {showCustomThumbnailOptions
                ? getUploadOptions()
                : getCustomThumbnailSection()}
            </Column>
            <Column alignItems={'center'} justifyContent={'space-between'}>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.FolderSetIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.FolderSetIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.FolderSetIcon);
                  }}
                  src={DefaultThumbnailUrls.FolderSetIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.FolderSetIcon && (
                  <CheckMark top={'-7px'} right={'-7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.DocumentsIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.DocumentsIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.DocumentsIcon);
                  }}
                  src={DefaultThumbnailUrls.DocumentsIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.DocumentsIcon && (
                  <CheckMark top={'-7px'} right={'-7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
          </Row>
          <Row>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.VideosIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.VideosIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.VideosIcon);
                  }}
                  src={DefaultThumbnailUrls.VideosIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.VideosIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.ImagesIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.ImagesIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.ImagesIcon);
                  }}
                  src={DefaultThumbnailUrls.ImagesIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.ImagesIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.MusicIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.MusicIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.MusicIcon);
                  }}
                  src={DefaultThumbnailUrls.MusicIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.MusicIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
          </Row>
          <Row>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.SnapshotsIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.SnapshotsIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(DefaultThumbnailUrls.SnapshotsIcon);
                  }}
                  src={DefaultThumbnailUrls.SnapshotsIcon}
                />
                {thumbnailImageUrl === DefaultThumbnailUrls.SnapshotsIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl === DefaultThumbnailUrls.VideoAndImageIcon
                  }
                  className={
                    thumbnailImageUrl === DefaultThumbnailUrls.VideoAndImageIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(
                        DefaultThumbnailUrls.VideoAndImageIcon
                      );
                  }}
                  src={DefaultThumbnailUrls.VideoAndImageIcon}
                />
                {thumbnailImageUrl ===
                  DefaultThumbnailUrls.VideoAndImageIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
            <Column>
              <ImageContainer>
                <Image
                  isSelected={
                    thumbnailImageUrl ===
                    DefaultThumbnailUrls.ImageAndDocumentIcon
                  }
                  className={
                    thumbnailImageUrl ===
                    DefaultThumbnailUrls.ImageAndDocumentIcon
                      ? 'selectedThumbnail'
                      : ''
                  }
                  width={sampleThumbnailWidth}
                  border={sampleThumbnailBorder}
                  borderRadius={sampleThumbnailBorderRadius}
                  onClick={() => {
                    setThumbnailImageUrl &&
                      setThumbnailImageUrl(
                        DefaultThumbnailUrls.ImageAndDocumentIcon
                      );
                  }}
                  src={DefaultThumbnailUrls.ImageAndDocumentIcon}
                />
                {thumbnailImageUrl ===
                  DefaultThumbnailUrls.ImageAndDocumentIcon && (
                  <CheckMark top={'-7px'} right={'7px'}>
                    <MdCheck />
                  </CheckMark>
                )}
              </ImageContainer>
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
};

function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
