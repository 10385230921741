import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { Button } from 'react-covideo-common';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import React from 'react';
import { MdDone } from 'react-icons/md';
import styled from 'styled-components/macro';
import {
  AddonFooter,
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  Options,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';

const CodirectHeader = styled(PageHeader)`
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  text-align: right;
`;

const CodirectPageContainer = styled(PageContainer)`
  margin: 60px;
`;

export const CodirectAddon = () => {
  const [showContactForm, setShowContactForm] = React.useState(false);

  const ContactForm = async () => {
    setShowContactForm(true);
  };

  return (
    <CodirectPageContainer>
      {showContactForm && (
        <ModalContactAM
          handleModalClose={() => setShowContactForm(false)}
          subject='Codirect Request'
        />
      )}
      <CodirectHeader>
        <Button
          variant='primary'
          text='Contact your Account Manager'
          onClick={ContactForm}
        />
      </CodirectHeader>
      <PageDescription>
        New for auto, RV, marine, and motorsports: marketing intelligence system
        that delivers trackable growth & retention for dealerships.
      </PageDescription>
      <Options>
        <VideoPlayer
          videoRef={React.createRef()}
          playButtonPosition='center'
          playerBackgroundColor='#000'
          playerIconsColor='#ffffff'
          videoSource='https://videos.covideo.com/videos/71305_3_u1zgd70shs1620066633.mp4'
        />
      </Options>
      <FeatureList>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Bring in-active customers back into the fold and keep them there
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Create, manage, & track targeted campaigns to your current &
              potential customers throughout their lifecycle
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Determine your triggering touchpoints, communication style, &
              budget
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Monitor campaigns, capitalize on opportunities, & track results
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              People & process-free system works continuously & automatically
              behind the scenes
            </label>
          </FeatureText>
        </Feature>
      </FeatureList>
      <AddonFooter>
        Have a question? Contact your account manager, contact us at
        support@covideo.com or give us a call at 1.800.306.1445.
      </AddonFooter>
    </CodirectPageContainer>
  );
};
