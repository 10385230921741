import { DropItem } from 'lib/api/droplr/types';
import { useGetQuickshareDetails } from 'lib/api/quickshare/useGetQuickshareDetails';
import { useGetVideos } from 'lib/api/videos/useGetVideos';
import { LoadingIndicator } from 'lib/components';
import React from 'react';
import SearchAndFolders from '../../SearchAndFolders';
import {
  EmptyFiles,
  FilesItem,
  FilesItemDate,
  FilesItemThumbnail,
  FilesItemTitle,
  FilesSelectButton,
  FilesSelector,
  ItemTypeValues,
  Menu,
  Row,
} from './Components';

type Props = {
  searchQuery: string;
  handleSearch(search: string): void;
  handleModalClose: () => void;
  onSelect: (drop: DropItem, itemType: string) => void;
};

export const VideosTab = (props: Props) => {
  const { searchQuery, handleSearch, handleModalClose, onSelect } = props;

  const videoFilter = {
    videoType: '',
    advisorId: '',
    userId: '',
    status: '',
    emailStatus: '',
    folderId: 0,
    page: 0,
    size: 1000,
    searchQuery,
  };

  const { data, isLoading: loading } = useGetVideos(videoFilter, '');
  const videos = data?.videos || [];
  const [selectedVideoId, setSelectedVideoId] = React.useState<string | number>(
    ''
  );

  const { data: quickshareDetails, refetch } = useGetQuickshareDetails({
    videoId: selectedVideoId,
    enabledOnMount: false,
  });

  React.useEffect(() => {
    if (selectedVideoId) {
      refetch();
    }
  }, [selectedVideoId, refetch]);

  React.useEffect(() => {
    if (quickshareDetails) {
      handleModalClose();
      onSelect(quickshareDetails, ItemTypeValues.VIDEO);
    }
  }, [quickshareDetails, handleModalClose, onSelect]);

  return (
    <>
      <Menu>
        <Row>
          <SearchAndFolders
            folders={[]}
            handleSearch={handleSearch}
            handleEmptySearch={() => {}}
            onSelectFolder={() => null}
            searchDisabled={false}
          />
        </Row>
      </Menu>
      {loading && <LoadingIndicator isLoading={loading} height='200px' />}
      {!loading && (
        <FilesSelector>
          {videos.map(video => (
            <FilesItem
              key={video.id}
              onClick={() => setSelectedVideoId(video.id)}
            >
              <FilesItemThumbnail image={video.thumbnail} />
              <FilesItemTitle>
                {video.title} <br />
                <FilesItemDate>{video.recordDate}</FilesItemDate>
              </FilesItemTitle>
              <FilesSelectButton className='btn-select-file'>
                Select Video
              </FilesSelectButton>
            </FilesItem>
          ))}
          {!videos.length && <EmptyFiles>No videos found.</EmptyFiles>}
        </FilesSelector>
      )}
    </>
  );
};
