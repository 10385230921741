import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';

import { CTASetListItem } from 'app/pages/design/callsToAction/ctaSetsList';
import { CallsToActionForm } from '../../../app/pages/design/callsToAction/CallsToActionForm';
import { AccessRole } from 'lib/const'; /*** SUS-1002 changes ***/
import { useAuth } from 'lib/context'; /*** SUS-1002 changes ***/

interface Props {
  onClose: (p?: any) => void;
  data?: CTASetListItem;
  modalLoading: boolean;
  type: 'add' | 'edit' | 'duplicate' | 'delete';
  isCompanyAdmin: boolean;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  height: 536px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalCTASetHandler = (props: Props) => {
  const { data, onClose, type } = props;

  /*** SUS-1002 and SUS-1185 changes ***/
  const {
    userData: { access },
  } = useAuth();
  const isAdminView =
    ((access === AccessRole.ADMIN || access === AccessRole.SUPERVISOR) &&
      data?.isCompany &&
      type === 'edit') ||
    type === 'duplicate' ||
    !data?.isCompany;
  /******/

  let title = 'New CTA Set';
  switch (type) {
    case 'add':
      title = 'New CTA Set';
      break;
    case 'edit':
      title = 'Edit CTA Set';
      break;
    case 'duplicate':
      title = 'Duplicate CTA Set';
      break;

    default:
      break;
  }

  return (
    <Modal maxHeight={!isAdminView ? '60%' : 'auto'} widthOnDesktop={'1008px'}>
      <Content>
        <Row justifyContent={'space-between'}>
          <Title>{!isAdminView ? 'View CTA Set' : title}</Title>
          <CloseIconWrap>
            <CloseIcon
              onClick={onClose}
              width={24}
              height={24}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <CallsToActionForm type={type} item={data} onEnd={onClose} />
      </Content>
    </Modal>
  );
};
