import React, { useState, useCallback } from 'react';
import { NewModal } from './NewModal';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import { formatBytes } from 'lib/utils/functions';
import { useDropzone } from 'react-dropzone';
import {
  acceptedFormats,
  statuses,
} from 'app/pages/library/components/Constants';
import { Button } from 'react-covideo-common';
import CloseIcon from 'lib/images/CloseIcon';
import FileProgress from '../files/FileProgress';
import FileTitle from '../files/FileTitle';
import {
  checkUploadedSize,
  EXCEEDED_MESSAGE,
  MAX_ATTACHMENT_SIZE,
  StatusOptions,
  uploadAttachmentWithProgress,
} from 'lib/utils/files';
import { errorToast } from '../toasts/error';
import {
  getStatusColor,
  getStatusText,
} from 'app/pages/library/components/uploadDropTabs/UploadDropTab';

const FileContent = styled.div`
  background: #f6f7f9;
  border-radius: 4px;
  height: 325px;
  overflow-y: auto;
`;

const FileItem = styled.div`
  display: flex;
  padding: 16px 32px;
`;

const FileDescription = styled.div`
  width: 90%;
`;

const FileAction = styled.div`
  width: 10%;
`;

const FileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const FileStatus = styled.div<{ color: string }>`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : '#9297A2')};
`;

const FileSize = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${theme.palette.coal};
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  left: 20px;
  cursor: pointer;
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #272a32;
`;

const Footer = styled.div`
  margin: 12px 32px;
  display: flex;
  justify-content: space-between;
`;

type UploadProgressModalProps = {
  handleModalClose: () => void;
  initialFile: File;
  uploadedFiles?: any[];
  setUploadedFiles?: (arg1: any) => void;
};

const UploadProgressModal = ({
  handleModalClose,
  initialFile,
  setUploadedFiles,
  uploadedFiles,
}: UploadProgressModalProps) => {
  const initialStatus = {
    status: statuses.WAITING,
    progress: 0,
    currentUpload: 0,
    sizeText: formatBytes(initialFile.size, 2),
  };
  const { ref } = useOnClickOutside(handleModalClose);
  const [files, setFiles] = useState([initialFile] as File[]);
  const [uploadStatuses, setUploadStatuses] = useState([initialStatus]);

  const uploadingFiles = !!files.length;
  let numberOfUploadedFiles = 0;
  let sumOfFiles = 0;

  files
    .filter(i => i !== undefined)
    .forEach((file, index) => {
      sumOfFiles += file.size;
      const status = uploadStatuses[index].status;
      if (status !== statuses.WAITING && status !== statuses.CANCELED) {
        numberOfUploadedFiles += 1;
      }
    });

  const deleteFromList = (file: File) => {
    const fileList = [...files];

    const uploadStatusesList = [...uploadStatuses];
    const index = files.indexOf(file);

    if (index === -1) return;

    fileList.splice(index, 1);
    uploadStatusesList.splice(index, 1);
    setFiles(fileList);
    setUploadStatuses(uploadStatusesList);

    if (!fileList.length) handleModalClose();
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const statusList = [] as any;
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];

        if (!uploadedFiles) return;
        const uploadedSize = checkUploadedSize(
          uploadedFiles,
          MAX_ATTACHMENT_SIZE
        );
        if (uploadedSize.remaningSize < file.size) {
          errorToast({ title: EXCEEDED_MESSAGE });
          return;
        }

        statusList.push({
          status: statuses.WAITING,
          sizeText: formatBytes(file.size, 2),
          progress: 0,
          currentUpload: 0,
        });

        setUploadStatuses([...uploadStatuses, ...statusList]);
        setFiles([...files, ...acceptedFiles]);
      }
    },
    [files]
  );

  const uploadFiles = async () => {
    let fileIndex = 0;
    const itemFiles = [...files];
    let itemStatuses = [...uploadStatuses];
    const uploadedItems: any = [];

    await Promise.allSettled(
      itemFiles.map(async (file: File) => {
        const uploadedFile = await uploadAttachmentWithProgress(
          file,
          (options: StatusOptions) => {
            const { status, currentUpload, progress } = options;
            itemStatuses = [...itemStatuses];
            const currentFileStatus = itemStatuses[fileIndex];

            // update status and progress
            itemStatuses[fileIndex] = {
              ...currentFileStatus,
              status: status || currentFileStatus.status,
              currentUpload: currentUpload || currentFileStatus.currentUpload,
              progress: progress || currentFileStatus.progress,
            };

            setUploadStatuses(itemStatuses);
          }
        );
        if (uploadedFile) {
          uploadedItems.push({
            ...uploadedFile,
            size: file.size,
            id: `attachments-${Math.floor(Math.random() * 1000)}`,
          });
        }
        fileIndex += 1;
        return;
      })
    );

    if (setUploadedFiles && uploadedFiles)
      setUploadedFiles([...uploadedFiles, ...uploadedItems]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFormats.join(', '),
  });

  const getStatusSize = (fileData: any) => {
    if (fileData.status === statuses.UPLOADING) {
      return `${fileData.currentUpload} / ${fileData.sizeText}`;
    }

    return fileData.sizeText;
  };

  const shouldShowPreview =
    files.filter((i, ind) => uploadStatuses[ind].status === statuses.WAITING)
      .length === files.filter(i => i !== undefined).length;

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={
        uploadingFiles
          ? `${numberOfUploadedFiles} / ${
              files.length
            } files uploading (${formatBytes(sumOfFiles, 2)})`
          : 'Upload files'
      }
    >
      <div ref={ref}>
        <>
          <FileContent>
            {files
              .filter(i => i !== undefined)
              .map((file, index) => (
                <FileItem key={index}>
                  <FileDescription>
                    <FileTitle title={file.name} />
                    {!shouldShowPreview && (
                      <>
                        <FileProgress
                          max={100}
                          progress={uploadStatuses[index].progress}
                        />
                        <FileDetails>
                          <FileStatus
                            color={getStatusColor(uploadStatuses[index].status)}
                          >
                            {getStatusText(uploadStatuses[index].status)}
                          </FileStatus>
                          <FileSize>
                            {getStatusSize(uploadStatuses[index])}
                          </FileSize>
                        </FileDetails>
                      </>
                    )}
                  </FileDescription>
                  <FileAction>
                    {shouldShowPreview && (
                      <Button
                        onClick={() => deleteFromList(file)}
                        icon={<CloseIcon />}
                        text=''
                        variant='ghost'
                      />
                    )}
                  </FileAction>
                </FileItem>
              ))}
          </FileContent>
          <Footer>
            <div style={{ alignSelf: 'center' }} {...getRootProps()}>
              {shouldShowPreview && (
                <>
                  <input {...getInputProps()} />
                  <Label>+ Add more files</Label>
                </>
              )}
            </div>

            <div>
              <Button
                variant='secondary'
                text='Cancel'
                onClick={() => handleModalClose()}
              />
              <Button
                text='Upload'
                onClick={uploadFiles}
                disabled={!shouldShowPreview}
              />
            </div>
          </Footer>
        </>
      </div>
    </NewModal>
  );
};

export default UploadProgressModal;
