import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  MdSubtitles,
  MdPowerSettingsNew,
  MdPause,
  MdPlayArrow,
} from 'react-icons/md';
import { useHistory } from 'react-router';
import { ModalTeleprompterRecord } from 'lib/components/modal/ModalTeleprompterRecord';
import { LoadingIndicator, Dropdown } from 'lib/components';
import { RecordCam } from './recordCam';
import { Upload } from '../../Upload';
import MovieIcon from 'lib/images/MovieIcon';
import {
  VirtualBackgroundImages,
  VirtualBackgrounds,
} from '../../virtualBackgrounds/VirtualBackgrounds';
import VirtualBackgroundIcon from 'lib/images/VirtualBackgroundIcon';
import { useAuth } from 'lib/context';
import { DetailedVideoPreview } from 'app/pages/video/videoDetails/main/DetailedVideoPreview';
import { Scroller } from 'lib/components/Scroller';
import { animateScroll } from 'react-scroll';
import { handleScriptSpeedUsingScriptSize } from 'lib/const';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { decodeAndReplaceText, getTitle } from 'lib/utils/functions';
import { DEFAULT_UPLOAD_FOLDER } from 'lib/components/videoUpload/VideoUpload';
import { Option } from 'lib/components/dropdown/Dropdown';
import { SHARED_FOLDER_PREFIX } from 'lib/const/Folders';
import { DropItem } from 'lib/api/droplr/types';
import { Button } from 'react-covideo-common';
import { getDrop } from 'lib/api/droplr/getDrop';
import { useCustomVirtualBackgroundQuery } from 'lib/api/customVirtualBackground/useCustomVirtualBackgroundQuery';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { isEmpty } from 'lodash';

const MainDiv = styled.div`
  min-height: 630px;
  display: block;
  margin-top: 64px;
  .Tooltip-Wrapper {
    .Tooltip-Tip.top {
      top: calc(var(--tooltip-margin) * 1.5) !important;
      left: 0px !important;
    }
  }
`;

const Content = styled.div`
  padding: 32px 20px 112px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 770px;
  height: 434px;
  border-radius: 8px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  video {
    border-radius: 8px;
  }
`;

const ContentHeader = styled.div`
  width: 770px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 32px;
`;

const TeleprompterWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #9297a2;
  > svg {
    margin-right: 10px;
  }
  > button {
    margin-left: 16px;
    font-weight: 600;
  }
  > button:last-child {
    margin-right: 0;
  }
  > button:nth-of-type(2) {
    margin-left: 8px;
  }
`;

//@ts-ignore
const isFirefox = typeof InstallTrigger !== 'undefined';

const VirtualBackgroundButton = styled.button.attrs(
  (props: { isDisabledBtn: boolean }) => props
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 8px;
  width: 191px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  border: none;
  justify-content: space-between;
  z-index: 150;
  cursor: ${props => (props.isDisabledBtn ? 'pointer' : 'not-allowed')};
  opacity: ${props => (props.isDisabledBtn ? 1 : 0.7)};
`;

const Teleprompter = styled.div`
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  z-index: 2;
  border-radius: 8px;
  padding: 32px 128px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  position: absolute;
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  .marquee-container {
    width: 500px;
  }
  .marquee-container:not(.horizontal)::before {
    background: transparent;
  }
  .marquee-container:not(.horizontal)::after {
    background: transparent;
  }
`;
const TeleprompterShadow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  &.top {
    top: 0;
    bottom: 55%;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0.54) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0) 100%
    );
  }
  &.bottom {
    top: 30%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0.54) 100%
    );
  }
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

const DropdownContainer = styled.div`
  width: 180px;
  margin-right: 12px;
`;

type VirtualBackgroundTypes = 'none' | 'default' | 'custom';

type PrivacyTypes = 'PUBLIC' | 'PRIVATE' | 'TEAM';

type VirtualBackground = {
  url: string; // required in case of custom backgrounds
  type: VirtualBackgroundTypes;
  index: number; // required in case of default backgrounds
  privacy: PrivacyTypes; // required in case of custom backgrounds
  id: string; // required in case of custom backgrounds
};

type Props = {
  dispatch?: any;
  loading?: boolean;
  handleShowRecordHome?: () => void;
  handleRecordingBlobReady?: (url: string) => void;
};

export const Main = ({
  loading = false,
  handleShowRecordHome,
  handleRecordingBlobReady,
}: Props) => {
  const history = useHistory();
  const [title, setTitle] = useState(0);
  const [recording, setRecording] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState('');
  const [showVirtualBackgrounds, setShowVirtualBackgrounds] =
    React.useState(false);
  const [recordingBlobUrl, setRecordingBlobUrl] = React.useState('');
  const [attributeValues, setAttributeValues] = React.useState({});
  const [videoRequestData, setVideoRequestData] = React.useState({});
  const [videoTags, setVideoTags] = React.useState([]);
  const [continueToSendShare, setContinueToSendShare] = React.useState(false);
  const { userData, whitelabel } = useAuth();
  const videoTitle = getTitle();

  const { data: customVirtualBackgrounds, isLoading: isCustomVBLoading } =
    useCustomVirtualBackgroundQuery();
  const [useTeleprompter, setUseTeleprompter] = useState(false);
  const [showTelepromoterBtn, setShowTelepromoterBtn] = useState(true);
  const [showTeleprompterModal, setShowTeleprompterModal] = useState(false);
  const [isTeleprompterDisabled, setTeleprompterDisabled] = useState(false);
  const [settings, setSettings] = React.useState<any>({});
  const [selectedVirtualBackground, setSelectedVirtualBackground] =
    React.useState<VirtualBackground>({
      url: '',
      type: 'none',
      index: -1,
      privacy: 'PUBLIC',
      id: '',
    });

  const [isLoading, setIsLoading] = React.useState(true);

  const [showVirtualBackgroundButton, setShowVirtualBackgroundButton] =
    React.useState(true);

  const [isVBEnabled, setIsVBEnabled] = React.useState(false);

  const showTeleprompter = [
    WHITELABEL_NAME.COVIDEO,
    WHITELABEL_NAME.APP_2_VID,
    WHITELABEL_NAME.PRO_VIDEO_NOW,
  ].includes(whitelabel.name);

  const scrollRef = React.useRef(null);
  const [isTeleprompterPlaying, setIsTeleprompterPlaying] =
    React.useState(false);
  const [selectedFolderOption, setSelectedFolderOption] = useState<Option>(
    DEFAULT_UPLOAD_FOLDER
  );
  let interval: any = '';

  const saveSettingsToStorage = (data: any) => {
    localStorage.setItem('TeleprompterSettings', JSON.stringify(data));
  };

  const fetchSettingsFromStorage = useCallback(() => {
    const settingsFromStorage = localStorage.getItem('TeleprompterSettings');
    let localStorageData = [];
    try {
      localStorageData =
        settingsFromStorage !== null ? JSON.parse(settingsFromStorage) : [];
    } catch (error) {
      console.log('error: ', error);
    }
    return localStorageData;
  }, []);

  useEffect(() => {
    if (userData?.user?.packageDetails?.id) {
      const status = !checkIfFeatureIsEnabled(
        userData,
        productFeature.TELEPROMPTER
      );
      setTeleprompterDisabled(status);
    }
  }, []);

  useEffect(() => {
    setSettings(fetchSettingsFromStorage());
  }, [fetchSettingsFromStorage]);

  const saveSettings = (s: any) => {
    setSettings(s);
    saveSettingsToStorage({ ...s, activeScriptId: '', content: '' });
    setShowTeleprompterModal(false);
  };

  const handleUpload = async (attributeValuesObject: any, tags: any) => {
    let file = await fetch(recordingBlobUrl || '').then((r: any) => r.blob());
    setFile(file);
    setFileUrl(recordingBlobUrl);
    setAttributeValues(attributeValuesObject || {});
    setVideoTags(tags || []);
    setUploading(true);
    setRecordingBlobUrl('');
  };

  React.useEffect(() => {
    const enabledVB =
      (whitelabel.name == WHITELABEL_NAME.COVIDEO ||
        whitelabel.name == WHITELABEL_NAME.APP_2_VID) &&
      !isFirefox;
    setIsVBEnabled(enabledVB);

    if (enabledVB) {
      const savedVB: VirtualBackground = getVirtualBackgroundFromStorage();
      if (
        savedVB &&
        savedVB.type === 'default' &&
        savedVB.index >= 0 &&
        VirtualBackgroundImages[savedVB.index]
      ) {
        savedVB.url = VirtualBackgroundImages[savedVB.index];
        setIsLoading(false);
        setSelectedVirtualBackground(savedVB);
      } else if (savedVB.type === 'custom' && savedVB.id) {
        getDrop(savedVB.id)
          .then((dropDetails: DropItem | undefined) => {
            if (dropDetails && dropDetails.previewMedium) {
              savedVB.url = dropDetails.previewMedium || '';
            }
            setIsLoading(false);
            setSelectedVirtualBackground(savedVB);
          })
          .catch(() => {
            setIsLoading(false);
            setSelectedVirtualBackground(savedVB);
          });
      } else {
        setIsLoading(false);
        setSelectedVirtualBackground(savedVB);
      }
    } else {
      setIsLoading(false);
    }
  }, [whitelabel, isCustomVBLoading]);

  React.useEffect(() => {
    if (isVBEnabled) saveVirtualBackgroundToStorage();
  }, [selectedVirtualBackground.url]);

  React.useEffect(() => {
    if (isTeleprompterPlaying) {
      if (interval) clearInterval(interval);
      interval = setInterval(play, settings.speed.value);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isTeleprompterPlaying, settings.speed]);

  React.useEffect(() => {
    if (useTeleprompter) {
      if (window.Intercom) {
        window.Intercom('trackEvent', 'teleprompter-used');
      }
    }
  }, [useTeleprompter]);

  React.useEffect(() => {
    if (userData.user?.packageDetails?.id == 0) {
      setShowVirtualBackgroundButton(true);
    } else if (userData.user?.packageDetails?.products) {
      const status = checkIfFeatureIsEnabled(
        userData,
        productFeature.VIRTUAL_BACKGROUNDS
      );
      setShowVirtualBackgroundButton(status);
    }
  });

  const getVirtualBackgroundFromStorage = () => {
    const vbSelection = localStorage.getItem('covideoVirtualBackground') || '';
    try {
      const savedVirtualBackground: VirtualBackground = JSON.parse(vbSelection);
      return savedVirtualBackground;
    } catch (ex) {
      return { url: '', type: 'default', index: -1 } as VirtualBackground;
    }
  };

  const saveVirtualBackgroundToStorage = () => {
    localStorage.setItem(
      'covideoVirtualBackground',
      JSON.stringify(selectedVirtualBackground)
    );
  };

  const play = () => {
    animateScroll.scrollMore(20, {
      containerId: 'teleprompter',
      duration: 50,
      smooth: true,
      offset: 50,
    });
  };

  const handleGoToRecordHome = () => {
    if (!handleShowRecordHome) {
      history.push('/record/Record_Home');
      return;
    }
    handleShowRecordHome();
  };

  const handleRecordingUrlGeneration = (url: string) => {
    if (handleRecordingBlobReady) {
      handleRecordingBlobReady(url);
      return;
    }
    setRecordingBlobUrl(url);
  };

  return (
    <MainDiv className='recordOptionWrapper'>
      <Content>
        {title !== 2 ? (
          <ContentHeader>
            {!recordingBlobUrl && showTelepromoterBtn && !recording && (
              <Button
                icon={<MovieIcon opacity={1} />}
                variant='secondary'
                text={'Change recording option'}
                onClick={handleGoToRecordHome}
              />
            )}
            {showTeleprompter && (
              <>
                {recording && useTeleprompter ? (
                  <Actions>
                    <DropdownContainer>
                      <Dropdown
                        placeholder={'Set teleprompter speed'}
                        value={settings.speed}
                        onChange={newValue => {
                          setIsTeleprompterPlaying(false);
                          setSettings({ ...settings, speed: newValue });
                          setIsTeleprompterPlaying(true);
                        }}
                        options={handleScriptSpeedUsingScriptSize(
                          settings.scriptSize
                        )}
                        height={40}
                        zIndexProp={9999}
                        menuZIndexProp={9}
                      />
                    </DropdownContainer>
                    <Button
                      text={`${
                        isTeleprompterPlaying ? 'Pause' : 'Play'
                      } Teleprompter`}
                      icon={
                        isTeleprompterPlaying ? (
                          <MdPause size={20} />
                        ) : (
                          <MdPlayArrow size={20} />
                        )
                      }
                      onClick={() => {
                        isTeleprompterPlaying
                          ? setIsTeleprompterPlaying(false)
                          : setIsTeleprompterPlaying(true);
                      }}
                    />
                  </Actions>
                ) : (
                  <>
                    {showTelepromoterBtn && !recording ? (
                      <TeleprompterWrapper>
                        <MdSubtitles color='#9297A2' size={20} />
                        <div>Teleprompter</div>
                        {isTeleprompterDisabled ? (
                          <ProductInfoTooltip
                            productFeatureId={productFeature.TELEPROMPTER}
                            style={{
                              top: '40px',
                              right: '0',
                              left: '-72px',
                            }}
                          >
                            <Button
                              variant='secondary'
                              disabled={isTeleprompterDisabled}
                              text={useTeleprompter ? 'Edit' : 'Turn on'}
                              onClick={() => {
                                if (!isTeleprompterDisabled) {
                                  setUseTeleprompter(true);
                                  setShowTeleprompterModal(true);
                                }
                              }}
                            />
                          </ProductInfoTooltip>
                        ) : (
                          <Button
                            variant='secondary'
                            disabled={isTeleprompterDisabled}
                            text={useTeleprompter ? 'Edit' : 'Turn on'}
                            onClick={() => {
                              if (!isTeleprompterDisabled) {
                                setUseTeleprompter(true);
                                setShowTeleprompterModal(true);
                              }
                            }}
                          />
                        )}
                        {useTeleprompter && (
                          <>
                            <Button
                              title='Turn Off Teleprompter'
                              icon={<MdPowerSettingsNew size={20} />}
                              onClick={() => {
                                setUseTeleprompter(false);
                              }}
                              variant='destructive'
                            />
                          </>
                        )}
                      </TeleprompterWrapper>
                    ) : null}
                  </>
                )}
              </>
            )}
          </ContentHeader>
        ) : null}
        <LoadingIndicator
          isLoading={loading || isLoading || isCustomVBLoading}
        />
        {!loading && !isLoading && !!recordingBlobUrl && (
          <div>
            <DetailedVideoPreview
              videoSource={recordingBlobUrl}
              title='Preview recorded video'
              handleUpload={handleUpload}
              videoTitle={videoTitle}
              videoRequestData={videoRequestData}
              onChangeVideoRequestData={setVideoRequestData}
              handleContinueToSendShare={setContinueToSendShare}
              folderValue={selectedFolderOption}
              setFolderValue={setSelectedFolderOption}
              handleAttributeChange={(values?: object) => {
                if (values) setAttributeValues(values);
              }}
            />
          </div>
        )}
        {!loading && !isLoading && !recordingBlobUrl && (
          <div style={{ position: 'relative' }}>
            <Container>
              {!uploading && (
                <>
                  {useTeleprompter && (
                    <>
                      <TeleprompterShadow className={settings.scriptPosition} />
                      <Teleprompter
                        ref={scrollRef}
                        id='teleprompter'
                        style={{
                          top:
                            settings.scriptPosition === 'top' ? '0px' : '40%',
                          height: '45%',
                        }}
                      >
                        <Scroller
                          content={decodeAndReplaceText(settings.content || '')}
                          fontSize={settings.scriptSize}
                        ></Scroller>
                      </Teleprompter>
                    </>
                  )}
                  <RecordCam
                    onRecordingStart={() => {
                      setTitle(1);
                      setRecording(true);
                      if (useTeleprompter) setIsTeleprompterPlaying(true);
                    }}
                    onRecordingEnd={() => {
                      setTitle(2);
                      setRecording(false);
                      setIsTeleprompterPlaying(false);
                      setSettings({});
                      setUseTeleprompter(false);
                      setShowTelepromoterBtn(false);
                    }}
                    useTeleprompter={useTeleprompter}
                    setIsTeleprompterPlaying={setIsTeleprompterPlaying}
                    isVBEnabled={isVBEnabled}
                    onUpload={handleUpload}
                    uploadDisabled={!videoTitle}
                    virtualBackgroundUrl={selectedVirtualBackground.url}
                    virtualBackgroundType={selectedVirtualBackground.type}
                    virtualBackgroundPrivacy={selectedVirtualBackground.privacy}
                    showRecordingButton={!showVirtualBackgrounds}
                    onRecordingUrlGeneration={(url: string) =>
                      handleRecordingUrlGeneration(url)
                    }
                    setShowVirtualBackgroundButton={
                      setShowVirtualBackgroundButton
                    }
                    handleGoToRecordHome={handleGoToRecordHome}
                  />
                </>
              )}
              {uploading && file && fileUrl && (
                <Upload
                  file={file}
                  fileName={
                    !isEmpty(attributeValues)
                      ? attributeValues[
                          'covideoName' as keyof typeof attributeValues
                        ]
                      : videoTitle
                  }
                  videoAttributesValues={attributeValues}
                  videoTags={videoTags}
                  videoRequestData={videoRequestData}
                  continueToSendShare={continueToSendShare}
                  selectedFolder={
                    !!selectedFolderOption?.access
                      ? `${SHARED_FOLDER_PREFIX}${selectedFolderOption.value}`
                      : selectedFolderOption?.label
                  }
                />
              )}
            </Container>
            <div
              style={{
                position: 'absolute',
                bottom: '25px',
                right: '20px',
                height: 'auto',
                width: 'auto',
                zIndex: 150,
                cursor: 'pointer',
              }}
            >
              {isVBEnabled && !recording && !uploading && (
                <ProductInfoTooltip
                  hideToolTip={showVirtualBackgroundButton}
                  style={{ left: '400px' }}
                  productFeatureId={productFeature.VIRTUAL_BACKGROUNDS}
                >
                  <VirtualBackgroundButton
                    onClick={() => {
                      showVirtualBackgroundButton &&
                        setShowVirtualBackgrounds(!showVirtualBackgrounds);
                    }}
                    isDisabledBtn={showVirtualBackgroundButton}
                  >
                    <VirtualBackgroundIcon height={'20px'} width='20px' />
                    Virtual Backgrounds
                  </VirtualBackgroundButton>
                </ProductInfoTooltip>
              )}
            </div>
          </div>
        )}

        {isVBEnabled && !recordingBlobUrl && (
          <div
            style={{
              minWidth: '800px',
              width: 'fit-content',
              marginTop: '25px',
            }}
            hidden={!showVirtualBackgrounds || recording}
          >
            <VirtualBackgrounds
              selectedVirtualBackgroundUrl={selectedVirtualBackground.url}
              customVirtualBackgrounds={customVirtualBackgrounds}
              onMainButtonClick={() => {
                setShowVirtualBackgrounds(false);
              }}
              onSelect={(
                url: string = '',
                type: VirtualBackgroundTypes,
                index: number,
                privacy: PrivacyTypes = 'PUBLIC',
                id: string = ''
              ) => {
                setSelectedVirtualBackground({ url, type, index, privacy, id });
              }}
            />
          </div>
        )}
      </Content>
      {showTeleprompterModal && (
        <ModalTeleprompterRecord
          handleModalClose={() => {
            setShowTeleprompterModal(false);
            setUseTeleprompter(false);
          }}
          handleSubmit={saveSettings}
          settings={settings || {}}
        />
      )}
    </MainDiv>
  );
};
