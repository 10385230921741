import React from 'react';
import { CDS_CATEGORIES, INTEGRATIONS_CATEGORIES } from '../main/constants';
import { FilterOption } from './FilterOption';
import { FilterContainer, OptionsWrapper, Sidebar } from './style';
import { useAuth } from 'lib/context';

interface SidebarFilterProps {
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFilters: string[];
  resetFilters: () => void;
}

export const SidebarFilter = ({
  setSelectedFilters,
  selectedFilters,
}: SidebarFilterProps) => {
  const {
    userData: { isAutomotive },
  } = useAuth();
  const options = isAutomotive
    ? { ...INTEGRATIONS_CATEGORIES, ...CDS_CATEGORIES }
    : INTEGRATIONS_CATEGORIES;
  return (
    <Sidebar>
      <FilterContainer>
        <OptionsWrapper>
          <FilterOption
            filterOptions={Object.values(options)}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            filterTypeText='CATEGORY'
          />
        </OptionsWrapper>
      </FilterContainer>
    </Sidebar>
  );
};
