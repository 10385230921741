import { NewModal } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Formik, Form, FormikValues } from 'formik';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { useDeleteGuidesMutation } from 'lib/api/guides/deleteGuidesMutation';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  resetSelection?: () => void;
  selectedGuides?: number[];
  search?: string;
  guideCategoryId: number;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  availableGuides?: number;
}

export const DeleteGuideModal = ({
  closeModalHandler,
  selectedGuides,
  resetSelection,
  search,
  guideCategoryId,
  page,
  setPage,
  availableGuides,
}: IProps) => {
  const resetAndCloseModal = () => {
    !!resetSelection && resetSelection();
    closeModalHandler();
  };

  const { mutateAsync, isLoading } = useDeleteGuidesMutation(
    resetAndCloseModal,
    search ? search : '',
    guideCategoryId,
    page,
    setPage,
    availableGuides
  );
  const onSubmitHandler = (values: FormikValues) => {
    mutateAsync({ videoIds: values.videoIds });
  };

  return (
    <NewModal
      headerText='Delete Video Guide'
      closeModal={closeModalHandler}
      style={{
        content: { maxHeight: 170, height: 170, width: 592, maxWidth: 592 },
      }}
    >
      <Formik
        initialValues={{ videoIds: selectedGuides }}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                m='0 0 16px 0'
              >
                <ParagraphNormal>
                  Are you sure you want to delete this video guide
                  {selectedGuides && selectedGuides.length > 1 ? 's' : ''}? This
                  action can’t be undone.
                </ParagraphNormal>
              </Gap>

              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  onClick={async () => {
                    closeModalHandler();
                  }}
                  variant='secondary'
                  disabled={isLoading}
                  text='No, Keep'
                />
                <Button
                  disabled={isLoading}
                  text={isLoading ? 'Deleting...' : 'Yes, Delete'}
                  variant='primary'
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
