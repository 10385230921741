import styled from 'styled-components/macro';
import { ReactComponent as AddBoxSvg } from './add-box-icon.svg';

const AddBoxIcon = styled(AddBoxSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default AddBoxIcon;
