import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import CloseIcon from 'lib/images/CloseIcon';
import { Modal } from 'lib/components';
import { useHistory } from 'react-router-dom';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 24px 0 32px 0;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${theme.palette.gray100};
  line-height: 20px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
`;

const OrangeButton = styled.div`
  padding: 8px 16px;
  background: ${props => props.theme.colors.primary[100]};
  color: ${theme.palette.white};
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`;

type Props = {
  addOn: string;
  handleModalClose: () => void;
};

const UPGRADE_PATH = '/profile/upgrade-account';

export const ModalActivateSub = ({ addOn, handleModalClose }: Props) => {
  const history = useHistory();

  const title = 'Activate subscription to enable add-on';
  const text =
    'To be able to activate this add-on, you first need to subscribe to a monthly or annual Covideo plan.';
  const addOnText = 'Thank you for your interest in ';
  const buttonText = 'View Subscription Options';

  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          {title}
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <Text>
            {addOnText}
            <b>“{addOn}</b>”!
          </Text>
          <Text>{text}</Text>
        </Content>
        <ButtonsWrapper>
          <OrangeButton onClick={() => history.push(UPGRADE_PATH)}>
            {buttonText}
          </OrangeButton>
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
