import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import calender from 'lib/images/icon-calendar.svg';
import CloseIcon from 'lib/images/CloseIcon';
import { Country, States } from 'lib/const';
import { AddNewCard } from 'lib/api/paymentApi';
import { defaultCardUpdate } from 'lib/api';
import Select from 'react-select';
import { Modal, LoadingIndicator } from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { MdCreditCard } from 'react-icons/md';
import { addOrUpdatePlanSubscription } from 'lib/api/paymentApi';
import { useAuth } from 'lib/context';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';
import { useRegenerateTokenMutation } from 'lib/api/auth/useRegenerateTokenMutation';

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  onSubmit?: () => void | undefined;
  billingCycle?: string;
  packageId?: number;
  packageQuantity?: number;
  packagesDictionary?: any;
};

const ModalItem = styled.div`
  min-height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;

  .StripeForm__fieldsContainer {
    background: rgba(122, 80, 199, 0.1);
    border-radius: 4px;
    padding: 10px;

    .form-group {
      margin: 0 0 15px;
    }
  }

  .StripeForm__description {
    font-size: 12px;
    padding: 4px 0;
    line-height: 16px;
    text-align: right;
    color: $gray-dark;
    margin: 20px 0;
  }

  .StripeElement {
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    padding: 10px 15px;
    background: #ffffff;
    border: 1px solid #dee3eb;
    border-radius: 4px;
    font-size: 14px;
    color: #5e646e;
    transition: all 0.2s linear;

    &:hover {
      border: 1px solid #c8cfd9;
      cursor: text;
    }
  }

  .StripeElement--focus,
  .StripeElement--focus:hover {
    box-shadow: inset 0 0 0 3px rgba(122, 80, 199, 0.1);
    border-color: ${props => props.theme.colors.primary[100]};
    color: #000;
  }

  .StripeForm {
    button {
      float: left;
      margin-right: 10px;
    }

    .StripeElement#card-number {
      padding: 13px 15px 13px 10px;
      width: 280px;
    }

    .StripeElement#card-expiry {
      width: 120px;
      padding: 13px 15px 13px 50px;
      background: #ffffff url(${calender}) 15px center no-repeat;
    }

    .StripeElement#card-cvc {
      width: 100px;
      padding: 13px 15px 13px 10px;
      background: #ffffff;
    }

    .StripeElement#postal-code {
      width: 100px;
      padding: 13px 15px;
    }
  }

  .form-group {
    margin: 5px 0;
    min-height: 40px;
  }

  .form-group {
    label,
    .form-group__label {
      display: block;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 5px;
      color: rgba(146, 151, 162, 1);
      &.dark--blue {
        color: ${theme.palette.primaryDarkBlue};
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .form-group span {
    display: inline-block;
    margin-top: 22px;

    &.small {
      color: #5e646e;
      font-size: 12px;

      a {
        color: #5e646e;
        text-decoration: underline;
        font-weight: normal;
      }
    }
  }

  .form-group.has-error input,
  .has-error input {
    background: #ffffff url('/dist/images/invalid.svg') calc(100% - 14px) center
      no-repeat;
    border: 1px solid #f94c4c;
  }

  .form-group.has-success input,
  .has-success input {
    border: 1px solid #008000;
  }

  .form-group.has-error input:focus,
  .has-error input:focus {
    background-position: calc(100% - 12px) center;
  }

  .form-group.has-success input:focus,
  .has-success input:focus {
    background-position: calc(100% - 12px) center;
  }

  .form-group div.help-block {
    position: absolute;

    span {
      font-size: 12px;
      font-weight: 700;
      color: #c8cfd9;
      margin: 0;
    }
  }

  .form-group.has-error div.help-block span {
    color: #f94c4c;
  }

  .form-group.has-success div.help-block span {
    color: #008000;
  }
`;

const FormWrapper = styled.div``;

const StripeUI = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f6f7f9;
  padding: 0px 20px 20px 20px;
`;

const InternalUI = styled.div`
  padding-top: 20px;
  .calendar--icon {
    position: absolute;
    top: 6px;
    left: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
`;

const Input = styled.input<{ width?: string; padding?: string }>`
  padding: ${props => (props.padding ? props.padding : '8px 12px')};
  box-sizing: border-box;
  width: ${props => (props.width ? props.width : '280px')};
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c8cfd9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const CloseButtonWrap = styled.div`
  margin-left: 10px;
  color: ${theme.palette.black_1_100};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .default {
    display: flex;
    align-items: center;
  }
`;

enum Views {
  ADD_CARD = 1,
  LOADING = 2,
}

export const AddCardModal = ({
  handleModalClose,
  onSubmit = undefined,
  billingCycle = '',
  packageId = 0,
  packageQuantity = 1,
  packagesDictionary = undefined,
}: Props) => {
  const { mutateAsync: regenerateJwtToken } = useRegenerateTokenMutation();
  const [currentView, setCurrentView] = useState<Views>(Views.ADD_CARD);
  const [cardData, setCardData] = useState<any>({
    holderName: '',
    billingAddress: '',
    postCode: '',
    city: '',
    state: '',
    country: 'US',
    cardNumber: '',
    expiry: '',
    cvc: '',
  });

  const [errors, setErrors] = useState<any>({});
  const [reRenderState, setReRenderState] = useState<any>(true);
  const [makeDefaultCard, setMakeDefaultCard] = useState<any>(false);
  const { showError } = useToastError();
  const { setJwtAndUpdateToken } = useAuth();

  const handleErrors = (event: any, param?: string | number) => {
    setErrors({ ...errors, [param ? param : event.elementType]: '' });
    if (event.error && !param) {
      setErrors({ ...errors, [event.elementType]: event.error.message });
    } else {
      if (event.length && param) {
        setCardData({ ...cardData, [param]: event });
      } else if (param) {
        setCardData({ ...cardData, [param]: '' });
        setErrors({ ...errors, [param]: 'Invalid input' });
      }
    }
  };

  const handleCardInput = (e: any, param?: string) => {
    if (param == 'cardNumber') {
      if (
        /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})|(62[0-9]{14,17})|((5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}))$/.test(
          e
        )
      ) {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: 'Invalid card number' });
      }
    }
    if (param == 'expiry') {
      const format = new Date().getFullYear().toString().substr(2).split('');
      const split1 = parseInt(format[0]);
      const expDateFormatter =
        e.replace(/\//g, '').substring(0, 2) +
        (e.length > 2 ? '/' : '') +
        e.replace(/\//g, '').substring(2, 4);
      if (
        new RegExp(`^(0[1-9]|1[0-2])\/?([` + split1 + `-9][0-9])$`).test(
          expDateFormatter
        )
      ) {
        setCardData({ ...cardData, [param]: expDateFormatter });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: expDateFormatter });
        setErrors({ ...errors, [param]: 'Invalid expiry date' });
      }
    }
    if (param == 'cvc') {
      if (e.length > 2 && e.length < 5) {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: 'Invalid cvc' });
      }
    }
  };

  const handleAddCardAndPay = async () => {
    try {
      setCurrentView(Views.LOADING);
      const {
        holderName,
        billingAddress,
        postCode,
        city,
        state,
        country,
        cardNumber,
        expiry,
        cvc,
      } = cardData;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc,
        billingCountry: country,
        billingPostalCode: postCode,
        billingAddress,
        billingCity: city,
        billingState: state,
        billingName: holderName,
      };
      const response = await AddNewCard(payload);
      const subscriptionPayload = {
        paymentId: response.paymentId,
        interval: billingCycle,
        quantity: packageQuantity,
        packageId,
        makeDefaultCard,
      };
      await addOrUpdatePlanSubscription(subscriptionPayload);
      const tokens = await regenerateJwtToken();
      setJwtAndUpdateToken(tokens);

      if (response) {
        successToast({
          title: packagesDictionary
            ? `Card added and ${packagesDictionary[packageId].title} is successfully activated!`
            : `Card added and plan is successfully activated!`,
        });
        handleModalClose(true);
      }
    } catch (error) {
      setCurrentView(Views.ADD_CARD);
      showError(error);
    }
  };

  const handleCardAdd = async () => {
    try {
      setCurrentView(Views.LOADING);
      const {
        holderName,
        billingAddress,
        postCode,
        city,
        state,
        country,
        cardNumber,
        expiry,
        cvc,
      } = cardData;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc,
        billingCountry: country,
        billingPostalCode: postCode,
        billingAddress,
        billingCity: city,
        billingState: state,
        billingName: holderName,
      };
      const response = await AddNewCard(payload);
      if (response.paymentId && makeDefaultCard) {
        await defaultCardUpdate(response.paymentId);
      }
      if (response) {
        successToast({ title: 'Card added successfully' });
        handleModalClose(true);
      }
    } catch (error) {
      setCurrentView(Views.ADD_CARD);
      showError(error);
    }
  };

  const AddCard = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Add New Payment Method</ContentHeader>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </ContentHeaderWrap>
        <ContentBody>
          <FormWrapper className='StripeForm'>
            <InternalUI>
              <StripeUI>
                <Row>
                  <div
                    className={`form-group ${
                      errors && errors.cardNumber ? 'has-error' : ''
                    }`}
                  >
                    <label className={`control-label dark--blue`}>
                      Card Number
                    </label>
                    <div>
                      <Input
                        type='text'
                        value={cardData.cardNumber}
                        placeholder='Card Number'
                        onChange={event =>
                          handleCardInput(event.target.value, 'cardNumber')
                        }
                        width='320px'
                      />
                    </div>
                    {errors && errors.cardNumber && (
                      <div className='help-block'>
                        <span>{errors.cardNumber}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`form-group ${
                      errors && errors.expiry ? 'has-error' : ''
                    }`}
                  >
                    <label className={`control-label`}>&nbsp;</label>
                    <div style={{ position: 'relative' }}>
                      <img
                        className='calendar--icon'
                        src={calender}
                        alt='calendar'
                      />
                      <Input
                        type='text'
                        value={cardData.expiry}
                        placeholder='MM/YY'
                        onChange={event =>
                          handleCardInput(event.target.value, 'expiry')
                        }
                        width='110px'
                        padding='0px 0px 0px 40px'
                      />
                    </div>
                    {errors && errors.expiry && (
                      <div className='help-block'>
                        <span>{errors.expiry}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`form-group ${
                      errors && errors.cvc ? 'has-error' : ''
                    }`}
                  >
                    <label className={`control-label`}>&nbsp;</label>
                    <div>
                      <Input
                        type='number'
                        value={cardData.cvc}
                        placeholder='CVC'
                        onChange={event =>
                          handleCardInput(event.target.value, 'cvc')
                        }
                        width='110px'
                      />
                    </div>
                    {errors && errors.cvc && (
                      <div className='help-block'>
                        <span>{errors.cvc}</span>
                      </div>
                    )}
                  </div>
                </Row>
              </StripeUI>
              <Row>
                <div
                  className={`form-group ${
                    errors && errors.holderName ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Holder Name</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.holderName}
                      placeholder=''
                      onChange={event =>
                        handleErrors(event.target.value, 'holderName')
                      }
                    />
                  </div>
                  {errors && errors.holderName && (
                    <div className='help-block'>
                      <span>{errors.holderName}</span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors && errors.billingAddress ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Billing Address</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.billingAddress}
                      placeholder=''
                      onChange={event =>
                        handleErrors(event.target.value, 'billingAddress')
                      }
                    />
                  </div>
                  {errors && errors.billingAddress && (
                    <div className='help-block'>
                      <span>{errors.billingAddress}</span>
                    </div>
                  )}
                </div>
              </Row>
              <Row>
                <div
                  className={`form-group ${
                    errors && errors.city ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>City</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.city}
                      placeholder=''
                      onChange={event =>
                        handleErrors(event.target.value, 'city')
                      }
                    />
                  </div>
                  {errors && errors.city && (
                    <div className='help-block'>
                      <span>{errors.city}</span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors && errors.state ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>State</label>
                  <div>
                    {reRenderState && (
                      <Select
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            height: '40px',
                            width: '100%',
                          }),
                          indicatorSeparator: () => ({ display: 'none' }),
                          container: () => ({ width: '280px' }),
                          menuPortal: (base: any) => ({
                            ...base,
                            zIndex: 1000,
                          }),
                          option: (base: any) => ({
                            ...base,
                            borderBottom: '1px solid #EEEFF2',
                          }),
                        }}
                        options={States[cardData.country]}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        menuPlacement={'bottom'}
                        getOptionValue={(option: any) => option.name}
                        getOptionLabel={(option: any) => option.name}
                        value={States[cardData.country].find((o: any) => {
                          return o.name == cardData.state;
                        })}
                        onChange={(option: any) => {
                          setCardData({ ...cardData, state: option.name });
                        }}
                        placeholder='Select State'
                      />
                    )}
                  </div>
                  {errors && errors.state && (
                    <div className='help-block'>
                      <span>{errors.state}</span>
                    </div>
                  )}
                </div>
              </Row>
              <Row>
                <div
                  className={`form-group ${
                    errors && errors.country ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Country or a region</label>
                  <div>
                    <Select
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          height: '40px',
                          width: '100%',
                        }),
                        indicatorSeparator: () => ({ display: 'none' }),
                        container: () => ({ width: '280px' }),
                        menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
                        option: (base: any) => ({
                          ...base,
                          borderBottom: '1px solid #EEEFF2',
                        }),
                      }}
                      options={Country}
                      menuPortalTarget={document.body}
                      menuPosition='fixed'
                      menuPlacement={'bottom'}
                      getOptionValue={option => option.countryShortCode}
                      getOptionLabel={option => option.countryName}
                      value={Country.find((o: any) => {
                        return o.countryShortCode == cardData.country;
                      })}
                      onChange={(option: any) => {
                        setReRenderState(false);
                        setCardData({
                          ...cardData,
                          country: option.countryShortCode,
                          state: '',
                        });
                        setTimeout(() => setReRenderState(true));
                      }}
                      placeholder='Select Country'
                    />
                  </div>
                  {errors && errors.country && (
                    <div className='help-block'>
                      <span>{errors.country}</span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors && errors.postCode ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Postal Code</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.postCode}
                      placeholder=''
                      onChange={event =>
                        handleErrors(event.target.value, 'postCode')
                      }
                    />
                  </div>
                  {errors && errors.postCode && (
                    <div className='help-block'>
                      <span>{errors.postCode}</span>
                    </div>
                  )}
                </div>
              </Row>
            </InternalUI>
          </FormWrapper>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <div className='default'>
          <CheckboxInput
            blueCheck={true}
            checkGroupIndicator={false}
            checked={makeDefaultCard}
            onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              let { checked } = event.target as HTMLInputElement;
              if (checked) setMakeDefaultCard(true);
              else setMakeDefaultCard(false);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <div>Set as Default</div>
        </div>
        {onSubmit !== undefined && (
          <Button
            disabled={
              Object.keys(errors).some(e => errors[e].length) ||
              Object.keys(cardData).some(e => !cardData[e].length)
            }
            icon={<MdCreditCard color={theme.palette.white} />}
            text='Pay Now'
            onClick={handleAddCardAndPay}
          />
        )}
        {onSubmit === undefined && (
          <Button
            disabled={
              Object.keys(errors).some(e => errors[e].length) ||
              Object.keys(cardData).some(e => !cardData[e].length)
            }
            text={`Add Payment Method`}
            onClick={handleCardAdd}
          />
        )}
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Adding card...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.ADD_CARD:
      CurrentVievComponent = AddCard;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
