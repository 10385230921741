import * as React from 'react';

export const CopyIcon = props => {
  const { onClick, color, width, height, opacity } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        fill={color || '#001B53'}
        fillRule="evenodd"
        d="M23.625 5.518c-.25-.25-.553-.375-.91-.375h-5.572c-.536 0-1.107.178-1.714.536V1.286c0-.357-.125-.661-.375-.911S14.5 0 14.143 0H8.57c-.357 0-.75.09-1.178.268-.429.178-.768.393-1.018.643L.911 6.375c-.25.25-.465.59-.643 1.018C.089 7.822 0 8.214 0 8.57v9c0 .358.125.661.375.911s.554.375.91.375h7.286v3.858c0 .357.125.66.375.91S9.5 24 9.857 24h12.858c.357 0 .66-.125.91-.375s.375-.553.375-.91V6.429c0-.357-.125-.661-.375-.911zm-8.196 2.478V12h-4.005l4.005-4.004zM6.857 2.853v4.004H2.853l4.004-4.004zm2.625 8.665c-.25.25-.464.59-.643 1.018-.178.429-.268.821-.268 1.179v3.428H1.714V8.57h5.572c.357 0 .66-.124.91-.375.25-.25.375-.553.375-.91V1.714h5.144v5.572l-4.233 4.232zm12.803 10.768h-12v-8.572h5.572c.357 0 .66-.125.91-.375s.376-.553.376-.91V6.857h5.142v15.429z"
        opacity={opacity || 1}
      />
    </svg>
  );
};
