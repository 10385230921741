import { Dropdown, NewModal } from 'lib/components';
import React, { useCallback, useEffect, useState } from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Form, Formik, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useFreeUsersQuery } from 'lib/api/multiLocations/useFreeUsersQuery';
import { NEW_ML_USER_PREFIX } from '../const';
import {
  ChildCustomer,
  ParentCustomer,
  User,
} from 'lib/api/multiLocations/multiLocationQuery';
import { useParams } from 'react-router-dom';
import { useAddChildUserMutation } from 'lib/api/multiLocations/useAddChildUserMutation';
import { CustomUserSelectionLabel } from '../components/CustomUsersSelectionLabel';
import { Button } from 'react-covideo-common';

interface Option {
  value: number;
  label: string;
  username: string;
  lastName: string;
  firstName: string;
  email: string;
  customerId: number;
}

interface InitialValues {
  userId: Option | null;
}
interface IProps {
  closeModalHandler: () => void;
  isEditMode: boolean;
}

export const CreateOrConectUserNameModal = ({
  closeModalHandler,
  isEditMode,
}: IProps) => {
  const { values, setValues } = useFormikContext<ParentCustomer>();
  const { customerId } = useParams() as { customerId: string };
  const { mutateAsync, isLoading: isCreatingUser } =
    useAddChildUserMutation(closeModalHandler);

  const [search, setSearchText] = useState('');
  const { data, isLoading, refetch } = useFreeUsersQuery({
    search,
    customerId: values.customerId,
  });

  const debouncedInputChange = useCallback(
    debounce(inputValue => {
      setSearchText(inputValue);
    }, 300),
    []
  );

  const onInputChangeHandler = (newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  useEffect(() => {
    refetch();
  }, [search]);

  const onSubmitHandler = (formValues: InitialValues) => {
    const { userId } = formValues;
    if (userId === null) {
      return;
    }
    if (isEditMode) {
      mutateAsync({ parentCustomerId: customerId, userId: userId.value });
      return;
    }

    const childUser = (item: ChildCustomer) => ({
      id: NEW_ML_USER_PREFIX,
      customerId: item.customerId,
      parentUserId: userId.value,
      parentCustomerId: values.customerId,
      email: '',
      username: '',
      lastName: '',
      firstName: '',
    });
    const topLevel = {
      id: userId.value,
      customerId: userId.customerId,
      parentUserId: 0,
      parentCustomerId: values.customerId,
      email: userId.email,
      username: userId.username,
      lastName: userId.lastName,
      firstName: userId.firstName,
    } as User;

    const updatedRes = values.childCustomers.map(item => ({
      ...item,
      users: [...item.users, childUser(item)],
    }));

    setValues({
      ...values,
      users: [...values.users, topLevel],
      childCustomers: updatedRes,
    });

    setTimeout(() => {
      closeModalHandler();
    }, 100);
  };

  const userIDs = values.users.map(user => user.id);

  const optionsValues =
    data?.users?.map(user => ({
      value: user.id,
      label: user.email,
      username: user.username,
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      customerId: user.customerId,
    })) || [];

  return (
    <NewModal
      headerText='Create or Connect Username'
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Formik
        initialValues={{ userId: null } as InitialValues}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit, setFieldValue, values: formValues }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Dropdown
                options={optionsValues.filter(
                  option => !userIDs.includes(option.value)
                )}
                value={optionsValues.filter(
                  option => formValues?.userId?.value === option.value
                )}
                onChange={(value: Option) => {
                  setFieldValue('userId', value);
                }}
                name='userId'
                placeholder={isLoading ? '' : 'Select parent user'}
                isClearable={true}
                extendStyles={{
                  container: {
                    width: '100%',
                    margin: '10px 0 15px 0',
                  },
                }}
                isSearchable={true}
                creatable={false}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                menuPosition='absolute'
                menuPlacement='bottom'
                filterOption={null}
                isLoading={isLoading}
                disabled={isCreatingUser}
                onInputChange={onInputChangeHandler}
                formatOptionLabel={CustomUserSelectionLabel}
              />
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  text={isCreatingUser ? 'Connecting...' : 'Connect'}
                  disabled={isCreatingUser || formValues.userId === null}
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
