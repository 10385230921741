import React, { useEffect, useState } from 'react';
import { useAuth } from 'lib/context';
import { Search } from 'lib/components';
import { Content, Heading, Layout } from './style';
import { Container, Gap, MainWrapper } from 'lib/components/styles/layout';
import { checkIfFeatureIsEnabled } from 'lib/utils/productFeature';
import { IntegrationList } from '../filtering/IntegrationList';
import { SidebarFilter } from '../filtering/SidebarFilter';
import {
  DISCOVER_BY_FILTERS,
  INTEGRATION_TYPE,
  IntegrationProps,
} from './constants';
import { useIntegrationTypeFilter } from './useIntegrationFilterType';
import { useIntegrationData } from './data';
import selectors from '../../../../cypress/selectors';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

export const Integrations = () => {
  const [integrations, setIntegrations] = useState<IntegrationProps[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const { userData, whitelabel } = useAuth();

  const { INTEGRATIONS } = useIntegrationData();
  const filter = useIntegrationTypeFilter();

  const [integrationSearch, setIntegrationSearch] = React.useState('');
  const hasFilters = selectedFilters.length > 0;

  const data = userData.isAutomotive
    ? filter([INTEGRATION_TYPE.AUTOMOTIVE])
    : filter([INTEGRATION_TYPE.UNIVERSAL]);

  useEffect(() => {
    let filteredIntegrations: IntegrationProps[] = data;
    if (whitelabel?.name !== WHITELABEL_NAME.COVIDEO) {
      // Available options for Covideo whitelabel based on previous implementation
      if (whitelabel?.browserExtensionURL) {
        filteredIntegrations.push(INTEGRATIONS[0]); //Chrome
        filteredIntegrations.push(INTEGRATIONS[1]); //Gmail
      }
      if (whitelabel.outlookURL) filteredIntegrations.push(INTEGRATIONS[4]); //Outlook
      if (whitelabel.iOSURL) filteredIntegrations.push(INTEGRATIONS[8]); //iOS
      if (whitelabel.androidURL) filteredIntegrations.push(INTEGRATIONS[9]); //Android
    }

    filteredIntegrations = filteredIntegrations.map(integration => {
      if (integration.productFeatureId) {
        return {
          ...integration,
          enabled: checkIfFeatureIsEnabled(
            userData,
            integration.productFeatureId
          ),
        };
      }
      return integration;
    });

    setIntegrations(filteredIntegrations);
  }, [userData.customerId]);

  const featuredIntegrations = integrations.filter(integration => {
    return integration.categories.includes(DISCOVER_BY_FILTERS.FEATURED);
  });
  const hasFeaturedIntegrations = featuredIntegrations.length > 0;

  const isIntegrationEnabled = (integration: IntegrationProps) => {
    return integration.enabled;
  };

  const filterIntegrations = () => {
    if (!hasFilters && !integrationSearch) {
      return integrations.filter(integration => {
        return isIntegrationEnabled(integration);
      });
    }
    const filtered = integrations.filter(integration => {
      const matchesFilters = hasFilters
        ? integration.categories &&
          integration.categories.some(category =>
            selectedFilters.includes(category)
          )
        : true; // If no filters are selected, all integrations match

      const matchesSearch = integrationSearch
        ? integration.name
            .toLowerCase()
            .includes(integrationSearch.toLowerCase())
        : true; // If no search parameter is provided, all integrations match

      const isEnabled = isIntegrationEnabled(integration);

      return matchesFilters && matchesSearch && isEnabled;
    });
    return filtered;
  };

  const resetFilters = () => {
    setSelectedFilters([]);
    setIntegrationSearch('');
  };

  return (
    <Layout>
      <SidebarFilter
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        resetFilters={resetFilters}
      />
      <Container>
        <MainWrapper resetPadding>
          <Content>
            <Gap gap='40px' flexDirection='column' alignItems='start'>
              <Search
                width='292px'
                onSearch={(search: string) => setIntegrationSearch(search)}
                placeholder='Search categories and tags'
                prevSearch={integrationSearch}
                dataCy={selectors.integrationsPage.searchInput}
              />
              {!hasFilters &&
                integrationSearch === '' &&
                hasFeaturedIntegrations && (
                  <Gap gap='24px' flexDirection='column' alignItems='start'>
                    <Heading>Featured</Heading>
                    <IntegrationList integrations={featuredIntegrations} />
                  </Gap>
                )}
              <Gap gap='24px' flexDirection='column' alignItems='start'>
                <Heading>All integrations</Heading>
                <IntegrationList integrations={filterIntegrations()} />
              </Gap>
            </Gap>
          </Content>
        </MainWrapper>
      </Container>
    </Layout>
  );
};
