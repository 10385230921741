import * as React from 'react';
import styled from 'styled-components/macro';
import { MdCheck } from 'react-icons/md';

import AppDownloadImg from 'lib/images/app_download.jpg';
import AndroidDownloadImg from 'lib/images/android_download.svg';
import IosDownloadImg from 'lib/images/ios_download.svg';
import { useAuth } from 'lib/context';

const ContainerWrapper = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-style: normal;
  color: #272a32;
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
`;

const Header = styled.div`
  margin-top: 32px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const Features = styled.div`
  margin-top: 24px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  > span {
    margin-left: 15px;
  }
`;

const LinkWrapper = styled.a`
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 80px;
`;

type Props = {
  isIOS: boolean;
};

export const IncompatibleMobile = ({ isIOS = true }: Props) => {
  const { whitelabel } = useAuth();

  const features = [
    'Easier Recording and Uploading',
    'Trimming and Merging Videos',
    'SMS Messaging',
  ];

  const androidUrl =
    whitelabel.androidURL ||
    'https://play.google.com/store/apps/details?id=covideo.com&feature=nav_result#?t=W251bGwsMSwyLDNd';
  const iosUrl =
    whitelabel.iOSURL ||
    'https://itunes.apple.com/us/app/covideo/id507458417?mt=8';

  return (
    <ContainerWrapper>
      <Container>
        <p>
          Currently, our web-based recorder is only compatible with the desktop
          version of Chrome and Mozilla Firefox.
        </p>
        <Header>Download our Covideo app for the best user experience!</Header>
        <Features>
          {features.map(feature => (
            <Feature key={feature}>
              <MdCheck size={20} color='#FF8B22' />
              <span>{feature}</span>
            </Feature>
          ))}
        </Features>

        <LinkWrapper
          href={isIOS ? iosUrl : androidUrl}
          target='_blank'
          rel='noopener'
        >
          <img
            src={isIOS ? IosDownloadImg : AndroidDownloadImg}
            width={120}
            height={40}
            alt='download'
          />
        </LinkWrapper>

        <img
          src={AppDownloadImg}
          alt='Ipad preview'
          width={630}
          height={500}
          style={{ alignSelf: 'center' }}
        />
      </Container>
    </ContainerWrapper>
  );
};
