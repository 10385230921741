import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { customerKeys } from './queryKeys';

const getCustomerWebsiteOverlayStatus = async ({
  customerId,
}: {
  customerId: string;
}): Promise<boolean> => {
  const response = await EXPRESS_API.get(
    `/customers/${customerId}/website-overlay-status`
  );
  return response.data;
};

export const useCustomerWebsiteOverlayStatus = ({
  customerId,
}: {
  customerId: string;
}) => {
  return useQuery(customerKeys.customer_overlay_status(customerId), () =>
    getCustomerWebsiteOverlayStatus({ customerId })
  );
};
