import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  DocumentHead,
  LoadingIndicator,
  PageTemplate,
  Spinner,
} from 'lib/components';
import SaveIcon from 'lib/images/SaveIcon';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import { moveVideo } from 'lib/api';
import { putVideoTags } from 'lib/api/videoTagsApi';
import RouteLeavingGuard from './RouteLeavingGuard';
import {
  checkIfVideoRequestFieldsRequired,
  validateAutomotiveFields,
} from 'lib/utils/automotiveRolePermissionChecks';
import { successToast } from 'lib/components/toasts/success';
import { DefaultFolderIds, SHARED_FOLDER_PREFIX } from 'lib/const/Folders';
import { DetailedVideoPreview } from './DetailedVideoPreview';
import { Button } from 'react-covideo-common';
import { createVideoRequest } from 'lib/api/videoRequests/useCreateVideoRequestMutation';
import { putVideoAttributes } from 'lib/api/videoAttributes/useEditVideoAttributeByVideoId';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';
import _ from 'lodash';
import { updateVideo } from 'lib/api/videos/useUpdateVideoMutation';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { deleteVideo } from 'lib/api/videos/useDeleteVideoMutation';

const Content = styled.div`
  margin-top: 64px;
  padding: 32px 112px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  order: 4;
  ${theme.mediaQueryMinWidth.md} {
    width: 100%;
    order: 0;
    flex-direction: row;
    justify-content: center;
    height: 40px;
  }
  a {
    justify-content: center;
    margin: 0;
    height: 40px;
    &:nth-of-type(n + 2) {
      margin: 20px 0 0 0;
    }
    ${theme.mediaQueryMinWidth.xs} {
      width: 30vw;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 12px;
      }
    }
    ${theme.mediaQueryMinWidth.md} {
      width: 30%;
      max-width: 200px;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 24px;
      }
    }
    div {
      width: auto;
      &:nth-of-type(1) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 18px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 24px;
        }
      }
      &:nth-of-type(2) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 14px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 16px;
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: auto;
  ${theme.fontBold800}
  font-size: 24px;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${theme.palette.title};
  line-height: 1.82;
  margin-right: auto;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  .blur {
    min-height: 40px;
    line-height: 1.82;
    max-width: 360px;
    overflow: hidden;
    text-align: left !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .focus {
    min-height: 40px;
    line-height: 1.82;
    border: 1px solid ${theme.palette.secondaryBlue};
    border-radius: 4px;
    max-width: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
  }
  width: auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin-top: 0;
`;

const CountGroup = styled.div`
  display: flex;
  width: 425px;
  justify-content: space-evenly;
`;
const CountLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.primary[100]};
`;

export const ReviewUploadedFiles = (props: any) => {
  const history = useHistory();
  const [videoArray, setVideoArray] = React.useState<any[]>([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const { userData } = useAuth();
  const { isAutomotive, isAutomotiveSalesRole, isAutomotiveServiceRole } =
    userData;

  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);

  React.useEffect(() => {
    if (
      !props ||
      !props.location ||
      !props.location.state ||
      !props.location.state.videos ||
      !props.location.state.videos.length
    ) {
      setIsLoading(false);
      setIsSaved(true);
    } else {
      const videoIds = props.location.state.videos;
      Promise.all(
        videoIds.map((id: string) => {
          return getVideo(id);
        })
      )
        .then(results => {
          setVideoArray(results);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      setVideoArray([]);
      window.onbeforeunload = null;
    };
  }, []);

  React.useEffect(() => {
    if (isSaved) {
      history.push('/home');
    }
  }, [isSaved]);

  const ReviewList = () => {
    const [validFormsCount, setValidFormsCount] = React.useState(0);
    const inputEl = React.useRef<any>({});
    const [tagsObject, setTagsObject] = React.useState<any>({});
    const [automotiveValidation, setAutomotiveValidation] = React.useState<any>(
      {}
    );
    const [videoRequestData, setVideoRequestData] = React.useState<any>({});
    const [deleteVideoTracker, setDeletedVideoTracker] = React.useState<any>(
      {}
    );
    const [isSaving, setIsSaving] = React.useState(false);
    const [totalRequiredAttributeCount, setTotalRequiredAttributeCount] =
      React.useState(0);
    const { data, isLoading: areAttributesLoading } =
      useGetAllVideoAttributesQuery({
        page: 0,
        size: 200, // attempt to fetch all on the first render
        filterByDepartment: true,
      });

    const { attributes: attributesList } = data || {
      attributes: [],
      count: 0,
    };

    // set required attribute number tracker
    React.useEffect(() => {
      if (!attributesList || !videoArray.length) {
        return;
      }
      // set number of required attributes
      const totalVideoCount = videoArray.length;
      const deletedVideoCount = Object.keys(deleteVideoTracker).length;
      const finalVideoCount = Math.abs(totalVideoCount - deletedVideoCount);

      let requiredAttributesCount = attributesList.filter(
        attribute => attribute.isRequired
      ).length;

      if (isAutomotiveSalesRole && videoRequestFieldsRequired)
        requiredAttributesCount += 1;
      if (isAutomotiveServiceRole && videoRequestFieldsRequired)
        requiredAttributesCount += 2;
      setTotalRequiredAttributeCount(finalVideoCount * requiredAttributesCount);
    }, [attributesList, deleteVideoTracker, videoArray]);

    React.useEffect(() => {
      if (!videoArray || !videoArray.length) {
        return;
      }

      let validation: any = {};
      videoArray.forEach((video: any) => {
        if (isAutomotive && !deleteVideoTracker[video.id]) {
          validation[video.id] = validateAutomotiveFields(
            userData,
            videoRequestData[video.id] || {}
          );
        }
      });
      setAutomotiveValidation(validation);
    }, [videoArray, deleteVideoTracker]);

    React.useEffect(() => {
      if (!videoArray.length) {
        return;
      }

      const formRefsObject: any = inputEl.current;
      const actions = [];
      if (formRefsObject && Object.keys(formRefsObject).length) {
        for (const key in formRefsObject) {
          if (formRefsObject[key] && !deleteVideoTracker[key]) {
            actions.push(formRefsObject[key].validateForm);
          }
        }

        Promise.all(actions.map(async action => await action())).then(
          results => {
            let validCount = 0;
            results.forEach(result => {
              if (isEmpty(result)) {
                ++validCount;
              }
            });
            setValidFormsCount(validCount);
          }
        );
      }
    }, [videoArray, automotiveValidation, attributesList, deleteVideoTracker]);

    React.useEffect(() => {
      if (areAttributesLoading || isLoading) {
        return;
      }

      if (videoArray.length === Object.keys(deleteVideoTracker).length) {
        setIsSaved(true);
      }
    }, [areAttributesLoading, isLoading, deleteVideoTracker, videoArray]);

    const onAttributeChangeHandler = async () => {
      const formRefsObject: any = inputEl.current;

      if (formRefsObject && Object.keys(formRefsObject).length) {
        let validCount = 0;
        for (const key in formRefsObject) {
          let errors: any = {};
          try {
            if (formRefsObject[key]) {
              errors = await formRefsObject[key].validateForm();
            }
          } catch (ex) {}

          if (isEmpty(errors)) {
            ++validCount;
          }

          if (
            validCount >
            videoArray.length - Object.keys(deleteVideoTracker).length
          ) {
            validCount =
              videoArray.length - Object.keys(deleteVideoTracker).length;
          }

          setValidFormsCount(validCount);
        }
      }
    };

    const tagsUpdateHandler = (id: string, videoTags: any) => {
      const newTagsObject: any = { ...tagsObject };
      newTagsObject[id] = videoTags;
      setTagsObject(newTagsObject);
    };

    const save = async () => {
      if (
        validFormsCount !==
        Math.abs(videoArray.length - Object.keys(deleteVideoTracker).length)
      ) {
        return;
      }

      setIsSaving(true);
      const formRefsObject: any = inputEl.current;
      if (formRefsObject && Object.keys(formRefsObject).length) {
        for (const key in formRefsObject) {
          if (formRefsObject[key]) {
            const attributeValues = formRefsObject[key].values;
            await saveHandler(
              key,
              attributeValues,
              tagsObject[key] || []
            ).catch(() => {});
            const targetFolder = attributeValues['folder'];
            if (parseInt(targetFolder.value) !== DefaultFolderIds.PRIMARY) {
              await moveVideo(key, {
                videoFolder: !!targetFolder.access
                  ? `${SHARED_FOLDER_PREFIX}${targetFolder.value}`
                  : targetFolder.label,
                copy: false,
              });
            }
          }
        }
      }

      setIsSaving(false);
      setIsSaved(true);
    };

    const onDeleteHandler = async (videoId: string) => {
      const newDeletedVideoTracker = { ...deleteVideoTracker };
      newDeletedVideoTracker[videoId] = true;
      setDeletedVideoTracker(newDeletedVideoTracker);

      const response = await deleteVideo({
        videoIds: [videoId],
        deleteMultiple: false,
      });

      if (response && !!response.deleted) {
        successToast({ title: 'Video deleted successfully.' });
      }
    };

    const saveHandler = async (
      videoId: string,
      attributeValues: any,
      videoTags: any
    ) => {
      await Promise.all([
        await updateVideoTags(videoId, videoTags),
        await updateVideoTitle(videoId, attributeValues['covideoName']),
        await updateVideoAttributes(videoId, attributeValues),
      ]);

      if (isAutomotive) {
        // do not call mutation to avoid rerender
        await createVideoRequest({
          data: {
            videoId,
            ...videoRequestData[videoId],
          },
        });
      }
    };

    const updateVideoTitle = async (videoId: string, newTitle: string) => {
      if (!newTitle) {
        return;
      }

      await updateVideo({
        videoId,
        videoBody: { title: newTitle },
      } as any).catch(() => {});
    };

    const updateVideoTags = async (videoId: string, videoTags: any) => {
      if (!videoTags || !videoTags.length) {
        return;
      }

      await putVideoTags(videoId, videoTags).catch(() => {});
    };

    const updateVideoAttributes = async (
      videoId: string,
      attributeValues: any
    ) => {
      if (!attributeValues || _.isEmpty(attributeValues)) {
        return;
      }

      let attributesToKeep = { ...attributeValues };

      // remove values that are not video attributes
      if (!!attributesToKeep['covideoName']) {
        delete attributesToKeep['covideoName'];
      }
      if (!!attributesToKeep['folder']) {
        delete attributesToKeep['folder'];
      }

      const attributes = Object.keys(attributesToKeep).map((key: string) => {
        const splitValues = key.split('-');
        const attributeId = splitValues[1];
        return {
          attributeId,
          value: attributesToKeep[key],
          videoId: parseInt(videoId),
        };
      });
      await putVideoAttributes({
        videoId: videoId,
        data: attributes,
      }).catch(err => err);
    };

    if (areAttributesLoading || isLoading) {
      return <LoadingIndicator isLoading={isLoading} text={'Loading...'} />;
    }
    if (
      !videoArray.length ||
      videoArray.length === Object.keys(deleteVideoTracker).length
    ) {
      return (
        <Container style={{ height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              marginTop: '250px',
              textAlign: 'center',
            }}
          >
            No videos for review.
          </div>
        </Container>
      );
    }

    let canSubmitAutomotiveVideo = true;
    Object.keys(automotiveValidation).forEach(videoId => {
      if (!automotiveValidation[videoId]) {
        canSubmitAutomotiveVideo = false;
      }
    });

    return (
      <Content>
        <Container>
          <ButtonsBox>
            <TitleWrapper>Review uploaded videos</TitleWrapper>
            <CountGroup>
              <CountLabel>
                <b>{validFormsCount}</b>/
                {Math.abs(
                  videoArray.length - Object.keys(deleteVideoTracker).length
                )}{' '}
                <span style={{ color: '#272A32' }}>videos are done</span>
              </CountLabel>
              <CountLabel>
                <b>{totalRequiredAttributeCount}</b>{' '}
                <span style={{ color: '#272A32' }}>required attributes</span>
              </CountLabel>
            </CountGroup>
            <ButtonsWrapper>
              <Button
                disabled={
                  validFormsCount !==
                    Math.abs(
                      videoArray.length - Object.keys(deleteVideoTracker).length
                    ) ||
                  (!canSubmitAutomotiveVideo &&
                    totalRequiredAttributeCount !== 0) ||
                  isSaving
                }
                icon={
                  isSaving ? (
                    <Spinner
                      size={8}
                      color={theme.palette.white}
                      style={{ marginRight: '5px' }}
                    />
                  ) : (
                    <SaveIcon height={18} width={18} />
                  )
                }
                text={isSaving ? 'Saving...' : 'Save Recording'}
                onClick={() => save()}
              />
            </ButtonsWrapper>
          </ButtonsBox>
          <List>
            {videoArray.map((video: any) => {
              if (deleteVideoTracker[video.id]) {
                return <React.Fragment key={video.id} />;
              }

              return (
                <li key={video.id}>
                  <DetailedVideoPreview
                    formId={video.id}
                    title={''}
                    isReviewCase={true}
                    onTagsUpdate={tagsUpdateHandler}
                    videoSource={video.videoSource}
                    handleUpload={() => {}}
                    videoTitle={video.title}
                    handleAttributeChange={onAttributeChangeHandler}
                    onChangeVideoRequestData={data => {
                      if (isAutomotive) {
                        const isValid = validateAutomotiveFields(
                          userData,
                          data
                        );
                        const validation = {
                          ...automotiveValidation,
                          [video.id]: isValid,
                        };
                        setAutomotiveValidation(validation);
                        videoRequestData[video.id] = data;
                        setVideoRequestData(videoRequestData);
                      }
                    }}
                    videoRequestData={videoRequestData[video.id] || {}}
                    formRef={inputEl}
                    onDelete={onDeleteHandler}
                    showFoldersDropdown={true}
                  />
                </li>
              );
            })}
          </List>
        </Container>
      </Content>
    );
  };

  return (
    <>
      <DocumentHead title='Review Videos' />
      <PageTemplate
        main={
          <>
            <RouteLeavingGuard
              when={true}
              stay={true}
              onConfirm={() => {}}
              navigate={path => history.push(path)}
              shouldBlockNavigation={() => {
                return !isSaved;
              }}
              title='Leave without saving details?'
              text='Your changes will not be saved. This action can’t be undone.'
              confirmButtonText='Continue'
              discardButtonText='Leave'
            />
            <ReviewList />
          </>
        }
      />
    </>
  );
};
