import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { theme } from 'lib/style';
import { useGetReport } from 'lib/hooks';
import { LoadingIndicator } from 'lib/components';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import { reportData } from 'lib/const';
import { TooltipChart } from './TooltipChart';

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 24px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #001b53;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: '100%';
  height: 200px;
`;

const radius: [number, number, number, number] = [4, 4, 0, 0];

type Props = {
  reportType: string;
  from: Date;
  to: Date;
  dateRange: string;
  users?: string[];
  customer?: string;
  reportId?: string;
};

export const ColumnChart = (props: Props) => {
  const {
    reportType,
    from,
    to,
    dateRange,
    users = [],
    customer = '',
    reportId,
  } = props;
  const [retryCount, setRetryCount] = useState(0);
  let report = reportData[reportType];
  const allowDecimals = !!report?.allowDecimals;
  const { data, error, loading } = useGetReport({
    reportType,
    from,
    to,
    dateRange,
    users: !reportId ? users : [],
    customer,
    retryCount,
    allowDecimals,
    reportId,
  });

  const showLoading = error || loading;

  if (!report) {
    report = reportData.views;
  }
  // @ts-ignore
  const value = parseFloat(data && data[report.field]) || 0;
  const isFieldPercentage = report.isPercentage || false;

  return (
    <Container>
      {showLoading && (
        <LoadingIndicator
          isLoading={loading}
          height='300px'
          error={error}
          onRetry={() => setRetryCount(retryCount + 1)}
        />
      )}
      {!showLoading && (
        <>
          <Header>
            <Value>
              {`${addThousandCommaSeparator(value, isFieldPercentage)}${
                isFieldPercentage ? ' %' : ''
              }`}
            </Value>
          </Header>
          <ChartContainer>
            <ResponsiveContainer>
              <BarChart data={data.stats}>
                <CartesianGrid stroke={theme.palette.gray} vertical={false} />
                <XAxis dataKey='date' />
                <YAxis
                  allowDecimals={allowDecimals}
                  tickFormatter={(tick: number) =>
                    addThousandCommaSeparator(tick)
                  }
                />
                <Tooltip content={TooltipChart} cursor={false} />
                <Bar
                  dataKey='cnt'
                  fill='#1ea45fcc'
                  radius={radius}
                  maxBarSize={60}
                />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </>
      )}
    </Container>
  );
};
