import React from 'react';
import {
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getCheckboxField,
  getDropdownField,
  getInputField,
} from '../../../components/AdminFormComponents';
import styled from 'styled-components/macro';
import extendIcon from 'lib/images/extendIcon.svg';
import { UserExtendFreeTrialModal } from 'app/pages/admin/components/UserExtendFreeTrialModal';
import {
  AUTOMOTIVE_RESELLER_ID,
  COVIDEO_RESELLER_ID,
} from 'lib/const/SuperAdminConstants';
import { automotiveRoleOptions } from 'lib/const/AutomotiveRole';

const trialTypes = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const accessType = [
  { value: 1, label: 'Standard User' },
  { value: 3, label: 'Administrator' },
  { value: 4, label: 'Supervisor' },
];

const ActionWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 392px;
  justify-content: end;
  gap: 140px;
  margin-bottom: -25px;
  margin-top: -10px;
  margin-left: -24px;
`;

const ExtendTrial = styled.div`
  height: 16px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  text-align: right;

  color: #001b53;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ExtendIcon = styled.div`
  left: 8.34%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.34%;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 304px;
  justify-content: flex-end;
  gap: 2px;
`;

export const CovideoSection = (props: UserFormProps) => {
  const {
    isSubmitting,
    isNewUser,
    resellerId,
    setFieldValue,
    values,
    isVerifiedDisabled,
    isAutomotive,
  } = props;
  const [extendedTrialModal, setExtendedTrialModal] = React.useState(false);

  return (
    <Section id={'covideoInfo'}>
      <SectionTitle>Covideo</SectionTitle>
      <RowWrapper>
        {!isNewUser && (
          <Row>
            <FieldLabel>User ID</FieldLabel>
            {getInputField('id', true, true, isSubmitting, 'text', true)}
          </Row>
        )}
        {!isNewUser && (
          <Row>
            <FieldLabel>Trial</FieldLabel>
            {getDropdownField(
              'trialAccount',
              'Select Trial',
              trialTypes,
              values.trialAccount,
              option => setFieldValue('trialAccount', option.value)
            )}
          </Row>
        )}
        {isNewUser && (
          <Row>
            {getCheckboxField(
              'verified',
              'Verified',
              !!values.verified,
              () => setFieldValue(`verified`, !values.verified),
              isSubmitting,
              isVerifiedDisabled
            )}
          </Row>
        )}
        {!isNewUser && !!values.trialAccount && (
          <Row>
            <FieldLabel></FieldLabel>
            <ActionWrapper>
              <Button onClick={() => setExtendedTrialModal(true)}>
                <ExtendTrial>Extend Trial</ExtendTrial>
                <ExtendIcon>
                  <img src={extendIcon} alt='' />
                </ExtendIcon>
              </Button>
            </ActionWrapper>
          </Row>
        )}
        <Row>
          <FieldLabel>Access Level</FieldLabel>
          {getDropdownField(
            'access',
            'Access Level',
            accessType,
            values.access,
            option => setFieldValue('access', option.value)
          )}
        </Row>
        {isAutomotive && (
          <Row>
            <FieldLabel>Automotive role</FieldLabel>
            {getDropdownField(
              'automotiveRole',
              'Automotive role',
              automotiveRoleOptions,
              values.automotiveRole?.toString() || '',
              option =>
                setFieldValue(
                  'automotiveRole',
                  parseInt(option.value.toString(), 10)
                )
            )}
          </Row>
        )}
        {!isNewUser && (
          <Row>
            {getCheckboxField(
              'qraAccess',
              'QR Code',
              !!values.qraAccess,
              () => setFieldValue(`qraAccess`, !values.qraAccess),
              isSubmitting
            )}
          </Row>
        )}
        {!isNewUser && (
          <Row>
            {getCheckboxField(
              'guideCategoriesAdministration',
              'Guide Edit',
              !!values.guideCategoriesAdministration,
              () =>
                setFieldValue(
                  `guideCategoriesAdministration`,
                  !values.guideCategoriesAdministration
                ),
              isSubmitting
            )}
          </Row>
        )}
        {!isNewUser &&
          resellerId !== COVIDEO_RESELLER_ID &&
          resellerId !== AUTOMOTIVE_RESELLER_ID && (
            <Row>
              {getCheckboxField(
                'resellerAdmin',
                'Reseller Admin',
                !!values.resellerAdmin,
                () =>
                  setFieldValue(`resellerAdmin`, values.resellerAdmin ? 0 : 1),
                isSubmitting
              )}
            </Row>
          )}
        {isNewUser && (
          <Row>
            {getCheckboxField(
              'transcriptionAccess',
              'Enable Captions',
              !!values.transcriptionAccess,
              () =>
                setFieldValue(
                  `transcriptionAccess`,
                  !values.transcriptionAccess
                ),
              isSubmitting
            )}
          </Row>
        )}
        {isNewUser && (
          <Row>
            {getCheckboxField(
              'mobileSMSAccess',
              'Mobile SMS',
              !!values.mobileSMSAccess,
              () => setFieldValue(`mobileSMSAccess`, !values.mobileSMSAccess),
              isSubmitting
            )}
          </Row>
        )}
      </RowWrapper>
      {extendedTrialModal && (
        <UserExtendFreeTrialModal
          user={{
            id: values.id,
            firstName: values.firstName,
            lastName: values.firstName,
          }}
          handleModalClose={() => setExtendedTrialModal(false)}
        />
      )}
    </Section>
  );
};
