import styled from 'styled-components/macro';
import { ReactComponent as VideoSvg } from './video.svg';

const VideoIcon = styled(VideoSvg)`
  width: ${props => props.width || '16px'};
  height: ${props => props.height || '16px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default VideoIcon;
