import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { PackageNameLabel, productFeature } from 'lib/utils/productFeature';
import React from 'react';
import { theme } from 'lib/style';
import LiveVideoIcon from 'lib/images/LiveVideoIcon';
import { useCreateInstantMeetingMutation } from 'lib/api/meetings/useCreateInstantMeetingMutation';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';

export const StartAction = () => {
  const { userData } = useAuth();

  const { mutateAsync: createInstantMeetingAsync, isLoading } =
    useCreateInstantMeetingMutation();

  const onStartLiveClick = async () => {
    createInstantMeetingAsync();
  };

  return (
    <>
      {userData.hasLiveVideoAccess ? (
        <Button
          text='Start Meeting'
          onClick={onStartLiveClick}
          disabled={!!isLoading}
          icon={
            <LiveVideoIcon color={theme.palette.white} width={17} height={15} />
          }
          variant='primary'
        />
      ) : (
        <ProductInfoTooltip
          productFeatureId={productFeature.LIVE_VIDEO}
          nextPlan={PackageNameLabel.TEAMS}
          style={{
            left: '0px',
            top: '45px',
          }}
        >
          <Button
            text='Start Meeting'
            disabled
            icon={
              <LiveVideoIcon
                color={theme.palette.white}
                width={17}
                height={15}
              />
            }
            variant='primary'
          />
        </ProductInfoTooltip>
      )}
    </>
  );
};
