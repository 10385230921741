export type TeleprompterParams = {
  start?: number;
  limit?: number;
  search?: string;
  field?: string;
  order?: string;
  scope?: string;
};

export const SCOPE = {
  PERSONAL: 'personal',
  SHARED: 'shared',
  PERSONAL_SHARED: 'personal_shared',
};
