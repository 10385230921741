import * as React from 'react';
import styled from 'styled-components/macro';

const Icon = styled.path`
  fill: ${props => props.color || 'currentColor'};
  opacity: ${props => props.opacity || 'inherit'};
`;

export const EditPencil = props => {
  const { onClick, width, height, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <Icon
        {...rest}
        fillRule="evenodd"
        d="M0 19.111V24h4.889L19.307 9.582l-4.889-4.889L0 19.111zM24 4.89L19.111 0l-3.298 3.311L20.702 8.2 24 4.889z"
      />
    </svg>
  );
};
