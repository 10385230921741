import * as React from 'react';

export const DropdownIcon = props => {
  const { onClick, color, width, height, opacity } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        fill={color || '#001B53'}
        fillRule="evenodd"
        d="M4 8L12 16 20 8z"
        opacity={opacity}
      />
    </svg>
  );
};
