import {
  IQuickShareMarkAsSentParams,
  useQuickShareMarkAsSentMutation,
} from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import { MarkAsSentOptions } from 'lib/const/MarkAsSentOptions';
import { useAuth } from 'lib/context';
import { copyToClipboardQuickShareSafari } from 'lib/utils/functions';
import { isSafari } from 'lib/utils/browserChecks';
import { getMarkAsSentValue } from 'lib/utils/markAsSent';

interface IProps {
  setShowModalQuickShareMarkAsSent: (
    showModalQuickShareMarkAsSent: boolean
  ) => void;
  handleOnMarkAsSentSuccessCallback?: () => void;
}

export const useQuickShare = ({
  setShowModalQuickShareMarkAsSent,
  handleOnMarkAsSentSuccessCallback,
}: IProps) => {
  const { userData } = useAuth();

  const onSuccessCallback = () => {
    handleOnMarkAsSentSuccessCallback?.();
    setShowModalQuickShareMarkAsSent(false);
  };

  // mutation
  const {
    isLoading: isQuickshareLoading,
    mutateAsync: quickShareMarkAsSentAsync,
  } = useQuickShareMarkAsSentMutation(onSuccessCallback);

  // mutation handler
  const onQuickShareMutationHandler = async (
    params: IQuickShareMarkAsSentParams
  ) => {
    if (isSafari) {
      await copyToClipboardQuickShareSafari(params);
    }
    return await quickShareMarkAsSentAsync(params).catch(err => err);
  };

  // on button click
  const onQuickShareButtonClick = (params: IQuickShareMarkAsSentParams) => {
    const markAsSent = getMarkAsSentValue(userData);

    // if markVideosAsSent set to NEVER -> do not mark as sent
    if (markAsSent === MarkAsSentOptions.NEVER.value) {
      return onQuickShareMutationHandler({
        ...params,
      });
    }
    // if markVideosAsSent not set or set to always ask -> show modal
    if (markAsSent === MarkAsSentOptions.ASK_VERY_TIME.value) {
      return setShowModalQuickShareMarkAsSent(true);
    }
    // if markVideosAsSent is set to ALWAYS -> mark it as sent
    if (markAsSent === MarkAsSentOptions.ALWAYS.value) {
      return onQuickShareMutationHandler({
        ...params,
      });
    }
  };

  return {
    onQuickShareButtonClick,
    isQuickshareLoading,
    onQuickShareMutationHandler,
  };
};
