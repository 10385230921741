import React from 'react';
import { LoadingIndicator } from 'lib/components';
import { UsageReport, UsageReportsListFilter } from 'lib/context';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { debounce } from 'lodash';
import {
  exportUsageReportsData,
  useUsageReportQuery,
  useUsageReportsStatisticsQuery,
} from 'lib/api/superAdminApi';
import { UsageReportsHeader } from '../components/UsageReportsHeader';
import { checkParamForString } from 'lib/utils/functions';
import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { SearchAndFilter } from '../components/SearchAndFilter';
import { UsageReportTable } from '../components/UsageReportsTable';
import { Statistics } from '../components/Statistics';
import { UserExtendFreeTrialModal } from '../../components/UserExtendFreeTrialModal';
import { errorToast } from 'lib/components/toasts/error';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { AllCustomerOption } from 'lib/const/SuperAdminOptions';
import { UsageReportPackageTypes } from 'lib/const/UsageReportPackageTypes';

const StatsText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.gray60};
  margin-bottom: 8px;
`;

export const List = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [extendedTrialModal, setExtendedTrialModal] = React.useState(false);
  const [usageReport, setUsageReport] = React.useState<UsageReport | undefined>(
    undefined
  );

  // All filters are fetched from the URL
  const filter: UsageReportsListFilter = {
    status:
      checkParamForString(queryParams.status) &&
      parseInt(queryParams.status!.toString(), 10) in VerificationStatus
        ? parseInt(queryParams.status!.toString(), 10)
        : VerificationStatus.ACTIVE,
    customerId: checkParamForString(queryParams.customerId)
      ? parseInt(queryParams.customerId!.toString(), 10)
      : AllCustomerOption.value,
    packageId: checkParamForString(queryParams.packageId)
      ? parseInt(queryParams.packageId!.toString(), 10)
      : UsageReportPackageTypes.ALL_USERS,
    sortKey: checkParamForString(queryParams.sortKey)
      ? queryParams.sortKey!.toString()
      : undefined,
    order:
      checkParamForString(queryParams.order) &&
      Object.values(PaginationConstants).includes(
        queryParams.order!.toString() as PaginationConstants
      )
        ? (queryParams.order as PaginationConstants)
        : PaginationConstants.ASCENDING,
    page: checkParamForString(queryParams.page)
      ? parseInt(queryParams.page!.toString(), 10)
      : DEFAULT_PAGE,
    size: checkParamForString(queryParams.size)
      ? parseInt(queryParams.size!.toString(), 10)
      : DEFAULT_SIZE,
    searchQuery: checkParamForString(queryParams.searchQuery)
      ? queryParams.searchQuery!.toString()
      : undefined,
  };

  const {
    count: reportCount,
    reportArray,
    isLoading: showLoading,
  } = useUsageReportQuery({
    params: {
      start: (filter.page || DEFAULT_PAGE) * (filter.size || DEFAULT_SIZE),
      limit: filter.size || DEFAULT_SIZE,
      search: filter.searchQuery,
      status: filter.status ?? VerificationStatus.ALL,
      sortKey: filter.sortKey,
      order: filter.order,
      packageId: filter.packageId,
      customerId:
        filter.customerId && filter.customerId >= 0
          ? filter.customerId
          : undefined,
    },
    filter,
    enabled: true,
  });

  const { statistics, isLoading: statsLoading } =
    useUsageReportsStatisticsQuery({
      params: {
        customerId: filter.customerId,
        packageId: filter.packageId,
        status: filter.status ?? VerificationStatus.ALL,
        search: filter.searchQuery,
      },
      filter: {
        customerId: filter.customerId,
        packageId: filter.packageId,
        status: filter.status ?? VerificationStatus.ALL,
        search: filter.searchQuery,
      },
    });

  const exportUsageReports = async () => {
    try {
      await exportUsageReportsData({
        search: filter.searchQuery,
        status: filter.status ?? VerificationStatus.ALL,
        sortKey: filter.sortKey,
        order: filter.order,
        packageId: filter.packageId,
        customerId:
          filter.customerId && filter.customerId >= 0
            ? filter.customerId
            : undefined,
      });

      successToast({
        title: 'The report will be generated and emailed shortly.',
      });
    } catch (error) {
      errorToast({ title: `Error in exporting Users.` });
    }
  };

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      queryParams.page = `${newPage}`;
      queryParams.size = `${newSize}`;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(queryParams),
      });
    },
    300
  );

  const addStatusToUrl = (status: number) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.status = `${status}`;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const addCustomerIdToUrl = (customerId: number) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.customerId = `${customerId}`;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const addPackageIdToUrl = (packageId: number) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.packageId = `${packageId}`;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const addSortingToUrl = (sortKey: string, order: string) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.sortKey = sortKey;
    queryParams.order = order;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const onSearch = (query: string) => {
    // Reset to first page if search set in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.searchQuery = query;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  return (
    <>
      <MainContainer>
        <MainWrapper>
          <Container>
            <UsageReportsHeader
              loading={false}
              onDownloadCsv={exportUsageReports}
            />
            <StatsText>Last 30 Days</StatsText>
            <Statistics statistics={statistics} isLoading={statsLoading} />
            <SearchAndFilter
              customerFilter={filter.customerId}
              statusFilter={filter.status}
              packageFilter={filter.packageId}
              onSelectCustomer={addCustomerIdToUrl}
              onSelectStatus={addStatusToUrl}
              onSelectPackage={addPackageIdToUrl}
              onSearch={onSearch}
              prevSearch={filter.searchQuery}
            />
            {showLoading && (
              <div style={{ width: '100%', height: '300px' }}>
                <LoadingIndicator isLoading={showLoading} height='300px' />
              </div>
            )}
            {!showLoading && !!reportArray.length && (
              <UsageReportTable
                reportArray={reportArray}
                reportCount={reportCount}
                page={filter.page}
                size={filter.size}
                sortKey={filter.sortKey}
                order={filter.order}
                onPaginationChange={onPaginationChange}
                onSortingUpdate={(sortKey, order) => {
                  addSortingToUrl(sortKey, order);
                }}
                openPlanClick={(report: UsageReport) => {
                  setUsageReport(report);
                  setExtendedTrialModal(true);
                }}
              />
            )}
            {!showLoading && !reportArray.length && (
              <NoList>No Reports to show.</NoList>
            )}
          </Container>
        </MainWrapper>
      </MainContainer>
      {extendedTrialModal && usageReport && (
        <UserExtendFreeTrialModal
          user={usageReport}
          handleModalClose={() => {
            setUsageReport(undefined);
            setExtendedTrialModal(false);
          }}
        />
      )}
    </>
  );
};
