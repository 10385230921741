import styled from 'styled-components/macro';
import { ReactComponent as Svg } from './eye-icon.svg';

const EyeIcon = styled(Svg)`
  width: ${props => props.width || '22px'};
  height: ${props => props.height || '15px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default EyeIcon;
