import Cookies, { CookieAttributes } from 'js-cookie';
import { ICombinedUserData, InventoryItem, VideoRequest } from 'lib/context';

import React from 'react';
import {
  MdAlignHorizontalCenter,
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
} from 'react-icons/md';
import {
  Aligments,
  EMAIL_DESKTOP_WIDTH,
  EmailBuilderElement,
  EmailBuilderElementType,
  PREVIEW_COOKIE_NAME,
  SendData,
  Sizes,
  tabs,
  thumbnailOptionsDictionary,
  defaultLPOption,
  LandingPageType,
  builderLandingPageTypeId,
  LandingPageOption,
  freemiumCTAOption,
  MAX_THUMBNAIL_HEIGHT,
  MAX_THUMBNAIL_WIDTH,
} from '.';
import {
  EmailIconDefault,
  GetCtaSetResponse,
  GetLandingPagesResponse,
  GetWebsiteOverlaysResponse,
  VideoListItem,
} from 'lib/api';
import { QuoteItem } from 'lib/api/repairOrders/types';
import { uniqBy } from 'lodash';
import { Option } from './';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { landingPageUrl } from 'configs/domains/main';

type ContentVariables = {
  '[first_name]'?: string;
  '[last_name]'?: string;
  '~ShortURL~'?: string;
  '[ro_number]'?: string;
  '[quote_total]'?: string;
  '[technician_name]'?: string;
  '[advisor_name]'?: string;
  '[advisor_email]'?: string;
  '[advisor_phone]'?: string;
  '[vin_number]'?: string;
  '[vehicle_name]'?: string;
  '[salesperson_name]'?: string;
  '[salesperson_email]'?: string;
  '[salesperson_phone]'?: string;
};

type ReplaceVariablesParams = {
  text: string;
  userData: ICombinedUserData;
  contactFirstName?: string;
  contactLastName?: string;
  videoRequest?: VideoRequest;
  sendData?: SendData;
  vehicleData?: InventoryItem;
};

export const replaceVariables = ({
  text,
  userData,
  contactFirstName,
  contactLastName,
  videoRequest,
  sendData,
  vehicleData,
}: ReplaceVariablesParams) => {
  const advisorName = videoRequest?.advisor
    ? `${videoRequest.advisor.firstName} ${videoRequest.advisor.lastName}`
    : '';
  const technicianName = videoRequest?.user
    ? `${videoRequest.user.firstName} ${videoRequest.user.lastName}`
    : '';
  const salesPersonName = `${userData.firstName} ${userData.lastName}`;
  let quoteTotal = 0;
  (sendData?.quoteItems || []).forEach(quote => {
    quoteTotal += parseFloat(quote.price.toString());
  });

  const serviceVariables: ContentVariables = {
    '[ro_number]': videoRequest?.repairOrderNumber || '',
    '[quote_total]': quoteTotal.toFixed(2),
    '[technician_name]': technicianName,
    '[advisor_name]': advisorName,
    '[advisor_email]': videoRequest?.advisor?.email || '',
    '[advisor_phone]': videoRequest?.advisor?.phone || '',
  };

  const salesVariables: ContentVariables = {
    '[vin_number]': sendData?.vin || '',
    '[vehicle_name]': vehicleData?.title || '',
    '[salesperson_name]': salesPersonName,
    '[salesperson_email]': userData.email,
    '[salesperson_phone]': userData.phone1,
  };

  const variables: ContentVariables = {
    '[first_name]': contactFirstName,
    '[last_name]': contactLastName,
    ...(userData.isAutomotiveSalesRole && salesVariables),
    ...(userData.isAutomotiveServiceRole && serviceVariables),
  };

  Object.keys(variables).forEach(variable => {
    text = text.replaceAll(
      variable,
      variables[variable as keyof ContentVariables] || ''
    );
  });
  return text;
};

type SavePreviewCookieProps = {
  quoteItems?: QuoteItem[];
};

export const savePreviewCookie = ({
  quoteItems = [],
}: SavePreviewCookieProps) => {
  const cookieOptions: CookieAttributes = {
    domain: process.env.NODE_ENV === 'development' ? undefined : 'covideo.com',
    path: '/',
  };
  const cookieData = {
    repairOrder: {
      quoteItems,
    },
  };
  Cookies.set(PREVIEW_COOKIE_NAME, JSON.stringify(cookieData), cookieOptions);
};

export const getPreviewUrl = (url: string, type = 0) => {
  /* Type is if we need to show vidmails url or covideo landing page url
  (If type is 0 show vidmails else show covideo landing page)
  */
  if (!type) {
    return url + '?preview&nn=1';
  }

  const parsedUrl = new URL(url);
  // remove extra search params
  parsedUrl.search = '';
  // set correct landing page,
  parsedUrl.host = new URL(landingPageUrl || '').host;
  return `${parsedUrl.toString()}?preview&nn=1`;
};

export const getTabs = (userData: ICombinedUserData) => {
  let tempTabs = { ...tabs };
  // Lock the publish and embed tab for freemium users
  if (userData.customer.packageId.toString() === '1') {
    tempTabs.publish.isLocked = true;
    tempTabs.embed.isLocked = true;
  }

  return tempTabs;
};

export const getAlignmentIcon = (option: string) => {
  let icon = <></>;
  switch (option) {
    case Aligments.LEFT:
      icon = <MdAlignHorizontalLeft size={20} />;
      break;
    case Aligments.CENTER:
      icon = <MdAlignHorizontalCenter size={20} />;
      break;
    case Aligments.RIGHT:
      icon = <MdAlignHorizontalRight size={20} />;
      break;
    default:
      break;
  }
  return icon;
};

export const generateHtmlFromElements = (elements: EmailBuilderElement[]) => {
  if (!elements.length) {
    return '';
  }
  const elementsHtml = elements
    .filter(element => element.type !== EmailBuilderElementType.IMAGE_EMPTY)
    .map(element => {
      const margin = [
        EmailBuilderElementType.IMAGE,
        EmailBuilderElementType.VIDEO_THUMB,
      ].includes(element.type)
        ? '1em 0'
        : '0';
      return (
        `<div class="builder-element" style="margin:${margin}">` +
        element.html +
        `</div>`
      );
    })
    .join('');
  return `<head><style>.covideo-signature-class{margin:0;font-size:16px;}.ql-font-serif{font-family:Georgia,TimesNewRoman,serif;}.ql-font-monospace{font-family:Monaco,CourierNew,monospace;}.ql-indent-1{padding-left:3em;}.ql-indent-2{padding-left:6em;}.ql-indent-3{padding-left:9em;}.ql-indent-4{padding-left:12em;}.ql-indent-5{padding-left:15em;}.ql-indent-6{padding-left:18em;}.ql-indent-7{padding-left:21em;}.ql-indent-8{padding-left:24em;}.ql-size-small{font-size:0.75em}.ql-size-large{font-size:1.5em}.ql-size-huge{font-size:2.5em}.ql-align-right{text-align: right;}.ql-align-center{text-align: center;}.ql-align-justify{text-align: justify;}h1{font-family:'Work Sans',sans-serif;font-size:40px;font-weight:900;font-stretch:normal;font-style:normal;letter-spacing:normal;color:#232b3c;padding:0;margin:0;}h2{font-family:'Work Sans',sans-serif;font-size:24px;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.33;letter-spacing:normal;color:#324058;padding:0;margin:0;}blockquote{border-left:4px solid#ccc;margin-bottom:5px;margin-top:5px;padding-left:16px;margin-left:0;}</style></head><div class="builder-wrapper" style="font-size:16px;word-break:break-word;"><div class="builder-container" style="width:${EMAIL_DESKTOP_WIDTH}px;max-width:100%;margin-right:auto;">${elementsHtml}</div></div>`;
};

type GenerateSignatureBoxHTMLParams = {
  html: string;
};
export const generateSignatureBoxHTML = ({
  html,
}: GenerateSignatureBoxHTMLParams) => {
  return setHTMLImgMaxWidth(html);
};

export const generateTextBoxHTML = () => {
  return `<p>This is a text box. </p>`;
};

export const generateHTMLBoxHTML = () => {
  return `<p>This is a HTML box. </p>`;
};

export const getMaxHeight = ({
  size = Sizes.THREE_QUARTER,
}: {
  size: string;
}) => {
  switch (size) {
    case Sizes.HALF:
      return 0.5 * MAX_THUMBNAIL_HEIGHT;
    case Sizes.THREE_QUARTER:
      return 0.75 * MAX_THUMBNAIL_HEIGHT;
    case Sizes.FULL:
      return MAX_THUMBNAIL_HEIGHT;
    default:
      return 0.75 * MAX_THUMBNAIL_HEIGHT;
  }
};

type GenerateVideoThumbnailHTMLParams = {
  thumbnail: string;
  from: string;
  videoUrl?: string;
  width?: string;
  align?: string;
  borderRadius?: string;
  maxWidth?: number;
  isPortrait?: boolean;
  customerId?: string;
  videoShareLinkText?: string;
};
export const generateVideoThumbnailHTML = ({
  from,
  videoUrl = '~ShortURL~',
  thumbnail,
  width = Sizes.THREE_QUARTER,
  align = Aligments.LEFT,
  borderRadius = '0',
  maxWidth,
  isPortrait,
  customerId = undefined,
  videoShareLinkText = '',
}: GenerateVideoThumbnailHTMLParams) => {
  let dimensions = `width:${width};max-width:${MAX_THUMBNAIL_WIDTH}px;height:auto`;
  if (!!isPortrait) {
    dimensions = `max-height:${getMaxHeight({ size: width })}px;`;
  }
  const useTranslatedText = customerId === '57756'; // For Benoist Rousseau

  const englishText =
    videoShareLinkText || `Click here to view your video from ${from}`;
  const frenchText = `Cliquez sur l'image ou regardez votre vidéo de ${from} ici`;
  const messageText = useTranslatedText ? frenchText : englishText;
  return `
  <div style="text-align:${align}${
    maxWidth ? `; max-width:${maxWidth}px` : ''
  }">
    <a title="${messageText}" href="${videoUrl}" target="_blank" style="text-decoration:none;">
      <img style="${dimensions};border-radius: ${borderRadius};" src="${thumbnail}" alt="Video Thumbnail" />
    </a>
  </div>`;
};
type GenerateImageBoxHTMLParams = {
  imageUrl: string;
  width?: string;
  align?: string;
  borderRadius?: string;
};
export const generateImageBoxHTML = ({
  imageUrl,
  width = Sizes.THREE_QUARTER,
  align = Aligments.LEFT,
  borderRadius = '5px',
}: GenerateImageBoxHTMLParams) => {
  if (!imageUrl) {
    return `
    <div style="height: 320px;display:flex;align-items:center;justify-content:center;background:#F2F4F6;font-weight: 600;font-size: 24px;color: rgba(0, 27, 83, 0.2);border-radius: 5px;">
    Add a photo here
    </div>`;
  }
  return `
  <div style="text-align:${align}">
    <img style="width:${width};height:auto;border-radius: ${borderRadius};" src="${imageUrl}" alt="Email content image" />
  </div>`;
};

const getPlayButtonThumbnailFromVideo = (video: VideoListItem) => {
  return video.autogeneratedThumbnail?.replace('_0001', '_0000') || '';
};

type ChooseDefaultVideoThumbnailParams = {
  defaultEmailIcon?: EmailIconDefault;
  video: VideoListItem;
};

export const chooseDefaultVideoThumbnail = ({
  defaultEmailIcon,
  video,
}: ChooseDefaultVideoThumbnailParams) => {
  let thumbnail = '';
  let thumbnailOption = '';
  switch (defaultEmailIcon?.title) {
    case thumbnailOptionsDictionary.VIDEO_SNAPSHOT.value:
      thumbnail = getPlayButtonThumbnailFromVideo(video);
      thumbnailOption = thumbnailOptionsDictionary.VIDEO_SNAPSHOT.value;
      break;
    case thumbnailOptionsDictionary.ANIMATED_SNAPSHOT.value:
      thumbnail = video.autogeneratedGif || '';
      thumbnailOption = thumbnailOptionsDictionary.ANIMATED_SNAPSHOT.value;
      break;
    default:
      // only icons supported, ignore templates
      if (defaultEmailIcon?.type !== 'icons') {
        break;
      }
      thumbnail = defaultEmailIcon?.thumbnail || '';
      thumbnailOption = defaultEmailIcon?.thumbnail || '';
      break;
  }
  if (!thumbnail) {
    thumbnail = getPlayButtonThumbnailFromVideo(video);
    thumbnailOption = thumbnailOptionsDictionary.VIDEO_SNAPSHOT.value;
  }
  return { thumbnail, thumbnailOption };
};

type GetThumbnailFromOptionAndVideoProps = {
  thumbnailOption?: string;
  video: VideoListItem;
};

export const getThumbnailFromOptionAndVideo = ({
  thumbnailOption,
  video,
}: GetThumbnailFromOptionAndVideoProps) => {
  let thumbnail = '';
  switch (thumbnailOption) {
    case thumbnailOptionsDictionary.VIDEO_SNAPSHOT.value:
      thumbnail = getPlayButtonThumbnailFromVideo(video);
      break;
    case thumbnailOptionsDictionary.ANIMATED_SNAPSHOT.value:
      thumbnail = video.autogeneratedGif || '';
      break;
    default:
      thumbnail = thumbnailOption || '';
      break;
  }
  if (!thumbnail) {
    thumbnail = getPlayButtonThumbnailFromVideo(video);
  }
  return thumbnail;
};

export function isHtmlEmpty(htmlString: string) {
  if (!htmlString) {
    return true;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // get all elements in the document
  const elements = doc.getElementsByTagName('*');

  // iterate over each element
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    // if an element is an image html is not empty
    if (element.tagName.toLowerCase() === 'img') {
      return false;
    }

    // if an element has non-empty content html is not empty
    if (element.textContent && element.textContent.trim().length !== 0) {
      return false;
    }
  }

  // if no element has non-empty content, html is empty
  return true;
}

export function setHTMLImgMaxWidth(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // get all img elements in the document
  const imgs = doc.getElementsByTagName('img');

  // set max-width: 100% for each img element
  for (let i = 0; i < imgs.length; i++) {
    const img = imgs[i];
    img.style.maxWidth = '100%';
  }

  // return the updated HTML string
  return doc.documentElement.outerHTML;
}

export const isObjEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const getSalesServiceOption = (userData: ICombinedUserData) => {
  const { isAutomotiveServiceRole } = userData;
  return {
    //has to be -2 so php doesn't add default if no LP selected
    value: -2,
    label: `${isAutomotiveServiceRole ? 'Service' : 'Sales'} page`,
    landingPageType: LandingPageType.AUTOMOTIVE,
  };
};

export const prepareTemplateLandingPageOptions = ({
  userData,
  templatesResponse,
  sendVehicle,
  sendQuote,
}: {
  userData: ICombinedUserData;
  templatesResponse: GetLandingPagesResponse;
  sendVehicle: boolean;
  sendQuote: boolean;
}): { options: LandingPageOption[]; autoSelectedOption: LandingPageOption } => {
  const { isAutomotive, isAutomotiveSalesRole, isAutomotiveServiceRole } =
    userData;
  // flag to disable cds landing page, to use vidmails only
  const disableCDSLandingPage = !isAutomotive;
  const salesServicePageOption = getSalesServiceOption(userData);
  // flag to show only custom landing pages (built by our builder)
  const disableStaticLandingPages =
    (isAutomotiveServiceRole && sendQuote) ||
    (isAutomotiveSalesRole && sendVehicle);

  let autoSelectedOption = disableCDSLandingPage
    ? defaultLPOption
    : salesServicePageOption;
  const defaultLandingPage = templatesResponse?.default;
  // filter our static lp options from response if static pages disabled, create options
  let options = (templatesResponse?.templates || [])
    .filter(
      (template: any) =>
        !disableStaticLandingPages ||
        template.typeId === builderLandingPageTypeId
    )
    .map((template: any) => ({
      label: template.title,
      value: template.id,
      landingPageType:
        template.typeId !== builderLandingPageTypeId
          ? LandingPageType.VIDMAILS
          : LandingPageType.AUTOMOTIVE,
    }));
  // add automotive LP as option
  if (!disableCDSLandingPage) {
    options = [...options, salesServicePageOption];
  }
  // add default LP as option if static not disabled or default not static
  if (
    defaultLandingPage?.id &&
    (!disableStaticLandingPages ||
      defaultLandingPage?.typeId === builderLandingPageTypeId)
  ) {
    const defaultLandingPageOption = {
      label: templatesResponse?.default?.title,
      value: templatesResponse?.default?.id,
      landingPageType:
        defaultLandingPage?.typeId === builderLandingPageTypeId
          ? LandingPageType.AUTOMOTIVE
          : LandingPageType.VIDMAILS,
    };
    autoSelectedOption = defaultLandingPageOption;
    options = [...options, defaultLandingPageOption];
  }
  options = uniqBy(options, 'value');

  return { options, autoSelectedOption };
};

export const prepareWebsiteOverlayOptions = (
  overlays: GetWebsiteOverlaysResponse
): { autoSelectedOption?: Option; options: Option[] } => {
  const websiteUrls = overlays.websiteUrls;
  let options: Option[] = [];
  let autoSelectedOption: Option | undefined;

  if (websiteUrls?.length > 0) {
    options = websiteUrls.map(e => ({
      label: e.title,
      value: e.id.toString(),
    }));
    options = uniqBy(options, 'value');

    const findDefaultLink = websiteUrls.find(item => item.defaultflag === 1);
    if (findDefaultLink?.id) {
      autoSelectedOption = {
        value: findDefaultLink.id.toString(),
        label: findDefaultLink.title,
      };
    }
  }
  return { options, autoSelectedOption };
};

export const prepareLinksetCTAOptions = ({
  userData,
  linksetsResponse,
}: {
  userData: ICombinedUserData;
  linksetsResponse: GetCtaSetResponse;
}): { autoSelectedOption?: Option; options: Option[] } => {
  const isCTASEnabled = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
  if (!isCTASEnabled) {
    return {
      autoSelectedOption: freemiumCTAOption,
      options: [freemiumCTAOption],
    };
  }

  const options: Option[] = (linksetsResponse?.linksets || []).map(e => ({
    label: e.title,
    value: e.id.toString(),
  }));
  const uniqueOptions = uniqBy(options, 'value');

  const findDefaultLink = (linksetsResponse?.linksets || []).find(
    item => item.defaultLinks === 1
  );
  let autoSelectedOption: Option | undefined;

  if (findDefaultLink) {
    autoSelectedOption = {
      value: findDefaultLink.id.toString(),
      label: findDefaultLink.title,
    };
  }

  return { options: uniqueOptions, autoSelectedOption };
};
