import LaptopIcon from 'lib/images/LaptopIcon';
import SmartphoneIcon from 'lib/images/SmartphoneIcon';
import React from 'react';
import { LandingPageOption, LandingPageType, Tabs } from './types';
import { FreemiumTemplateId } from 'lib/const/VideoTemplateItems';

// custom landing pages created by customers have this typeId
export const builderLandingPageTypeId = 19;

export const defaultLPOption: LandingPageOption = {
  value: 0,
  label: '',
  landingPageType: LandingPageType.VIDMAILS,
};

export const freemiumLPOption = {
  value: FreemiumTemplateId,
  label: 'Video Landing Page',
  landingPageType: LandingPageType.VIDMAILS,
};

export const freemiumCTAOption = {
  value: '-1',
  label: 'None',
};

export const tabs: Tabs = {
  send: {
    value: 'Send',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
  share: {
    value: 'Share',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
  embed: {
    value: 'Embed',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
  publish: {
    value: 'Publish',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
};

export const CREATE_NEW_ID = '0';

export const MAX_EMAIL_RECIPIENTS = 1000;
export const MAX_PHONE_RECIPIENTS = 1000;

export const noSmsMsg = (
  <>
    To activate the SMS functionality for your account, please call us at
    <br />
    <a href='tel:8003061445'>800-306-1445</a>
  </>
);

export const noInventoryItemsMsg = (
  <>
    To enable the integration with your Inventory Management System, please call
    us at
    <br />
    <a href='tel:8003061445'>800-306-1445</a>
  </>
);

export const PREVIEW_COOKIE_NAME = 'covideo-preview-data';

export enum Sizes {
  HALF = '50%',
  THREE_QUARTER = '75%',
  FULL = '100%',
}

export const EMAIL_BUILDER_BORDER_RADIUS = '5px';

export enum Aligments {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export const PREVIEWS = {
  desktop: 'desktop',
  mobile: 'mobile',
};

export const EMAIL_DESKTOP_WIDTH = 600;
export const EMAIL_MOBILE_WIDTH = 360;

/*
SUS-2504
Default thumbnail size should be same as code generated from PHP
PHP: max-width = 430px, max-height = 240px
Send & Share: max-width = 574 * 0.75 = 430px, max-height = 320 * 0.75 = 240px
*/
export const MAX_THUMBNAIL_HEIGHT = 320;
export const MAX_THUMBNAIL_WIDTH = 574;
export const MAX_THUMBNAIL_MOBILE_WIDTH = 325;

export const previewOptions = [
  {
    value: PREVIEWS.desktop,
    icon: <LaptopIcon height={24} />,
  },
  {
    value: PREVIEWS.mobile,
    icon: <SmartphoneIcon height={30} />,
  },
];

type OptionsDictionary = {
  [key: string]: {
    label: string;
    value: string;
  };
};

export const thumbnailOptionsDictionary: OptionsDictionary = {
  ANIMATED_SNAPSHOT: {
    label: 'Animated snapshot',
    value: 'Animated Snapshot',
  },
  VIDEO_SNAPSHOT: {
    label: 'Video snapshot',
    value: 'Video Snapshot',
  },
};

export const BUILDER_IDS = {
  EMPTY: '',
  VIDEO_THUMB: 'video-thumb',
  VIDEO_URL: 'video-url',
  SIGNATURE: 'signature',
};

export const CUSTOMER_FIRST_NAME_VARIABLE = '[first_name]';
export const CUSTOMER_LAST_NAME_VARIABLE = '[last_name]';

export const GENERAL_VARIABLES = [
  { value: '~ShortURL~', text: 'Video Share URL' },
];

export const LEGACY_VARIABLES = [
  { value: '~FirstName~', text: "Customer's first name" },
  { value: '~LastName~', text: "Customer's last name" },
];

export const SERVICE_VARIABLES = [
  { value: CUSTOMER_FIRST_NAME_VARIABLE, text: "Customer's first name" },
  { value: CUSTOMER_LAST_NAME_VARIABLE, text: "Customer's last name" },
  { value: '[ro_number]', text: 'Repair order number' },
  { value: '[quote_total]', text: 'Total sum from a quote' },
  { value: '[technician_name]', text: 'Technician full name' },
  { value: '[advisor_name]', text: 'Your name' },
  { value: '[advisor_email]', text: 'Your email address' },
  { value: '[advisor_phone]', text: 'Your phone number' },
];

export const SALES_VARIABLES = [
  { value: CUSTOMER_FIRST_NAME_VARIABLE, text: "Customer's first name" },
  { value: CUSTOMER_LAST_NAME_VARIABLE, text: "Customer's last name" },
  { value: '[vin_number]', text: 'VIN number' },
  { value: '[vehicle_name]', text: 'Vehicle name' },
  { value: '[salesperson_name]', text: 'Salesperson full name' },
  { value: '[salesperson_email]', text: 'Salesperson email address' },
  { value: '[salesperson_phone]', text: 'Salesperson phone number' },
];

export const LIMIT_CONTACTS_PAGE_SIZE = 20;
export const LIMIT_GROUPS_PAGE_SIZE = 5;
export const CONTACTS_SORT_KEY = 'contact.firstName';
export const GROUPS_SORT_KEY = 'name';
export const SORT_ORDER = 'ASC';
