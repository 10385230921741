import * as React from 'react';
import styled from 'styled-components/macro';
import { SwitchIcon } from 'lib/images/SwitchIcon';
import { PhoneIcon } from 'lib/images/PhoneIcon';
import { EnvelopeIcon } from 'lib/images/EnvelopeIcon';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { useLandingPageBuilderContext } from '../../../app/pages/design/landingPageBuilder/context';
import { IWhiteLabel } from 'lib/api/whitelabel/getWhiteLabel';

interface ComponentProps {
  isModalOpen?: boolean | undefined;
  isVisible?: boolean;
  showOnMobile?: true;
}

const Container = styled.div<ComponentProps>`
  display: ${props => (props.showOnMobile ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: #f7f8fa;
  ${theme.mediaQueryMinWidth.md} {
    display: flex;
    position: ${props => (props.isModalOpen ? 'relative' : 'fixed')};
    bottom: 64px;
    width: 100%;
    z-index: ${props => (props.isModalOpen ? 0 : 9)};
    font-size: 13px;
    position: fixed;
    bottom: 0;
    margin: 0;
    justify-content: space-between;
    cursor: default;
    font-size: 14px;
  }
`;

const SwitchLink = styled.div`
  ${theme.fontNormal500}
  font-size: 13px;
  color: #53565f;
  cursor: pointer;
  text-decoration: underline;
  line-height: 1.6;
  ${theme.mediaQueryMinWidth.mb} {
    font-size: 15px;
  }
`;

const Content = styled.div<ComponentProps>`
  display: ${props => (props.showOnMobile ? 'flex' : 'none')};
  align-items: center;
  max-width: 254px;
  svg {
    margin: 0 8px 0 0px;
    font-weight: 500;
    font-size: 16px;
    ${theme.mediaQueryMinWidth.md} {
      margin: 0 8px 0 32px;
    }
  }
  ${SwitchLink} {
    margin: 0 0 0 0px;
  }
  ${theme.mediaQueryMinWidth.md} {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
`;

const SupportLink = styled.a`
  ${theme.fontNormal400}
  color: #53565f;
  line-height: 1.6;
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
  ${theme.mediaQueryMinWidth.mb} {
    font-size: 15px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: ${theme.palette.primaryDarkBlue};
  ${theme.mediaQueryMinWidth.mb} {
    font-size: 16px;
  }
  &:nth-of-type(1) {
    margin: 0 0 0 30px;
  }
  &:nth-of-type(2) {
    margin: 0 32px 0 32px;
  }
  svg {
    font-weight: 500;
    font-size: 16px;
    opacity: 0.5;
  }
  ${SupportLink} {
    margin: 0 0 0 8px;
  }

  ${theme.mediaQueryMinWidth.mb} {
    ${SupportLink} {
      &:nth-of-type(2) {
        margin: 0 32px 0 8px;
      }
    }
  }
`;

const ContentSupport = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  background-color: #ecedf2;
  width: 100%;
  ${theme.mediaQueryMinWidth.md} {
    height: 40px;
    justify-content: space-between;
    background-color: #f7f8fa;
    width: auto;
  }
`;

const onSwitchClick = (e: React.MouseEvent, whitelabel: IWhiteLabel) => {
  e.preventDefault();
  var cDate = new Date();
  cDate.setFullYear(cDate.getFullYear() + 10);
  document.cookie =
    'usebeta=0;domain=' +
    whitelabel.rootDomain +
    ';secure;path=/;samesite=lax;expires=' +
    cDate.toUTCString();
  window.location.href = `https://${whitelabel.domainV2}/backoffice/Email_VideoLibrary.php`;
};

const ContentSupportComponent = () => {
  const { whitelabel } = useAuth();

  return (
    <ContentSupport>
      <Flex>
        <EnvelopeIcon width='24px' height='24px' viewBox='0 0 24 24' />
        <SupportLink href={'mailto:' + whitelabel.supportEmail}>
          {whitelabel.supportEmail}
        </SupportLink>
      </Flex>
      {whitelabel.supportPhone && (
        <Flex>
          <PhoneIcon />
          <SupportLink href={'tel:+1' + whitelabel.supportPhone}>
            {whitelabel.supportPhone}
          </SupportLink>
        </Flex>
      )}
    </ContentSupport>
  );
};

const SwitchToClassicDesktop = ({ isVisible, isModalOpen }: ComponentProps) => {
  const { whitelabel } = useAuth();

  const builderContext = useLandingPageBuilderContext();
  if (isVisible && !builderContext.isEnabled)
    return (
      <Container isModalOpen={isModalOpen}>
        <Content>
          <SwitchIcon
            onClick={(e: React.MouseEvent) => onSwitchClick(e, whitelabel)}
          />
          <SwitchLink onClick={e => onSwitchClick(e, whitelabel)}>
            Switch to Classic Layout
          </SwitchLink>
        </Content>
        <ContentSupportComponent />
      </Container>
    );
  return null;
};

export { SwitchToClassicDesktop };
