import { RecipientsSelector } from 'app/pages/reports/components/RecipientsSelector';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { CustomReportModalFormikValues, TYPE_OF_REPORT } from '../types';
import { CustomReportBodyWrapper } from '../styles';
import { SendInfoMessage } from 'app/pages/admin/customers/components/SendInfoMessage';
import { ParagraphExtraSmall } from 'lib/components/styles/typography';

interface TextInputProps {
  margin?: string;
  background?: string | null;
  width?: string;
  dataCy?: string;
}

export const BasicWrapper = styled(CustomReportBodyWrapper)`
  min-width: 615px;
  max-width: 615px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4e5461;
`;

export const TextInput = styled.input.attrs<TextInputProps>(props => ({
  type: props.type || 'text',
  'data-cy': props.dataCy,
}))<TextInputProps>`
  display: block;
  width: ${props => props.width || '100%'};
  max-width: 800px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: ${props => props.background || '#fff'};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin: ${props => props.margin || ''};
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2684ff;
    outline: 0;
    box-shadow: 0 0 0 1px #2684ff;
  }
  &:disabled {
    border: 0;
    cursor: not-allowed;
    background-color: #f6f7f9;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: hsl(0, 0%, 50%);
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: hsl(0, 0%, 50%);
    opacity: 1;
  }
`;

const InputField = (props: FieldAttributes<any>) => {
  return <Field {...props} />;
};

export const CustomModalReportBasicStep = () => {
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();
  return (
    <BasicWrapper>
      <GroupWrapper>
        <Label>Report Name</Label>
        <InputField
          width='100%'
          placeholder='Custom report title'
          name={`title`}
          as={TextInput}
          type='text'
        />
      </GroupWrapper>

      <GroupWrapper>
        <Label>Recipients (optional)</Label>
        <RecipientsSelector
          onChange={receivers => setFieldValue('receivers', receivers)}
          isClearable={true}
          initRecipients={values.receivers || []}
          showIcon={true}
        />
        <ParagraphExtraSmall color='#9297a2' m='8px 0 16px 0'>
          Type recipient’s email address and press ‘Enter’. Each recipient needs
          to be added separately.
        </ParagraphExtraSmall>
        {values.typeOfReport === TYPE_OF_REPORT.RECURRING && (
          <SendInfoMessage />
        )}
      </GroupWrapper>
    </BasicWrapper>
  );
};
