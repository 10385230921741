import React from 'react';
import DatePicker from 'react-datepicker';

import styled from 'styled-components/macro';
import CalendarIcon from 'lib/images/CalendarIcon';

import 'react-datepicker/dist/react-datepicker.css';
import { CustomReportModalFormikValues } from '../types';
import { useFormikContext } from 'formik';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 490px;
  margin: 0 auto;
`;

const InputDatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9297a2;
  gap: 16px;
`;

const InputDateWrapper = styled.div`
  position: relative;
  input {
    padding: 8px 8px 8px 40px;
    border-radius: 4px;
    border: solid 1px #d0d3d9;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #272a32;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  top: 8px;
  left: 8px;
  z-index: 1;
`;

const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .react-datepicker {
    width: 100%;
    border: none;
  }
  & .react-datepicker__month-container {
    width: 50%;
  }
  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.neutral[10]};
    border: none;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.colors.neutral[80]};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__year-text--in-range {
    background: ${({ theme }) => theme.colors.primary[100]};
  }
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral[80]};
`;

const DatePickerGroup = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const CustomTimeframe = () => {
  const MIN_DATE = new Date('01-01-2022');
  const MAX_DATE = new Date();
  const { values, setFieldValue } =
    useFormikContext<CustomReportModalFormikValues>();

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFieldValue('startDate', start);
    setFieldValue('endDate', end);
  };

  const onStartDateChange = (date: Date) => {
    setFieldValue('startDate', date);
  };

  const onEndDateChange = (date: Date) => {
    setFieldValue('endDate', date);
  };

  return (
    <Content>
      <InputDatesWrapper>
        <DatePickerGroup>
          <InputLabel>Start date</InputLabel>
          <InputDateWrapper>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
            <DatePicker
              onChange={onStartDateChange}
              selected={values.startDate}
              open={false}
              disabled
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
            />
          </InputDateWrapper>
        </DatePickerGroup>

        <DatePickerGroup>
          <InputLabel>End date</InputLabel>
          <InputDateWrapper>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
            <DatePicker
              onChange={onEndDateChange}
              selected={values.endDate}
              open={false}
              disabled
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
            />
          </InputDateWrapper>
        </DatePickerGroup>
      </InputDatesWrapper>
      <DatePickerWrapper>
        <DatePicker
          selected={values.startDate}
          onChange={onDateChange}
          startDate={values.startDate}
          endDate={values.endDate}
          monthsShown={2}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          selectsRange
          inline
        />
      </DatePickerWrapper>
    </Content>
  );
};
