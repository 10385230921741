import React from 'react';

export const OrderTableArrowIcon = props => {
  const { onClick, color, rotate } = props;
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      onClick={onClick}
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotate || 0})`}
    >
      <path d="M5 0V8.01H8L4 12L0 8.01H3V0H5Z" fill={color} />
    </svg>
  );
};
