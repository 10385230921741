import React, { useEffect, useState } from 'react';
import { Gap } from 'lib/components/styles/layout';
import {
  ParagraphSmallBold,
  SubHeading,
} from 'lib/components/styles/typography';
import { FaChevronRight } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components/macro';
import { ThumbnailCategorizedList } from '../../ThumbnailCategorizedList';
import { Button } from 'react-covideo-common';
import { CategorizedEmpty } from '../CategorizedEmpty';
import { useHistory } from 'react-router';
import { ModalsType } from './MainGuidePage';
import {
  CategorizedGuide,
  useGuideCategoryQuery,
} from 'lib/api/guides/guideCategoryQuery';
import { LoadingIndicator } from 'lib/components/LoadingIndicator';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { PaginationWrapper } from 'app/pages/admin/ims/components/style';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'lib/const/PaginationConstants';
import CategoryBadge from '../../components/CategoryBadge';
import { SelectedGuideCategoryType } from './constants';
import { AiOutlinePlus } from 'react-icons/ai';
import DeleteIcon from 'lib/images/DeleteIcon';

const SpanSmallBold = styled(ParagraphSmallBold).attrs({ as: 'span' })`
  text-decoration-line: underline;
  margin-left: 16px;
  cursor: pointer;
`;

const PageWraper = styled.div``;

interface IProps {
  setModals?: React.Dispatch<React.SetStateAction<ModalsType>>;
  setSelectedGuideCategory: React.Dispatch<
    React.SetStateAction<SelectedGuideCategoryType>
  >;
  showOnlyCompanyGuides: boolean;
}

export const GuidesCategorized = ({
  setModals,
  setSelectedGuideCategory,
  showOnlyCompanyGuides,
}: IProps) => {
  const history = useHistory();
  const [guidesCategorizedPage, setGuidesCategorizedPage] =
    useState(DEFAULT_PAGE);
  const [guidesCategorizedSize, setGuidesCategorizedSize] =
    useState(DEFAULT_SIZE);
  const limit = guidesCategorizedSize || 10;
  const start = guidesCategorizedPage * limit;

  const { data, isLoading, refetch } = useGuideCategoryQuery(
    start,
    limit,
    showOnlyCompanyGuides
  );

  const onPaginationChange = async ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setGuidesCategorizedPage(page);
    setGuidesCategorizedSize(size);
  };

  useEffect(() => {
    refetch();
  }, [guidesCategorizedPage, guidesCategorizedSize, showOnlyCompanyGuides]);

  if (isLoading) {
    return (
      <PageWraper>
        <LoadingIndicator isLoading={true} />
      </PageWraper>
    );
  }

  const openModalAndUpdateSelection = (
    modalType: ModalsType,
    data: CategorizedGuide
  ) => {
    if (!data) {
      return;
    }
    setModals?.(modalType);
    setSelectedGuideCategory({
      name: data.name,
      guideCategoryId: data.guideCategoryId,
      guidesCount: data.guidesCount,
      private: data.private,
      isEditable: data.isEditable,
    });
  };

  return (
    <>
      {!data?.categories?.length ? (
        <PageWraper>
          <ParagraphSmallBold>There is no guides to show.</ParagraphSmallBold>
        </PageWraper>
      ) : (
        <TableContextProvider
          total={data.count}
          onChange={onPaginationChange}
          initPage={guidesCategorizedPage}
          initSize={guidesCategorizedSize}
        >
          {data?.categories?.map((categorizedGuide: CategorizedGuide) => (
            <div
              key={categorizedGuide.guideCategoryId}
              style={{ position: 'relative', marginBottom: 26 }}
            >
              <Gap
                width='100%'
                justifyContent='space-between'
                alignItems='center'
                m='0 0 16px 0'
              >
                <SubHeading
                  color='#272A32'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/guides/${categorizedGuide.guideCategoryId}`);
                  }}
                >
                  {categorizedGuide.name}
                  <SpanSmallBold as='span'>
                    View all
                    <FaChevronRight
                      style={{ marginLeft: 6 }}
                      size={11}
                      color='rgba(146, 151, 162, 1)'
                    />
                  </SpanSmallBold>
                </SubHeading>
                {categorizedGuide.isEditable && (
                  <CategoryBadge isPrivate={categorizedGuide.private} />
                )}
                {categorizedGuide.isEditable && (
                  <Gap gap='6px'>
                    <Button
                      onClick={() =>
                        openModalAndUpdateSelection(
                          'visibility',
                          categorizedGuide
                        )
                      }
                      text='Visibility'
                      icon={<IoMdEye size={18} />}
                      variant='secondary'
                    />
                    <Button
                      onClick={() =>
                        openModalAndUpdateSelection('rename', categorizedGuide)
                      }
                      text='Rename'
                      icon={<MdEdit size={18} />}
                      variant='secondary'
                    />
                    <Button
                      onClick={() =>
                        openModalAndUpdateSelection(
                          'add-guides',
                          categorizedGuide
                        )
                      }
                      text='Guide'
                      icon={<AiOutlinePlus size={18} />}
                      variant='secondary'
                    />

                    <Button
                      onClick={() =>
                        openModalAndUpdateSelection('delete', categorizedGuide)
                      }
                      icon={<DeleteIcon />}
                      variant='destructive'
                    />
                  </Gap>
                )}
              </Gap>

              {categorizedGuide.guides.length === 0 ? (
                <CategorizedEmpty />
              ) : (
                <ThumbnailCategorizedList
                  guides={categorizedGuide.guides}
                  guideCategoryId={categorizedGuide.guideCategoryId}
                  count={categorizedGuide.guidesCount}
                  isEditable={categorizedGuide.isEditable}
                />
              )}
            </div>
          ))}
          <PaginationWrapper>
            <TableFooter>
              <TablePaginationNew />
              <TablePaginationSizeNew text='Show rows:' />
            </TableFooter>
          </PaginationWrapper>
        </TableContextProvider>
      )}
    </>
  );
};
