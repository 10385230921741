import { RecipientsSelector } from 'app/pages/reports/components/RecipientsSelector';
import { sendReactions } from 'lib/api';
import { useToastError } from 'lib/hooks';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { LoadingIndicator } from '../LoadingIndicator';
import { successToast } from '../toasts/success';
import { Modal } from './Modal';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 32px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
  margin-bottom: 8px;
`;

const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

type Props = {
  handleModalClose: () => void;
  data: Array<any>;
  videoId: string;
  type?: string;
};

export const SendReactionsModal = (props: Props) => {
  const [recipients, setRecipients] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { handleModalClose, data, videoId, type } = props;

  const { ref } = useOnClickOutside(handleModalClose);
  const { showError } = useToastError();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));
    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const send = async () => {
    setLoading(true);

    const body = {
      receivers: recipients,
      data,
      type,
    };
    try {
      await sendReactions(videoId, body);
    } catch (e) {
      console.log(e);
      showError(e);
    }

    setLoading(false);
    handleModalClose();

    successToast({
      title: `${
        type === 'text' ? 'Comments' : 'Reactions'
      } report successfully sent!`,
    });
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Send</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={handleModalClose}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <Content>
          {loading && <LoadingIndicator isLoading={loading} height='50px' />}
          {!loading && (
            <div>
              <Label>{`Share the ${
                type === 'text' ? 'comments' : 'reactions'
              } with the following recipients`}</Label>
              <RecipientsSelector
                onChange={recipients => setRecipients(recipients)}
                showIcon={true}
                isClearable={false}
                onError={email => {
                  if (email) {
                    setError('Invalid email address.');
                  } else {
                    setError('');
                  }
                }}
              />

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
          )}
        </Content>
        <Footer>
          <Button
            text={`Generate and Send`}
            onClick={send}
            disabled={loading || !recipients.length}
          />
        </Footer>
      </div>
    </Modal>
  );
};
