import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

import { guidesKeyes } from './guidesKeyes';
import { Guides } from './guidesQuery';

export interface CategorizedGuide {
  guideCategoryId: number;
  userId: number;
  customerId: number;
  name: string;
  order: number;
  private: boolean;
  createdAt: string;
  updatedAt: string;
  isEditable: boolean;
  guidesCount: number;
  guides: Guides[];
}

interface CategorizedGuideResponse {
  categories: CategorizedGuide[];
  count: number;
}

const getGuidesCategory = async (
  start: number = 0,
  limit: number,
  showOnlyCompanyGuides: boolean
): Promise<CategorizedGuideResponse> => {
  const params = {
    start,
    limit,
    showOnlyCompanyGuides,
  };

  return (
    await EXPRESS_API.get(`guide-category/`, {
      params,
    })
  ).data as CategorizedGuideResponse;
};

export const useGuideCategoryQuery = (
  start: number,
  limit: number,
  showOnlyCompanyGuides: boolean
) => {
  return useQuery(
    guidesKeyes.categorized(),
    () => getGuidesCategory(start, limit, showOnlyCompanyGuides),
    {
      refetchOnReconnect: true,
      refetchOnMount: true,
    }
  );
};
