import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { TeleprompterState, GlobalAction } from '.';
import { teleprompterReducer } from './teleprompterReducer';

type Props = {
  children: ReactNode;
};

type ContextProps = {
  state: TeleprompterState;
  dispatch: React.Dispatch<GlobalAction>;
};

const TeleprompterContext = createContext({} as ContextProps);

const initialState: TeleprompterState = {
  scripts: {},
  count: 0,
  loading: true,
};

export const TeleprompterContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(teleprompterReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <TeleprompterContext.Provider value={value}>
      {children}
    </TeleprompterContext.Provider>
  );
};

export const useTeleprompterContext = () => useContext(TeleprompterContext);
