import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import Select from 'react-select';
import {
  VideoType,
  AutomotiveRole,
  VideoEmailStatus,
  VideoStatus,
} from 'lib/const';
import { useAuth, CDSVideoListFilter } from 'lib/context';
import { useEffect, useState } from 'react';
import { getUsersByAutomotiveRoles } from 'lib/api';
import { startCase, toLower } from 'lodash';
import { VideoTypeSales } from 'lib/const/VideoType';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'react-covideo-common';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 592px;
  padding: 32px;
  background-color: #fff;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  color: ${theme.palette.coal};
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;
const Content = styled.div`
  margin-top: 32px;
`;
const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
const FilterWrapper = styled.div`
  margin-bottom: 12px;
`;

const videoTypeServiceOptions = Object.values(VideoType).map(
  (videoType: string) => {
    return {
      value: videoType,
      label: videoType ? videoType : 'All video types',
    };
  }
);

const videoTypeSalesOptions = Object.values(VideoTypeSales).map(
  (videoType: string) => {
    return {
      value: videoType,
      label: videoType ? videoType : 'All video types',
    };
  }
);

const statusOptions = Object.values(VideoStatus).map((status: string) => {
  return {
    value: status,
    label:
      status === VideoStatus.Pending
        ? 'Awaiting Approval'
        : status
          ? startCase(toLower(status))
          : 'All statuses',
  };
});

const emailStatusOptions = Object.values(VideoEmailStatus).map(
  (emailStatus: string) => {
    return {
      value: emailStatus,
      label:
        emailStatus === VideoEmailStatus.Watched
          ? 'Viewed'
          : emailStatus
            ? startCase(toLower(emailStatus))
            : 'All statuses',
    };
  }
);

type Option = {
  value: string | number;
  label: string | number;
};
type Props = {
  handleModalClose: () => void;
  filter: CDSVideoListFilter;
  currentFolder?: string;
};
export const ModalFilterVideos = ({
  handleModalClose,
  filter,
  currentFolder = '',
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { userData } = useAuth();
  const { customer } = userData;

  const [updatedFilter, setUpdatedFilter] =
    useState<CDSVideoListFilter>(filter);
  const [advisorOptions, setAdvisorOptions] = useState<Option[]>([]);
  const [recordedByOptions, setRecordedByOptions] = useState<Option[]>([]);

  const isManager = userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER;
  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;

  const showVideoStatusFilter = currentFolder !== 'Company';
  const showRecordedByFilter = !userData.isAutomotiveSalesRole && !isTechnician;
  const showAdvisorFilter =
    !userData.isAutomotiveSalesRole &&
    (!isTechnician || isManager); /* SUS-1222 Changes */

  const themes = useTheme();

  const getAssignees = async () => {
    const advisors = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_ADVISOR,
    });

    const advOptions = advisors.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    const managers = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    });

    const mngOptions = managers.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setAdvisorOptions([
      { label: 'All Advisors', value: '' },
      ...advOptions,
      ...mngOptions,
    ]);

    const recorders = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: userData.isAutomotiveSalesRole
        ? AutomotiveRole.SALESPERSON
        : AutomotiveRole.TECHNICIAN,
    });

    const bdcRecorders = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: userData.isAutomotiveSalesRole
        ? AutomotiveRole.BDC
        : AutomotiveRole.TECHNICIAN,
    });

    const recordedByOptions = recorders.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    const bdcRecordedByOptions = bdcRecorders.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setRecordedByOptions([
      {
        label: userData.isAutomotiveSalesRole
          ? 'All Salespersons'
          : 'All Technicians',
        value: '',
      },
      ...recordedByOptions,
      ...bdcRecordedByOptions,
    ]);
  };

  useEffect(() => {
    if (showAdvisorFilter || showRecordedByFilter) {
      getAssignees();
    }
  }, []);

  const handleRecordedByChange = (userId: string) => {
    setUpdatedFilter({
      ...updatedFilter,
      userId: userId,
    });
  };
  const handleAdvisorChange = (advisorId: string) => {
    setUpdatedFilter({
      ...updatedFilter,
      advisorId: advisorId,
    });
  };
  const handleStatusChange = (status: VideoStatus) => {
    setUpdatedFilter({
      ...updatedFilter,
      status: status,
    });
  };
  const handleEmailStatusChange = (emailStatus: VideoEmailStatus) => {
    setUpdatedFilter({
      ...updatedFilter,
      emailStatus: emailStatus,
    });
  };
  const handleVideoTypeChange = (videoType: string) => {
    setUpdatedFilter({
      ...updatedFilter,
      videoType: videoType,
    });
  };

  const changeLink = (filter: CDSVideoListFilter) => {
    const queryParams = queryString.parse(location.search);
    queryParams.videoType = filter.videoType || '';
    if (showAdvisorFilter) {
      queryParams.advisorId = filter.advisorId || '';
    }
    if (showRecordedByFilter) {
      queryParams.userId = filter.userId || '';
    }
    if (showVideoStatusFilter) {
      queryParams.status = filter.status || VideoStatus.NoStatus;
      queryParams.emailStatus = filter.emailStatus || VideoEmailStatus.NoStatus;
    }
    // reset to first page if filters set in case filtered results don't have multiple pages
    queryParams.page = '0';
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const handleFilterSubmit = () => {
    changeLink(updatedFilter);
    handleModalClose();
  };

  const videoTypeOptions = userData.isAutomotiveSalesRole
    ? videoTypeSalesOptions
    : videoTypeServiceOptions;

  const selectStyles = {
    control: (base: any) => ({ ...base, height: '40px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 999,
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        color: isSelected ? themes.colors.primary[100] : theme.palette.gray100,
        backgroundColor: isFocused ? theme.palette.blue05 : theme.palette.white,
        cursor: 'pointer',
        position: 'relative',
        fontWeight: 600,
        fontSize: 15,
        ':after': {
          content: '" "',
          position: 'absolute',
          right: 15,
          top: 6,
          display: isSelected ? 'inline-block' : 'none',
          transform: 'rotate(45deg)',
          height: 13,
          width: 6,
          marginLeft: '60%',
          borderBottom: `2px solid ${themes.colors.primary[100]}`,
          borderRight: `2px solid ${themes.colors.primary[100]}`,
        },
      };
    },
  };

  return (
    <Modal>
      <ModalItem>
        <Header>
          <Title>Filter Videos</Title>
          <CloseButtonWrapper>
            <CloseIcon
              width={'24px'}
              height={'24px'}
              onClick={handleModalClose}
            />
          </CloseButtonWrapper>
        </Header>
        <Content>
          {showRecordedByFilter && (
            <FilterWrapper>
              <Select
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                options={recordedByOptions}
                value={recordedByOptions.find(o => {
                  return o.value === updatedFilter.userId;
                })}
                onChange={(option: any) => handleRecordedByChange(option.value)}
                isSearchable={true}
              />
            </FilterWrapper>
          )}
          {showAdvisorFilter && (
            <FilterWrapper>
              <Select
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                options={advisorOptions}
                value={advisorOptions.find(o => {
                  return o.value === updatedFilter.advisorId;
                })}
                onChange={(option: any) => handleAdvisorChange(option.value)}
                isSearchable={true}
              />
            </FilterWrapper>
          )}
          {showVideoStatusFilter && isTechnician && (
            <FilterWrapper>
              <Select
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                options={statusOptions}
                value={statusOptions.find(o => {
                  return o.value === updatedFilter.status;
                })}
                onChange={(option: any) => handleStatusChange(option.value)}
                isSearchable={true}
              />
            </FilterWrapper>
          )}
          {showVideoStatusFilter && !isTechnician && (
            <FilterWrapper>
              <Select
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                options={emailStatusOptions.filter(
                  (status: any) => status.value !== VideoEmailStatus.Deleted
                )}
                value={emailStatusOptions.find(o => {
                  return o.value === updatedFilter.emailStatus;
                })}
                onChange={(option: any) =>
                  handleEmailStatusChange(option.value)
                }
                isSearchable={true}
              />
            </FilterWrapper>
          )}
          <FilterWrapper>
            <Select
              styles={selectStyles}
              menuPortalTarget={document.body}
              menuPosition='fixed'
              menuPlacement={'bottom'}
              options={videoTypeOptions}
              value={videoTypeOptions.find(o => {
                return o.value === updatedFilter.videoType;
              })}
              onChange={(option: any) => handleVideoTypeChange(option.value)}
              isSearchable={true}
            />
          </FilterWrapper>
        </Content>
        <ButtonsWrapper>
          <Button
            text={`Save Filters`}
            variant='primary'
            onClick={() => handleFilterSubmit()}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
