import { UserInvitesParams } from './useUserInvitesQuery';
import { CustomerUsageQueryParams, UserSearchParams } from './types';

export const superAdminKeys = {
  reseller: () => ['RESELLER'],
  customers: (id: string) => ['CUSTOMERS', id],
  all_customers: () => ['USERS'],
  users: (customerId: string, departmentId: string) => [
    'USERS',
    customerId,
    departmentId,
  ],
  user_search: (params: UserSearchParams) => ['USER_SEARCH', params],
  all_users: () => ['CUSTOMERS'],
  departments: (id: string) => ['DEPARTMENTS', id],
  reseller_departments: (id: string, search: string) => [
    'RESELLLER_DEPARTMENTS',
    id,
    search,
  ],
  reseller_users: (id: string) => ['RESELLLER_DEPARTMENTS', id],
  all_invite_users: () => ['INVITE_USERS'],
  invite_users: (params?: UserInvitesParams) => ['INVITE_USERS', params],
  customer_usage: (params: CustomerUsageQueryParams) => [
    'CUSTOMER_USAGE',
    params,
  ],
};
