import * as React from 'react';
import styled from 'styled-components/macro';

const Icon = styled.path`
  fill: ${props => props.color || 'currentColor'};
  padding: ${props => props.color || '7px'};
  opacity: ${props => props.opacity || 'inherit'};
`;

export const DownloadIcon = props => {
  const { onClick, width, height, ...rest } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '24px'}
      height={height || '24px'}
      viewBox='0 0 24 24'
      onClick={onClick}
      style={{ padding: props.padding }}
    >
      <g fillRule='evenodd' transform='translate(-16 -12)'>
        <Icon
          {...rest}
          d='M24.104 27.642L28 31.5l3.91-3.858h8.052v7.283H16.038v-7.283h8.066zM34.5 31.5H33V33h1.5v-1.5zm3 0H36V33h1.5v-1.5zM30.76 13v7.358h5.74L28 29l-8.5-8.642h5.74V13h5.52z'
        />
      </g>
    </svg>
  );
};
