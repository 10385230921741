import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import { theme } from 'lib/style';
import { RecordBoth } from './recordBoth';
import { Upload } from '../../Upload';
import { useHistory } from 'react-router';
import MovieIcon from 'lib/images/MovieIcon';
import { DetailedVideoPreview } from 'app/pages/video/videoDetails/main/DetailedVideoPreview';
import { getTitle } from 'lib/utils/functions';
import { DEFAULT_UPLOAD_FOLDER } from 'lib/components/videoUpload/VideoUpload';
import { Option } from 'lib/components/dropdown/Dropdown';
import { SHARED_FOLDER_PREFIX } from 'lib/const/Folders';
import { Button } from 'react-covideo-common';
import { isEmpty } from 'lodash';

const MainDiv = styled.div`
  min-height: 630px;
  display: block;
  margin-top: 64px;
`;

const Content = styled.div`
  padding: 32px 20px 112px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 710px;
  height: 400px;
  border-radius: 8px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  video {
    border-radius: 8px;
  }
`;

const ContentHeader = styled.div`
  width: 710px;
  height: 40px;
  margin: 0 32px 32px;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.67;
  color: ${theme.palette.black_1_100};
  display: flex;
  justify-content: space-between;
`;

type Props = {
  dispatch?: any;
  loading?: boolean;
  handleShowRecordHome?: () => void;
  handleRecordingBlobReady?: (url: string) => void;
};

export const Main = ({
  loading = false,
  handleShowRecordHome,
  handleRecordingBlobReady,
}: Props) => {
  const history = useHistory();
  const [title, setTitle] = useState(0);
  const [recording, setRecording] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState('');
  const [recordingBlobUrl, setRecordingBlobUrl] = React.useState('');
  const [attributeValues, setAttributeValues] = React.useState({});
  const [videoTags, setVideoTags] = React.useState([]);
  const [videoRequestData, setVideoRequestData] = React.useState({});
  const [continueToSendShare, setContinueToSendShare] = React.useState(false);
  const videoTitle = getTitle();

  const titleMap: { [key: number]: string } = {
    0: 'New screen recording',
    1: 'Recording',
    2: videoTitle,
  };
  const [selectedFolderOption, setSelectedFolderOption] = useState<Option>(
    DEFAULT_UPLOAD_FOLDER
  );

  const handleUpload = async (attributeValuesObject: any, tags: any) => {
    let file = await fetch(recordingBlobUrl || '').then((r: any) => r.blob());
    setFile(file);
    setFileUrl(recordingBlobUrl);
    setAttributeValues(attributeValuesObject || {});
    setVideoTags(tags || []);
    setUploading(true);
    setRecordingBlobUrl('');
  };

  const handleGoToRecordHome = () => {
    if (!handleShowRecordHome) {
      history.push('/record/Record_Home');
      return;
    }
    handleShowRecordHome();
  };

  const handleRecordingUrlGeneration = (url: string) => {
    if (handleRecordingBlobReady) {
      handleRecordingBlobReady(url);
      return;
    }
    setRecordingBlobUrl(url);
  };

  return (
    <MainDiv className='recordOptionWrapper'>
      <Content>
        {!recordingBlobUrl && (
          <ContentHeader>
            {title < 2 && <span>{titleMap[title]}</span>}
            {!recording && (
              <Button
                icon={<MovieIcon opacity={1} />}
                variant='secondary'
                text={'Change recording option'}
                onClick={handleGoToRecordHome}
              />
            )}
          </ContentHeader>
        )}
        <LoadingIndicator isLoading={loading} />
        {!loading && !!recordingBlobUrl && (
          <div style={{ width: '90%' }}>
            <DetailedVideoPreview
              videoSource={recordingBlobUrl}
              title='Preview recorded video'
              handleUpload={handleUpload}
              videoTitle={videoTitle}
              videoRequestData={videoRequestData}
              onChangeVideoRequestData={setVideoRequestData}
              handleContinueToSendShare={setContinueToSendShare}
              folderValue={selectedFolderOption}
              setFolderValue={setSelectedFolderOption}
              handleAttributeChange={(values?: object) => {
                if (values) setAttributeValues(values);
              }}
            />
          </div>
        )}
        {!loading && !recordingBlobUrl && (
          <Container>
            {!uploading && (
              <RecordBoth
                onRecordingStart={() => {
                  setTitle(1);
                  setRecording(true);
                }}
                onRecordingEnd={() => setTitle(2)}
                onUpload={handleUpload}
                uploadDisabled={!videoTitle}
                onRecordingUrlGeneration={(url: string) =>
                  handleRecordingUrlGeneration(url)
                }
                handleGoToRecordHome={handleGoToRecordHome}
              />
            )}
            {uploading && file && fileUrl && (
              <Upload
                file={file}
                fileName={
                  !isEmpty(attributeValues)
                    ? attributeValues[
                        'covideoName' as keyof typeof attributeValues
                      ]
                    : videoTitle
                }
                videoAttributesValues={attributeValues}
                videoTags={videoTags}
                videoRequestData={videoRequestData}
                continueToSendShare={continueToSendShare}
                selectedFolder={
                  !!selectedFolderOption?.access
                    ? `${SHARED_FOLDER_PREFIX}${selectedFolderOption.value}`
                    : selectedFolderOption?.label
                }
              />
            )}
          </Container>
        )}
      </Content>
    </MainDiv>
  );
};
