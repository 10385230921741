import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import ChromeWithSquareBackground from 'lib/images/ChromeWithSquareBackground';
import OutlookWithSquareBackground from 'lib/images/OutlookWithSquareBackground';
import CovideoWithSquareBackground from 'lib/images/CovideoWithSquareBackground';
import AppleSquareBg from 'lib/images/apple-with-square-background.svg';
import AndroidSquareBg from 'lib/images/android-square-bg.svg';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/context';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 850px;
  height: 64px;
  &:nth-of-type(n + 1) {
    margin-top: 24px;
  }
  img {
    &:nth-of-type(2) {
      margin-left: 8px;
    }
  }
`;

const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 169px;
  height: 48px;
  border: 1px solid ${theme.palette.primaryDarkBlue};
  background-color: #fff;
  cursor: pointer;
  &:nth-of-type(n + 1) {
    margin-left: 24px;
  }
  &:focus {
    outline: 0;
  }
`;

const ActionText = styled.p`
  flex-grow: 2;
  ${theme.fontBold700}
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 31, 104);
`;

const Title = styled.h3`
  ${theme.fontNormal500}
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgb(50, 64, 88);
  margin: 0;
`;

const Description = styled.p`
  ${theme.fontNormal500}
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin: 0;
`;

const OptionWrapper = styled.summary`
  margin-left: 24px;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 64px;
`;

export const CovideoUseOptions = () => {
  const history = useHistory();
  const { whitelabel } = useAuth();

  return (
    <Container>
      <Row>
        <ChromeWithSquareBackground />
        <OptionWrapper>
          <Title>Chrome Extension</Title>
          <Description>
            Access {whitelabel.name} directly from your Gmail account
          </Description>
        </OptionWrapper>
        <ActionButton
          onClick={() => window.open(whitelabel.browserExtensionURL, '_blank')}
        >
          <ActionText>Get Extension</ActionText>
        </ActionButton>
      </Row>
      <Row>
        <OutlookWithSquareBackground />
        <OptionWrapper>
          <Title>Outlook Add-In</Title>
          <Description>
            Access {whitelabel.name} directly from your Outlook account
          </Description>
        </OptionWrapper>
        <ActionButton
          onClick={() => window.open(whitelabel.outlookURL, '_blank')}
        >
          <ActionText>Download</ActionText>
        </ActionButton>
      </Row>
      <Row>
        <CovideoWithSquareBackground />
        <OptionWrapper>
          <Title>Web App</Title>
          <Description>
            Continue using {whitelabel.name} through {whitelabel.homepage}
          </Description>
        </OptionWrapper>
        <ActionButton onClick={() => history.push('/record/Record_Home')}>
          <ActionText>Use Web App</ActionText>
        </ActionButton>
      </Row>
      <Row>
        <img src={AppleSquareBg} alt={'Dark grey Apple Icon'} />
        <img src={AndroidSquareBg} alt={'Light green Android Icon'} />
        <OptionWrapper>
          <Title>Mobile App</Title>
          <Description>Get {whitelabel.name} for iOS and Android</Description>
        </OptionWrapper>
        <ActionButton onClick={() => window.open(whitelabel.iOSURL, '_blank')}>
          <ActionText>Get iOS App</ActionText>
        </ActionButton>
        <ActionButton
          onClick={() => window.open(whitelabel.androidURL, '_blank')}
        >
          <ActionText>Get Android App</ActionText>
        </ActionButton>
      </Row>
    </Container>
  );
};
