import styled from 'styled-components/macro';
import { ReactComponent as LiveVideoSvg } from './liveVideo.svg';

const LiveVideoIcon = styled(LiveVideoSvg)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  g {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export default LiveVideoIcon;
