import * as React from 'react';
import { useMemo } from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { PreLoader } from './PreLoader';
import { getVideosCount } from 'lib/api';
import { useAuth } from 'lib/context';

const Wrapper = () => {
  return useMemo(() => <PreLoader />, []);
};

export const RecordCam = () => {
  const { userData } = useAuth();
  const [userVideoCount, setUserVideoCount] = React.useState(0);

  const fetchUserVideoCount = async () => {
    const { count } = await getVideosCount();
    setUserVideoCount(count);
  };

  React.useEffect(() => {
    fetchUserVideoCount();
  }, []);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id != 0) {
        if (
          userPackageDetails.maxVideosCreated != 0 &&
          userVideoCount >= userPackageDetails.maxVideosCreated
        ) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, [userData, userVideoCount]);

  return (
    <>
      <DocumentHead title='Record Home' />
      <PageTemplate main={<Wrapper />} />
    </>
  );
};
