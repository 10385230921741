import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-covideo-common';
import { HoverPopup, LoadingIndicator, ModalReassign } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { MdOutlineChevronRight } from 'react-icons/md';
import { RiPencilFill } from 'react-icons/ri';
import IconAccount from 'lib/images/IconAccount';
import { QuoteList, ActivityList, CustomerHover } from '../components';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import dayjs from 'dayjs';
import ReassignIcon from 'lib/images/ReassignIcon';
import { successToast } from 'lib/components/toasts/success';
import { calculateCloseRatio } from 'lib/utils/automotive';
import { QUOTE_SETTINGS } from '../components/RepairOrderListing';
import { RepairOrderStatus, VideoEmailStatus } from 'lib/const';
import { useGetSingleRepairOrder } from 'lib/api/repairOrders/getSingleRepairOrder';
import { useReassignAdvisor } from 'lib/api/repairOrders/reassignAdvisor';
import { QuoteItem } from 'lib/api/repairOrders/types';
import { useUpdateRepairOrderQuoteItems } from 'lib/api/repairOrders/updateQuoteItems';

interface MatchParams {
  repairOrderId: string;
}

const Container = styled.div`
  padding: 64px 0 0;
  width: 1216px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 112px);
`;

const Content = styled.div`
  margin-top: 32px;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span {
    color: ${theme.palette.gray80};
    font-weight: normal;
    font-size: 16px;
    margin-left: 8px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const HeaderSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  margin-right: 20px;
  white-space: nowrap;
  color: ${theme.palette.gray100};
`;
type BadgeProps = {
  status: string;
};
const Badge = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 36px;
  color: ${props =>
    props.status === 'Sent' ? theme.palette.blue80 : theme.palette.white};
  background-color: ${props =>
    props.status === 'Sent' ? theme.palette.blue05 : theme.palette.blue80};
  margin-left: 16px;
`;

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const Box = styled.div`
  box-sizing: border-box;
  width: 280px;
  height: 64px;
  padding: 13px 16px 8px 16px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 7px;
  > p {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.covideoBlue100};
    margin-bottom: 3px;
    margin-top: 0;
    > svg {
      margin-right: 7px;
    }
  }
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.blue60};
  }
`;

const QuoteActivityContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const { repairOrderId } = props.match.params;
  const {
    isLoading: loading,
    data: repairOrder,
    refetch: refreshRepairOrderDetails,
  } = useGetSingleRepairOrder(repairOrderId);

  const { userData } = useAuth();
  const { dollarCloseRatio, itemCloseRatio } = calculateCloseRatio(
    repairOrder ? repairOrder?.quoteItems : []
  );

  const [isEditingQuote, setIsEditingQuote] = useState(false);
  const [note, setNote] = useState('');
  const [updatedQuoteItems, setUpdatedQuoteItems] = useState(
    repairOrder.quoteItems || []
  );
  const [approvedTotal, setApprovedTotal] = useState(0);

  const history = useHistory();
  const { mutateAsync: editRepairOrderQuoteItems } =
    useUpdateRepairOrderQuoteItems();
  const { mutateAsync: editAdvisorRepairOrder } = useReassignAdvisor();

  const [showModalReassign, setShowModalReassign] = useState(false);

  const calculateApprovedPrice = (quoteItems: QuoteItem[]) => {
    const totalPriceApproved = quoteItems
      .filter((item: QuoteItem) => item.approved)
      .reduce((a, b: QuoteItem) => a + Number(b.price), 0);
    setApprovedTotal(totalPriceApproved);
  };

  const handleCancelClick = () => {
    refreshRepairOrderDetails();
    setNote('');
  };

  const goToRepairOrders = () => history.push(`/repair-orders`);
  const goToEditResend = () =>
    history.push(`/repair-orders/edit-resend/${repairOrderId}`);

  useEffect(() => {
    if (repairOrder && repairOrder.quoteItems) {
      setUpdatedQuoteItems(repairOrder.quoteItems);
      calculateApprovedPrice(repairOrder.quoteItems);
    }
  }, [repairOrder]);

  const isServiceManager =
    userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER;

  const submitEditQuote = async () => {
    await editRepairOrderQuoteItems({
      data: {
        quoteItems: updatedQuoteItems,
        status: RepairOrderStatus.Responded,
        note,
      },
      repairOrderId,
    });
    calculateApprovedPrice(updatedQuoteItems);
    setIsEditingQuote(false);
  };

  const handleReassignSubmit = async (
    advisorId: string,
    assignToIds: string[],
    advisor: any
  ) => {
    const advisorName =
      advisor.firstName && advisor.lastName
        ? `${advisor.firstName} ${advisor.lastName}`
        : 'New Advisor';
    assignToIds.map(async (repairOrderId: string) => {
      await editAdvisorRepairOrder({ advisorId, repairOrderId });
    });
    successToast({
      title: `${advisorName} is now assigned to this order!`,
    });
  };

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='300px' />;
  }

  return (
    <>
      <Container>
        <Content>
          <>
            <Breadcrumbs>
              <Button
                onClick={() => goToRepairOrders()}
                variant='transparent'
                text='Repair Orders'
              />
              <MdOutlineChevronRight size={16} />
              <span>RO# {repairOrder.repairOrderNumber || ''}</span>
            </Breadcrumbs>
            <Header>
              <HeaderSide>
                <Title>{repairOrder.repairOrderNumber}</Title>
                <Badge status={repairOrder.status}>{repairOrder.status}</Badge>
              </HeaderSide>
              <HeaderSide>
                {isServiceManager && (
                  <Button
                    onClick={() => setShowModalReassign(true)}
                    text='Reassign'
                    variant='secondary'
                    icon={<ReassignIcon />}
                    disabled={
                      repairOrder?.video?.videoRequest?.emailStatus ===
                      VideoEmailStatus.Deleted
                    }
                  />
                )}
                <Button
                  onClick={() => goToEditResend()}
                  text='Edit & Resend'
                  variant='secondary'
                  icon={<RiPencilFill />}
                  disabled={
                    repairOrder?.video?.videoRequest?.emailStatus ===
                    VideoEmailStatus.Deleted
                  }
                />
              </HeaderSide>
            </Header>
            <BoxWrapper>
              <HoverPopup
                popup={
                  <CustomerHover
                    firstName={repairOrder.firstName}
                    lastName={repairOrder.lastName}
                    email={repairOrder.email}
                    phone={repairOrder.phone}
                  />
                }
                hoverable={
                  <Box>
                    <p>
                      <IconAccount />
                      {`${
                        repairOrder.firstName && repairOrder.lastName
                          ? `${repairOrder.firstName} ${repairOrder.lastName}`
                          : `${repairOrder.email}`
                      }`}
                    </p>
                    <span>Customer</span>
                  </Box>
                }
              />
              <Box>
                <p>
                  {dayjs(repairOrder.createdAt).format('MMM DD YYYY h:mm A')}
                </p>
                <span>Date</span>
              </Box>
              <Box>
                <p>
                  {userData.customer.quoteSettings === QUOTE_SETTINGS.ITEM
                    ? itemCloseRatio
                    : dollarCloseRatio}
                </p>
                <span>Close Ratio</span>
              </Box>
              <Box>
                <p>
                  {repairOrder.advisor.firstName} {repairOrder.advisor.lastName}
                </p>
                <span>Advisor</span>
              </Box>
            </BoxWrapper>
            <QuoteActivityContainer>
              <QuoteList
                status={repairOrder.status}
                total={approvedTotal}
                submitEditQuote={submitEditQuote}
                emailStatus={
                  repairOrder?.video?.videoRequest?.emailStatus || ''
                }
                setUpdatedQuoteItems={setUpdatedQuoteItems}
                setNote={setNote}
                note={note}
                updatedQuoteItems={updatedQuoteItems}
                isEditingQuote={isEditingQuote}
                setIsEditingQuote={setIsEditingQuote}
                handleCancelClick={handleCancelClick}
              />
              <ActivityList activities={repairOrder.activity || []} />
            </QuoteActivityContainer>
          </>
        </Content>
      </Container>
      {showModalReassign && (
        <ModalReassign
          handleSubmit={handleReassignSubmit}
          handleModalClose={() => setShowModalReassign(false)}
          title={'Reassign order to another advisor'}
          assignTo={[repairOrderId]}
        />
      )}
    </>
  );
});
