import {
  WebsiteOverlay,
  fetchUsers,
  getDepartments,
  getExitLinksSetsForSuperAdmin,
  getLandingPagesForSuperAdmin,
  getOverlaysForSuperAdmin,
  updateDefaultsForSuperAdmin,
} from 'lib/api';
import { Dropdown, Modal, Search } from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { successToast } from 'lib/components/toasts/success';
import {
  captionOptions,
  captionVisibilityOptions,
  emailIconOptions,
  emailOptions,
} from 'lib/const/ManageDefaultOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { SuperAdminUser } from 'lib/context';
import { useToastError } from 'lib/hooks';
import CloseIcon from 'lib/images/CloseIcon';
import { theme } from 'lib/style';
import { InvertColor } from 'lib/utils';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { ColorResult } from 'react-color';
import { MdArrowForward } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CloseButtonWrap,
  Container,
  ContainerArrow,
  ContainerList,
  ContainerListHeader,
  ContainerOptions,
  Content,
  Header,
  HeaderTitle,
  ListItem,
  MainUserList,
  OptionItems,
  SubHeader,
} from './ManageDefaultComponents';
import { IdOption, OptionsSelector } from './OptionsSelector';
import { DefaultDataPayload, DefaultDataType } from './ManageDefaultTypes';
import { ColorSelector } from './ColorSelector';
import { Button } from 'react-covideo-common';

type Props = {
  customerId?: number;
  handleModalClose: (shouldRefresh?: boolean) => void;
};

export const SuperAdminManageDefaultModal = (props: Props) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [allSelected, setAllSelected] = React.useState(false);
  const [deptList, setDeptList] = React.useState<
    Array<{ Name: string; DepartmentID: number }>
  >([]);
  const [selectedDept, setSelectedDept] = React.useState<number>(0);
  const [selectedUser, setSelectedUser] = React.useState<number[]>([]);
  const [usersList, setUsersList] = React.useState<Array<SuperAdminUser>>([]);
  const [totalUser, setTotalUser] = React.useState(0);
  const [templateLoading, setTemplateLoading] = React.useState<boolean>(false);
  const [templateOptions, setTemplateOptions] = React.useState<
    Array<{ label: string; value: number }>
  >([{ label: 'Do not change', value: 0 }]);
  const [linkSetOptions, setLinkSetOptions] = React.useState<Array<IdOption>>([
    { title: 'Do not change', id: 0 },
  ]);
  const [overlayOptions, setOverlayOptions] = React.useState<
    Partial<WebsiteOverlay>[]
  >([{ title: 'Do not change', id: 0 }]);
  const [pageTemplate, setPageTemplate] = React.useState(0);
  const [pageTemplateCopy, setPageTemplateCopy] = React.useState(0);
  const [searchTemplate, setSearchTemplate] = React.useState('');
  const [templateCount, setTemplateCount] = React.useState<number>(0);
  const [iconColor, setIconColor] = React.useState('#F6F7F9');
  const [defaultData, setDefaultData] = React.useState<DefaultDataType>({
    landingPageId: 0,
    ctaSetId: 0,
    playerColor: '',
    emailSetting: -1,
    emailIcon: -1,
    overlayUrlId: 0,
    autoTranscribe: -1,
    transcriptionDefaultEnabled: -1,
  });

  const limitTemplate = 100;
  const size = 10;

  const { handleModalClose, customerId } = props;

  const loadTypes = { MORE: 'more', INITIAL: 'initial' };

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const getData = () => {
    const payload: DefaultDataPayload = {};
    const {
      landingPageId,
      ctaSetId,
      playerColor,
      emailSetting,
      emailIcon,
      overlayUrlId,
      autoTranscribe,
      transcriptionDefaultEnabled,
    } = defaultData;
    if (landingPageId != 0) {
      payload.designId = landingPageId;
    }
    if (ctaSetId != 0) {
      payload.ctaSetId = ctaSetId;
    }
    if (playerColor != '') {
      payload.playerColor = { playerBackgroundColor: playerColor };
    }
    if (emailSetting != -1) {
      payload.notifyMe = emailSetting === 1 ? true : false;
    }
    if (selectedDept === 0 && allSelected) {
      payload.isAll = true;
    }
    if (selectedDept != 0 && allSelected) {
      payload.departmentId = selectedDept;
    }
    if (!allSelected) {
      payload.userIds = selectedUser;
    }
    if (emailIcon !== -1) {
      payload.emailIcon = emailIcon;
    }
    if (overlayUrlId !== 0) {
      payload.overlayUrlId = overlayUrlId;
    }
    if (autoTranscribe !== -1) {
      payload.autoTranscribe = autoTranscribe === 1 ? true : false;
    }
    if (transcriptionDefaultEnabled !== -1) {
      payload.transcriptionDefaultEnabled =
        transcriptionDefaultEnabled === 1 ? true : false;
    }

    return payload;
  };

  const getSelectValue = deptList.find(o => {
    return o.DepartmentID === selectedDept;
  });

  const manageDefault = async () => {
    setLoading(true);
    try {
      const payload = getData();
      await updateDefaultsForSuperAdmin(customerId || 0, payload);
      setLoading(false);
      successToast({
        title: `Default applied to all selected users!`,
      });
    } catch (error) {
      showError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const searchTemplateInput = debounce((e: string) => {
    setSearchTemplate(e);
    e ? setPageTemplate(0) : setPageTemplate(pageTemplateCopy);
  }, 600);

  const handleChangePlayerBackgroundColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    setDefaultData({ ...defaultData, playerColor: hexAlpha });

    const isBlack = InvertColor(color.hex);

    if (isBlack !== iconColor) {
      setIconColor(isBlack);
    }
  };

  const fetchTemplates = async () => {
    setTemplateLoading(true);
    const templates = await getLandingPagesForSuperAdmin({
      start: pageTemplate * limitTemplate,
      limit: limitTemplate,
      search: searchTemplate,
      customerId,
    });
    let options: Array<{ label: string; value: number }> = JSON.parse(
      JSON.stringify(templateOptions)
    );
    templates.templates.forEach(e => {
      options = [...options, { label: e.title, value: e.id }];
    });
    options = uniqBy(options, 'value');
    setTemplateOptions(options);
    setTemplateCount(templates.count);
    setTemplateLoading(false);
  };

  const loadUsers = async (loadType: string) => {
    try {
      setLoading(true);
      const response = await fetchUsers({
        start: loadType === loadTypes.INITIAL ? 0 : page,
        limit: size,
        search: searchQuery,
        status: VerificationStatus.ACTIVE,
        departmentId: selectedDept,
        customerId,
      });
      if (allSelected) {
        const filterData = response.users.filter(
          (e: SuperAdminUser) => !selectedUser.includes(e.id)
        );
        const selection = selectedUser;
        filterData.forEach((e: SuperAdminUser) => {
          selection.push(e.id);
        });
        setSelectedUser(selection);
      }

      if (loadType === loadTypes.INITIAL) {
        setPage(0);
        setUsersList([...response.users]);
      } else {
        setUsersList([...usersList, ...response.users]);
      }

      setTotalUser(parseInt(response.count, 10));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  useEffect(() => {
    //initial load, on searchQuery and on selectedDept change load
    loadUsers(loadTypes.INITIAL);
  }, [selectedDept, searchQuery]);

  useEffect(() => {
    if (page > 0) {
      //infinite load
      loadUsers(loadTypes.MORE);
    }
  }, [page, size]);

  const loadWebsiteOverlays = async () => {
    const overlays = await getOverlaysForSuperAdmin({ customerId });
    let options: Partial<WebsiteOverlay>[] = overlayOptions;
    const filterArray = overlays.websiteUrls;
    const overlayData = [...options, ...filterArray];
    setOverlayOptions(overlayData);
  };

  const loadExitlinks = async () => {
    const linksetsData = await getExitLinksSetsForSuperAdmin({
      customerId,
      limit: 1000,
    });
    let options = JSON.parse(JSON.stringify(linkSetOptions));
    const filterArray = linksetsData?.linksets.filter(
      (e: { title: string; id: number; company: number }) => e.company != 0
    );
    const ctaData = [...options, ...(filterArray || [])];
    setLinkSetOptions(ctaData);
  };

  useEffect(() => {
    const getDept = async () => {
      try {
        setLoading(true);
        const dept = await getDepartments(customerId, {});
        const mapArr = [{ Name: 'All Departments', DepartmentID: 0 }];
        setDeptList([...mapArr, ...dept.data]);
        setSelectedDept(mapArr[0]?.DepartmentID); // Set 'All Departments' as default selected option
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError(error);
      }
    };
    getDept();
    loadWebsiteOverlays();
    loadExitlinks();
  }, []);

  const onUsersSearch = (inputValue: string) => {
    setSearchQuery(inputValue);
  };

  useEffect(() => {
    if (selectedUser.length === usersList.length && usersList.length)
      setAllSelected(true);
  }, [selectedUser, totalUser, usersList]);

  React.useEffect(() => {
    fetchTemplates();
  }, [pageTemplate, searchTemplate]);

  return (
    <Modal style={{ position: 'fixed' }} maxHeight='95%'>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Manage Defaults</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <CloseIcon
              onClick={() => handleModalClose()}
              width={24}
              height={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content style={{ width: 900 }}>
          <Container>
            <ContainerList>
              <div style={{ flex: 4, gap: 12, display: 'flex' }}>
                <Search
                  placeholder='Search Users...'
                  onSearch={onUsersSearch}
                  width='50%'
                />
                <div style={{ width: '100%' }}>
                  <Dropdown
                    options={deptList}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.DepartmentID}
                    creatable={false}
                    value={getSelectValue}
                    onChange={option => {
                      setAllSelected(false);
                      setSelectedUser([]);
                      setUsersList([]);
                      setPage(0);
                      setSelectedDept(option.DepartmentID);
                    }}
                  />
                </div>
              </div>
              <ContainerListHeader>
                <SubHeader>
                  <CheckboxInput
                    blueCheck={true}
                    checkGroupIndicator={!!selectedUser.length && !allSelected}
                    checked={allSelected}
                    grayCheck={true}
                    ignoreGrayForAllSelect={true}
                    onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                      let { checked } = event.target as HTMLInputElement;
                      if (checked) {
                        setAllSelected(true);
                        setSelectedUser(usersList.map(e => e.id));
                      } else {
                        setAllSelected(false);
                        setSelectedUser([]);
                      }
                    }}
                  />
                  &nbsp;All users
                </SubHeader>
              </ContainerListHeader>
              <MainUserList>
                <InfiniteScroll
                  dataLength={usersList.length}
                  next={() => {
                    setPage(page + 1);
                  }}
                  hasMore={totalUser > usersList.length}
                  loader={<h4>Loading...</h4>}
                  height={300}
                  endMessage={''}
                >
                  {usersList.length ? (
                    usersList.map((e: SuperAdminUser, i: number) => {
                      return (
                        <ListItem key={`user-item-${i}`}>
                          <div className='item--label'>
                            <CheckboxInput
                              blueCheck={true}
                              checkGroupIndicator={false}
                              checked={selectedUser.includes(e.id)}
                              onChange={(event: React.SyntheticEvent) => {
                                event.stopPropagation();
                                let { checked } =
                                  event.target as HTMLInputElement;
                                if (checked) {
                                  setSelectedUser([...selectedUser, e.id]);
                                } else {
                                  setAllSelected(false);
                                  setSelectedUser(
                                    selectedUser.filter(
                                      (el: number) => el != e.id
                                    )
                                  );
                                }
                              }}
                            />
                            <label>
                              {e.firstName} {e.lastName}
                            </label>
                          </div>
                          <label className='email'>{e.email}</label>
                        </ListItem>
                      );
                    })
                  ) : (
                    <div style={{ textAlign: 'center' }}>No data</div>
                  )}
                </InfiniteScroll>
              </MainUserList>
            </ContainerList>
            <ContainerArrow>
              <MdArrowForward size={24} color={'rgba(0, 27, 83, 0.2)'} />
            </ContainerArrow>
            <ContainerOptions>
              <div style={{ flexGrow: 1 }}>
                <b>Defaults</b>
                <OptionItems style={{ marginTop: '5px' }}>
                  Landing page
                  <Dropdown
                    creatable={false}
                    menuPosition='fixed'
                    dropdownHeight={300}
                    options={templateOptions}
                    value={templateOptions.find(o => {
                      return o.value === defaultData.landingPageId;
                    })}
                    onChange={option => {
                      setDefaultData({
                        ...defaultData,
                        landingPageId: option.value,
                      });
                      setTemplateLoading(false);
                    }}
                    onMenuScrollToBottom={() => {
                      if (templateOptions.length < templateCount)
                        setPageTemplate(pageTemplate + 1);
                      if (!searchTemplate)
                        setPageTemplateCopy(pageTemplate + 1);
                    }}
                    onInputChange={searchTemplateInput}
                    isLoading={templateLoading}
                    onMenuClose={() => setTemplateLoading(false)}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  CTA set
                  <Dropdown
                    creatable={false}
                    options={linkSetOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    value={linkSetOptions.find(o => {
                      return o.id === defaultData.ctaSetId;
                    })}
                    onChange={option => {
                      setDefaultData({ ...defaultData, ctaSetId: option.id });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <OptionItems>
                  Website Overlay
                  <Dropdown
                    creatable={false}
                    options={overlayOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    value={overlayOptions.find((o: Partial<WebsiteOverlay>) => {
                      return o.id === defaultData.overlayUrlId;
                    })}
                    onChange={(option: WebsiteOverlay) => {
                      setDefaultData({
                        ...defaultData,
                        overlayUrlId: option.id,
                      });
                    }}
                    className='option-container'
                  />
                </OptionItems>
                <ColorSelector
                  value={defaultData.playerColor}
                  onChange={handleChangePlayerBackgroundColor}
                />
                <OptionsSelector
                  title='Email notifications'
                  options={emailOptions}
                  defaultValue={defaultData.emailSetting}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      emailSetting: option.id,
                    });
                  }}
                />
                <OptionsSelector
                  title='Video Thumbnail'
                  options={emailIconOptions}
                  defaultValue={defaultData.emailIcon}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      emailIcon: option.id,
                    });
                  }}
                />
                <OptionsSelector
                  title='Captions'
                  options={captionOptions}
                  defaultValue={defaultData.autoTranscribe}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      autoTranscribe: option.id,
                    });
                  }}
                />
                <OptionsSelector
                  title='Caption Visibility'
                  options={captionVisibilityOptions}
                  defaultValue={defaultData.transcriptionDefaultEnabled}
                  onChange={option => {
                    setDefaultData({
                      ...defaultData,
                      transcriptionDefaultEnabled: option.id,
                    });
                  }}
                />
              </div>
              <div style={{ flexShrink: 0 }}>
                <Button
                  text='Apply to selected users'
                  onClick={async () => {
                    await manageDefault();
                  }}
                  disabled={loading || !selectedUser.length}
                />
              </div>
            </ContainerOptions>
          </Container>
        </Content>
      </div>
    </Modal>
  );
};
