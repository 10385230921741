import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { useAuth } from 'lib/context';
import { useCovideoTheme } from 'react-covideo-common';

export const useNavigationColors = () => {
  const { whitelabel, userData } = useAuth();
  const themes = useCovideoTheme();

  const getBgColor = () => {
    if (whitelabel.name === WHITELABEL_NAME.COVIDEO) {
      return userData.isAutomotive
        ? themes.colors.white[100]
        : themes.colors.secondary[100];
    }
    return themes.colors.secondary[100];
  };

  const getColor = () => {
    if (whitelabel.name === WHITELABEL_NAME.COVIDEO) {
      return userData.isAutomotive
        ? themes.colors.secondary[100]
        : themes.colors.white[80];
    }
    return themes.colors.white[80];
  };

  const backgroundColor = getBgColor();
  const color = getColor();

  return { color, backgroundColor };
};
