import React from 'react';
import styled from 'styled-components/macro';
import MaskedInput from 'react-text-mask';
import { toMMSS } from 'lib/utils/functions';
import { theme } from 'lib/style';

const COLON = ':';

const timePipe = (conformedValue: string) => {
  if (!!conformedValue && !!conformedValue.length) {
    const parts = conformedValue.split(COLON);
    if (!!parts && !!parts.length) {
      const minute = parts[0];

      if (
        minute.length === 1 &&
        (parts.length === 2 || parseInt(minute, 10) >= 1)
      ) {
        parts[0] = `0${minute}`;
      }

      return parts.join(COLON);
    }
  }
  return conformedValue;
};

const fromMMSS = (mmss: string): number => {
  const [minutes, seconds] = mmss.split(COLON).map(Number);
  return minutes * 60 + seconds;
};

const MaskedInputStyles = styled(MaskedInput)<{ error?: string }>`
  background: #ffffff;
  border: 1px solid
    ${({ error }) => (!!error ? 'rgba(232, 76, 61, 1)' : '#d0d3d9')};
  border-radius: 4px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 8px;
  color: ${theme.palette.gray100};
  min-width: 140px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ErrorWrapper = styled.p`
  color: red;
  font-size: 12px;
  max-width: 140px;
`;

interface Props {
  max: number;
  min: number;
  value: number;
  onChange(value: number): void;
}

export const DurationInput = ({ max, min, value, onChange }: Props) => {
  const [seconds, setSeconds] = React.useState<number>(0);
  const [previousSeconds, setPreviousSeconds] = React.useState<number>(0);
  const [isError, setIsError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSeconds(value);
    setPreviousSeconds(value);
  }, [value]);

  const handleInputChange = (value?: string) => {
    if (!!value) {
      const newSeconds = fromMMSS(value);
      setSeconds(newSeconds);
    }
  };

  const handleInputBlur = () => {
    const isInvalid = seconds < min || seconds > max;
    setIsError(isInvalid);
    if (isInvalid) {
      setSeconds(previousSeconds);
      return;
    }

    setPreviousSeconds(seconds);
    setIsError(false);
    onChange(seconds);
  };

  return (
    <InputWrapper>
      <MaskedInputStyles
        value={toMMSS(seconds)?.split('.')?.at(0) || ''}
        mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
        placeholder='00:00'
        guide={false}
        error={isError ? 'error' : ''}
        pipe={timePipe}
        onChange={event => handleInputChange(event.target.value)}
        onBlur={handleInputBlur}
      />
      {isError && (
        <ErrorWrapper>
          Value must be between {toMMSS(min)?.split('.')?.at(0) || ''} and{' '}
          {toMMSS(max)?.split('.')?.at(0) || 0}
        </ErrorWrapper>
      )}
    </InputWrapper>
  );
};
