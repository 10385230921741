import React from 'react';

import instagram from '../icons/instagram.png';
import bni from '../icons/bni.png';
import cars from '../icons/carscom.png';
import dealerReater from '../icons/dealerreater.png';
import edmunds from '../icons/edmunds.png';
import linkedIn from '../icons/linkedin.png';
import tikTok from '../icons/tiktok.png';
import whatsapp from '../icons/whatsapp.png';
import youtube from '../icons/youtube.png';
import yelp from '../icons/yelp.png';
import pinterest from '../icons/pinterest.png';
import facebook from '../icons/facebook.png';
import zeemee from '../icons/zeemee.png';
import twitter_new from '../icons/twitter_new.png';
import { SocialLinksTypes } from '../const';

interface ISocialIcons {
  socialNetwork: SocialLinksTypes | undefined;
}

const socialIconProps = {
  instagram: { src: instagram, alt: 'Instagram icon' },
  bni: { src: bni, alt: 'Instagram icon' },
  carsdotcom: { src: cars, alt: 'Cars.com icon' },
  edmunds: { src: edmunds, alt: 'Edmunds icon' },
  dealerRater: { src: dealerReater, alt: 'Instagram icon' },
  linkedIn: { src: linkedIn, alt: 'Dealer Rater icon' },
  tikTok: { src: tikTok, alt: 'TikTok icon' },
  twitter: { src: twitter_new, alt: 'Twitter icon' },
  whatsApp: { src: whatsapp, alt: 'Whatsapp icon' },
  youtube: { src: youtube, alt: 'Youtube icon' },
  yelp: { src: yelp, alt: 'Yelp icon' },
  pinterest: { src: pinterest, alt: 'Pinterest icon' },
  facebook: { src: facebook, alt: 'Facebook icon' },
  zeemee: { src: zeemee, alt: 'ZeeMee icon' },
};

const commonStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '40px',
  maxHeight: '40px',
  borderRadius: '4px',
  cursor: 'pointer',
};

export const socialIcons = ({ socialNetwork }: ISocialIcons) => {
  if (!socialNetwork || !socialIconProps[socialNetwork])
    return (
      <div style={{ ...commonStyles, background: 'rgba(244,243,241, .7)' }} />
    );

  const { alt, src } = socialIconProps[socialNetwork];

  return <img src={src} alt={alt} style={commonStyles} />;
};
