import * as React from 'react';
import styled from 'styled-components/macro';

import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { LoadingIndicator } from 'lib/components';
import CloseIcon from 'lib/images/CloseIcon';
import { usePlayerDesignQuery } from 'lib/api/playerDesign/usePlayerDesignQuery';

const ModalItem = styled.div`
  display: block;
  position: relative;
  background-color: rgb(0, 0, 0);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CloseButtonWrap = styled.div`
  background-color: ${theme.palette.themeDark};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 4px;
  border: 1px solid #fff;
  border-radius: 4px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

type Props = {
  handleModalClose: () => void;
  videoId: string;
  source?: string;
  hideContext?: boolean;
};

type VideoData = {
  id: string;
  title: string;
  dateCreated: string;
  views: string;
  autogeneratedThumbnail: string;
  autogeneratedGif: string;
  videoSource: string;
  personalThumbnail: string;
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  playButtonPosition: string;
  wtvId?: number;
};

export const ModalVideoListVideo = ({
  videoId = '',
  source = '',
  hideContext = false,
  handleModalClose,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<VideoData>();
  const [hideContextMenu, setHideContextMenu] = React.useState(hideContext);

  const { data: defaultAppearanceData } = usePlayerDesignQuery();

  React.useEffect(() => {
    if (videoId) {
      setIsLoading(true);
      getVideo(videoId)
        .then((video: any) => {
          setData(video);
          if (video.wtvId !== undefined && video.wtvId > 0) {
            setHideContextMenu(true);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [videoId]);

  const videoRef = React.createRef<HTMLVideoElement>();

  return (
    <NewModal
      hideHeader={true}
      closeModal={handleModalClose}
      style={{ content: { padding: 0, border: 'none' } }}
    >
      <ModalItem>
        <LoadingIndicator
          height={'100%'}
          isLoading={isLoading}
          error={isError}
          color={theme.palette.white}
        />
        {!isLoading && !isError && (data || source !== '') && (
          <>
            <CloseButtonWrap
              title={'Close video preview'}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </CloseButtonWrap>
            <Content>
              {(data && videoId && (
                <VideoPlayer
                  playerBackgroundColor={data.playerBackgroundColor}
                  videoSource={data.videoSource}
                  videoId={data.id}
                  playButtonPosition={data.playButtonPosition}
                  playerIconsColor={data.playerIconsAndTextColor}
                  thumbnail={
                    data.personalThumbnail
                      ? data.personalThumbnail
                      : data.autogeneratedThumbnail
                  }
                  videoRef={videoRef}
                  autoplay={true}
                  hideContext={hideContextMenu}
                />
              )) || (
                <VideoPlayer
                  playerBackgroundColor={
                    defaultAppearanceData?.playerBackgroundColor
                  }
                  videoSource={source}
                  playButtonPosition={defaultAppearanceData?.playButtonPosition}
                  playerIconsColor={
                    defaultAppearanceData?.playerIconsAndTextColor
                  }
                  thumbnail={''}
                  videoRef={videoRef}
                  autoplay={true}
                  hideContext={hideContextMenu}
                />
              )}
            </Content>
          </>
        )}
      </ModalItem>
    </NewModal>
  );
};
